var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { getClass } from '../_lib/helper';
import './Link.scss';
import styled, { defaultTheme } from '../_lib/style';
var StyledLink = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    return (!props.isDisabled &&
        props.theme &&
        props.theme.link &&
        props.theme.link.color &&
        "\n        color: " + props.theme.link.color + " !important;\n      ");
});
StyledLink.defaultProps = {
    theme: {
        link: defaultTheme.link,
    },
};
function Link(_a) {
    var isDisabled = _a.isDisabled, url = _a.url, className = _a.className, onClick = _a.onClick, tabIndex = _a.tabIndex, newWindow = _a.newWindow, children = _a.children, id = _a.id, role = _a.role, ariaDisabled = _a.ariaDisabled;
    return (React.createElement(StyledLink, __assign({ id: id, href: isDisabled ? '#' : url, className: getClass('elmo-link', className, { disabled: isDisabled }), tabIndex: tabIndex, target: newWindow ? '_blank' : undefined, "data-testid": "elmo-link-" + (id || 'default') }, (!isDisabled ? { onClick: onClick } : {}), { role: role, "aria-disabled": ariaDisabled }), children));
}
Link.defaultProps = {
    role: 'link',
    ariaDisabled: false,
};
export default Link;
var templateObject_1;
