import Qrcode from 'qrcode-generator';

export function generateAndDownloadQRCode(
  data: string,
  filename: string
): void {
  const qr = Qrcode(0, 'L');

  qr.addData(data);
  qr.make();

  const content = qr.createDataURL(20);
  const tempElement: HTMLAnchorElement = document.createElement('a');

  tempElement.download = filename;
  tempElement.href = content;
  tempElement.click();

  tempElement.remove();
}
