var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
import './TextStyle.scss';
function TextStyle(props) {
    return (React.createElement(LoaderHolder, __assign({ className: "elmo-loader-text", viewBoxHeight: 35, viewBoxWidth: 500 }, props),
        React.createElement("rect", { x: "0", y: "10", rx: "4", ry: "4", width: "150", height: "25" }),
        React.createElement("rect", { x: "160", y: "10", rx: "4", ry: "4", width: "290", height: "25" })));
}
export default TextStyle;
