import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateSurveyTemplatePayload } from './type';
import { ErrorResponse } from 'type';

export const updateSurveyTemplate: ActionCreator<UpdateSurveyTemplatePayload> =
  createAction('UPDATE_SURVEY_TEMPLATE');

export const updateSurveyTemplateSuccess: EmptyActionCreator = createAction(
  'UPDATE_SURVEY_TEMPLATE_SUCCESS'
);

export const updateSurveyTemplateFailure: ActionCreator<ErrorResponse> =
  createAction('UPDATE_SURVEY_TEMPLATE_FAILURE');

export const clearUpdateSurveyTemplate: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_SURVEY_TEMPLATE'
);
