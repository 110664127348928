import { ActionCreator, createAction } from 'redux-act';

import { GetFilterValueRequest, GetFilterValuesResponse } from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getFilterValues: ActionCreator<GetFilterValueRequest> =
  createAction('GET_FILTER_VALUES');

export const getFilterValuesSuccess: ActionCreator<GetFilterValuesResponse> =
  createAction('GET_FILTER_VALUES_SUCCESS');

export const getFilterValuesFailure: ActionCreator<ErrorResponse> =
  createAction('GET_FILTER_VALUES_FAILURE');

export const updateGetFilterValuesQuery: ActionCreator<Query> = createAction(
  'UPDATE_GET_FILTER_VALUES_QUERY'
);
