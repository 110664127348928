import { GetEmailTemplatesState } from './type';
import { Query } from 'lib/stateTemplates';

export const getEmailTemplatesInitialState: GetEmailTemplatesState = {
  isValid: false,
  isLoaded: false,
  isLoading: false,
  count: 0,
  data: [],
  errors: undefined,
  query: Query,
};
