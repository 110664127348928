import { createReducer } from 'redux-act';

import {
  createPdf,
  createPdfSuccess,
  createPdfFailure,
  clearCreatePdf,
} from './actions';
import { createPdfInitialState } from '../type';
import createPdfState from './state';

export function createPdfReducer(state: createPdfInitialState) {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    isValid: false,
    data: null,
    error: null,
  };
}

export function createPdfSuccessReducer(
  state: createPdfInitialState,
  data: any
) {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    isValid: true,
    data: data,
  };
}

export function createPdfFailureReducer(
  state: createPdfInitialState,
  error: any
) {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    isValid: false,
    error: error,
  };
}

export function clearCreatePdfReducer(state: createPdfInitialState) {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    isValid: false,
    data: null,
    error: null,
  };
}

export const createPdfReducers = {
  [`${createPdf}`]: createPdfReducer,
  [`${createPdfSuccess}`]: createPdfSuccessReducer,
  [`${createPdfFailure}`]: createPdfFailureReducer,
  [`${clearCreatePdf}`]: clearCreatePdfReducer,
};

export default createReducer(
  {
    ...createPdfReducers,
  },
  createPdfState
);
