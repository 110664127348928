import { UploadInviteRecipientsCSVState } from './type';

export const uploadInviteRecipientsCSVInitialState: UploadInviteRecipientsCSVState =
  {
    isUpdated: false,
    isUpdating: false,
    isValid: false,
    data: {
      recipientsCount: 0,
      associatedFiltersCount: 0,
      displayName: null,
      path: null,
    },
  };
