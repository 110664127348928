import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField, TextFieldProps } from '@mui/material';
import { FormItem } from 'elmo-elements';
import { useField } from 'formik';
import React from 'react';

import { dateTimeWithTimeZone, removeSeconds } from 'lib/date';
import './Datetime.scss';

export interface DateTimeProps {
  label?: string;
  name: string;
  value?: string;
  validate?: (value: any) => string | undefined;
  isDisabled?: boolean;
  disablePast?: boolean;
}

const Datetime = ({
  label,
  isDisabled,
  disablePast = true,
  ...props
}: DateTimeProps) => {
  const [field, meta, helpers] = useField(props);
  const isError = meta.touched && meta.error;

  const handleChange = (value: any) => {
    helpers.setValue(removeSeconds(value));
  };

  return (
    <FormItem
      label={label}
      className={'datetime-field'}
      message={isError ? meta.error : dateTimeWithTimeZone(field.value)}
      status={isError ? 'error' : 'default'}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label={''}
          className={'schedule-picker'}
          onChange={handleChange}
          value={field.value}
          inputFormat={'dd/MM/yyyy HH:mm'}
          disablePast={disablePast}
          disabled={isDisabled}
          renderInput={(params: TextFieldProps) => (
            <TextField
              id={props.name}
              size={'medium'}
              InputLabelProps={{ shrink: false }}
              placeholder={''}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </FormItem>
  );
};

export default Datetime;
