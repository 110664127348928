import produce from 'immer';

import {
  clearCreateInviteMessage,
  createInviteMessage,
  createInviteMessageFailure,
  createInviteMessageSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function createInviteMessageReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteMessage.isCreated = false;
    draft.createInviteMessage.isCreating = true;
    draft.createInviteMessage.isValid = false;
    draft.createInviteMessage.data = undefined;
  });
}

export function createInviteMessageSuccessReducer(
  state: InvitesState,
  data: any
) {
  return produce(state, (draft) => {
    draft.createInviteMessage.isCreated = true;
    draft.createInviteMessage.isCreating = false;
    draft.createInviteMessage.isValid = true;
    draft.createInviteMessage.data = data.data;
  });
}

export function createInviteMessageFailureReducer(
  state: InvitesState,
  error: any
) {
  return produce(state, (draft) => {
    draft.createInviteMessage.isCreated = true;
    draft.createInviteMessage.isCreating = false;
    draft.createInviteMessage.error = error;
  });
}

export function clearCreateInviteMessageReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteMessage = invitesInitialState.createInviteMessage;
  });
}

export const createInviteMessageReducers = {
  [`${createInviteMessage}`]: createInviteMessageReducer,
  [`${createInviteMessageSuccess}`]: createInviteMessageSuccessReducer,
  [`${createInviteMessageFailure}`]: createInviteMessageFailureReducer,
  [`${clearCreateInviteMessage}`]: clearCreateInviteMessageReducer,
};
