import produce from 'immer';

import {
  getQuestionCategory,
  getQuestionCategoryFailure,
  getQuestionCategorySuccess,
  clearGetQuestionCategory,
} from './actions';
import {
  GetQuestionCategoryResponse,
  GetQuestionCategoryPayload,
} from './type';
import { transformQuestionCategory } from '../../../transformers';
import { getQuestionCategoryInitialState } from './state';
import { QuestionCategoryState } from '../type';
import { ErrorResponse } from 'type';

export function getQuestionCategoryReducer(
  state: QuestionCategoryState,
  payload: GetQuestionCategoryPayload
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.getQuestionCategory = { ...getQuestionCategoryInitialState };
    draft.getQuestionCategory.isLoading = true;
    draft.getQuestionCategory.questionCategoryId = payload.questionCategoryId;
  });
}

export function getQuestionCategorySuccessReducer(
  state: QuestionCategoryState,
  data: GetQuestionCategoryResponse
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.getQuestionCategory.isLoading = false;
    draft.getQuestionCategory.isLoaded = true;
    draft.getQuestionCategory.isValid = true;
    draft.getQuestionCategory.data = transformQuestionCategory(data.data);
  });
}

export function getQuestionCategoryFailureReducer(
  state: QuestionCategoryState,
  error: ErrorResponse
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.getQuestionCategory.isLoading = false;
    draft.getQuestionCategory.isLoaded = true;
    draft.getQuestionCategory.error = error;
  });
}

export function clearGetQuestionCategoryReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.getQuestionCategory = getQuestionCategoryInitialState;
  });
}

export const getQuestionCategoryReducers = {
  [`${getQuestionCategory}`]: getQuestionCategoryReducer,
  [`${getQuestionCategorySuccess}`]: getQuestionCategorySuccessReducer,
  [`${getQuestionCategoryFailure}`]: getQuestionCategoryFailureReducer,
  [`${clearGetQuestionCategory}`]: clearGetQuestionCategoryReducer,
};
