import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { associateFiltersFailure, associateFiltersSuccess } from './actions';
import { AssociateFiltersRequest } from './type';
import { associateFiltersApi } from './api';
import { SagaAction } from 'type';

export const associateFiltersSaga = function* (
  action: SagaAction<AssociateFiltersRequest>
): SagaIterator {
  try {
    yield call(associateFiltersApi(action.payload));

    yield put(associateFiltersSuccess());
  } catch (errors) {
    yield put(associateFiltersFailure(errors));
  }
};
