import { takeEvery } from 'redux-saga/effects';

import { getDefaultInviteSenderSaga } from './getDefaultInviteSender/sagas';
import { getDefaultInviteSender } from './getDefaultInviteSender/actions';
import { getFilerSaga } from './filter/sagas';
import { getFilter } from './filter/actions';

export default function* root() {
  yield takeEvery(getDefaultInviteSender, getDefaultInviteSenderSaga);
  yield takeEvery(getFilter, getFilerSaga);
}
