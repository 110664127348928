import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getCollectorsFailure,
  getCollectorsSuccess,
  updateCollectorsQuery,
} from './actions';
import { getCollectorsInitialState } from './state';
import { getCollectorsApi } from './api';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

export const getCollectorsSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const currentQuery = getQuery(getCollectorsInitialState.query);
    yield put(updateCollectorsQuery(currentQuery));

    const data = yield call(getCollectorsApi(action.payload, currentQuery));

    yield put(getCollectorsSuccess(data));
  } catch (error) {
    yield put(getCollectorsFailure(error));
  }
};
