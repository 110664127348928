import { call, put } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getTextQuestionAnswersFailure,
  getTextQuestionAnswersSuccess,
} from './actions';
import { getReportFilters } from 'page/OverviewReport/state/sagas/overviewReportHelpers';
import { GetTextQuestionAnswersPayload } from './type';
import { getTextQuestionAnswersApi } from './api';
import { SagaAction } from 'type';

export const getTextQuestionAnswersSaga = function* (
  action: SagaAction<GetTextQuestionAnswersPayload>
): SagaIterator {
  try {
    const filters = yield select(getReportFilters);

    const data = yield call(getTextQuestionAnswersApi(action.payload, filters));

    yield put(getTextQuestionAnswersSuccess(data));
  } catch (error) {
    yield put(getTextQuestionAnswersFailure(error));
  }
};
