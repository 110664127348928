import produce from 'immer';

import {
  createSurveyNotification,
  createSurveyNotificationFailure,
  createSurveyNotificationSuccess,
  clearCreateSurveyNotification,
} from './actions';
import { transformSurveyNotification } from '../../../transformers';
import { createSurveyNotificationInitialState } from './state';
import { CreateSurveyNotificationResponse } from './type';
import { SurveyNotificationState } from '../type';
import { ErrorResponse } from 'type';

export const createSurveyNotificationReducer = (
  state: SurveyNotificationState
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.createSurveyNotification = {
      ...createSurveyNotificationInitialState,
    };
    draft.createSurveyNotification.isLoading = true;
  });
};

export const createSurveyNotificationSuccessReducer = (
  state: SurveyNotificationState,
  data: CreateSurveyNotificationResponse
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.createSurveyNotification.isLoading = false;
    draft.createSurveyNotification.isLoaded = true;
    draft.createSurveyNotification.isValid = true;
    draft.createSurveyNotification.data = transformSurveyNotification(
      data.data
    );
  });
};

export const createSurveyNotificationFailureReducer = (
  state: SurveyNotificationState,
  error: ErrorResponse
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.createSurveyNotification.isLoading = false;
    draft.createSurveyNotification.isLoaded = true;
    draft.createSurveyNotification.error = error;
  });
};

export const clearCreateSurveyNotificationReducers = (
  state: SurveyNotificationState
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.createSurveyNotification = createSurveyNotificationInitialState;
  });
};

export const createSurveyNotificationReducers = {
  [`${createSurveyNotification}`]: createSurveyNotificationReducer,
  [`${createSurveyNotificationSuccess}`]:
    createSurveyNotificationSuccessReducer,
  [`${createSurveyNotificationFailure}`]:
    createSurveyNotificationFailureReducer,
  [`${clearCreateSurveyNotification}`]: clearCreateSurveyNotificationReducers,
};
