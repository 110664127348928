import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getEmailTemplateFailure, getEmailTemplateSuccess } from './actions';
import { getEmailTemplateApi } from './api';

export interface GetEmailTemplateSagaProps {
  error: boolean;
  payload: number;
  type: string;
}

export const getEmailTemplateSaga = function* (
  action: GetEmailTemplateSagaProps
): SagaIterator {
  try {
    const data = yield call(getEmailTemplateApi(action.payload));

    yield put(getEmailTemplateSuccess(data));
  } catch (errors) {
    yield put(getEmailTemplateFailure(errors));
  }
};
