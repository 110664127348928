// KSCAN Exclusions:
// KSCAN:2b008834

export const parseClientNameFromUrl = (url?: string): string => {
  if (!url) {
    url = window.location.href;
  }

  const regExp = /(http|https):\/\/(.+?)\.elmo\w+/gi;
  const match = regExp.exec(url);

  if (match === null) {
    return 'unknown';
  }

  return match[2];
};
