import React from 'react';
import Button from '../../Button';
import './BulkactionButtons.scss';
function BulkactionButtons(_a) {
    var actions = _a.actions, isDisabled = _a.isDisabled;
    return (React.createElement("div", { className: "elmo-bulk-actions__buttons" }, actions.map(function (action, index) {
        return (React.createElement(Button, { isText: true, icon: action.icon, key: index, isDisabled: isDisabled, onClick: action.onClick }, action.label));
    })));
}
export default BulkactionButtons;
