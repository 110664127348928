import { deleteRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const responseRoute = '/respondents/:respondentId';

export const deleteResponseApi = (respondentId: number) => {
  return deleteRequest(
    getRoute(responseRoute, {
      params: { respondentId: respondentId },
    })
  );
};
