import produce from 'immer';

import {
  clearUpdateInviteMessageTemplate,
  updateInviteMessageTemplate,
  updateInviteMessageTemplateFailure,
  updateInviteMessageTemplateSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function updateInviteMessageTemplateReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteMessageTemplate.isUpdated = false;
    draft.updateInviteMessageTemplate.isUpdating = true;
  });
}

export function updateInviteMessageTemplateSuccessReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteMessageTemplate.isUpdated = true;
    draft.updateInviteMessageTemplate.isUpdating = false;
  });
}

export function updateInviteMessageTemplateFailureReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteMessageTemplate.isUpdated = false;
    draft.updateInviteMessageTemplate.isUpdating = false;
  });
}

export function clearUpdateInviteMessageTemplateReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteMessageTemplate =
      invitesInitialState.updateInviteMessageTemplate;
  });
}

export const updateInviteMessageTemplateReducers = {
  [`${updateInviteMessageTemplate}`]: updateInviteMessageTemplateReducer,
  [`${updateInviteMessageTemplateSuccess}`]:
    updateInviteMessageTemplateSuccessReducer,
  [`${updateInviteMessageTemplateFailure}`]:
    updateInviteMessageTemplateFailureReducer,
  [`${clearUpdateInviteMessageTemplate}`]:
    clearUpdateInviteMessageTemplateReducer,
};
