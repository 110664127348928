import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getUserProfileFilterOptionsSuccess,
  getUserProfileFilterOptionsFailure,
} from './actions';
import { getUserProfileFilterOptionsApi } from './api';

export const getUserProfileFilterOptionsSaga = function* (): SagaIterator {
  try {
    const data = yield call(getUserProfileFilterOptionsApi());

    yield put(getUserProfileFilterOptionsSuccess(data));
  } catch (error) {
    yield put(getUserProfileFilterOptionsFailure(error));
  }
};
