import { createAction } from 'redux-act';

export const createInviteMessageSchedule: any = createAction(
  'CREATE_INVITE_MESSAGE_SCHEDULE'
);

export const createInviteMessageScheduleSuccess: any = createAction(
  'CREATE_INVITE_MESSAGE_SCHEDULE_SUCCESS'
);

export const createInviteMessageScheduleFailure: any = createAction(
  'CREATE_INVITE_MESSAGE_SCHEDULE_FAILURE'
);

export const clearCreateInviteMessageSchedule: any = createAction(
  'CLEAR_CREATE_INVITE_MESSAGE_SCHEDULE'
);
