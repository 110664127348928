import { GetAnonSurveyTheme } from './type';

export const getAnonSurveyThemeInitialState: GetAnonSurveyTheme = {
  isLoaded: false,
  isLoading: false,
  isValid: false,
  surveyId: 0,
  theme: {
    buttonColour: '',
    footerBackgroundColor: '',
    footerDescription: '',
    footerFontColor: '',
    footerLogoName: null,
    footerLogoUrl: '',
    headingBannerBackgroundColour: '',
    headingBannerFontColour: '',
    logoName: '',
    logoUrl: '',
    textFontColour: '',
  },
};
