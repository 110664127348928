import { ActionCreator, createAction } from 'redux-act';

import { GetBulkUrlsPayload, GetBulkUrlsResponse } from './type';
import { ErrorResponse } from 'type';

export const getBulkUrls: ActionCreator<GetBulkUrlsPayload> =
  createAction('GET_BULK_URLS');

export const getBulkUrlsSuccess: ActionCreator<GetBulkUrlsResponse> =
  createAction('GET_BULK_URLS_SUCCESS');

export const getBulkUrlsFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_BULK_URLS_FAILURE'
);
