import { createReducer } from 'redux-act';

import { deleteQuestionReducers } from './deleteQuestion/reducers';
import { createQuestionReducers } from './createQuestion/reducers';
import { updateQuestionReducers } from './updateQuestion/reducers';
import { getQuestionsReducers } from './getQuestions/reducers';
import { getQuestionReducers } from './getQuestion/reducers';
import { questionInitialState } from './state';

export default createReducer(
  {
    ...createQuestionReducers,
    ...getQuestionReducers,
    ...updateQuestionReducers,
    ...getQuestionsReducers,
    ...deleteQuestionReducers,
  },
  questionInitialState
);
