var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import MenuLink from './MenuLink';
import ThemeAware from './ThemeAware';
import { Nav } from '../Styles';
import './SubMenu.scss';
function SubMenu(_a) {
    var menuItems = _a.menuItems, onCloseMenuMobile = _a.onCloseMenuMobile;
    return (React.createElement(ThemeAware, { render: function (_a) {
            var menuBackgroundColor = _a.menuBackgroundColor, menuFont = _a.menuFont, menuFontColor = _a.menuFontColor, menuHoverFontColor = _a.menuHoverFontColor;
            return (React.createElement(Nav, { className: "elmo-nav-submenu__items", menuBackgroundColor: "#fff", menuFont: menuFont, "aria-label": "Elmo submenu" }, menuItems && (React.createElement("ul", null, menuItems.map(function (menuItem, i) { return (React.createElement(MenuLink, __assign({ key: "sub-menu-" + i }, menuItem, { menuFontColor: menuFontColor, menuHoverFontColor: menuHoverFontColor, onCloseMenuMobile: onCloseMenuMobile, isSubMenu: true }))); })))));
        } }));
}
export default SubMenu;
