import produce from 'immer';

import {
  getTextQuestionAnswers,
  clearGetTextQuestionAnswers,
  getTextQuestionAnswersFailure,
  getTextQuestionAnswersSuccess,
} from './actions';
import {
  GetTextQuestionAnswersPayload,
  GetTextQuestionAnswersResponse,
} from './type';
import { getTextQuestionAnswersInitialState } from './state';
import { OverviewReportState } from '../type';
import { ErrorResponse } from 'type';

export function getTextQuestionAnswersReducer(
  state: OverviewReportState,
  data: GetTextQuestionAnswersPayload
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getTextQuestionAnswers.isLoading = true;
    draft.getTextQuestionAnswers.isLoaded = false;
    draft.getTextQuestionAnswers.isValid = false;
    draft.getTextQuestionAnswers.count = 0;
    draft.getTextQuestionAnswers.query = {
      pageSize: data.pageSize,
      currentPage: data.currentPage,
    };
    draft.getTextQuestionAnswers.data = [];
  });
}

export function getTextQuestionAnswersSuccessReducer(
  state: OverviewReportState,
  data: GetTextQuestionAnswersResponse
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getTextQuestionAnswers.isLoading = false;
    draft.getTextQuestionAnswers.isLoaded = true;
    draft.getTextQuestionAnswers.isValid = true;
    draft.getTextQuestionAnswers.count = data.count;
    draft.getTextQuestionAnswers.data = data.data;
  });
}

export function getTextQuestionAnswersFailureReducer(
  state: OverviewReportState,
  error: ErrorResponse
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getTextQuestionAnswers.isLoading = false;
    draft.getTextQuestionAnswers.isLoaded = true;
    draft.getTextQuestionAnswers.error = error;
  });
}

export function clearGetTextQuestionAnswersReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getTextQuestionAnswers = getTextQuestionAnswersInitialState;
  });
}

export const getTextQuestionAnswersReducers = {
  [`${getTextQuestionAnswers}`]: getTextQuestionAnswersReducer,
  [`${getTextQuestionAnswersSuccess}`]: getTextQuestionAnswersSuccessReducer,
  [`${getTextQuestionAnswersFailure}`]: getTextQuestionAnswersFailureReducer,
  [`${clearGetTextQuestionAnswers}`]: clearGetTextQuestionAnswersReducer,
};
