import { GetFilterState } from './type';
import { Filter } from 'type';

export const getFilterInitialState: GetFilterState = {
  isLoaded: false,
  isLoading: false,
  isValid: false,
  data: {} as Filter,
  error: undefined,
};
