import produce from 'immer';

import { List } from 'lib/stateTemplates';
import { GetInvitesState } from './type';

export const getInvitesInitialState: GetInvitesState = produce(
  List,
  (draft) => {
    draft.query.sort = '-createdAt';
  }
);
