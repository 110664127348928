import produce from 'immer';

import {
  getDefaultTheme,
  getDefaultThemeFailure,
  getDefaultThemeSuccess,
} from './actions';
import { transformTheme } from '../../../transformers';
import { ThemeState } from '../type';
import { ErrorResponse } from 'type';

export function getDefaultThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getDefaultTheme.isLoading = true;
    draft.getDefaultTheme.isLoaded = false;
    draft.getDefaultTheme.isValid = false;
    draft.getDefaultTheme.theme = {};
    draft.getDefaultTheme.error = undefined;
  });
}

export function getDefaultThemeSuccessReducer(
  state: ThemeState,
  data: any
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getDefaultTheme.isLoading = false;
    draft.getDefaultTheme.isLoaded = true;
    draft.getDefaultTheme.isValid = true;
    draft.getDefaultTheme.theme = transformTheme(data.data);
  });
}

export function getDefaultThemeFailureReducer(
  state: ThemeState,
  error: ErrorResponse
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getDefaultTheme.isLoading = false;
    draft.getDefaultTheme.isLoaded = true;
    draft.getDefaultTheme.error = error;
  });
}

export const getDefaultThemeReducers = {
  [`${getDefaultTheme}`]: getDefaultThemeReducer,
  [`${getDefaultThemeSuccess}`]: getDefaultThemeSuccessReducer,
  [`${getDefaultThemeFailure}`]: getDefaultThemeFailureReducer,
};
