var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass } from '../../_lib/helper';
import './DropdownItem.scss';
import { DropdownItemTypes, DropdownKeyConstants, } from '../type';
var DropdownItem = /** @class */ (function (_super) {
    __extends(DropdownItem, _super);
    function DropdownItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * When a key is pressed
         * @param e
         */
        _this.handleKeyboardEvent = function (e) {
            // ignore all key presses on the item except the Enter key
            if (e.key !== DropdownKeyConstants.enterKey || !_this.props.onClick) {
                return;
            }
            _this.handleClick();
        };
        _this.handleClick = function () {
            var _a = _this.props, value = _a.value, onClick = _a.onClick, isDisabled = _a.isDisabled, toggleMenu = _a.toggleMenu;
            if (!onClick || isDisabled) {
                return;
            }
            onClick(value);
            if (toggleMenu) {
                toggleMenu();
            }
        };
        return _this;
    }
    DropdownItem.prototype.render = function () {
        var _a = this.props, isDisabled = _a.isDisabled, ariaLabel = _a.ariaLabel, children = _a.children, type = _a.type, dividerType = _a.dividerType;
        return (React.createElement("li", { className: getClass('elmo-dropdown__menu-item', '', {
                disabled: isDisabled,
                danger: type === DropdownItemTypes.danger,
                'has-deafult-divider': dividerType === 'default',
                'has-short-divider': dividerType === 'short',
            }), onClick: this.handleClick, tabIndex: isDisabled ? -1 : 0, onKeyDown: this.handleKeyboardEvent, role: "menuitem", "aria-label": ariaLabel, "aria-disabled": isDisabled }, children));
    };
    return DropdownItem;
}(Component));
export default DropdownItem;
