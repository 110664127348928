import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse, Menu } from 'type';

export const getMenu: EmptyActionCreator = createAction('GET_MENU');

export const getMenuSuccess: ActionCreator<Menu> =
  createAction('GET_MENU_SUCCESS');

export const getMenuFailure: ActionCreator<ErrorResponse> =
  createAction('GET_MENU_FAILURE');
