import { call, put } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getOverviewReportStatsFailure,
  getOverviewReportStatsSuccess,
} from './actions';
import { getReportFilters } from 'page/OverviewReport/state/sagas/overviewReportHelpers';
import { getOverviewReportStatsApi } from './api';
import { SagaAction } from 'type';

export const getOverviewReportStatsSaga = function* (
  action: SagaAction<number | string>
): SagaIterator {
  try {
    const filters = yield select(getReportFilters);

    const data = yield call(getOverviewReportStatsApi(action.payload, filters));

    yield put(getOverviewReportStatsSuccess(data));
  } catch (error) {
    yield put(getOverviewReportStatsFailure(error));
  }
};
