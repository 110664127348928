import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { deleteInviteFailure, deleteInviteSuccess } from './actions';
import { deleteInviteApi } from './api';

export const deleteInviteSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(deleteInviteApi(action.payload));

    yield put(deleteInviteSuccess(data));
  } catch (error) {
    yield put(deleteInviteFailure(error));
  }
};
