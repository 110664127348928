var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import withLayoutAware from './LayoutAware';
var LayoutHeaderWithoutContext = /** @class */ (function (_super) {
    __extends(LayoutHeaderWithoutContext, _super);
    function LayoutHeaderWithoutContext() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.headerRef = createRef();
        return _this;
    }
    LayoutHeaderWithoutContext.prototype.componentDidMount = function () {
        var setHeaderRef = this.props.setHeaderRef;
        if (this.headerRef && this.headerRef.current && setHeaderRef) {
            setHeaderRef(this.headerRef.current);
        }
    };
    LayoutHeaderWithoutContext.prototype.render = function () {
        var children = this.props.children;
        return (React.createElement("div", { className: "elmo-layout__main-header", ref: this.headerRef, role: "banner" }, children));
    };
    return LayoutHeaderWithoutContext;
}(Component));
export { LayoutHeaderWithoutContext };
export default withLayoutAware(LayoutHeaderWithoutContext);
