import { appTranslations } from '../../translations';
export const LANGUAGES = {
  en: 'en',
};

const commonTranslations = {
  app: {
    dashboard: {
      key: 'app.menu.dashboard',
      default: 'Dashboard Default',
    },
    language: {
      key: 'app.menu.language',
      default: 'Language Default',
    },
    menu: {
      receivedInvalidData: {
        key: 'app.menu.receivedInvalidData',
        default: 'Received data is not valid',
      },
    },
    auth: {
      redirectingToLogin: {
        key: 'app.auth.redirectingToLogin',
        default: 'Redirecting to login page, please wait...',
      },
      receivedInvalidData: {
        key: 'app.auth.receivedInvalidData',
        default: 'Received data is not valid',
      },
    },
  },
};

export const messages: any = {
  ...appTranslations,
  ...commonTranslations,
};
