import produce from 'immer';

import {
  clearCreateQuestionCategory,
  createQuestionCategory,
  createQuestionCategoryFailure,
  createQuestionCategorySuccess,
} from './actions';
import { createQuestionCategoryInitialState } from './state';
import { questionCategoryInitialState } from '../state';
import { QuestionCategoryState } from '../type';

export function createQuestionCategoryReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.createQuestionCategory = { ...createQuestionCategoryInitialState };
    draft.createQuestionCategory.isLoading = true;
  });
}

export function createQuestionCategorySuccessReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.createQuestionCategory.isLoaded = true;
    draft.createQuestionCategory.isValid = true;
    draft.createQuestionCategory.isLoading = false;
  });
}

export function createQuestionCategoryFailureReducer(
  state: QuestionCategoryState,
  error: any
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.createQuestionCategory.isLoaded = true;
    draft.createQuestionCategory.isLoading = false;
    draft.createQuestionCategory.error = error;
  });
}

export function clearCreateQuestionCategoryReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.createQuestionCategory =
      questionCategoryInitialState.createQuestionCategory;
  });
}

export const createQuestionCategoryReducers = {
  [`${createQuestionCategory}`]: createQuestionCategoryReducer,
  [`${createQuestionCategorySuccess}`]: createQuestionCategorySuccessReducer,
  [`${createQuestionCategoryFailure}`]: createQuestionCategoryFailureReducer,
  [`${clearCreateQuestionCategory}`]: clearCreateQuestionCategoryReducer,
};
