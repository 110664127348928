import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createEmailTemplateFailure,
  createEmailTemplateSuccess,
} from './actions';
import { CreateEmailTemplatePayload } from './type';
import { createEmailTemplateApi } from './api';

export interface CreateEmailTemplateSagaProps {
  error: boolean;
  payload: CreateEmailTemplatePayload;
  type: string;
}

export const createEmailTemplateSaga = function* (
  action: CreateEmailTemplateSagaProps
): SagaIterator {
  try {
    yield call(createEmailTemplateApi(action.payload));

    yield put(createEmailTemplateSuccess());
  } catch (errors) {
    yield put(createEmailTemplateFailure(errors));
  }
};
