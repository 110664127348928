import { CreateRespondentPayload } from './type';
import { postRequest } from 'lib/api';

const anonRespondentRoute = '/anon/respondent';
const respondentRoute = '/respondent';

export const createRespondentApi = (payload: CreateRespondentPayload) => {
  let route = anonRespondentRoute;

  const body: any = {
    survey_id: payload.surveyId,
    answers: payload.answers,
  };

  if (payload.collectorHash) {
    body.collector_hash = payload.collectorHash;
  } else if (payload.inviteHash) {
    body.invite_hash = payload.inviteHash;
  } else if (payload.hash) {
    route = respondentRoute;
    body.hash = payload.hash;
  }

  return postRequest(route, body);
};
