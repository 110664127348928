import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetBulkUrlPayload, GetBulkUrlResponse } from './type';
import { ErrorResponse } from 'type';

export const getBulkUrl: ActionCreator<GetBulkUrlPayload> =
  createAction('GET_BULK_URL');

export const getBulkUrlSuccess: ActionCreator<GetBulkUrlResponse> =
  createAction('GET_BULK_URL_SUCCESS');

export const getBulkUrlFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_BULK_URL_FAILURE'
);

export const clearGetBulkUrl: EmptyActionCreator =
  createAction('CLEAR_GET_BULK_URL');
