import React from 'react';
import Button from '../../Button';
function ModalFooter(_a) {
    var closeModal = _a.closeModal, primaryButton = _a.primaryButton, closeLabel = _a.closeLabel, hasCloseFooterButton = _a.hasCloseFooterButton;
    if (!(primaryButton || hasCloseFooterButton)) {
        return null;
    }
    return (React.createElement("div", { className: "elmo-modal__footer" },
        hasCloseFooterButton && (React.createElement("div", { className: "elmo-modal__footer-close" },
            React.createElement(Button, { isText: true, isBlock: true, onClick: closeModal, className: "elmo-modal__footer-close-btn" }, closeLabel ? closeLabel : 'Cancel'))),
        primaryButton && (React.createElement("div", { className: "elmo-modal__footer-primary-btn" }, primaryButton))));
}
export default ModalFooter;
