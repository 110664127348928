import { GetSavedOverviewReportState } from './type';

export const getSavedOverviewReportInitialState: GetSavedOverviewReportState = {
  description: '',
  filters: {},
  questionSettings: {},
  title: '',
  isLoading: false,
  isLoaded: false,
  isValid: false,
};
