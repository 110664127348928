var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import './Pagination.scss';
import PaginationRange from './component/PaginationRange';
import PaginationItem from './component/PaginationItem';
import { FirstPageIcon, ChevronLeftIcon as PrevPageIcon, ChevronRightIcon as NextPageIcon, LastPageIcon, } from '../Icon';
import PaginationDropdown from './component/PaginationDropdown';
import { PAGINATION_FIRST_PAGE } from '../_lib/const';
import { getClass } from '../_lib/helper';
var Pagination = /** @class */ (function (_super) {
    __extends(Pagination, _super);
    function Pagination() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onPageChange = function (pageNumber) { return function (e) {
            e.preventDefault();
            var _a = _this.props, pageSize = _a.pageSize, totalResults = _a.totalResults, onPageChange = _a.onPageChange;
            var pageCount = _this.countNumPages(totalResults, pageSize);
            if (pageNumber > pageCount || pageNumber < PAGINATION_FIRST_PAGE) {
                return;
            }
            var _b = _this.getStartEndItem(pageNumber, pageSize, totalResults), startItem = _b.startItem, endItem = _b.endItem;
            onPageChange(pageNumber, startItem, endItem);
        }; };
        return _this;
    }
    Pagination.prototype.countNumPages = function (totalResults, pageSize) {
        return pageSize > 0 ? Math.ceil(totalResults / pageSize) : 0;
    };
    Pagination.prototype.getStartEndItem = function (pageNumber, pageSize, totalResults) {
        if (pageNumber <= 0 || pageSize <= 0 || totalResults <= 0) {
            return {
                startItem: 0,
                endItem: 0,
            };
        }
        var startItem = 1 + (pageNumber - 1) * pageSize;
        var endItem = Math.min(startItem - 1 + pageSize, totalResults);
        return {
            startItem: startItem,
            endItem: endItem,
        };
    };
    Pagination.prototype.render = function () {
        var _a = this.props, pageSize = _a.pageSize, pageSizeOptions = _a.pageSizeOptions, totalResults = _a.totalResults, currentPage = _a.currentPage, hideDropdown = _a.hideDropdown, hideRangeText = _a.hideRangeText, id = _a.id, className = _a.className, onPageSizeChange = _a.onPageSizeChange;
        var _b = this.getStartEndItem(currentPage, pageSize, totalResults), startItem = _b.startItem, endItem = _b.endItem;
        var pageCount = this.countNumPages(totalResults, pageSize);
        return (React.createElement("nav", { className: getClass('elmo-pagination', className), role: "navigation", "aria-label": "Pagination", tabIndex: 0, id: id, "data-testid": "elmo-pagination-" + (id || 'default') },
            !hideRangeText && (React.createElement(PaginationRange, { firstResult: startItem, lastResult: endItem, totalResults: totalResults })),
            !hideDropdown && (React.createElement(PaginationDropdown, { pageSize: pageSize, pageSizeOptions: pageSizeOptions, onPageSizeChange: onPageSizeChange })),
            React.createElement("div", { className: "elmo-pagination__wrapper" },
                React.createElement(PaginationItem, { ariaLabel: "Go to First Page", onClick: this.onPageChange(PAGINATION_FIRST_PAGE), isDisabled: currentPage === 1, icon: React.createElement(FirstPageIcon, null) }),
                React.createElement(PaginationItem, { ariaLabel: "Go to Previous Page", onClick: this.onPageChange(currentPage - 1), isDisabled: currentPage === 1, icon: React.createElement(PrevPageIcon, null) }),
                React.createElement(PaginationItem, { ariaLabel: "Go to Next Page", onClick: this.onPageChange(currentPage + 1), isDisabled: currentPage === pageCount, icon: React.createElement(NextPageIcon, null) }),
                React.createElement(PaginationItem, { ariaLabel: "Go to Last Page", onClick: this.onPageChange(pageCount), isDisabled: currentPage === pageCount, icon: React.createElement(LastPageIcon, null) }))));
    };
    return Pagination;
}(Component));
export default Pagination;
