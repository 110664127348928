import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetSurveyStatsResponse } from './type';
import { ErrorResponse } from 'type';

export const getSurveyStats: ActionCreator<string> =
  createAction('GET_SURVEY_STATS');

export const getSurveyStatsSuccess: ActionCreator<GetSurveyStatsResponse> =
  createAction('GET_SURVEY_STATS_SUCCESS');

export const getSurveyStatsFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_SURVEY_STATS_FAILURE'
);

export const clearGetSurveyStats: EmptyActionCreator = createAction(
  'CLEAR_GET_SURVEY_STATS'
);
