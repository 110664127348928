import { BadgeType } from 'elmo-elements/Badge';
import React, { ReactElement } from 'react';
import { Badge } from 'elmo-elements';

import { statusBadgeProps } from './type';

const StatusBadge = ({ status }: statusBadgeProps): ReactElement => {
  let type: BadgeType = 'outlined';

  switch (status) {
    case 'active':
    case 'live':
    case 'available':
      type = 'info';
      break;
    case 'draft':
      type = 'warning';
      break;
    case 'closed':
    case 'disabled':
    case 'scheduled':
      type = 'grey';
      break;
    default:
      break;
  }

  return <Badge type={type}>{status}</Badge>;
};

export default StatusBadge;
