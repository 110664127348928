import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getSurveyTemplateFailure, getSurveyTemplateSuccess } from './actions';
import { GetSurveyTemplatePayload } from './type';
import { getSurveyTemplateApi } from './api';
import { SagaAction } from 'type';

export const getSurveyTemplateSaga = function* (
  action: SagaAction<GetSurveyTemplatePayload>
): SagaIterator {
  try {
    const { isGlobal, surveyTemplateId } = action.payload;

    const data = yield call(getSurveyTemplateApi(isGlobal, surveyTemplateId));

    yield put(getSurveyTemplateSuccess(data));
  } catch (error) {
    yield put(getSurveyTemplateFailure(error));
  }
};
