/* eslint-disable max-len */
import React from 'react';
function ReportOffOutlined() {
    return (React.createElement("g", null,
        React.createElement("path", { fill: "none", d: "M0 0h24v24H0V0z" }),
        React.createElement("path", { d: "M9.1 5h5.8L19 9.1v5.8l-.22.22 1.42 1.41.8-.8V8.27L15.73 3H8.27l-.8.8 1.41 1.42z" }),
        React.createElement("circle", { cx: "12", cy: "16", r: "1" }),
        React.createElement("path", { d: "M13 9.33V7h-2v.33zM2.41 1.58L1 2.99l3.64 3.64L3 8.27v7.46L8.27 21h7.46l1.64-1.64L21.01 23l1.41-1.41L2.41 1.58zM14.9 19H9.1L5 14.9V9.1l1.05-1.05 9.9 9.9L14.9 19z" })));
}
export default ReportOffOutlined;
