import { SortAscIcon, SortDescIcon } from '../../Icon';
import React from 'react';
import { SORT_ASC, SORT_DESC } from '../../_lib/const';
import { getClass } from '../../_lib/helper';
function ListTableSortIcon(_a) {
    var _b;
    var direction = _a.direction;
    var classes = getClass('elmo-listtable__sort-icon', '', (_b = {},
        _b[direction] = direction !== undefined,
        _b));
    var icon = null;
    switch (direction) {
        case SORT_ASC: {
            icon = React.createElement(SortAscIcon, null);
            break;
        }
        case SORT_DESC: {
            icon = React.createElement(SortDescIcon, null);
            break;
        }
        default: {
            return null;
        }
    }
    return (React.createElement("span", { className: classes, "data-sort": direction }, icon));
}
export default ListTableSortIcon;
