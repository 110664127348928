import produce from 'immer';

import {
  clearDeleteInviteMessage,
  deleteInviteMessage,
  deleteInviteMessageFailure,
  deleteInviteMessageSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function deleteInviteMessageReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.deleteInviteMessage.isDeleted = false;
    draft.deleteInviteMessage.isValid = false;
    draft.deleteInviteMessage.isDeleting = true;
  });
}

export function deleteInviteMessageSuccessReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.deleteInviteMessage.isDeleted = true;
    draft.deleteInviteMessage.isValid = true;
    draft.deleteInviteMessage.isDeleting = false;
  });
}

export function deleteInviteMessageFailureReducer(
  state: InvitesState,
  error: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.deleteInviteMessage.isDeleted = true;
    draft.deleteInviteMessage.isDeleting = false;
    draft.deleteInviteMessage.error = error;
  });
}

export function clearDeleteInviteMessageReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.deleteInviteMessage = invitesInitialState.deleteInviteMessage;
  });
}

export const deleteInviteMessageReducers = {
  [`${deleteInviteMessage}`]: deleteInviteMessageReducer,
  [`${deleteInviteMessageSuccess}`]: deleteInviteMessageSuccessReducer,
  [`${deleteInviteMessageFailure}`]: deleteInviteMessageFailureReducer,
  [`${clearDeleteInviteMessage}`]: clearDeleteInviteMessageReducer,
};
