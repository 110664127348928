import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import {
  GetSurveyNotificationPayload,
  GetSurveyNotificationResponse,
} from './type';
import { ErrorResponse } from 'type';

export const getSurveyNotification: ActionCreator<GetSurveyNotificationPayload> =
  createAction('GET_SURVEY_NOTIFICATION');

export const getSurveyNotificationSuccess: ActionCreator<GetSurveyNotificationResponse> =
  createAction('GET_SURVEY_NOTIFICATION_SUCCESS');

export const getSurveyNotificationFailure: ActionCreator<ErrorResponse> =
  createAction('GET_SURVEY_NOTIFICATION_FAILURE');

export const clearGetSurveyNotification: EmptyActionCreator = createAction(
  'CLEAR_GET_SURVEY_NOTIFICATION'
);
