import { getDefaultEmailTemplateInitialState } from './getDefaultEmailTemplate/state';
import { createEmailTemplateInitialState } from './createEmailTemplate/state';
import { updateEmailTemplateInitialState } from './updateEmailTemplate/state';
import { getEmailTemplatesInitialState } from './getEmailTemplates/state';
import { getEmailTemplateInitialState } from './getEmailTemplate/state';
import { EmailTemplateState } from './type';

export const emailTemplateInitialState: EmailTemplateState = {
  getDefaultEmailTemplate: getDefaultEmailTemplateInitialState,
  createEmailTemplate: createEmailTemplateInitialState,
  getEmailTemplate: getEmailTemplateInitialState,
  getEmailTemplates: getEmailTemplatesInitialState,
  updateEmailTemplate: updateEmailTemplateInitialState,
};
