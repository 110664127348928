import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getAdminDetailsFailure, getAdminDetailsSuccess } from './actions';
import { getAdminDetailsApi } from './api';

export const getAdminDetailsSaga = function* (): SagaIterator {
  try {
    const data = yield call(getAdminDetailsApi());

    yield put(getAdminDetailsSuccess(data));
  } catch (error) {
    yield put(getAdminDetailsFailure(error));
  }
};
