import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useState } from 'react';

import { updateManageInviteMessage } from 'store/modules/invites/manageInvite/actions';
import MessageEditor from 'element/messageEditor';
import translations from './translations';
import { MessageProps } from './type';

const Message = ({ closeModal, previousStep }: MessageProps): ReactElement => {
  const dispatch = useDispatch();

  const parameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );
  const message = useSelector(
    (state: any) => state.emailInvites.manageInvite.data.message
  );

  const [messageState, updateMessageState] = useState<any>(message);

  const saveMessage = () => {
    dispatch(updateManageInviteMessage(messageState));
    closeModal();
  };

  return (
    <MessageEditor
      closeModal={closeModal}
      messageState={messageState}
      updateMessageState={updateMessageState}
      hasSecondaryButton={parameters.isFlow}
      secondaryButtonOnClick={previousStep}
      primaryButtonOnClick={saveMessage}
      messageTranslations={translations}
    />
  );
};

export default Message;
