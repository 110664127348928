import MaterialUiStepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { Heading } from 'elmo-elements';
import Step from '@mui/material/Step';
import React from 'react';

import { StepperProps, Step as StepType } from './type';
import './style.scss';

const Stepper = ({
  steps,
  activeStep,
  heading,
  nonLinear = false,
}: StepperProps) => {
  return (
    <>
      {heading && (
        <Heading htmlTag="h5" type="title" size="xs">
          {heading}
        </Heading>
      )}
      <MaterialUiStepper
        id={'stepper'}
        activeStep={activeStep}
        orientation={'vertical'}
        nonLinear={nonLinear}
      >
        {steps.map((step: StepType) => (
          <Step
            key={step.label}
            {...(step.isCompleted && { completed: step.isCompleted })}
            {...(step.isActive && { active: step.isActive })}
          >
            <StepLabel {...step.labelProps}>
              <div className={'label'}>{step.label}</div>
              {step.description && (
                <div className={'description'}>{step.description}</div>
              )}
            </StepLabel>
          </Step>
        ))}
      </MaterialUiStepper>
    </>
  );
};

export default Stepper;
