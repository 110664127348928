import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { CreateFilterPayload } from './type';
import { ErrorResponse } from 'type';

export const createFilter: ActionCreator<CreateFilterPayload> =
  createAction('CREATE_FILTER');

export const createFilterSuccess: EmptyActionCreator = createAction(
  'CREATE_FILTER_SUCCESS'
);

export const createFilterFailure: ActionCreator<ErrorResponse> = createAction(
  'CREATE_FILTER_FAILURE'
);

export const clearCreateFilter: EmptyActionCreator = createAction(
  'CLEAR_CREATE_FILTER'
);
