import produce from 'immer';

import {
  getAdminDetails,
  getAdminDetailsFailure,
  getAdminDetailsSuccess,
} from './actions';
import { transformAdminDetails } from '../../../transformers';
import { getAdminDetailsInitialState } from './state';
import { GetAdminDetailsResponse } from './type';
import { AdminState } from '../type';
import { ErrorResponse } from 'type';

export function getAdminDetailsReducer(state: AdminState): AdminState {
  return produce(state, (draft: AdminState) => {
    draft.getAdminDetails = { ...getAdminDetailsInitialState };
    draft.getAdminDetails.isLoading = true;
  });
}

export function getAdminDetailsSuccessReducer(
  state: AdminState,
  data: GetAdminDetailsResponse
): AdminState {
  return produce(state, (draft: AdminState) => {
    draft.getAdminDetails.isLoaded = true;
    draft.getAdminDetails.isLoading = false;
    draft.getAdminDetails.isValid = true;
    draft.getAdminDetails.data = transformAdminDetails(data.data);
  });
}

export function getAdminDetailsFailureReducer(
  state: AdminState,
  error: ErrorResponse
): AdminState {
  return produce(state, (draft: AdminState) => {
    draft.getAdminDetails.isLoaded = true;
    draft.getAdminDetails.isLoading = false;
    draft.getAdminDetails.error = error;
  });
}

export const getAdminDetailsReducers = {
  [`${getAdminDetails}`]: getAdminDetailsReducer,
  [`${getAdminDetailsSuccess}`]: getAdminDetailsSuccessReducer,
  [`${getAdminDetailsFailure}`]: getAdminDetailsFailureReducer,
};
