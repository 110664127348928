import produce from 'immer';

import {
  getSurveyNotifications,
  getSurveyNotificationsFailure,
  getSurveyNotificationsSuccess,
  updateSurveyNotificationsQuery,
} from './actions';
import { transformSurveyNotifications } from '../../../transformers';
import { getSurveyNotificationsInitialState } from './state';
import { GetSurveyNotificationsResponse } from './type';
import { SurveyNotificationState } from '../type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getSurveyNotificationsReducer = (
  state: SurveyNotificationState
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotifications = { ...getSurveyNotificationsInitialState };
    draft.getSurveyNotifications.isLoading = true;
  });
};

export const getSurveyNotificationsSuccessReducer = (
  state: SurveyNotificationState,
  data: GetSurveyNotificationsResponse
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotifications.isLoading = false;
    draft.getSurveyNotifications.isLoaded = true;
    draft.getSurveyNotifications.isValid = true;
    draft.getSurveyNotifications.count = data.count;
    draft.getSurveyNotifications.data = transformSurveyNotifications(data.data);
  });
};

export const getSurveyNotificationsFailureReducer = (
  state: SurveyNotificationState,
  error: ErrorResponse
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotifications.isLoading = false;
    draft.getSurveyNotifications.isLoaded = true;
    draft.getSurveyNotifications.error = error;
  });
};

export const updateSurveyNotificationsQueryReducers = (
  state: SurveyNotificationState,
  query: Query
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotifications.query = query;
  });
};

export const getSurveyNotificationsReducers = {
  [`${getSurveyNotifications}`]: getSurveyNotificationsReducer,
  [`${getSurveyNotificationsSuccess}`]: getSurveyNotificationsSuccessReducer,
  [`${getSurveyNotificationsFailure}`]: getSurveyNotificationsFailureReducer,
  [`${updateSurveyNotificationsQuery}`]: updateSurveyNotificationsQueryReducers,
};
