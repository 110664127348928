export default {
  notifications: {
    success: {
      key: 'EmailInvites.inviteParticipants.notifications.success',
      default: 'Participants invited successfully.',
    },
  },
  titleNew: {
    key: 'EmailInvites.inviteParticipants.step2.title',
    default: 'Invite Message',
  },
  title: {
    key: 'EmailInvites.inviteParticipants.step2.title',
    default: 'Email Message',
  },
  confirmationButton: {
    key: 'EmailInvites.inviteParticipants.step2.confirmationButton',
    default: 'Save',
  },
  secondaryButton: {
    key: 'EmailInvites.inviteParticipants.step2.secondaryButton',
    default: 'Previous',
  },
  sectionTitles: {
    sender: {
      key: 'EmailInvites.inviteParticipants.step2.sectionTitles.sender',
      default: 'Sender details',
    },
    selectInvitationMessage: {
      key: 'EmailInvites.inviteParticipants.step2.sectionTitles.selectInvitationMessage',
      default: 'Choose invitation message',
    },
    invitationMessage: {
      key: 'EmailInvites.inviteParticipants.step2.sectionTitles.invitationMessage',
      default: 'Invitation message',
    },
  },
  fields: {
    senderEmail: {
      title: {
        key: 'EmailInvites.inviteParticipants.step2.fields.senderEmail.title',
        default: `Sender's email address`,
      },
    },
    senderName: {
      title: {
        key: 'EmailInvites.inviteParticipants.step2.fields.senderName.title',
        default: `Sender's name`,
      },
      placeHolder: {
        key: 'EmailInvites.inviteParticipants.step2.fields.senderName.placeHolder',
        default: `Enter Sender's name`,
      },
    },
    selectMessage: {
      title: {
        key: 'EmailInvites.inviteParticipants.step2.fields.selectMessage.title',
        default: 'Select your message template',
      },
      message: {
        key: '',
        default: '(You can personalise the message in the template chosen)',
      },
    },
    subject: {
      title: {
        key: 'EmailInvites.inviteParticipants.step2.fields.subject.title',
        default: 'Subject',
      },
      placeHolder: {
        key: 'EmailInvites.inviteParticipants.step2.fields.subject.placeHolder',
        default: 'Enter Subject',
      },
    },
    content: {
      title: {
        key: 'EmailInvites.inviteParticipants.step2.fields.content.title',
        default: 'Your email message',
      },
      placeHolder: {
        key: 'EmailInvites.inviteParticipants.step2.fields.content.placeHolder',
        default: 'Enter Content',
      },
    },
  },
  errors: {
    removedPlaceholder: {
      title: {
        key: 'EmailInvites.inviteParticipants.step2.errors.removedPlaceholder.title',
        default: 'Validation Error',
      },
      body: {
        key: 'EmailInvites.inviteParticipants.step2.errors.removedPlaceholder.body',
        default:
          'We can see you tried to make some changes or remove the {{SurveyLink}} place holder. ' +
          'This is where the survey link will go in your message so please do not modify it.',
      },
      primaryActionButton: {
        key: 'EmailInvites.inviteParticipants.step2.errors.removedPlaceholder.primaryActionButton',
        default: 'Ok, keep the placeholder',
      },
    },
  },
};
