import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useEffect } from 'react';

import {
  clearDeleteInviteMessage,
  clearManageInvite,
  deleteInviteMessage,
  getInvite,
  removeManageInviteReminder,
  showNotification,
} from 'store/actions';
import ConfirmationModal from 'element/Modal/components/confirmation';
import translations from './translations';
import { RootState } from 'store/type';
import { t } from 'lib/translation';
import { Message } from 'type';

interface DeleteReminderProps {
  collectorId: number;
  closeModal: () => void;
  reminder: Message;
}

const DeleteReminder = ({
  collectorId,
  closeModal,
  reminder,
}: DeleteReminderProps): ReactElement => {
  const dispatch = useDispatch();

  const inviteDetails = useSelector(
    (state: RootState) => state.emailInvites.manageInvite.data.inviteDetails
  );
  const deleteInviteMessageState = useSelector(
    (state: RootState) => state.emailInvites.deleteInviteMessage
  );

  const deleteReminderAction = () => {
    if (!reminder.id) {
      closeModal();
      return;
    }

    if (reminder.status === null) {
      dispatch(removeManageInviteReminder(reminder.id));
      closeModal();
    } else {
      dispatch(deleteInviteMessage(reminder.id));
    }
  };

  useEffect(() => {
    if (
      deleteInviteMessageState.isDeleted &&
      deleteInviteMessageState.isValid
    ) {
      dispatch(clearDeleteInviteMessage());
      dispatch(clearManageInvite());
      dispatch(
        getInvite({
          collectorId: collectorId,
          emailRecipientListId: inviteDetails.id,
        })
      );
      dispatch(showNotification(t(translations.updateSuccess)));
      closeModal();
    }
  }, [dispatch, deleteInviteMessageState]);

  return (
    <ConfirmationModal
      id={'delete-reminder-confirmation'}
      cancelCallback={closeModal}
      title={t(translations.title)}
      confirmedCallback={deleteReminderAction}
      confirmButtonText={t(translations.confirmationButton)}
      isUpdating={deleteInviteMessageState.isDeleting}
    >
      <>{t(translations.content)}</>
    </ConfirmationModal>
  );
};

export default DeleteReminder;
