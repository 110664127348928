import map from 'lodash/map';

import { transformFilterValues } from './filterValues';
import { APIFilter, Filter } from 'type';

export const transformFilter = (filter: APIFilter): Filter => {
  return {
    id: filter.id,
    name: filter.name,
    valuesCount: filter.values_count,
    values: filter.values && transformFilterValues(filter.values),
  };
};

export const transformFilters = (filters: APIFilter[]): Filter[] => {
  return map(filters, transformFilter);
};
