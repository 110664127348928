import {
  Button,
  Card,
  Col,
  Divider,
  FormItem,
  Row,
  EditIcon,
} from 'elmo-elements';
import React from 'react';

import translations from './translations';
import { SummaryCardProps } from './type';
import { t } from 'lib/translation';
import './style.scss';

const summaryCard = ({
  id,
  sectionTitle,
  icon,
  children,
  hasPrimaryAction = true,
  primaryActionOnClick,
  hasSecondaryAction = false,
  secondaryActionLabel = '',
  secondaryActionIcon = undefined,
  secondaryActionOnClick,
}: SummaryCardProps) => {
  const secondaryActionButtons = () => {
    if (!hasSecondaryAction) {
      return <></>;
    }

    return (
      <Button
        id={`${id}-secondary-action`}
        icon={secondaryActionIcon}
        isUppercase={false}
        className={'secondary-action text-button'}
        onClick={secondaryActionOnClick}
      >
        {secondaryActionLabel}
      </Button>
    );
  };

  return (
    <>
      <Card id={id} className={'summary-card'}>
        <FormItem className={'card-title'} label={sectionTitle} icon={icon}>
          {children}
        </FormItem>

        {(hasPrimaryAction || hasSecondaryAction) && (
          <div className={'card-footer'}>
            <div className="pb-1">
              <Divider />
            </div>

            <Row
              className="align-content-between"
              wrap="nowrap"
              isNoGutters={true}
            >
              <Col grow={0}>
                {hasPrimaryAction && (
                  <Button
                    id={`${id}-primary-action`}
                    icon={<EditIcon />}
                    isUppercase={false}
                    className={'text-button'}
                    onClick={primaryActionOnClick}
                  >
                    {t(translations.primaryActionButton)}
                  </Button>
                )}
              </Col>
              <Col isAuto={true}>{secondaryActionButtons()}</Col>
            </Row>
          </div>
        )}
      </Card>
    </>
  );
};

export default summaryCard;
