import { RouteObject } from 'react-router-dom';
import React from 'react';

import FilterValuesList from './pages/FilterValuesList';
import FiltersList from './pages/FiltersList';

const FilterRoutes: RouteObject = {
  path: '/filters-library',
  children: [
    {
      path: '/filters-library/:filterId/values',
      element: <FilterValuesList />,
    },
    {
      path: '/filters-library',
      element: <FiltersList />,
    },
  ],
};

export default FilterRoutes;
