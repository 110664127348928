import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createSurveyNotificationFailure,
  createSurveyNotificationSuccess,
} from './actions';
import { CreateSurveyNotificationPayload } from './type';
import { createSurveyNotificationApi } from './api';
import { SagaAction } from 'type';

export const createSurveyNotificationSaga = function* (
  action: SagaAction<CreateSurveyNotificationPayload>
): SagaIterator {
  try {
    const data = yield call(createSurveyNotificationApi(action.payload));

    yield put(createSurveyNotificationSuccess(data));
  } catch (error) {
    yield put(createSurveyNotificationFailure(error));
  }
};
