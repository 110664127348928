import { GetBulkUrlPayload } from './type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/bulkurl-collector/:collectorId/batches/:batchId';

export const getBulkUrlApi = (payload: GetBulkUrlPayload): any => {
  return getRequest(
    getRoute(apiUrl, {
      params: {
        collectorId: payload.collectorId,
        batchId: payload.batchId,
      },
    })
  );
};
