import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { addCollectorFailure, addCollectorSuccess } from './actions';
import { AddCollectorPayload } from './type';
import { addCollectorApi } from './api';
import { SagaAction } from 'type';

export const addCollectorSaga = function* (
  action: SagaAction<AddCollectorPayload>
): SagaIterator {
  try {
    const data = yield call(addCollectorApi(action.payload));

    yield put(addCollectorSuccess(data));
  } catch (error) {
    yield put(addCollectorFailure(error));
  }
};
