import React from 'react';
import classNames from 'classnames';
import Spinner from '../Spinner';
import './style.scss';
var LoadingOverlay = function (_a) {
    var isLoading = _a.isLoading, children = _a.children, showSpinner = _a.showSpinner, ariaLabel = _a.ariaLabel, ariaLive = _a.ariaLive, tabIndex = _a.tabIndex;
    return (React.createElement("div", { className: "elmo-loadingoverlay", role: isLoading ? 'progressbar' : undefined, "aria-busy": isLoading, "aria-label": ariaLabel, "aria-live": ariaLive, tabIndex: tabIndex },
        showSpinner && isLoading && (React.createElement("div", { className: classNames('elmo-loadingoverlay__overlay', {
                'is-loading': isLoading,
            }) },
            React.createElement(Spinner, null))),
        React.createElement("div", { className: classNames('elmo-loadingoverlay__content', {
                'is-loading': isLoading,
            }) }, children)));
};
LoadingOverlay.defaultProps = {
    showSpinner: true,
    ariaLabel: 'Loading overlay',
    ariaLive: 'assertive',
    tabIndex: 0,
};
export default LoadingOverlay;
