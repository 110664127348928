import { createReducer } from 'redux-act';

import { getAssociatedFiltersReducers } from './getAssociatedFilters/reducers';
import { associateFiltersReducers } from './associateFilters/reducers';
import { getFilterValuesReducers } from './getFilterValues/reducers';
import { createFilterReducers } from './createFilter/reducers';
import { updateFilterReducers } from './updateFilter/reducers';
import { getFiltersReducers } from './getFilters/reducers';
import { getFilterReducers } from './getFilter/reducers';
import { filterInitialState } from './state';

export default createReducer(
  {
    ...createFilterReducers,
    ...getFilterReducers,
    ...getFiltersReducers,
    ...getFilterValuesReducers,
    ...updateFilterReducers,
    ...getAssociatedFiltersReducers,
    ...associateFiltersReducers,
  },
  filterInitialState
);
