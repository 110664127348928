import produce from 'immer';

import {
  clearGetOverviewReportFilterOptions,
  getOverviewReportFilterOptions,
  getOverviewReportFilterOptionsFailure,
  getOverviewReportFilterOptionsSuccess,
} from './actions';
import { getOverviewReportFilterOptionsInitialState } from './state';
import { OverviewReportState } from '../type';
import { ErrorResponse } from 'type';

export function getOverviewReportFilterOptionsReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getOverviewReportFilterOptions.isLoading = true;
    draft.getOverviewReportFilterOptions.isLoaded = false;
    draft.getOverviewReportFilterOptions.isValid = false;
    draft.getOverviewReportFilterOptions.associatedFiltersOptions = [];
    draft.getOverviewReportFilterOptions.departmentOptions = [];
    draft.getOverviewReportFilterOptions.locationOptions = [];
    draft.getOverviewReportFilterOptions.managerOptions = [];
    draft.getOverviewReportFilterOptions.positionOptions = [];
    draft.getOverviewReportFilterOptions.lockedFilters = 0;
  });
}

export function getOverviewReportFilterOptionsSuccessReducer(
  state: OverviewReportState,
  data: any
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getOverviewReportFilterOptions.isLoading = false;
    draft.getOverviewReportFilterOptions.isLoaded = true;
    draft.getOverviewReportFilterOptions.isValid = true;
    draft.getOverviewReportFilterOptions.associatedFiltersOptions =
      data.data.associatedFilters;
    draft.getOverviewReportFilterOptions.departmentOptions =
      data.data.departments;
    draft.getOverviewReportFilterOptions.locationOptions = data.data.locations;
    draft.getOverviewReportFilterOptions.managerOptions = data.data.managers;
    draft.getOverviewReportFilterOptions.positionOptions = data.data.positions;
    draft.getOverviewReportFilterOptions.lockedFilters =
      data.data.lockedFilters;
  });
}

export function getOverviewReportFilterOptionsFailureReducer(
  state: OverviewReportState,
  error: ErrorResponse
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getOverviewReportFilterOptions.isLoading = false;
    draft.getOverviewReportFilterOptions.isLoaded = true;
    draft.getOverviewReportFilterOptions.error = error;
  });
}

export function clearGetOverviewReportFilterOptionsReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getOverviewReportFilterOptions =
      getOverviewReportFilterOptionsInitialState;
  });
}

export const getOverviewReportFilterOptionsReducers = {
  [`${getOverviewReportFilterOptions}`]: getOverviewReportFilterOptionsReducer,
  [`${getOverviewReportFilterOptionsSuccess}`]:
    getOverviewReportFilterOptionsSuccessReducer,
  [`${getOverviewReportFilterOptionsFailure}`]:
    getOverviewReportFilterOptionsFailureReducer,
  [`${clearGetOverviewReportFilterOptions}`]:
    clearGetOverviewReportFilterOptionsReducer,
};
