import { createReducer } from 'redux-act';

import { getCollectorUsageReducers } from './getCollectorUsage/reducers';
import { updateCollectorReducers } from './updateCollector/reducers';
import { getCollectorsReducers } from './getCollectors/reducers';
import { getCollectorReducers } from './getCollector/reducers';
import { addCollectorReducers } from './addCollector/reducers';
import { collectorInitialState } from './state';

export default createReducer(
  {
    ...addCollectorReducers,
    ...getCollectorReducers,
    ...getCollectorsReducers,
    ...getCollectorUsageReducers,
    ...updateCollectorReducers,
  },
  collectorInitialState
);
