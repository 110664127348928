import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getInviteMessageTemplates,
  clearGetInviteMessageTemplates,
} from 'store/actions';
import { getDefaultInviteSender } from 'lib/sharedState/actions';
import { analytics, AnalyticsEventNames } from 'lib';
import ManageInvite from './components/manage';
import View from './view';

const MessagesIndex = (): ReactElement => {
  const dispatch = useDispatch();

  const { surveyId, collectorId } = useParams() as unknown as {
    surveyId: string;
    collectorId: number;
  };

  const messagesState = useSelector(
    (state: any) => state.emailInvites.getInviteMessageTemplates
  );
  const defaultInviteSenderState = useSelector(
    (state: any) => state.sharedState.getDefaultInviteSender
  );
  const collector = useSelector(
    (state: any) => state.collector.getCollector.collector
  );
  const [currentModal, changeCurrentModal] = useState('');
  const [currentMessage, changeCurrentMessage] = useState<any>({});

  const showModal = (modal: string, message: any) => {
    changeCurrentModal(modal);
    changeCurrentMessage(message);
  };

  const closeModal = () => {
    changeCurrentModal('');
    changeCurrentMessage({});
  };

  const renderCurrentModal = () => {
    if (currentModal === 'editInvite') {
      analytics.sendEvent(
        AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_EDIT_MESSAGE_BUTTON_CLICKED,
        {
          surveyId: collector.survey.id,
          surveyTitle: collector.survey.title,
          collectorId: collector.id,
          surveyStatus: collector.survey.status,
          collectorType: collector.type,
          collectorTitle: collector.title,
          messageTitle: currentMessage.title,
          messageId: currentMessage.id,
          subject: currentMessage.subject,
          content: currentMessage.message,
        }
      );

      return (
        <ManageInvite
          closeModal={closeModal}
          collectorId={collectorId}
          message={currentMessage}
        />
      );
    } else if (currentModal === 'AddInviteMessage') {
      analytics.sendEvent(
        AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_ADD_MESSAGE_BUTTON_CLICKED,
        {
          surveyId: collector.survey.id,
          surveyTitle: collector.survey.title,
          collectorId: collector.id,
          surveyStatus: collector.survey.status,
          collectorType: collector.type,
          collectorTitle: collector.title,
        }
      );

      return (
        <ManageInvite
          closeModal={closeModal}
          collectorId={collectorId}
          message={{
            ...defaultInviteSenderState.data,
            title: '',
            subject: '',
            message:
              '<p>Hello,</p><br><p>We would love your valuable feedback and input to help us ' +
              'work better.</p><br><p>Please click the link below to participate and have your ' +
              'say.</p><br><div>{{SurveyLink}}</div><br>',
          }}
          isNew={true}
        />
      );
    }

    return <></>;
  };

  useEffect(() => {
    dispatch(clearGetInviteMessageTemplates());
    dispatch(getInviteMessageTemplates(collectorId));
  }, [collectorId, dispatch]);

  useEffect(() => {
    if (messagesState.isLoaded && messagesState.data[0]) {
      analytics.sendEvent(
        AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_MESSAGES_TAB_VIEWED,
        {
          subject: messagesState.data[0].subject,
          content: messagesState.data[0].message,
          title: messagesState.data[0].title,
        }
      );
    }
  }, [messagesState, collectorId]);

  useEffect(() => {
    if (
      !defaultInviteSenderState.isLoaded &&
      !defaultInviteSenderState.isLoading
    ) {
      dispatch(getDefaultInviteSender());
    }
  }, [defaultInviteSenderState, dispatch]);

  return (
    <View
      messagesState={messagesState}
      surveyId={surveyId}
      collectorId={collectorId}
      showModal={showModal}
      currentModalRender={renderCurrentModal()}
    />
  );
};

export default MessagesIndex;
