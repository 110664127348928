import { takeEvery } from 'redux-saga/effects';

import {
  getCompanyUsers,
  getUserProfileFilterOptions,
  getUsers,
} from './actions';
import { getUserProfileFilterOptionsSaga } from './getUserProfileFilterOptions/sagas';
import { getCompanyUsersSaga } from './getCompanyUsers/sagas';
import { getUsersSaga } from './getUsers/sagas';

export default function* sagas() {
  yield takeEvery(getUsers, getUsersSaga);
  yield takeEvery(getCompanyUsers, getCompanyUsersSaga);
  yield takeEvery(getUserProfileFilterOptions, getUserProfileFilterOptionsSaga);
}
