var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Badge from '../../Badge';
import './ButtonBadge.scss';
import { abbreviateNumber } from '../../_lib/helper';
function ButtonBadge(_a) {
    var badge = _a.badge, id = _a.id;
    var returnButtonBadge = React.createElement(React.Fragment, null);
    if ((typeof badge === 'number' && badge === 0) ||
        (typeof badge === 'object' && badge.children && badge.children === 0)) {
        return null;
    }
    if (typeof badge === 'number' || typeof badge === 'string') {
        if (typeof badge === 'number') {
            badge = abbreviateNumber(badge);
        }
        returnButtonBadge = React.createElement(Badge, { type: 'info' }, badge);
    }
    else {
        if (badge.children && typeof badge.children === 'number') {
            badge.children = abbreviateNumber(badge.children);
        }
        returnButtonBadge = React.createElement(Badge, __assign({}, badge));
    }
    return (React.createElement("div", { className: "elmo-btn__badge", "data-testid": "elmo-btn__badge-" + (id || 'default') }, returnButtonBadge));
}
export default ButtonBadge;
