import produce from 'immer';

import {
  clearInviteParticipants,
  inviteParticipants,
  inviteParticipantsFailure,
  inviteParticipantsSuccess,
} from './actions';
import { inviteParticipantsInitialState } from './state';
import { InvitesState } from '../type';

export function inviteParticipantsReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.inviteParticipants.isLoaded = false;
    draft.inviteParticipants.isLoading = true;
    draft.inviteParticipants.isValid = false;
  });
}

export function inviteParticipantsSuccessReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.inviteParticipants.isLoaded = true;
    draft.inviteParticipants.isLoading = false;
    draft.inviteParticipants.isValid = true;
  });
}

export function inviteParticipantsFailureReducer(
  state: InvitesState,
  error: any
) {
  return produce(state, (draft) => {
    draft.inviteParticipants.isLoaded = true;
    draft.inviteParticipants.isLoading = false;
    draft.inviteParticipants.error = error;
  });
}

export function clearInviteParticipantsReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.inviteParticipants = inviteParticipantsInitialState;
  });
}

export const inviteParticipantsReducers = {
  [`${inviteParticipants}`]: inviteParticipantsReducer,
  [`${inviteParticipantsSuccess}`]: inviteParticipantsSuccessReducer,
  [`${inviteParticipantsFailure}`]: inviteParticipantsFailureReducer,
  [`${clearInviteParticipants}`]: clearInviteParticipantsReducer,
};
