const translations = {
  errorBoundary: {
    title: {
      key: '',
      default: 'Failed to load page',
    },
    body: {
      key: '',
      default:
        'We were unable to load this page. Please reload the page to try again',
    },
  },
  loadingText: {
    key: '',
    default: 'Loading...',
  },
};

export default translations;
