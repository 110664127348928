import produce from 'immer';

import {
  getCollectorUsage,
  getCollectorUsageSuccess,
  getCollectorUsageFailure,
  clearGetCollectorUsage,
} from './actions';
import { getCollectorUsageInitialState } from './state';
import { CollectorState } from '../type';
import { ErrorResponse } from 'type';

export function getCollectorUsageReducer(
  state: CollectorState
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollectorUsage = { ...getCollectorUsageInitialState };
    draft.getCollectorUsage.isLoading = true;
  });
}

export function getCollectorUsageSuccessReducer(
  state: CollectorState,
  data: any
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollectorUsage.isLoading = false;
    draft.getCollectorUsage.isLoaded = true;
    draft.getCollectorUsage.isValid = true;
    draft.getCollectorUsage.usage = data.data;
  });
}

export function getCollectorUsageFailureReducer(
  state: CollectorState,
  error: ErrorResponse
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollectorUsage.isLoading = false;
    draft.getCollectorUsage.isLoaded = true;
    draft.getCollectorUsage.error = error;
  });
}

export function clearGetCollectorUsageReducer(
  state: CollectorState
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollectorUsage = getCollectorUsageInitialState;
  });
}

export const getCollectorUsageReducers = {
  [`${getCollectorUsage}`]: getCollectorUsageReducer,
  [`${getCollectorUsageSuccess}`]: getCollectorUsageSuccessReducer,
  [`${getCollectorUsageFailure}`]: getCollectorUsageFailureReducer,
  [`${clearGetCollectorUsage}`]: clearGetCollectorUsageReducer,
};
