import { createReducer } from 'redux-act';

import { getAnonSurveyThemeReducers } from './getAnonSurveyTheme/reducers';
import { getDefaultThemeReducers } from './getDefaultTheme/reducers';
import { setDefaultThemeReducers } from './setDefaultTheme/reducers';
import { createThemeReducers } from './createTheme/reducers';
import { updateThemeReducers } from './updateTheme/reducers';
import { deleteThemeReducers } from './deleteTheme/reducers';
import { getThemesReducers } from './getThemes/reducers';
import { getThemeReducers } from './getTheme/reducers';
import { themeInitialState } from './state';

export default createReducer(
  {
    ...getAnonSurveyThemeReducers,
    ...getDefaultThemeReducers,
    ...getThemesReducers,
    ...deleteThemeReducers,
    ...setDefaultThemeReducers,
    ...createThemeReducers,
    ...updateThemeReducers,
    ...getThemeReducers,
  },
  themeInitialState
);
