import produce from 'immer';

import {
  getCompanyUsers,
  getCompanyUsersFailure,
  getCompanyUsersSuccess,
  clearGetCompanyUsers,
} from './actions';
import { getCompanyUsersInitialState } from './state';
import { GetCompanyUsersResponse } from './type';
import { ErrorResponse } from 'type';
import { UserState } from '../type';

export function getCompanyUsersReducer(state: UserState): UserState {
  return produce(state, (draft) => {
    draft.getCompanyUsers = { ...getCompanyUsersInitialState };
    draft.getCompanyUsers.isLoading = true;
  });
}

export function getCompanyUsersSuccessReducer(
  state: UserState,
  data: GetCompanyUsersResponse
): UserState {
  return produce(state, (draft) => {
    draft.getCompanyUsers.isLoaded = true;
    draft.getCompanyUsers.isLoading = false;
    draft.getCompanyUsers.isValid = true;
    draft.getCompanyUsers.count = data.count;
    draft.getCompanyUsers.users = data.data;
  });
}

export function getCompanyUsersFailureReducer(
  state: UserState,
  error: ErrorResponse
): UserState {
  return produce(state, (draft) => {
    draft.getCompanyUsers.isLoaded = true;
    draft.getCompanyUsers.isLoading = false;
    draft.getCompanyUsers.error = error;
  });
}

export function clearGetCompanyUsersReducer(state: UserState): UserState {
  return produce(state, (draft) => {
    draft.getCompanyUsers = getCompanyUsersInitialState;
  });
}

export const getCompanyUsersReducers = {
  [`${getCompanyUsers}`]: getCompanyUsersReducer,
  [`${getCompanyUsersSuccess}`]: getCompanyUsersSuccessReducer,
  [`${getCompanyUsersFailure}`]: getCompanyUsersFailureReducer,
  [`${clearGetCompanyUsers}`]: clearGetCompanyUsersReducer,
};
