import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

export const getDefaultEmailTemplate: EmptyActionCreator = createAction(
  'GET_DEFAULT_EMAIL_TEMPLATE'
);

export const getDefaultEmailTemplateSuccess: ActionCreator<any> = createAction(
  'GET_DEFAULT_EMAIL_TEMPLATE_SUCCESS'
);

export const getDefaultEmailTemplateFailure: EmptyActionCreator = createAction(
  'GET_DEFAULT_EMAIL_TEMPLATE_FAILURE'
);
