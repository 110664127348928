import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import PageLoading from 'element/PageLoading';
import translations from './translations';
import { withDelay } from 'lib/util';
import { t } from 'lib/translation';

const PreviewSurvey = lazy(() => withDelay(() => import('./PreviewSurvey')));

const PreviewSurveySuspense = (): ReactElement => {
  return (
    <Suspense fallback={<PageLoading text={t(translations.loadingText)} />}>
      <PreviewSurvey />
    </Suspense>
  );
};

export default PreviewSurveySuspense;
