var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '../../_lib/style';
var elements = {
    h1: styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""]))),
    h2: styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""]))),
    h3: styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""]))),
    h4: styled.h4(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""]))),
    h5: styled.h5(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""]))),
    h6: styled.h6(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""]))),
};
var StyledHeading = function (htmlTag) {
    return elements[htmlTag || 'h3'];
};
export default StyledHeading;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
