import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';

const apiUrl = '/question-categories';

type GetQuestionCategoriesApiParams = {
  page: number;
  limit: number;
  'search-text'?: string;
  'search-field'?: string;
  'sort-field'?: string;
  'sort-type'?: 'asc' | 'desc';
};

type SortParams = Pick<
  GetQuestionCategoriesApiParams,
  'sort-field' | 'sort-type'
>;

export const createSortParams = (sort: Query['sort']): SortParams => {
  switch (sort) {
    case 'title':
    case '-title':
      return {
        'sort-field': 'title',
        'sort-type': sort === '-title' ? 'desc' : 'asc',
      };

    default:
      return {};
  }
};

export const getQuestionCategoriesApi = (query: Query): any => {
  const params: GetQuestionCategoriesApiParams = {
    page: query.page,
    limit: query.limit,
  };

  if (query.keyword) {
    params['search-text'] = query.keyword;
    params['search-field'] = 'category-title';
  }

  return getRequest(apiUrl, {
    params: { ...params, ...createSortParams(query.sort) },
  });
};
