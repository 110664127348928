import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

export const getSurvey: ActionCreator<number | string> =
  createAction('GET_SURVEY');

export const getSurveySuccess: ActionCreator<any> =
  createAction('GET_SURVEY_SUCCESS');

export const getSurveyFailure: EmptyActionCreator =
  createAction('GET_SURVEY_FAILURE');

export const clearGetSurvey: EmptyActionCreator =
  createAction('CLEAR_GET_SURVEY');
