import PeopleIcon from '@mui/icons-material/People';
import { FormItem } from 'elmo-elements';
import React from 'react';

import EmailInput from 'element/inputTags/components/emailInput';
import BaseModal from 'element/Modal/components/baseModal';
import translations from './translations';
import { ManualInputProp } from './type';
import { t } from 'lib/translation';
import './style.scss';

const ManualInput = ({
  recipientsState,
  updateRecipientsStateByField,
  previousStep,
  saveRecipients,
  isFlow,
  closeModal,
}: ManualInputProp) => {
  return (
    <BaseModal
      id={'invite-recipients-manual-input'}
      modalType={'x-large'}
      closeModal={closeModal}
      title={
        <>
          <PeopleIcon className={'header-icon'} />
          {t(translations.title)}
        </>
      }
      secondaryButtonText={t(translations.backButton)}
      secondaryButtonClick={previousStep}
      primaryButtonClick={saveRecipients}
      primaryButtonText={
        isFlow
          ? t(translations.primaryButton.saveAndContinue)
          : t(translations.primaryButton.saveAndClose)
      }
      primaryButtonIsDisabled={recipientsState.recipients.length === 0}
      buttonsType={'separate'}
    >
      <>
        <FormItem label={t(translations.recipientsField.title)}>
          <EmailInput
            values={recipientsState.recipients}
            onChangeAction={(tags: string[]) =>
              updateRecipientsStateByField('recipients', tags)
            }
          />
          <span id={'input-recipient-count'}>
            {t(
              translations.recipientsField.recipientsCount,
              {
                count: recipientsState.recipients.length,
              },
              translations.recipientsField.recipientsCountPlural
            )}
          </span>
        </FormItem>
      </>
    </BaseModal>
  );
};

export default ManualInput;
