import { Button, Heading, Row } from 'elmo-elements';
import React, { ReactElement } from 'react';

import { t } from 'lib/translation';
import './EmptyState.scss';

export interface EmptyStateProps {
  isVisible?: boolean;
  icon?: ReactElement;
  content: {
    header: {
      key: string;
      default: string;
    };
    body: {
      key: string;
      default: string;
    };
    primaryButtonText?: {
      key: string;
      default: string;
    };
  };
  hasPrimaryButton?: boolean;
  primaryButtonAction?: () => void;
}

const EmptyState = ({
  isVisible = true,
  icon,
  content,
  hasPrimaryButton = true,
  primaryButtonAction,
}: EmptyStateProps): ReactElement => {
  if (!isVisible) {
    return <></>;
  }

  return (
    <div id={'empty-state'} data-testid={'empty-state'}>
      {icon && <Row justifyContent={'center'}>{icon}</Row>}
      <Row justifyContent={'center'}>
        <Heading id={'empty-state-heading'} htmlTag="h2" type="title" size="lg">
          {t(content.header)}
        </Heading>
      </Row>
      <Row justifyContent={'center'}>{t(content.body)}</Row>
      {hasPrimaryButton && (
        <Row justifyContent={'center'}>
          <Button
            id={'empty-state-primary-action'}
            type="primary"
            onClick={primaryButtonAction}
          >
            {t(content.primaryButtonText)}
          </Button>
        </Row>
      )}
    </div>
  );
};

export default EmptyState;
