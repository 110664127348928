import produce from 'immer';

import {
  cloneSurvey,
  cloneSurveySuccess,
  cloneSurveyFailure,
  clearCloneSurvey,
} from './actions';
import { cloneSurveyInitialState } from './state';
import { SurveyState } from '../type';
import { ErrorResponse } from 'type';

export function cloneSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.cloneSurvey.isLoading = true;
    draft.cloneSurvey.isLoaded = false;
    draft.cloneSurvey.isValid = false;
    draft.cloneSurvey.error = undefined;
  });
}

export function cloneSurveySuccessReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.cloneSurvey.isLoading = false;
    draft.cloneSurvey.isLoaded = true;
    draft.cloneSurvey.isValid = true;
  });
}

export function cloneSurveyFailureReducer(
  state: SurveyState,
  error: ErrorResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.cloneSurvey.isLoading = false;
    draft.cloneSurvey.isLoaded = true;
    draft.cloneSurvey.error = error;
  });
}

export function clearCloneSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.cloneSurvey = cloneSurveyInitialState;
  });
}

export const cloneSurveyReducers = {
  [`${cloneSurvey}`]: cloneSurveyReducer,
  [`${cloneSurveySuccess}`]: cloneSurveySuccessReducer,
  [`${cloneSurveyFailure}`]: cloneSurveyFailureReducer,
  [`${clearCloneSurvey}`]: clearCloneSurveyReducer,
};
