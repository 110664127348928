import React, { Suspense as ReactSuspense, ReactNode } from 'react';

import PageLoading from 'element/PageLoading';
import { ErrorBoundary } from './components';
import translations from './translations';
import { t } from 'lib/translation';

export interface SuspenseProps {
  children: ReactNode;
  fallback?: ReactNode;
}

const Suspense = ({ children, fallback: Fallback }: SuspenseProps) => {
  return (
    <ErrorBoundary>
      <ReactSuspense
        fallback={
          Fallback || <PageLoading text={t(translations.loadingText)} />
        }
      >
        {children}
      </ReactSuspense>
    </ErrorBoundary>
  );
};

export default Suspense;
