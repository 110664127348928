import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { LoadSavedOverviewReportPayload } from '../loadSavedOverviewReport/type';
import { ErrorResponse } from 'type';

export const getSavedOverviewReport: ActionCreator<LoadSavedOverviewReportPayload> =
  createAction('GET_SAVED_OVERVIEW_REPORT');

export const getSavedOverviewReportSuccess: ActionCreator<any> = createAction(
  'GET_SAVED_OVERVIEW_REPORT_SUCCESS'
);

export const getSavedOverviewReportFailure: ActionCreator<ErrorResponse> =
  createAction('GET_SAVED_OVERVIEW_REPORT_FAILURE');

export const clearGetSavedOverviewReport: EmptyActionCreator = createAction(
  'CLEAR_GET_SAVED_OVERVIEW_REPORT'
);
