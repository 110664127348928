import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const filterRoute = '/filters/:filterId';

export const getFilterApi = (filterId: number) => {
  return getRequest(
    getRoute(filterRoute, {
      params: { filterId: filterId },
    })
  );
};
