import produce from 'immer';

import {
  getEmailTemplates,
  getEmailTemplatesFailure,
  getEmailTemplatesSuccess,
  clearEmailTemplates,
  updateGetEmailTemplatesQuery,
} from './actions';
import { transformEmailsTemplates } from '../../../transformers';
import { getEmailTemplatesInitialState } from './state';
import { GetEmailTemplatesResponse } from './type';
import { EmailTemplateState } from '../type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export function getEmailTemplatesReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getEmailTemplates.isLoading = true;
    draft.getEmailTemplates.isLoaded = false;
    draft.getEmailTemplates.isValid = false;
    draft.getEmailTemplates.count = 0;
    draft.getEmailTemplates.data = [];
  });
}

export function getEmailTemplatesSuccessReducer(
  state: EmailTemplateState,
  data: GetEmailTemplatesResponse
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getEmailTemplates.isLoading = false;
    draft.getEmailTemplates.isLoaded = true;
    draft.getEmailTemplates.isValid = true;
    draft.getEmailTemplates.count = data.data.count;
    draft.getEmailTemplates.data = transformEmailsTemplates(
      data.data.email_templates
    );
  });
}

export function getEmailTemplatesFailureReducer(
  state: EmailTemplateState,
  errors: ErrorResponse
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getEmailTemplates.isLoading = false;
    draft.getEmailTemplates.isLoaded = true;
    draft.getEmailTemplates.errors = errors;
  });
}

export function clearGetEmailTemplatesReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getEmailTemplates = getEmailTemplatesInitialState;
  });
}

export function updateGetEmailTemplatesQueryReducer(
  state: EmailTemplateState,
  query: Query
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getEmailTemplates.query = query;
  });
}

export const getEmailTemplatesReducers = {
  [`${getEmailTemplates}`]: getEmailTemplatesReducer,
  [`${getEmailTemplatesSuccess}`]: getEmailTemplatesSuccessReducer,
  [`${getEmailTemplatesFailure}`]: getEmailTemplatesFailureReducer,
  [`${clearEmailTemplates}`]: clearGetEmailTemplatesReducer,
  [`${updateGetEmailTemplatesQuery}`]: updateGetEmailTemplatesQueryReducer,
};
