import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

export const deleteInviteMessage: ActionCreator<number> = createAction(
  'DELETE_INVITE_MESSAGE'
);

export const deleteInviteMessageSuccess: EmptyActionCreator = createAction(
  'DELETE_INVITE_MESSAGE_SUCCESS'
);

export const deleteInviteMessageFailure: EmptyActionCreator = createAction(
  'DELETE_INVITE_MESSAGE_FAILURE'
);

export const clearDeleteInviteMessage: EmptyActionCreator = createAction(
  'CLEAR_DELETE_INVITE_MESSAGE'
);
