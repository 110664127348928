import React from 'react';
import './DropdownDivider.scss';
import { getClass, noop } from '../../_lib/helper';
function DropdownDivider(_a) {
    var type = _a.type;
    return (React.createElement("hr", { className: getClass('elmo-dropdown__divider', '', {
            short: type === 'short',
        }), 
        // noop required to prevent DropdownItem click event from triggering on Chrome device emulator
        onClick: noop }));
}
export default DropdownDivider;
