import React from 'react';
import StyledContainer from './style';
function Container(_a) {
    var children = _a.children, isFluid = _a.isFluid, size = _a.size, role = _a.role;
    return (React.createElement(StyledContainer, { isFluid: isFluid, size: size, role: role }, children));
}
Container.defaultProps = {
    isFluid: false,
};
export default Container;
