import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getFilterFailure, getFilterSuccess } from './actions';
import { getFilterApi } from './api';
import { SagaAction } from 'type';

export const getFilterSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const data = yield call(getFilterApi(action.payload));

    yield put(getFilterSuccess(data));
  } catch (errors) {
    yield put(getFilterFailure(errors));
  }
};
