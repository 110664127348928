import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getSurveyAccessListFailure,
  getSurveyAccessListSuccess,
} from './actions';
import { getSurveyAccessListApi } from './api';
import { SagaAction } from 'type';

export const getSurveyAccessListSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const data = yield call(getSurveyAccessListApi(action.payload));

    yield put(getSurveyAccessListSuccess(data));
  } catch (error) {
    yield put(getSurveyAccessListFailure(error));
  }
};
