import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  closeIndividualLinkFailure,
  closeIndividualLinkSuccess,
} from './actions';
import { CloseIndividualLinkRequest } from './type';
import { closeIndividualLinkApi } from './api';
import { SagaAction } from 'type';

export const closeIndividualLinkSaga = function* (
  action: SagaAction<CloseIndividualLinkRequest>
): SagaIterator {
  try {
    yield call(closeIndividualLinkApi(action.payload));

    yield put(closeIndividualLinkSuccess());
  } catch (error) {
    yield put(closeIndividualLinkFailure(error));
  }
};
