import { AnonTheme, APIAnonTheme, APITheme, Theme } from 'type';

export const transformAnonTheme = (apiTheme: APIAnonTheme): AnonTheme => {
  return {
    buttonColour: apiTheme.button_colour,
    footerBackgroundColor: apiTheme.footer_background_color,
    footerDescription: apiTheme.footer_description ?? '',
    footerFontColor: apiTheme.footer_font_color,
    footerLogoName: apiTheme.footer_logo_name,
    footerLogoUrl: apiTheme.footer_logo_url,
    headingBannerBackgroundColour: apiTheme.heading_banner_background_colour,
    headingBannerFontColour: apiTheme.heading_banner_font_colour,
    logoName: apiTheme.logo_name,
    logoUrl: apiTheme.logo_url,
    textFontColour: apiTheme.text_font_colour,
  };
};

export const transformTheme = (apiTheme: APITheme): Theme => {
  return {
    ...transformAnonTheme(apiTheme),
    id: apiTheme.id,
    description: apiTheme.description ?? '',
    isDefault: apiTheme.is_default,
    title: apiTheme.title,
  };
};

export const transformThemes = (apiThemes: APITheme[]): Theme[] => {
  return apiThemes.map(transformTheme);
};

export const transformAnonThemeBack = (theme: AnonTheme): APIAnonTheme => {
  return {
    button_colour: theme.buttonColour,
    footer_background_color: theme.footerBackgroundColor,
    footer_description: theme.footerDescription,
    footer_font_color: theme.footerFontColor,
    footer_logo_name: theme.footerLogoName,
    footer_logo_url: theme.footerLogoUrl,
    heading_banner_background_colour: theme.headingBannerBackgroundColour,
    heading_banner_font_colour: theme.headingBannerFontColour,
    logo_name: theme.logoName,
    logo_url: theme.logoUrl,
    text_font_colour: theme.textFontColour,
  };
};

export const transformThemeBack = (theme: Theme): APITheme => {
  return {
    ...transformAnonThemeBack(theme),
    id: theme.id,
    description: theme.description,
    is_default: theme.isDefault,
    title: theme.title,
  };
};
