var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Children, Component } from 'react';
import { childrenIsEqual, getClass } from '../../_lib/helper';
import { withDataTableContext } from '../DataTableContextConsumer';
import './DataTableBody.scss';
/**
 * DataTableBody should contain the DataTable.Tr Row components
 */
var DataTableBody = /** @class */ (function (_super) {
    __extends(DataTableBody, _super);
    function DataTableBody() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DataTableBody.prototype.componentDidMount = function () {
        var _a = this.props, setContextState = _a.setContextState, children = _a.children;
        if (setContextState) {
            setContextState({
                rowCount: Children.count(children),
            });
        }
    };
    DataTableBody.prototype.componentDidUpdate = function (prevProps) {
        var children = this.props.children;
        if (!childrenIsEqual(children, prevProps.children)) {
            this.props.updateSelectAllCheckboxState();
        }
    };
    DataTableBody.prototype.renderContent = function () {
        var _this = this;
        var children = this.props.children;
        return Children.map(children, function (child, rowIndex) {
            return _this.addRowProps(child, rowIndex);
        });
    };
    /**
     * Attaches props that the body knows about to the row components
     * @param child
     * @param rowIndex
     */
    DataTableBody.prototype.addRowProps = function (child, rowIndex) {
        return React.cloneElement(child, {
            rowIndex: rowIndex,
        });
    };
    DataTableBody.prototype.render = function () {
        var className = this.props.className;
        return (React.createElement("tbody", { className: getClass('elmo-datatable__body', className) }, this.renderContent()));
    };
    DataTableBody.displayName = 'DataTableBody';
    return DataTableBody;
}(Component));
export default withDataTableContext(DataTableBody);
