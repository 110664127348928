import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { createFilterFailure, createFilterSuccess } from './actions';
import { CreateFilterPayload } from './type';
import { createFilterApi } from './api';
import { SagaAction } from 'type';

export const createFilterSaga = function* (
  action: SagaAction<CreateFilterPayload>
): SagaIterator {
  try {
    yield call(createFilterApi(action.payload));

    yield put(createFilterSuccess());
  } catch (errors) {
    yield put(createFilterFailure(errors));
  }
};
