import { UpdateQuestionCategoryPayload } from './type';
import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const globalUrl = '/admin/question-categories/:questionCategoryId';
const localUrl = '/question-categories/:questionCategoryId';

export const updateQuestionCategoryApi = (
  payload: UpdateQuestionCategoryPayload
) => {
  const url = payload.isGlobal ? globalUrl : localUrl;

  return putRequest(
    getRoute(url, {
      params: { questionCategoryId: payload.questionCategoryId },
    }),
    payload.data
  );
};
