import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { generateBulkUrlsFailure, generateBulkUrlsSuccess } from './actions';
import { getBulkUrls } from '../getBulkUrls/actions';
import { GenerateBulkUrlsPayload } from './type';
import { generateBulkUrlsApi } from './api';
import { SagaAction } from 'type';

export const generateBulkUrlsSaga = function* (
  action: SagaAction<GenerateBulkUrlsPayload>
): SagaIterator {
  try {
    const data = yield call(generateBulkUrlsApi(action.payload));

    if (data.error && data.error === 'timeout') {
      yield put(
        getBulkUrls({
          collectorId: action.payload.collectorId,
          query: { page: 1, limit: 1 },
        })
      );

      return;
    }

    yield put(generateBulkUrlsSuccess(data));
  } catch (error) {
    yield put(generateBulkUrlsFailure(error));
  }
};
