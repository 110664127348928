import React from 'react';
import { getClass } from '../../_lib/helper';
import Button from '../../Button';
import './PaginationItem.scss';
function PaginationItem(_a) {
    var ariaLabel = _a.ariaLabel, onClick = _a.onClick, isDisabled = _a.isDisabled, icon = _a.icon;
    var className = getClass('elmo-pagination__item', { disabled: isDisabled });
    return (React.createElement(Button, { type: "default", isDisabled: isDisabled, onClick: isDisabled ? undefined : onClick, icon: icon, className: className, ariaLabel: ariaLabel, isText: true, isCircular: true }));
}
export default PaginationItem;
