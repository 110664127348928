import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { uploadBulkUrlCSVFailure, uploadBulkUrlCSVSuccess } from './actions';
import { UploadBulkUrlCSVPayload } from './type';
import { uploadBulkUrlCSVApi } from './api';
import { SagaAction } from 'type';

export const uploadBulkUrlCSVSaga = function* (
  action: SagaAction<UploadBulkUrlCSVPayload>
): SagaIterator {
  try {
    const data = yield call(uploadBulkUrlCSVApi(action.payload));

    yield put(uploadBulkUrlCSVSuccess(data));
  } catch (error) {
    yield put(uploadBulkUrlCSVFailure(error));
  }
};
