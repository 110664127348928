const translations = {
  header: {
    subHeading: {
      key: '',
      default: 'Collector Invites',
    },
    exportButton: {
      key: '',
      default: 'Export CSV',
    },
  },
  bodyActions: {
    addButton: {
      key: '',
      default: 'Invite Participants',
    },
  },
  invitesTable: {
    columns: {
      title: {
        key: '',
        default: 'Invite List name',
      },
      addedBy: {
        key: '',
        default: 'Added by',
      },
      addedDate: {
        key: '',
        default: 'Added Date',
      },
      sendDate: {
        key: '',
        default: 'Send date',
      },
      reminders: {
        key: '',
        default: 'No. Reminders',
      },
      responseRate: {
        key: '',
        default: 'Resp. Rate',
      },
    },
    notifications: {
      noPermissionInviteSent: {
        key: '',
        default: 'Invite has been sent. Please refresh the page.',
      },
    },
    actions: {
      viewRecipients: {
        key: '',
        default: 'View Recipients',
      },
      manageInvite: {
        key: '',
        default: 'Manage Invite',
      },
      editSchedule: {
        key: '',
        default: 'Edit Schedule',
      },
      deleteInvite: {
        key: '',
        default: 'Delete Invite',
      },
    },
  },
  deleteInvite: {
    successMessage: {
      key: '',
      default: 'Invite deleted successfully.',
    },
    confirmButtonText: {
      key: '',
      default: 'Delete Invite',
    },
    modalTitle: {
      key: '',
      default: 'Delete Invite',
    },
    modalContent: {
      key: '',
      default: 'Are you sure you want to delete the invite list?',
    },
  },

  emptyState: {
    noContent: {
      header: {
        key: '',
        default: 'Invite your first participants',
      },
      body: {
        key: '',
        default:
          'Invite survey participants to have higher engagement within your organisation.',
      },
      primaryButtonText: {
        key: '',
        default: 'Invite Participants',
      },
    },
    noResults: {
      header: {
        key: '',
        default: 'No invites found',
      },
      body: {
        key: '',
        default: `Try adjusting your search to find the invite you're looking for.`,
      },
    },
  },
};

export default translations;
