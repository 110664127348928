import { UpdateSurveyAccessListRequest } from './type';
import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId/access';

export const updateSurveyAccessListApi = (
  payload: UpdateSurveyAccessListRequest
): any => {
  return putRequest(
    getRoute(apiUrl, { params: { surveyId: payload.surveyId } }),
    { userIds: payload.users }
  );
};
