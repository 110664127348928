import { Checkbox, FormItem, Search } from 'elmo-elements';
import { FixedSizeList as List } from 'react-window';
import React, { useEffect, useState } from 'react';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import { getFilteredUsers, getSearchUsers } from '../userFiltering';
import { RenderUserListProps } from './type';
import translations from './translations';
import { t } from 'lib/translation';
import './styles.scss';

const RenderUserList = ({
  filters,
  companyUsers,
  selectedUsers,
  updateSelectedUsers,
}: RenderUserListProps) => {
  const [searchValue, updateSearch] = useState<string>('');

  const filterUsers = getFilteredUsers(filters, companyUsers, selectedUsers);
  const searchUsers = getSearchUsers(searchValue.toLowerCase(), filterUsers);
  const isSearch = searchValue !== '';
  const filterUsersCount = filterUsers.length;
  const selectedUsersCount = selectedUsers.length;

  const updateRecipient = (event: any, data: any) => {
    if (event === 'selectAll') {
      updateSelectedUsers(data);
    } else if (event === 'deselectAll') {
      updateSelectedUsers([]);
    } else if (event.target.checked) {
      // Add User
      updateSelectedUsers([...selectedUsers, data.id]);
    } else {
      // Remove User
      const newUsers = remove([...selectedUsers], (userId: any) => {
        return !isEqual(data.id, userId);
      });

      updateSelectedUsers(newUsers);
    }
  };

  const renderSelectAll = () => {
    const isAllSelected =
      selectedUsersCount > 0 && filterUsersCount === selectedUsersCount;
    const isIndeterminate =
      selectedUsersCount > 0 && selectedUsersCount < filterUsersCount;

    return (
      <Checkbox
        id={'select-all'}
        label={t(translations.selectAll)}
        isChecked={isAllSelected}
        isIndeterminate={isIndeterminate}
        onChange={(event: any) => {
          if (event.target.checked) {
            updateRecipient('selectAll', map(filterUsers, 'id'));
          } else {
            updateRecipient('deselectAll', null);
          }
        }}
      />
    );
  };

  const renderRecipient = ({ index, style }: any) => {
    const user = searchUsers[index - (isSearch ? 0 : 1)];

    return (
      <div
        className={'list-item ' + (index % 2 ? 'odd' : 'even')}
        style={style}
      >
        {index === 0 && !isSearch ? (
          renderSelectAll()
        ) : (
          <Checkbox
            id={`user-${user.id}`}
            label={`${user.firstName} ${user.lastName}`}
            isChecked={includes(selectedUsers, user.id)}
            onChange={(e) => updateRecipient(e, user)}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    const newUsers = filter(selectedUsers, (userId: string) => {
      return find(filterUsers, ['id', userId]);
    });

    if (!isEqual(selectedUsers, newUsers)) {
      updateSelectedUsers(newUsers);
    }
  }, [filters, selectedUsers, filterUsers, updateSelectedUsers]);

  useEffect(() => {
    if (selectedUsersCount === 0) {
      updateSelectedUsers(map(filterUsers, 'id'));
    }
  }, []);

  return (
    <FormItem
      id={'recipients-list'}
      label={t(translations.recipients)}
      labelAddon={`${selectedUsersCount}/${filterUsersCount}`}
      className={``}
    >
      <Search
        isVisible={true}
        id={'filters-user-list-search'}
        placeholder={t(translations.search)}
        value={searchValue}
        onChange={updateSearch}
      />
      <List
        className="filters-users-list"
        height={380}
        itemCount={searchUsers.length + (isSearch ? 0 : 1)}
        itemSize={49}
        width={'100%'}
      >
        {renderRecipient}
      </List>
    </FormItem>
  );
};

export default RenderUserList;
