import { createAction } from 'redux-act';

export const loginSharedReport: any = createAction('LOGIN_SHARED_REPORT');

export const loginSharedReportSuccess: any = createAction(
  'LOGIN_SHARED_REPORT_SUCCESS'
);

export const loginSharedReportFailure: any = createAction(
  'LOGIN_SHARED_REPORT_FAILURE'
);
