import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetAdminBenchmarkDataResponse } from './type';
import { ErrorResponse } from 'type';

export const getAdminBenchmarkData: EmptyActionCreator = createAction(
  'GET_ADMIN_BENCHMARK_DATA'
);

export const getAdminBenchmarkDataSuccess: ActionCreator<GetAdminBenchmarkDataResponse> =
  createAction('GET_ADMIN_BENCHMARK_DATA_SUCCESS');

export const getAdminBenchmarkDataFailure: ActionCreator<ErrorResponse> =
  createAction('GET_ADMIN_BENCHMARK_DATA_FAILURE');
