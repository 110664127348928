import produce from 'immer';

import {
  clearDeleteTheme,
  deleteTheme,
  deleteThemeFailure,
  deleteThemeSuccess,
} from './actions';
import { themeInitialState } from '../state';
import { ThemeState } from '../type';

export function deleteThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.deleteTheme.isDeleted = false;
    draft.deleteTheme.isDeleting = true;
  });
}

export function deleteThemeSuccessReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.deleteTheme.isDeleted = true;
    draft.deleteTheme.isDeleting = false;
  });
}

export function deleteThemeFailureReducer(
  state: ThemeState,
  error: any
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.deleteTheme.isDeleted = true;
    draft.deleteTheme.isDeleting = false;
    draft.deleteTheme.error = error;
  });
}

export function clearDeleteThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.deleteTheme = themeInitialState.deleteTheme;
  });
}

export const deleteThemeReducers = {
  [`${deleteTheme}`]: deleteThemeReducer,
  [`${deleteThemeSuccess}`]: deleteThemeSuccessReducer,
  [`${deleteThemeFailure}`]: deleteThemeFailureReducer,
  [`${clearDeleteTheme}`]: clearDeleteThemeReducer,
};
