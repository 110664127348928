import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { RunSurveyPayload, RunSurveyResponse } from './type';

export const runSurvey: ActionCreator<RunSurveyPayload> =
  createAction('RUN_SURVEY');

export const runSurveySuccess: ActionCreator<RunSurveyResponse> =
  createAction('RUN_SURVEY_SUCCESS');

export const runSurveyFailure: EmptyActionCreator =
  createAction('RUN_SURVEY_FAILURE');
