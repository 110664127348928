import React from 'react';
import { SortAscIcon, SortDescIcon } from '../../Icon';
import { getClass } from '../../_lib/helper';
import './DataTableSort.scss';
/**
 * Renders the icon for the current sort direction in the DataTable
 * @param direction
 * @constructor
 */
function DataTableSort(_a) {
    var direction = _a.direction, isHidden = _a.isHidden;
    var icon = null;
    switch (direction) {
        case 'asc': {
            icon = React.createElement(SortAscIcon, null);
            break;
        }
        case 'desc': {
            icon = React.createElement(SortDescIcon, null);
            break;
        }
        default: {
            icon = React.createElement("div", { className: "sort-spacer" });
        }
    }
    return (React.createElement("div", { className: getClass('elmo-datatable__sort', '', {
            hidden: isHidden,
        }) }, icon));
}
export default DataTableSort;
