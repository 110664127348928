export enum AnalyticsEventTypes {
  EVENT_TYPE_ASSIGNMENT = 'ASSIGNMENT',
  EVENT_TYPE_CUSTOMISATION = 'CUSTOMISATION',
  EVENT_TYPE_EXPORT = 'EXPORT',
  EVENT_TYPE_FILTER = 'FILTER',
  EVENT_TYPE_LOGIN = 'LOGIN',
  EVENT_TYPE_NOTIFICATION = 'NOTIFICATION',
  EVENT_TYPE_PRODUCT_SWITCH = 'PRODUCT_SWITCH',
  EVENT_TYPE_REPORT = 'REPORT',
  EVENT_TYPE_SEARCH = 'SEARCH',
  EVENT_TYPE_SHARE = 'SHARE',
  EVENT_TYPE_USE_TEMPLATE = 'USE_TEMPLATE',
  EVENT_TYPE_VIEW_PAGE = 'VIEW_PAGE',
}
