import { CheckboxProps as ElmoCheckboxProps } from 'elmo-elements/Checkbox';
import { Checkbox as ElmoCheckbox, FormItem } from 'elmo-elements';
import React, { ReactNode } from 'react';
import { useField } from 'formik';

export type CheckboxProps = Omit<ElmoCheckboxProps, 'onChange' | 'options'> & {
  id?: string;
  icon?: ReactNode;
  onChange?: (value: boolean) => void;

  // field config
  name: string;
  value?: any;
};

const Checkbox = ({
  id,
  icon,
  name,
  value,
  onChange,
  ...checkboxProps
}: CheckboxProps) => {
  const [field, meta, helpers] = useField({ name, value });
  const isError = meta.touched && meta.error;

  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    helpers.setValue(event.currentTarget.checked);

    if (onChange) {
      onChange(event.currentTarget.checked);
    }
  };

  return (
    <FormItem
      message={isError ? meta.error : undefined}
      status={isError ? 'error' : 'default'}
    >
      {icon ? (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <ElmoCheckbox
            {...checkboxProps}
            id={id}
            isChecked={field.value}
            onChange={handleChange}
          />
          <div style={{ marginLeft: 5, display: 'flex' }}>{icon}</div>
        </div>
      ) : (
        <ElmoCheckbox
          {...checkboxProps}
          id={id}
          name={name}
          isChecked={field.value}
          onChange={handleChange}
        />
      )}
    </FormItem>
  );
};

export default Checkbox;
