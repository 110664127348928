import { Layout, PlaceholderHeader, PlaceholderListTable } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import { isELMOAdminEnabled } from 'lib/helpers/featureFlag';
import NotFound from 'section/errorPages/pages/NotFound';
import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';
import access from 'lib/access';

const BenchmarkDataContainer = lazy(() =>
  withDelay(() => import('./BenchmarkDataContainer'))
);

const BenchmarkData = (): ReactElement => {
  if (!isELMOAdminEnabled() || !access.isELMOSurveyAdmin()) {
    return <NotFound />;
  }

  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={true}
              hasBackButton={false}
              hasButtons={false}
              numberOfTabs={2}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderListTable cols={5} hasAvatar={false} />
          </Layout.Content>
        </>
      }
    >
      <BenchmarkDataContainer />
    </Suspense>
  );
};

export default BenchmarkData;
