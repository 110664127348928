import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const getOverviewReportFilterOptions: ActionCreator<any> = createAction(
  'GET_OVERVIEW_REPORT_FILTER_OPTIONS'
);

export const getOverviewReportFilterOptionsSuccess: ActionCreator<any> =
  createAction('GET_OVERVIEW_REPORT_FILTER_OPTIONS_SUCCESS');

export const getOverviewReportFilterOptionsFailure: ActionCreator<ErrorResponse> =
  createAction('GET_OVERVIEW_REPORT_FILTER_OPTIONS_FAILURE');

export const clearGetOverviewReportFilterOptions: EmptyActionCreator =
  createAction('CLEAR_GET_OVERVIEW_REPORT_FILTER_OPTIONS');
