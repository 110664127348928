import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  uploadBulkPasswordCSVFailure,
  uploadBulkPasswordCSVSuccess,
} from './actions';
import { UploadBulkPasswordCSVRequest } from './type';
import { uploadBulkPasswordCSVApi } from './api';
import { ErrorResponse, SagaAction } from 'type';

export const uploadBulkPasswordCSVSaga = function* (
  action: SagaAction<UploadBulkPasswordCSVRequest>
): SagaIterator {
  try {
    let formData = new FormData();
    formData.append('file', action.payload.file);

    const data = yield call(
      uploadBulkPasswordCSVApi(action.payload.id, formData)
    );

    if (data === null || data === undefined) {
      const updateError: ErrorResponse = {
        data: undefined,
        headers: undefined,
        status: 0,
      };

      yield put(uploadBulkPasswordCSVFailure(updateError));
    }
    yield put(uploadBulkPasswordCSVSuccess(data));
  } catch (error) {
    yield put(uploadBulkPasswordCSVFailure(error));
  }
};
