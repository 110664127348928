import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const getCollectorUsage: ActionCreator<number> = createAction(
  'GET_COLLECTOR_USAGE'
);

export const getCollectorUsageSuccess: ActionCreator<any> = createAction(
  'GET_COLLECTOR_USAGE_SUCCESS'
);

export const getCollectorUsageFailure: ActionCreator<ErrorResponse> =
  createAction('GET_COLLECTOR_USAGE_FAILURE');

export const clearGetCollectorUsage: EmptyActionCreator = createAction(
  'CLEAR_GET_COLLECTOR_USAGE'
);
