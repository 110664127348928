import { useSelector } from 'react-redux';
import findIndex from 'lodash/findIndex';
import { Select } from 'elmo-elements';
import React from 'react';

import { inviteSelectProps } from './type';
import translations from './translations';
import { t } from 'lib/translation';

const InviteSelect = ({ updateForm, currentForm }: inviteSelectProps) => {
  const messagesTemplatesState = useSelector(
    (state: any) => state.emailInvites.getInviteMessageTemplates
  );

  const onChange = (selected: any) => {
    const selectedTemplateIndex = findIndex(messagesTemplatesState.data, {
      id: selected.value,
    });
    const selectedTemplate = messagesTemplatesState.data[selectedTemplateIndex];

    updateForm({
      ...currentForm,
      selectedTemplate: selected,
      senderEmail: selectedTemplate.from_email,
      senderName: selectedTemplate.from_name,
      subject: selectedTemplate.subject,
      content: selectedTemplate.message,
    });
  };

  const selectOptions = () => {
    return messagesTemplatesState.data.map((data: any) => {
      return {
        value: data.id,
        label: data.title,
      };
    });
  };

  return (
    <Select
      id={'invitation-message-template'}
      value={currentForm.selectedTemplate}
      placeholder={t(translations.placeholder)}
      onChange={onChange}
      options={selectOptions()}
      isInline={false}
    />
  );
};

export default InviteSelect;
