import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getSurveyFailure, getSurveySuccess } from './actions';
import { getSurveyApi } from './api';

export interface GetSurveySagaProps {
  error: boolean;
  payload: number | string;
  type: string;
}

export const getSurveySaga = function* (
  action: GetSurveySagaProps
): SagaIterator {
  try {
    const data = yield call(getSurveyApi(action.payload));

    yield put(getSurveySuccess(data));
  } catch (error) {
    yield put(getSurveyFailure());
  }
};
