var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
function ContentStyle(props) {
    return (React.createElement(LoaderHolder, __assign({}, props),
        React.createElement("rect", { x: "50", y: "15", rx: "4", ry: "4", width: "117", height: "10" }),
        React.createElement("rect", { x: "50", y: "35", rx: "3", ry: "3", width: "85", height: "10" }),
        React.createElement("rect", { x: "0", y: "80", rx: "3", ry: "3", width: "350", height: "10" }),
        React.createElement("rect", { x: "0", y: "100", rx: "3", ry: "3", width: "380", height: "10" }),
        React.createElement("rect", { x: "0", y: "120", rx: "3", ry: "3", width: "201", height: "10" }),
        React.createElement("circle", { cx: "20", cy: "30", r: "20" })));
}
export default ContentStyle;
