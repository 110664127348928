import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { updateCollectorFailure, updateCollectorSuccess } from './actions';
import { updateCollectorApi } from './api';
import { SagaAction } from 'type';

export const updateCollectorSaga = function* (
  action: SagaAction<{ collectorId: number; data: any }>
): SagaIterator {
  try {
    yield call(
      updateCollectorApi(action.payload.collectorId, action.payload.data)
    );

    yield put(updateCollectorSuccess());
  } catch (error) {
    yield put(updateCollectorFailure(error));
  }
};
