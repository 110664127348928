const translations = {
  sectionTitle: {
    key: '',
    default: 'List Details',
  },
  addButton: {
    key: '',
    default: 'Add List Details',
  },
  inviteTitle: {
    key: '',
    default: 'List name',
  },
};

export default translations;
