import body from './components/body/translations';

export const overviewListTranslations: any = {
  list: {
    title: {
      key: 'reports.overviewList.list.title',
      default: 'Survey Overview Report',
    },
    noContent: {
      header: {
        key: 'reports.overviewList.list.noContent.header',
        default: 'There are currently no surveys',
      },
      body: {
        key: 'reports.overviewList.list.noContent.body',
        default: '',
      },
    },
    noResults: {
      header: {
        key: 'reports.overviewList.list.noResults.header',
        default: 'Your search returned no results',
      },
      body: {
        key: 'reports.overviewList.list.noResults.body',
        default: '',
      },
    },
    ...body,
  },
};

export default overviewListTranslations;
