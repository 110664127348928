import { Layout, PlaceholderDashboard, PlaceholderHeader } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import { isSurveyNotificationsEnabled } from 'lib/helpers/featureFlag';
import NotFound from 'section/errorPages/pages/NotFound';
import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const CreateNotificationContainer = lazy(() =>
  withDelay(() => import('./CreateNotificationContainer'))
);

const CreateNotification = (): ReactElement => {
  if (!isSurveyNotificationsEnabled()) {
    return <NotFound />;
  }

  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={false}
              hasBackButton={true}
              hasButtons={false}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderDashboard rows={1} cols={1} />
          </Layout.Content>
        </>
      }
    >
      <CreateNotificationContainer />
    </Suspense>
  );
};

export default CreateNotification;
