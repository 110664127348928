import { createAction } from 'redux-act';

export const updateInviteRecipient: any = createAction(
  'UPDATE_INVITE_RECIPIENT'
);

export const updateInviteRecipientSuccess: any = createAction(
  'UPDATE_INVITE_RECIPIENT_SUCCESS'
);

export const updateInviteRecipientFailure: any = createAction(
  'UPDATE_INVITE_RECIPIENT_FAILURE'
);

export const clearUpdateInviteRecipient: any = createAction(
  'CLEAR_UPDATE_INVITE_RECIPIENT'
);
