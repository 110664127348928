var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Col from '../../Grid/Col';
import React from 'react';
import ListTableSortIcon from './ListTableSortIcon';
import { getClass } from '../../_lib/helper';
import './ListTableTh.scss';
import { BulkActionIcon as SelectAllIcon } from '../../Icon';
import { withListTableContext } from '../ListTableContextConsumer';
import { SORT_ASC, SORT_DESC } from '../../_lib/const';
var ListTableTh = /** @class */ (function (_super) {
    __extends(ListTableTh, _super);
    function ListTableTh() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function (column) { return function () {
            var _a = _this.props, sortable = _a.sortable, sort = _a.sort, setContextState = _a.setContextState, onSort = _a.onSort, isPlaceholder = _a.isPlaceholder;
            if (isPlaceholder) {
                return;
            }
            if (!sortable || !sort || !setContextState) {
                return;
            }
            var sortDirection = column !== sort.column || !sort.direction || sort.direction === SORT_DESC
                ? SORT_ASC
                : SORT_DESC;
            if (onSort) {
                onSort(column, sortDirection);
            }
            setContextState({
                sort: {
                    column: column,
                    direction: sortDirection,
                },
            });
        }; };
        _this.handleKeyPress = function (column) { return function (e) {
            if (e.key === 'Enter') {
                _this.handleClick(column)();
            }
            return false;
        }; };
        return _this;
    }
    ListTableTh.prototype.render = function () {
        var _a = this.props, index = _a.index, label = _a.label, sortable = _a.sortable, sort = _a.sort, classNamesForCols = _a.classNamesForCols, colSpanForCols = _a.colSpanForCols, hasBulkActionsButton = _a.hasBulkActionsButton, toggleBulkActionOpen = _a.toggleBulkActionOpen, isBulkActionOpen = _a.isBulkActionOpen, isPlaceholder = _a.isPlaceholder;
        var classNamesForIndex = classNamesForCols
            ? classNamesForCols[index]
            : '';
        var colSpanForIndex = colSpanForCols ? colSpanForCols[index] : {};
        var headerClassNames = getClass('', classNamesForIndex ? classNamesForIndex.header : '');
        var headerColClassNames = getClass('elmo-listtable__header-content', sortable ? 'elmo-listtable__header-content__sortable' : '');
        return (React.createElement(Col, __assign({ className: headerClassNames }, colSpanForIndex),
            React.createElement("div", { className: "elmo-listtable__header-col", role: "columnheader" },
                hasBulkActionsButton && index === 0 && !isBulkActionOpen && (React.createElement("button", { type: "button", onClick: toggleBulkActionOpen, className: "elmo-listtable__bulkactions-btn", "aria-label": "Select all" },
                    React.createElement(SelectAllIcon, null))),
                React.createElement("div", __assign({ "data-testid": "elmo-listtable-header-" + index, className: headerColClassNames }, (sortable && {
                    onClick: this.handleClick(index),
                    tabIndex: 0,
                    onKeyPress: this.handleKeyPress(index),
                })),
                    label,
                    sortable && sort && sort.column === index && (React.createElement(ListTableSortIcon, { direction: sort.direction }))))));
    };
    return ListTableTh;
}(React.Component));
export { ListTableTh as ListTableThNoContext };
export default withListTableContext(ListTableTh);
