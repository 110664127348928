const translations = {
  heading: {
    key: 'SharedReport.login.heading',
    default: 'Please enter your password below.',
  },
  loginButton: {
    key: 'SharedReport.login.loginButton',
    default: 'Log in',
  },
  errorMessages: {
    invalidCredentials: {
      key: 'SharedReport.login.errorMessages.invalidCredentials',
      default: 'Unable to login. Invalid credentials',
    },
  },
  invalidLink: {
    header: {
      key: '',
      default: 'This link is either invalid or has been disabled',
    },
    body: {
      key: '',
      default: 'Please contact your administrator for assistance.',
    },
  },
};

export default translations;
