import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  getCollectorFilterOptionsFailure,
  getCollectorFilterOptionsSuccess,
} from './actions';
import { GetCollectorFilterOptionsPayload } from './type';
import { getCollectorFilterOptionsApi } from './api';
import { SagaAction } from 'type';

export const getCollectorFilterOptionsSaga = function* (
  action: SagaAction<GetCollectorFilterOptionsPayload>
): SagaIterator {
  try {
    const data = yield call(getCollectorFilterOptionsApi(action.payload));

    yield put(getCollectorFilterOptionsSuccess(data));
  } catch (error) {
    yield put(getCollectorFilterOptionsFailure(error));
  }
};
