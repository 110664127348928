import produce from 'immer';

import {
  updateFilter,
  clearUpdateFilter,
  updateFilterFailure,
  updateFilterSuccess,
} from './actions';
import { updateFilterInitialState } from './state';
import { FilterState } from '../type';
import { ErrorResponse } from 'type';

export function updateFilterReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.updateFilter.isLoading = true;
    draft.updateFilter.isLoaded = false;
    draft.updateFilter.isValid = false;
  });
}

export function updateFilterSuccessReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.updateFilter.isLoading = false;
    draft.updateFilter.isLoaded = true;
    draft.updateFilter.isValid = true;
  });
}

export function updateFilterFailureReducer(
  state: FilterState,
  errors: ErrorResponse
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.updateFilter.isLoading = false;
    draft.updateFilter.isLoaded = true;
    draft.updateFilter.error = errors;
  });
}

export function clearUpdateFilterReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.updateFilter = updateFilterInitialState;
  });
}

export const updateFilterReducers = {
  [`${updateFilter}`]: updateFilterReducer,
  [`${updateFilterSuccess}`]: updateFilterSuccessReducer,
  [`${updateFilterFailure}`]: updateFilterFailureReducer,
  [`${clearUpdateFilter}`]: clearUpdateFilterReducer,
};
