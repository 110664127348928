const translations = {
  title: {
    key: '',
    default: 'Import CSV',
  },
  primaryButton: {
    saveAndClose: {
      key: '',
      default: 'Save & Close',
    },
    saveAndContinue: {
      key: '',
      default: 'Save & Continue',
    },
  },
  backButton: {
    key: '',
    default: 'Previous',
  },
  downloadStep: {
    title: {
      key: '',
      default: '1. Download Import Template',
    },
    description: {
      key: '',
      default: 'Download Recipients Template',
    },
  },
  uploadStep: {
    title: {
      key: '',
      default: '2. Upload Import Template',
    },
    description: {
      key: '',
      default: 'Upload a filled in Recipients Template',
    },
  },
};

export default translations;
