const translations = {
  title: {
    key: '',
    default: 'Participants - Search in my organisation',
  },
  heading: {
    key: '',
    default: '',
  },
  backButton: {
    key: '',
    default: 'Previous',
  },
  primaryButton: {
    saveAndClose: {
      key: '',
      default: 'Save & Close',
    },
    saveAndContinue: {
      key: '',
      default: 'Save & Continue',
    },
  },
  filters: {
    department: {
      key: '',
      default: 'Department',
    },
    position: {
      key: '',
      default: 'Position',
    },
    location: {
      key: '',
      default: 'Location',
    },
  },
  errorState: {
    header: {
      key: '',
      default: 'Whoops! There was an error.',
    },
    body: {
      key: '',
      default:
        'An error occurred while we trying to get a list of your company users.',
    },
    primaryButtonText: {
      key: '',
      default: 'Retry',
    },
  },
};

export default translations;
