import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import {
  GetQuestionCategoryPayload,
  GetQuestionCategoryResponse,
} from './type';
import { ErrorResponse } from 'type';

export const getQuestionCategory: ActionCreator<GetQuestionCategoryPayload> =
  createAction('GET_QUESTION_CATEGORY');

export const getQuestionCategorySuccess: ActionCreator<GetQuestionCategoryResponse> =
  createAction('GET_QUESTION_CATEGORY_SUCCESS');

export const getQuestionCategoryFailure: ActionCreator<ErrorResponse> =
  createAction('GET_QUESTION_CATEGORY_FAILURE');

export const clearGetQuestionCategory: EmptyActionCreator = createAction(
  'CLEAR_GET_QUESTION_CATEGORY'
);
