import {
  isSharedReport,
  isSharedReportLoginPage,
} from '../helpers/sharedReports';
import { isRunSurveyPage, isSurveyFormPage } from '../helpers/surveyForm';
import { UNAUTHORIZED_ERROR } from '../error/ErrorApiResponseHandler';
import { completeLogin, redirectToSharedReportLogin } from './index';
import { getToken, isTokenExpired } from '../token';
import { isErrorPage } from '../helpers/error';
import { messages, t } from '../translation';
import AuthAccess from '../access';
import httpClient from '../api';

const isLoggedIn = (): boolean => {
  const token = getToken();

  if (token === null) {
    return false;
  }

  return !isTokenExpired(token);
};

const isAuthenticatedGeneral = async (): Promise<any> => {
  if (isLoggedIn() && AuthAccess.hasSurveyModuleAccess()) {
    return Promise.resolve(true);
  }

  try {
    const response: any = await httpClient
      .get('/auth/check-token', {
        baseURL: '/oauth2-client',
      })
      .then((result: any) => {
        return result.data;
      })
      .catch((error: any) => {
        if (error === UNAUTHORIZED_ERROR) {
          return Promise.reject(error);
        }

        window.location.href = '/error/authError';
        return;
      });

    completeLogin(response.token);

    return Promise.resolve(true);
  } catch (err) {
    return Promise.reject(t(messages.app.auth.redirectingToLogin));
  }
};

const isAuthenticatedSharedReport = async (): Promise<any> => {
  if (isLoggedIn()) {
    return Promise.resolve(true);
  }

  if (isSharedReportLoginPage()) {
    return Promise.resolve(true);
  }

  redirectToSharedReportLogin();

  return Promise.resolve(t(messages.app.auth.redirectingToLogin));
};

export const isAuthenticated = async (): Promise<any> => {
  if (isSharedReport()) {
    return isAuthenticatedSharedReport();
  }

  if (isErrorPage() || isSurveyFormPage() || isRunSurveyPage()) {
    return Promise.resolve(true);
  }

  return isAuthenticatedGeneral();
};
