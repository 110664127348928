var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import Button from '../../Button';
import { ViewColumnOutlinedIcon as OptionsIcon } from '../../Icon';
import { withDataTableContext } from '../DataTableContextConsumer';
import './DataTableCustomiseButton.scss';
/**
 * The Customise Columns button opens the Customise Columns Modal
 */
var DataTableCustomiseButton = /** @class */ (function (_super) {
    __extends(DataTableCustomiseButton, _super);
    function DataTableCustomiseButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onOptionsClick = function () {
            var setContextState = _this.props.setContextState;
            if (setContextState) {
                setContextState({
                    isCustomiseModalOpen: true,
                });
            }
        };
        return _this;
    }
    DataTableCustomiseButton.prototype.render = function () {
        var isBulkActionOpen = this.props.isBulkActionOpen;
        if (isBulkActionOpen) {
            return null;
        }
        return (React.createElement(Button, { className: "elmo-datatable__options-button", onClick: this.onOptionsClick, isText: true, icon: React.createElement(OptionsIcon, null), ariaLabel: "Options" }));
    };
    return DataTableCustomiseButton;
}(Component));
export default withDataTableContext(DataTableCustomiseButton);
