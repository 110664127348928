import DescriptionIcon from '@mui/icons-material/Description';
import { Button, NativeTable, Row } from 'elmo-elements';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import SummaryCard from 'element/card/components/summaryCard';
import { InviteDetailsProps } from './type';
import translations from './translations';
import { t } from 'lib/translation';

const InviteDetails = ({
  editInviteDetails,
  showSummary,
  showAddAction,
}: InviteDetailsProps): ReactElement => {
  const parameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );
  const inviteDetails = useSelector(
    (state: any) => state.emailInvites.manageInvite.data.inviteDetails
  );

  if (showAddAction && !showSummary) {
    return (
      <Row justifyContent={'center'}>
        <Button
          id={'manage-invite-details'}
          type={'primary'}
          onClick={editInviteDetails}
        >
          {t(translations.addButton)}
        </Button>
      </Row>
    );
  }

  if (!showSummary) {
    return <></>;
  }

  return (
    <>
      <SummaryCard
        id={'invite-details'}
        icon={<DescriptionIcon />}
        sectionTitle={t(translations.sectionTitle)}
        hasPrimaryAction={!parameters.isEdit}
        primaryActionOnClick={editInviteDetails}
      >
        <NativeTable>
          <NativeTable.Body>
            <NativeTable.Tr>
              <NativeTable.Th>{t(translations.inviteTitle)}</NativeTable.Th>
              <NativeTable.Td>{inviteDetails.recipientGroup}</NativeTable.Td>
            </NativeTable.Tr>
          </NativeTable.Body>
        </NativeTable>
      </SummaryCard>
    </>
  );
};

export default InviteDetails;
