import { MessageBlock, Layout } from 'elmo-elements';
import React, { ReactElement } from 'react';

import translations from '../translations';
import { t } from 'lib/translation';

const InvalidLink = (): ReactElement => {
  return (
    <Layout.Content>
      <div style={{ marginTop: '48px' }}>
        <MessageBlock>
          <MessageBlock.Header>
            {t(translations.invalidLink.header)}
          </MessageBlock.Header>
          <MessageBlock.Body>
            {t(translations.invalidLink.body)}
          </MessageBlock.Body>
        </MessageBlock>
      </div>
    </Layout.Content>
  );
};

export default InvalidLink;
