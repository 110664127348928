const translations = {
  title: {
    key: '',
    default: 'Invite participants - 2/3',
  },
  backButton: {
    key: '',
    default: 'Back',
  },
  nextButton: {
    key: '',
    default: 'Next',
  },
  filters: {
    department: {
      key: '',
      default: 'Department',
    },
    position: {
      key: '',
      default: 'Position',
    },
    location: {
      key: '',
      default: 'Location',
    },
    manager: {
      key: '',
      default: 'Manager',
    },
  },
};

export default translations;
