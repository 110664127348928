import { ActionCreator, createAction } from 'redux-act';

import { UpdateThemeData } from './type';

export const updateTheme: ActionCreator<UpdateThemeData> =
  createAction('UPDATE_THEME');

export const updateThemeSuccess = createAction('UPDATE_THEME_SUCCESS');

export const updateThemeFailure: ActionCreator<any> = createAction(
  'UPDATE_THEME_FAILURE'
);

export const clearUpdateTheme = createAction('CLEAR_UPDATE_THEME');
