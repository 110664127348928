import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const route = '/reports/:surveyId/shared';

export const getIndividualLinksApi = (surveyId: number, params: Query) => {
  return getRequest(getRoute(route, { params: { surveyId: surveyId } }), {
    params: params,
  });
};
