import { UpdateQuestionPayload } from './type';
import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const globalUrl = '/admin/questions/:id';
const localUrl = '/questions/:id';

export const updateQuestionApi = (payload: UpdateQuestionPayload) => {
  const url = payload.global ? globalUrl : localUrl;

  return putRequest(
    getRoute(url, {
      params: {
        id: payload.questionId,
      },
    }),
    {
      categories: payload.categories,
      structure: payload.structure,
      type: payload.type,
    }
  );
};
