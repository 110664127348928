import { Delete, List, Update } from 'lib/stateTemplates';
import { MySurveysInitialState } from '../type';

export const BulkUrlsFileUpdate = {
  isLoaded: false,
  isLoading: false,
  files: [],
  isGenerating: false,
  isGenerated: false,
  errorMessages: {
    uploadImportTemplate: '',
    generateBulkUrls: '',
  },
  fileUploadElementKey: 0,
};

export const BulkUrlsCollector = {
  isLoaded: false,
  isLoading: false,
  data: {},
};

export const AvailableUrls = {
  list: List,
};

export const GenerateCheck = {
  isLoading: false,
  isLoaded: false,
  id: null,
  data: {},
};

export const BulkUrls = {
  bulkCollector: BulkUrlsCollector,
  fileUpload: BulkUrlsFileUpdate,
  availableUrls: AvailableUrls,
  generateCheck: GenerateCheck,
};

const Clone = {
  isCloned: false,
  isCloning: false,
};

export default {
  isLoaded: false,
  isLoading: false,
  error: undefined,
  list: {
    isLoading: false,
    isLoaded: false,
    data: [],
  },
  bulkUrls: BulkUrls,
  updateSurvey: Update,
  deleteSurvey: Delete,
  cloneSurvey: Clone,
} as MySurveysInitialState;
