import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

export const createInvite: ActionCreator<any> = createAction('CREATE_INVITE');

export const createInviteSuccess: ActionCreator<any> = createAction(
  'CREATE_INVITE_SUCCESS'
);

export const createInviteFailure: ActionCreator<any> = createAction(
  'CREATE_INVITE_FAILURE'
);

export const clearCreateInvite: EmptyActionCreator = createAction(
  'CLEAR_CREATE_INVITE'
);
