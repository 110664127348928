import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { restoreResponseFailure, restoreResponseSuccess } from './actions';
import { RestoreResponsePayload } from './type';
import { restoreResponseApi } from './api';
import { SagaAction } from 'type';

export const restoreResponseSaga = function* (
  action: SagaAction<RestoreResponsePayload>
): SagaIterator {
  try {
    yield call(restoreResponseApi(action.payload.respondent_id));
    yield put(restoreResponseSuccess());
  } catch (error) {
    yield put(restoreResponseFailure(error));
  }
};
