import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  getSavedOverviewReportFailure,
  getSavedOverviewReportSuccess,
} from './actions';
import { LoadSavedOverviewReportPayload } from '../loadSavedOverviewReport/type';
import { getSavedOverviewReportApi } from './api';
import { SagaAction } from 'type';

export const getSavedOverviewReportSaga = function* (
  action: SagaAction<LoadSavedOverviewReportPayload>
): SagaIterator {
  try {
    const { surveyId, reportId } = action.payload;

    const data = yield call(getSavedOverviewReportApi(surveyId, reportId));

    yield put(getSavedOverviewReportSuccess(data));
  } catch (error) {
    yield put(getSavedOverviewReportFailure(error));
  }
};
