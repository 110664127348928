import { takeEvery } from 'redux-saga/effects';

import {
  createSurveyTemplate,
  deleteSurveyTemplate,
  getFeaturedTemplates,
  getSurveyTemplate,
  getSurveyTemplates,
  updateSurveyTemplate,
} from './actions';
import { getFeaturedTemplatesSaga } from './getFeaturedTemplates/sagas';
import { deleteSurveyTemplateSaga } from './deleteSurveyTemplate/sagas';
import { updateSurveyTemplateSaga } from './updateSurveyTemplate/sagas';
import { createSurveyTemplateSaga } from './createSurveyTemplate/sagas';
import { getSurveyTemplatesSaga } from './getSurveyTemplates/sagas';
import { getSurveyTemplateSaga } from './getSurveyTemplate/sagas';

export default function* sagas() {
  yield takeEvery(deleteSurveyTemplate, deleteSurveyTemplateSaga);
  yield takeEvery(getFeaturedTemplates, getFeaturedTemplatesSaga);
  yield takeEvery(getSurveyTemplates, getSurveyTemplatesSaga);
  yield takeEvery(updateSurveyTemplate, updateSurveyTemplateSaga);
  yield takeEvery(createSurveyTemplate, createSurveyTemplateSaga);
  yield takeEvery(getSurveyTemplate, getSurveyTemplateSaga);
}
