import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetSiteThemeResponse } from './type';
import { ErrorResponse } from 'type';

export const getSiteTheme: EmptyActionCreator = createAction('GET_SITE_THEME');

export const getSiteThemeSuccess: ActionCreator<GetSiteThemeResponse> =
  createAction('GET_SITE_THEME_SUCCESS');

export const getSiteThemeFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_SITE_THEME_FAILURE'
);
