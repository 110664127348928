import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/email-recipients/:recipientId';

export const updateInviteRecipientApi = (recipientId: number, payload: any) => {
  return putRequest(
    getRoute(url, {
      params: { recipientId: recipientId },
    }),
    payload
  );
};
