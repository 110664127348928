import { getCurrentPage } from '../history';

export const isSurveyFormPage = (): boolean => {
  const currentPage = getCurrentPage();

  return currentPage.startsWith('/preview/');
};

export const isRunSurveyPage = (): boolean => {
  const currentPage = getCurrentPage();

  return (
    currentPage.startsWith('/run/') || currentPage.startsWith('/run-survey/')
  );
};
