import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateSurveyAccessListFailure,
  updateSurveyAccessListSuccess,
} from './actions';
import { UpdateSurveyAccessListRequest } from './type';
import { updateSurveyAccessListApi } from './api';
import { SagaAction } from 'type';

export const updateSurveyAccessListSaga = function* (
  action: SagaAction<UpdateSurveyAccessListRequest>
): SagaIterator {
  try {
    yield call(updateSurveyAccessListApi(action.payload));

    yield put(updateSurveyAccessListSuccess());
  } catch (error) {
    yield put(updateSurveyAccessListFailure(error));
  }
};
