import React from 'react';
function FormItemControl(_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "elmo-formitem__control__item-group" }, React.Children.map(children, function (child, index) {
        if (React.isValidElement(child)) {
            return (React.createElement("div", { className: 'elmo-formitem__control__item', key: index }, child));
        }
        return null;
    })));
}
export default FormItemControl;
