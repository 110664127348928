import { isSharedReport } from 'lib/helpers/sharedReports';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId/overview-report-filter-options';
const sharedReportApiUrl =
  '/shared-reports/:surveyId/overview-report-filter-options';

export const getOverviewReportFilterOptionsApi = (
  surveyId: number | string
): any => {
  const route = isSharedReport() ? sharedReportApiUrl : apiUrl;

  return getRequest(
    getRoute(route, {
      params: { surveyId: surveyId },
    })
  );
};
