var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import ResizeObserver from '@juggle/resize-observer';
import './ModalBody.scss';
var ModalBody = /** @class */ (function (_super) {
    __extends(ModalBody, _super);
    function ModalBody() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.bodyRef = createRef();
        _this.bodyInnerRef = createRef();
        _this.modalBodyObserver = new ResizeObserver(function (entries) {
            _this.doShadow();
        });
        _this.doShadow = function () {
            var bodyElement = _this.bodyRef.current;
            var bodyElementHeight = 0;
            if (bodyElement) {
                bodyElementHeight = bodyElement.clientHeight;
            }
            if (_this.bodyInnerRef &&
                _this.bodyInnerRef.current &&
                bodyElement &&
                _this.props.modalContent &&
                _this.props.modalContent.current) {
                if (_this.bodyInnerRef.current.clientHeight > bodyElementHeight) {
                    _this.props.modalContent.current.classList.add('has-shadow');
                }
                else {
                    _this.props.modalContent.current.classList.remove('has-shadow');
                }
            }
            return false;
        };
        return _this;
    }
    ModalBody.prototype.componentDidMount = function () {
        if (this.bodyInnerRef && this.bodyInnerRef.current) {
            this.modalBodyObserver.observe(this.bodyInnerRef.current);
            this.doShadow();
        }
    };
    ModalBody.prototype.render = function () {
        var _a = this.props, isBackgroundGrey = _a.isBackgroundGrey, children = _a.children;
        var className = 'elmo-modal__body' + (isBackgroundGrey ? ' elmo-modal__body-grey' : '');
        return (React.createElement("div", { className: className, ref: this.bodyRef },
            React.createElement("div", { className: "elmo-modal__body-inner", ref: this.bodyInnerRef }, children)));
    };
    return ModalBody;
}(Component));
export default ModalBody;
