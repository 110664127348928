import map from 'lodash/map';

import { AnonRecipient, APIAnonRecipient, APIRecipient, Recipient } from 'type';

export const transformRecipient = (apiRecipient: APIRecipient): Recipient => {
  return {
    createdAt: apiRecipient.created_at,
    email: apiRecipient.email,
    filterValues: apiRecipient.filter_values,
    length: apiRecipient.length,
    id: apiRecipient.id,
    inputType: apiRecipient.input_type,
    isTmsUser: apiRecipient.is_tms_user,
    linkDisabled: apiRecipient.link_disabled,
    responseDate: apiRecipient.response_date,
    responseStatus: apiRecipient.response_status,
    updatedAt: apiRecipient.updated_at,
  };
};

export const transformRecipients = (
  apiRecipients: APIRecipient[]
): Recipient[] => {
  return map(apiRecipients, transformRecipient);
};

export const transformAnonRecipient = (
  apiAnonRecipient: APIAnonRecipient
): AnonRecipient => {
  return {
    linkDisabled: apiAnonRecipient.link_disabled,
    responseStatus: apiAnonRecipient.response_status,
  };
};
