import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateInviteRecipientFailure,
  updateInviteRecipientSuccess,
} from './actions';
import { updateInviteRecipientApi } from './api';

export const updateInviteRecipientSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(
      updateInviteRecipientApi(action.payload.recipientId, action.payload.data)
    );

    yield put(updateInviteRecipientSuccess(data));
  } catch (error) {
    yield put(updateInviteRecipientFailure(error));
  }
};
