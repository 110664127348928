var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import RadioWrapper from './component/RadioWrapper';
import RadioLabel from './component/RadioLabel';
import RadioElement from './component/RadioElement';
import { RadioKeyConstants } from './type';
import Tooltip from '../Tooltip';
var Radio = /** @class */ (function (_super) {
    __extends(Radio, _super);
    function Radio() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleOnChange = function () {
            var _a = _this.props, disabled = _a.disabled, onChange = _a.onChange, value = _a.value;
            if (disabled) {
                return;
            }
            if (onChange) {
                onChange(value);
            }
        };
        /**
         * When keyup events occur within the component
         * @param e
         */
        _this.onKeyUp = function (e) {
            e.preventDefault();
            if (e.key === RadioKeyConstants.enterKey) {
                _this.handleOnChange();
            }
        };
        return _this;
    }
    Radio.prototype.render = function () {
        var _a = this.props, id = _a.id, name = _a.name, value = _a.value, disabled = _a.disabled, checked = _a.checked, children = _a.children, className = _a.className, tooltip = _a.tooltip;
        var radioWrapper = (React.createElement(RadioWrapper, { disabled: disabled, checked: checked, onKeyUp: this.onKeyUp, id: id, className: className },
            React.createElement(RadioElement, { name: name, value: value, disabled: disabled, onChange: this.handleOnChange, checked: checked }),
            React.createElement(RadioLabel, null, children)));
        if (tooltip) {
            return (React.createElement(Tooltip, { tabIndex: -1, title: tooltip, placement: "bottom" }, radioWrapper));
        }
        return radioWrapper;
    };
    Radio.displayName = 'Radio';
    return Radio;
}(Component));
export default Radio;
