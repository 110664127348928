const translations = {
  validationError: {
    key: 'element.inputTags.emailInput.validationError',
    default: 'Validation Error',
  },
  errors: {
    tooManyEmails: {
      title: {
        key: 'element.inputTags.emailInput.errors.tooManyEmails.title',
        default: 'Error - Too Many Recipients',
      },
      body: {
        key: 'element.inputTags.emailInput.errors.tooManyEmails.body',
        default:
          'We can see you tried to add {{total}} emails.' +
          ' There is a limit of {{maxEmails}} emails for each group, ' +
          'please update your list to a maximum of {{maxEmails}} to proceed',
      },
      primaryButton: {
        key: 'element.inputTags.emailInput.errors.tooManyEmails.primaryButton',
        default: 'Ok',
      },
      tooltip: {
        key: 'element.inputTags.emailInput.errors.tooManyEmails.tooltip',
        default: 'Maximum email limit reached',
      },
    },
    duplicateEmails: {
      title: {
        key: 'element.inputTags.emailInput.errors.duplicateEmails.title',
        default: 'Error - Duplicate Emails',
      },
      body: {
        key: 'element.inputTags.emailInput.errors.duplicateEmails.body',
        default:
          '{{total}} Emails have been successfully added.\n\n' +
          'The following ({{duplicateCount}}) duplicate emails have been removed:\n\n',
      },
      primaryButton: {
        key: 'element.inputTags.emailInput.errors.duplicateEmails.primaryButton',
        default: 'Ok',
      },
      tooltip: {
        key: 'element.inputTags.emailInput.errors.duplicateEmails.tooltip',
        default: 'Duplicate email',
      },
    },
    invalidEmails: {
      title: {
        key: 'element.inputTags.emailInput.errors.invalidEmails.title',
        default: 'Email Summary',
      },
      body: {
        key: 'element.inputTags.emailInput.errors.invalidEmails.body',
        default:
          '{{total}} Emails have been successfully added.\n\n' +
          'The following ({{invalidCount}}) invalid emails have been removed.' +
          ' Please check the email addresses are valid and try again.\n\n',
      },
      primaryButton: {
        key: 'element.inputTags.emailInput.errors.invalidEmails.primaryButton',
        default: 'Ok',
      },
      tooltip: {
        key: 'element.inputTags.emailInput.errors.invalidEmails.tooltip',
        default: 'Invalid email address',
      },
    },
  },
};

export default translations;
