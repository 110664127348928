import produce from 'immer';

import {
  clearGetSavedOverviewReport,
  getSavedOverviewReport,
  getSavedOverviewReportFailure,
  getSavedOverviewReportSuccess,
} from './actions';
import { transformQuestionSettings } from '../../../transformers';
import { getSavedOverviewReportInitialState } from './state';
import { OverviewReportState } from '../type';
import { ErrorResponse } from 'type';

export function getSavedOverviewReportReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getSavedOverviewReport.isLoaded = false;
    draft.getSavedOverviewReport.isLoading = true;
    draft.getSavedOverviewReport.isValid = false;
  });
}

export function getSavedOverviewReportSuccessReducer(
  state: OverviewReportState,
  data: any
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getSavedOverviewReport.isLoaded = true;
    draft.getSavedOverviewReport.isLoading = false;
    draft.getSavedOverviewReport.isValid = true;
    draft.getSavedOverviewReport.description = data.data.description;
    draft.getSavedOverviewReport.title = data.data.title;
    draft.getSavedOverviewReport.filters = data.data.filters;
    draft.getSavedOverviewReport.questionSettings = transformQuestionSettings(
      data.data.questionDisplaySettings
    );
  });
}

export function getSavedOverviewReportFailureReducer(
  state: OverviewReportState,
  error: ErrorResponse
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getSavedOverviewReport.isLoaded = true;
    draft.getSavedOverviewReport.isLoading = false;
    draft.getSavedOverviewReport.error = error;
  });
}

export function clearGetSavedOverviewReportReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getSavedOverviewReport = getSavedOverviewReportInitialState;
  });
}

export const getSavedOverviewReportReducers = {
  [`${getSavedOverviewReport}`]: getSavedOverviewReportReducer,
  [`${getSavedOverviewReportSuccess}`]: getSavedOverviewReportSuccessReducer,
  [`${getSavedOverviewReportFailure}`]: getSavedOverviewReportFailureReducer,
  [`${clearGetSavedOverviewReport}`]: clearGetSavedOverviewReportReducer,
};
