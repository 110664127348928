import * as React from 'react';
import { CancelIcon } from '../../Icon';
import './File.scss';
import Button from '../../Button';
export var File = function (_a) {
    var name = _a.name, handleDelete = _a.handleDelete, className = _a.className;
    return (React.createElement("div", { className: "elmo-uploaded-file " + className },
        React.createElement("span", null, name),
        React.createElement(Button, { icon: React.createElement(CancelIcon, null), onClick: handleDelete })));
};
