import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const getDefaultTheme: EmptyActionCreator =
  createAction('GET_DEFAULT_THEME');
export const getDefaultThemeSuccess: any = createAction(
  'GET_DEFAULT_THEME_SUCCESS'
);
export const getDefaultThemeFailure: ActionCreator<ErrorResponse> =
  createAction('GET_DEFAULT_THEME_FAILURE');
