const translations = {
  title: {
    key: '',
    default: 'Inviting Participants...',
  },
  steps: {
    recipientList: {
      key: '',
      default: 'Creating Invite List',
    },
    recipients: {
      key: '',
      default: 'Adding Recipients to Invite',
    },
    message: {
      key: '',
      default: 'Saving Invite Message',
    },
    send: {
      key: '',
      default: 'Sending Emails to Recipients',
    },
    reminderMessage: {
      key: '',
      default: 'Saving Reminder Message(s)',
    },
    schedule: {
      key: '',
      default: 'Scheduling Emails to Recipients',
    },
    error: {
      key: '',
      default: 'An error occurred with this step',
    },
  },
  notifications: {
    success: {
      queued: {
        key: '',
        default: 'Participant invites queued.',
      },
      scheduled: {
        key: '',
        default: 'Participant invites scheduled.',
      },
    },
  },
};

export default translations;
