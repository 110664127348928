import { Menu as ElmoMenu, PlaceholderMenu } from 'elmo-elements';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Update } from 'history';

import {
  getParentPageTitle,
  getMenuMainItems,
  getSubMenuItems,
  getUserInfo,
  getUserMenuItems,
  onClickLogo,
} from './helpers';
import { getMenu } from 'store/actions';
import { RootState } from 'store/type';
import { history } from 'lib/history';
import { setPageTitle } from 'lib';
import './Menu.scss';

export const MENU_KEY = 'app.menu.survey';

const Menu = (): ReactElement => {
  const dispatch = useDispatch();

  const menuState = useSelector((state: RootState) => state.app.getMenu);

  const [currentRoute, updateCurrentRoute] = useState<string>(
    window.location.pathname
  );

  history.listen(({ location }: Update) => {
    updateCurrentRoute(location.pathname);
  });

  useEffect(() => {
    if (!menuState.isLoading && !menuState.isLoaded) {
      dispatch(getMenu());
    }
  }, [menuState, dispatch]);

  useEffect(() => {
    if (menuState.isLoaded) {
      const pageTitle = getParentPageTitle(menuState.data, currentRoute);

      setPageTitle(pageTitle);
    }
  }, [menuState]);

  if (!menuState.isLoaded || !menuState.isValid) {
    return (
      <>
        <div className="d-none d-lg-block">
          <PlaceholderMenu />
        </div>
      </>
    );
  }

  const mainMenuItems = getMenuMainItems(menuState.data);
  const subMenuItems = getSubMenuItems(menuState.data, currentRoute);
  const userMenuItems = getUserMenuItems(menuState.data);
  const userInfo = getUserInfo(menuState.data);

  return (
    <>
      <ReactSVG src="/survey-icons.svg" className={'elmo-menu-svg'} />
      <ElmoMenu
        logo={menuState.data.branding.logo}
        menuItems={mainMenuItems}
        userDetails={userInfo}
        userMenu={userMenuItems}
        theme={menuState.data.branding.theme}
        isLoading={menuState.isLoading}
        isReact={true}
        logoHref={onClickLogo}
        subMenuItems={subMenuItems}
        asSubMenu={subMenuItems !== undefined}
        footerMessage={
          '<span style="opacity: 0.75">Powered by</span> <a href="https://elmosoftware.com.au/' +
          '?utm_source=ELMO-system&amp;utm_medium=bottom-left-hand-nav&amp;utm_campaign=brand-' +
          'awarenes" target="_blank" rel="noopener noreferrer">ELMO Software</a>'
        }
        {...(menuState.data.branding.smallLogo !== ''
          ? { mobileLogo: menuState.data.branding.smallLogo }
          : {})}
      />
    </>
  );
};

export default Menu;
