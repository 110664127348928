import produce from 'immer';

import {
  getAdminBenchmarkData,
  getAdminBenchmarkDataFailure,
  getAdminBenchmarkDataSuccess,
} from './actions';
import { getAdminBenchmarkDataInitialState } from './state';
import { GetAdminBenchmarkDataResponse } from './type';
import { AdminState } from '../type';
import { ErrorResponse } from 'type';

export function getAdminBenchmarkDataReducer(state: AdminState): AdminState {
  return produce(state, (draft: AdminState) => {
    draft.getAdminBenchmarkData = { ...getAdminBenchmarkDataInitialState };
    draft.getAdminBenchmarkData.isLoading = true;
  });
}

export function getAdminBenchmarkDataSuccessReducer(
  state: AdminState,
  data: GetAdminBenchmarkDataResponse
): AdminState {
  return produce(state, (draft: AdminState) => {
    draft.getAdminBenchmarkData.isLoaded = true;
    draft.getAdminBenchmarkData.isLoading = false;
    draft.getAdminBenchmarkData.isValid = true;
    draft.getAdminBenchmarkData.count = data.count;
    draft.getAdminBenchmarkData.data = data.data;
  });
}

export function getAdminBenchmarkDataFailureReducer(
  state: AdminState,
  error: ErrorResponse
): AdminState {
  return produce(state, (draft: AdminState) => {
    draft.getAdminBenchmarkData.isLoaded = true;
    draft.getAdminBenchmarkData.isLoading = false;
    draft.getAdminBenchmarkData.error = error;
  });
}

export const getAdminBenchmarkDataReducers = {
  [`${getAdminBenchmarkData}`]: getAdminBenchmarkDataReducer,
  [`${getAdminBenchmarkDataSuccess}`]: getAdminBenchmarkDataSuccessReducer,
  [`${getAdminBenchmarkDataFailure}`]: getAdminBenchmarkDataFailureReducer,
};
