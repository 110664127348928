import React from 'react';
import ToggleContext from './ToggleContext';
function ToggleOn(_a) {
    var children = _a.children;
    return (React.createElement(ToggleContext.Consumer, null, function (_a) {
        var isOn = _a.isOn;
        return (isOn ? children : null);
    }));
}
export default ToggleOn;
