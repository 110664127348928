import { Layout, PlaceholderDashboard, PlaceholderHeader } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import { isSharedReport as isSharedReportPage } from 'lib/helpers/sharedReports';
import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const QuestionSummaries = lazy(() =>
  withDelay(() => import('./QuestionSummaries'))
);

const QuestionSummariesSuspense = (): ReactElement => {
  const isSharedReport = isSharedReportPage();

  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={!isSharedReport}
              hasBackButton={!isSharedReport}
              hasButtons={false}
              numberOfTabs={5}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderDashboard cols={1} rows={3} />
          </Layout.Content>
        </>
      }
    >
      <QuestionSummaries />
    </Suspense>
  );
};

export default QuestionSummariesSuspense;
