import { UploadedFile } from 'elmo-elements/FileUpload';
import React, { ReactElement } from 'react';
import { Row, Col } from 'elmo-elements';

import { DownloadCard, UploadCard } from './components';
import './ImportCSVTemplate.scss';

interface ImportCSVTemplateProps {
  downloadTitle: string;
  downloadDescription: string;
  downloadAction: () => void;
  uploadTitle: string;
  uploadDescription: string;
  acceptedFiles?: string[];
  onDropAccepted: (files: UploadedFile) => void;
  onDropRejected: (files: UploadedFile) => void;
  handleFileDelete: () => void;
  isUploading: boolean;
  uploadedFileName: string | null;
  fileMaxSize: number;
}

const ImportCSVTemplate = ({
  downloadTitle,
  downloadDescription,
  downloadAction,
  uploadTitle,
  uploadDescription,
  acceptedFiles,
  onDropAccepted,
  onDropRejected,
  handleFileDelete,
  isUploading,
  uploadedFileName,
  fileMaxSize,
}: ImportCSVTemplateProps): ReactElement => {
  return (
    <>
      <Row className={'import-csv-template'}>
        <Col sm={24} md={12}>
          <DownloadCard
            title={downloadTitle}
            description={downloadDescription}
            buttonAction={downloadAction}
          />
        </Col>
        <Col sm={24} md={12}>
          <UploadCard
            title={uploadTitle}
            description={uploadDescription}
            acceptedFiles={acceptedFiles}
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            handleFileDelete={handleFileDelete}
            isUploading={isUploading}
            uploadedFileName={uploadedFileName}
            fileMaxSize={fileMaxSize}
          />
        </Col>
      </Row>
    </>
  );
};

export default ImportCSVTemplate;
