import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancer = composeWithDevTools(applyMiddleware(sagaMiddleware));

const store = createStore(reducers, {}, composeEnhancer);

sagaMiddleware.run(sagas);

export type AppDispatch = typeof store.dispatch;

export default store;
