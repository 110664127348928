import React from 'react';
import { getClass } from '../../_lib/helper';
import './NativeTableTr.scss';
function NativeTableTr(_a) {
    var _b;
    var children = _a.children, className = _a.className, valign = _a.valign;
    return (React.createElement("tr", { className: getClass('elmo-nativetable-tr', (_b = {
                className: className
            },
            _b["elmo-nativetable-tr__valign-" + valign] = true,
            _b)) }, children));
}
NativeTableTr.defaultProps = {
    valign: 'top',
};
export default NativeTableTr;
