import axios, { AxiosRequestConfig } from 'axios';

import ErrorApiResponseHandler from '../error/ErrorApiResponseHandler';
import { isAuthenticated } from '../authentication/check';
import logger from '../logger';

// <Dev.Note.Security>
//  The principle we follow here is that in order to make your app more secure
//  try to think before you are using a 3rd party library and validate if you really need it.
//  If the answer is yes it is better to wrap your 3rd party apis. For example we have a
//  wrapper around axios that provides you a HttpClient
//
//  You dont need to wrap the entire react :) or redux but it make sense for use to wrap a Logger
//  library or a HttpClient library.
// </Dev.Note.Security>

// Setup a custom HttpClient
const httpClient = axios.create({
  baseURL: '/api',
  xsrfHeaderName: 'x-survey-xsrf-token',
  xsrfCookieName: 'SURVEY-XSRF-TOKEN',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

// Adding some custom request interceptor
httpClient.interceptors.request.use(
  (requestConfig: any) => {
    if (requestConfig.url === '/auth/check-token') {
      return requestConfig;
    }

    return isAuthenticated().then(() => {
      return requestConfig;
    });
  },
  (error: any) => {
    logger.error(error, 'Oh we have error in our http request');
    return Promise.reject(error);
  }
);

// Adding some custom response interceptor
httpClient.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    logger.error(error, 'Oh we have error in our http response');
    return ErrorApiResponseHandler.handleError(error);
  }
);

export const getRequest = <T = any>(path: string, requestConfig?: any) => {
  return (): Promise<T> =>
    httpClient.get<T>(path, requestConfig).then((result: any) => {
      return result.data;
    });
};

export const postRequest = (
  path: string,
  payload?: any,
  requestConfig?: any
) => {
  return () =>
    httpClient.post(path, payload, requestConfig).then((result: any) => {
      return result.data;
    });
};

export const putRequest = (
  path: string,
  payload?: any,
  requestConfig?: any
) => {
  return () =>
    httpClient.put(path, payload, requestConfig).then((result: any) => {
      return result.data;
    });
};

export const deleteRequest = (path: string, requestConfig?: any): any => {
  return () =>
    httpClient
      .delete(path, requestConfig)
      .then((result: AxiosRequestConfig) => {
        return result.data;
      });
};

export const patchRequest = (
  path: string,
  payload?: any,
  requestConfig?: any
) => {
  return () =>
    httpClient.patch(path, payload, requestConfig).then((result: any) => {
      return result.data;
    });
};

// Setup the default httpClient
export default httpClient;
