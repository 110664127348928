const translations = {
  draftUsed: {
    key: '',
    default:
      'We can see you had an unfinished invite, do you want to continue or...',
  },
  clearDraft: {
    key: '',
    default: 'Clear Draft',
  },
};

export default translations;
