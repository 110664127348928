import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  getLatestCreatedBulkLinkFailure,
  getLatestCreatedBulkLinkSuccess,
} from './actions';
import { getLatestCreatedBulkLinkApi } from './api';
import { SagaAction } from 'type';

export const getLatestCreatedBulkLinkSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const searchFilters = {
      page: 1,
      limit: 1,
    };

    const data = yield call(
      getLatestCreatedBulkLinkApi(action.payload, searchFilters)
    );

    yield put(getLatestCreatedBulkLinkSuccess(data));
  } catch (error) {
    yield put(getLatestCreatedBulkLinkFailure(error));
  }
};
