import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getSurveyTemplatesFailure,
  getSurveyTemplatesSuccess,
  updateSurveyTemplatesQuery,
} from './actions';
import { getSurveyTemplatesInitialState } from './state';
import { getSurveyTemplatesApi } from './api';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

export const getSurveyTemplatesSaga = function* (
  action: SagaAction<string>
): SagaIterator {
  try {
    const currentQuery = getQuery(getSurveyTemplatesInitialState.query);

    yield put(updateSurveyTemplatesQuery(currentQuery));

    // Disconnect from currentQuery so status does not show in address bar
    const queryParams = {
      ...currentQuery,
      status: action.payload,
    };

    if (action.payload === 'archived') {
      queryParams.filter = undefined;
    }

    const data = yield call(getSurveyTemplatesApi(queryParams));
    yield put(getSurveyTemplatesSuccess(data));
  } catch (error) {
    yield put(getSurveyTemplatesFailure(error));
  }
};
