var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { ExpandMoreIcon } from '../Icon';
import { getClass, uniqueId } from '../_lib/helper';
import NativeSelectOption from './components/NativeSelectOption';
import './NativeSelect.scss';
var NativeSelect = /** @class */ (function (_super) {
    __extends(NativeSelect, _super);
    function NativeSelect(props) {
        var _this = _super.call(this, props) || this;
        _this.onChange = function (event) {
            var onChange = _this.props.onChange;
            if (onChange) {
                onChange(event.currentTarget.value);
            }
        };
        _this.selectId = uniqueId('select-');
        return _this;
    }
    NativeSelect.prototype.render = function () {
        var _a = this.props, id = _a.id, className = _a.className, children = _a.children, value = _a.value, ariaLabel = _a.ariaLabel;
        return (React.createElement("div", { id: id, className: getClass('elmo-nativeselect', className), "data-testid": "elmo-nativeselect-" + (id || 'default') },
            React.createElement("div", { className: "elmo-nativeselect__icon" },
                React.createElement(ExpandMoreIcon, null)),
            ariaLabel && (React.createElement("label", { htmlFor: this.selectId, className: "sr-only" }, ariaLabel)),
            React.createElement("select", { id: this.selectId, onChange: this.onChange, value: value }, children)));
    };
    NativeSelect.Option = NativeSelectOption;
    return NativeSelect;
}(Component));
export default NativeSelect;
