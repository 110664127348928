import { createReducer } from 'redux-act';

import { getLatestCreatedBulkLinkReducers } from './getLatestCreatedBulkLink/reducers';
import { uploadBulkPasswordCSVReducers } from './uploadBulkPasswordCSV/reducers';
import { createIndividualLinkReducers } from './createIndividualLink/reducers';
import { closeIndividualLinkReducers } from './closeIndividualLink/reducers';
import { updateBulkPasswordReducers } from './updateBulkPassword/reducers';
import { getIndividualLinkReducers } from './getIndividualLinks/reducers';
import { getBulkLinkBatchReducers } from './getBulkLinkBatch/reducers';
import { createBulkLinksReducers } from './createBulkLinks/reducers';
import { getBulkLinksReducers } from './getBulkLinks/reducers';
import { shareReportInitialState } from './state';

export default createReducer(
  {
    ...closeIndividualLinkReducers,
    ...createBulkLinksReducers,
    ...createIndividualLinkReducers,
    ...getBulkLinkBatchReducers,
    ...getBulkLinksReducers,
    ...getIndividualLinkReducers,
    ...getLatestCreatedBulkLinkReducers,
    ...updateBulkPasswordReducers,
    ...uploadBulkPasswordCSVReducers,
  },
  shareReportInitialState
);
