var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass } from '../../_lib/helper';
import { TIMELINE_ACTIVE_COLOR, TIMELINE_DEFAULT_COLOR, } from '../../_lib/const';
var TimelineItem = /** @class */ (function (_super) {
    __extends(TimelineItem, _super);
    function TimelineItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getIcon = function () {
            var icon = _this.props.icon;
            return React.createElement("div", { className: "elmo-timeline__point" }, icon);
        };
        return _this;
    }
    TimelineItem.prototype.render = function () {
        var _a;
        var _b = this.props, children = _b.children, className = _b.className, isSet = _b.isSet, icon = _b.icon, color = _b.color, activeColor = _b.activeColor;
        var itemClassName = getClass('', (_a = {},
            _a["" + className] = !!className,
            _a['elmo-timeline__item'] = true,
            _a));
        var currentColor = color;
        if (isSet) {
            currentColor = activeColor;
        }
        var currentStatus = undefined;
        if (isSet !== null && isSet !== undefined) {
            currentStatus = isSet ? 'marked' : 'not marked';
        }
        return (React.createElement("li", { className: itemClassName },
            React.createElement("div", { className: "elmo-timeline__line" }),
            icon ? (this.getIcon()) : (React.createElement("div", { style: { background: currentColor }, className: "elmo-timeline__point", "aria-label": currentStatus })),
            React.createElement("div", { className: "elmo-timeline__content" }, children)));
    };
    TimelineItem.defaultProps = {
        color: TIMELINE_DEFAULT_COLOR,
        activeColor: TIMELINE_ACTIVE_COLOR,
    };
    return TimelineItem;
}(Component));
export default TimelineItem;
