import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateEmailTemplatePayload } from './type';
import { ErrorResponse } from 'type';

export const updateEmailTemplate: ActionCreator<UpdateEmailTemplatePayload> =
  createAction('UPDATE_EMAIL_TEMPLATE');

export const updateEmailTemplateSuccess: EmptyActionCreator = createAction(
  'UPDATE_EMAIL_TEMPLATE_SUCCESS'
);

export const updateEmailTemplateFailure: ActionCreator<ErrorResponse> =
  createAction('UPDATE_EMAIL_TEMPLATE_FAILURE');

export const clearUpdateEmailTemplate: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_EMAIL_TEMPLATE'
);
