import produce from 'immer';

import { getSiteThemeInitialState } from './state';
import { GetSiteThemeResponse } from './type';
import * as actions from './actions';
import { ErrorResponse } from 'type';
import { AppState } from '../type';

export const getSiteThemeReducer = (state: AppState): AppState => {
  return produce(state, (draft: AppState) => {
    draft.getSiteTheme = { ...getSiteThemeInitialState };
    draft.getSiteTheme.isLoading = true;
  });
};

export const getSiteThemeSuccessReducer = (
  state: AppState,
  data: GetSiteThemeResponse
): AppState => {
  return produce(state, (draft: AppState) => {
    draft.getSiteTheme.isLoading = false;
    draft.getSiteTheme.isLoaded = true;
    draft.getSiteTheme.isValid = true;
    draft.getSiteTheme.data = data.data;
  });
};

export const getSiteThemeFailureReducer = (
  state: AppState,
  error: ErrorResponse
): AppState => {
  return produce(state, (draft: AppState) => {
    draft.getSiteTheme.isLoading = false;
    draft.getSiteTheme.isLoaded = true;
    draft.getSiteTheme.error = error;
  });
};

export const getSiteThemeReducers = {
  [`${actions.getSiteTheme}`]: getSiteThemeReducer,
  [`${actions.getSiteThemeSuccess}`]: getSiteThemeSuccessReducer,
  [`${actions.getSiteThemeFailure}`]: getSiteThemeFailureReducer,
};
