var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { withDataTableContext } from '../DataTableContextConsumer';
import SelectList from '../../SelectList';
var getState = function (_a) {
    var headerColumns = _a.headerColumns;
    return ({
        headerColumns: headerColumns.map(function (column) { return (__assign({}, column)); }),
    });
};
var DataTableCustomiseModal = /** @class */ (function (_super) {
    __extends(DataTableCustomiseModal, _super);
    function DataTableCustomiseModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = getState(_this.props);
        _this.onClose = function () {
            var setContextState = _this.props.setContextState;
            setContextState({
                isCustomiseModalOpen: false,
            });
        };
        _this.onSubmit = function () {
            var setContextState = _this.props.setContextState;
            setContextState({
                isCustomiseModalOpen: false,
                headerColumns: _this.state.headerColumns,
            });
        };
        _this.onSelectAllToggle = function (isSelect) {
            var headerColumns = _this.props.headerColumns;
            var newHeaderColumns = headerColumns.map(function (_a) {
                var isHidden = _a.isHidden, restColumn = __rest(_a, ["isHidden"]);
                return (__assign(__assign({}, restColumn), { isHidden: restColumn.isDisabled ? isHidden : !isSelect }));
            });
            _this.setState({
                headerColumns: newHeaderColumns,
            });
        };
        _this.onItemToggle = function (_a, index) {
            var isDisabled = _a.isDisabled;
            return function () {
                if (isDisabled) {
                    return;
                }
                var headerColumns = _this.state.headerColumns;
                var newHeaderColumns = headerColumns.map(function (column, columnIndex) {
                    return index === columnIndex
                        ? __assign(__assign({}, column), { isHidden: !column.isHidden }) : column;
                });
                _this.setState({
                    headerColumns: newHeaderColumns,
                });
            };
        };
        return _this;
    }
    DataTableCustomiseModal.prototype.renderContent = function () {
        var _this = this;
        var headerColumns = this.state.headerColumns;
        return (React.createElement(SelectList, { onSelectAllToggle: this.onSelectAllToggle }, headerColumns.map(function (column, index) {
            return (React.createElement(SelectList.Option, { key: index, isSelected: !headerColumns[index].isHidden, onToggle: _this.onItemToggle(column, index), isDisabled: column.isDisabled }, column.title));
        })));
    };
    DataTableCustomiseModal.prototype.render = function () {
        var isCustomiseModalOpen = this.props.isCustomiseModalOpen;
        return (React.createElement(Modal, { id: "OptionsDialog", type: "medium", isOpened: isCustomiseModalOpen, closeModal: this.onClose, title: "Customise Columns", primaryButton: React.createElement(Button, { type: "primary", isBlock: true, onClick: this.onSubmit }, "Ok"), closeLabel: "Close" }, this.renderContent()));
    };
    DataTableCustomiseModal.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var isCustomiseModalOpen = this.props.isCustomiseModalOpen;
        if (isCustomiseModalOpen &&
            isCustomiseModalOpen !== prevProps.isCustomiseModalOpen) {
            this.setState(getState(this.props));
        }
    };
    return DataTableCustomiseModal;
}(Component));
export { DataTableCustomiseModal };
export default withDataTableContext(DataTableCustomiseModal);
