import produce from 'immer';

import { Query as defaultQuery } from 'lib/stateTemplates';
import { GetSurveyTemplatesState } from './type';
import { Query } from 'lib/query/type';

export const getSurveyTemplatesInitialState: GetSurveyTemplatesState = {
  count: 0,
  data: [],
  isLoaded: false,
  isLoading: false,
  isValid: false,
  query: produce(defaultQuery, (draft: Query) => {
    draft.filter = 1;
    draft.view = 'grid';
    draft.sort = '-created-date';
  }),
};
