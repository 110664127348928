import produce from 'immer';

import {
  addCollector,
  addCollectorSuccess,
  addCollectorFailure,
  clearAddCollector,
} from './actions';
import { transformCollector } from '../../../transformers';
import { addCollectorInitialState } from './state';
import { AddCollectorResponse } from './type';
import { CollectorState } from '../type';
import { ErrorResponse } from 'type';

export function addCollectorReducer(state: CollectorState): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.addCollector.isLoading = true;
    draft.addCollector.isLoaded = false;
    draft.addCollector.isValid = false;
  });
}

export function addCollectorSuccessReducer(
  state: CollectorState,
  data: AddCollectorResponse
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.addCollector.isLoading = false;
    draft.addCollector.isLoaded = true;
    draft.addCollector.isValid = true;
    draft.addCollector.collector = transformCollector(data.data);
  });
}

export function addCollectorFailureReducer(
  state: CollectorState,
  error: ErrorResponse
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.addCollector.isLoading = false;
    draft.addCollector.isLoaded = true;
    draft.addCollector.error = error;
  });
}

export function clearAddCollectorReducer(
  state: CollectorState
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.addCollector = addCollectorInitialState;
  });
}

export const addCollectorReducers = {
  [`${addCollector}`]: addCollectorReducer,
  [`${addCollectorSuccess}`]: addCollectorSuccessReducer,
  [`${addCollectorFailure}`]: addCollectorFailureReducer,
  [`${clearAddCollector}`]: clearAddCollectorReducer,
};
