var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { getClass } from '../../_lib/helper';
/**
 * Table component of the DataTable
 * @param children
 * @param isResponsive
 * @param className
 * @param isFullWidth
 * @constructor
 */
function DataTableWrapper(_a) {
    var children = _a.children, className = _a.className, isFullWidth = _a.isFullWidth;
    var props = {
        className: getClass('elmo-datatable-table', className, {
            'full-width': isFullWidth,
        }),
    };
    return React.createElement("table", __assign({}, props), children);
}
export default DataTableWrapper;
