import React from 'react';

import QuestionSummariesSuspense from '../QuestionSummaries';
import './PDFView.scss';

const PDFView = () => {
  return (
    <div id={'overview-report-view'}>
      <QuestionSummariesSuspense />
    </div>
  );
};

export default PDFView;
