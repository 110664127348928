import produce from 'immer';

import {
  getEmailTemplate,
  getEmailTemplateSuccess,
  getEmailTemplateFailure,
  clearGetEmailTemplate,
} from './actions';
import { transformEmailTemplate } from '../../../transformers';
import { emailTemplateInitialState } from '../state';
import { GetEmailTemplateResponse } from './type';
import { EmailTemplateState } from '../type';
import { ErrorResponse } from 'type';

export function getEmailTemplateReducer(
  state: EmailTemplateState,
  emailTemplateId: number
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getEmailTemplate.isLoading = true;
    draft.getEmailTemplate.isLoaded = false;
    draft.getEmailTemplate.isValid = false;
    draft.getEmailTemplate.emailTemplateId = emailTemplateId;
  });
}

export function getEmailTemplateSuccessReducer(
  state: EmailTemplateState,
  data: GetEmailTemplateResponse
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getEmailTemplate.isLoading = false;
    draft.getEmailTemplate.isLoaded = true;
    draft.getEmailTemplate.isValid = true;
    draft.getEmailTemplate.data = transformEmailTemplate(data.data);
  });
}

export function getEmailTemplateFailureReducer(
  state: EmailTemplateState,
  error: ErrorResponse
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getEmailTemplate.isLoading = false;
    draft.getEmailTemplate.isLoaded = true;
    draft.getEmailTemplate.error = error;
  });
}

export function clearGetEmailTemplateReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getEmailTemplate = emailTemplateInitialState.getEmailTemplate;
  });
}

export const getEmailTemplateReducers = {
  [`${getEmailTemplate}`]: getEmailTemplateReducer,
  [`${getEmailTemplateSuccess}`]: getEmailTemplateSuccessReducer,
  [`${getEmailTemplateFailure}`]: getEmailTemplateFailureReducer,
  [`${clearGetEmailTemplate}`]: clearGetEmailTemplateReducer,
};
