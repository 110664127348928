import produce from 'immer';

import {
  getSurveyAccessList,
  getSurveyAccessListSuccess,
  getSurveyAccessListFailure,
  clearGetSurveyAccessList,
} from './actions';
import { transformSurveyAccessLists } from '../../../transformers';
import { getSurveyAccessListInitialState } from './state';
import { GetSurveyAccessListResponse } from './type';
import { SurveyState } from '../type';
import { ErrorResponse } from 'type';

export function getSurveyAccessListReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyAccessList.isLoading = true;
    draft.getSurveyAccessList.isLoaded = false;
    draft.getSurveyAccessList.isValid = false;
    draft.getSurveyAccessList.count = 0;
    draft.getSurveyAccessList.data = [];
  });
}

export function getSurveyAccessListSuccessReducer(
  state: SurveyState,
  data: GetSurveyAccessListResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyAccessList.isLoading = false;
    draft.getSurveyAccessList.isLoaded = true;
    draft.getSurveyAccessList.isValid = true;
    draft.getSurveyAccessList.count = data.data.count;
    draft.getSurveyAccessList.data = transformSurveyAccessLists(data.data.data);
  });
}

export function getSurveyAccessListFailureReducer(
  state: SurveyState,
  error: ErrorResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyAccessList.isLoading = false;
    draft.getSurveyAccessList.isLoaded = true;
    draft.getSurveyAccessList.error = error;
  });
}

export function clearGetSurveyAccessListReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyAccessList = getSurveyAccessListInitialState;
  });
}

export const getSurveyAccessListReducers = {
  [`${getSurveyAccessList}`]: getSurveyAccessListReducer,
  [`${getSurveyAccessListSuccess}`]: getSurveyAccessListSuccessReducer,
  [`${getSurveyAccessListFailure}`]: getSurveyAccessListFailureReducer,
  [`${clearGetSurveyAccessList}`]: clearGetSurveyAccessListReducer,
};
