import { createReducer } from 'redux-act';

import { uploadBulkUrlCSVReducers } from './uploadBulkUrlCSV/reducers';
import { generateBulkUrlsReducers } from './generateBulkUrls/reducers';
import { getBulkUrlsReducers } from './getBulkUrls/reducers';
import { getBulkUrlReducers } from './getBulkUrl/reducers';
import { bulkUrlInitialState } from './state';

export default createReducer(
  {
    ...generateBulkUrlsReducers,
    ...getBulkUrlReducers,
    ...getBulkUrlsReducers,
    ...uploadBulkUrlCSVReducers,
  },
  bulkUrlInitialState
);
