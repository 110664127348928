import produce from 'immer';

import {
  getUserProfileFilterOptions,
  getUserProfileFilterOptionsFailure,
  getUserProfileFilterOptionsSuccess,
  clearGetUserProfileFilterOptions,
} from './actions';
import { getUserProfileFilterOptionsInitialState } from './state';
import { GetUserProfileFilterOptionsResponse } from './type';
import { ErrorResponse } from 'type';
import { UserState } from '../type';

export function getUserProfileFilterOptionsReducer(
  state: UserState
): UserState {
  return produce(state, (draft) => {
    draft.getUserProfileFilterOptions = {
      ...getUserProfileFilterOptionsInitialState,
    };
    draft.getUserProfileFilterOptions.isLoading = true;
  });
}

export function getUserProfileFilterOptionsSuccessReducer(
  state: UserState,
  data: GetUserProfileFilterOptionsResponse
): UserState {
  return produce(state, (draft) => {
    draft.getUserProfileFilterOptions.isLoaded = true;
    draft.getUserProfileFilterOptions.isLoading = false;
    draft.getUserProfileFilterOptions.isValid = true;
    draft.getUserProfileFilterOptions.filterOptions = data.data;
  });
}

export function getUserProfileFilterOptionsFailureReducer(
  state: UserState,
  error: ErrorResponse
): UserState {
  return produce(state, (draft) => {
    draft.getUserProfileFilterOptions.isLoaded = true;
    draft.getUserProfileFilterOptions.isLoading = false;
    draft.getUserProfileFilterOptions.error = error;
  });
}

export function clearGetUserProfileFilterOptionsReducer(
  state: UserState
): UserState {
  return produce(state, (draft) => {
    draft.getUserProfileFilterOptions = getUserProfileFilterOptionsInitialState;
  });
}

export const getUserProfileFilterOptionsReducers = {
  [`${getUserProfileFilterOptions}`]: getUserProfileFilterOptionsReducer,
  [`${getUserProfileFilterOptionsSuccess}`]:
    getUserProfileFilterOptionsSuccessReducer,
  [`${getUserProfileFilterOptionsFailure}`]:
    getUserProfileFilterOptionsFailureReducer,
  [`${clearGetUserProfileFilterOptions}`]:
    clearGetUserProfileFilterOptionsReducer,
};
