import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetQuestionPayload, GetQuestionResponse } from './type';
import { ErrorResponse, QuestionsQuery } from 'type';

export const getQuestion: ActionCreator<GetQuestionPayload> =
  createAction('GET_QUESTION');

export const getQuestionFromQuery: EmptyActionCreator = createAction(
  'GET_QUESTION_FROM_QUERY'
);

export const getQuestionSuccess: ActionCreator<GetQuestionResponse> =
  createAction('GET_QUESTION_SUCCESS');

export const getQuestionFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_QUESTION_FAILURE'
);

export const updateGetQuestionQuery: ActionCreator<QuestionsQuery> =
  createAction('UPDATE_GET_QUESTION_QUERY');

export const clearGetQuestion: EmptyActionCreator =
  createAction('CLEAR_GET_QUESTION');
