import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateEmailTemplateFailure,
  updateEmailTemplateSuccess,
} from './actions';
import { UpdateEmailTemplatePayload } from './type';
import { updateEmailTemplateApi } from './api';

export interface UpdateEmailTemplateSagaProps {
  error: boolean;
  payload: UpdateEmailTemplatePayload;
  type: string;
}

export const updateEmailTemplateSaga = function* (
  action: UpdateEmailTemplateSagaProps
): SagaIterator {
  try {
    yield call(updateEmailTemplateApi(action.payload));

    yield put(updateEmailTemplateSuccess());
  } catch (errors) {
    yield put(updateEmailTemplateFailure(errors));
  }
};
