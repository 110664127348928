import produce from 'immer';

import {
  getPreviewSurvey,
  getPreviewSurveyFailure,
  getPreviewSurveySuccess,
  clearGetPreviewSurvey,
} from './actions';
import { transformAnonSurvey } from '../../../transformers';
import { getPreviewSurveyInitialState } from './state';
import { GetPreviewSurveyResponse } from './type';
import { surveyInitialState } from '../state';
import { SurveyState } from '../type';
import { ErrorResponse } from 'type';

export function getPreviewSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getPreviewSurvey = { ...getPreviewSurveyInitialState };
    draft.getPreviewSurvey.isLoading = true;
  });
}

export function getPreviewSurveySuccessReducer(
  state: SurveyState,
  data: GetPreviewSurveyResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getPreviewSurvey.isLoaded = true;
    draft.getPreviewSurvey.isLoading = false;
    draft.getPreviewSurvey.isValid = true;
    draft.getPreviewSurvey.survey = transformAnonSurvey(data.data.survey);
  });
}

export function getPreviewSurveyFailureReducer(
  state: SurveyState,
  error: ErrorResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getPreviewSurvey.isLoaded = true;
    draft.getPreviewSurvey.isLoading = false;
    draft.getPreviewSurvey.error = error;
  });
}

export function clearGetPreviewSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getPreviewSurvey = surveyInitialState.getPreviewSurvey;
  });
}

export const getPreviewSurveyReducers = {
  [`${getPreviewSurvey}`]: getPreviewSurveyReducer,
  [`${getPreviewSurveySuccess}`]: getPreviewSurveySuccessReducer,
  [`${getPreviewSurveyFailure}`]: getPreviewSurveyFailureReducer,
  [`${clearGetPreviewSurvey}`]: clearGetPreviewSurveyReducer,
};
