import { isSharedReport } from 'lib/helpers/sharedReports';
import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId/overview-report/pdf-export/true';
const sharedReportApiUrl =
  '/shared-reports/:surveyId/overview-report/pdf-export/true';

export const saveOverviewReportForPdfApi = (
  surveyId: number | string,
  postData: any
) => {
  const route = isSharedReport() ? sharedReportApiUrl : apiUrl;

  return postRequest(
    getRoute(route, { params: { surveyId: surveyId } }),
    postData
  );
};
