import React, { ReactElement } from 'react';

import QuestionSummariesSuspense from 'section/reports/pages/QuestionSummaries';
import './SharedReport.scss';

const SharedReport = (): ReactElement => {
  return <QuestionSummariesSuspense />;
};

export default SharedReport;
