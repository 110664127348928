import React from 'react';

export const onProfileImageError = (
  event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  event.currentTarget.src = '/media/default-profile.jpg';
  event.currentTarget.onerror = null;
};

export const shouldIgnoreError = (url: string): boolean => {
  return (
    ['/site-theme'].includes(url) || url.startsWith('/anon/respondent-progress')
  );
};
