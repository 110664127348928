var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import DataTableTh from './DataTableTh';
import Checkbox from '../../Checkbox';
import { withDataTableContext } from '../DataTableContextConsumer';
var DataTableSelectAllCheckbox = /** @class */ (function (_super) {
    __extends(DataTableSelectAllCheckbox, _super);
    function DataTableSelectAllCheckbox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Called when Select All checkbox is toggled
         */
        _this.handleSelectAllToggle = function () {
            var _a = _this.props, isSelectAllChecked = _a.isSelectAllChecked, setContextState = _a.setContextState, onSelectAllVisibleToggle = _a.onSelectAllVisibleToggle;
            var isChecked = !isSelectAllChecked;
            if (setContextState) {
                setContextState({
                    isSelectAllChecked: isChecked,
                    isSelectAllIndeterminate: false,
                    showSelectAllComponent: isChecked,
                });
            }
            if (onSelectAllVisibleToggle) {
                onSelectAllVisibleToggle(isChecked);
            }
        };
        return _this;
    }
    DataTableSelectAllCheckbox.prototype.render = function () {
        var _a = this.props, isSelectAllChecked = _a.isSelectAllChecked, isSelectAllIndeterminate = _a.isSelectAllIndeterminate;
        return (React.createElement(DataTableTh, { className: "elmo-datatable__row-checkbox" },
            React.createElement(Checkbox, { id: "datatableSelectAll", isChecked: isSelectAllChecked, isIndeterminate: isSelectAllIndeterminate, onChange: this.handleSelectAllToggle, ariaLabel: "Select row" })));
    };
    return DataTableSelectAllCheckbox;
}(React.Component));
export default withDataTableContext(DataTableSelectAllCheckbox);
