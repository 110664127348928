import produce from 'immer';

import {
  createSurvey,
  createSurveyFailure,
  createSurveySuccess,
  clearCreateSurvey,
} from './actions';
import { transformSurvey } from '../../../transformers/survey';
import { createSurveyInitialState } from './state';
import { CreateSurveyResponse } from './type';
import { SurveyState } from '../type';
import { ErrorResponse } from 'type';

export const createSurveyReducer = (state: SurveyState): SurveyState => {
  return produce(state, (draft: SurveyState) => {
    draft.createSurvey = { ...createSurveyInitialState };
    draft.createSurvey.isLoading = true;
  });
};

export const createSurveySuccessReducer = (
  state: SurveyState,
  data: CreateSurveyResponse
): SurveyState => {
  return produce(state, (draft: SurveyState) => {
    draft.createSurvey.isLoading = false;
    draft.createSurvey.isLoaded = true;
    draft.createSurvey.isValid = true;
    draft.createSurvey.data = transformSurvey(data.data);
  });
};

export const createSurveyFailureReducer = (
  state: SurveyState,
  error: ErrorResponse
): SurveyState => {
  return produce(state, (draft: SurveyState) => {
    draft.createSurvey.isLoading = false;
    draft.createSurvey.isLoaded = true;
    draft.createSurvey.error = error;
  });
};

export const clearCreateSurveyReducer = (state: SurveyState): SurveyState => {
  return produce(state, (draft: SurveyState) => {
    draft.createSurvey = createSurveyInitialState;
  });
};

export const createSurveyReducers = {
  [`${createSurvey}`]: createSurveyReducer,
  [`${createSurveySuccess}`]: createSurveySuccessReducer,
  [`${createSurveyFailure}`]: createSurveyFailureReducer,
  [`${clearCreateSurvey}`]: clearCreateSurveyReducer,
};
