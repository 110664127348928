import { createReducer } from 'redux-act';

import { createQuestionCategoryReducers } from './createQuestionCategory/reducers';
import { deleteQuestionCategoryReducers } from './deleteQuestionCategory/reducers';
import { updateQuestionCategoryReducers } from './updateQuestionCategory/reducers';
import { getQuestionCategoriesReducers } from './getQuestionCategories/reducers';
import { getQuestionCategoryReducers } from './getQuestionCategory/reducers';
import { questionCategoryInitialState } from './state';

export default createReducer(
  {
    ...createQuestionCategoryReducers,
    ...deleteQuestionCategoryReducers,
    ...getQuestionCategoriesReducers,
    ...getQuestionCategoryReducers,
    ...updateQuestionCategoryReducers,
  },
  questionCategoryInitialState
);
