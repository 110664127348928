import { Layout, PlaceholderHeader, PlaceholderListTable } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import { isSurveyNotificationsEnabled } from 'lib/helpers/featureFlag';
import NotFound from 'section/errorPages/pages/NotFound';
import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const NotificationHistoryContainer = lazy(() =>
  withDelay(() => import('./NotificationHistoryContainer'))
);

const NotificationHistory = (): ReactElement => {
  if (!isSurveyNotificationsEnabled()) {
    return <NotFound />;
  }

  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={true}
              hasTabs={false}
              hasBackButton={true}
              hasButtons={false}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderListTable cols={3} hasAvatar={false} />
          </Layout.Content>
        </>
      }
    >
      <NotificationHistoryContainer />
    </Suspense>
  );
};

export default NotificationHistory;
