/**
 * Define a very general simple functionality here that can be used accross
 * multiple services or components. A function you want to define here should be
 * very small, and it should be used in multiple places.
 */

/**
 * Extract the component name
 *
 * @                          param Component
 */
export function getComponentName(Component: any) {
  return Component.displayName || Component.name || 'Component';
}

/**
 * Note: Use this function only for testing when you want to have some delay
 *
 * @param path
 * @param time
 */
export function withDelay(callback: any, time: number = 1000): Promise<any> {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(callback());
    }, time);
  });
}

/**
 * A simple queryString helper
 */
export const queryString: QueryStringInterface = {
  parse: (query: string) => {
    if (query) {
      const qs = new URLSearchParams(query);
      const params: any = {};
      qs.forEach((value: any, key: any) => {
        params[key] = qs.get(key);
      });

      return params;
    }
  },
  stringify: (params: any) => {
    const qs = new URLSearchParams('');
    if (params) {
      const keys = Object.keys(params);
      if (keys && Array.isArray(keys)) {
        keys.forEach((key: any) => {
          qs.append(key, params[key]);
        });
      }
    }

    return qs.toString();
  },
};

/**
 * A simple function to generate a route with placeholder
 * For example you pass ('/users/user/:id', {params: {id: 12}})
 * It gives you `/users/user/12`
 *
 * For example you pass ('/users/user/:id', {params: {id: 12}, query: {page: 12}})
 * It gives you `/users/user/12?page=12` *
 *
 * @param path
 * @param param
 */
export function getRoute(path: string, config?: any): string {
  let finalPath = path;
  if (!config) {
    return finalPath;
  }

  // If route param exist, then replace them, for example
  // replace '/users/user/:id' with {id: 12}
  if (config.params) {
    const keys: any = Object.keys(config.params);
    if (Array.isArray(keys)) {
      finalPath = keys.reduce((cPath: string, key: string) => {
        return cPath.replace(`:${key}`, config.params[key]);
      }, path);
    }
  }

  // If route query string params exist
  if (config.query) {
    const search: string = queryString.stringify(config.query);
    if (search !== '') {
      finalPath = `${finalPath}?${search}`;
    }
  }

  return finalPath;
}

/**
 * A simple queryString helper
 */
interface QueryStringInterface {
  parse: (query: string) => any;
  stringify: (params: any) => string;
}

/**
 * Convert a simple JSON with no nested object to formData.
 *
 * @param obj
 */
export function jsonToFormData(obj: any) {
  let params = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.append(key, obj[key]);
    }
  }

  return params;
}

export function immutableInsertItemInArray(
  array: any,
  index: number,
  item: any
) {
  let newArray = array.slice();
  newArray.splice(index, 0, item);

  return newArray;
}

/**
 * Rename keys for object to match those for select box
 *
 * @param obj
 * @param keyField
 * @param labelField
 */
export function renameKeysForSelect(
  obj: any,
  keyField: string,
  labelField: string
) {
  if (obj === undefined) {
    return [];
  }

  return obj.map((val: any) => {
    return {
      id: val.id,
      value: val[keyField],
      label: val[labelField],
    };
  });
}
