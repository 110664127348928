const translations = {
  notFound: {
    header: {
      key: '',
      default: 'Not Found',
    },
    body: {
      key: '',
      default:
        'The server returned a "404 Not Found". Try returning to your home page and try again.',
    },
  },
  noAccess: {
    header: {
      key: '',
      default: 'No Access',
    },
    body: {
      key: '',
      default:
        'Please check with your company admin if you have been granted the necessary permissions.',
    },
  },
  error: {
    header: {
      key: '',
      default: 'Whoops! Something went wrong...',
    },
    body: {
      key: '',
      default: 'Try going back and trying again.',
    },
  },
  underMaintenance: {
    title: {
      key: 'app.underMaintenance.title',
      default: 'Maintenance',
    },
    subtitle1: {
      key: 'app.underMaintenance.subtitle1',
      default: 'We are undergoing a bit of scheduled',
    },
    subtitle2: {
      key: 'app.underMaintenance.subtitle2',
      default: 'maintenance and will be back online soon.',
    },
    content: {
      key: 'app.underMaintenance.content',
      default: 'Sorry for the inconvenience. Please try again in an hour.',
    },
  },
};

export default translations;
