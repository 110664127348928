import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import some from 'lodash/some';
import map from 'lodash/map';

const hasValues = (filteredList: any, filters: any, field: string) => {
  if (isEmpty(filters)) {
    return filteredList;
  }

  const filtersList = map(filters, 'value');

  return filter(filteredList, (user: any) => {
    if (field === 'manager') {
      return some(
        filtersList,
        (filterVal: string) =>
          user.manager !== null && includes(user.manager.id, filterVal)
      );
    }

    return some(filtersList, (filterVal: string) =>
      includes(user[field], filterVal)
    );
  });
};

export const getFilteredUsers = (
  { department, position, location, manager }: any,
  companyUsers: any,
  selectedUsers: any
) => {
  const departmentUsers = hasValues(
    companyUsers.users,
    department,
    'department'
  );
  const positionUsers = hasValues(departmentUsers, position, 'position');
  const locationUsers = hasValues(positionUsers, location, 'location');
  const managerUsers = hasValues(locationUsers, manager, 'manager');

  return sortBy(managerUsers, [
    (user: any) => {
      return !includes(selectedUsers, user.id);
    },
    'firstName',
    'lastName',
  ]);
};

export const getSearchUsers = (searchValue: string, users: any[]) => {
  if (searchValue === '') {
    return users;
  }

  return filter(users, (user: any) => {
    return (
      user.firstName.toLowerCase().indexOf(searchValue) > -1 ||
      user.lastName.toLowerCase().indexOf(searchValue) > -1
    );
  });
};
