import { GetSurveyNotificationHistoryState } from './type';
import { Query } from 'lib/stateTemplates';

export const getSurveyNotificationHistoryInitialState: GetSurveyNotificationHistoryState =
  {
    count: 0,
    data: [],
    isLoaded: false,
    isLoading: false,
    isValid: false,
    query: Query,
  };
