var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import FormItem from '../../FormItem';
import Datepicker from '../../Datepicker';
import { getClass } from '../../_lib/helper';
var DateRangeDatepicker = /** @class */ (function (_super) {
    __extends(DateRangeDatepicker, _super);
    function DateRangeDatepicker() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DateRangeDatepicker.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, updateParentDate = _a.updateParentDate, value = _a.value;
        if (value !== prevProps.value && updateParentDate) {
            updateParentDate(value);
        }
    };
    DateRangeDatepicker.prototype.render = function () {
        var _a = this.props, className = _a.className, placeholder = _a.placeholder, label = _a.label, value = _a.value, onChange = _a.onChange, disabledDate = _a.disabledDate, format = _a.format, isClearable = _a.isClearable, isDisabled = _a.isDisabled, isInline = _a.isInline, ariaLabel = _a.ariaLabel;
        return (React.createElement("div", { className: getClass('elmo-date-range-datepicker', className) },
            label && React.createElement(FormItem.Label, { label: label }),
            React.createElement(Datepicker, { value: value, placeholder: placeholder, onChange: onChange, disabledDate: disabledDate, format: format, isClearable: isClearable, isDisabled: isDisabled, isInline: isInline, ariaLabel: ariaLabel || label })));
    };
    return DateRangeDatepicker;
}(Component));
export default DateRangeDatepicker;
