import { Query } from 'lib/stateTemplates';
import { GetFiltersState } from './type';

export const getFiltersInitialState: GetFiltersState = {
  count: 0,
  data: [],
  isLoaded: false,
  isLoading: false,
  isValid: false,
  query: Query,
  error: undefined,
};
