import produce from 'immer';

import {
  clearCreateQuestion,
  createQuestion,
  createQuestionFailure,
  createQuestionSuccess,
} from './actions';
import { createQuestionInitialState } from './state';
import { questionInitialState } from '../state';
import { QuestionState } from '../type';

export function createQuestionReducer(state: QuestionState): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.createQuestion = { ...createQuestionInitialState };
    draft.createQuestion.isCreating = true;
  });
}

export function createQuestionSuccessReducer(
  state: QuestionState
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.createQuestion.isCreated = true;
    draft.createQuestion.isValid = true;
    draft.createQuestion.isCreating = false;
  });
}

export function createQuestionFailureReducer(
  state: QuestionState,
  error: any
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.createQuestion.isCreated = true;
    draft.createQuestion.isCreating = false;
    draft.createQuestion.error = error;
  });
}

export function clearCreateQuestionReducer(
  state: QuestionState
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.createQuestion = questionInitialState.createQuestion;
  });
}

export const createQuestionReducers = {
  [`${createQuestion}`]: createQuestionReducer,
  [`${createQuestionSuccess}`]: createQuestionSuccessReducer,
  [`${createQuestionFailure}`]: createQuestionFailureReducer,
  [`${clearCreateQuestion}`]: clearCreateQuestionReducer,
};
