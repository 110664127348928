import map from 'lodash/map';

import { AnonSurvey, APIAnonSurvey, APISurvey, Survey } from 'type';
import { transformAnonTheme, transformTheme } from './theme';
import { transformCollectors } from './collector';
import { transformUser } from './user';

export const transformSurvey = (survey: APISurvey): Survey => {
  return {
    anonymityLevel: survey.anonymity_level,
    anonymous: survey.anonymous,
    archivedDate: survey.archived_date,
    closedDate: survey.closed_date,
    collectors: survey.collectors && transformCollectors(survey.collectors),
    createdDate: survey.created_date ? survey.created_date : null,
    description: survey.description,
    id: survey.id,
    ownedBy: survey.owned_by ? transformUser(survey.owned_by) : null,
    pagesCount: survey.pages_count,
    publishedDate: survey.published_date,
    questionsCount: survey.questions_count,
    responsesCount: survey.responses_count,
    status: survey.status,
    structure: survey.structure,
    thankYou: survey.thank_you,
    theme: survey.theme ? transformTheme(survey.theme) : null,
    title: survey.title,
    previewHash: survey.preview_hash,
    isElmoManaged: survey.is_elmo_managed,
  };
};

export const transformSurveys = (surveys: APISurvey[]): Survey[] => {
  return map(surveys, transformSurvey);
};

export const transformAnonSurvey = (survey: APIAnonSurvey): AnonSurvey => {
  return <AnonSurvey>{
    anonymityLevel: survey.anonymity_level,
    anonymous: survey.anonymous,
    description: survey.description,
    id: survey.id,
    pagesCount: survey.pages_count,
    questionsCount: survey.questions_count,
    status: survey.status,
    structure: survey.structure,
    thankYou: survey.thank_you,
    theme: transformAnonTheme(survey.theme),
    title: survey.title,
  };
};
