import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getInvitesFailure,
  getInvitesSuccess,
  updateGetInvitesQuery,
} from './actions';
import { getInvitesInitialState } from './state';
import { getInvitesApi } from './api';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

interface GetInvitesSagaProps extends SagaAction<number> {}

export const getInvitesSaga = function* (
  action: GetInvitesSagaProps
): SagaIterator {
  try {
    const currentQuery = getQuery(getInvitesInitialState.query);
    yield put(updateGetInvitesQuery(currentQuery));

    const data = yield call(getInvitesApi(action.payload, currentQuery));

    yield put(getInvitesSuccess(data));
  } catch (error) {
    yield put(getInvitesFailure(error));
  }
};
