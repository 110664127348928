import { createReducer } from 'redux-act';

import { getDefaultEmailTemplateReducers } from './getDefaultEmailTemplate/reducers';
import { createEmailTemplateReducers } from './createEmailTemplate/reducers';
import { updateEmailTemplateReducers } from './updateEmailTemplate/reducers';
import { getEmailTemplatesReducers } from './getEmailTemplates/reducers';
import { getEmailTemplateReducers } from './getEmailTemplate/reducers';
import { emailTemplateInitialState } from './state';

export default createReducer(
  {
    ...getDefaultEmailTemplateReducers,
    ...createEmailTemplateReducers,
    ...getEmailTemplateReducers,
    ...getEmailTemplatesReducers,
    ...updateEmailTemplateReducers,
  },
  emailTemplateInitialState
);
