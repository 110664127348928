import { DeleteQuestionCategoryPayload } from './type';
import { deleteRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const globalUrl = '/admin/question-categories/:questionCategory';
const localUrl = '/question-categories/:questionCategory';

export const deleteQuestionCategoryApi = ({
  isGlobal,
  categoryId,
}: DeleteQuestionCategoryPayload) => {
  const url = isGlobal ? globalUrl : localUrl;

  return deleteRequest(
    getRoute(url, {
      params: {
        questionCategory: categoryId,
      },
    })
  );
};
