import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateSurveyNotificationFailure,
  updateSurveyNotificationSuccess,
} from './actions';
import { UpdateSurveyNotificationPayload } from './type';
import { updateSurveyNotificationApi } from './api';
import { SagaAction } from 'type';

export const updateSurveyNotificationSaga = function* (
  action: SagaAction<UpdateSurveyNotificationPayload>
): SagaIterator {
  try {
    yield call(updateSurveyNotificationApi(action.payload));

    yield put(updateSurveyNotificationSuccess());
  } catch (error) {
    yield put(updateSurveyNotificationFailure(error));
  }
};
