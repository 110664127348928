import produce from 'immer';

import {
  deleteSurveyTemplate,
  deleteSurveyTemplateFailure,
  deleteSurveyTemplateSuccess,
  clearDeleteSurveyTemplate,
} from './actions';
import { deleteSurveyTemplateInitialState } from './state';
import { SurveyTemplateState } from '../type';
import { ErrorResponse } from 'type';

export const deleteSurveyTemplateReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.deleteSurveyTemplate = { ...deleteSurveyTemplateInitialState };
    draft.deleteSurveyTemplate.isLoading = true;
  });
};

export const deleteSurveyTemplateSuccessReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.deleteSurveyTemplate.isLoading = false;
    draft.deleteSurveyTemplate.isLoaded = true;
    draft.deleteSurveyTemplate.isValid = true;
  });
};

export const deleteSurveyTemplateFailureReducer = (
  state: SurveyTemplateState,
  error: ErrorResponse
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.deleteSurveyTemplate.isLoading = false;
    draft.deleteSurveyTemplate.isLoaded = true;
    draft.deleteSurveyTemplate.error = error;
  });
};

export const clearDeleteSurveyTemplateReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.deleteSurveyTemplate = deleteSurveyTemplateInitialState;
  });
};

export const deleteSurveyTemplateReducers = {
  [`${deleteSurveyTemplate}`]: deleteSurveyTemplateReducer,
  [`${deleteSurveyTemplateSuccess}`]: deleteSurveyTemplateSuccessReducer,
  [`${deleteSurveyTemplateFailure}`]: deleteSurveyTemplateFailureReducer,
  [`${clearDeleteSurveyTemplate}`]: clearDeleteSurveyTemplateReducer,
};
