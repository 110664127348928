import { uploadBulkUrlCSVInitialState } from './uploadBulkUrlCSV/state';
import { generateBulkUrlsInitialState } from './generateBulkUrls/state';
import { getBulkUrlsInitialState } from './getBulkUrls/state';
import { getBulkUrlInitialState } from './getBulkUrl/state';
import { BulkUrlState } from './type';

export const bulkUrlInitialState: BulkUrlState = {
  generateBulkUrls: generateBulkUrlsInitialState,
  getBulkUrl: getBulkUrlInitialState,
  getBulkUrls: getBulkUrlsInitialState,
  uploadBulkUrlCSV: uploadBulkUrlCSVInitialState,
};
