import { APIAdminDetails, AdminDetails } from 'type';

export const transformAdminDetails = (
  details: APIAdminDetails
): AdminDetails => {
  return {
    clientsCount: details.clients_count,
    responseIndustriesCount: details.response_industries_count,
    responsesCount: details.responses_count,
    surveysCount: details.surveys_count,
  };
};
