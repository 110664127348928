import { RouteObject } from 'react-router-dom';
import React from 'react';

import BenchmarkData from './pages/BenchmarkData';
import AdminDetails from './pages/AdminDetails';

const Routes: RouteObject = {
  path: '/admin',
  children: [
    {
      path: 'details',
      element: <AdminDetails />,
    },
    {
      path: 'benchmark-data',
      element: <BenchmarkData />,
    },
  ],
};

export default Routes;
