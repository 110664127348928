import { SelectedFiltersState } from 'type';

export const genderOptions = {
  filterGenderFemale: false,
  filterGenderMale: false,
  filterGenderOther: false,
};

export const ageOptions = {
  from18to24: false,
  from25to34: false,
  from35to44: false,
  from45to54: false,
  from55to64: false,
  from65to65plus: false,
};

export const residencyOptions = {
  australian: false,
  foreign: false,
  workHolidayMaker: false,
};

export const payOptions = {
  level1: false,
  level2: false,
  level3: false,
  level4: false,
  level5: false,
};

export const employmentStatusOptions = {
  fullTime: false,
  partTime: false,
  casual: false,
  labourHire: false,
};

export const reportFilters: SelectedFiltersState = {
  collectors: [],
  associatedFilters: {},
  gender: genderOptions,
  age: ageOptions,
  residency: residencyOptions,
  pay: payOptions,
  employmentStatus: employmentStatusOptions,
  position: null,
  manager: null,
  department: null,
  location: null,
  breakdown: null,
};
