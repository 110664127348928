import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import { loginSharedReportFailure, loginSharedReportSuccess } from './actions';
import { completeLogin } from 'lib/authentication';
import { loginSharedReportApi } from './api';
import { LoginCredentials } from './type';
import { SagaAction } from 'type';

export const loginSharedReportSaga = function* (
  action: SagaAction<LoginCredentials>
): SagaIterator {
  try {
    const data = yield call(loginSharedReportApi(action.payload));

    completeLogin(data.token);

    yield put(loginSharedReportSuccess());
  } catch (error) {
    yield put(loginSharedReportFailure(error));
  }
};
