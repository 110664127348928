import { createReducer } from 'redux-act';

import { mySurveyBulkUrlsAvailableUrlsReducers } from './reducers/BulkUrlsAvailableUrlsReducers';
import { mySurveyBulkUrlsGenerateUrlsReducers } from './reducers/BulkUrlsGenerateUrlsReducers';
import { mySurveyBulkUrlsReducers } from './reducers/BulkUrlsReducers';
import MySurveysInitialState from './state';

export default createReducer(
  {
    ...mySurveyBulkUrlsReducers,
    ...mySurveyBulkUrlsGenerateUrlsReducers,
    ...mySurveyBulkUrlsAvailableUrlsReducers,
  },
  MySurveysInitialState
);
