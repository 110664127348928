import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

/**
 * Get current page path
 */
export const getCurrentPage = (): string => {
  return history.location.pathname;
};

export default history;
