import produce from 'immer';

import {
  getCollectors,
  getCollectorsSuccess,
  getCollectorsFailure,
  updateCollectorsQuery,
  clearGetCollectors,
} from './actions';
import { transformFullCollectors } from '../../../transformers';
import { getCollectorsInitialState } from './state';
import { DefaultQuery, ErrorResponse } from 'type';
import { GetCollectorsResponse } from './type';
import { CollectorState } from '../type';

export function getCollectorsReducer(state: CollectorState): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollectors = { ...getCollectorsInitialState };
    draft.getCollectors.isLoading = true;
  });
}

export function getCollectorsSuccessReducer(
  state: CollectorState,
  data: GetCollectorsResponse
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollectors.isLoading = false;
    draft.getCollectors.isLoaded = true;
    draft.getCollectors.isValid = true;
    draft.getCollectors.count = data.count;
    draft.getCollectors.data = transformFullCollectors(data.data);
  });
}

export function getCollectorsFailureReducer(
  state: CollectorState,
  error: ErrorResponse
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollectors.isLoading = false;
    draft.getCollectors.isLoaded = true;
    draft.getCollectors.error = error;
  });
}

export function updateCollectorListQueryReducer(
  state: CollectorState,
  query: DefaultQuery
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollectors.query = query;
  });
}

export function clearGetCollectorListReducer(
  state: CollectorState
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollectors = getCollectorsInitialState;
  });
}

export const getCollectorsReducers = {
  [`${getCollectors}`]: getCollectorsReducer,
  [`${getCollectorsSuccess}`]: getCollectorsSuccessReducer,
  [`${getCollectorsFailure}`]: getCollectorsFailureReducer,
  [`${updateCollectorsQuery}`]: updateCollectorListQueryReducer,
  [`${clearGetCollectors}`]: clearGetCollectorListReducer,
};
