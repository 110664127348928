import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getAssociatedFiltersFailure,
  getAssociatedFiltersSuccess,
  updateGetAssociatedFiltersQuery,
} from './actions';
import { getAssociatedFiltersInitialState } from './state';
import { GetAssociatedFiltersRequest } from './type';
import { getAssociatedFiltersApi } from './api';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

export const getAssociatedFiltersSaga = function* (
  action: SagaAction<GetAssociatedFiltersRequest>
): SagaIterator {
  try {
    const queryData = getQuery(getAssociatedFiltersInitialState.query);

    yield put(updateGetAssociatedFiltersQuery(queryData));

    const data = yield call(getAssociatedFiltersApi(action.payload, queryData));

    yield put(getAssociatedFiltersSuccess(data));
  } catch (errors) {
    yield put(getAssociatedFiltersFailure(errors));
  }
};
