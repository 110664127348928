var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
function ListStyle(props) {
    return (React.createElement(LoaderHolder, __assign({}, props),
        React.createElement("rect", { x: "0", y: "10", rx: "3", ry: "3", width: "250", height: "10" }),
        React.createElement("rect", { x: "20", y: "30", rx: "3", ry: "3", width: "220", height: "10" }),
        React.createElement("rect", { x: "20", y: "50", rx: "3", ry: "3", width: "170", height: "10" }),
        React.createElement("rect", { x: "0", y: "70", rx: "3", ry: "3", width: "250", height: "10" }),
        React.createElement("rect", { x: "20", y: "90", rx: "3", ry: "3", width: "200", height: "10" }),
        React.createElement("rect", { x: "20", y: "110", rx: "3", ry: "3", width: "80", height: "10" })));
}
export default ListStyle;
