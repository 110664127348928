import { ActionCreator, createAction } from 'redux-act';

import { GetBulkLinksResponse } from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getBulkLinks: ActionCreator<number> =
  createAction('GET_BULK_LINKS');

export const getBulkLinksSuccess: ActionCreator<GetBulkLinksResponse> =
  createAction('GET_BULK_LINKS_SUCCESS');

export const getBulkLinksFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_BULK_LINKS_FAILURE'
);

export const updateGetBulkLinksQuery: ActionCreator<Query> = createAction(
  'UPDATE_GET_BULK_LINKS_QUERY'
);
