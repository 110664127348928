import ScheduleIcon from '@mui/icons-material/Schedule';
import { NativeTable } from 'elmo-elements';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import React from 'react';

import {
  isMessageEditable,
  hasEditableReminder,
} from 'lib/helpers/emailInvites';
import { hasCancelledReminder, isInviteCompleted } from './helpers';
import SummaryCard from 'element/card/components/summaryCard';
import { Scheduled, Sent } from 'constants/messageStatus';
import { renderDateTimeWithTimeZone } from 'lib/date';
import { SubmissionSummaryProps } from './type';
import translations from './translations';
import { isCollectorActive } from 'lib';
import { Actions } from './components';
import { RootState } from 'store/type';
import { t } from 'lib/translation';
import { Message } from 'type';
import './styles.scss';

const Submission = ({
  editSendSchedule,
  editReminder,
  showAddAction,
}: SubmissionSummaryProps) => {
  const invite = useSelector(
    (state: RootState) => state.emailInvites.manageInvite.data
  );

  const inviteParameters = useSelector(
    (state: RootState) => state.emailInvites.manageInvite.parameters
  );

  const collector = useSelector(
    (state: RootState) => state.collector.getCollector
  );

  const isCompleted = isInviteCompleted(invite);

  const isScheduleAndEditable = () => {
    return !(
      !isCollectorActive(collector.collector) ||
      (inviteParameters.isEdit &&
        !isMessageEditable(invite.message) &&
        !hasEditableReminder(invite.reminders))
    );
  };

  if (showAddAction && !isCompleted) {
    return (
      <Actions
        invite={invite}
        inviteParameters={inviteParameters}
        editSendSchedule={editSendSchedule}
        editReminder={editReminder}
        collector={collector.collector}
        showPrimaryAction={true}
      />
    );
  }

  if (!isCompleted) {
    return <></>;
  }

  return (
    <>
      <SummaryCard
        id={'invite-recipients'}
        icon={<ScheduleIcon />}
        sectionTitle={t(translations.sectionTitle)}
        hasPrimaryAction={isScheduleAndEditable()}
        primaryActionOnClick={editSendSchedule}
      >
        <NativeTable>
          <NativeTable.Body>
            {invite.message.status === Scheduled && (
              <NativeTable.Tr>
                <NativeTable.Th>{t(translations.scheduledDate)}</NativeTable.Th>
                <NativeTable.Td>
                  {renderDateTimeWithTimeZone(invite.message.scheduledDate)}
                </NativeTable.Td>
              </NativeTable.Tr>
            )}

            {invite.message.status === Sent && (
              <NativeTable.Tr>
                <NativeTable.Th>{t(translations.sentDate)}</NativeTable.Th>
                <NativeTable.Td>
                  {renderDateTimeWithTimeZone(invite.message.sentDate)}
                </NativeTable.Td>
              </NativeTable.Tr>
            )}

            {map(invite.reminders, (reminder: Message, index: number) => {
              if (reminder.status === Sent) {
                return (
                  <NativeTable.Tr key={index}>
                    <NativeTable.Th>
                      {t(translations.sentReminder, { index: index + 1 })}
                    </NativeTable.Th>
                    <NativeTable.Td>
                      {renderDateTimeWithTimeZone(reminder.sentDate)}
                    </NativeTable.Td>
                  </NativeTable.Tr>
                );
              }

              if (reminder.status === Scheduled) {
                return (
                  <NativeTable.Tr key={index}>
                    <NativeTable.Th>
                      {t(translations.scheduledReminder, { index: index + 1 })}
                    </NativeTable.Th>
                    <NativeTable.Td>
                      {renderDateTimeWithTimeZone(reminder.scheduledDate)}
                    </NativeTable.Td>
                  </NativeTable.Tr>
                );
              }

              return null;
            })}
          </NativeTable.Body>
        </NativeTable>
      </SummaryCard>

      {isCollectorActive(collector.collector) && (
        <Actions
          invite={invite}
          inviteParameters={inviteParameters}
          editSendSchedule={editSendSchedule}
          editReminder={editReminder}
          collector={collector.collector}
          showPrimaryAction={hasCancelledReminder(invite)}
        />
      )}
    </>
  );
};

export default Submission;
