var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Svg from './Svg';
import './LoaderHolder.scss';
export var defaultProps = {
    animate: true,
    ariaLabel: 'Loading interface...',
    baseUrl: '',
    gradientRatio: 2,
    interval: 0.25,
    preserveAspectRatio: 'none',
    primaryColor: '#f0f0f0',
    primaryOpacity: 1,
    rtl: false,
    secondaryColor: '#e0e0e0',
    secondaryOpacity: 1,
    speed: 2,
    style: {},
    width: '100%',
    viewBoxWidth: 400,
    viewBoxHeight: 130,
};
export function InitialComponent(props) {
    return (React.createElement("rect", { x: "0", y: "0", rx: "5", ry: "5", width: props.viewBoxWidth, height: props.viewBoxHeight }));
}
function LoaderHolder(props) {
    var mergedProps = __assign(__assign({}, defaultProps), props);
    var children = props.children ? (props.children) : (React.createElement(InitialComponent, __assign({}, mergedProps)));
    return (React.createElement(Svg, __assign({ className: "elmo-loader" }, mergedProps), children));
}
export default LoaderHolder;
