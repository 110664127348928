import React from 'react';
import { getClass } from '../../_lib/helper';
import './NativeTableTd.scss';
function NativeTableTd(_a) {
    var children = _a.children, className = _a.className, colspan = _a.colspan, rowspan = _a.rowspan, width = _a.width, textAlign = _a.textAlign;
    return (React.createElement("td", { className: getClass('elmo-nativetable-td', className), colSpan: colspan, rowSpan: rowspan, style: {
            width: width,
        }, align: textAlign }, children));
}
export default NativeTableTd;
