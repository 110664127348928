import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetResponseResponse } from './type';
import { ErrorResponse } from 'type';

export const getResponse: ActionCreator<number> = createAction('GET_RESPONSE');

export const getResponseSuccess: ActionCreator<GetResponseResponse> =
  createAction('GET_RESPONSE_SUCCESS');

export const getResponseFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_RESPONSE_FAILURE'
);

export const clearGetResponse: EmptyActionCreator =
  createAction('CLEAR_GET_RESPONSE');
