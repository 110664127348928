import { GetOverviewReportFilterOptionsState } from './type';

export const getOverviewReportFilterOptionsInitialState: GetOverviewReportFilterOptionsState =
  {
    isLoaded: false,
    isLoading: false,
    isValid: false,
    associatedFiltersOptions: [],
    departmentOptions: [],
    locationOptions: [],
    managerOptions: [],
    positionOptions: [],
    lockedFilters: 0,
  };
