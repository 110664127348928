import {
  cancel,
  fork,
  put,
  putResolve,
  select,
  take,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateManageInviteSchedules,
  clearCreateInvite,
  createInvite,
  createInviteFailure,
  createInviteSuccess,
  clearCreateInviteRecipients,
  createInviteRecipients,
  createInviteRecipientsFailure,
  createInviteRecipientsSuccess,
  clearCreateInviteMessage,
  createInviteMessage,
  createInviteMessageFailure,
  createInviteMessageSuccess,
  clearCreateInviteMessageSchedule,
  createInviteMessageSchedule,
  createInviteMessageScheduleFailure,
  createInviteMessageScheduleSuccess,
  clearSendInviteMessage,
  sendInviteMessage,
  sendInviteMessageFailure,
  sendInviteMessageSuccess,
  clearCreateInviteReminderMessage,
  createInviteReminderMessage,
  createInviteReminderMessageFailure,
  createInviteReminderMessageSuccess,
} from '../actions';
import { createInviteReminderMessageSelector } from '../createInviteReminderMessage/selectors';
import {
  inviteParticipantsFailure,
  inviteParticipantsSuccess,
} from './actions';
import { createInviteMessageSelector } from '../createInviteMessage/selectors';
import { createInviteSelector } from '../createInvite/selectors';
import { getInvite } from '../manageInvite/selectors';
import { Recipients } from '../manageInvite/type';

const recipientsPayloadData = (recipientData: Recipients) => {
  if (recipientData.inputType === 'selectTmsProfiles') {
    return { uuids: recipientData.uuids };
  }

  if (recipientData.inputType === 'manualInput') {
    return { emails: recipientData.recipients };
  }

  if (recipientData.inputType === 'csvImport') {
    return { filePath: recipientData.csvUpload.path };
  }

  return {};
};

export const inviteParticipantsTask = function* (action: any): SagaIterator {
  try {
    const isScheduled = action.payload.isScheduled;

    yield putResolve(updateManageInviteSchedules(action.payload.scheduleData));

    const inviteData = yield select(getInvite);

    const recipientListPayload = {
      collectorId: action.payload.collectorId,
      data: { title: inviteData.inviteDetails.recipientGroup },
    };

    yield put(createInvite(recipientListPayload));

    yield take(createInviteSuccess);

    const recipientList = yield select(createInviteSelector);

    const recipientsPayload = {
      collectorId: action.payload.collectorId,
      listId: recipientList.data.id,
      data: recipientsPayloadData(inviteData.recipients),
    };

    yield put(createInviteRecipients(recipientsPayload));

    yield take(createInviteRecipientsSuccess);

    const inviteMessagePayload = {
      collectorId: action.payload.collectorId,
      listId: recipientList.data.id,
      data: {
        subject: inviteData.message.subject,
        message: inviteData.message.content,
        fromName: inviteData.message.senderName,
        plainMessage: '',
      },
    };

    yield put(createInviteMessage(inviteMessagePayload));

    yield take(createInviteMessageSuccess);

    const inviteMessage = yield select(createInviteMessageSelector);

    if (isScheduled) {
      const createSchedulePayload = {
        messageId: inviteMessage.data.id,
        scheduleDate: inviteData.message.scheduledDate,
      };

      yield put(createInviteMessageSchedule(createSchedulePayload));

      yield take(createInviteMessageScheduleSuccess);
    } else {
      yield put(sendInviteMessage(inviteMessage.data.id));

      yield take(sendInviteMessageSuccess);
    }

    const remindersToAdd = inviteData.reminders.length;

    let reminderIndex = 0;
    while (reminderIndex < remindersToAdd) {
      const reminderPayload = {
        collectorId: action.payload.collectorId,
        recipientListId: recipientList.data.id,
        data: {
          subject: inviteData.reminders[reminderIndex].subject,
          message: inviteData.reminders[reminderIndex].content,
          fromName: inviteData.reminders[reminderIndex].senderName,
          plainMessage: '',
        },
      };

      yield put(createInviteReminderMessage(reminderPayload));

      yield take(createInviteReminderMessageSuccess);

      const reminderMessage = yield select(createInviteReminderMessageSelector);

      const reminderSchedulePayload = {
        messageId: reminderMessage.data.id,
        scheduleDate: inviteData.reminders[reminderIndex].scheduledDate,
      };

      yield put(createInviteMessageSchedule(reminderSchedulePayload));

      yield take(createInviteMessageScheduleSuccess);

      reminderIndex++;
    }

    yield put(inviteParticipantsSuccess());
  } catch (error) {
    yield put(inviteParticipantsFailure(error));
  }
};

export const inviteParticipantsSaga = function* (action: any): SagaIterator {
  const task = yield fork(inviteParticipantsTask, action);
  const cancelAction = yield take([
    createInviteFailure,
    createInviteRecipientsFailure,
    createInviteMessageFailure,
    sendInviteMessageFailure,
    createInviteMessageScheduleFailure,
    createInviteReminderMessageFailure,
  ]);
  yield cancel(task);
  yield put(inviteParticipantsFailure(cancelAction));
};

export const inviteParticipantsClearStateSaga = function* (): SagaIterator {
  yield put(clearCreateInviteRecipients());
  yield put(clearCreateInvite());
  yield put(clearCreateInviteMessage());
  yield put(clearSendInviteMessage());
  yield put(clearCreateInviteMessageSchedule());
  yield put(clearCreateInviteReminderMessage());
};
