import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateQuestionSettingsPayload } from './type';
import { QuestionSettingsBulkGroup } from 'type';

export const updateQuestionSettings: ActionCreator<UpdateQuestionSettingsPayload> =
  createAction('UPDATE_QUESTION_SETTINGS');

export const bulkUpdateQuestionSettings: ActionCreator<QuestionSettingsBulkGroup> =
  createAction('BULK_UPDATE_QUESTION_SETTINGS');

export const clearUpdateQuestionSettings: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_QUESTION_SETTINGS'
);
