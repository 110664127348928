import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/filters/:filterId/values';

export const getFilterValuesApi = (filterId: number, query: Query) => {
  return getRequest(getRoute(url, { params: { filterId: filterId } }), {
    params: query,
  });
};
