import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId/notification';

export const getSurveyNotificationsApi = (surveyId: number, query: Query) => {
  return getRequest(getRoute(apiUrl, { params: { surveyId: surveyId } }), {
    params: query,
  });
};
