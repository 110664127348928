import { PlaceholderHeader } from 'elmo-elements';
import React, { lazy } from 'react';

import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const BulkUrlsContainer = lazy(() => withDelay(() => import('./container')));

function BulkUrlPage() {
  return (
    <Suspense
      fallback={<PlaceholderHeader hasSubHeading={false} hasTabs={false} />}
    >
      <BulkUrlsContainer />
    </Suspense>
  );
}

export default BulkUrlPage;
