import { GetQuestionPayload, GetQuestionResponse } from './type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const globalUrl = '/admin/questions/:questionId';
const localUrl = '/questions/:questionId';

export const getQuestionApi = ({
  isGlobal,
  questionId,
}: GetQuestionPayload) => {
  const url = isGlobal ? globalUrl : localUrl;

  return getRequest<GetQuestionResponse>(
    getRoute(url, {
      params: {
        questionId,
      },
    })
  );
};
