import React from 'react';
import { ThemeContextConsumer } from './ThemeContext';
function ThemeAware(_a) {
    var render = _a.render;
    return (React.createElement(ThemeContextConsumer, null, function (theme) {
        return theme && (React.createElement(React.Fragment, null, render({
            containerPadding: (theme.container && theme.container.padding) || '',
            containerWidth: (theme.container && theme.container.width) || '',
            profileColor: (theme.profile && theme.profile.color) || '',
            profileBackgroundColor: (theme.profile && theme.profile.backgroundColor) || '',
            adminColor: (theme.admin && theme.admin.color) || '',
            adminBackgroundColor: (theme.admin && theme.admin.backgroundColor) || '',
            menuPadding: (theme.menu && theme.menu.padding) || '',
            menuFont: (theme.menu && theme.menu.font) || '',
            menuFontColor: (theme.menu && theme.menu.fontColor) || '',
            menuHoverFontColor: (theme.menu && theme.menu.hoverFontColor) || '',
            menuBackgroundColor: (theme.menu && theme.menu.backgroundColor) || '',
            menuBackgroundColorHover: (theme.menu && theme.menu.backgroundColorHover) || '',
        })));
    }));
}
export default ThemeAware;
