import produce from 'immer';

import {
  getDefaultEmailTemplate,
  getDefaultEmailTemplateFailure,
  getDefaultEmailTemplateSuccess,
} from './actions';
import { EmailTemplateState } from '../type';

export function getDefaultEmailTemplateReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getDefaultEmailTemplate.isLoaded = false;
    draft.getDefaultEmailTemplate.isLoading = true;
    draft.getDefaultEmailTemplate.isValid = false;
    draft.getDefaultEmailTemplate.data = undefined;
  });
}

export function getDefaultEmailTemplateSuccessReducer(
  state: EmailTemplateState,
  data: any
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getDefaultEmailTemplate.isLoaded = true;
    draft.getDefaultEmailTemplate.isLoading = false;
    draft.getDefaultEmailTemplate.isValid = true;
    draft.getDefaultEmailTemplate.data = data.data;
  });
}

export function getDefaultEmailTemplateFailureReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.getDefaultEmailTemplate.isLoaded = true;
    draft.getDefaultEmailTemplate.isLoading = false;
    draft.getDefaultEmailTemplate.isValid = false;
  });
}

export const getDefaultEmailTemplateReducers = {
  [`${getDefaultEmailTemplate}`]: getDefaultEmailTemplateReducer,
  [`${getDefaultEmailTemplateSuccess}`]: getDefaultEmailTemplateSuccessReducer,
  [`${getDefaultEmailTemplateFailure}`]: getDefaultEmailTemplateFailureReducer,
};
