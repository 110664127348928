import { RouteObject } from 'react-router-dom';
import React from 'react';

import CreateQuestion from './pages/CreateQuestion';
import QuestionList from './pages/QuestionList';
import EditQuestion from './pages/EditQuestion';

export const QUESTION_LIBRARY_PATH = '/question-library';

const routes: RouteObject = {
  path: QUESTION_LIBRARY_PATH,
  children: [
    {
      path: `${QUESTION_LIBRARY_PATH}/global/:id/edit`,
      element: <EditQuestion isGlobal />,
    },
    {
      path: `${QUESTION_LIBRARY_PATH}/:id/edit`,
      element: <EditQuestion />,
    },
    {
      path: `${QUESTION_LIBRARY_PATH}/create`,
      element: <CreateQuestion />,
    },
    {
      path: QUESTION_LIBRARY_PATH,
      element: <QuestionList />,
    },
  ],
};

export default routes;
