import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import { getBulkLinkBatchFailure, getBulkLinkBatchSuccess } from './actions';
import { GetBulkLinkBatchRequest } from './type';
import { getBulkLinkBatchApi } from './api';
import { SagaAction } from 'type';

export const getBulkLinkBatchSaga = function* (
  action: SagaAction<GetBulkLinkBatchRequest>
): SagaIterator {
  try {
    const data = yield call(getBulkLinkBatchApi(action.payload));

    yield put(getBulkLinkBatchSuccess(data));
  } catch (error) {
    yield put(getBulkLinkBatchFailure(error));
  }
};
