import FroalaEditor from 'froala-editor';

import { isPlaceholdersEnabled } from 'lib/helpers/featureFlag';
import { StringMap } from 'type';

export const registerPlaceholderCommand = (
  additionalPlaceholders?: StringMap<string>
) => {
  if (!isPlaceholdersEnabled()) {
    return;
  }

  FroalaEditor.RegisterCommand('placeholders', {
    showOnMobile: true,
    icon: '<span class="placeholder-label">Placeholders</span>',
    title: 'Insert Placeholder',
    type: 'dropdown',
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    options: {
      '::SURVEY_TITLE::': '::SURVEY_TITLE::',
      '::SURVEY_CREATED_DATE::': '::SURVEY_CREATED_DATE::',
      '::COLLECTOR_TITLE::': '::COLLECTOR_TITLE::',
      '::COLLECTOR_TYPE::': '::COLLECTOR_TYPE::',
      '::COLLECTOR_CREATED_DATE::': '::COLLECTOR_CREATED_DATE::',
      ...additionalPlaceholders,
    },
    callback: function (
      this: FroalaEditor.FroalaEditor,
      buttonName: string,
      value: string
    ) {
      this.html.insert(value);
    },
  });
};
