import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetPreviewSurveyResponse } from './type';
import { ErrorResponse } from 'type';

export const getPreviewSurvey: ActionCreator<string> =
  createAction('GET_PREVIEW_SURVEY');

export const getPreviewSurveySuccess: ActionCreator<GetPreviewSurveyResponse> =
  createAction('GET_PREVIEW_SURVEY_SUCCESS');

export const getPreviewSurveyFailure: ActionCreator<ErrorResponse> =
  createAction('GET_PREVIEW_SURVEY_FAILURE');

export const clearGetPreviewSurvey: EmptyActionCreator = createAction(
  'CLEAR_GET_PREVIEW_SURVEY'
);
