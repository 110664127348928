import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createInviteMessageScheduleFailure,
  createInviteMessageScheduleSuccess,
} from './actions';
import { createInviteMessageScheduleApi } from './api';

export const createInviteMessageScheduleSaga = function* (
  action: any
): SagaIterator {
  try {
    const data = yield call(
      createInviteMessageScheduleApi(
        action.payload.messageId,
        action.payload.scheduleDate
      )
    );

    yield put(createInviteMessageScheduleSuccess(data));
  } catch (error) {
    yield put(createInviteMessageScheduleFailure(error));
  }
};
