import { getLatestCreatedBulkLinkInitialState } from './getLatestCreatedBulkLink/state';
import { uploadBulkPasswordCSVInitialState } from './uploadBulkPasswordCSV/state';
import { createIndividualLinkInitialState } from './createIndividualLink/state';
import { closeIndividualLinkInitialState } from './closeIndividualLink/state';
import { getIndividualLinksInitialState } from './getIndividualLinks/state';
import { updateBulkPasswordInitialState } from './updateBulkPassword/state';
import { getBulkLinkBatchInitialState } from './getBulkLinkBatch/state';
import { createBulkLinksInitialState } from './createBulkLinks/state';
import { getBulkLinksInitialState } from './getBulkLinks/state';
import { ShareReportState } from './type';

export const shareReportInitialState: ShareReportState = {
  closeIndividualLink: closeIndividualLinkInitialState,
  createBulkLinks: createBulkLinksInitialState,
  createIndividualLink: createIndividualLinkInitialState,
  getBulkLinkBatch: getBulkLinkBatchInitialState,
  getBulkLinks: getBulkLinksInitialState,
  getIndividualLinks: getIndividualLinksInitialState,
  getLatestCreatedBulkLink: getLatestCreatedBulkLinkInitialState,
  updateBulkPassword: updateBulkPasswordInitialState,
  uploadBulkPasswordCSV: uploadBulkPasswordCSVInitialState,
};
