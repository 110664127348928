import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetAdminDetailsResponse } from './type';
import { ErrorResponse } from 'type';

export const getAdminDetails: EmptyActionCreator =
  createAction('GET_ADMIN_DETAILS');

export const getAdminDetailsSuccess: ActionCreator<GetAdminDetailsResponse> =
  createAction('GET_ADMIN_DETAILS_SUCCESS');

export const getAdminDetailsFailure: ActionCreator<ErrorResponse> =
  createAction('GET_ADMIN_DETAILS_FAILURE');
