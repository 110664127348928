import map from 'lodash/map';

import { APIBulkUrl, BulkUrl } from 'type';
import { transformUser } from './user';

export const transformBulkUrl = (bulkUrl: APIBulkUrl): BulkUrl => {
  return {
    collectorsCount: bulkUrl.collectors_count,
    createdDate: bulkUrl.created_date,
    createdUser: transformUser(bulkUrl.created_user),
    generateCompleted: bulkUrl.generate_completed,
    id: bulkUrl.id,
    title: bulkUrl.title,
    updatedDate: bulkUrl.updated_date,
  };
};

export const transformBulkUrls = (bulkUrls: APIBulkUrl[]): BulkUrl[] => {
  return map(bulkUrls, transformBulkUrl);
};
