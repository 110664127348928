import React from 'react';
import { getClass } from '../../_lib/helper';
import './RadioWrapper.scss';
function RadioWrapper(_a) {
    var children = _a.children, checked = _a.checked, disabled = _a.disabled, onKeyUp = _a.onKeyUp, id = _a.id, className = _a.className;
    var radioWrapperClassNames = getClass('elmo-radio', className, {
        checked: checked,
        disabled: disabled,
    });
    return (React.createElement("label", { id: id, className: radioWrapperClassNames, "aria-checked": checked, tabIndex: 0, onKeyUp: onKeyUp, "data-testid": "elmo-radio-" + (id || 'default') }, children));
}
export default RadioWrapper;
