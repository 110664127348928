import React, { Children } from 'react';
import DescriptionListItem from './component/DescriptionListItem';
import { getClass } from '../_lib/helper';
import './DescriptionList.scss';
// TODO: Change style property name to something else, clashes with default property names
function DescriptionList(_a) {
    var _b;
    var id = _a.id, children = _a.children, style = _a.style, className = _a.className;
    if (style === 'align-horizontal') {
        children = Children.map(children, function (child) {
            return React.cloneElement(child, { hasDivider: true });
        });
    }
    return (React.createElement("dl", { id: id, "data-testid": "elmo-dl-" + (id || 'default'), className: getClass('elmo-dl', className, (_b = {}, _b["" + style] = style, _b)) }, children));
}
DescriptionList.Item = DescriptionListItem;
export default DescriptionList;
