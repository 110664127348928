import React from 'react';
import { Dropdown } from '../../main';
function ActionCardHeaderOptionsDropdown(_a) {
    var icon = _a.icon, dropdownOptions = _a.dropdownOptions, ariaLabel = _a.ariaLabel, id = _a.id, isDisabled = _a.isDisabled;
    var dropdownChildren = [];
    dropdownOptions.forEach(function (option, index) {
        dropdownChildren.push(React.createElement(Dropdown.Item, { value: null, onClick: option.onClick, isDisabled: option.isDisabled, key: index },
            option.icon,
            " ",
            option.label));
        if (option.withDivider) {
            dropdownChildren.push(React.createElement(Dropdown.Divider, { type: "short", key: index + 100 }));
        }
    });
    return (React.createElement(Dropdown, { isDisabled: isDisabled, icon: icon, position: 'bottom-end', ariaLabel: ariaLabel, id: id }, dropdownChildren));
}
export default ActionCardHeaderOptionsDropdown;
