import produce from 'immer';

import {
  deleteSurvey,
  deleteSurveySuccess,
  deleteSurveyFailure,
  clearDeleteSurvey,
} from './actions';
import { deleteSurveyInitialState } from './state';
import { SurveyState } from '../type';
import { ErrorResponse } from 'type';

export function deleteSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.deleteSurvey.isLoading = true;
    draft.deleteSurvey.isLoaded = false;
    draft.deleteSurvey.isValid = false;
    draft.deleteSurvey.error = undefined;
  });
}

export function deleteSurveySuccessReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.deleteSurvey.isLoading = false;
    draft.deleteSurvey.isLoaded = true;
    draft.deleteSurvey.isValid = true;
  });
}

export function deleteSurveyFailureReducer(
  state: SurveyState,
  error: ErrorResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.deleteSurvey.isLoading = false;
    draft.deleteSurvey.isLoaded = true;
    draft.deleteSurvey.error = error;
  });
}

export function clearDeleteSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.deleteSurvey = deleteSurveyInitialState;
  });
}

export const deleteSurveyReducers = {
  [`${deleteSurvey}`]: deleteSurveyReducer,
  [`${deleteSurveySuccess}`]: deleteSurveySuccessReducer,
  [`${deleteSurveyFailure}`]: deleteSurveyFailureReducer,
  [`${clearDeleteSurvey}`]: clearDeleteSurveyReducer,
};
