var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import StarRatingComponent from './StarRatingComponent';
import { StarHalfIcon, StarBorderIcon, StarIcon } from '../../../Icon';
export var StarRatingDefaultProps = {
    value: 0,
    starCount: 5,
    customIcon: React.createElement(StarIcon, null),
    customIconHalf: React.createElement(StarHalfIcon, null),
    emptyCustomIcon: React.createElement(StarBorderIcon, null),
};
function renderStarIconHalf(IconHalf, color) {
    var style = {};
    if (color) {
        style.color = color;
    }
    return React.createElement("span", { style: style }, IconHalf);
}
function calculateHalfStar(nextValue, event, isHalfStarFocused) {
    var newRatingValue = nextValue;
    var left = event.currentTarget.getBoundingClientRect().left;
    var xPosition = (event.pageX - left) / event.currentTarget.offsetWidth;
    // Check if user use mouse or keyboard, when user use keayboard event.pageX equal zero
    if ((!event.pageX && isHalfStarFocused) ||
        (event.pageX && xPosition <= 0.5)) {
        newRatingValue -= 0.5;
    }
    return newRatingValue;
}
var RatingStar = /** @class */ (function (_super) {
    __extends(RatingStar, _super);
    function RatingStar(props) {
        var _this = _super.call(this, props) || this;
        _this.handleOnStarClick = function (nextValue, prevValue, name, event, isHalfStarFocused) {
            var _a = _this.props, isDisabled = _a.isDisabled, isHalfStarCount = _a.isHalfStarCount, onClick = _a.onClick;
            var newRatingValue = nextValue;
            if (nextValue === undefined) {
                return;
            }
            if (isDisabled) {
                return;
            }
            if (isHalfStarCount) {
                newRatingValue = calculateHalfStar(nextValue, event, isHalfStarFocused);
            }
            _this.setState({ value: newRatingValue });
            if (onClick) {
                onClick(nextValue, prevValue, name, event);
            }
        };
        _this.renderStarIcon = function () {
            return _this.props.customIcon;
        };
        _this.renderEmptyStarIcon = function () {
            return _this.props.emptyCustomIcon;
        };
        _this.state = {
            value: props.value,
        };
        return _this;
    }
    RatingStar.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement(StarRatingComponent, { id: this.props.id, name: this.props.name, value: this.state.value, count: this.props.count, onClick: this.handleOnStarClick, render: this.renderStarIcon, renderIconHalf: function () {
                    return renderStarIconHalf(_this.props.customIconHalf, _this.props.color);
                }, renderEmptyIcon: this.renderEmptyStarIcon, color: this.props.color, emptyColor: this.props.emptyColor, isDisabled: this.props.isDisabled, type: this.props.type, hasLabel: this.props.hasLabel, size: this.props.size, isHalfStarCount: this.props.isHalfStarCount, legend: this.props.legend })));
    };
    RatingStar.defaultProps = StarRatingDefaultProps;
    return RatingStar;
}(Component));
export default RatingStar;
