import { Navigate, RouteObject } from 'react-router-dom';
import React from 'react';

import OverviewListPage from './components/overviewList';

const ReportListRoutes: RouteObject = {
  path: '/reports',
  children: [
    {
      path: '/reports/survey-overview/:surveyId/share',
      element: (
        <Navigate to={'/surveys/:surveyId/reports/question-summaries/share'} />
      ),
    },
    {
      path: '/reports/survey-overview/active',
      element: <OverviewListPage />,
    },
    {
      path: '/reports/survey-overview/archived',
      element: <OverviewListPage />,
    },
    {
      path: '/reports/survey-overview',
      element: (
        <Navigate
          to={`/reports/survey-overview/active${document.location.search}`}
        />
      ),
    },
  ],
};

export default ReportListRoutes;
