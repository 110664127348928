const translations = {
  title: {
    key: 'EmailInvites.manageInvite.steps.recipients.manualInput.title',
    default: 'Participants - Enter emails manually',
  },
  primaryButton: {
    saveAndClose: {
      key: 'EmailInvites.manageInvite.steps.recipients.manualInput.confirmationButton',
      default: 'Save & Close',
    },
    saveAndContinue: {
      key: 'EmailInvites.manageInvite.steps.recipients.manualInput.confirmationButton',
      default: 'Save & Continue',
    },
  },
  backButton: {
    key: 'EmailInvites.manageInvite.steps.recipients.manualInput.backButton',
    default: 'Previous',
  },
  recipientsField: {
    title: {
      key: 'EmailInvites.manageInvite.steps.recipients.manualInput.recipientsField.title',
      default: 'Copy and paste from list or type in manually',
    },
    recipientsCount: {
      key: 'EmailInvites.manageInvite.steps.recipients.manualInput.recipientsField.recipientsCount',
      default: '{{count}} participant',
    },
    recipientsCountPlural: {
      key: 'EmailInvites.manageInvite.steps.recipients.manualInput.recipientsField.recipientsCount_plural',
      default: '{{count}} participants',
    },
  },
};

export default translations;
