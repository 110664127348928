import { Button, NativeTable, Row } from 'elmo-elements';
import PeopleIcon from '@mui/icons-material/People';
import { useSelector } from 'react-redux';
import React from 'react';

import SummaryCard from 'element/card/components/summaryCard';
import { RecipientsSummaryProps } from './type';
import translations from './translations';
import { t } from 'lib/translation';

const Recipients = ({
  editRecipients,
  showSummary,
  showAddAction,
}: RecipientsSummaryProps) => {
  const parameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );
  const recipientDetails = useSelector(
    (state: any) => state.emailInvites.manageInvite.data.recipients
  );

  const recipientsCount = () => {
    if (recipientDetails.inputType === 'manualInput') {
      return recipientDetails.recipients.length;
    }

    if (recipientDetails.inputType === 'selectTmsProfiles') {
      return recipientDetails.uuids.length;
    }

    if (recipientDetails.inputType === 'csvImport') {
      return recipientDetails.csvUpload.recipientsCount;
    }

    return 0;
  };

  const contactBy = () => {
    if (recipientDetails.inputType === 'manualInput') {
      return t(translations.manualInput);
    }

    if (recipientDetails.inputType === 'selectTmsProfiles') {
      return t(translations.tmsProfileSelect);
    }

    if (recipientDetails.inputType === 'csvImport') {
      return t(translations.csvUpload);
    }

    return 'unknown';
  };

  if (showAddAction && !showSummary) {
    return (
      <Row justifyContent={'center'}>
        <Button
          id={'manage-invites-recipients'}
          type={'primary'}
          onClick={editRecipients}
        >
          {t(translations.addButton)}
        </Button>
      </Row>
    );
  }

  if (!showSummary) {
    return <></>;
  }

  return (
    <>
      <SummaryCard
        id={'invite-recipients'}
        icon={<PeopleIcon />}
        sectionTitle={t(translations.sectionTitle)}
        hasPrimaryAction={!parameters.isEdit}
        primaryActionOnClick={editRecipients}
      >
        <NativeTable>
          <NativeTable.Body>
            <NativeTable.Tr>
              <NativeTable.Th>{t(translations.recipientsType)}</NativeTable.Th>
              <NativeTable.Td>{contactBy()}</NativeTable.Td>
            </NativeTable.Tr>

            <NativeTable.Tr>
              <NativeTable.Th>{t(translations.recipientCount)}</NativeTable.Th>
              <NativeTable.Td>{recipientsCount()}</NativeTable.Td>
            </NativeTable.Tr>
          </NativeTable.Body>
        </NativeTable>
      </SummaryCard>
    </>
  );
};

export default Recipients;
