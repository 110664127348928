var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import Button from '../../Button';
import { ChevronLeftIcon, ChevronRightIcon } from '../../Icon';
import styled from 'styled-components';
var CollapseButtonStyle = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #fff;\n  width: 36px !important;\n  height: 36px !important;\n  padding: 0 !important;\n  min-width: 36px !important;\n  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.07);\n"], ["\n  background: #fff;\n  width: 36px !important;\n  height: 36px !important;\n  padding: 0 !important;\n  min-width: 36px !important;\n  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.07);\n"])));
function CollapseButton(_a) {
    var onLayoutToggle = _a.onLayoutToggle, isMenuCollapsed = _a.isMenuCollapsed;
    return (React.createElement("div", { className: "collapse-panel", onClick: onLayoutToggle },
        React.createElement("span", { className: "hover-line" }),
        React.createElement("div", { className: "collapse-panel__collapse" },
            React.createElement(CollapseButtonStyle, { icon: !isMenuCollapsed ? React.createElement(ChevronLeftIcon, null) : React.createElement(ChevronRightIcon, null), isCircular: true, ariaLabel: isMenuCollapsed ? 'Expand' : 'Collapse' }))));
}
export default CollapseButton;
var templateObject_1;
