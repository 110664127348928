import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { deleteThemeFailure, deleteThemeSuccess } from './actions';
import { deleteThemeApi } from './api';
import { SagaAction } from 'type';

export const deleteThemeSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    yield call(deleteThemeApi(action.payload));

    yield put(deleteThemeSuccess());
  } catch (error) {
    yield put(deleteThemeFailure());
  }
};
