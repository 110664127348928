import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const deleteSurvey: ActionCreator<number> =
  createAction('DELETE_SURVEY');

export const deleteSurveySuccess: EmptyActionCreator = createAction(
  'DELETE_SURVEY_SUCCESS'
);

export const deleteSurveyFailure: ActionCreator<ErrorResponse> = createAction(
  'DELETE_SURVEY_FAILURE'
);

export const clearDeleteSurvey: EmptyActionCreator = createAction(
  'CLEAR_DELETE_SURVEY'
);
