import { call, put } from 'redux-saga/effects';

import { updateThemeFailure, updateThemeSuccess } from './actions';
import { UpdateThemeData } from './type';
import { updateThemeApi } from './api';
import { SagaAction } from 'type';

export const updateThemeSaga = function* (action: SagaAction<UpdateThemeData>) {
  try {
    yield call(updateThemeApi(action.payload));
    yield put(updateThemeSuccess());
  } catch (err) {
    yield put(updateThemeFailure(err));
  }
};
