import produce from 'immer';

import {
  getSurveyCollectorUsage,
  getSurveyCollectorUsageSuccess,
  getSurveyCollectorUsageFailure,
  clearGetSurveyCollectorUsage,
} from './actions';
import { getSurveyCollectorUsageInitialState } from './state';
import { GetSurveyCollectorUsageResponse } from './type';
import { SurveyState } from '../type';
import { ErrorResponse } from 'type';

export function getSurveyCollectorUsageReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyCollectorUsage.isLoading = true;
    draft.getSurveyCollectorUsage.isLoaded = false;
    draft.getSurveyCollectorUsage.isValid = false;
    draft.getSurveyCollectorUsage.count = 0;
    draft.getSurveyCollectorUsage.collectorsInUse = [];
    draft.getSurveyCollectorUsage.error = undefined;
  });
}

export function getSurveyCollectorUsageSuccessReducer(
  state: SurveyState,
  data: GetSurveyCollectorUsageResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyCollectorUsage.isLoading = false;
    draft.getSurveyCollectorUsage.isLoaded = true;
    draft.getSurveyCollectorUsage.isValid = true;
    draft.getSurveyCollectorUsage.count = data.data.count;
    draft.getSurveyCollectorUsage.collectorsInUse = data.data.collectorsInUse;
  });
}

export function getSurveyCollectorUsageFailureReducer(
  state: SurveyState,
  error: ErrorResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyCollectorUsage.isLoading = false;
    draft.getSurveyCollectorUsage.isLoaded = true;
    draft.getSurveyCollectorUsage.error = error;
  });
}

export function clearGetSurveyCollectorUsageReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyCollectorUsage = getSurveyCollectorUsageInitialState;
  });
}

export const getSurveyCollectorUsageReducers = {
  [`${getSurveyCollectorUsage}`]: getSurveyCollectorUsageReducer,
  [`${getSurveyCollectorUsageSuccess}`]: getSurveyCollectorUsageSuccessReducer,
  [`${getSurveyCollectorUsageFailure}`]: getSurveyCollectorUsageFailureReducer,
  [`${clearGetSurveyCollectorUsage}`]: clearGetSurveyCollectorUsageReducer,
};
