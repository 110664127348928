import { getCurrentPage } from '../history';

export const isSharedReport = (): boolean => {
  const currentPage = getCurrentPage();

  return currentPage.startsWith('/report/');
};

export const isSharedReportLoginPage = (): boolean => {
  const currentPage = getCurrentPage();

  return currentPage.startsWith('/report/') && currentPage.endsWith('/login');
};

export const getReportHash = (): string => {
  const currentPage = getCurrentPage();

  const parts = currentPage.split('/');

  return parts[2];
};
