import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createSurveyTemplateFailure,
  createSurveyTemplateSuccess,
} from './actions';
import { CreateSurveyTemplatePayload } from './type';
import { createSurveyTemplateApi } from './api';
import { SagaAction } from 'type';

export const createSurveyTemplateSaga = function* (
  action: SagaAction<CreateSurveyTemplatePayload>
): SagaIterator {
  try {
    const data = yield call(
      createSurveyTemplateApi(action.payload.isGlobal, action.payload.payload)
    );
    yield put(createSurveyTemplateSuccess(data));
  } catch (error) {
    yield put(createSurveyTemplateFailure(error));
  }
};
