import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { cloneSurveyFailure } from './actions';
import { cloneSurveyApi } from './api';
import { SagaAction } from 'type';

export const cloneSurveySaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const data = yield call(cloneSurveyApi(action.payload));

    window.location.href = '/surveys/' + data.data.id + '/edit';
  } catch (error) {
    yield put(cloneSurveyFailure(error));
  }
};
