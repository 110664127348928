import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import map from 'lodash/map';
import produce from 'immer';

import {
  clearManageInvite,
  removeManageInviteReminder,
  updateManageInviteBulk,
  updateManageInviteDetails,
  updateManageInviteMessage,
  updateManageInviteParameter,
  updateManageInviteRecipients,
  updateManageInviteReminders,
  updateManageInviteSchedules,
} from './actions';
import {
  InviteDetails,
  ManageInvitesData,
  Recipients,
  ReminderSchedules,
  Schedules,
} from './type';
import { manageInviteInitialState } from './state';
import { InvitesState } from '../type';
import { Message } from 'type';

export function updateManageInviteBulkReducer(
  state: InvitesState,
  data: ManageInvitesData
) {
  return produce(state, (draft) => {
    draft.manageInvite.data = { ...draft.manageInvite.data, ...data };
  });
}

export function updateManageInviteDetailsReducer(
  state: InvitesState,
  data: InviteDetails
) {
  return produce(state, (draft) => {
    draft.manageInvite.data.inviteDetails = data;
  });
}

export function updateManageInviteRecipientsReducer(
  state: InvitesState,
  data: Recipients
) {
  return produce(state, (draft) => {
    draft.manageInvite.data.recipients = data;
  });
}

export function updateManageInviteMessageReducer(
  state: InvitesState,
  data: Message
) {
  return produce(state, (draft) => {
    draft.manageInvite.data.message = data;
  });
}

export function updateManageInviteSchedulesReducer(
  state: InvitesState,
  data: Schedules
) {
  const reminderMessages: Message[] = map(
    state.manageInvite.data.reminders,
    (reminder: Message) => {
      const schedule: ReminderSchedules | undefined = find(data.reminders, [
        'id',
        reminder.id,
      ]);

      return {
        ...reminder,
        scheduledDate: schedule ? schedule.scheduledDate : '',
      } as Message;
    }
  );

  return produce(state, (draft) => {
    draft.manageInvite.data.message.scheduledDate = data.scheduledDate;
    draft.manageInvite.data.reminders = reminderMessages;
  });
}

export function updateManageInviteRemindersReducer(
  state: InvitesState,
  data: Message
) {
  const index = findIndex(state.manageInvite.data.reminders, ['id', data.id]);
  const newIndex = state.manageInvite.data.reminders.length;

  return produce(state, (draft) => {
    draft.manageInvite.data.reminders[index === -1 ? newIndex : index] = data;
  });
}

export function removeManageInviteRemindersReducer(
  state: InvitesState,
  reminderId: number
): InvitesState {
  const index = findIndex(state.manageInvite.data.reminders, [
    'id',
    reminderId,
  ]);

  return produce(state, (draft: InvitesState) => {
    draft.manageInvite.data.reminders.splice(index, 1);
  });
}

export function clearManageInviteReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.manageInvite = manageInviteInitialState;
  });
}

export function updateManageInviteParameterReducer(
  state: InvitesState,
  change: any
) {
  return produce(state, (draft) => {
    draft.manageInvite.parameters = {
      ...draft.manageInvite.parameters,
      ...change,
    };
  });
}

export const manageInviteReducers = {
  [`${updateManageInviteBulk}`]: updateManageInviteBulkReducer,
  [`${updateManageInviteDetails}`]: updateManageInviteDetailsReducer,
  [`${updateManageInviteRecipients}`]: updateManageInviteRecipientsReducer,
  [`${updateManageInviteMessage}`]: updateManageInviteMessageReducer,
  [`${updateManageInviteSchedules}`]: updateManageInviteSchedulesReducer,
  [`${updateManageInviteReminders}`]: updateManageInviteRemindersReducer,
  [`${clearManageInvite}`]: clearManageInviteReducer,
  [`${updateManageInviteParameter}`]: updateManageInviteParameterReducer,
  [`${removeManageInviteReminder}`]: removeManageInviteRemindersReducer,
};
