import { Layout, PlaceholderDashboard, PlaceholderHeader } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const SurveyTemplatesListComponent = lazy(() =>
  withDelay(() => import('./SurveyTemplatesListComponent'))
);

const SurveyTemplatesList = (): ReactElement => {
  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={true}
              hasBackButton={false}
              hasButtons={false}
              numberOfTabs={2}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderDashboard cols={4} rows={2} />
          </Layout.Content>
        </>
      }
    >
      <SurveyTemplatesListComponent />
    </Suspense>
  );
};

export default SurveyTemplatesList;
