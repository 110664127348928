import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/survey/:surveyId/respondents';

export const getIndividualResponsesApi = (
  surveyId: number,
  currentQuery: Query
) => {
  return getRequest(getRoute(url, { params: { surveyId: surveyId } }), {
    params: currentQuery,
  });
};
