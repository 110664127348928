import { combineReducers, ReducersMapObject } from 'redux';

import { default as surveyNotification } from './modules/surveyNotification/reducers';
import { default as questionCategory } from './modules/questionCategory/reducers';
import { default as overviewReport } from './modules/overviewReport/reducers';
import { default as surveyTemplate } from './modules/surveyTemplate/reducers';
import { default as emailTemplate } from './modules/emailTemplates/reducers';
import { default as createPdf } from '../element/createPdf/state/reducers';
import { default as authenticate } from './modules/authenticate/reducers';
import { default as mySurveys } from '../page/MySurveys/state/reducers';
import { default as shareReport } from './modules/shareReport/reducers';
import { default as reports } from '../page/Reports/state/reducers';
import { default as collector } from './modules/collector/reducers';
import { default as benchmark } from './modules/benchmark/reducers';
import { default as response } from './modules/response/reducers';
import { default as bulkUrls } from './modules/bulkUrls/reducers';
import { default as question } from './modules/question/reducers';
import { default as sharedState } from 'lib/sharedState/reducers';
import { default as invites } from './modules/invites/reducers';
import { default as survey } from './modules/survey/reducers';
import { default as filter } from './modules/filter/reducers';
import { default as theme } from './modules/theme/reducers';
import { default as admin } from './modules/admin/reducers';
import { default as user } from './modules/user/reducers';
import { default as app } from './modules/app/reducers';

const reducers = combineReducers({
  app: app,
  emailInvites: invites,
  survey: survey,
  collector: collector,
  response: response,
  emailTemplate: emailTemplate,
  authenticate: authenticate,
  theme: theme,
  overviewReport: overviewReport,
  surveyTemplate: surveyTemplate,
  filter: filter,
  shareReport: shareReport,
  question: question,
  questionCategory: questionCategory,
  bulkUrls: bulkUrls,
  surveyNotification: surveyNotification,
  user: user,
  admin: admin,
  benchmark: benchmark,
  mySurveys,
  reports,
  createPdf,
  sharedState,
} as ReducersMapObject);

export default reducers;
