import produce from 'immer';

import {
  clearLoadSavedOverviewReport,
  loadSavedOverviewReport,
  loadSavedOverviewReportFailure,
  loadSavedOverviewReportSuccess,
} from './actions';
import { loadSavedOverviewReportInitialState } from './state';
import { OverviewReportState } from '../type';
import { ErrorResponse } from 'type';

export function loadSavedOverviewReportReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.loadSavedOverviewReport.isLoaded = false;
    draft.loadSavedOverviewReport.isLoading = true;
    draft.loadSavedOverviewReport.isValid = false;
  });
}

export function loadSavedOverviewReportSuccessReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.loadSavedOverviewReport.isLoaded = true;
    draft.loadSavedOverviewReport.isLoading = false;
    draft.loadSavedOverviewReport.isValid = true;
  });
}

export function loadSavedOverviewReportFailureReducer(
  state: OverviewReportState,
  error: ErrorResponse
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.loadSavedOverviewReport.isLoaded = true;
    draft.loadSavedOverviewReport.isLoading = false;
    draft.loadSavedOverviewReport.error = error;
  });
}

export function clearLoadSavedOverviewReportReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.loadSavedOverviewReport = loadSavedOverviewReportInitialState;
  });
}

export const loadSavedOverviewReportReducers = {
  [`${loadSavedOverviewReport}`]: loadSavedOverviewReportReducer,
  [`${loadSavedOverviewReportSuccess}`]: loadSavedOverviewReportSuccessReducer,
  [`${loadSavedOverviewReportFailure}`]: loadSavedOverviewReportFailureReducer,
  [`${clearLoadSavedOverviewReport}`]: clearLoadSavedOverviewReportReducer,
};
