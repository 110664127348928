import produce from 'immer';

import {
  clearSaveOverviewReportForPdf,
  saveOverviewReportForPdf,
  saveOverviewReportForPdfFailure,
  saveOverviewReportForPdfSuccess,
} from './actions';
import { saveOverviewReportForPDFInitialState } from './state';
import { OverviewReportState } from '../type';
import { ErrorResponse } from 'type';

export function saveOverviewReportForPdfReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.saveOverviewReportForPDF.isUpdated = false;
    draft.saveOverviewReportForPDF.isUpdating = true;
    draft.saveOverviewReportForPDF.isValid = false;
    draft.saveOverviewReportForPDF.data = {};
    draft.saveOverviewReportForPDF.error = undefined;
  });
}

export function saveOverviewReportForPdfSuccessReducer(
  state: OverviewReportState,
  data: any
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.saveOverviewReportForPDF.isUpdated = true;
    draft.saveOverviewReportForPDF.isUpdating = false;
    draft.saveOverviewReportForPDF.isValid = true;
    draft.saveOverviewReportForPDF.data = data.data;
  });
}

export function saveOverviewReportForPdfFailureReducer(
  state: OverviewReportState,
  errors: ErrorResponse
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.saveOverviewReportForPDF.isUpdated = true;
    draft.saveOverviewReportForPDF.isUpdating = false;
    draft.saveOverviewReportForPDF.error = errors;
  });
}

export function clearSaveOverviewReportForPdfReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.saveOverviewReportForPDF = saveOverviewReportForPDFInitialState;
  });
}

export const saveOverviewReportForPDFReducers = {
  [`${saveOverviewReportForPdf}`]: saveOverviewReportForPdfReducer,
  [`${saveOverviewReportForPdfSuccess}`]:
    saveOverviewReportForPdfSuccessReducer,
  [`${saveOverviewReportForPdfFailure}`]:
    saveOverviewReportForPdfFailureReducer,
  [`${clearSaveOverviewReportForPdf}`]: clearSaveOverviewReportForPdfReducer,
};
