import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/template/:surveyTemplateId';
const globalApiUrl = '/admin/template/:surveyTemplateId';

export const getSurveyTemplateApi = (
  isGlobal: boolean,
  surveyTemplateId: string
): any => {
  const url = isGlobal ? globalApiUrl : apiUrl;

  return getRequest(
    getRoute(url, { params: { surveyTemplateId: surveyTemplateId } })
  );
};
