/* eslint-disable max-len */
import React from 'react';
function MySurveys() {
    var inlineStyle = '.st0{opacity:   0.87;} .st1{fill - opacity:0.87;}';
    return (React.createElement("svg", { version: "1.1", id: "Layer_1", viewBox: "0 0 24 24", className: "elmo-icon__svg" },
        React.createElement("style", { type: "text/css" }, inlineStyle),
        React.createElement("polygon", { className: "st0", points: "12.9,12.1 11.1,13.9 10.1,12.8 9,13.9 10.1,14.9 10.1,14.9 11.1,16 12.2,14.9 12.2,14.9 13.9,13.2 " }),
        React.createElement("polygon", { className: "st0", points: "12.9,8.6 11.1,10.3 10.1,9.3 9,10.3 10.1,11.4 10.1,11.4 11.1,12.4 12.2,11.4 12.2,11.4 13.9,9.6 " }),
        React.createElement("path", { d: "M16,21H4V7H2v14c0,1.1,0.9,2,2,2h12V21z" }),
        React.createElement("path", { d: "M19,15.1V17H8V3h6v5h3.6l2.2-2.2L15,1H8C6.9,1,6,1.9,6,3l0,14c0,1.1,0.9,2,2,2h11c1.1,0,2-0.9,2-2v-3.9L19,15.1z" }),
        React.createElement("path", { className: "st1", d: "M22.7,7.3c-0.5-0.5-1.1-0.5-1.5-0.1l-0.7,0.7L22,9.4l0.7-0.7C23.1,8.3,23.1,7.7,22.7,7.3z" }),
        React.createElement("polygon", { className: "st1", points: "14.9,13.5 14.9,15 16.4,15 21.3,10.1 19.8,8.6 " })));
}
export default MySurveys;
