import React from 'react';
import { getClass } from '../_lib/helper';
import Dropdown from './components/Dropdown';
import PrimaryButton from './components/PrimaryButton';
import './SplitButton.scss';
function SplitButton(props) {
    var buttonClassNames = getClass('elmo-split-btn', props.className);
    return React.createElement("div", { className: buttonClassNames }, props.children);
}
SplitButton.Dropdown = Dropdown;
SplitButton.PrimaryButton = PrimaryButton;
export default SplitButton;
