var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
function BulletListStyle(props) {
    return (React.createElement(LoaderHolder, __assign({}, props),
        React.createElement("circle", { cx: "10", cy: "20", r: "8" }),
        React.createElement("rect", { x: "25", y: "15", rx: "5", ry: "5", width: "220", height: "10" }),
        React.createElement("circle", { cx: "10", cy: "50", r: "8" }),
        React.createElement("rect", { x: "25", y: "45", rx: "5", ry: "5", width: "220", height: "10" }),
        React.createElement("circle", { cx: "10", cy: "80", r: "8" }),
        React.createElement("rect", { x: "25", y: "75", rx: "5", ry: "5", width: "220", height: "10" }),
        React.createElement("circle", { cx: "10", cy: "110", r: "8" }),
        React.createElement("rect", { x: "25", y: "105", rx: "5", ry: "5", width: "220", height: "10" })));
}
export default BulletListStyle;
