import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getQuestionCategoryFailure,
  getQuestionCategorySuccess,
} from './actions';
import { GetQuestionCategoryPayload } from './type';
import { getQuestionCategoryApi } from './api';
import { SagaAction } from 'type';

export const getQuestionCategorySaga = function* (
  action: SagaAction<GetQuestionCategoryPayload>
): SagaIterator {
  try {
    const data = yield call(getQuestionCategoryApi(action.payload));

    yield put(getQuestionCategorySuccess(data));
  } catch (error) {
    yield put(getQuestionCategoryFailure(error));
  }
};
