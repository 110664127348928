var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Children } from 'react';
import Checkbox from '../../Checkbox';
import Row from '../../Grid/Row';
import ListTableTd from './ListTableTd';
import './ListTableTr.scss';
import ListTableActions from './ListTableActions';
import { getClass, isElementOfType } from '../../_lib/helper';
import { withListTableContext } from '../ListTableContextConsumer';
import Avatar from '../../Avatar';
var ListTableTr = /** @class */ (function (_super) {
    __extends(ListTableTr, _super);
    function ListTableTr() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toggleCheckbox = function (isSelected) {
            var _a = _this.props, onItemToggle = _a.onItemToggle, updateSelectAllCheckboxState = _a.updateSelectAllCheckboxState;
            updateSelectAllCheckboxState(isSelected);
            if (onItemToggle) {
                onItemToggle(isSelected);
            }
        };
        _this.handleRowClick = function (isSelected) { return function () {
            var _a = _this.props, isBulkActionOpen = _a.isBulkActionOpen, onRowClick = _a.onRowClick, rowIndex = _a.rowIndex, href = _a.href;
            if (isBulkActionOpen) {
                _this.toggleCheckbox(isSelected);
                return;
            }
            if (href) {
                window.location.href = href;
                return;
            }
            if (onRowClick) {
                onRowClick(rowIndex);
            }
        }; };
        _this.handleRowKeyPress = function (isSelected) { return function (e) {
            if (e.key === 'Enter') {
                _this.handleRowClick(isSelected)();
            }
            return false;
        }; };
        return _this;
    }
    ListTableTr.prototype.renderAvatar = function () {
        var _a = this.props, avatarType = _a.avatarType, rowData = _a.rowData;
        var rowDataHasAvatar = rowData && rowData.avatar && rowData.avatar.label;
        if (avatarType === 'avatar' && rowDataHasAvatar) {
            return (React.createElement(Avatar, { src: rowData.avatar.src, label: rowData.avatar.label, isLabelVisible: false }));
        }
        if (avatarType === 'thumbnail' && rowDataHasAvatar) {
            var backgroundImage = rowData.avatar.src
                ? { backgroundImage: 'url(' + rowData.avatar.src + ')' }
                : undefined;
            return (React.createElement("div", { className: "elmo-listtable__thumbnail", title: rowData.avatar.label, style: __assign({}, backgroundImage), role: "img", "aria-label": rowData.avatar.label }));
        }
    };
    ListTableTr.prototype.renderActions = function () {
        var _a = this.props, actions = _a.actions, icon = _a.icon, isBulkActionOpen = _a.isBulkActionOpen, rowIndex = _a.rowIndex, hasActions = _a.hasActions;
        if (isBulkActionOpen) {
            return null;
        }
        return (React.createElement(ListTableActions, { actions: actions, icon: icon, rowIndex: rowIndex, isPlaceholderRendered: hasActions }));
    };
    ListTableTr.prototype.renderColumns = function () {
        var _a = this.props, children = _a.children, columns = _a.columns, rowData = _a.rowData, rowIndex = _a.rowIndex;
        if (children) {
            return Children.map(children, function (child, colIndex) {
                var column = columns[colIndex];
                var label = column ? column.props.label : '';
                if (!React.isValidElement(child) ||
                    !isElementOfType(child, ListTableTd)) {
                    return null;
                }
                return React.cloneElement(child, {
                    rowIndex: rowIndex,
                    colIndex: colIndex,
                    label: label,
                });
            });
        }
        else if (columns) {
            return columns.map(function (child, colIndex) {
                var column = child;
                return (React.createElement(ListTableTd, { data: rowData, rowIndex: rowIndex, colIndex: colIndex, label: column.props.label, property: column.props.property, render: column.props.render, key: colIndex }));
            });
        }
    };
    ListTableTr.prototype.render = function () {
        var _a = this.props, rowIndex = _a.rowIndex, isSelected = _a.isSelected, isDisabled = _a.isDisabled, isBulkActionOpen = _a.isBulkActionOpen, id = _a.id, testId = _a.testId, href = _a.href, onRowClick = _a.onRowClick, className = _a.className;
        var isClickable = !!(onRowClick || href) && !isDisabled;
        return (React.createElement("div", __assign({ key: rowIndex, role: "row", className: getClass('elmo-listtable__row-wrapper', className, {
                selected: isSelected,
                clickable: isClickable,
            }), "data-testid": "elmo-listtable-row-" + (testId || id || 'default') }, (!isDisabled && {
            onClick: this.handleRowClick(!isSelected),
            onKeyPress: this.handleRowKeyPress(!isSelected),
        }), { tabIndex: isClickable ? 0 : -1 }),
            isBulkActionOpen && (React.createElement("div", { className: "elmo-listtable__row-checkbox", role: "cell" },
                React.createElement(Checkbox, { id: 'cb-' + rowIndex, isChecked: isSelected, isDisabled: isDisabled, ariaLabel: "Select row" }))),
            this.renderAvatar(),
            React.createElement("div", { className: "elmo-listtable__cols" },
                React.createElement(Row, { className: "elmo-listtable__row" }, this.renderColumns())),
            this.renderActions()));
    };
    return ListTableTr;
}(React.Component));
export { ListTableTr as ListTableTrNoContext };
export default withListTableContext(ListTableTr);
