import { UpdateRespondentProgressPayload } from './type';
import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const route = '/anon/respondent-progress/:hash';

export const updateRespondentProgressApi = (
  payload: UpdateRespondentProgressPayload
) => {
  return putRequest(
    getRoute(route, { params: { hash: payload.hash } }),
    payload
  );
};
