import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { updateSurveyFailure, updateSurveySuccess } from './actions';
import { updateSurveyApi } from './api';
import { SagaAction } from 'type';

export const updateSurveySaga = function* (
  action: SagaAction<{ surveyId: number; data: any }>
): SagaIterator {
  try {
    yield call(updateSurveyApi(action.payload.surveyId, action.payload.data));

    yield put(updateSurveySuccess());
  } catch (error) {
    yield put(updateSurveyFailure(error));
  }
};
