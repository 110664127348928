import React, { ReactElement } from 'react';
import moment from 'moment-timezone';

export const DATE_FORMAT: string = 'DD/MM/YYYY';

export const isTimeStampBefore = (
  timestamp: any,
  secondsAgo: number
): boolean => {
  return moment(timestamp).isBefore(
    moment().seconds(0).subtract(secondsAgo, 'seconds').format()
  );
};

export const isTimeStampAfter = (
  timestamp: any,
  inSeconds: number
): boolean => {
  return moment(timestamp).isAfter(
    moment().seconds(0).add(inSeconds, 'seconds').format()
  );
};

export const getTimeZoneAbbr = (timezone: string = moment.tz.guess()) => {
  return moment.tz(timezone).zoneAbbr();
};

export function dateFormat(date: moment.MomentInput, format?: string) {
  if (!date) {
    return undefined;
  }

  return moment(date).format(format ? format : DATE_FORMAT);
}

export const formatDate = (timestamp: string): string | undefined => {
  return dateFormat(timestamp, 'DD/MM/YYYY');
};

export const formatDateTime = (
  timestamp: string | null,
  format: string = 'DD/MM/YYYY h:mm:ss a'
): string | undefined => {
  if (!timestamp) {
    return '';
  }

  return dateFormat(timestamp, format);
};

export const formatDateTimeWithTimeZone = (
  timestamp: string,
  timezone: string = moment.tz.guess()
): string | undefined => {
  return moment(timestamp).tz(timezone).format('dddd, MMMM Do YYYY, h:mm a z');
};

export const renderDate = (date: string | null): ReactElement => {
  if (!date) {
    return <>-</>;
  }

  return <>{formatDate(date)}</>;
};

export const dateTimeWithTimeZone = (timestamp: string | null): string => {
  if (!timestamp) {
    return '';
  }

  const formattedDate = formatDateTimeWithTimeZone(timestamp);

  if (formattedDate === 'Invalid date' || !formattedDate) {
    return '';
  }

  return formattedDate;
};

export const renderDateTimeWithTimeZone = (
  timestamp: string | null
): ReactElement => {
  if (!timestamp) {
    return <>-</>;
  }

  return <>{dateTimeWithTimeZone(timestamp)}</>;
};

export const removeSeconds = (timestamp: string | null): string => {
  if (!timestamp) {
    return '';
  }

  return moment(timestamp).seconds(0).millisecond(0).format();
};

export const convertToUTC = (timestamp: string | null): string => {
  if (!timestamp) {
    return '';
  }

  return moment(timestamp).utc().format();
};

export const isTimestampEqual = (
  timestamp1: string | null,
  timestamp2: string | null
): boolean => {
  if (!timestamp1 || !timestamp2) {
    return false;
  }

  return convertToUTC(timestamp1) === convertToUTC(timestamp2);
};
