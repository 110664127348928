import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateInviteMessageTemplateFailure,
  updateInviteMessageTemplateSuccess,
} from './actions';
import { updateInviteMessageTemplateApi } from './api';

export const updateInviteMessageTemplateSaga = function* (
  action: any
): SagaIterator {
  try {
    const data = yield call(
      updateInviteMessageTemplateApi(
        action.payload.collectorId,
        action.payload.messageId,
        action.payload.data
      )
    );

    yield put(updateInviteMessageTemplateSuccess(data));
  } catch (error) {
    yield put(updateInviteMessageTemplateFailure(error));
  }
};
