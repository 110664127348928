import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { CreateRespondentPayload } from './type';

export const createRespondent: ActionCreator<CreateRespondentPayload> =
  createAction('CREATE_RESPONDENT');

export const createRespondentSuccess: ActionCreator<any> = createAction(
  'CREATE_RESPONDENT_SUCCESS'
);

export const createRespondentFailure: EmptyActionCreator = createAction(
  'CREATE_RESPONDENT_FAILURE'
);
