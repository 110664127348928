import produce from 'immer';

import {
  clearGetInviteRecipients,
  getInviteRecipients,
  getInviteRecipientsFailure,
  getInviteRecipientsSuccess,
  updateGetInviteRecipientsQuery,
} from './actions';
import { getInviteRecipientsInitialState } from './state';
import { Query } from 'lib/query/type';
import { InvitesState } from '../type';

export function getInviteRecipientsReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInviteRecipients.isLoaded = false;
    draft.getInviteRecipients.isLoading = true;
    draft.getInviteRecipients.isValid = false;
    draft.getInviteRecipients.count = 0;
    draft.getInviteRecipients.data = [];
  });
}

export function getInviteRecipientsSuccessReducer(
  state: InvitesState,
  data: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInviteRecipients.isLoaded = true;
    draft.getInviteRecipients.isLoading = false;
    draft.getInviteRecipients.isValid = true;
    draft.getInviteRecipients.count = data.count;
    draft.getInviteRecipients.data = data.data;
  });
}

export function getInviteRecipientsFailureReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInviteRecipients.isLoaded = true;
    draft.getInviteRecipients.isLoading = false;
    draft.getInviteRecipients.isValid = false;
  });
}

export function updateGetInviteRecipientsQueryReducer(
  state: InvitesState,
  query: Query
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInviteRecipients.query = query;
  });
}

export function clearGetInviteRecipientsReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInviteRecipients = getInviteRecipientsInitialState;
  });
}

export const getInviteRecipientsReducers = {
  [`${getInviteRecipients}`]: getInviteRecipientsReducer,
  [`${getInviteRecipientsSuccess}`]: getInviteRecipientsSuccessReducer,
  [`${getInviteRecipientsFailure}`]: getInviteRecipientsFailureReducer,
  [`${updateGetInviteRecipientsQuery}`]: updateGetInviteRecipientsQueryReducer,
  [`${clearGetInviteRecipients}`]: clearGetInviteRecipientsReducer,
};
