import { deleteRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/template/:surveyTemplateId';
const globalApiUrl = '/admin/template/:surveyTemplateId';

export const deleteSurveyTemplateApi = (
  surveyTemplateId: number,
  isGlobal: boolean
) => {
  const url = isGlobal ? globalApiUrl : apiUrl;

  return deleteRequest(
    getRoute(url, { params: { surveyTemplateId: surveyTemplateId } })
  );
};
