import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getDefaultSenderFailure, getDefaultSenderSuccess } from './actions';
import { getDefaultSenderApi } from './api';

export const getDefaultSenderSaga = function* (): SagaIterator {
  try {
    const data = yield call(getDefaultSenderApi());

    yield put(getDefaultSenderSuccess(data));
  } catch (error) {
    yield put(getDefaultSenderFailure(error));
  }
};
