import { createReducer } from 'redux-act';

import { getUserProfileFilterOptionsReducers } from './getUserProfileFilterOptions/reducers';
import { getCompanyUsersReducers } from './getCompanyUsers/reducers';
import { getUsersReducers } from './getUsers/reducers';
import { userInitialState } from './state';

export default createReducer(
  {
    ...getUsersReducers,
    ...getCompanyUsersReducers,
    ...getUserProfileFilterOptionsReducers,
  },
  userInitialState
);
