import {
  getOverviewList,
  getOverviewListSuccess,
  getOverviewListFailure,
  updateOverviewListQuery,
} from '../actions';
import { Query } from '../../../../lib/query/type';
import { ReportsInitialState } from '../../type';

export function getOverviewListReducer(state: ReportsInitialState) {
  return {
    ...state,
    overviewList: {
      ...state.overviewList,
      isLoaded: false,
      isLoading: true,
      count: 0,
      data: [],
    },
  };
}

export function getOverviewListSuccessReducer(
  state: ReportsInitialState,
  data: any
) {
  return {
    ...state,
    overviewList: {
      ...state.overviewList,
      isLoaded: true,
      isLoading: false,
      count: data.data.count,
      data: data.data.surveys,
    },
  };
}

export function getOverviewListFailureReducer(state: ReportsInitialState) {
  return {
    ...state,
    overviewList: {
      ...state.overviewList,
      isLoaded: false,
      isLoading: false,
      count: 0,
      data: [],
    },
  };
}

export function updateOverviewListQueryReducer(
  state: ReportsInitialState,
  query: Query
) {
  return {
    ...state,
    overviewList: {
      ...state.overviewList,
      query: query,
    },
  };
}

export const overviewListReducers = {
  [`${getOverviewList}`]: getOverviewListReducer,
  [`${getOverviewListSuccess}`]: getOverviewListSuccessReducer,
  [`${getOverviewListFailure}`]: getOverviewListFailureReducer,
  [`${updateOverviewListQuery}`]: updateOverviewListQueryReducer,
};
