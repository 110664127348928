import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { updateQuestionFailure, updateQuestionSuccess } from './actions';
import { UpdateQuestionPayload } from './type';
import { updateQuestionApi } from './api';
import { SagaAction } from 'type';

export const updateQuestionSaga = function* (
  action: SagaAction<UpdateQuestionPayload>
): SagaIterator {
  try {
    yield call(updateQuestionApi(action.payload));

    yield put(updateQuestionSuccess());
  } catch (error) {
    yield put(updateQuestionFailure(error as any));
  }
};
