import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const collectorRoute = '/collector/get-usage/:collectorId';

export const getCollectorUsageApi = (collectorId: number) => {
  return getRequest(
    getRoute(collectorRoute, {
      params: { collectorId: collectorId },
    })
  );
};
