import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormItem, Input } from 'elmo-elements';
import isEmpty from 'lodash/isEmpty';

import {
  createInviteMessageTemplate,
  clearCreateInviteMessageTemplate,
  updateInviteMessageTemplate,
  clearUpdateInviteMessageTemplate,
  getInviteMessageTemplates,
  showNotification,
} from 'store/actions';
import ConfirmationModal from 'element/Modal/components/confirmation';
import { analytics, AnalyticsEventNames } from 'lib';
import translations from './translations';
import { manageProps } from './type';
import { t } from 'lib/translation';
import Editor from 'element/Editor';

const Manage = ({
  closeModal,
  collectorId,
  message = {},
  isNew = false,
}: manageProps): ReactElement => {
  const dispatch = useDispatch();
  const updateMessageState = useSelector(
    (state: any) => state.emailInvites.updateInviteMessageTemplate
  );
  const createMessageState = useSelector(
    (state: any) => state.emailInvites.createInviteMessageTemplate
  );
  const collector = useSelector(
    (state: any) => state.collector.getCollector.collector
  );
  const [title, updateTitle] = useState<string>(message.title);
  const [subject, updateSubject] = useState<string>(message.subject);
  const [fromName, updateFromName] = useState<string>(message.from_name);
  const [htmlContent, updateHtmlContent] = useState<string>(message.message);

  const handleUpdateMessage = () => {
    const payload = {
      collectorId: collectorId,
      messageId: message.id,
      data: {
        title: title,
        fromName: fromName,
        emailTemplateId: message.email_template
          ? message.email_template.id
          : null,
        subject: subject,
        message: htmlContent,
      },
    };
    if (!isNew) {
      dispatch(updateInviteMessageTemplate(payload));

      analytics.sendEvent(
        AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_SAVE_MESSAGE_BUTTON_CLICKED,
        {
          surveyId: collector.survey.id,
          surveyTitle: collector.survey.title,
          collectorId: collector.id,
          surveyStatus: collector.survey.status,
          collectorType: collector.type,
          collectorTitle: collector.title,
          buttonSource: 'Edit Message',
          messageTitle: title,
          messageId: message.id,
          subject: subject,
          content: htmlContent,
        }
      );
    } else {
      dispatch(createInviteMessageTemplate(payload));

      analytics.sendEvent(
        AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_SAVE_MESSAGE_BUTTON_CLICKED,
        {
          surveyId: collector.survey.id,
          surveyTitle: collector.survey.title,
          collectorId: collector.id,
          surveyStatus: collector.survey.status,
          collectorType: collector.type,
          collectorTitle: collector.title,
          buttonSource: 'Add Message',
          messageTitle: title,
          messageId: message.id,
          subject: subject,
          content: htmlContent,
        }
      );
    }
  };

  const isSaveDisabled = (): boolean => {
    return (
      isEmpty(subject) ||
      isEmpty(htmlContent) ||
      isEmpty(title) ||
      isEmpty(fromName)
    );
  };

  useEffect(() => {
    if (updateMessageState.isUpdated) {
      closeModal();
      dispatch(getInviteMessageTemplates(collectorId));
      dispatch(clearUpdateInviteMessageTemplate());
      dispatch(showNotification(t(translations.notifications.success.update)));
    }
  }, [updateMessageState, collectorId, closeModal, dispatch]);

  useEffect(() => {
    if (createMessageState.isCreated) {
      closeModal();
      dispatch(getInviteMessageTemplates(collectorId));
      dispatch(clearCreateInviteMessageTemplate());
      dispatch(showNotification(t(translations.notifications.success.add)));
    }
  }, [createMessageState, collectorId, closeModal, dispatch]);

  return (
    <ConfirmationModal
      type={'x-large'}
      cancelCallback={closeModal}
      confirmedCallback={handleUpdateMessage}
      title={isNew ? t(translations.title.add) : t(translations.title.update)}
      closeLabel={t(translations.cancel)}
      confirmButtonText={t(translations.save)}
      isDisabled={isSaveDisabled()}
      isUpdating={
        updateMessageState.isUpdating || createMessageState.isCreating
      }
    >
      <>
        <FormItem label={t(translations.formLabels.messageTitle)}>
          <Input
            id={'message-title'}
            value={title}
            onChange={(e) => updateTitle(e.target.value)}
          />
        </FormItem>
        <FormItem label={t(translations.formLabels.fromEmail)}>
          <Input
            id={'message-from-email'}
            value={message.from_email}
            onChange={() => true}
            isDisabled={true}
          />
        </FormItem>
        <FormItem label={t(translations.formLabels.fromName)}>
          <Input
            id={'message-from-name'}
            value={fromName}
            onChange={(e) => updateFromName(e.target.value)}
          />
        </FormItem>
        <FormItem label={t(translations.formLabels.subject)}>
          <Input
            id={'message-subject'}
            value={subject}
            onChange={(e) => updateSubject(e.target.value)}
          />
        </FormItem>
        <FormItem label={t(translations.formLabels.htmlContent)}>
          <Editor
            model={htmlContent}
            onModelChange={updateHtmlContent}
            config={{
              heightMin: 200,
              placeholderText: 'Enter an Email Template Body Message',
            }}
          />
        </FormItem>
      </>
    </ConfirmationModal>
  );
};

export default Manage;
