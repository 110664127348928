import { GetUserProfileFilterOptionsState } from './type';

export const getUserProfileFilterOptionsInitialState: GetUserProfileFilterOptionsState =
  {
    isLoaded: false,
    isLoading: false,
    isValid: false,
    filterOptions: {
      positions: [],
      departments: [],
      locations: [],
      managers: [],
    },
  };
