import { takeEvery } from 'redux-saga/effects';

import {
  clearInviteParticipants,
  createInvite,
  createInviteMessage,
  createInviteMessageSchedule,
  createInviteMessageTemplate,
  createInviteRecipients,
  createInviteReminderMessage,
  deleteInvite,
  getInvite,
  getInviteMessageTemplates,
  getInviteRecipients,
  getInvites,
  inviteParticipants,
  transformManageInviteSavedInvite,
  updateManageInviteDetails,
  updateManageInviteMessage,
  updateManageInviteRecipients,
  updateManageInviteReminders,
  sendInviteMessage,
  updateInviteMessageSchedule,
  updateInviteMessageTemplate,
  updateInviteRecipient,
  updateInviteSchedules,
  deleteInviteMessage,
  removeManageInviteReminder,
  uploadInviteRecipientsCSV,
} from './actions';
import {
  inviteParticipantsClearStateSaga,
  inviteParticipantsSaga,
} from './inviteParticipants/sagas';
import {
  saveInviteDraftSaga,
  transformSavedInviteSaga,
} from './manageInvite/sagas';
import { updateInviteMessageTemplateSaga } from './updateInviteMessageTemplate/sagas';
import { createInviteMessageTemplateSaga } from './createInviteMessageTemplate/sagas';
import { createInviteMessageScheduleSaga } from './createInviteMessageSchedule/sagas';
import { createInviteReminderMessageSaga } from './createInviteReminderMessage/sagas';
import { updateInviteMessageScheduleSaga } from './updateInviteMessageSchedule/sagas';
import { getInviteMessageTemplatesSaga } from './getInviteMessageTemplates/sagas';
import { uploadInviteRecipientsCSVSaga } from './uploadInviteRecipientsCSV/sagas';
import { createInviteRecipientsSaga } from './createInviteRecipients/sagas';
import { updateInviteRecipientSaga } from './updateInviteRecipient/sagas';
import { updateInviteScheduleSaga } from './updateInviteSchedules/sagas';
import { getInviteRecipientsSaga } from './getInviteRecipients/sagas';
import { createInviteMessageSaga } from './createInviteMessage/sagas';
import { deleteInviteMessageSaga } from './deleteInviteMessage/sagas';
import { sendInviteMessageSaga } from './sendInviteMessage/sagas';
import { createInviteSaga } from './createInvite/sagas';
import { deleteInviteSaga } from './deleteInvite/sagas';
import { getInvitesSaga } from './getInvites/sagas';
import { getInviteSaga } from './getInvite/sagas';

export default function* sagas() {
  yield takeEvery(getInviteMessageTemplates, getInviteMessageTemplatesSaga);
  yield takeEvery(updateInviteMessageTemplate, updateInviteMessageTemplateSaga);
  yield takeEvery(getInviteRecipients, getInviteRecipientsSaga);
  yield takeEvery(createInviteRecipients, createInviteRecipientsSaga);
  yield takeEvery(updateInviteRecipient, updateInviteRecipientSaga);
  yield takeEvery(createInvite, createInviteSaga);
  yield takeEvery(deleteInvite, deleteInviteSaga);
  yield takeEvery(deleteInviteMessage, deleteInviteMessageSaga);
  yield takeEvery(createInviteMessage, createInviteMessageSaga);
  yield takeEvery(inviteParticipants, inviteParticipantsSaga);
  yield takeEvery(sendInviteMessage, sendInviteMessageSaga);
  yield takeEvery(createInviteMessageTemplate, createInviteMessageTemplateSaga);
  yield takeEvery(getInvites, getInvitesSaga);
  yield takeEvery(getInvite, getInviteSaga);
  yield takeEvery(updateManageInviteDetails, saveInviteDraftSaga);
  yield takeEvery(updateManageInviteRecipients, saveInviteDraftSaga);
  yield takeEvery(updateManageInviteMessage, saveInviteDraftSaga);
  yield takeEvery(updateManageInviteReminders, saveInviteDraftSaga);
  yield takeEvery(removeManageInviteReminder, saveInviteDraftSaga);
  yield takeEvery(transformManageInviteSavedInvite, transformSavedInviteSaga);
  yield takeEvery(clearInviteParticipants, inviteParticipantsClearStateSaga);
  yield takeEvery(createInviteMessageSchedule, createInviteMessageScheduleSaga);
  yield takeEvery(updateInviteMessageSchedule, updateInviteMessageScheduleSaga);
  yield takeEvery(createInviteReminderMessage, createInviteReminderMessageSaga);
  yield takeEvery(updateInviteSchedules, updateInviteScheduleSaga);
  yield takeEvery(uploadInviteRecipientsCSV, uploadInviteRecipientsCSVSaga);
}
