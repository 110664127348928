import map from 'lodash/map';
import produce from 'immer';

import {
  clearGetInvites,
  getInvites,
  getInvitesFailure,
  getInvitesSuccess,
  updateGetInvitesQuery,
} from './actions';
import {
  transformMessage,
  transformMessages,
  transformRecipients,
  transformUser,
} from 'store/transformers';
import { invitesInitialState } from '../state';
import { APIInvite } from '../getInvite/type';
import { GetInvitesResponse } from './type';
import { Query } from 'lib/query/type';
import { InvitesState } from '../type';
import { Invite } from 'type';

export function getInvitesReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInvites.isLoaded = false;
    draft.getInvites.isLoading = true;
    draft.getInvites.isValid = false;
    draft.getInvites.data = null;
    draft.getInvites.count = 0;
  });
}

export function getInvitesSuccessReducer(
  state: InvitesState,
  data: GetInvitesResponse
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInvites.isLoaded = true;
    draft.getInvites.isLoading = false;
    draft.getInvites.isValid = true;
    draft.getInvites.data = map(data.data, (apiInvite: APIInvite): Invite => {
      return {
        createdAt: apiInvite.created_at,
        createdBy: transformUser(apiInvite.created_by),
        recipientCount: apiInvite.email_recipient_count,
        recipients: transformRecipients(apiInvite.email_recipients),
        id: apiInvite.id,
        inviteCount: apiInvite.invite_count,
        inviteMessage: apiInvite.invite_messages[0]
          ? transformMessage(apiInvite.invite_messages[0])
          : null,
        isDeleted: apiInvite.is_deleted,
        lastInviteDate: apiInvite.last_invite_date,
        lastResponseDate: apiInvite.last_response_date,
        reminderMessages: transformMessages(apiInvite.reminder_messages),
        respondentCount: apiInvite.respondent_count,
        title: apiInvite.title,
        updatedAt: apiInvite.updated_at,
      };
    });
    draft.getInvites.count = data.count;
  });
}

export function getInvitesFailureReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInvites.isLoaded = true;
    draft.getInvites.isLoading = false;
    draft.getInvites.isValid = false;
  });
}

export function updateGetInvitesQueryReducer(
  state: InvitesState,
  query: Query
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInvites.query = query;
  });
}

export function clearGetInvitesReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInvites = invitesInitialState.getInvites;
  });
}

export const getInvitesReducers = {
  [`${getInvites}`]: getInvitesReducer,
  [`${getInvitesSuccess}`]: getInvitesSuccessReducer,
  [`${getInvitesFailure}`]: getInvitesFailureReducer,
  [`${updateGetInvitesQuery}`]: updateGetInvitesQueryReducer,
  [`${clearGetInvites}`]: clearGetInvitesReducer,
};
