import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { deleteResponseFailure, deleteResponseSuccess } from './actions';
import { DeleteResponsePayload } from './type';
import { deleteResponseApi } from './api';
import { SagaAction } from 'type';

export const deleteResponseSaga = function* (
  action: SagaAction<DeleteResponsePayload>
): SagaIterator {
  try {
    yield call(deleteResponseApi(action.payload.respondent_id));
    yield put(deleteResponseSuccess());
  } catch (error) {
    yield put(deleteResponseFailure(error));
  }
};
