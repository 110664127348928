import { Layout, PlaceholderDashboard, PlaceholderHeader } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const CreateQuestionComponent = lazy(() =>
  withDelay(() => import('./CreateQuestionComponent'))
);

const CreateQuestion = (): ReactElement => {
  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={false}
              hasBackButton={true}
              hasButtons={false}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderDashboard cols={1} rows={1} />
          </Layout.Content>
        </>
      }
    >
      <CreateQuestionComponent />
    </Suspense>
  );
};

export default CreateQuestion;
