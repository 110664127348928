import React from 'react';
import Popover from '../Popover';
import Badge from '../Badge';
function PopoverBadge(_a) {
    var children = _a.children, mode = _a.mode, label = _a.label, type = _a.type, id = _a.id, icon = _a.icon, isInline = _a.isInline;
    return (React.createElement(Popover, { id: id, mode: mode, isInline: isInline },
        React.createElement(Popover.Target, null,
            React.createElement(Badge, { icon: icon, type: type, hasPopoverIcon: true }, label)),
        React.createElement(Popover.Content, null, children)));
}
export default PopoverBadge;
