import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getResponseFailure, getResponseSuccess } from './actions';
import { getResponseApi } from './api';
import { SagaAction } from 'type';

export const getResponseSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const data = yield call(getResponseApi(action.payload));

    yield put(getResponseSuccess(data));
  } catch (error) {
    yield put(getResponseFailure(error));
  }
};
