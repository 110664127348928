import { CloseIndividualLinkRequest } from './type';
import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const route = '/shared-reports/:sharedReportId';

export const closeIndividualLinkApi = (request: CloseIndividualLinkRequest) => {
  return putRequest(
    getRoute(route, { params: { sharedReportId: request.sharedReportId } }),
    request.data
  );
};
