import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { CreateSurveyPayload, CreateSurveyResponse } from './type';
import { ErrorResponse } from 'type';

export const createSurvey: ActionCreator<CreateSurveyPayload> =
  createAction('CREATE_SURVEY');

export const createSurveySuccess: ActionCreator<CreateSurveyResponse> =
  createAction('CREATE_SURVEY_SUCCESS');

export const createSurveyFailure: ActionCreator<ErrorResponse> = createAction(
  'CREATE_SURVEY_FAILURE'
);

export const clearCreateSurvey: EmptyActionCreator = createAction(
  'CLEAR_CREATE_SURVEY'
);
