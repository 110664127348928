import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getCollectorFailure, getCollectorSuccess } from './actions';
import { getCollectorApi } from './api';

export interface GetCollectorSagaProps {
  error: boolean;
  payload: number;
  type: string;
}

export const getCollectorSaga = function* (
  action: GetCollectorSagaProps
): SagaIterator {
  try {
    const data = yield call(getCollectorApi(action.payload));

    yield put(getCollectorSuccess(data));
  } catch (error) {
    yield put(getCollectorFailure());
  }
};
