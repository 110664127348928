import React, { useEffect, useState } from 'react';
import AutosizeInput from 'react-input-autosize';
import { CloseIcon } from 'elmo-elements';
import ReactTooltip from 'react-tooltip';
import map from 'lodash/map';

import BaseModal from 'element/Modal/components/baseModal';
import { emailInputProps, pasteErrorTypes } from './type';
import translations from './translations';
import { t } from 'lib/translation';
import TagsInput from '../../index';
import './style.scss';

const EmailInput = ({
  values,
  onChangeAction,
  inputPlaceholder = 'Add email addresses',
  emailLimit = 500,
}: emailInputProps) => {
  const emailValidationRegex =
    /^[\w-.+]+@([a-z0-9]+\.|[a-z0-9][a-z0-9-]+[a-z0-9]\.)+[a-z0-9]{2,}$/i;
  const [showModal, shouldShowModal] = useState<any>(false);
  const [inputRef, updateInputRef] = useState<any>(null);
  const [toolTipError, updateToolTipError] = useState<string>('');

  const pasteSplit = (data: string): string[] => {
    const separators = [',', ' ', '\n', '\r'];

    return data
      .split(new RegExp(separators.join('|')))
      .map((d) => d.trim())
      .filter(Boolean);
  };

  const renderTag = (props: any) => {
    let { tag, key, disabled, onRemove, ...other } = props;
    return (
      <span key={key} {...other}>
        <span className={'tag-label'}>{tag}</span>
        {!disabled && (
          <span className={'tag-remove-btn'} onClick={() => onRemove(key)}>
            <CloseIcon />
          </span>
        )}
      </span>
    );
  };

  const renderAutoSizingInput = ({ ...props }: any) => {
    let { onChange, value, ...other } = props;
    return (
      <AutosizeInput
        data-tip={toolTipError}
        data-testid={'multi-email-input'}
        inputRef={updateInputRef}
        inputStyle={{
          outline: 'none',
          border: 'none',
          height: 46,
          margin: '8px 8px 8px 10px',
        }}
        className={'react-tagsinput-input'}
        type="text"
        onChange={onChange}
        value={value}
        {...other}
      />
    );
  };

  const onOverLimitTags = (
    acceptedTags: string[],
    rejectedTags: string[],
    overLimitBy: number,
    fromPaste: boolean
  ) => {
    if (fromPaste) {
      shouldShowModal({
        error: 'tooManyEmails',
        data: {
          total: values.length + acceptedTags.length + rejectedTags.length,
          maxEmails: emailLimit,
        },
      });
    } else {
      updateToolTipError(t(translations.errors.tooManyEmails.tooltip));
    }
    return false;
  };

  const onNonUniqueFound = (
    acceptedTags: string[],
    rejectedTags: string[],
    fromPaste: boolean
  ) => {
    if (fromPaste) {
      shouldShowModal({
        error: 'duplicateEmails',
        data: {
          total: acceptedTags.length,
          rejectedEmails: rejectedTags,
          duplicateCount: rejectedTags.length,
        },
      });

      return;
    }

    updateToolTipError(t(translations.errors.duplicateEmails.tooltip));
  };

  const onValidationReject = (
    acceptedTags: string[],
    rejectedTags: any,
    fromPaste: boolean
  ): void => {
    if (fromPaste) {
      shouldShowModal({
        error: 'invalidEmails',
        data: {
          total: acceptedTags.length,
          rejectedEmails: rejectedTags,
          invalidCount: rejectedTags.length,
        },
      });

      return;
    }

    updateToolTipError(t(translations.errors.invalidEmails.tooltip));
  };

  const closeValidationModal = () => {
    shouldShowModal(false);
  };

  const validationModal = () => {
    if (!showModal) {
      return <></>;
    }

    const error: pasteErrorTypes = showModal.error;
    const errorTranslations = translations.errors[error];
    const data = showModal.data;

    return (
      <BaseModal
        id={'emailInputValidationError'}
        closeModal={closeValidationModal}
        title={t(errorTranslations.title)}
        hasSecondaryButton={false}
        primaryButtonClick={closeValidationModal}
        primaryButtonText={t(errorTranslations.primaryButton)}
      >
        <>
          {t(errorTranslations.body, data)}

          {data.rejectedEmails && (
            <>
              <ul>
                {map(
                  data.rejectedEmails,
                  (emailAddress: string, key: number) => {
                    return <li key={key}>{emailAddress}</li>;
                  }
                )}
              </ul>
            </>
          )}
        </>
      </BaseModal>
    );
  };

  useEffect(() => {
    if (toolTipError !== '') {
      ReactTooltip.show(inputRef);
    }
  }, [toolTipError, inputRef]);

  return (
    <>
      <TagsInput
        value={values}
        addKeys={[9, 13, 32, 186, 188]}
        maxTags={emailLimit}
        onOverLimitTags={onOverLimitTags}
        pasteSplit={(data) => pasteSplit(data)}
        addOnPaste={true}
        addOnBlur={true}
        onlyUnique={true}
        onNonUniqueFound={onNonUniqueFound}
        validationRegex={emailValidationRegex}
        onValidationReject={onValidationReject}
        inputProps={{ placeholder: inputPlaceholder }}
        renderInput={renderAutoSizingInput}
        renderTag={renderTag}
        onChange={onChangeAction}
      />
      <ReactTooltip
        place={'bottom'}
        effect={'solid'}
        event={'onLoad'}
        globalEventOff={'click'}
        afterHide={() => updateToolTipError('')}
      />
      {validationModal()}
    </>
  );
};

export default EmailInput;
