var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Dropdown from '../../Dropdown';
import { getClass } from '../../_lib/helper';
function DropdownWrapper(props) {
    var className = getClass('elmo-split-btn__dropdown', props.className);
    return (React.createElement(Dropdown, __assign({}, props, { className: className, type: "primary" }), props.children));
}
export default DropdownWrapper;
