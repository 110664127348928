import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const collectorRoute = '/collector/:collectorId';

export const getCollectorApi = (collectorId: number) => {
  return getRequest(
    getRoute(collectorRoute, {
      params: { collectorId: collectorId },
    })
  );
};
