import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { AssociateFiltersRequest } from './type';
import { ErrorResponse } from 'type';

export const associateFilters: ActionCreator<AssociateFiltersRequest> =
  createAction('ASSOCIATE_FILTERS');

export const associateFiltersSuccess: EmptyActionCreator = createAction(
  'ASSOCIATE_FILTERS_SUCCESS'
);

export const associateFiltersFailure: ActionCreator<ErrorResponse> =
  createAction('ASSOCIATE_FILTERS_FAILURE');

export const clearAssociateFilters: EmptyActionCreator = createAction(
  'CLEAR_ASSOCIATE_FILTERS'
);
