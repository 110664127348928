import { takeEvery } from 'redux-saga/effects';

import {
  createQuestionCategory,
  deleteQuestionCategory,
  getQuestionCategories,
  getQuestionCategory,
  updateQuestionCategory,
} from './actions';
import { deleteQuestionCategorySaga } from './deleteQuestionCategory/sagas';
import { createQuestionCategorySaga } from './createQuestionCategory/sagas';
import { updateQuestionCategorySaga } from './updateQuestionCategory/sagas';
import { getQuestionCategoriesSaga } from './getQuestionCategories/sagas';
import { getQuestionCategorySaga } from './getQuestionCategory/sagas';

export default function* sagas() {
  yield takeEvery(createQuestionCategory, createQuestionCategorySaga);
  yield takeEvery(deleteQuestionCategory, deleteQuestionCategorySaga);
  yield takeEvery(getQuestionCategories, getQuestionCategoriesSaga);
  yield takeEvery(getQuestionCategory, getQuestionCategorySaga);
  yield takeEvery(updateQuestionCategory, updateQuestionCategorySaga);
}
