import { deleteRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url =
  '/collectors/:collectorId/email-recipient-lists/:emailRecipientListId';

export const deleteInviteApi = ({
  collectorId,
  emailRecipientListId,
}: {
  collectorId: number;
  emailRecipientListId: number;
}) => {
  return deleteRequest(
    getRoute(url, {
      params: {
        collectorId: collectorId,
        emailRecipientListId: emailRecipientListId,
      },
    })
  );
};
