import { getAnonSurveyThemeInitialState } from './getAnonSurveyTheme/state';
import { getDefaultThemeInitialState } from './getDefaultTheme/state';
import { setDefaultThemeInitialState } from './setDefaultTheme/state';
import { createThemeInitialState } from './createTheme/state';
import { updateThemeInitialState } from './updateTheme/state';
import { deleteThemeInitialState } from './deleteTheme/state';
import { getThemesInitialState } from './getThemes/state';
import { getThemeInitialState } from './getTheme/state';
import { ThemeState } from './type';

export const themeInitialState: ThemeState = {
  getAnonSurveyTheme: getAnonSurveyThemeInitialState,
  getDefaultTheme: getDefaultThemeInitialState,
  getThemes: getThemesInitialState,
  deleteTheme: deleteThemeInitialState,
  setDefaultTheme: setDefaultThemeInitialState,
  createTheme: createThemeInitialState,
  updateTheme: updateThemeInitialState,
  getTheme: getThemeInitialState,
};
