import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetFilterResponse } from './type';
import { ErrorResponse } from 'type';

export const getFilter: ActionCreator<number> = createAction('GET_FILTER');

export const getFilterSuccess: ActionCreator<GetFilterResponse> =
  createAction('GET_FILTER_SUCCESS');

export const getFilterFailure: ActionCreator<ErrorResponse> =
  createAction('GET_FILTER_FAILURE');

export const clearGetFilter: EmptyActionCreator =
  createAction('CLEAR_GET_FILTER');
