import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createRespondentProgressFailure,
  createRespondentProgressSuccess,
} from './actions';
import { CreateRespondentProgressPayload } from './type';
import { createRespondentProgressApi } from './api';
import { SagaAction } from 'type';

export const createRespondentProgressSaga = function* (
  action: SagaAction<CreateRespondentProgressPayload>
): SagaIterator {
  try {
    yield call(createRespondentProgressApi(action.payload));

    yield put(createRespondentProgressSuccess());
  } catch (error) {
    yield put(createRespondentProgressFailure());
  }
};
