import { SelectPropsOption } from 'elmo-elements/Select/type';

import { UserProfileFilterOptions } from '../type';

export const getManagerOptions = (
  userProfileFilterOptions: UserProfileFilterOptions
): SelectPropsOption[] => {
  let options: SelectPropsOption[] = [];

  for (const manager of userProfileFilterOptions.managers) {
    options.push({ value: manager.id, label: manager.name });
  }

  return options;
};
