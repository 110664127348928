import CheckCircle from '@mui/icons-material/CheckCircle';
import { StepIconProps } from '@mui/material/StepIcon';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Tooltip } from 'elmo-elements';
import Error from '@mui/icons-material/Error';
import React, { useEffect } from 'react';

import {
  clearManageInvite,
  updateManageInviteParameter,
  clearInviteParticipants,
  showNotification,
} from 'store/actions';
import BaseModal from 'element/Modal/components/baseModal';
import { analytics, AnalyticsEventNames } from 'lib';
import translations from './translations';
import { SubmissionProps } from './type';
import { RootState } from 'store/type';
import Stepper from 'element/stepper';
import { t } from 'lib/translation';
import { getRoute } from 'lib/util';
import history from 'lib/history';
import './styles.scss';

const Submission = ({ surveyId, collectorId, closeModal }: SubmissionProps) => {
  const dispatch = useDispatch();

  const manageInvites = useSelector(
    (state: any) => state.emailInvites.manageInvite
  );
  const inviteParticipantsState = useSelector(
    (state: any) => state.emailInvites.inviteParticipants
  );
  const recipientListState = useSelector(
    (state: any) => state.emailInvites.createInvite
  );
  const recipientsState = useSelector(
    (state: any) => state.emailInvites.createInviteRecipients
  );
  const messageState = useSelector(
    (state: any) => state.emailInvites.createInviteMessage
  );
  const reminderMessageState = useSelector(
    (state: any) => state.emailInvites.createInviteReminderMessage
  );
  const sendMessageState = useSelector(
    (state: any) => state.emailInvites.sendInviteMessage
  );
  const createScheduleState = useSelector(
    (state: any) => state.emailInvites.createInviteMessageSchedule
  );
  const collector = useSelector(
    (state: RootState) => state.collector.getCollector
  );

  const stepIcon = ({ completed, active, error, icon }: StepIconProps) => {
    if (completed) {
      return <CheckCircle className={'completed'} />;
    }

    if (active) {
      return <Spinner />;
    }

    if (error) {
      return (
        <>
          <Tooltip title={t(translations.steps.error)}>
            <Error className={'error'} />
          </Tooltip>
        </>
      );
    }

    return (
      <svg
        className="MuiSvgIcon-root MuiStepIcon-root"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <circle cx="12" cy="12" r="12" />
        <text className="MuiStepIcon-text" x="12" y="16" textAnchor="middle">
          {icon}
        </text>
      </svg>
    );
  };

  const getSteps = () => {
    const steps = [
      {
        label: t(translations.steps.recipientList),
        labelProps: {
          icon: stepIcon({
            completed:
              recipientListState.isCreated && recipientListState.isValid,
            active: recipientListState.isCreating,
            error: recipientListState.isCreated && !recipientListState.isValid,
            icon: 1,
          }),
        },
      },
      {
        label: t(translations.steps.recipients),
        labelProps: {
          icon: stepIcon({
            completed: recipientsState.isCreated && recipientsState.isValid,
            active: recipientsState.isCreating,
            error: recipientsState.isCreated && !recipientsState.isValid,
            icon: 2,
          }),
        },
      },
      {
        label: t(translations.steps.message),
        labelProps: {
          icon: stepIcon({
            completed: messageState.isCreated && messageState.isValid,
            active: messageState.isCreating,
            error: messageState.isCreated && !messageState.isValid,
            icon: 3,
          }),
        },
      },
      {
        label: t(translations.steps.send),
        labelProps: {
          icon: stepIcon({
            completed: sendMessageState.isLoaded && sendMessageState.isValid,
            active: sendMessageState.isLoading,
            error: sendMessageState.isLoaded && !sendMessageState.isValid,
            icon: 4,
          }),
        },
      },
    ];

    if (manageInvites.parameters.isScheduled) {
      steps.splice(3, 1, {
        label: t(translations.steps.schedule),
        labelProps: {
          icon: stepIcon({
            completed:
              reminderMessageState.isCreating ||
              reminderMessageState.isCreated ||
              (createScheduleState.isCreated && createScheduleState.isValid),
            active:
              !reminderMessageState.isCreating &&
              !reminderMessageState.isCreated &&
              createScheduleState.isCreating,
            error:
              !reminderMessageState.isCreated &&
              createScheduleState.isCreated &&
              !createScheduleState.isValid,
            icon: 4,
          }),
        },
      });
    }

    if (manageInvites.data.reminders.length > 0) {
      steps.splice(4, 0, {
        label: t(translations.steps.reminderMessage),
        labelProps: {
          icon: stepIcon({
            completed:
              reminderMessageState.isCreated && reminderMessageState.isValid,
            active: reminderMessageState.isCreating,
            error:
              reminderMessageState.isCreated && !reminderMessageState.isValid,
            icon: 5,
          }),
        },
      });
    }

    return steps;
  };

  useEffect(() => {
    if (inviteParticipantsState.isLoaded && inviteParticipantsState.isValid) {
      if (manageInvites.parameters.isScheduled) {
        if (
          collector.collector &&
          manageInvites.data &&
          messageState.data &&
          recipientListState.data
        ) {
          analytics.sendEvent(
            AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_INVITE_SCHEDULED_BY_SEND_LATER_BUTTON,
            {
              surveyId: collector.collector.survey.id,
              surveyTitle: collector.collector.survey.title,
              collectorId: collector.collector.id,
              surveyStatus: collector.collector.survey.status,
              collectorType: collector.collector.type,
              collectorTitle: collector.collector.title,
              inviteListName: manageInvites.data.inviteDetails.recipientGroup,
              inviteListId: recipientListState.data.id,
              inviteMessageId: messageState.data.id,
              numberOfRecipients:
                manageInvites.data.recipients.recipients.length +
                manageInvites.data.recipients.uuids.length +
                manageInvites.data.recipients.csvUpload.recipientsCount,
              numberOfAssociatedFilters:
                manageInvites.data.recipients.csvUpload.associatedFiltersCount,
              inputType: manageInvites.data.recipients.inputType,
            }
          );
        }
      } else {
        if (
          collector.collector &&
          manageInvites.data &&
          messageState.data &&
          recipientListState.data
        ) {
          analytics.sendEvent(
            AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_INVITE_SENT_BY_SEND_NOW_BUTTON,
            {
              surveyId: collector.collector.survey.id,
              surveyTitle: collector.collector.survey.title,
              collectorId: collector.collector.id,
              surveyStatus: collector.collector.survey.status,
              collectorType: collector.collector.type,
              collectorTitle: collector.collector.title,
              inviteListName: manageInvites.data.inviteDetails.recipientGroup,
              inviteListId: recipientListState.data.id,
              inviteMessageId: messageState.data.id,
              numberOfRecipients:
                manageInvites.data.recipients.recipients.length +
                manageInvites.data.recipients.uuids.length +
                manageInvites.data.recipients.csvUpload.recipientsCount,
              numberOfAssociatedFilters:
                manageInvites.data.recipients.csvUpload.associatedFiltersCount,
              inputType: manageInvites.data.recipients.inputType,
            }
          );
        }
      }

      dispatch(clearManageInvite());
      dispatch(
        showNotification(
          manageInvites.parameters.isScheduled
            ? t(translations.notifications.success.scheduled)
            : t(translations.notifications.success.queued)
        )
      );
      dispatch(clearInviteParticipants());
      localStorage.removeItem('survey-invite-draft');
      history.push(
        getRoute('/surveys/:surveyId/edit/:collectorId/email-invites', {
          params: {
            surveyId: surveyId,
            collectorId: collectorId,
          },
        })
      );
    }
  }, [
    collector.collector,
    manageInvites.data,
    messageState.data,
    recipientListState.data,
    inviteParticipantsState,
    collectorId,
    surveyId,
    manageInvites.parameters.isScheduled,
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearInviteParticipants());
      dispatch(updateManageInviteParameter({ isScheduled: false }));
    };
  }, [dispatch]);

  return (
    <>
      <BaseModal
        id={'invite-participants-submission'}
        closeModal={closeModal}
        title={t(translations.title)}
        hasPrimaryButton={false}
        hasSecondaryButton={false}
      >
        <>
          <Stepper steps={getSteps()} activeStep={1} nonLinear={true} />
        </>
      </BaseModal>
    </>
  );
};

export default Submission;
