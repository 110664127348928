import { ActionCreator, createAction } from 'redux-act';

export const updateManageInviteBulk: any = createAction(
  'UPDATE_MANAGE_INVITE_BULK'
);

export const updateManageInviteDetails: any = createAction(
  'UPDATE_MANAGE_INVITE_DETAILS'
);

export const updateManageInviteRecipients: any = createAction(
  'UPDATE_MANAGE_INVITE_RECIPIENTS'
);

export const updateManageInviteMessage: any = createAction(
  'UPDATE_MANAGE_INVITE_MESSAGE'
);

export const updateManageInviteSchedules: any = createAction(
  'UPDATE_MANAGE_INVITE_SCHEDULES'
);

export const updateManageInviteReminders: any = createAction(
  'UPDATE_MANAGE_INVITE_REMINDERS'
);

export const clearManageInvite: any = createAction('CLEAR_MANAGE_INVITE');

export const updateManageInviteParameter: any = createAction(
  'UPDATE_MANAGE_INVITE_PARAMETER'
);

export const transformManageInviteSavedInvite: any = createAction(
  'TRANSFORM_MANAGE_INVITE_SAVED_INVITE'
);

export const removeManageInviteReminder: ActionCreator<number> = createAction(
  'REMOVE_MANAGE_INVITE_REMINDER'
);
