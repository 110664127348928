import produce from 'immer';

import {
  clearUploadInviteRecipientsCSV,
  uploadInviteRecipientsCSV,
  uploadInviteRecipientsCSVFailure,
  uploadInviteRecipientsCSVSuccess,
} from './actions';
import { UploadInviteRecipientsCSVSuccessResponse } from './type';
import { uploadInviteRecipientsCSVInitialState } from './state';
import { InvitesState } from '../type';
import { ErrorResponse } from 'type';

export function uploadInviteRecipientsCSVReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.uploadInviteRecipientsCSV.isUpdated = false;
    draft.uploadInviteRecipientsCSV.isUpdating = true;
    draft.uploadInviteRecipientsCSV.isValid = false;
    draft.uploadInviteRecipientsCSV.data =
      uploadInviteRecipientsCSVInitialState.data;
  });
}

export function uploadInviteRecipientsCSVSuccessReducer(
  state: InvitesState,
  payload: UploadInviteRecipientsCSVSuccessResponse
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.uploadInviteRecipientsCSV.isUpdated = true;
    draft.uploadInviteRecipientsCSV.isUpdating = false;
    draft.uploadInviteRecipientsCSV.isValid = true;
    draft.uploadInviteRecipientsCSV.data.displayName =
      payload.requestData.file.name;
    draft.uploadInviteRecipientsCSV.data.path =
      payload.responseData.data.file_path;
    draft.uploadInviteRecipientsCSV.data.recipientsCount =
      payload.responseData.data.row_count;
    draft.uploadInviteRecipientsCSV.data.associatedFiltersCount =
      payload.responseData.data.associated_filter_count;
  });
}

export function uploadInviteRecipientsCSVFailureReducer(
  state: InvitesState,
  error: ErrorResponse
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.uploadInviteRecipientsCSV.isUpdated = true;
    draft.uploadInviteRecipientsCSV.isUpdating = false;
    draft.uploadInviteRecipientsCSV.errors = error.data.errors;
  });
}

export function clearUploadInviteRecipientsCSVReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.uploadInviteRecipientsCSV = uploadInviteRecipientsCSVInitialState;
  });
}

export const uploadInviteRecipientsCSVReducers = {
  [`${uploadInviteRecipientsCSV}`]: uploadInviteRecipientsCSVReducer,
  [`${uploadInviteRecipientsCSVSuccess}`]:
    uploadInviteRecipientsCSVSuccessReducer,
  [`${uploadInviteRecipientsCSVFailure}`]:
    uploadInviteRecipientsCSVFailureReducer,
  [`${clearUploadInviteRecipientsCSV}`]: clearUploadInviteRecipientsCSVReducer,
};
