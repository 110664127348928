import produce from 'immer';

import {
  updateEmailTemplate,
  clearUpdateEmailTemplate,
  updateEmailTemplateFailure,
  updateEmailTemplateSuccess,
} from './actions';
import { emailTemplateInitialState } from '../state';
import { EmailTemplateState } from '../type';
import { ErrorResponse } from 'type';

export function updateEmailTemplateReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.updateEmailTemplate.isUpdating = true;
    draft.updateEmailTemplate.isUpdated = false;
    draft.updateEmailTemplate.isValid = false;
  });
}

export function updateEmailTemplateSuccessReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.updateEmailTemplate.isUpdating = false;
    draft.updateEmailTemplate.isUpdated = true;
    draft.updateEmailTemplate.isValid = true;
  });
}

export function updateEmailTemplateFailureReducer(
  state: EmailTemplateState,
  errors: ErrorResponse
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.updateEmailTemplate.isUpdating = false;
    draft.updateEmailTemplate.isUpdated = true;
    draft.updateEmailTemplate.errors = errors;
  });
}

export function clearUpdateEmailTemplateReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.updateEmailTemplate = emailTemplateInitialState.updateEmailTemplate;
  });
}

export const updateEmailTemplateReducers = {
  [`${updateEmailTemplate}`]: updateEmailTemplateReducer,
  [`${updateEmailTemplateSuccess}`]: updateEmailTemplateSuccessReducer,
  [`${updateEmailTemplateFailure}`]: updateEmailTemplateFailureReducer,
  [`${clearUpdateEmailTemplate}`]: clearUpdateEmailTemplateReducer,
};
