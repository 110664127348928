import { RouteObject } from 'react-router-dom';
import React from 'react';

import NotFound from './pages/NotFound';
import NoAccess from './pages/NoAccess';
import Error from './pages/Error';

const ErrorPagesRoutes: RouteObject[] = [
  {
    path: '/not-found',
    element: <NotFound />,
  },
  {
    path: '/no-access',
    element: <NoAccess />,
  },
  {
    path: '/error-page',
    element: <Error />,
  },
];

export default ErrorPagesRoutes;
