import { takeEvery } from 'redux-saga/effects';

import {
  closeIndividualLink,
  createBulkLinks,
  createIndividualLink,
  getBulkLinkBatch,
  getBulkLinks,
  getIndividualLinks,
  getLatestCreatedBulkLink,
  updateBulkPassword,
  uploadBulkPasswordCSV,
} from './actions';
import { getLatestCreatedBulkLinkSaga } from './getLatestCreatedBulkLink/sagas';
import { uploadBulkPasswordCSVSaga } from './uploadBulkPasswordCSV/sagas';
import { createIndividualLinkSaga } from './createIndividualLink/sagas';
import { closeIndividualLinkSaga } from './closeIndividualLink/sagas';
import { updateBulkPasswordsSaga } from './updateBulkPassword/sagas';
import { getIndividualLinksSaga } from './getIndividualLinks/sagas';
import { getBulkLinkBatchSaga } from './getBulkLinkBatch/sagas';
import { createBulkLinksSaga } from './createBulkLinks/sagas';
import { getBulkLinksSaga } from './getBulkLinks/sagas';

export default function* sagas() {
  yield takeEvery(closeIndividualLink, closeIndividualLinkSaga);
  yield takeEvery(createBulkLinks, createBulkLinksSaga);
  yield takeEvery(createIndividualLink, createIndividualLinkSaga);
  yield takeEvery(getBulkLinkBatch, getBulkLinkBatchSaga);
  yield takeEvery(getBulkLinks, getBulkLinksSaga);
  yield takeEvery(getIndividualLinks, getIndividualLinksSaga);
  yield takeEvery(getLatestCreatedBulkLink, getLatestCreatedBulkLinkSaga);
  yield takeEvery(updateBulkPassword, updateBulkPasswordsSaga);
  yield takeEvery(uploadBulkPasswordCSV, uploadBulkPasswordCSVSaga);
}
