var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '../../_lib/style';
var elements = {
    span: styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""]))),
    small: styled.small(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""]))),
    strong: styled.strong(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""]))),
};
var StyledText = function (htmlTag) {
    return elements[htmlTag || 'span'];
};
export default StyledText;
var templateObject_1, templateObject_2, templateObject_3;
