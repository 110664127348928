import produce from 'immer';

import {
  updateSurveyAccessList,
  updateSurveyAccessListSuccess,
  updateSurveyAccessListFailure,
  clearUpdateSurveyAccessList,
} from './actions';
import { updateSurveyAccessListInitialState } from './state';
import { SurveyState } from '../type';
import { ErrorResponse } from 'type';

export function updateSurveyAccessListReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateSurveyAccessList = { ...updateSurveyAccessListInitialState };
    draft.updateSurveyAccessList.isLoading = true;
  });
}

export function updateSurveyAccessListSuccessReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateSurveyAccessList.isLoading = false;
    draft.updateSurveyAccessList.isLoaded = true;
    draft.updateSurveyAccessList.isValid = true;
  });
}

export function updateSurveyAccessListFailureReducer(
  state: SurveyState,
  error: ErrorResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateSurveyAccessList.isLoading = false;
    draft.updateSurveyAccessList.isLoaded = true;
    draft.updateSurveyAccessList.error = error;
  });
}

export function clearUpdateSurveyAccessListReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateSurveyAccessList = updateSurveyAccessListInitialState;
  });
}

export const updateSurveyAccessListReducers = {
  [`${updateSurveyAccessList}`]: updateSurveyAccessListReducer,
  [`${updateSurveyAccessListSuccess}`]: updateSurveyAccessListSuccessReducer,
  [`${updateSurveyAccessListFailure}`]: updateSurveyAccessListFailureReducer,
  [`${clearUpdateSurveyAccessList}`]: clearUpdateSurveyAccessListReducer,
};
