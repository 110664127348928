import { createAction } from 'redux-act';

export const updateOverviewListQuery: any = createAction(
  'REPORTS_UPDATE_OVERVIEW_LIST_QUERY'
);

export const getOverviewList: any = createAction('REPORTS_GET_OVERVIEW_LIST');

export const getOverviewListSuccess: any = createAction(
  'REPORTS_GET_OVERVIEW_LIST_SUCCESS'
);

export const getOverviewListFailure: any = createAction(
  'REPORTS_GET_OVERVIEW_LIST_FAILURE'
);
