import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getFilterValuesFailure,
  getFilterValuesSuccess,
  updateGetFilterValuesQuery,
} from './actions';
import { GetFilterValueRequest } from './type';
import { Query } from 'lib/stateTemplates';
import { getFilterValuesApi } from './api';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

export const getFilterValuesSaga = function* (
  action: SagaAction<GetFilterValueRequest>
): SagaIterator {
  try {
    let queryData = getQuery(Query);

    yield put(updateGetFilterValuesQuery(queryData));

    if (action.payload.query) {
      queryData = action.payload.query;
    }

    const data = yield call(
      getFilterValuesApi(action.payload.filterId, queryData)
    );

    yield put(getFilterValuesSuccess(data));
  } catch (errors) {
    yield put(getFilterValuesFailure(errors));
  }
};
