import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetIndividualResponsesResponse } from './type';
import { DefaultQuery, ErrorResponse } from 'type';

export const updateIndividualResponsesQuery: ActionCreator<DefaultQuery> =
  createAction('UPDATE_INDIVIDUAL_RESPONSES_QUERY');

export const getIndividualResponses: ActionCreator<number> = createAction(
  'GET_INDIVIDUAL_RESPONSES'
);

export const getIndividualResponsesSuccess: ActionCreator<GetIndividualResponsesResponse> =
  createAction('GET_INDIVIDUAL_RESPONSES_SUCCESS');

export const getIndividualResponsesFailure: ActionCreator<ErrorResponse> =
  createAction('GET_INDIVIDUAL_RESPONSES_FAILURE');

export const clearGetIndividualResponses: EmptyActionCreator = createAction(
  'CLEAR_GET_INDIVIDUAL_RESPONSES'
);
