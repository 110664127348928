import { GetSurveyNotificationHistoryPayload } from './type';
import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId/notification/:notificationId/history';

export const getSurveyNotificationHistoryApi = (
  payload: GetSurveyNotificationHistoryPayload,
  query: Query
) => {
  return getRequest(
    getRoute(apiUrl, {
      params: {
        surveyId: payload.surveyId,
        notificationId: payload.notificationId,
      },
    }),
    {
      params: query,
    }
  );
};
