import { createReducer } from 'redux-act';

import { createInviteMessageScheduleReducers } from './createInviteMessageSchedule/reducers';
import { createInviteMessageTemplateReducers } from './createInviteMessageTemplate/reducers';
import { createInviteReminderMessageReducers } from './createInviteReminderMessage/reducers';
import { updateInviteMessageScheduleReducers } from './updateInviteMessageSchedule/reducers';
import { updateInviteMessageTemplateReducers } from './updateInviteMessageTemplate/reducers';
import { getInviteMessageTemplatesReducers } from './getInviteMessageTemplates/reducers';
import { uploadInviteRecipientsCSVReducers } from './uploadInviteRecipientsCSV/reducers';
import { createInviteRecipientsReducers } from './createInviteRecipients/reducers';
import { updateInviteRecipientReducers } from './updateInviteRecipient/reducers';
import { updateInviteSchedulesReducers } from './updateInviteSchedules/reducers';
import { createInviteMessageReducers } from './createInviteMessage/reducers';
import { deleteInviteMessageReducers } from './deleteInviteMessage/reducers';
import { getInviteRecipientsReducers } from './getInviteRecipients/reducers';
import { inviteParticipantsReducers } from './inviteParticipants/reducers';
import { sendInviteMessageReducers } from './sendInviteMessage/reducers';
import { createInviteReducers } from './createInvite/reducers';
import { deleteInviteReducers } from './deleteInvite/reducers';
import { manageInviteReducers } from './manageInvite/reducers';
import { getInvitesReducers } from './getInvites/reducers';
import { getInviteReducers } from './getInvite/reducers';
import { invitesInitialState } from './state';

export default createReducer(
  {
    ...createInviteReducers,
    ...createInviteMessageReducers,
    ...createInviteMessageScheduleReducers,
    ...createInviteMessageTemplateReducers,
    ...createInviteRecipientsReducers,
    ...createInviteReminderMessageReducers,
    ...deleteInviteReducers,
    ...deleteInviteMessageReducers,
    ...getInviteReducers,
    ...getInviteMessageTemplatesReducers,
    ...getInviteRecipientsReducers,
    ...getInvitesReducers,
    ...inviteParticipantsReducers,
    ...manageInviteReducers,
    ...sendInviteMessageReducers,
    ...updateInviteMessageScheduleReducers,
    ...updateInviteMessageTemplateReducers,
    ...updateInviteRecipientReducers,
    ...updateInviteSchedulesReducers,
    ...uploadInviteRecipientsCSVReducers,
  },
  invitesInitialState
);
