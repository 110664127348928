import { GetTextQuestionAnswersState } from './type';

export const getTextQuestionAnswersInitialState: GetTextQuestionAnswersState = {
  isLoading: false,
  isLoaded: false,
  isValid: false,
  count: 0,
  query: {
    pageSize: 10,
    currentPage: 1,
  },
  data: [],
};
