import React from 'react';
import Image from '../../Image';
import { CompanyLogoWrapper } from '../Styles';
import { getClass } from '../../_lib/helper';
function CompanyLogo(_a) {
    var _b, _c;
    var logo = _a.logo, mobileLogo = _a.mobileLogo, logoHref = _a.logoHref, hasDrawMenu = _a.hasDrawMenu, isCollapsed = _a.isCollapsed;
    return (React.createElement(CompanyLogoWrapper, { onClick: logoHref, className: getClass('elmo-nav-header__logo', '', (_b = {},
            _b['with-draw-menu'] = !isCollapsed && hasDrawMenu,
            _b)) },
        React.createElement(Image, { src: logo, className: getClass('elmo-nav-header__logo-desktop', '', (_c = {},
                _c['with-draw-menu'] = !isCollapsed && hasDrawMenu,
                _c)), alt: "Logo" }),
        React.createElement(Image, { src: mobileLogo ? mobileLogo : logo, className: "elmo-nav-header__logo-mobile", alt: "Logo" })));
}
export default CompanyLogo;
