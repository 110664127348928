import { createAction } from 'redux-act';

export const getDefaultInviteSender: any = createAction(
  'SHARED_STATE_GET_DEFAULT_INVITE_SENDER'
);

export const getDefaultInviteSenderSuccess: any = createAction(
  'SHARED_STATE_GET_DEFAULT_INVITE_SENDER_SUCCESS'
);

export const getDefaultInviteSenderFailure: any = createAction(
  'SHARED_STATE_GET_DEFAULT_INVITE_SENDER_FAILURE'
);
