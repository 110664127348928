import { GetQuestionCategoryState } from './type';
import { QuestionCategory } from 'type';

export const getQuestionCategoryInitialState: GetQuestionCategoryState = {
  data: {} as QuestionCategory,
  isLoaded: false,
  isLoading: false,
  isValid: false,
  questionCategoryId: 0,
};
