import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateSurveyTemplateFailure,
  updateSurveyTemplateSuccess,
} from './actions';
import { UpdateSurveyTemplatePayload } from './type';
import { updateSurveyTemplateApi } from './api';
import { SagaAction } from 'type';

export const updateSurveyTemplateSaga = function* (
  action: SagaAction<UpdateSurveyTemplatePayload>
): SagaIterator {
  try {
    yield call(
      updateSurveyTemplateApi(
        action.payload.id,
        action.payload.isGlobal,
        action.payload.payload
      )
    );
    yield put(updateSurveyTemplateSuccess());
  } catch (error) {
    yield put(updateSurveyTemplateFailure(error));
  }
};
