import React from 'react';
function FormItemLabel(_a) {
    var icon = _a.icon, label = _a.label, labelAddon = _a.labelAddon;
    if (icon || label || labelAddon) {
        return (React.createElement("div", { className: "elmo-formitem__label" },
            React.createElement("label", null,
                icon && icon,
                " ",
                label && label),
            labelAddon && (React.createElement("span", { className: "elmo-formitem__label-optional" }, labelAddon))));
    }
    return null;
}
export default FormItemLabel;
