import { ActionCreator, createAction } from 'redux-act';

import { GetFiltersResponse } from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getFilters: ActionCreator<boolean> = createAction('GET_FILTERS');

export const getFiltersSuccess: ActionCreator<GetFiltersResponse> =
  createAction('GET_FILTERS_SUCCESS');

export const getFiltersFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_FILTERS_FAILURE'
);

export const updateGetFiltersQuery: ActionCreator<Query> = createAction(
  'UPDATE_GET_FILTERS_QUERY'
);
