import { getRoute } from '../../../lib/util';
import httpClient from '../../../lib/api';

export const resources: any = {
  bulkCollector: {
    url: '/collector/:id',
  },
  uploadBulkUrlsCsv: {
    url: '/bulkurl-collector/:id/upload-csv',
  },
  generateBulkUrls: {
    url: '/bulkurl-collector/:id/generate-urls',
  },
  getAvailableUrls: {
    url: '/bulkurl-collector/:id/batches',
  },
  getAvailableUrl: {
    url: '/bulkurl-collector/:collectorId/batches/:batchId',
  },
  associatedFilters: {
    url: '/survey/:id/filters',
  },
  filters: {
    url: '/filters',
  },
  surveys: {
    url: '/survey',
  },
  survey: {
    url: '/survey/:surveyId',
  },
  surveyCollectorUsage: {
    url: '/survey/:surveyId/collector-usage',
  },
  surveyClone: {
    url: '/survey/:surveyId/clone',
  },
  collectorList: {
    url: '/survey/:surveyId/collector',
  },
  collector: {
    url: '/collector',
  },
  templates: {
    url: '/template',
  },
  collectorUsage: {
    url: '/collector/get-usage/:collectorId',
  },
  collectorStatus: {
    url: '/collector/:collectorId',
  },
};

export class MySurveysApiService {
  getBulkUrlsCollector(id: any): any {
    return () =>
      httpClient
        .get(getRoute(resources.bulkCollector.url, { params: { id: id } }))
        .then((result: any) => {
          return result.data;
        });
  }

  uploadDocumentRequest(id: any, formData: any) {
    return () =>
      httpClient
        .post(
          getRoute(resources.uploadBulkUrlsCsv.url, { params: { id: id } }),
          formData
        )
        .then((result: any) => {
          if (result === undefined) {
            return null;
          }
          return result.data;
        });
  }

  generateBulkUrlsRequest(id: any, formData: any) {
    return () =>
      httpClient
        .post(
          getRoute(resources.generateBulkUrls.url, { params: { id: id } }),
          formData
        )
        .then((result: any) => {
          if (result === undefined) {
            return null;
          }
          return result.data;
        })
        .catch((error: any) => {
          if (error.status === 504) {
            return { error: 'timeout' };
          }

          return null;
        });
  }
  getAvailableUrls(payload: any): any {
    return () =>
      httpClient
        .get(
          getRoute(resources.getAvailableUrls.url, {
            params: { id: payload.bulkCollectorId },
          }),
          { params: payload.searchFilters }
        )
        .then((result: any) => {
          return result.data;
        });
  }
  getAvailableUrl(payload: any): any {
    return () =>
      httpClient
        .get(
          getRoute(resources.getAvailableUrl.url, {
            params: {
              collectorId: payload.bulkCollectorId,
              batchId: payload.batchId,
            },
          })
        )
        .then((result: any) => {
          return result.data;
        });
  }
}

export default new MySurveysApiService();
