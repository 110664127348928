import React from 'react';
var PopoverContentDefaultProps = {
    role: 'tooltip',
};
function PopoverContent(_a) {
    var children = _a.children, id = _a.id, className = _a.className, role = _a.role, ariaRoledescription = _a.ariaRoledescription, tabIndex = _a.tabIndex;
    return (React.createElement("div", { id: id, className: className, role: id ? role : undefined, "aria-roledescription": ariaRoledescription, tabIndex: tabIndex }, children));
}
PopoverContent.displayName = 'PopoverContent';
PopoverContent.defaultProps = PopoverContentDefaultProps;
export default PopoverContent;
