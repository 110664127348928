import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getQuestionCategoriesFailure,
  getQuestionCategoriesSuccess,
  updateGetQuestionCategoriesQuery,
} from './actions';
import { getQuestionCategoriesInitialState } from './state';
import { getQuestionCategoriesApi } from './api';
import { getQuery } from 'lib/query';
import { ErrorResponse } from 'type';

export const getQuestionCategoriesSaga = function* (): SagaIterator {
  try {
    const currentQuery = getQuery(getQuestionCategoriesInitialState.query);
    yield put(updateGetQuestionCategoriesQuery(currentQuery));

    const data = yield call(getQuestionCategoriesApi(currentQuery));

    yield put(getQuestionCategoriesSuccess(data));
  } catch (error) {
    yield put(getQuestionCategoriesFailure(error as ErrorResponse));
  }
};
