import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetUsersResponse } from './type';
import { ErrorResponse } from 'type';

export const getUsers: EmptyActionCreator = createAction('GET_USERS');

export const getUsersSuccess: ActionCreator<GetUsersResponse> =
  createAction('GET_USERS_SUCCESS');

export const getUsersFailure: ActionCreator<ErrorResponse> =
  createAction('GET_USERS_FAILURE');
