import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getSurveyCollectorUsageFailure,
  getSurveyCollectorUsageSuccess,
} from './actions';
import { getSurveyCollectorUsageApi } from './api';
import { SagaAction } from 'type';

export const getSurveyCollectorUsageSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const data = yield call(getSurveyCollectorUsageApi(action.payload));

    yield put(getSurveyCollectorUsageSuccess(data));
  } catch (error) {
    yield put(getSurveyCollectorUsageFailure(error));
  }
};
