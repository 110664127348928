import { createAction } from 'redux-act';

export const createInviteReminderMessage: any = createAction(
  'CREATE_INVITE_REMINDER_MESSAGE'
);

export const createInviteReminderMessageSuccess: any = createAction(
  'CREATE_INVITE_REMINDER_MESSAGE_SUCCESS'
);

export const createInviteReminderMessageFailure: any = createAction(
  'CREATE_INVITE_REMINDER_MESSAGE_FAILURE'
);

export const clearCreateInviteReminderMessage: any = createAction(
  'CLEAR_CREATE_INVITE_REMINDER_MESSAGE'
);
