import { getSurveyNotificationHistoryInitialState } from './getSurveyNotificationHistory/state';
import { createSurveyNotificationInitialState } from './createSurveyNotification/state';
import { updateSurveyNotificationInitialState } from './updateSurveyNotification/state';
import { getSurveyNotificationsInitialState } from './getSurveyNotifications/state';
import { getSurveyNotificationInitialState } from './getSurveyNotification/state';
import { SurveyNotificationState } from './type';

export const surveyNotificationInitialState: SurveyNotificationState = {
  createSurveyNotification: createSurveyNotificationInitialState,
  getSurveyNotification: getSurveyNotificationInitialState,
  getSurveyNotificationHistory: getSurveyNotificationHistoryInitialState,
  getSurveyNotifications: getSurveyNotificationsInitialState,
  updateSurveyNotification: updateSurveyNotificationInitialState,
};
