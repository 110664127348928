import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetCompanyUsersResponse } from './type';
import { ErrorResponse } from 'type';

export const getCompanyUsers: EmptyActionCreator =
  createAction('GET_COMPANY_USERS');

export const getCompanyUsersSuccess: ActionCreator<GetCompanyUsersResponse> =
  createAction('GET_COMPANY_USERS_SUCCESS');

export const getCompanyUsersFailure: ActionCreator<ErrorResponse> =
  createAction('GET_COMPANY_USERS_FAILURE');

export const clearGetCompanyUsers: EmptyActionCreator = createAction(
  'CLEAR_GET_COMPANY_USERS'
);
