import produce from 'immer';

import {
  clearDeleteResponse,
  deleteResponse,
  deleteResponseFailure,
  deleteResponseSuccess,
} from './actions';
import { ErrorResponse } from '../../../../type';
import { ResponseState } from '../type';

export function deleteResponseReducer(state: ResponseState): ResponseState {
  return produce(state, (draft: ResponseState) => {
    draft.deleteResponse.isDeleting = true;
    draft.deleteResponse.isDeleted = false;
  });
}

export function deleteResponseSuccessReducer(
  state: ResponseState
): ResponseState {
  return produce(state, (draft: ResponseState) => {
    draft.deleteResponse.isDeleting = false;
    draft.deleteResponse.isDeleted = true;
  });
}

export function deleteResponseFailureReducer(
  state: ResponseState,
  error: ErrorResponse
): ResponseState {
  return produce(state, (draft: ResponseState) => {
    draft.deleteResponse.isDeleting = false;
    draft.deleteResponse.isDeleted = false;
    draft.deleteResponse.error = error;
  });
}

export function clearDeleteResponseReducer(
  state: ResponseState
): ResponseState {
  return produce(state, (draft: ResponseState) => {
    draft.deleteResponse.isDeleting = false;
    draft.deleteResponse.isDeleted = false;
    draft.deleteResponse.error = undefined;
  });
}

export const deleteResponseReducers = {
  [`${deleteResponse}`]: deleteResponseReducer,
  [`${deleteResponseSuccess}`]: deleteResponseSuccessReducer,
  [`${deleteResponseFailure}`]: deleteResponseFailureReducer,
  [`${clearDeleteResponse}`]: clearDeleteResponseReducer,
};
