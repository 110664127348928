import { getDecodedToken } from './token';

export const getCompanySystemName = (): string => {
  const jwt = getDecodedToken();

  if (!jwt) {
    return '';
  }

  return jwt.company_system_name;
};

export const getTMSHost = (): string => {
  const jwt = getDecodedToken();

  if (!jwt) {
    return '';
  }

  return jwt.host;
};
