import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateRespondentProgressPayload } from './type';

export const updateRespondentProgress: ActionCreator<UpdateRespondentProgressPayload> =
  createAction('UPDATE_RESPONDENT_PROGRESS');

export const updateRespondentProgressSuccess: EmptyActionCreator = createAction(
  'UPDATE_RESPONDENT_PROGRESS_SUCCESS'
);

export const updateRespondentProgressFailure: EmptyActionCreator = createAction(
  'UPDATE_RESPONDENT_PROGRESS_FAILURE'
);
