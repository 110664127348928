export default {
  title: {
    key: '',
    default: 'Reminder Message',
  },
  confirmationButton: {
    key: '',
    default: 'Save',
  },
  secondaryButton: {
    key: '',
    default: 'Previous',
  },
  sectionTitles: {
    sender: {
      key: '',
      default: 'Sender details',
    },
    selectInvitationMessage: {
      key: '',
      default: 'Choose reminder message',
    },
    invitationMessage: {
      key: '',
      default: 'Reminder message',
    },
  },
  fields: {
    senderEmail: {
      title: {
        key: '',
        default: `Sender's email address`,
      },
    },
    senderName: {
      title: {
        key: '',
        default: `Sender's name`,
      },
      placeHolder: {
        key: '',
        default: `Enter Sender's name`,
      },
    },
    selectMessage: {
      title: {
        key: '',
        default: 'Select your reminder template',
      },
      message: {
        key: '',
        default: '(You can personalise the message in the template chosen)',
      },
    },
    subject: {
      title: {
        key: '',
        default: 'Subject',
      },
      placeHolder: {
        key: '',
        default: 'Enter Subject',
      },
    },
    content: {
      title: {
        key: '',
        default: 'Your reminder message',
      },
      placeHolder: {
        key: '',
        default: 'Enter Content',
      },
    },
  },
};
