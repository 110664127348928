import { getClass } from '../../../_lib/helper';
/**
 * Responsive properties
 */
export var responsiveProperties = [
    { property: 'display', prefix: 'flex-' },
    { property: 'direction', prefix: 'flex-' },
    { property: 'wrap', prefix: 'flex-' },
    { property: 'justifyContent', prefix: 'justify-content-' },
    { property: 'alignItems', prefix: 'align-items-' },
    { property: 'alignContent', prefix: 'align-content-' },
];
/**
 *
 * @param breakpoint
 * @param options
 */
export function mapResponsive(block, breakpoint, options) {
    if (options === null || options === undefined) {
        return '';
    }
    return responsiveProperties
        .map(function (item) {
        if (options[item.property] !== undefined) {
            return "" + item.prefix + breakpoint + "-" + options[item.property];
        }
        return undefined;
    })
        .join(' ');
}
/**
 *
 * @param block
 * @param props
 */
export function mapPropsToClass(block, props) {
    var _a;
    return getClass(block, (_a = {},
        _a["" + props.className] = props.className,
        _a["no-gutters"] = props.isNoGutters,
        _a["flex-inline"] = props.isInline,
        _a["flex-" + props.direction] = props.direction,
        _a["flex-" + props.wrap] = props.wrap,
        _a["justify-content-" + props.justifyContent] = props.justifyContent,
        _a["align-items-" + props.alignItems] = props.alignItems,
        _a["align-content-" + props.alignContent] = props.alignContent,
        _a["" + mapResponsive(block, 'xs', props.xs)] = props.xs,
        _a["" + mapResponsive(block, 'sm', props.sm)] = props.sm,
        _a["" + mapResponsive(block, 'md', props.md)] = props.md,
        _a["" + mapResponsive(block, 'lg', props.lg)] = props.lg,
        _a["" + mapResponsive(block, 'xl', props.xl)] = props.xl,
        _a["" + mapResponsive(block, 'xxl', props.xxl)] = props.xxl,
        _a), []);
}
