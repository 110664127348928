import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';

import { updateManageInviteRecipients } from 'store/modules/invites/manageInvite/actions';
import RecipientsTypeSelector from './components/recipientsTypeSelector';
import SelectTmsProfiles from './components/selectTmsProfiles';
import ManualInput from './components/manualInput';
import CSVImport from './components/CSVImport';
import { RecipientStepProps } from './type';

const Recipients = ({
  closeModal,
  nextStep,
  previousStep,
  collectorId,
}: RecipientStepProps) => {
  const dispatch = useDispatch();

  const parameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );
  const recipients = useSelector(
    (state: any) => state.emailInvites.manageInvite.data.recipients
  );

  const [recipientsState, updateRecipientsState] = useState<any>(recipients);

  const updateRecipientsStateByField = (field: string, data: any) => {
    updateRecipientsState({
      ...recipientsState,
      [field]: data,
    });
  };

  const saveRecipients = () => {
    dispatch(updateManageInviteRecipients(recipientsState));
    if (parameters.isFlow) {
      nextStep();
    } else {
      closeModal();
    }
  };

  const changeInputType = () => {
    updateRecipientsStateByField('inputType', '');
  };

  if (recipientsState.inputType === 'manualInput') {
    return (
      <ManualInput
        recipientsState={recipientsState}
        updateRecipientsStateByField={updateRecipientsStateByField}
        saveRecipients={saveRecipients}
        previousStep={changeInputType}
        isFlow={parameters.isFlow}
        closeModal={closeModal}
      />
    );
  }

  if (recipientsState.inputType === 'selectTmsProfiles') {
    return (
      <SelectTmsProfiles
        recipientsState={recipientsState}
        updateRecipientsStateByField={updateRecipientsStateByField}
        updateRecipientsState={updateRecipientsState}
        saveRecipients={saveRecipients}
        previousStep={changeInputType}
        isFlow={parameters.isFlow}
        closeModal={closeModal}
      />
    );
  }

  if (recipientsState.inputType === 'csvImport') {
    return (
      <CSVImport
        recipientsState={recipientsState}
        updateRecipientsStateByField={updateRecipientsStateByField}
        updateRecipientsState={updateRecipientsState}
        saveRecipients={saveRecipients}
        previousStep={changeInputType}
        isFlow={parameters.isFlow}
        closeModal={closeModal}
        collectorId={collectorId}
      />
    );
  }

  return (
    <RecipientsTypeSelector
      recipientsState={recipientsState}
      updateRecipientsStateByField={updateRecipientsStateByField}
      isFlow={parameters.isFlow}
      previousStep={previousStep}
      closeModal={closeModal}
    />
  );
};

export default Recipients;
