import { Cancelled, Scheduled } from '../../constants/messageStatus';
import { isTimestampEqual, isTimeStampBefore } from '../date';
import history, { getCurrentPage } from '../history';
import translations from './translations';
import { t } from '../translation';
import { getRoute } from '../util';
import { Message } from 'type';

export const compliedRoute = (
  path: string,
  surveyId: string,
  collectorId: number
) => {
  return getRoute(path, { params: { surveyId, collectorId } });
};

export const getCurrentTab = (
  surveyId: string,
  collectorId: number
): 'messages' | 'recipients' | 'invites' | null => {
  let currentPage = getCurrentPage();

  switch (currentPage) {
    case compliedRoute(
      '/surveys/:surveyId/edit/:collectorId/email-invites/messages',
      surveyId,
      collectorId
    ):
      return 'messages';
    case compliedRoute(
      '/surveys/:surveyId/edit/:collectorId/email-invites',
      surveyId,
      collectorId
    ):
      return 'invites';
    default:
      return null;
  }
};

/**
 * Get tabs to show in header, must be function for isCurrentPage to run correctly when tabs are changed
 */
export const getHeaderTabs = (surveyId: string, collectorId: number) => {
  const currentTab = getCurrentTab(surveyId, collectorId);

  return [
    {
      active: currentTab === 'invites',
      label: t(translations.emailInvites.tabs.invites),
      onClick: () => {
        history.push(
          compliedRoute(
            '/surveys/:surveyId/edit/:collectorId/email-invites',
            surveyId,
            collectorId
          )
        );
      },
    },
    {
      active: currentTab === 'messages',
      label: t(translations.emailInvites.tabs.messages),
      onClick: () => {
        history.push(
          compliedRoute(
            '/surveys/:surveyId/edit/:collectorId/email-invites/messages',
            surveyId,
            collectorId
          )
        );
      },
    },
  ];
};

export const isMessageEditable = (message: Message): boolean => {
  if (message.status === null) {
    return true;
  }

  if (
    message.status === Scheduled &&
    !isTimeStampBefore(message.scheduledDate, 120)
  ) {
    return true;
  }

  return false;
};

export const hasEditableReminder = (reminders: Message[]): boolean => {
  for (let reminder of reminders) {
    if (
      (reminder.status === Scheduled &&
        !isTimeStampBefore(reminder.scheduledDate, 120)) ||
      (reminder.status === null &&
        reminder.sentDate === null &&
        reminder.scheduledDate === null)
    ) {
      return true;
    }
  }

  return false;
};

export const shouldUpdateMessageSchedule = (
  message: Message,
  newSchedule: string | null
): boolean => {
  return (
    (message.status === Scheduled &&
      !isTimestampEqual(message.scheduledDate, newSchedule) &&
      !isTimeStampBefore(message.scheduledDate, 60)) ||
    message.status === Cancelled
  );
};
