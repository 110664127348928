import map from 'lodash/map';

import { APISurveyTemplate, SurveyTemplate, User } from 'type';
import { transformUser, transformUserBack } from './user';

export const transformSurveyTemplate = (
  surveyTemplate: APISurveyTemplate
): SurveyTemplate => {
  return {
    archivedDate: surveyTemplate.archived_date,
    createdBy: surveyTemplate.created_by
      ? transformUser(surveyTemplate.created_by)
      : ({} as User),
    createdDate: surveyTemplate.created_date,
    description: surveyTemplate.description,
    duration: surveyTemplate.duration,
    global: surveyTemplate.global,
    id: surveyTemplate.id,
    isFeatured: surveyTemplate.is_featured,
    publishedDate: surveyTemplate.published_date,
    questionCategory: surveyTemplate.question_category,
    status: surveyTemplate.status,
    stepsCompleted: surveyTemplate.steps_completed,
    structure: surveyTemplate.structure,
    summary: surveyTemplate.summary,
    thankYou: surveyTemplate.thank_you,
    title: surveyTemplate.title,
    updatedDate: surveyTemplate.updated_date,
  };
};

export const transformSurveyTemplates = (
  surveyTemplates: APISurveyTemplate[]
): SurveyTemplate[] => {
  return map(surveyTemplates, transformSurveyTemplate);
};

export const transformSurveyTemplatesBack = (
  surveyTemplate: SurveyTemplate
): APISurveyTemplate => {
  return {
    archived_date: surveyTemplate.archivedDate,
    created_by: transformUserBack(surveyTemplate.createdBy),
    created_date: surveyTemplate.createdDate,
    description: surveyTemplate.description,
    duration: surveyTemplate.duration,
    global: surveyTemplate.global,
    id: surveyTemplate.id,
    is_featured: surveyTemplate.isFeatured,
    published_date: surveyTemplate.publishedDate,
    question_category: surveyTemplate.questionCategory,
    status: surveyTemplate.status,
    steps_completed: surveyTemplate.stepsCompleted,
    structure: surveyTemplate.structure,
    summary: surveyTemplate.summary,
    thank_you: surveyTemplate.thankYou,
    title: surveyTemplate.title,
    updated_date: surveyTemplate.updatedDate,
  };
};
