import React from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FilterListIcon as FilterIcon } from '../../Icon';
import './FilterCustomModal.scss';
import { getClass } from '../../_lib/helper';
import Tooltip from '../../Tooltip';
function FilterCustomModal(_a) {
    var children = _a.children, isOpen = _a.isOpen, toggle = _a.toggle, onApply = _a.onApply, isButtonActive = _a.isButtonActive, isApplyBtnDisabled = _a.isApplyBtnDisabled, tooltip = _a.tooltip;
    return (React.createElement("div", { className: "elmo-filter__modal" },
        React.createElement(Tooltip, { title: tooltip, tabIndex: -1, placement: "bottom" },
            React.createElement(Button, { onClick: toggle, className: getClass('elmo-filter__modal-button', {}, {
                    active: isButtonActive,
                }), ariaLabel: "Filter modal" },
                React.createElement(FilterIcon, null))),
        React.createElement(Modal, { id: "FilterCustomModal", type: "side", isOpened: isOpen, closeModal: toggle, title: "Filter", primaryButton: React.createElement(Button, { type: "primary", onClick: onApply, isDisabled: isApplyBtnDisabled }, "Apply") }, children)));
}
export default FilterCustomModal;
