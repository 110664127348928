import produce from 'immer';

import {
  createRespondent,
  createRespondentFailure,
  createRespondentSuccess,
} from './actions';
import { createRespondentInitialState } from './state';
import { SurveyState } from '../type';

export function createRespondentReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.createRespondent = {
      ...createRespondentInitialState,
      isLoading: true,
    };
  });
}

export function createRespondentSuccessReducer(
  state: SurveyState,
  data: any
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.createRespondent = {
      isLoaded: true,
      isLoading: false,
      isValid: true,
      data,
    };
  });
}

export function createRespondentFailureReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.createRespondent.isLoaded = true;
    draft.createRespondent.isLoading = false;
    draft.createRespondent.isValid = false;
  });
}

export const createRespondentReducers = {
  [`${createRespondent}`]: createRespondentReducer,
  [`${createRespondentSuccess}`]: createRespondentSuccessReducer,
  [`${createRespondentFailure}`]: createRespondentFailureReducer,
};
