import { Query } from 'lib/stateTemplates';
import { GetBulkLinksState } from './type';

export const getBulkLinksInitialState: GetBulkLinksState = {
  count: 0,
  isLoaded: false,
  isLoading: false,
  isValid: false,
  list: [],
  query: Query,
};
