const translations = {
  errors: {
    removedPlaceholder: {
      title: {
        key: 'EmailInvites.inviteParticipants.step2.errors.removedPlaceholder.title',
        default: 'Validation Error',
      },
      body: {
        key: 'EmailInvites.inviteParticipants.step2.errors.removedPlaceholder.body',
        default:
          'We can see you tried to make some changes or remove the {{SurveyLink}} place holder. ' +
          'This is where the survey link will go in your message so please do not modify it.',
      },
      primaryActionButton: {
        key: 'EmailInvites.inviteParticipants.step2.errors.removedPlaceholder.primaryActionButton',
        default: 'Ok, keep the placeholder',
      },
    },
  },
};

export default translations;
