const translations = {
  sectionTitle: {
    key: '',
    default: 'Reminder Message',
  },
  sender: {
    key: '',
    default: 'From',
  },
  subject: {
    key: '',
    default: 'Subject',
  },
  message: {
    key: '',
    default: 'Message',
  },
  deleteReminder: {
    key: '',
    default: 'Delete',
  },
};

export default translations;
