import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const updateCollector: ActionCreator<any> =
  createAction('UPDATE_COLLECTOR');

export const updateCollectorSuccess: EmptyActionCreator = createAction(
  'UPDATE_COLLECTOR_SUCCESS'
);

export const updateCollectorFailure: ActionCreator<ErrorResponse> =
  createAction('UPDATE_COLLECTOR_FAILURE');

export const clearUpdateCollector: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_COLLECTOR'
);
