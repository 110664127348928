import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getPreviewSurveyFailure, getPreviewSurveySuccess } from './actions';
import { getPreviewSurveyApi } from './api';

export interface GetPreviewSurveySagaProps {
  error: boolean;
  payload: string;
  type: string;
}

export const getPreviewSurveySaga = function* (
  action: GetPreviewSurveySagaProps
): SagaIterator {
  try {
    const data = yield call(getPreviewSurveyApi(action.payload));

    yield put(getPreviewSurveySuccess(data));
  } catch (error) {
    yield put(getPreviewSurveyFailure(error));
  }
};
