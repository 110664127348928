import { call, put } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getReportFiltersForSavedOverviewReport } from 'page/OverviewReport/state/sagas/overviewReportHelpers';
import {
  saveOverviewReportForPdfFailure,
  saveOverviewReportForPdfSuccess,
} from './actions';
import { SaveOverviewReportForPDFPayload } from './type';
import { saveOverviewReportForPdfApi } from './api';
import { SagaAction } from 'type';

export const saveOverviewReportForPdfSaga = function* (
  action: SagaAction<SaveOverviewReportForPDFPayload>
): SagaIterator {
  try {
    const filters = yield select(getReportFiltersForSavedOverviewReport);

    let postData = action.payload.reportData;
    postData.filters = filters;

    const data = yield call(
      saveOverviewReportForPdfApi(action.payload.surveyId, postData)
    );

    yield put(saveOverviewReportForPdfSuccess(data));
  } catch (error) {
    yield put(saveOverviewReportForPdfFailure(error));
  }
};
