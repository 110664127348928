import { UploadedFile } from 'elmo-elements/FileUpload';
import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  uploadInviteRecipientsCSVFailure,
  uploadInviteRecipientsCSVSuccess,
} from './actions';
import { uploadInviteRecipientsCSVApi } from './api';

export interface UploadInviteRecipientsCSVSagaProps {
  error: boolean;
  payload: { collectorId: number; file: UploadedFile };
  type: string;
}

export const uploadInviteRecipientsCSVSaga = function* (
  action: UploadInviteRecipientsCSVSagaProps
): SagaIterator {
  try {
    const responseData = yield call(
      uploadInviteRecipientsCSVApi(action.payload)
    );

    yield put(
      uploadInviteRecipientsCSVSuccess({
        requestData: action.payload,
        responseData: responseData,
      })
    );
  } catch (error) {
    yield put(uploadInviteRecipientsCSVFailure(error));
  }
};
