import { GetSurveyStatsState } from './type';

export const getSurveyStatsInitialState: GetSurveyStatsState = {
  isLoading: false,
  isLoaded: false,
  isValid: false,
  count: 0,
  data: {
    responsesCount: 0,
    notificationsCount: 0,
    collaboratorsCount: 0,
  },
};
