export function getSessionStorage(
  key: string,
  format: 'string' | 'object' = 'string'
): any {
  const storedData = sessionStorage.getItem(key);

  if (storedData) {
    if (format === 'string') {
      return storedData;
    }
    if (format === 'object') {
      try {
        return JSON.parse(storedData);
      } catch (e) {
        return false;
      }
    }
  }
  return false;
}

export function setSessionStorage(
  key: string,
  data: any,
  format: 'string' | 'object' = 'string'
) {
  if (format === 'string') {
    sessionStorage.setItem(key, data);
  }

  if (format === 'object') {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
}

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};
