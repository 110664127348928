import map from 'lodash/map';

import { APIMessage, Message } from 'type';

export const transformMessage = (apiMessage: APIMessage): Message => {
  return {
    content: apiMessage.message,
    createdAt: apiMessage.created_at,
    id: apiMessage.id,
    scheduledDate: apiMessage.scheduled_date,
    senderEmail: apiMessage.from_email,
    senderName: apiMessage.from_name,
    sentDate: apiMessage.sent_date,
    status: apiMessage.status,
    subject: apiMessage.subject,
    updatedAt: apiMessage.updated_at,
    selectedTemplate: null,
  };
};

export const transformMessages = (apiMessages: APIMessage[]): Message[] => {
  return map(apiMessages, transformMessage);
};
