import produce from 'immer';

import {
  createFilter,
  clearCreateFilter,
  createFilterFailure,
  createFilterSuccess,
} from './actions';
import { createFilterInitialState } from './state';
import { FilterState } from '../type';

export function createFilterReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.createFilter.isLoading = true;
    draft.createFilter.isLoaded = false;
    draft.createFilter.isValid = false;
  });
}

export function createFilterSuccessReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.createFilter.isLoading = false;
    draft.createFilter.isLoaded = true;
    draft.createFilter.isValid = true;
  });
}

export function createFilterFailureReducer(
  state: FilterState,
  errors: any
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.createFilter.isLoading = false;
    draft.createFilter.isLoaded = true;
    draft.createFilter.error = errors;
  });
}

export function clearCreateFilterReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.createFilter = createFilterInitialState;
  });
}

export const createFilterReducers = {
  [`${createFilter}`]: createFilterReducer,
  [`${createFilterSuccess}`]: createFilterSuccessReducer,
  [`${createFilterFailure}`]: createFilterFailureReducer,
  [`${clearCreateFilter}`]: clearCreateFilterReducer,
};
