import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getFeaturedTemplatesFailure,
  getFeaturedTemplatesSuccess,
} from './actions';
import { getFeaturedTemplatesApi } from './api';

export const getFeaturedTemplatesSaga = function* (): SagaIterator {
  try {
    const params = {
      status: 'active',
      filter: 1,
      featured: true,
    };

    const data = yield call(getFeaturedTemplatesApi(params));

    yield put(getFeaturedTemplatesSuccess(data));
  } catch (error) {
    yield put(getFeaturedTemplatesFailure(error));
  }
};
