import React from 'react';
import { ArrowBackIcon } from '../../Icon';
import MenuIcon from './MenuIcon';
function SubMenuHeader(_a) {
    var onSubMenuToggle = _a.onSubMenuToggle, icon = _a.icon, title = _a.title;
    return (React.createElement("div", { className: "elmo-nav-submenu__header" },
        React.createElement("a", { onClick: onSubMenuToggle },
            React.createElement(ArrowBackIcon, null),
            React.createElement("span", { className: "elmo-nav-submenu__header-icon" },
                React.createElement(MenuIcon, { icon: icon })),
            title)));
}
export default SubMenuHeader;
