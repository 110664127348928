import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/collectors/:collectorId/invite-message-templates/:messageId';

export const updateInviteMessageTemplateApi = (
  collectorId: number,
  messageId: number,
  payload: any
) => {
  return putRequest(
    getRoute(url, {
      params: { messageId: messageId, collectorId: collectorId },
    }),
    payload
  );
};
