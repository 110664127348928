import produce from 'immer';

import {
  clearRestoreResponse,
  restoreResponse,
  restoreResponseFailure,
  restoreResponseSuccess,
} from './actions';
import { ErrorResponse } from '../../../../type';
import { ResponseState } from '../type';

export function restoreResponseReducer(state: ResponseState): ResponseState {
  return produce(state, (draft: ResponseState) => {
    draft.restoreResponse.isRestoring = true;
    draft.restoreResponse.isRestored = false;
  });
}

export function restoreResponseSuccessReducer(
  state: ResponseState
): ResponseState {
  return produce(state, (draft: ResponseState) => {
    draft.restoreResponse.isRestoring = false;
    draft.restoreResponse.isRestored = true;
  });
}

export function restoreResponseFailureReducer(
  state: ResponseState,
  error: ErrorResponse
): ResponseState {
  return produce(state, (draft: ResponseState) => {
    draft.restoreResponse.isRestoring = false;
    draft.restoreResponse.isRestored = false;
    draft.restoreResponse.error = error;
  });
}

export function clearRestoreResponseReducer(
  state: ResponseState
): ResponseState {
  return produce(state, (draft: ResponseState) => {
    draft.restoreResponse.isRestoring = false;
    draft.restoreResponse.isRestored = false;
    draft.restoreResponse.error = undefined;
  });
}

export const restoreResponseReducers = {
  [`${restoreResponse}`]: restoreResponseReducer,
  [`${restoreResponseSuccess}`]: restoreResponseSuccessReducer,
  [`${restoreResponseFailure}`]: restoreResponseFailureReducer,
  [`${clearRestoreResponse}`]: clearRestoreResponseReducer,
};
