import { takeEvery } from 'redux-saga/effects';

import {
  getBulkUrls,
  getBulkUrl,
  uploadBulkUrlCSV,
  generateBulkUrls,
} from './actions';
import { uploadBulkUrlCSVSaga } from './uploadBulkUrlCSV/sagas';
import { generateBulkUrlsSaga } from './generateBulkUrls/sagas';
import { getBulkUrlsSaga } from './getBulkUrls/sagas';
import { getBulkUrlSaga } from './getBulkUrl/sagas';

export default function* sagas() {
  yield takeEvery(generateBulkUrls, generateBulkUrlsSaga);
  yield takeEvery(getBulkUrl, getBulkUrlSaga);
  yield takeEvery(getBulkUrls, getBulkUrlsSaga);
  yield takeEvery(uploadBulkUrlCSV, uploadBulkUrlCSVSaga);
}
