import map from 'lodash/map';

import { APIUser, User } from 'type';

export const transformUser = (apiUser: APIUser): User => {
  return {
    email: apiUser.email ? apiUser.email : '',
    firstName: apiUser.first_name,
    id: apiUser.id ? apiUser.id : 0,
    lastName: apiUser.last_name,
    profileImage: apiUser.profile_image,
    uuid: apiUser.uuid,
  };
};

export const transformUsers = (users: APIUser[]): User[] => {
  return map(users, transformUser);
};

export const transformUserBack = (user: User): APIUser => {
  return {
    email: user.email ? user.email : '',
    first_name: user.firstName,
    id: user.id ? user.id : 0,
    last_name: user.lastName,
    profile_image: user.profileImage,
    uuid: user.uuid,
  };
};
