import merge from 'lodash/merge';
import find from 'lodash/find';
import map from 'lodash/map';

import { ManageInvitesData } from 'store/modules/invites/manageInvite/type';
import { FullCollector, Message } from 'type';
import { analytics } from 'lib';

const recipientsCount = (recipientDetails: any) => {
  if (recipientDetails.inputType === 'manualInput') {
    return recipientDetails.recipients.length;
  }

  if (recipientDetails.inputType === 'selectTmsProfiles') {
    return recipientDetails.uuids.length;
  }

  if (recipientDetails.inputType === 'csvImport') {
    return recipientDetails.csvUpload.recipientsCount;
  }

  return 0;
};

const sendEvent = (
  eventName: string,
  survey: any,
  collector: FullCollector,
  invite: ManageInvitesData,
  extraParams: any
) => {
  analytics.sendEvent(eventName, {
    surveyId: survey.id,
    surveyTitle: survey.title,
    collectorId: collector.id,
    collectorTitle: collector.title,
    collectorType: collector.type,
    noRecipients: recipientsCount(invite.recipients),
    noAssociatedFilters: invite.recipients.csvUpload.associatedFiltersCount,
    contactBy: invite.recipients.inputType,
    listName: invite.inviteDetails.recipientGroup,
    ...extraParams,
  });
};

export const reminderIndexEvent = (
  eventName: string,
  collector: FullCollector,
  invite: ManageInvitesData,
  reminderIndex: number
) => {
  const extraParams = {
    reminderNo: reminderIndex,
  };

  sendEvent(eventName, collector.survey, collector, invite, extraParams);
};

export const sendScheduleEvent = (
  eventName: string,
  collector: FullCollector,
  invite: ManageInvitesData
) => {
  const extraParams = {
    reminderCount: invite.reminders.length,
  };

  sendEvent(eventName, collector.survey, collector, invite, extraParams);
};

export const saveNewInviteEvent = (
  eventName: string,
  collector: FullCollector,
  invite: ManageInvitesData,
  scheduleData: any
) => {
  const reminders: any = map(scheduleData.reminders, (reminder: any) => {
    return { [`reminder${reminder.id}`]: reminder.scheduledDate };
  });

  const extraParams = {
    reminderCount: invite.reminders.length,
    sendNow: scheduleData.sendNow,
    inviteSchedule: scheduleData.scheduledDate,
    ...merge.apply(merge, reminders),
  };

  sendEvent(eventName, collector.survey, collector, invite, extraParams);
};

export const saveEditInviteEvent = (
  eventName: string,
  collector: FullCollector,
  invite: ManageInvitesData,
  scheduleData: any
) => {
  let newReminders = 0;

  const reminders: any = map(
    scheduleData.reminders,
    (reminderSchedule: Message) => {
      const reminder = find(invite.reminders, ['id', reminderSchedule.id]);
      if (reminder && reminder.status === null) {
        newReminders++;
      }

      return {
        [`reminder${reminderSchedule.id}Date`]:
          reminder && reminder.status === 'sent'
            ? reminder.sentDate
            : reminderSchedule.scheduledDate,
        [`reminder${reminderSchedule.id}IsSent`]:
          reminderSchedule.status === 'sent',
      };
    }
  );

  const extraParams = {
    reminderCount: invite.reminders.length,
    isSent: invite.message.status === 'sent',
    inviteDate:
      invite.message.status === 'sent'
        ? invite.message.sentDate
        : scheduleData.scheduledDate,
    newReminders: newReminders,
    ...merge.apply(merge, reminders),
  };

  sendEvent(eventName, collector.survey, collector, invite, extraParams);
};
