import { GetSurveyCollectorUsageState } from './type';

export const getSurveyCollectorUsageInitialState: GetSurveyCollectorUsageState =
  {
    isLoaded: false,
    isLoading: false,
    isValid: false,
    count: 0,
    collectorsInUse: [],
  };
