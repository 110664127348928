import React, { ReactElement, useEffect } from 'react';
import { Button, AddIcon } from 'elmo-elements';
import { useSelector } from 'react-redux';

import InviteMessageSummary from 'element/inviteMessageSummary';
import { isCollectorActive } from 'lib';
import { RootState } from 'store/type';
import { bodyProps } from './type';
import './style.scss';

export const preventClick = (e: { preventDefault: () => void }) => {
  e.preventDefault();
};

const Body = ({ showModal }: bodyProps): ReactElement => {
  const messagesState = useSelector(
    (state: any) => state.emailInvites.getInviteMessageTemplates
  );
  const collector = useSelector(
    (state: RootState) => state.collector.getCollector
  );

  const showAddModal = () => {
    showModal('AddInviteMessage');
  };

  useEffect(() => {
    window.addEventListener('click', preventClick);
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('click', preventClick);
    };
  }, []);

  return (
    <>
      {messagesState.data.map((inviteMessageTemplate: any, key: number) => {
        return (
          <InviteMessageSummary
            key={key}
            messageData={inviteMessageTemplate}
            showModal={showModal}
            isActive={
              collector.isLoaded && isCollectorActive(collector.collector)
            }
          />
        );
      })}
      {collector.isLoaded && isCollectorActive(collector.collector) && (
        <Button icon={<AddIcon />} id={'add-message'} onClick={showAddModal}>
          Add Message
        </Button>
      )}
    </>
  );
};

export default Body;
