import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetQuestionCategoriesResponse } from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getQuestionCategories: EmptyActionCreator = createAction(
  'GET_QUESTION_CATEGORIES'
);

export const getQuestionCategoriesSuccess: ActionCreator<GetQuestionCategoriesResponse> =
  createAction('GET_QUESTION_CATEGORIES_SUCCESS');

export const getQuestionCategoriesFailure: ActionCreator<ErrorResponse> =
  createAction('GET_QUESTION_CATEGORIES_FAILURE');

export const updateGetQuestionCategoriesQuery: ActionCreator<Query> =
  createAction('UPDATE_GET_QUESTION_CATEGORIES_QUERY');
