import { ActionCreator, createAction } from 'redux-act';

import { GetIndividualLinksResponse } from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getIndividualLinks: ActionCreator<number> = createAction(
  'GET_INDIVIDUAL_LINKS'
);

export const getIndividualLinksSuccess: ActionCreator<GetIndividualLinksResponse> =
  createAction('GET_INDIVIDUAL_LINKS_SUCCESS');

export const getIndividualLinksFailure: ActionCreator<ErrorResponse> =
  createAction('GET_INDIVIDUAL_LINKS_FAILURE');

export const updateGetIndividualLinksQuery: ActionCreator<Query> = createAction(
  'UPDATE_GET_INDIVIDUAL_LINKS_QUERY'
);
