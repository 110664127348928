import React from 'react';
import './HeaderTabs.scss';
import { getClass } from '../../_lib/helper';
function HeaderTabs(_a) {
    var tabs = _a.tabs;
    return (React.createElement("div", { className: "elmo-tabs", id: "elmo-tab" },
        React.createElement("nav", { className: "elmo-tabs__nav", id: "elmo-tab-nav" },
            React.createElement("ul", null, Array.isArray(tabs) &&
                tabs.map(function (item, index) { return (React.createElement("li", { className: getClass('elmo-tabs__item', '', {
                        active: item.active,
                    }), key: index },
                    React.createElement("a", { onClick: item.onClick }, item.label))); })))));
}
export default HeaderTabs;
