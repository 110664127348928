import React from 'react';
import DescriptionListLabel from './DescriptionListLabel';
import { getClass } from '../../_lib/helper';
function DescriptionListItem(_a) {
    var children = _a.children, label = _a.label, hasDivider = _a.hasDivider;
    return (React.createElement(React.Fragment, null,
        React.createElement(DescriptionListLabel, { hasDivider: hasDivider }, label),
        React.createElement("dd", { className: getClass('elmo-dl__dd', {}, { 'has-divider': !!hasDivider }) }, children ? children : '-')));
}
export default DescriptionListItem;
