/* eslint-disable max-len */
import React from 'react';
function AppSwitcher() {
    return (React.createElement("g", null,
        React.createElement("circle", { cx: "3", cy: "3", r: "3" }),
        React.createElement("circle", { cx: "3", cy: "12", r: "3" }),
        React.createElement("circle", { cx: "3", cy: "21", r: "3" }),
        React.createElement("circle", { cx: "21", cy: "3", r: "3" }),
        React.createElement("circle", { cx: "21", cy: "12", r: "3" }),
        React.createElement("circle", { cx: "21", cy: "21", r: "3" }),
        React.createElement("circle", { cx: "12", cy: "3", r: "3" }),
        React.createElement("circle", { cx: "12", cy: "12", r: "3" }),
        React.createElement("circle", { cx: "12", cy: "21", r: "3" })));
}
export default AppSwitcher;
