import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { CreateEmailTemplatePayload } from './type';
import { ErrorResponse } from 'type';

export const createEmailTemplate: ActionCreator<CreateEmailTemplatePayload> =
  createAction('CREATE_EMAIL_TEMPLATE');

export const createEmailTemplateSuccess: EmptyActionCreator = createAction(
  'CREATE_EMAIL_TEMPLATE_SUCCESS'
);

export const createEmailTemplateFailure: ActionCreator<ErrorResponse> =
  createAction('CREATE_EMAIL_TEMPLATE_FAILURE');

export const clearCreateEmailTemplate: EmptyActionCreator = createAction(
  'CLEAR_EMAIL_TEMPLATE'
);
