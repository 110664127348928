import { RouteObject } from 'react-router-dom';
import React from 'react';

import CreateTheme from './pages/CreateTheme';
import ThemeList from './pages/ThemeList';
import EditTheme from './pages/EditTheme';

const routes: RouteObject = {
  path: '/themes',
  children: [
    {
      path: '/themes/create',
      element: <CreateTheme />,
    },
    {
      path: '/themes/:themeId/edit',
      element: <EditTheme />,
    },
    {
      path: '/themes/:themeId/copy',
      element: <CreateTheme />,
    },
    {
      path: '/themes',
      element: <ThemeList />,
    },
  ],
};

export default routes;
