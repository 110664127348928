import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/collectors/:collectorId/invite-message-templates';

export const getInviteMessageTemplatesApi = (collectorId: number) => {
  return getRequest(
    getRoute(url, {
      params: { collectorId: collectorId },
    })
  );
};
