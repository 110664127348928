import {
  CSVUpload,
  InviteDetails,
  ManageInvitesData,
  ManageInvitesState,
  Recipients,
  ReminderSchedules,
  Schedules,
} from './type';
import { Message } from 'type';

export const inviteDetails: InviteDetails = {
  recipientGroup: '',
};

export const csvUpload: CSVUpload = {
  recipientsCount: 0,
  associatedFiltersCount: 0,
  displayName: null,
  path: null,
};

export const recipients: Recipients = {
  recipients: [],
  uuids: [],
  csvUpload: csvUpload,
  inputType: '',
  filters: {
    department: null,
    position: null,
    location: null,
    manager: null,
  },
};

export const message: Message = {
  selectedTemplate: null,
  senderEmail: '',
  senderName: '',
  subject: '',
  content: '',
  scheduledDate: null,
  sentDate: null,
  status: null,
};

export const reminders: ReminderSchedules = {
  id: undefined,
  scheduledDate: null,
};

export const schedules: Schedules = {
  sendNow: false,
  scheduledDate: null,
  reminders: [],
};

export const manageInviteData: ManageInvitesData = {
  inviteDetails: inviteDetails,
  recipients: recipients,
  message: message,
  reminders: [],
};

export const manageInviteInitialState: ManageInvitesState = {
  parameters: {
    isEdit: false,
    isDraft: false,
    isFlow: true,
    isScheduled: false,
    currentStep: '',
    currentReminder: 0,
  },
  data: manageInviteData,
};
