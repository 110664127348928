import { useDispatch, useSelector } from 'react-redux';
import WarningIcon from '@mui/icons-material/Warning';
import PeopleIcon from '@mui/icons-material/People';
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import LoadingModal from 'element/Modal/components/loading';
import TmsProfileSelector from 'element/tmsProfileSelector';
import BaseModal from 'element/Modal/components/baseModal';
import EmptyState from 'sharedComponents/EmptyState';
import { getCompanyUsers } from 'store/actions';
import { SelectTmsProfilesProps } from './type';
import translations from './translations';
import { RootState } from 'store/type';
import { t } from 'lib/translation';
import './styles.scss';

const SelectTmsProfiles = ({
  recipientsState,
  updateRecipientsStateByField,
  updateRecipientsState,
  previousStep,
  saveRecipients,
  isFlow,
  closeModal,
}: SelectTmsProfilesProps) => {
  const dispatch = useDispatch();

  const companyUsers = useSelector(
    (state: RootState) => state.user.getCompanyUsers
  );

  useEffect(() => {
    if (!companyUsers.isLoading && !companyUsers.isLoaded) {
      dispatch(getCompanyUsers());
    }
  }, [dispatch, companyUsers.isLoading, companyUsers.isLoaded]);

  if (!companyUsers.isLoaded) {
    return (
      <>
        <LoadingModal closeCallback={closeModal} title={''} />
      </>
    );
  }

  return (
    <>
      <BaseModal
        closeModal={closeModal}
        title={
          <>
            <PeopleIcon className={'header-icon'} />
            {t(translations.title)}
          </>
        }
        modalType={'x-large'}
        primaryButtonText={
          isFlow
            ? t(translations.primaryButton.saveAndContinue)
            : t(translations.primaryButton.saveAndClose)
        }
        primaryButtonIsDisabled={isEmpty(recipientsState.uuids)}
        primaryButtonClick={saveRecipients}
        secondaryButtonText={t(translations.backButton)}
        secondaryButtonClick={previousStep}
        buttonsType={'separate'}
      >
        {!companyUsers.isValid ? (
          <>
            <EmptyState
              icon={<WarningIcon />}
              content={translations.errorState}
              primaryButtonAction={() => dispatch(getCompanyUsers())}
            />
          </>
        ) : (
          <>
            <TmsProfileSelector
              selectedUsers={recipientsState.uuids}
              filters={recipientsState.filters}
              updateSelectedUsers={(users: number[]) =>
                updateRecipientsStateByField('uuids', users)
              }
              updateFilters={(newFilters: any) => {
                updateRecipientsStateByField('filters', newFilters);
              }}
              updateState={(data: any) => {
                updateRecipientsState({ ...recipientsState, ...data });
              }}
            />
          </>
        )}
      </BaseModal>
    </>
  );
};

export default SelectTmsProfiles;
