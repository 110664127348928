import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React from 'react';
import { enableES5 } from 'immer';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import 'elmo-elements/main.css';
// Froala Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min';
// Froala Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
// Froala requires Font Awesome.
import 'font-awesome/css/font-awesome.css';

import './lib/polyfill/index';
import store from './store/store';
import App from './App';
import './lib/translation';

enableES5();

const container = document.getElementById('root') as Element;

const root = createRoot(container);

root.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>
);
