import {
  Layout,
  PlaceholderDashboard,
  PlaceholderHeader,
  PlaceholderListTable,
} from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const IndividualShareReportComponent = lazy(() =>
  withDelay(() => import('./IndividualShareReportComponent'))
);

const IndividualShareReport = (): ReactElement => {
  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={true}
              hasTabs={true}
              hasBackButton={true}
              hasButtons={false}
              numberOfTabs={2}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderDashboard cols={1} rows={1} />
            <PlaceholderListTable cols={4} hasAvatar={false} />
          </Layout.Content>
        </>
      }
    >
      <IndividualShareReportComponent />
    </Suspense>
  );
};

export default IndividualShareReport;
