const translations = {
  selectAll: {
    key: 'element.tmsProfileSelector.renderUserList.selectAll',
    default: 'Select All',
  },
  recipients: {
    key: 'element.tmsProfileSelector.renderUserList.recipients',
    default: 'Participants',
  },
  search: {
    key: 'element.tmsProfileSelector.renderUserList.search',
    default: 'Search',
  },
};

export default translations;
