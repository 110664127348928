import React, { ReactElement } from 'react';
import { Layout } from 'elmo-elements';

import translations from '../../translations';
import { t } from 'lib/translation';
import './Maintenance.scss';

const Maintenance = (): ReactElement => {
  return (
    <>
      <Layout.Content>
        <div className="maintenance-container">
          <img className="logo" src="elmo-logo.png" alt="" />
          <h1>{t(translations.underMaintenance.title)}</h1>
          <h3>
            {t(translations.underMaintenance.subtitle1)}
            <br />
            {t(translations.underMaintenance.subtitle2)}
          </h3>
          <br />
          <br />
          <p>{t(translations.underMaintenance.content)}</p>
        </div>
      </Layout.Content>
    </>
  );
};

export default Maintenance;
