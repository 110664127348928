import { createReducer } from 'redux-act';

import { getIndividualResponsesReducers } from './getIndividualResponses/reducers';
import { restoreResponseReducers } from './restoreResponse/reducers';
import { deleteResponseReducers } from './deleteResponse/reducers';
import { getResponseReducers } from './getResponse/reducers';
import { responseInitialState } from './state';

export default createReducer(
  {
    ...deleteResponseReducers,
    ...restoreResponseReducers,
    ...getIndividualResponsesReducers,
    ...getResponseReducers,
  },
  responseInitialState
);
