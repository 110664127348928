const translations = {
  loadingText: {
    key: '',
    default: 'Loading...',
  },
  emptyState: {
    noContent: {
      header: {
        key: '',
        default: 'Whoops! This link is invalid.',
      },
      body: {
        key: '',
        default: 'Please contact your administrator for assistance.',
      },
    },
    inactiveSurvey: {
      header: {
        key: '',
        default: 'This survey is no longer available.',
      },
      body: {
        key: '',
        default: 'Please contact administration for further details.',
      },
    },
    inactiveCollector: {
      header: {
        key: '',
        default: 'This survey collector is no longer available.',
      },
      body: {
        key: '',
        default: 'Please contact administration for further details.',
      },
    },
    inactiveInvite: {
      header: {
        key: '',
        default: 'This survey invite is no longer active.',
      },
      body: {
        key: '',
        default: 'Please contact administration for further details.',
      },
    },
  },
  thankYou: {
    key: '',
    default: 'Thank you for completing the survey!',
  },
  submitError: {
    key: '',
    default:
      'There is an error on submitting the survey result.<br>' +
      'Please contact your administrator or technical support.',
  },
  resumeSurvey: {
    title: {
      key: '',
      default: 'Resume previous session',
    },
    secondaryButton: {
      key: '',
      default: 'Start Over',
    },
    primaryButton: {
      key: '',
      default: 'Resume',
    },
    message: {
      key: '',
      default:
        'Would you like to resume from where you left off or start over? ',
    },
    messageAppendix: {
      key: '',
      default: 'Note: Start over will erase previously answered questions.',
    },
  },
};

export default translations;
