import DescriptionIcon from '@mui/icons-material/Description';
import { useDispatch, useSelector } from 'react-redux';
import { FormItem, Input } from 'elmo-elements';
import React, { useState } from 'react';

import { updateManageInviteDetails } from 'store/modules/invites/manageInvite/actions';
import BaseModal from 'element/Modal/components/baseModal';
import { InviteDetailsProps } from './type';
import translations from './translations';
import { t } from 'lib/translation';

const InviteDetails = ({ closeModal, nextStep }: InviteDetailsProps) => {
  const dispatch = useDispatch();

  const parameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );

  const inviteDetails = useSelector(
    (state: any) => state.emailInvites.manageInvite.data.inviteDetails
  );

  const [inviteDetailsState, updateInviteDetailsState] =
    useState<any>(inviteDetails);

  const saveDetails = () => {
    dispatch(updateManageInviteDetails(inviteDetailsState));
    if (parameters.isFlow) {
      nextStep();
    } else {
      closeModal();
    }
  };

  const updateLocalState = (field: string, data: any) => {
    updateInviteDetailsState({
      ...inviteDetailsState,
      [field]: data,
    });
  };

  return (
    <BaseModal
      id={'invite-details'}
      closeModal={closeModal}
      title={
        <>
          <DescriptionIcon className={'header-icon'} />
          {t(translations.title)}
        </>
      }
      primaryButtonClick={saveDetails}
      primaryButtonIsDisabled={inviteDetailsState.recipientGroup === ''}
      primaryButtonText={
        parameters.isFlow
          ? t(translations.primaryButton.saveContinue)
          : t(translations.primaryButton.saveClose)
      }
      hasSecondaryButton={false}
    >
      <>
        <FormItem
          label={t(translations.form.name.label)}
          message={t(translations.form.name.message)}
        >
          <Input
            id={'invite-title'}
            value={inviteDetailsState.recipientGroup}
            onChange={(e: any) =>
              updateLocalState('recipientGroup', e.target.value)
            }
          />
        </FormItem>
      </>
    </BaseModal>
  );
};

export default InviteDetails;
