import { GetAssociatedFiltersState } from './type';
import { Query } from 'lib/stateTemplates';

export const getAssociatedFiltersInitialState: GetAssociatedFiltersState = {
  count: 0,
  data: [],
  isLoaded: false,
  isLoading: false,
  isValid: false,
  surveyId: 0,
  query: {
    ...Query,
    limit: 100,
  },
};
