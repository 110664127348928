import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';
import { UploadedFile } from 'elmo-elements/FileUpload';

import { ErrorResponse } from 'type';

export const uploadInviteRecipientsCSV: ActionCreator<{
  collectorId: number;
  file: UploadedFile;
}> = createAction('UPLOAD_INVITE_RECIPIENTS_CSV');

export const uploadInviteRecipientsCSVSuccess: ActionCreator<any> =
  createAction('UPLOAD_INVITE_RECIPIENTS_CSV_SUCCESS');

export const uploadInviteRecipientsCSVFailure: ActionCreator<ErrorResponse> =
  createAction('UPLOAD_INVITE_RECIPIENTS_CSV_FAILURE');

export const clearUploadInviteRecipientsCSV: EmptyActionCreator = createAction(
  'CLEAR_UPLOAD_INVITE_RECIPIENTS_CSV'
);
