var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactDOM from 'react-dom';
import ConfirmDialog from './component/ConfirmDialog';
import DangerDialog from './component/DangerDialog';
import WarningDialog from './component/WarningDialog';
import InfoDialog from './component/InfoDialog';
import SuccessDialog from './component/SuccessDialog';
function destroy(container) {
    var unmountResult = ReactDOM.unmountComponentAtNode(container);
    if (unmountResult && container.parentNode) {
        container.parentNode.removeChild(container);
    }
}
function onOkHandler(_a, container) {
    var onOk = _a.onOk;
    destroy(container);
    if (onOk) {
        onOk();
    }
}
function onCancelHandler(_a, container) {
    var onCancel = _a.onCancel;
    destroy(container);
    if (onCancel) {
        onCancel();
    }
}
function render(DialogComponent, props) {
    var container = document.createElement('div');
    document.body.appendChild(container);
    var dialogProps = __assign(__assign({}, props), { onOk: function () { return onOkHandler(props, container); }, onCancel: function () { return onCancelHandler(props, container); } });
    ReactDOM.render(React.createElement(DialogComponent, __assign({}, dialogProps)), container);
}
// eslint-disable-next-line  no-empty,no-empty-function,@typescript-eslint/no-empty-function
function Dialog(props) { }
function confirm(props) {
    render(ConfirmDialog, props);
}
function danger(props) {
    render(DangerDialog, props);
}
function warning(props) {
    render(WarningDialog, props);
}
function info(props) {
    render(InfoDialog, props);
}
function success(props) {
    render(SuccessDialog, props);
}
Dialog.confirm = confirm;
Dialog.danger = danger;
Dialog.warning = warning;
Dialog.info = info;
Dialog.success = success;
export default Dialog;
