import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/template/:surveyTemplateId';
const globalApiUrl = '/admin/template/:surveyTemplateId';

export const updateSurveyTemplateApi = (
  surveyTemplateId: number,
  isGlobal: boolean,
  payload: any
) => {
  const url = isGlobal ? globalApiUrl : apiUrl;

  return putRequest(
    getRoute(url, { params: { surveyTemplateId: surveyTemplateId } }),
    payload
  );
};
