import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { runSurveyFailure, runSurveySuccess } from './actions';
import { RunSurveyPayload } from './type';
import { runSurveyApi } from './api';

export interface RunSurveySagaProps {
  error: boolean;
  payload: RunSurveyPayload;
  type: string;
}

export const runSurveySaga = function* (
  action: RunSurveySagaProps
): SagaIterator {
  try {
    const data = yield call(runSurveyApi(action.payload));

    yield put(runSurveySuccess(data));
  } catch (error) {
    yield put(runSurveyFailure());
  }
};
