var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from '../../main';
import React from 'react';
export default function ActionCardFooterElement(_a) {
    var actions = _a.actions, position = _a.position;
    return (React.createElement("div", { className: "elmo-actioncard__footer-" + position }, actions.map(function (_a, index) {
        var label = _a.label, _b = _a.type, type = _b === void 0 ? 'primary' : _b, _c = _a.isText, isText = _c === void 0 ? true : _c, restButtonProps = __rest(_a, ["label", "type", "isText"]);
        return (React.createElement(Button, __assign({}, restButtonProps, { key: index, className: "elmo-actioncard__footer-btn", type: type, isText: true }), label));
    })));
}
