import { Layout, MessageBlock } from 'elmo-elements';
import React from 'react';

import translations from '../translations';
import { t } from 'lib/translation';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Layout.Content>
            <div style={{ marginTop: '48px' }}>
              <MessageBlock>
                <MessageBlock.Header>
                  {t(translations.errorBoundary.title)}
                </MessageBlock.Header>
                <MessageBlock.Body>
                  {t(translations.errorBoundary.body)}
                </MessageBlock.Body>
              </MessageBlock>
            </div>
          </Layout.Content>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
