import { RouteObject } from 'react-router-dom';
import React from 'react';

import ManageInvite from 'page/EmailInvites/components/manageInvite';
import MessagesIndex from 'page/EmailInvites/components/messages';
import InviteRecipients from './pages/InviteRecipients';
import Invites from './pages/InvitesList';

const EmailInviteRoutes: RouteObject = {
  path: '/surveys/:surveyId/edit/:collectorId/email-invites',
  children: [
    {
      path: '/surveys/:surveyId/edit/:collectorId/email-invites/messages',
      element: <MessagesIndex />,
    },
    {
      path: '/surveys/:surveyId/edit/:collectorId/email-invites/create',
      element: <ManageInvite />,
    },
    {
      path: '/surveys/:surveyId/edit/:collectorId/email-invites/:inviteId/edit',
      element: <ManageInvite />,
    },
    {
      path: '/surveys/:surveyId/edit/:collectorId/email-invites/:inviteId',
      element: <InviteRecipients />,
    },
    {
      path: '/surveys/:surveyId/edit/:collectorId/email-invites',
      element: <Invites />,
    },
  ],
};

export default EmailInviteRoutes;
