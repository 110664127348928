import { Layout, PlaceholderHeader, PlaceholderDashboard } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';

const CreateSurveyTemplateComponent = lazy(
  () => import('./CreateSurveyTemplateComponent')
);

const CreateSurveyTemplate = (): ReactElement => {
  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={false}
              hasBackButton={true}
              hasButtons={false}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderDashboard cols={1} rows={2} />
          </Layout.Content>
        </>
      }
    >
      <CreateSurveyTemplateComponent />
    </Suspense>
  );
};

export default CreateSurveyTemplate;
