import { ActionCreator, createAction } from 'redux-act';

import { GetSurveyTemplatesResponse } from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const updateSurveyTemplatesQuery: ActionCreator<Query> = createAction(
  'UPDATE_SURVEY_TEMPLATES_QUERY'
);

export const getSurveyTemplates: ActionCreator<string> = createAction(
  'GET_SURVEY_TEMPLATES'
);

export const getSurveyTemplatesSuccess: ActionCreator<GetSurveyTemplatesResponse> =
  createAction('GET_SURVEY_TEMPLATES_SUCCESS');

export const getSurveyTemplatesFailure: ActionCreator<ErrorResponse> =
  createAction('GET_SURVEY_TEMPLATES_FAILURE');
