import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GenerateBulkUrlsPayload, GenerateBulkUrlsResponse } from './type';
import { ErrorResponse } from 'type';

export const generateBulkUrls: ActionCreator<GenerateBulkUrlsPayload> =
  createAction('GENERATE_BULK_URLS');

export const generateBulkUrlsSuccess: ActionCreator<GenerateBulkUrlsResponse> =
  createAction('GENERATE_BULK_URLS_SUCCESS');

export const generateBulkUrlsFailure: ActionCreator<ErrorResponse> =
  createAction('GENERATE_BULK_URLS_FAILURE');

export const clearGenerateBulkUrls: EmptyActionCreator = createAction(
  'CLEAR_GENERATE_BULK_URLS'
);
