import { getUserProfileFilterOptionsInitialState } from './getUserProfileFilterOptions/state';
import { getCompanyUsersInitialState } from './getCompanyUsers/state';
import { getUsersInitialState } from './getUsers/state';
import { UserState } from './type';

export const userInitialState: UserState = {
  getUsers: getUsersInitialState,
  getCompanyUsers: getCompanyUsersInitialState,
  getUserProfileFilterOptions: getUserProfileFilterOptionsInitialState,
};
