import produce from 'immer';

import {
  createIndividualLink,
  createIndividualLinkFailure,
  createIndividualLinkSuccess,
  clearCreateIndividualLink,
} from './actions';
import { createIndividualLinkInitialState } from './state';
import { CreateIndividualLinkResponse } from './type';
import { ShareReportState } from '../type';
import { ErrorResponse } from 'type';

export const createIndividualLinkReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.createIndividualLink = { ...createIndividualLinkInitialState };
    draft.createIndividualLink.isLoading = true;
  });
};

export const createIndividualLinkSuccessReducer = (
  state: ShareReportState,
  data: CreateIndividualLinkResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.createIndividualLink.isLoading = false;
    draft.createIndividualLink.isLoaded = true;
    draft.createIndividualLink.isValid = true;
    draft.createIndividualLink.shortUrl = data.data;
  });
};

export const createIndividualLinkFailureReducer = (
  state: ShareReportState,
  error: ErrorResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.createIndividualLink.isLoading = false;
    draft.createIndividualLink.isLoaded = true;
    draft.createIndividualLink.error = error;
  });
};

export const clearCreateIndividualLinkReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.createIndividualLink = createIndividualLinkInitialState;
  });
};

export const createIndividualLinkReducers = {
  [`${createIndividualLink}`]: createIndividualLinkReducer,
  [`${createIndividualLinkSuccess}`]: createIndividualLinkSuccessReducer,
  [`${createIndividualLinkFailure}`]: createIndividualLinkFailureReducer,
  [`${clearCreateIndividualLink}`]: clearCreateIndividualLinkReducer,
};
