import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { updateFilterFailure, updateFilterSuccess } from './actions';
import { UpdateFilterPayload } from './type';
import { updateFilterApi } from './api';
import { SagaAction } from 'type';

export const updateFilterSaga = function* (
  action: SagaAction<UpdateFilterPayload>
): SagaIterator {
  try {
    yield call(updateFilterApi(action.payload.filterId, action.payload.data));

    yield put(updateFilterSuccess());
  } catch (errors) {
    yield put(updateFilterFailure(errors));
  }
};
