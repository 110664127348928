import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'elmo-elements';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import StepperWithCard from 'element/stepper/components/withCard';
import { updateManageInviteParameter } from 'store/actions';
import { Scheduled, Sent } from 'constants/messageStatus';
import InviteDetails from './components/inviteDetails';
import Submission from './components/submission';
import Recipients from './components/recipients';
import Reminder from './components/reminders';
import Message from './components/message';
import translations from './translations';
import { t } from 'lib/translation';

const Summary = () => {
  const dispatch = useDispatch();

  const parameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );

  const inviteData = useSelector(
    (state: any) => state.emailInvites.manageInvite.data
  );

  const steps = [
    {
      id: 'invite-details',
      label: t(translations.steps.details.label),
      description: t(translations.steps.details.description),
    },
    {
      id: 'invite-recipients',
      label: t(translations.steps.recipients.label),
      description: t(translations.steps.recipients.description),
    },
    {
      id: 'invite-message',
      label: t(translations.steps.message.label),
      description: t(translations.steps.message.description),
    },
    {
      id: 'reminders',
      label: t(translations.steps.reminder.label),
      description: t(translations.steps.reminder.description),
    },
    {
      label: t(translations.steps.sendInvite.label),
      description: t(translations.steps.sendInvite.description),
    },
  ];

  const isDetailsStepComplete = (): boolean => {
    return !isEmpty(inviteData.inviteDetails.recipientGroup);
  };

  const isRecipientsStepComplete = (): boolean => {
    if (
      (isEmpty(inviteData.recipients.recipients) ||
        isEmpty(inviteData.recipients.uuids)) &&
      isEmpty(inviteData.recipients.inputType)
    ) {
      return false;
    }

    return true;
  };

  const isMessageStepComplete = (): boolean => {
    if (
      isEmpty(inviteData.message.subject) ||
      isEmpty(inviteData.message.content)
    ) {
      return false;
    }

    return true;
  };

  const isSendScheduleComplete = (): boolean => {
    if (
      inviteData.message.status !== Sent &&
      inviteData.message.status !== Scheduled
    ) {
      return false;
    }

    return true;
  };

  const getCurrentStep = () => {
    if (parameters.isEdit) {
      return 5;
    }

    if (!isDetailsStepComplete()) {
      return 0;
    }

    if (isDetailsStepComplete() && !isRecipientsStepComplete()) {
      return 1;
    }

    if (isRecipientsStepComplete() && !isMessageStepComplete()) {
      return 2;
    }

    if (isMessageStepComplete() && !isSendScheduleComplete()) {
      return 3;
    }

    return 4;
  };

  const updateCurrentStep = (step: string, params?: any) => {
    dispatch(updateManageInviteParameter({ currentStep: step, ...params }));
  };

  return (
    <>
      <Container size="lg">
        <Row>
          <Col md={8}>
            <StepperWithCard
              steps={steps}
              activeStep={getCurrentStep()}
              heading={t(translations.stepperHeading)}
            />
          </Col>
          <Col md={16}>
            <InviteDetails
              editInviteDetails={() => updateCurrentStep('inviteDetails')}
              showSummary={isDetailsStepComplete()}
              showAddAction={!isDetailsStepComplete()}
            />

            <Recipients
              showSummary={isRecipientsStepComplete()}
              editRecipients={() => updateCurrentStep('recipientsInput')}
              showAddAction={
                isDetailsStepComplete() && !isRecipientsStepComplete()
              }
            />

            <Message
              editMessage={() => updateCurrentStep('message')}
              showSummary={isMessageStepComplete()}
              showAddAction={
                isRecipientsStepComplete() && !isMessageStepComplete()
              }
            />

            <Reminder
              editReminder={(reminderId: number | undefined = -1) =>
                updateCurrentStep('reminder', { currentReminder: reminderId })
              }
            />

            <Submission
              editSchedule={() => updateCurrentStep('schedule')}
              editSendSchedule={() => updateCurrentStep('sendSchedule')}
              editReminder={() =>
                updateCurrentStep('reminder', { currentReminder: -1 })
              }
              showAddAction={isMessageStepComplete()}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Summary;
