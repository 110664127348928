import produce from 'immer';

import {
  getSurveyNotificationHistory,
  getSurveyNotificationHistoryFailure,
  getSurveyNotificationHistorySuccess,
  updateSurveyNotificationHistoryQuery,
} from './actions';
import { transformSurveyNotificationHistory } from '../../../transformers';
import { getSurveyNotificationHistoryInitialState } from './state';
import { GetSurveyNotificationHistoryResponse } from './type';
import { SurveyNotificationState } from '../type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getSurveyNotificationHistoryReducer = (
  state: SurveyNotificationState
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotificationHistory = {
      ...getSurveyNotificationHistoryInitialState,
    };
    draft.getSurveyNotificationHistory.isLoading = true;
  });
};

export const getSurveyNotificationHistorySuccessReducer = (
  state: SurveyNotificationState,
  data: GetSurveyNotificationHistoryResponse
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotificationHistory.isLoading = false;
    draft.getSurveyNotificationHistory.isLoaded = true;
    draft.getSurveyNotificationHistory.isValid = true;
    draft.getSurveyNotificationHistory.count = data.count;
    draft.getSurveyNotificationHistory.data =
      transformSurveyNotificationHistory(data.data);
  });
};

export const getSurveyNotificationHistoryFailureReducer = (
  state: SurveyNotificationState,
  error: ErrorResponse
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotificationHistory.isLoading = false;
    draft.getSurveyNotificationHistory.isLoaded = true;
    draft.getSurveyNotificationHistory.error = error;
  });
};

export const updateSurveyNotificationHistoryQueryReducers = (
  state: SurveyNotificationState,
  query: Query
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotificationHistory.query = query;
  });
};

export const getSurveyNotificationHistoryReducers = {
  [`${getSurveyNotificationHistory}`]: getSurveyNotificationHistoryReducer,
  [`${getSurveyNotificationHistorySuccess}`]:
    getSurveyNotificationHistorySuccessReducer,
  [`${getSurveyNotificationHistoryFailure}`]:
    getSurveyNotificationHistoryFailureReducer,
  [`${updateSurveyNotificationHistoryQuery}`]:
    updateSurveyNotificationHistoryQueryReducers,
};
