import produce from 'immer';

import {
  getFilter,
  getFilterFailure,
  getFilterSuccess,
  clearFilterState,
} from './actions';
import { sharedStateInitialState } from '../type';
import { filterInitialState } from './state';

export function getFilterReducer(
  state: sharedStateInitialState,
  filterId: number
) {
  return produce(state, (draft) => {
    draft.filter.isLoaded = false;
    draft.filter.isLoading = true;
    draft.filter.isValid = false;
    draft.filter.filterId = filterId;
    draft.filter.data = {};
  });
}

export function getFilterSuccessReducer(
  state: sharedStateInitialState,
  data: any
) {
  return produce(state, (draft) => {
    draft.filter.isLoaded = true;
    draft.filter.isLoading = false;
    draft.filter.isValid = true;
    draft.filter.data = data.data;
  });
}

export function getFilterFailureReducer(state: sharedStateInitialState) {
  return produce(state, (draft) => {
    draft.filter.isLoaded = true;
    draft.filter.isLoading = false;
  });
}

export function clearFilterStateReducer(state: sharedStateInitialState) {
  return produce(state, (draft) => {
    draft.filter = filterInitialState;
  });
}

export const filterReducers = {
  [`${getFilter}`]: getFilterReducer,
  [`${getFilterSuccess}`]: getFilterSuccessReducer,
  [`${getFilterFailure}`]: getFilterFailureReducer,
  [`${clearFilterState}`]: clearFilterStateReducer,
};
