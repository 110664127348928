import map from 'lodash/map';

import {
  AnonCollector,
  APIAnonCollector,
  APICollector,
  APIFullCollector,
  Collector,
  FullCollector,
} from 'type';
import { transformSurvey } from './survey';

export const transformCollector = (collector: APICollector): Collector => {
  return {
    active: collector.active,
    hash: collector.hash,
    id: collector.id,
    responsesCount: collector.responses_count,
    shortUrl: collector.short_url,
    survey: collector.survey && transformSurvey(collector.survey),
    title: collector.title,
    type: collector.type,
    createdDate: collector.created_date,
  };
};

export const transformCollectors = (
  collectors: APICollector[]
): Collector[] => {
  return map(collectors, transformCollector);
};

export const transformAnonCollector = (
  collector: APIAnonCollector
): AnonCollector => {
  return {
    active: collector.active,
    allowMultipleReplies: collector.allow_multiple_replies,
    hash: collector.hash,
    id: collector.id,
    shortUrl: collector.short_url,
    title: collector.title,
    type: collector.type,
    surveyId: collector.survey_id,
  };
};

export const transformFullCollector = (
  collector: APIFullCollector
): FullCollector => {
  return {
    active: collector.active,
    allowMultipleReplies: collector.allow_multiple_replies,
    bulkCollectorBatch: collector.bulk_collector_batch,
    closedDate: collector.closed_date,
    createdDate: collector.created_date,
    hash: collector.hash,
    id: collector.id,
    responsesCount: collector.responses_count,
    shortUrl: collector.short_url,
    survey: collector.survey && transformSurvey(collector.survey),
    title: collector.title,
    type: collector.type,
    updatedDate: collector.updated_date,
    usage: collector.usage,
  };
};

export const transformFullCollectors = (
  collectors: APIFullCollector[]
): FullCollector[] => {
  return map(collectors, transformFullCollector);
};
