const translations = {
  text: {
    key: '',
    default: 'This is a preview link and no responses will be recorded',
  },
  loadingText: {
    key: '',
    default: 'Loading...',
  },
  emptyState: {
    noContent: {
      header: {
        key: '',
        default: 'Whoops! This link is invalid.',
      },
      body: {
        key: '',
        default: 'Please contact your administrator for assistance.',
      },
      primaryButtonText: {
        key: '',
        default: '',
      },
    },
    noResults: {
      header: {
        key: '',
        default: '',
      },
      body: {
        key: '',
        default: '',
      },
    },
  },
};

export default translations;
