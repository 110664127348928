import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const cloneSurvey: any = createAction('CLONE_SURVEY');

export const cloneSurveySuccess: EmptyActionCreator = createAction(
  'CLONE_SURVEY_SUCCESS'
);

export const cloneSurveyFailure: ActionCreator<ErrorResponse> = createAction(
  'CLONE_SURVEY_FAILURE'
);

export const clearCloneSurvey: EmptyActionCreator =
  createAction('CLEAR_CLONE_SURVEY');
