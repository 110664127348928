import { createAction } from 'redux-act';

export const getInviteMessageTemplates: any = createAction(
  'GET_INVITE_MESSAGE_TEMPLATES'
);

export const getInviteMessageTemplatesSuccess: any = createAction(
  'GET_INVITE_MESSAGE_TEMPLATES_SUCCESS'
);

export const getInviteMessageTemplatesFailure: any = createAction(
  'GET_INVITE_MESSAGE_TEMPLATES_FAILURE'
);

export const clearGetInviteMessageTemplates: any = createAction(
  'CLEAR_GET_INVITE_MESSAGE_TEMPLATES'
);
