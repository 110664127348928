import { SetOverviewReportFiltersState } from './type';

export const setOverviewReportFiltersInitialState: SetOverviewReportFiltersState =
  {
    filters: {
      collectors: [],
      associatedFilters: {},
      gender: {
        filterGenderFemale: false,
        filterGenderMale: false,
        filterGenderOther: false,
      },
      age: {
        from18to24: false,
        from25to34: false,
        from35to44: false,
        from45to54: false,
        from55to64: false,
        from65to65plus: false,
      },
      residency: {
        australian: false,
        foreign: false,
        workHolidayMaker: false,
      },
      pay: {
        level1: false,
        level2: false,
        level3: false,
        level4: false,
        level5: false,
      },
      employmentStatus: {
        fullTime: false,
        partTime: false,
        casual: false,
        labourHire: false,
      },
      position: null,
      manager: null,
      department: null,
      location: null,
      breakdown: null,
    },
    selectedFilterCount: 0,
  };
