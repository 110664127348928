import ScheduleIcon from '@mui/icons-material/Schedule';
import { useDispatch, useSelector } from 'react-redux';
import { Form as FormikForm, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';

import {
  clearManageInvite,
  updateManageInviteParameter,
  inviteParticipants,
  updateInviteSchedules,
  clearUpdateInviteSchedules,
  removeManageInviteReminder,
  showNotification,
} from 'store/actions';
import {
  formToState,
  getReminderSchedules,
  isScheduleFormEditable,
  sendScheduleValidationSchema,
} from './helpers';
import {
  saveNewInviteEvent,
  saveEditInviteEvent,
} from '../../../helpers/events';
import { Schedules } from 'store/modules/invites/manageInvite/type';
import ReminderSchedules from './components/reminderSchedules';
import BaseModal from 'element/Modal/components/baseModal';
import InviteSchedule from './components/inviteSchedule';
import { analytics, AnalyticsEventNames } from 'lib';
import { SendScheduleStepProps } from './type';
import { isTimeStampAfter } from 'lib/date';
import translations from './translations';
import { RootState } from 'store/type';
import { t } from 'lib/translation';
import { getRoute } from 'lib/util';
import history from 'lib/history';
import { Message } from 'type';
import './style.scss';

const SendSchedule = ({
  closeModal,
  surveyId,
  collectorId,
}: SendScheduleStepProps) => {
  const dispatch = useDispatch();
  const formRef = useRef<any>(null);

  const parameters = useSelector(
    (state: RootState) => state.emailInvites.manageInvite.parameters
  );
  const manageInvites = useSelector(
    (state: any) => state.emailInvites.manageInvite.data
  );
  const updateScheduleState = useSelector(
    (state: any) => state.emailInvites.updateInviteSchedules
  );
  const collector = useSelector(
    (state: RootState) => state.collector.getCollector
  );

  const isFormEditable = isScheduleFormEditable(parameters, manageInvites);
  const reminderSchedules = getReminderSchedules(manageInvites.reminders);

  const formValidationSchema = sendScheduleValidationSchema(
    parameters.isEdit,
    manageInvites.message,
    manageInvites.reminders
  );

  const newInvite = (scheduleData: Schedules) => {
    dispatch(
      inviteParticipants({
        collectorId: collectorId,
        scheduleData: scheduleData,
        isScheduled: !scheduleData.sendNow,
      })
    );
    dispatch(
      updateManageInviteParameter({
        currentStep: 'submission',
        isScheduled: !scheduleData.sendNow,
      })
    );

    if (collector.collector && manageInvites && scheduleData.scheduledDate) {
      analytics.sendEvent(
        AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_SEND_LATER_SAVE_BUTTON_CLICKED,
        {
          surveyId: collector.collector.survey.id,
          surveyTitle: collector.collector.survey.title,
          collectorId: collector.collector.id,
          surveyStatus: collector.collector.survey.status,
          collectorType: collector.collector.type,
          collectorTitle: collector.collector.title,
          inviteListName: manageInvites.inviteDetails.recipientGroup,
          numberOfRecipients:
            manageInvites.recipients.recipients.length +
            manageInvites.recipients.uuids.length +
            manageInvites.data.recipients.csvUpload.recipientsCount,
          numberOfAssociatedFilters:
            manageInvites.data.recipients.csvUpload.associatedFiltersCount,
          inputType: manageInvites.recipients.inputType,
          scheduledDate: scheduleData.scheduledDate,
        }
      );
    }
  };

  const updateSchedules = (scheduleData: Schedules) => {
    scheduleData.messageId = manageInvites.message.id;

    dispatch(
      updateInviteSchedules({
        scheduleData: scheduleData,
        collectorId: collectorId,
      })
    );
  };

  const submitSchedules = (values: any) => {
    const scheduleData = formToState(values);

    if (!parameters.isEdit) {
      saveNewInviteEvent(
        AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_SAVE_INVITE_BUTTON_CLICKED,
        collector.collector,
        manageInvites,
        scheduleData
      );

      newInvite(scheduleData);
    } else {
      saveEditInviteEvent(
        AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_SAVE_INVITE_BUTTON_CLICKED,
        collector.collector,
        manageInvites,
        scheduleData
      );

      updateSchedules(scheduleData);
    }
  };

  const clearNewRemindersAndCloseModel = () => {
    if (parameters.isEdit) {
      const newReminders = filter(manageInvites.reminders, ['status', null]);

      map(newReminders, (reminder: Message) => {
        if (reminder.id) {
          dispatch(removeManageInviteReminder(reminder.id));
        }
      });
    }

    closeModal();
  };

  useEffect(() => {
    if (updateScheduleState.isUpdated && updateScheduleState.isValid) {
      dispatch(clearManageInvite());
      dispatch(clearUpdateInviteSchedules());
      dispatch(showNotification(t(translations.updateSuccess)));

      history.push(
        getRoute('/surveys/:surveyId/edit/:collectorId/email-invites', {
          params: {
            surveyId: surveyId,
            collectorId: collectorId,
          },
        })
      );
    }
  }, [updateScheduleState, surveyId, collectorId, dispatch]);

  return (
    <BaseModal
      id={'invite-schedules'}
      closeModal={clearNewRemindersAndCloseModel}
      title={
        <>
          <ScheduleIcon className={'header-icon'} />
          {t(translations.title)}
        </>
      }
      primaryButtonClick={() => {
        formRef.current.validateForm();
        formRef.current.handleSubmit();
      }}
      hasPrimaryButton={isFormEditable}
      primaryButtonText={t(translations.primaryButton)}
      primaryButtonIsLoading={updateScheduleState.isUpdating}
      hasSecondaryButton={false}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          sendNow: false,
          inviteDate: isTimeStampAfter(manageInvites.message.scheduledDate, 120)
            ? manageInvites.message.scheduledDate
            : null,
          ...reminderSchedules,
        }}
        validationSchema={formValidationSchema}
        onSubmit={submitSchedules}
      >
        {() => (
          <FormikForm>
            <InviteSchedule />

            <ReminderSchedules collectorId={collectorId} />
          </FormikForm>
        )}
      </Formik>
    </BaseModal>
  );
};

export default SendSchedule;
