import React from 'react';
function MenuIcon(_a) {
    var Icon = _a.icon;
    if (typeof Icon === 'string') {
        return (React.createElement("span", { className: "elmo-icon" },
            React.createElement("svg", { className: "elmo-icon__svg" },
                React.createElement("use", { xlinkHref: '#' + Icon }))));
    }
    return React.createElement(Icon, null);
}
MenuIcon.defaultProps = {
    icon: '',
};
export default MenuIcon;
