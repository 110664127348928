import { getRoute, jsonToFormData } from 'lib/util';
import { UpdateEmailTemplatePayload } from './type';
import { putRequest } from 'lib/api';

const defaultEmailTemplateRoute = '/email-templates/:emailTemplateId';

export const updateEmailTemplateApi = (data: UpdateEmailTemplatePayload) => {
  const route = getRoute(defaultEmailTemplateRoute, {
    params: { emailTemplateId: data.id },
  });

  return putRequest(route, jsonToFormData(data.data), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};
