import React from 'react';
import { getClass } from '../../_lib/helper';
import './DataTableTd.scss';
/**
 * The table cell component.
 * @param children
 * @param className
 * @param colSpan
 * @constructor
 */
function DataTableTd(_a) {
    var children = _a.children, className = _a.className, colSpan = _a.colSpan, textAlign = _a.textAlign;
    return (React.createElement("td", { className: getClass('elmo-datatable__td', className), colSpan: colSpan, style: { textAlign: textAlign } }, children));
}
export default DataTableTd;
