var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component, createRef, forwardRef } from 'react';
import { getClass } from '../_lib/helper';
import './Input.scss';
var Input = /** @class */ (function (_super) {
    __extends(Input, _super);
    function Input() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.internalInputRef = createRef();
        _this.handleOnChange = function (e) {
            var _a = _this.props, isDisabled = _a.isDisabled, onChange = _a.onChange, onEnterPress = _a.onEnterPress;
            if (e === undefined) {
                return;
            }
            if (isDisabled) {
                e.stopPropagation();
                return;
            }
            if (e.keyCode === 13 && onEnterPress) {
                onEnterPress(e);
            }
            if (onChange) {
                onChange(e);
            }
        };
        _this.handleClick = function (e) {
            // TODO: Add isDisabled check
            var onClick = _this.props.onClick;
            if (onClick) {
                onClick(e);
            }
        };
        _this.handleOnKeyPress = function (e) {
            var _a = _this.props, isDisabled = _a.isDisabled, onEnterPress = _a.onEnterPress, onKeyPress = _a.onKeyPress;
            if (e === undefined) {
                return;
            }
            if (isDisabled) {
                e.stopPropagation();
                return;
            }
            if (e.charCode === 13 && onEnterPress) {
                onEnterPress(e);
            }
            if (onKeyPress) {
                onKeyPress(e);
            }
        };
        _this.handleMouseDown = function (e) {
            var onMouseDown = _this.props.onMouseDown;
            if (onMouseDown) {
                onMouseDown(e);
            }
        };
        _this.handleOnBlur = function (e) {
            var onBlur = _this.props.onBlur;
            if (onBlur) {
                onBlur(e);
            }
        };
        _this.handleOnFocus = function (e) {
            var onFocus = _this.props.onFocus;
            if (onFocus) {
                onFocus(e);
            }
        };
        _this.renderInput = function () {
            var _a;
            var _b = _this.props, isDisabled = _b.isDisabled, isReadOnly = _b.isReadOnly, isHidden = _b.isHidden, value = _b.value, status = _b.status, id = _b.id, name = _b.name, htmlType = _b.htmlType, placeholder = _b.placeholder, tabIndex = _b.tabIndex, ariaLabel = _b.ariaLabel, ariaDescribedby = _b.ariaDescribedby, ariaInvalid = _b.ariaInvalid, ariaErrorMessageId = _b.ariaErrorMessageId, onKeyDown = _b.onKeyDown, autoComplete = _b.autoComplete;
            var className = getClass('elmo-input', (_a = {},
                _a["elmo-input--on-" + status] = status,
                _a));
            var ariaInvalidStatus = ariaInvalid || status === 'error' || status === 'warning';
            return (React.createElement("input", { id: id, "data-testid": "elmo-input-" + (id || 'default'), className: className, onClick: _this.handleClick, onChange: _this.handleOnChange, onKeyPress: _this.handleOnKeyPress, onBlur: _this.handleOnBlur, onFocus: _this.handleOnFocus, onKeyDown: onKeyDown, disabled: isDisabled, readOnly: isReadOnly, hidden: isHidden, value: value, type: htmlType, name: name, placeholder: placeholder, onMouseDown: _this.handleMouseDown, ref: _this.inputRef, tabIndex: tabIndex, "aria-label": ariaLabel, "aria-describedby": ariaDescribedby, "aria-invalid": ariaInvalidStatus, "aria-errormessage": ariaInvalidStatus && ariaErrorMessageId
                    ? ariaErrorMessageId
                    : undefined, autoComplete: autoComplete }));
        };
        return _this;
    }
    Input.prototype.componentDidUpdate = function () {
        if (this.props.autoFocus && this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };
    Object.defineProperty(Input.prototype, "inputRef", {
        get: function () {
            var _a = this, forwardedRef = _a.props.forwardedRef, internalInputRef = _a.internalInputRef;
            if (forwardedRef &&
                typeof forwardedRef.current !==
                    'undefined') {
                return forwardedRef;
            }
            return internalInputRef;
        },
        enumerable: false,
        configurable: true
    });
    Input.prototype.render = function () {
        var _a, _b, _c;
        var _d = this.props, inputAdornmentStart = _d.inputAdornmentStart, inputAdornmentEnd = _d.inputAdornmentEnd, status = _d.status, isDisabled = _d.isDisabled, isReadOnly = _d.isReadOnly;
        var className = getClass('elmo-input__wrapper', (_a = {},
            _a["elmo-input__wrapper--on-" + status] = status,
            _a["elmo-input__wrapper--is-disabled"] = isDisabled,
            _a["elmo-input__wrapper--is-read-only"] = isReadOnly,
            _a));
        var adornmentStartClassName = getClass('elmo-input__adornment', (_b = {},
            _b["elmo-input__adornment--start"] = inputAdornmentStart,
            _b));
        var adornmentEndClassName = getClass('elmo-input__adornment', (_c = {},
            _c["elmo-input__adornment--end"] = inputAdornmentEnd,
            _c));
        return inputAdornmentStart || inputAdornmentEnd ? (React.createElement("div", { className: className },
            inputAdornmentStart && (React.createElement("div", { className: adornmentStartClassName }, inputAdornmentStart)),
            this.renderInput(),
            inputAdornmentEnd && (React.createElement("div", { className: adornmentEndClassName }, inputAdornmentEnd)))) : (this.renderInput());
    };
    Input.defaultProps = {
        isReadOnly: false,
        isDisabled: false,
        isHidden: false,
        htmlType: 'text',
        tabIndex: 0,
        autoComplete: 'on', // html input autoComplete default is on
    };
    return Input;
}(Component));
export { Input };
export default forwardRef(function (props, ref) { return (React.createElement(Input, __assign({ forwardedRef: ref }, props))); });
