import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const deleteSurveyTemplate: ActionCreator<{
  id: number;
  isGlobal: boolean;
}> = createAction('DELETE_SURVEY_TEMPLATE');

export const deleteSurveyTemplateSuccess: EmptyActionCreator = createAction(
  'DELETE_SURVEY_TEMPLATE_SUCCESS'
);

export const deleteSurveyTemplateFailure: ActionCreator<ErrorResponse> =
  createAction('DELETE_SURVEY_TEMPLATE_FAILURE');

export const clearDeleteSurveyTemplate: EmptyActionCreator = createAction(
  'CLEAR_DELETE_SURVEY_TEMPLATE'
);
