import { SelectPropsOption } from 'elmo-elements/Select/type';

import { Filter } from '../type';

export const getFilterOptions = (filters: Filter[]): SelectPropsOption[] => {
  let options: SelectPropsOption[] = [];

  for (const filter of filters) {
    options.push({ value: filter.id.toString(), label: filter.name });
  }

  return options;
};
