var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import LayoutContent from './component/LayoutContent';
import LayoutHeader from './component/LayoutHeader';
import LayoutMenu from './component/LayoutMenu';
import LayoutContext from './component/LayoutContext';
import LayoutSidePanel from './component/LayoutSidePanel';
import { getClass, getScrollY, scrollLock, setScrollY, classOSinHTML, } from '../_lib/helper';
import { LayoutThemes, } from './type';
import './Layout.scss';
var Layout = /** @class */ (function (_super) {
    __extends(Layout, _super);
    function Layout(props) {
        var _this = _super.call(this, props) || this;
        _this.bodyScrollTop = 0;
        _this.setMenuStatus = function (menuState) {
            _this.setState({ menuState: menuState });
        };
        _this.toggleSidePanelOpen = function () {
            _this.setState(function (_a) {
                var isSidePanelOpen = _a.isSidePanelOpen;
                return ({
                    isSidePanelOpen: !isSidePanelOpen,
                });
            }, function () {
                if (_this.state.isSidePanelOpen) {
                    _this.bodyScrollTop = getScrollY();
                    scrollLock(true);
                }
                else {
                    scrollLock(false);
                    setScrollY(_this.bodyScrollTop);
                }
            });
        };
        _this.toggleSidePanelOverlay = function () {
            _this.setState(function (_a) {
                var isSidePanelOverlay = _a.isSidePanelOverlay;
                return ({
                    isSidePanelOverlay: !isSidePanelOverlay,
                });
            });
        };
        _this.toggleBulkActionOpen = function () {
            _this.setState(function (_a) {
                var isBulkActionOpen = _a.isBulkActionOpen;
                return ({
                    isBulkActionOpen: !isBulkActionOpen,
                });
            });
        };
        _this.toggleBulkActionDisabled = function () {
            _this.setState(function (_a) {
                var isBulkActionDisabled = _a.isBulkActionDisabled;
                return ({
                    isBulkActionDisabled: !isBulkActionDisabled,
                });
            });
        };
        _this.toggleSearchOpen = function () {
            _this.setState(function (_a) {
                var isSearchOpen = _a.isSearchOpen;
                return ({
                    isSearchOpen: !isSearchOpen,
                });
            });
        };
        _this.toggleModalOpen = function () {
            _this.setState(function (_a) {
                var isModalOpen = _a.isModalOpen;
                return ({
                    isModalOpen: !isModalOpen,
                });
            }, function () {
                if (_this.state.isModalOpen) {
                    _this.bodyScrollTop = getScrollY();
                    scrollLock(true);
                }
                else {
                    scrollLock(false);
                    setScrollY(_this.bodyScrollTop);
                }
            });
        };
        _this.setHeaderRef = function (ref) {
            _this.setState({ headerRef: ref }, function () {
                var onUpdateHeaderRef = new CustomEvent('onUpdateHeaderRef', {
                    detail: {
                        target: ref,
                    },
                });
                window.dispatchEvent(onUpdateHeaderRef);
            });
        };
        _this.setLayoutRef = function (ref) {
            _this.setState({ layoutRef: ref }, function () {
                var onUpdateLayoutRef = new CustomEvent('onUpdateLayoutRef', {
                    detail: {
                        target: ref,
                    },
                });
                window.dispatchEvent(onUpdateLayoutRef);
            });
        };
        // For the LayoutContext we define the value as state to prevent
        // unnecessary rendering. Please refer to Context API for more info.
        _this.state = {
            menuState: 'wide',
            isContentScrolling: false,
            isSidePanelOpen: false,
            isSidePanelOverlay: false,
            isBulkActionOpen: false,
            isBulkActionDisabled: true,
            isSearchOpen: false,
            isModalOpen: false,
            setMenuStatus: _this.setMenuStatus,
            toggleSidePanelOpen: _this.toggleSidePanelOpen,
            toggleSidePanelOverlay: _this.toggleSidePanelOverlay,
            toggleBulkActionOpen: _this.toggleBulkActionOpen,
            toggleBulkActionDisabled: _this.toggleBulkActionDisabled,
            toggleSearchOpen: _this.toggleSearchOpen,
            toggleModalOpen: _this.toggleModalOpen,
            layoutRef: undefined,
            setLayoutRef: _this.setLayoutRef,
            headerRef: undefined,
            setHeaderRef: _this.setHeaderRef,
        };
        _this.handleClassOSinHTML();
        var isStyleReboot = props.isStyleReboot;
        if (isStyleReboot) {
            _this.getRebootStyle();
        }
        return _this;
    }
    /* istanbul ignore next */
    Layout.prototype.getRebootStyle = function () {
        require('../_lib/style/_reboot.scss');
    };
    /* istanbul ignore next */
    Layout.prototype.handleClassOSinHTML = function () {
        classOSinHTML();
    };
    Layout.prototype.render = function () {
        var _a, _b;
        var _c = this.state, menuState = _c.menuState, isSearchOpen = _c.isSearchOpen, isBulkActionOpen = _c.isBulkActionOpen, isSidePanelOpen = _c.isSidePanelOpen, isModalOpen = _c.isModalOpen;
        var _d = this.props, menu = _d.menu, children = _d.children, theme = _d.theme;
        return (React.createElement("div", { className: getClass('elmo-layout', (_a = {},
                _a["menu-status-" + menuState] = true,
                _a['elmo-elements'] = true,
                _a), (_b = {
                    'is-search-open': isSearchOpen,
                    'is-bulkaction-open': isBulkActionOpen,
                    'is-sidepanel-open': isSidePanelOpen,
                    'is-modal-open': isModalOpen
                },
                _b["theme-" + theme] = theme,
                _b)) },
            React.createElement(LayoutContext.Provider, { value: this.state },
                menu && React.createElement(LayoutMenu, null, menu),
                React.createElement("div", { className: "elmo-layout__main-app" }, children))));
    };
    Layout.defaultProps = {
        isStyleReboot: true,
        theme: LayoutThemes.default,
    };
    Layout.Header = LayoutHeader;
    Layout.Content = LayoutContent;
    Layout.SidePanel = LayoutSidePanel;
    return Layout;
}(Component));
export default Layout;
