import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetBulkLinkBatchRequest, GetBulkLinkBatchResponse } from './type';
import { ErrorResponse } from 'type';

export const getBulkLinkBatch: ActionCreator<GetBulkLinkBatchRequest> =
  createAction('GET_BULK_LINK_BATCH');

export const getBulkLinkBatchSuccess: ActionCreator<GetBulkLinkBatchResponse> =
  createAction('GET_BULK_LINK_BATCH_SUCCESS');

export const getBulkLinkBatchFailure: ActionCreator<ErrorResponse> =
  createAction('GET_BULK_LINK_BATCH_FAILURE');

export const clearGetBulkLinkBatch: EmptyActionCreator = createAction(
  'CLEAR_GET_BULK_LINK_BATCH'
);
