var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import SearchClearButton from './component/SearchClearButton';
import SearchCloseButton from './component/SearchCloseButton';
import SearchSubmitButton from './component/SearchSubmitButton';
import { getClass } from '../_lib/helper';
import Input from '../Input';
import Row from '../Grid/Row';
import Col from '../Grid/Col';
import './Search.scss';
var Search = /** @class */ (function (_super) {
    __extends(Search, _super);
    function Search() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleOnClose = function () {
            var onClose = _this.props.onClose;
            if (onClose) {
                onClose();
            }
        };
        _this.handleOnSubmit = function () {
            var onSubmit = _this.props.onSubmit;
            if (onSubmit) {
                onSubmit();
            }
        };
        _this.handleOnChange = function (e) {
            _this.setValue(e.target.value);
        };
        _this.handleOnClear = function () {
            _this.setValue('');
        };
        return _this;
    }
    Search.prototype.setValue = function (value) {
        var onChange = this.props.onChange;
        if (onChange) {
            onChange(value);
        }
    };
    Object.defineProperty(Search.prototype, "value", {
        get: function () {
            return this.props.value || '';
        },
        enumerable: false,
        configurable: true
    });
    Search.prototype.render = function () {
        var _a = this.props, onKeyPress = _a.onKeyPress, id = _a.id, onFocus = _a.onFocus, onBlur = _a.onBlur, isVisible = _a.isVisible, onClose = _a.onClose, placeholder = _a.placeholder, ariaLabel = _a.ariaLabel, isSubmitEnabledAlways = _a.isSubmitEnabledAlways;
        var value = this.value;
        return (React.createElement("div", { id: id, className: getClass('elmo-search', {}, { open: isVisible }), "data-testid": "elmo-search-" + (id || 'default') },
            React.createElement("div", { className: "elmo-search__wrapper" },
                React.createElement(Row, { isNoGutters: true, className: "flex-nowrap", role: "presentation" },
                    React.createElement(Col, { isFill: true, role: "presentation" },
                        React.createElement("div", { className: "elmo-search__input", role: "search" },
                            React.createElement(Input, { value: value, onChange: this.handleOnChange, onEnterPress: this.handleOnSubmit, onKeyPress: onKeyPress, onFocus: onFocus, onBlur: onBlur, placeholder: placeholder, autoFocus: isVisible, ariaLabel: ariaLabel, htmlType: "search", name: "search" }),
                            React.createElement(SearchClearButton, { value: value, onClear: this.handleOnClear }),
                            React.createElement(SearchSubmitButton, { value: value, onSubmit: this.handleOnSubmit, isSubmitEnabledAlways: isSubmitEnabledAlways }))),
                    onClose && (React.createElement(Col, { isAuto: true, role: "presentation" },
                        React.createElement(SearchCloseButton, { onClick: this.handleOnClose })))))));
    };
    Search.displayName = 'Search';
    Search.defaultProps = {
        placeholder: 'Search',
        ariaLabel: 'Search field',
    };
    return Search;
}(Component));
export default Search;
