import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/collectors/:collectorId/invite-message-templates';

export const createInviteMessageTemplateApi = (
  collectorId: number,
  payload: any
) => {
  return postRequest(
    getRoute(url, {
      params: { collectorId: collectorId },
    }),
    payload
  );
};
