import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useEffect } from 'react';

import { ErrorBoundary } from './sharedComponents/Suspense/components';
import ThemeProvider from './sharedComponents/ThemeProvider';
import { isAuthenticated } from './lib/authentication/check';
import AppLayout, { AppLoading } from './layout';
import featureFlag from 'lib/featureFlag/index';
import { initializeApp } from './store/actions';
import { RootState } from './store/type';
import { history } from './lib/history';
import * as Pendo from 'lib/pendo';
import './styles.scss';

const App = (): ReactElement => {
  const dispatch = useDispatch();

  const initializeAppState = useSelector(
    (state: RootState) => state.app.initializeApp
  );

  useEffect(() => {
    if (!initializeAppState.isLoaded) {
      isAuthenticated().then(() => {
        if (featureFlag.isEnabled('maintenance-mode')) {
          history.push('/scheduled-maintenance');
        }

        dispatch(initializeApp());
        Pendo.init();
      });
    }
  }, [initializeAppState.isLoaded]);

  return (
    <AppLoading
      isLoaded={initializeAppState.isLoaded}
      error={initializeAppState.error}
    >
      <ThemeProvider>
        <HistoryRouter history={history}>
          <ErrorBoundary>
            <AppLayout />
          </ErrorBoundary>
        </HistoryRouter>
      </ThemeProvider>
    </AppLoading>
  );
};

export default App;
