import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getUsersFailure, getUsersSuccess } from './actions';
import { getUsersApi } from './api';

export const getUsersSaga = function* (): SagaIterator {
  try {
    const data = yield call(getUsersApi());

    yield put(getUsersSuccess(data));
  } catch (error) {
    yield put(getUsersFailure(error));
  }
};
