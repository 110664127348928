import React, { Children } from 'react';
import { isElementOfType } from '../../_lib/helper';
import ListTableTr from './ListTableTr';
function ListTableBody(_a) {
    var children = _a.children;
    return (React.createElement(React.Fragment, null, Children.map(children, function (child, rowIndex) {
        if (!React.isValidElement(child) ||
            !isElementOfType(child, ListTableTr)) {
            return null;
        }
        return React.cloneElement(child, {
            rowIndex: rowIndex,
        });
    })));
}
ListTableBody.displayName = 'ListTableBody';
export default ListTableBody;
