var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import classNames from 'classnames';
import { CloudUploadOutlinedIcon as CloudUploadIcon } from '../../Icon';
export var DropArea = function (_a) {
    var isDragActive = _a.isDragActive, getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, dropareaText = _a.dropareaText, draggingText = _a.draggingText;
    var rootProps = getRootProps ? getRootProps() : null;
    return (React.createElement("div", __assign({}, rootProps, { className: classNames('elmo-fileupload-droparea', {
            'dropzone--isActive': isDragActive,
        }) }),
        getInputProps && React.createElement("input", __assign({}, getInputProps())),
        React.createElement(CloudUploadIcon, { className: "cloudUploadIcon" }),
        isDragActive ? React.createElement(React.Fragment, null, draggingText) : React.createElement(React.Fragment, null, dropareaText)));
};
