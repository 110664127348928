import { createReducer } from 'redux-act';

import { getBenchmarkReportReducers } from './getBenchmarkReport/reducers';
import { benchmarkInitialState } from './state';

export default createReducer(
  {
    ...getBenchmarkReportReducers,
  },
  benchmarkInitialState
);
