import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getQuestionFailure, getQuestionSuccess } from './actions';
import { GetQuestionPayload } from './type';
import { getQuestionApi } from './api';
import { SagaAction } from 'type';

export const getQuestionSaga = function* (
  action: SagaAction<GetQuestionPayload>
): SagaIterator {
  try {
    const data = yield call(getQuestionApi(action.payload));

    yield put(getQuestionSuccess(data));
  } catch (error) {
    yield put(getQuestionFailure(error as any));
  }
};
