import React from 'react';
import { BulkActionIcon as SelectAllIcon } from '../../Icon';
import Button from '../../Button';
import './DataTableBulkActionsButton.scss';
/**
 * Wraps the Bulk Actions button
 * @param onBulkActionsClick
 * @constructor
 */
function DataTableBulkActionsButton(_a) {
    var toggleBulkActionOpen = _a.toggleBulkActionOpen;
    return (React.createElement(Button, { className: "elmo-datatable__bulkactions-btn", onClick: toggleBulkActionOpen, isText: true, icon: React.createElement(SelectAllIcon, null), ariaLabel: "Select all" }));
}
export default DataTableBulkActionsButton;
