import { Item } from 'lib/stateTemplates';
import { GetQuestionState } from './type';
import { Question } from 'type';

export const getQuestionInitialState: GetQuestionState = {
  ...Item,
  error: null,
  questionId: 0,
  data: {} as Question,
};
