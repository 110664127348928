import AuthAccess from '../access';

class FeatureFlagsService {
  private featureFlags: any = {};
  private hasLoaded: boolean = false;

  constructor() {
    this.loadFlags();
  }

  /**
   * Load feature flags for current user
   */
  loadFlags(): void {
    if (AuthAccess.currentUser && AuthAccess.currentUser.feature_flags) {
      this.featureFlags = AuthAccess.currentUser.feature_flags;
      this.hasLoaded = true;
    }
  }

  /**
   * Check if passed in feature flag exists and is enabled or not
   *
   * @param featureFlag
   */
  isEnabled(featureFlag: string): boolean {
    if (!this.hasLoaded) {
      this.loadFlags();
    }

    let flag = this.featureFlags[featureFlag];
    if (flag === undefined || flag === null) {
      return false;
    } else {
      return flag;
    }
  }
}

export default new FeatureFlagsService();
