import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getInviteFailure, getInviteSuccess } from './actions';
import { getInviteApi } from './api';

export const getInviteSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(getInviteApi(action.payload));

    yield put(getInviteSuccess(data));
  } catch (error) {
    yield put(getInviteFailure(error));
  }
};
