import produce from 'immer';

import {
  clearSendInviteMessage,
  sendInviteMessage,
  sendInviteMessageFailure,
  sendInviteMessageSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function sendInviteMessageReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.sendInviteMessage.isLoaded = false;
    draft.sendInviteMessage.isLoading = true;
    draft.sendInviteMessage.isValid = false;
  });
}

export function sendInviteMessageSuccessReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.sendInviteMessage.isLoaded = true;
    draft.sendInviteMessage.isLoading = false;
    draft.sendInviteMessage.isValid = true;
  });
}

export function sendInviteMessageFailureReducer(
  state: InvitesState,
  error: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.sendInviteMessage.isLoaded = true;
    draft.sendInviteMessage.isLoading = false;
    draft.sendInviteMessage.error = error;
  });
}

export function clearSendInviteMessageReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.sendInviteMessage = invitesInitialState.sendInviteMessage;
  });
}

export const sendInviteMessageReducers = {
  [`${sendInviteMessage}`]: sendInviteMessageReducer,
  [`${sendInviteMessageSuccess}`]: sendInviteMessageSuccessReducer,
  [`${sendInviteMessageFailure}`]: sendInviteMessageFailureReducer,
  [`${clearSendInviteMessage}`]: clearSendInviteMessageReducer,
};
