var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import Button from '../../Button';
export var ButtonUpload = function (_a) {
    var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, open = _a.open;
    var rootProps = getRootProps ? getRootProps() : null;
    return (React.createElement("div", __assign({ className: "elmo-fileupload-input" }, rootProps, { onClick: function (e) {
            e.preventDefault();
        } }),
        getInputProps && React.createElement("input", __assign({}, getInputProps())),
        React.createElement(Button, { type: "primary", onClick: function () {
                open();
            } }, "Upload")));
};
