import produce from 'immer';

import {
  generateBulkUrls,
  generateBulkUrlsFailure,
  generateBulkUrlsSuccess,
  clearGenerateBulkUrls,
} from './actions';
import { generateBulkUrlsInitialState } from './state';
import { BulkUrlState } from '../type';
import { ErrorResponse } from 'type';

export function generateBulkUrlsReducer(state: BulkUrlState): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.generateBulkUrls = { ...generateBulkUrlsInitialState };
    draft.generateBulkUrls.isLoading = true;
  });
}

export function generateBulkUrlsSuccessReducer(
  state: BulkUrlState
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.generateBulkUrls.isLoading = false;
    draft.generateBulkUrls.isLoaded = true;
    draft.generateBulkUrls.isValid = true;
  });
}

export function generateBulkUrlsFailureReducer(
  state: BulkUrlState,
  error: ErrorResponse
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.generateBulkUrls.isLoading = false;
    draft.generateBulkUrls.isLoaded = true;
    draft.generateBulkUrls.error = error;
  });
}

export function clearGenerateBulkUrlsReducer(
  state: BulkUrlState
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.generateBulkUrls = generateBulkUrlsInitialState;
  });
}

export const generateBulkUrlsReducers = {
  [`${generateBulkUrls}`]: generateBulkUrlsReducer,
  [`${generateBulkUrlsSuccess}`]: generateBulkUrlsSuccessReducer,
  [`${generateBulkUrlsFailure}`]: generateBulkUrlsFailureReducer,
  [`${clearGenerateBulkUrls}`]: clearGenerateBulkUrlsReducer,
};
