import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { DeleteQuestionPayload } from './type';
import { ErrorResponse } from 'type';

export const deleteQuestion: ActionCreator<DeleteQuestionPayload> =
  createAction('DELETE_QUESTION');

export const deleteQuestionSuccess: EmptyActionCreator = createAction(
  'DELETE_QUESTION_SUCCESS'
);

export const deleteQuestionFailure: ActionCreator<ErrorResponse> = createAction(
  'DELETE_QUESTION_FAILURE'
);

export const clearDeleteQuestion: EmptyActionCreator = createAction(
  'CLEAR_DELETE_QUESTION'
);
