import produce from 'immer';

import {
  clearGetRespondentProgress,
  getRespondentProgress,
  getRespondentProgressFailure,
  getRespondentProgressSuccess,
} from './actions';
import { getRespondentProgressInitialState } from './state';
import { GetRespondentProgressResponse } from './type';
import { SurveyState } from '../type';

export function getRespondentProgressReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getRespondentProgress = {
      ...getRespondentProgressInitialState,
    };
    draft.getRespondentProgress.isLoading = true;
  });
}

export function getRespondentProgressSuccessReducer(
  state: SurveyState,
  data: GetRespondentProgressResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getRespondentProgress.isLoaded = true;
    draft.getRespondentProgress.isValid = true;
    draft.getRespondentProgress.isLoading = false;
    draft.getRespondentProgress.data = data.data;
  });
}

export function getRespondentProgressFailureReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getRespondentProgress.isLoaded = true;
    draft.getRespondentProgress.isLoading = false;
  });
}

export function clearGetRespondentProgressReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getRespondentProgress = { ...getRespondentProgressInitialState };
  });
}

export const getRespondentProgressReducers = {
  [`${getRespondentProgress}`]: getRespondentProgressReducer,
  [`${getRespondentProgressSuccess}`]: getRespondentProgressSuccessReducer,
  [`${getRespondentProgressFailure}`]: getRespondentProgressFailureReducer,
  [`${clearGetRespondentProgress}`]: clearGetRespondentProgressReducer,
};
