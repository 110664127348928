import { Col, FormItem, Row, Select } from 'elmo-elements';
import { useSelector } from 'react-redux';
import ldUniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import React from 'react';

import { getFilteredUsers } from './components/userFiltering';
import RenderUserList from './components/renderUserList';
import { TmsProfileSelectorProps } from './type';
import translations from './translations';
import { RootState } from 'store/type';
import { t } from 'lib/translation';
import './styles.scss';

const getUniqBy = (data: any, uniqBy: string, mapBy: string) => {
  return map(ldUniqBy(data, uniqBy), mapBy).filter(Boolean);
};

const TmsProfileSelector = ({
  selectedUsers,
  filters,
  updateSelectedUsers,
  updateFilters,
  updateState,
}: TmsProfileSelectorProps) => {
  const companyUsers = useSelector(
    (state: RootState) => state.user.getCompanyUsers
  );

  const departmentList = getUniqBy(
    companyUsers.users,
    'department',
    'department'
  );
  const positionList = getUniqBy(companyUsers.users, 'position', 'position');
  const locationList = getUniqBy(companyUsers.users, 'location', 'location');
  const managerList = getUniqBy(companyUsers.users, 'manager.id', 'manager');

  const getList = (list: any) => {
    return map(list, (item: any) => {
      return {
        label: item,
        value: item,
      };
    });
  };

  const getManagerList = (list: any) => {
    return map(list, (item: any) => {
      return {
        label: item.firstName + ' ' + item.lastName,
        value: item.id,
      };
    });
  };

  const updateFilter = (filterName: string, newValue: any) => {
    const newFilters = {
      ...filters,
      [filterName]: newValue,
    };

    if (updateState) {
      const filterUsers = getFilteredUsers(
        newFilters,
        companyUsers,
        selectedUsers
      );

      updateState({ filters: newFilters, uuids: map(filterUsers, 'id') });
      return;
    }

    updateFilters(newFilters);
  };

  return (
    <>
      <Row justifyContent="between">
        <Col span={14}>
          <FormItem label={t(translations.filters.department)}>
            <Select
              id={'department-select'}
              isMulti={true}
              options={getList(departmentList)}
              value={filters.department}
              onChange={(newValue) => updateFilter('department', newValue)}
              isInline={false}
            />
          </FormItem>

          <FormItem label={t(translations.filters.position)}>
            <Select
              id={'position-select'}
              isMulti={true}
              options={getList(positionList)}
              value={filters.position}
              onChange={(newValue) => updateFilter('position', newValue)}
              isInline={false}
            />
          </FormItem>

          <FormItem label={t(translations.filters.location)}>
            <Select
              id={'location-select'}
              isMulti={true}
              options={getList(locationList)}
              value={filters.location}
              onChange={(newValue) => updateFilter('location', newValue)}
              isInline={false}
            />
          </FormItem>

          <FormItem label={t(translations.filters.manager)}>
            <Select
              id={'manager-select'}
              isMulti={true}
              options={getManagerList(managerList)}
              value={filters.manager}
              onChange={(newValue) => updateFilter('manager', newValue)}
              isInline={false}
            />
          </FormItem>
        </Col>
        <Col span={2} className={`verticalLine`} />
        <Col span={8}>
          <RenderUserList
            filters={filters}
            companyUsers={companyUsers}
            selectedUsers={selectedUsers}
            updateSelectedUsers={updateSelectedUsers}
          />
        </Col>
      </Row>
    </>
  );
};

export default TmsProfileSelector;
