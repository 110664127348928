import produce from 'immer';

import {
  getSurveys,
  getSurveysSuccess,
  getSurveysFailure,
  updateGetSurveysQuery,
  clearGetSurveys,
} from './actions';
import { transformSurveys } from '../../../transformers';
import { DefaultQuery, ErrorResponse } from 'type';
import { getSurveysInitialState } from './state';
import { GetSurveysResponse } from './type';
import { SurveyState } from '../type';

export function getSurveysReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveys.isLoading = true;
    draft.getSurveys.isLoaded = false;
    draft.getSurveys.isValid = false;
    draft.getSurveys.count = 0;
    draft.getSurveys.data = [];
  });
}

export function getSurveysSuccessReducer(
  state: SurveyState,
  data: GetSurveysResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveys.isLoading = false;
    draft.getSurveys.isLoaded = true;
    draft.getSurveys.isValid = true;
    draft.getSurveys.count = data.data.count;
    draft.getSurveys.data = transformSurveys(data.data.surveys);
  });
}

export function getSurveysFailureReducer(
  state: SurveyState,
  error: ErrorResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveys.isLoading = false;
    draft.getSurveys.isLoaded = true;
    draft.getSurveys.error = error;
  });
}

export function updateGetSurveysQueryReducers(
  state: SurveyState,
  query: DefaultQuery
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveys.query = query;
  });
}

export function clearGetSurveysReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveys = getSurveysInitialState;
  });
}

export const getSurveysReducers = {
  [`${getSurveys}`]: getSurveysReducer,
  [`${getSurveysSuccess}`]: getSurveysSuccessReducer,
  [`${getSurveysFailure}`]: getSurveysFailureReducer,
  [`${updateGetSurveysQuery}`]: updateGetSurveysQueryReducers,
  [`${clearGetSurveys}`]: clearGetSurveysReducer,
};
