import { getRequest } from 'lib/api';

const apiUrl = '/survey';

export const getSurveysApi = (params: {
  status: string;
  filter: number;
  featured: boolean;
}): any => {
  return getRequest(apiUrl, { params: params });
};
