import produce from 'immer';

import {
  clearCreateInvite,
  createInvite,
  createInviteFailure,
  createInviteSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function createInviteReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.createInvite.isCreated = false;
    draft.createInvite.isCreating = true;
    draft.createInvite.isValid = false;
    draft.createInvite.data = undefined;
  });
}

export function createInviteSuccessReducer(
  state: InvitesState,
  data: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.createInvite.isCreated = true;
    draft.createInvite.isCreating = false;
    draft.createInvite.isValid = true;
    draft.createInvite.data = data.data;
  });
}

export function createInviteFailureReducer(
  state: InvitesState,
  error: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.createInvite.isCreated = true;
    draft.createInvite.isCreating = false;
    draft.createInvite.error = error;
  });
}

export function clearCreateInviteReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.createInvite = invitesInitialState.createInvite;
  });
}

export const createInviteReducers = {
  [`${createInvite}`]: createInviteReducer,
  [`${createInviteSuccess}`]: createInviteSuccessReducer,
  [`${createInviteFailure}`]: createInviteFailureReducer,
  [`${clearCreateInvite}`]: clearCreateInviteReducer,
};
