var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import { isScreenDesktop, debounce } from '../../_lib/helper';
import { withLayoutAware } from '../../Layout';
var SCROLL_EVENT_NAME = 'onScroll_main-content';
var UPDATE_HEADER_REF_EVENT = 'onUpdateHeaderRef';
var StickyTargetWithoutContext = /** @class */ (function (_super) {
    __extends(StickyTargetWithoutContext, _super);
    function StickyTargetWithoutContext() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.targetRef = createRef();
        _this.doSticky = function (e) {
            var id = _this.props.id;
            if (id &&
                window.ElmoSticky[id] &&
                e.target &&
                e.target.tagName === undefined &&
                _this.mainHeader) {
                var StickyHeaderTarget = window.ElmoSticky[id]['target'];
                var StickyHeaderElement = window.ElmoSticky[id]['element'];
                if (!StickyHeaderElement) {
                    return false;
                }
                var isScreenDesktopValue = isScreenDesktop();
                var scrollTop = 0;
                if (isScreenDesktopValue && e.detail) {
                    // run based on scrollTop of Target
                    scrollTop = e.detail.scrollTop;
                }
                else {
                    // run based on scrollTop of Window
                    scrollTop =
                        window.scrollY || document.getElementsByTagName('html')[0].scrollTop;
                    var top_1 = _this.mainHeader ? _this.mainHeader.offsetHeight : 0;
                    StickyHeaderElement.style.top = top_1 + 'px';
                }
                var heightGaps = scrollTop - StickyHeaderTarget.offsetTop;
                if (heightGaps > 0 &&
                    scrollTop <
                        StickyHeaderTarget.offsetTop + StickyHeaderTarget.offsetHeight - 48) {
                    /*
                    It starts sticking the Element
                    when the scroll passes the Target.offsetTop
                  */
                    StickyHeaderElement.classList.add('elmo-sticky--sticked');
                }
                else {
                    StickyHeaderElement.classList.remove('elmo-sticky--sticked');
                }
            }
            return false;
        };
        return _this;
    }
    StickyTargetWithoutContext.prototype.setMainHeaderRef = function () {
        var headerRef = this.props.headerRef;
        if (headerRef) {
            this.mainHeader = headerRef;
            return true;
        }
        return false;
    };
    StickyTargetWithoutContext.prototype.componentDidMount = function () {
        var _this = this;
        var id = this.props.id;
        if (id) {
            if (window.ElmoSticky === undefined) {
                window.ElmoSticky = {};
            }
            if (window.ElmoSticky[id] === undefined) {
                window.ElmoSticky[id] = {};
            }
            window.ElmoSticky[id]['target'] = this.targetRef.current;
            this.handleScrollListener = debounce(this.doSticky, 100);
            window.addEventListener('scroll', this.handleScrollListener, true);
            window.addEventListener(SCROLL_EVENT_NAME, this.handleScrollListener, true);
        }
        /**
         * the below is the code trying to set mainHeader.
         * if it succeed, it means layout context is already rendered.
         * Otherwise, add an event listener to catch the event of layout rendered.
         */
        if (!this.setMainHeaderRef()) {
            // addEventListener to update mainHeaderRef when the event is dispatched
            this.mainHeaderListener = function () { return _this.setMainHeaderRef(); };
            window.addEventListener(UPDATE_HEADER_REF_EVENT, function () {
                _this.mainHeaderListener();
            }, true);
        }
    };
    StickyTargetWithoutContext.prototype.componentWillUnmount = function () {
        var id = this.props.id;
        window.removeEventListener('scroll', this.handleScrollListener, true);
        window.removeEventListener(SCROLL_EVENT_NAME, this.handleScrollListener, true);
        if (this.mainHeaderListener) {
            window.removeEventListener(UPDATE_HEADER_REF_EVENT, this.mainHeaderListener, true);
        }
        if (id && window.ElmoSticky && window.ElmoSticky[id]) {
            delete window.ElmoSticky[id];
        }
    };
    StickyTargetWithoutContext.prototype.render = function () {
        var _a = this.props, children = _a.children, id = _a.id;
        return id && React.createElement("div", { ref: this.targetRef }, children);
    };
    return StickyTargetWithoutContext;
}(Component));
export { StickyTargetWithoutContext };
var StickyTarget = withLayoutAware(StickyTargetWithoutContext);
export default StickyTarget;
