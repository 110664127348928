import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const updateSurvey: ActionCreator<any> = createAction('UPDATE_SURVEY');

export const updateSurveySuccess: EmptyActionCreator = createAction(
  'UPDATE_SURVEY_SUCCESS'
);

export const updateSurveyFailure: ActionCreator<ErrorResponse> = createAction(
  'UPDATE_SURVEY_FAILURE'
);

export const clearUpdateSurvey: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_SURVEY'
);
