var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import CustomEvent from 'custom-event';
import PropTypes from 'prop-types';
import withLayoutAware from './LayoutAware';
import './LayoutContent.scss';
export var LayoutContentPropTypes = {
    children: PropTypes.node,
};
var LayoutContentWithoutContext = /** @class */ (function (_super) {
    __extends(LayoutContentWithoutContext, _super);
    function LayoutContentWithoutContext() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mainRef = React.createRef();
        _this.handleScroll = function (event) {
            // Dispatch custom event passing scrollTop of Main Content
            /* This custom event can be listened -> window.addEventListener("scroll_main-content",e=>console.log(e)); */
            if (event.target === _this.mainRef.current) {
                // Dispatch event only if ths scroll is triggered on the layout
                var mainScroll = new CustomEvent('onScroll_main-content', {
                    detail: {
                        scrollTop: event.target.scrollTop,
                        target: event.target,
                    },
                });
                window.dispatchEvent(mainScroll);
            }
        };
        _this.handleResize = function () {
            // Trigger scroll event manually when window gets resized
            if (_this.mainRef && _this.mainRef.current) {
                var event = new Event('scroll');
                _this.mainRef.current.dispatchEvent(event);
            }
        };
        return _this;
    }
    LayoutContentWithoutContext.prototype.componentDidMount = function () {
        var setLayoutRef = this.props.setLayoutRef;
        if (this.mainRef && this.mainRef.current && setLayoutRef) {
            setLayoutRef(this.mainRef.current);
            this.mainRef.current.addEventListener('scroll', this.handleScroll, true);
        }
        window.addEventListener('resize', this.handleResize, true);
    };
    LayoutContentWithoutContext.prototype.componentWillUnmount = function () {
        if (this.mainRef && this.mainRef.current) {
            this.mainRef.current.removeEventListener('scroll', this.handleScroll, true);
        }
        window.removeEventListener('resize', this.handleResize, true);
    };
    LayoutContentWithoutContext.prototype.render = function () {
        var children = this.props.children;
        return (React.createElement("div", { className: "elmo-layout__main-content-wrapper", ref: this.mainRef, role: "main" },
            React.createElement("div", { className: "elmo-layout__main-content" }, children)));
    };
    return LayoutContentWithoutContext;
}(Component));
export { LayoutContentWithoutContext };
export default withLayoutAware(LayoutContentWithoutContext);
