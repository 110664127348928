import produce from 'immer';

import { Query as QueryType } from 'lib/query/type';
import { GetCollectorsState } from './type';
import { Query } from 'lib/stateTemplates';

export const getCollectorsInitialState: GetCollectorsState = {
  count: 0,
  data: [],
  isLoaded: false,
  isLoading: false,
  isValid: false,
  query: produce(Query, (draft: QueryType) => {
    draft.sort = '-createdDate';
  }),
};
