import { postRequest } from 'lib/api';

const apiUrl = '/template';
const globalApiUrl = '/admin/template';

export const createSurveyTemplateApi = (isGlobal: boolean, payload: any) => {
  const url = isGlobal ? globalApiUrl : apiUrl;

  return postRequest(url, payload);
};
