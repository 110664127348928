import {
  QuestionSettingsBulkGroup,
  QuestionSettingsType,
  SavedQuestionSettings,
} from 'type';
import { getChartType, getDisplayValue } from 'lib';

export const transformQuestionSetting = (
  settings: SavedQuestionSettings
): QuestionSettingsType => {
  return {
    answerLabel: settings.axisLabelAnswers,
    chartType: getChartType(settings.chartType),
    displayValue: getDisplayValue(settings.responsesValue, settings.type),
    responseLabel: settings.axisLabelResponses,
    showTable: settings.showDataTable,
    title: settings.title,
  };
};

export const transformQuestionSettings = (
  settingsGroup: SavedQuestionSettings[]
): QuestionSettingsBulkGroup => {
  const questionSettings: QuestionSettingsBulkGroup = {};

  for (const settings of settingsGroup) {
    questionSettings[settings.name] = transformQuestionSetting(settings);
  }

  return questionSettings;
};
