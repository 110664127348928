import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getDefaultEmailTemplateFailure,
  getDefaultEmailTemplateSuccess,
} from './actions';
import { getDefaultEmailTemplateApi } from './api';

export const getDefaultEmailTemplateSaga = function* (): SagaIterator {
  try {
    const data = yield call(getDefaultEmailTemplateApi());

    yield put(getDefaultEmailTemplateSuccess(data));
  } catch (error) {
    yield put(getDefaultEmailTemplateFailure());
  }
};
