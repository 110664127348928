const translations = {
  reminderButton: {
    key: '',
    default: 'Add Reminder',
  },
  submitSchedule: {
    key: '',
    default: 'Send/Schedule',
  },
  rescheduleInvite: {
    key: '',
    default: 'Reschedule Invite',
  },
  rescheduleReminder: {
    key: '',
    default: 'Reschedule Reminder(s)',
  },
  submitButton: {
    key: '',
    default: 'Send Now',
  },
  divider: {
    key: '',
    default: 'OR',
  },
  scheduleButton: {
    key: '',
    default: 'Send Later',
  },
  sectionTitle: {
    key: '',
    default: 'Schedule/Send',
  },
  sentDate: {
    key: '',
    default: 'Sent Time',
  },
  scheduledDate: {
    key: '',
    default: 'Scheduled Time',
  },
  scheduledReminder: {
    key: '',
    default: 'Reminder {{index}} Scheduled',
  },
  sentReminder: {
    key: '',
    default: 'Reminder {{index}} Sent',
  },
};

export default translations;
