import produce from 'immer';

import {
  clearGetTheme,
  getTheme,
  getThemeFailure,
  getThemeSuccess,
} from './actions';
import { transformTheme } from 'store/transformers';
import { getThemeInitialState } from './state';
import { GetThemeResponse } from './type';
import { ThemeState } from '../type';

export function getThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getTheme.isLoading = true;
  });
}

export function getThemeSuccessReducer(
  state: ThemeState,
  resp: GetThemeResponse
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getTheme.isLoaded = true;
    draft.getTheme.isValid = true;
    draft.getTheme.data = transformTheme(resp.data);
  });
}

export function getThemeFailureReducer(
  state: ThemeState,
  err: any
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getTheme.error = err;
  });
}

export function clearGetThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getTheme = {
      ...getThemeInitialState,
    };
  });
}

export const getThemeReducers = {
  [`${getTheme}`]: getThemeReducer,
  [`${getThemeSuccess}`]: getThemeSuccessReducer,
  [`${getThemeFailure}`]: getThemeFailureReducer,
  [`${clearGetTheme}`]: clearGetThemeReducer,
};
