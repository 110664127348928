import React from 'react';
import { getClass } from '../_lib/helper';
import './Image.scss';
import { StyledImage } from './Styles';
function getSrcSet(src, srcSet) {
    return srcSet;
}
function getSizes(sizes) {
    return sizes;
}
function Image(_a) {
    var _b;
    var src = _a.src, id = _a.id, alt = _a.alt, className = _a.className, width = _a.width, height = _a.height, srcSet = _a.srcSet, sizes = _a.sizes, objectFit = _a.objectFit, type = _a.type, role = _a.role;
    var roleName = role ? role : 'img';
    return (React.createElement(StyledImage, { id: id, alt: alt, src: src, width: width, height: height, sizes: getSizes(sizes), srcSet: getSrcSet(src, srcSet), "data-testid": "elmo-image-" + (id || 'default'), objectFit: objectFit, role: roleName, className: getClass('elmo-image', className, (_b = {}, _b["" + type] = type, _b)) }));
}
export default Image;
