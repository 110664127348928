import {
  PlaceholderDashboard,
  PlaceholderHeader,
  Layout,
  Row,
  Col,
} from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const OverviewComponent = lazy(() =>
  withDelay(() => import('./OverviewComponent'))
);

const Overview = (): ReactElement => {
  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={true}
              hasBackButton={true}
              hasButtons={false}
              numberOfTabs={5}
            />
          </Layout.Header>
          <Layout.Content>
            <Row className="survey-overview">
              <Col sm={8}>
                <PlaceholderDashboard cols={1} rows={1} />
                <PlaceholderDashboard cols={1} rows={1} />
                <PlaceholderDashboard cols={1} rows={1} />
              </Col>
              <Col sm={16}>
                <Row>
                  <Col sm={6}>
                    <PlaceholderDashboard cols={1} rows={1} />
                  </Col>
                  <Col sm={6}>
                    <PlaceholderDashboard cols={1} rows={1} />
                  </Col>
                  <Col sm={6}>
                    <PlaceholderDashboard cols={1} rows={1} />
                  </Col>
                  <Col sm={6}>
                    <PlaceholderDashboard cols={1} rows={1} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24}>
                    <PlaceholderDashboard cols={1} rows={1} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Layout.Content>
        </>
      }
    >
      <OverviewComponent />
    </Suspense>
  );
};

export default Overview;
