import isEmpty from 'lodash/isEmpty';

import { ManageInvitesData } from 'store/modules/invites/manageInvite/type';
import { Cancelled } from 'constants/messageStatus';
import translations from '../translations';
import { AnalyticsEventNames } from 'lib';
import { t } from 'lib/translation';
import { Message } from 'type';

export const hasCancelledReminder = (invite: ManageInvitesData): boolean => {
  return invite.reminders.some(
    (reminder: Message) => reminder.status === Cancelled
  );
};

export const getPrimaryButtonText = (invite: ManageInvitesData): string => {
  if (invite.message.status === Cancelled) {
    return t(translations.rescheduleInvite);
  }

  const rescheduleReminder = hasCancelledReminder(invite);

  return rescheduleReminder
    ? t(translations.rescheduleReminder)
    : t(translations.submitSchedule);
};

export const isInviteCompleted = (invite: ManageInvitesData): boolean => {
  if (
    isEmpty(invite.message.sentDate) &&
    isEmpty(invite.message.scheduledDate)
  ) {
    return false;
  }

  return invite.message.status !== Cancelled;
};

export const getReminderEventName = (invite: ManageInvitesData): string => {
  if (!isInviteCompleted(invite)) {
    return AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_ADD_REMINDER_BUTTON_CLICKED;
  }

  const rescheduleReminder = hasCancelledReminder(invite);

  return rescheduleReminder
    ? AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_RESCHEDULE_REMINDER
    : AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_ADD_REMINDER_AFTER_SAVE;
};

export const getScheduleEventName = (invite: ManageInvitesData): string => {
  if (!isInviteCompleted(invite)) {
    return AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_SEND_SCHEDULE_BUTTON_CLICKED;
  }

  return AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_RESCHEDULE_BUTTON_CLICKED;
};
