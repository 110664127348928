import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { setDefaultThemeFailure, setDefaultThemeSuccess } from './actions';
import { setDefaultThemeApi } from './api';
import { SagaAction } from 'type';

export const setDefaultThemeSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    yield call(setDefaultThemeApi(action.payload));

    yield put(setDefaultThemeSuccess(action.payload));
  } catch (error) {
    yield put(setDefaultThemeFailure());
  }
};
