var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass } from '../../../_lib/helper';
import '../../style.scss';
import { Text } from '../../../Typography';
var StarRatingComponent = /** @class */ (function (_super) {
    __extends(StarRatingComponent, _super);
    function StarRatingComponent(props) {
        var _this = _super.call(this, props) || this;
        // It's a flag means that user use keyboard and the half star is focused or full star
        _this.isHalfStarFocused = false;
        _this.renderLabel = function () {
            var _a = _this.props, count = _a.count, value = _a.value;
            return (React.createElement("div", { className: "elmo-rating__label" },
                React.createElement("span", null, value),
                React.createElement("span", { className: "elmo-rating__label-count" },
                    "/",
                    count)));
        };
        _this.state = {
            value: props.value,
        };
        return _this;
    }
    StarRatingComponent.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var value = nextProps.value;
        if (value != null && value !== this.state.value) {
            this.setState({ value: value });
        }
    };
    StarRatingComponent.prototype.onChange = function (inputValue) {
        var _a = this.props, isDisabled = _a.isDisabled, value = _a.value;
        if (isDisabled) {
            return;
        }
        // do not update internal state based on input value if prop passed
        if (value != null) {
            return;
        }
        this.setState({ value: inputValue });
    };
    StarRatingComponent.prototype.onClick = function (index, value, name, event) {
        event.stopPropagation();
        var _a = this.props, onClick = _a.onClick, isDisabled = _a.isDisabled;
        if (isDisabled) {
            return;
        }
        if (onClick) {
            onClick(index, value, name, event, this.isHalfStarFocused);
        }
    };
    StarRatingComponent.prototype.onHover = function (index, value, name, event) {
        event.stopPropagation();
        var _a = this.props, onHover = _a.onHover, isDisabled = _a.isDisabled;
        if (isDisabled) {
            return;
        }
        if (onHover) {
            onHover(index, value, name, event);
        }
    };
    StarRatingComponent.prototype.onHoverOut = function (index, value, name, event) {
        event.stopPropagation();
        var _a = this.props, onHoverOut = _a.onHoverOut, isDisabled = _a.isDisabled;
        if (isDisabled) {
            return;
        }
        if (onHoverOut) {
            onHoverOut(index, value, name, event);
        }
    };
    StarRatingComponent.prototype.onFocusInput = function (index) {
        this.isHalfStarFocused = index % 1 !== 0;
    };
    StarRatingComponent.prototype.starStyles = function (i, rating) {
        var _a = this.props, color = _a.color, emptyColor = _a.emptyColor, isDisabled = _a.isDisabled;
        return {
            float: 'left',
            cursor: !isDisabled ? 'pointer' : 'default',
            color: i <= rating ? color : emptyColor,
            marginBottom: 0,
            position: 'relative',
        };
    };
    StarRatingComponent.prototype.renderSingleStar = function (id, index, name) {
        var _this = this;
        var value = this.state.value;
        return (React.createElement("label", { key: "label_" + id, style: this.starStyles(index, value), className: 'elmo-rating-star ' +
                (index <= value ? 'elmo-rating-full-star' : 'elmo-rating-empty-star'), htmlFor: id, onClick: function (e) { return _this.onClick(index, value, name, e); }, onMouseOver: function (e) { return _this.onHover(index, value, name, e); }, onMouseLeave: function (e) { return _this.onHoverOut(index, value, name, e); } },
            React.createElement("span", { className: "sr-only" }, index + " Star" + (index === 1 ? '' : 's')),
            React.createElement("input", { key: "input_" + id, className: "sr-only", type: "radio", name: name || 'star', id: id, value: index, checked: index === value, "aria-checked": index === value, onChange: this.onChange.bind(this, index, name), tabIndex: index === value || value === 0 ? 0 : -1 }),
            this.renderItems(index, value, name, id)));
    };
    StarRatingComponent.prototype.renderHalfStar = function (id, index, name) {
        var _this = this;
        var value = this.state.value;
        return (React.createElement("label", { key: "label_" + id, style: this.starStyles(index, value), className: 'elmo-rating-star ' +
                (index <= value ? 'elmo-rating-full-star' : 'elmo-rating-empty-star'), onClick: function (e) { return _this.onClick(index, value, name, e); }, onMouseOver: function (e) { return _this.onHover(index, value, name, e); }, onMouseLeave: function (e) { return _this.onHoverOut(index, value, name, e); } },
            React.createElement("span", { className: "sr-only" }, index + " Star" + (index === 1 ? '' : 's')),
            React.createElement("input", { key: "input_" + id + "_half", className: "sr-only", type: "radio", name: name || 'star', id: id + "half", value: index - 0.5, checked: index - 0.5 === value, "aria-checked": index - 0.5 === value, onChange: this.onChange.bind(this, index - 0.5, name), tabIndex: index - 0.5 === value || value === 0 ? 0 : -1, "aria-label": index - 0.5 + " star", onFocus: function (e) { return _this.onFocusInput(index - 0.5); } }),
            React.createElement("input", { key: "input_" + id, className: "sr-only", type: "radio", name: name || 'star', id: id, value: index, checked: index === value, "aria-checked": index === value, onChange: this.onChange.bind(this, index, name), tabIndex: index === value || value === 0 ? 0 : -1, "aria-label": index + " star", onFocus: function (e) { return _this.onFocusInput(index); } }),
            this.renderItems(index, value, name, id)));
    };
    StarRatingComponent.prototype.renderStars = function () {
        var _a = this.props, name = _a.name, count = _a.count, isHalfStarCount = _a.isHalfStarCount;
        if (typeof count !== 'number') {
            return null;
        }
        // populate stars
        var starNodes = [];
        for (var i = 1; i < count + 1; i++) {
            var id = "" + (name || 'star') + i;
            var starNodeLabel = isHalfStarCount
                ? this.renderHalfStar(id, i, name)
                : this.renderSingleStar(id, i, name);
            starNodes.push(starNodeLabel);
        }
        return starNodes.length ? starNodes : null;
    };
    StarRatingComponent.prototype.renderItems = function (index, value, name, id) {
        var _a = this.props, render = _a.render, renderIconHalf = _a.renderIconHalf, renderEmptyIcon = _a.renderEmptyIcon;
        var emptyIcon = typeof renderEmptyIcon === 'function' &&
            renderEmptyIcon(index, value, name, id);
        if (typeof renderIconHalf === 'function' &&
            Math.ceil(value) === index &&
            value % 1 !== 0) {
            return renderIconHalf(index, value, name, id);
        }
        if (typeof renderEmptyIcon === 'function' &&
            index > value &&
            emptyIcon !== undefined) {
            return emptyIcon;
        }
        if (typeof render === 'function') {
            return render(index, value, name, id);
        }
        return (React.createElement("i", { key: "icon_" + id, style: { fontStyle: 'normal' } }, "\u2605"));
    };
    StarRatingComponent.prototype.render = function () {
        var _a = this.props, isDisabled = _a.isDisabled, className = _a.className, id = _a.id, hasLabel = _a.hasLabel, legend = _a.legend;
        var classes = getClass('elmo-rating elmo-rating--star', {
            'elmo-rating-non-editable': isDisabled,
        }, className);
        return (React.createElement(Text, null,
            React.createElement("div", { id: id, "data-testid": "elmo-rating-" + (id || 'default'), style: { display: 'flex', alignItems: 'center' }, className: classes },
                React.createElement("fieldset", { style: { display: 'inline-block', position: 'relative' }, "aria-disabled": isDisabled },
                    React.createElement("legend", { className: "sr-only" }, legend),
                    this.renderStars()),
                hasLabel ? this.renderLabel() : React.createElement(React.Fragment, null))));
    };
    StarRatingComponent.defaultProps = {
        count: 5,
        color: '#545454',
        emptyColor: '#545454',
        size: 'lg',
        legend: 'Rating',
    };
    return StarRatingComponent;
}(Component));
export default StarRatingComponent;
