import React, { ReactElement } from 'react';
import { Layout } from 'elmo-elements';

import EmptyState from 'sharedComponents/EmptyState';
import translations from '../../translations';

const NoAccess = (): ReactElement => {
  return (
    <Layout.Content>
      <EmptyState content={translations.noAccess} hasPrimaryButton={false} />
    </Layout.Content>
  );
};

export default NoAccess;
