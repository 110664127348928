import { getTMSHost, setPageTitle } from 'lib';
import { Menu, SubMenuModule } from 'type';
import { history } from 'lib/history';
import { MENU_KEY } from '../Menu';

export const getMenuMainItems = (menu: Menu) => {
  return menu.menu.modules.map(
    (item: { title: string; url: string; icon: string; key: string }) => ({
      title: item.title,
      url: item.url,
      icon: item.icon,
      isSelected: item.key === MENU_KEY,
    })
  );
};

export const changeRoute = (item: SubMenuModule) => {
  if (!item.angularRoute) {
    setPageTitle(item.title);
    history.push(item.url);
  } else {
    window.location.href = item.url;
  }
};

export const getSubMenuItems = (menu: Menu, currentRoute: string) => {
  if (!menu.menu.subModules) {
    return undefined;
  }

  return menu.menu.subModules.map((item: SubMenuModule) => ({
    title: item.title,
    url: item.url,
    icon: item.icon,
    isSelected: currentRoute === item.url,
    handleClick: () => {
      changeRoute(item);
    },
  }));
};

export const getUserMenuItems = (menu: Menu) => {
  // remove admin item from the menu, we are presenting the admin info in the user details object
  const userMenu = menu.menu.user.filter(
    (n: { key: string }) => n.key !== 'app.menu.admin'
  );

  return userMenu.map((item: { title: string; url: string; icon: string }) => ({
    title: item.title,
    url: item.url,
    icon: item.icon,
  }));
};

export const getParentPageTitle = (
  menu: Menu,
  currentRoute: string
): string => {
  if (!menu.menu.subModules) {
    return '';
  }

  const routeParts = currentRoute.split('/');

  const item = menu.menu.subModules.find((submenuItem: SubMenuModule) => {
    const currentRouteParts = submenuItem.url.split('/');

    return currentRouteParts[1] === routeParts[1];
  });

  return item ? item.title : '';
};

export const onClickLogo = () => {
  const tmsHost = getTMSHost();

  if (!tmsHost) {
    return;
  }

  window.location.href = `//${tmsHost}`;
};
