var _a;
import React from 'react';
import { STATUS_TYPE_INFO, STATUS_TYPE_DANGER, STATUS_TYPE_SUCCESS, STATUS_TYPE_WARNING, } from '../../_lib/const';
import { InfoIcon, WarningIcon, ErrorIcon, CheckCircleIcon } from '../../Icon';
import './AlertIcon.scss';
var icons = (_a = {},
    _a[STATUS_TYPE_INFO] = InfoIcon,
    _a[STATUS_TYPE_DANGER] = ErrorIcon,
    _a[STATUS_TYPE_WARNING] = WarningIcon,
    _a[STATUS_TYPE_SUCCESS] = CheckCircleIcon,
    _a);
function AlertIcon(_a) {
    var type = _a.type, icon = _a.icon;
    if (icon) {
        return React.createElement(React.Fragment, null, "icon");
    }
    var Icon = icons[type] || InfoIcon;
    return React.createElement(Icon, { className: "elmo-alert__icon", title: type });
}
export default AlertIcon;
