import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateQuestionPayload } from './type';
import { ErrorResponse } from 'type';

export const updateQuestion: ActionCreator<UpdateQuestionPayload> =
  createAction('UPDATE_QUESTION');

export const updateQuestionSuccess: EmptyActionCreator = createAction(
  'UPDATE_QUESTION_SUCCESS'
);

export const updateQuestionFailure: ActionCreator<ErrorResponse> = createAction(
  'UPDATE_QUESTION_FAILURE'
);

export const clearUpdateQuestion: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_QUESTION'
);
