import produce from 'immer';

import {
  clearUpdateInviteSchedules,
  updateInviteSchedules,
  updateInviteSchedulesFailure,
  updateInviteSchedulesSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function updateInviteSchedulesReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteSchedules.isUpdated = false;
    draft.updateInviteSchedules.isUpdating = true;
    draft.updateInviteSchedules.isValid = false;
  });
}

export function updateInviteSchedulesSuccessReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteSchedules.isUpdated = true;
    draft.updateInviteSchedules.isUpdating = false;
    draft.updateInviteSchedules.isValid = true;
  });
}

export function updateInviteSchedulesFailureReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteSchedules.isUpdated = true;
    draft.updateInviteSchedules.isUpdating = false;
  });
}

export function clearUpdateInviteSchedulesReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteSchedules = invitesInitialState.updateInviteSchedules;
  });
}

export const updateInviteSchedulesReducers = {
  [`${updateInviteSchedules}`]: updateInviteSchedulesReducer,
  [`${updateInviteSchedulesSuccess}`]: updateInviteSchedulesSuccessReducer,
  [`${updateInviteSchedulesFailure}`]: updateInviteSchedulesFailureReducer,
  [`${clearUpdateInviteSchedules}`]: clearUpdateInviteSchedulesReducer,
};
