import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { AddCollectorPayload, AddCollectorResponse } from './type';
import { ErrorResponse } from 'type';

export const addCollector: ActionCreator<AddCollectorPayload> =
  createAction('ADD_COLLECTOR');

export const addCollectorSuccess: ActionCreator<AddCollectorResponse> =
  createAction('ADD_COLLECTOR_SUCCESS');

export const addCollectorFailure: ActionCreator<ErrorResponse> = createAction(
  'ADD_COLLECTOR_FAILURE'
);

export const clearAddCollector: EmptyActionCreator = createAction(
  'CLEAR_ADD_COLLECTOR'
);
