import { RouteObject } from 'react-router-dom';
import React from 'react';

import IndividualShareReport from './pages/IndividualShareReport';
import QuestionSummariesSuspense from './pages/QuestionSummaries';
import IndividualResponses from './pages/IndividualResponses';
import BulkShareReport from './pages/BulkShareReport';
import BenchmarkReport from './pages/BenchmarkReport';

const ReportsRoutes: RouteObject = {
  path: '/surveys/:surveyId/reports',
  children: [
    {
      path: '/surveys/:surveyId/reports/question-summaries/report/:reportId',
      element: <QuestionSummariesSuspense />,
    },
    {
      path: '/surveys/:surveyId/reports/question-summaries/share/individual',
      element: <IndividualShareReport />,
    },
    {
      path: '/surveys/:surveyId/reports/question-summaries/share/bulk',
      element: <BulkShareReport />,
    },
    {
      path: '/surveys/:surveyId/reports/benchmark',
      element: <BenchmarkReport />,
    },
    {
      path: '/surveys/:surveyId/reports/question-summaries',
      element: <QuestionSummariesSuspense />,
    },
    {
      path: '/surveys/:surveyId/reports/individual-responses',
      element: <IndividualResponses />,
    },
  ],
};

export default ReportsRoutes;
