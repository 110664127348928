import map from 'lodash/map';

import { APIEmailTemplate, EmailTemplate } from '../../type';
import { transformUser } from './user';

export const transformEmailTemplate = (
  emailTemplate: APIEmailTemplate
): EmailTemplate => {
  return {
    body: emailTemplate.body,
    createdBy: transformUser(emailTemplate.created_by),
    createdDate: emailTemplate.created_date,
    deletedDate: emailTemplate.deleted_date,
    id: emailTemplate.id,
    lastUpdatedBy: emailTemplate.last_updated_by
      ? transformUser(emailTemplate.last_updated_by)
      : null,
    title: emailTemplate.title,
    updatedDate: emailTemplate.updated_date,
  };
};

export const transformEmailsTemplates = (
  emailTemplates: APIEmailTemplate[]
): EmailTemplate[] => {
  return map(emailTemplates, transformEmailTemplate);
};
