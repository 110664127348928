import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const responseRoute = '/respondents/:respondentId/restore';

export const restoreResponseApi = (respondentId: number) => {
  return putRequest(
    getRoute(responseRoute, {
      params: { respondentId: respondentId },
    })
  );
};
