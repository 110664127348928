import filter from 'lodash/filter';
import assign from 'lodash/assign';
import keys from 'lodash/keys';
import map from 'lodash/map';

import {
  ManageInviteParameters,
  ManageInvitesData,
} from 'store/modules/invites/manageInvite/type';
import {
  hasEditableReminder,
  isMessageEditable,
} from 'lib/helpers/emailInvites';
import { Cancelled } from 'constants/messageStatus';
import { convertToUTC } from 'lib/date';
import { Message } from 'type';

export const getReminderSchedules = (reminderMessages: Message[]) => {
  const remindersArray: any = map(reminderMessages, (message: Message) => {
    return { [`reminder${message.id}`]: message.scheduledDate };
  });

  return assign.apply(assign, remindersArray);
};

export const formToState = (formData: any) => {
  const reminderData = filter(keys(formData), (key: string) => {
    return key.startsWith('reminder');
  });

  const reminderSchedules = map(reminderData, (key: string) => {
    return {
      id: parseInt(key.replace('reminder', ''), 10),
      scheduledDate: convertToUTC(formData[key]),
    };
  });

  return {
    sendNow: formData.sendNow,
    scheduledDate: formData.inviteDate
      ? convertToUTC(formData.inviteDate)
      : null,
    reminders: reminderSchedules,
  };
};

export const hasCancelledMessage = (invite: ManageInvitesData) => {
  return (
    invite.message.status === Cancelled ||
    invite.reminders.some((reminder) => reminder.status === Cancelled)
  );
};

export const isScheduleFormEditable = (
  parameters: ManageInviteParameters,
  invite: ManageInvitesData
): boolean => {
  if (!parameters.isEdit) {
    return true;
  }

  if (
    isMessageEditable(invite.message) ||
    hasEditableReminder(invite.reminders)
  ) {
    return true;
  }

  return hasCancelledMessage(invite);
};
