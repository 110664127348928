import { GetIndividualLinksState } from './type';
import { Query } from 'lib/stateTemplates';

export const getIndividualLinksInitialState: GetIndividualLinksState = {
  count: 0,
  isLoaded: false,
  isLoading: false,
  isValid: false,
  list: [],
  query: Query,
};
