var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
import './ButtonsStyle.scss';
function ButtonsStyle(props) {
    return (React.createElement(LoaderHolder, __assign({ className: "elmo-loader-buttons", viewBoxHeight: 60, viewBoxWidth: 400 }, props),
        React.createElement("rect", { x: "0", y: "10", rx: "4", ry: "4", width: "60", height: "50" }),
        React.createElement("rect", { x: "80", y: "10", rx: "4", ry: "4", width: "100", height: "50" }),
        React.createElement("rect", { x: "200", y: "10", rx: "4", ry: "4", width: "60", height: "50" })));
}
export default ButtonsStyle;
