import httpClient from '../../../lib/api';

export const resources: any = {
  createPdf: {
    url: '/survey/export/create-pdf',
  },
};

export class CreatePdfApiService {
  createPdf(params: any): any {
    return () =>
      httpClient
        .get(resources.createPdf.url, { params })
        .then((result: any) => result.data);
  }
}

export default new CreatePdfApiService();
