import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateBulkPasswordFailure,
  updateBulkPasswordSuccess,
} from './actions';
import { UpdateBulkPasswordRequest } from './type';
import { ErrorResponse, SagaAction } from 'type';
import { updateBulkPasswordApi } from './api';

export const updateBulkPasswordsSaga = function* (
  action: SagaAction<UpdateBulkPasswordRequest>
): SagaIterator {
  try {
    let formData = new FormData();
    formData.append('fileName', action.payload.fileName);
    formData.append('originalFileName', action.payload.originalFileName);

    const data = yield call(updateBulkPasswordApi(action.payload.id, formData));

    if (data === null || data === undefined) {
      const updateError: ErrorResponse = {
        data: undefined,
        headers: undefined,
        status: 0,
      };
      yield put(updateBulkPasswordFailure(updateError));
    }

    yield put(updateBulkPasswordSuccess());
  } catch (error) {
    yield put(updateBulkPasswordFailure(error));
  }
};
