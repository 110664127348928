import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

export const deleteTheme: ActionCreator<number> = createAction('DELETE_THEME');

export const deleteThemeSuccess: EmptyActionCreator = createAction(
  'DELETE_THEME_SUCCESS'
);

export const deleteThemeFailure: EmptyActionCreator = createAction(
  'DELETE_THEME_FAILURE'
);

export const clearDeleteTheme: EmptyActionCreator =
  createAction('CLEAR_DELETE_THEME');
