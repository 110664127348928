var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { ExpandLessIcon as ExpandLessOutlinedIcon, ExpandMoreIcon as ExpandMoreOutlinedIcon, } from '../Icon';
import ActionCardHeader from './components/ActionCardHeader';
import ActionCardContent from './components/ActionCardContent';
import ActionCardFooter from './components/ActionCardFooter';
import { getClass } from '../_lib/helper';
import { Button } from '../main';
import './ActionCard.scss';
var ActionCard = /** @class */ (function (_super) {
    __extends(ActionCard, _super);
    function ActionCard(props) {
        var _this = _super.call(this, props) || this;
        _this.expandBtnClickHandler = function () {
            var onExpand = _this.props.onExpand;
            var isExpanded = _this.state.isExpanded;
            _this.setState({
                isExpanded: !isExpanded,
            }, function () {
                if (onExpand) {
                    onExpand(_this.state.isExpanded);
                }
            });
        };
        var isExpanded = _this.props.isExpanded;
        _this.state = {
            isExpanded: isExpanded ? isExpanded : false,
        };
        return _this;
    }
    ActionCard.prototype.componentDidUpdate = function (prevProps) {
        var isExpanded = this.props.isExpanded;
        if (prevProps.isExpanded !== this.props.isExpanded) {
            this.setState({
                isExpanded: !!isExpanded,
            });
        }
    };
    ActionCard.prototype.render = function () {
        var _a = this.props, children = _a.children, id = _a.id, header = _a.header, actions = _a.actions, isExpandable = _a.isExpandable, expandBtnAriaLabel = _a.expandBtnAriaLabel;
        var isExpanded = this.state.isExpanded;
        return (React.createElement("div", { id: id, "data-testid": "elmo-actioncard-" + (id || 'default'), className: getClass('elmo-actioncard', { 'is-expanded': isExpanded }, { 'is-expandable': isExpandable, 'absolute-header': !this.hasTitle }) },
            header && React.createElement(ActionCardHeader, __assign({}, header)),
            React.createElement("div", { className: "elmo-actioncard__content-wrapper" },
                React.createElement("div", { className: "elmo-actioncard__content-inner-wrapper" }, children),
                isExpandable && (React.createElement("div", { className: "elmo-actioncard__expand-btn-wrapper" },
                    React.createElement(Button, { isText: true, icon: isExpanded ? (React.createElement(ExpandLessOutlinedIcon, null)) : (React.createElement(ExpandMoreOutlinedIcon, null)), onClick: this.expandBtnClickHandler, ariaLabel: expandBtnAriaLabel, ariaExpanded: isExpanded })))),
            actions && actions.length && React.createElement(ActionCardFooter, { actions: actions })));
    };
    Object.defineProperty(ActionCard.prototype, "hasTitle", {
        get: function () {
            var _a = this.props.header, header = _a === void 0 ? {} : _a;
            return header.title && header.titleAddOn && header.icon;
        },
        enumerable: false,
        configurable: true
    });
    ActionCard.Content = ActionCardContent;
    ActionCard.defaultProps = {
        expandBtnAriaLabel: 'Expand',
    };
    return ActionCard;
}(Component));
export default ActionCard;
