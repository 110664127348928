import { Button, FormItem, Heading, Input, Row } from 'elmo-elements';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getAnonSurveyTheme,
  loginSharedReport,
  clearGetSurvey,
} from 'store/actions';
import { InvalidLink, LoginError } from './components';
import LoaderOverlay from 'element/LoaderOverlay';
import translations from './translations';
import { RootState } from 'store/type';
import { t } from 'lib/translation';
import './ReportLogin.scss';

const ReportLogin = (): ReactElement => {
  const dispatch = useDispatch();
  const { reportHash } = useParams() as unknown as { reportHash: string };

  const authenticateState = useSelector(
    (state: RootState) => state.authenticate.loginSharedReport
  );

  const themeState = useSelector(
    (state: RootState) => state.theme.getAnonSurveyTheme
  );

  const [password, updatePassword] = useState<string>('');

  const doLogin = () => {
    dispatch(clearGetSurvey());
    dispatch(
      loginSharedReport({
        username: reportHash,
        password: password,
      })
    );
  };

  useEffect(() => {
    dispatch(getAnonSurveyTheme(reportHash));
  }, [reportHash]);

  if (themeState.error && themeState.error.status === 404) {
    return <InvalidLink />;
  }

  return (
    <>
      {(authenticateState.isLoading || themeState.isLoading) && (
        <LoaderOverlay />
      )}
      <div id={'login-container'}>
        <div className={'well well-white e-padding-40'}>
          <Row>
            <div id={'login-box'}>
              {themeState.theme.logoUrl && (
                <Row>
                  <img
                    className={'logo'}
                    src={themeState.theme.logoUrl}
                    alt={themeState.theme.logoName}
                  />
                </Row>
              )}
              <Row className={'bottom-spacing'}>
                <Heading>{t(translations.heading)}</Heading>
              </Row>
              <LoginError />
              <Row>
                <FormItem isFluid={false}>
                  <Input
                    id={'report-password'}
                    htmlType={'password'}
                    value={password}
                    onChange={(e) => updatePassword(e.target.value)}
                  />
                </FormItem>
              </Row>
              <Row>
                <Button
                  type={'primary'}
                  id={'submit-button'}
                  isBlock={true}
                  onClick={doLogin}
                >
                  {t(translations.loginButton)}
                </Button>
              </Row>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ReportLogin;
