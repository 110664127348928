import React from 'react';
import { Heading } from '../../Typography';
function ModalTitle(_a) {
    var icon = _a.icon, title = _a.title, titleSize = _a.titleSize;
    if (title) {
        return (React.createElement("div", { className: "elmo-modal__header-title" },
            React.createElement(Heading, { size: titleSize, type: "title" },
                icon,
                " ",
                title)));
    }
    return null;
}
export default ModalTitle;
