var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Children, Component } from 'react';
import SelectListOption from './component/SelectListOption';
import SelectAllButton from './component/SelectAllButton';
import './SelectList.scss';
import SelectListSearch from './component/SelectListSearch';
import SelectListOptionHeader from './component/SelectListOptionHeader';
var SelectList = /** @class */ (function (_super) {
    __extends(SelectList, _super);
    function SelectList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleSelectAllClick = function () {
            var onSelectAllToggle = _this.props.onSelectAllToggle;
            onSelectAllToggle(!_this.isAllSelected);
        };
        return _this;
    }
    SelectList.prototype.render = function () {
        var _a = this.props, id = _a.id, onSearch = _a.onSearch, onSelectAllToggle = _a.onSelectAllToggle;
        return (React.createElement("div", { id: id, "data-testid": "elmo-select-list-" + (id || 'default'), className: "elmo-select-list" },
            React.createElement("div", { className: "elmo-select-list__header" },
                onSearch && React.createElement(SelectListSearch, { onChange: onSearch }),
                onSelectAllToggle && (React.createElement(SelectAllButton, { handleSelectAllClick: this.handleSelectAllClick, isActive: !this.isAllSelected }))),
            React.createElement("div", { className: "elmo-select-list__content", role: "list" }, this.props.children)));
    };
    Object.defineProperty(SelectList.prototype, "isAllSelected", {
        get: function () {
            var total = 0;
            var countSelected = 0;
            Children.forEach(this.props.children, function (child) {
                var _a = child.props, isDisabled = _a.isDisabled, isSelected = _a.isSelected;
                if (!isDisabled) {
                    total++;
                    if (!!isSelected) {
                        countSelected++;
                    }
                }
            });
            return countSelected === total;
        },
        enumerable: false,
        configurable: true
    });
    SelectList.Option = SelectListOption;
    SelectList.OptionHeader = SelectListOptionHeader;
    return SelectList;
}(Component));
export default SelectList;
