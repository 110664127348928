import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { CloseIndividualLinkRequest } from './type';
import { ErrorResponse } from 'type';

export const closeIndividualLink: ActionCreator<CloseIndividualLinkRequest> =
  createAction('CLOSE_INDIVIDUAL_LINK');

export const closeIndividualLinkSuccess: EmptyActionCreator = createAction(
  'CLOSE_INDIVIDUAL_LINK_SUCCESS'
);

export const closeIndividualLinkFailure: ActionCreator<ErrorResponse> =
  createAction('CLOSE_INDIVIDUAL_LINK_FAILURE');

export const clearCloseIndividualLink: EmptyActionCreator = createAction(
  'CLEAR_CLOSE_INDIVIDUAL_LINK'
);
