import React from 'react';
import MenuIcon from './MenuIcon';
import Button from '../../Button';
import Image from '../../Image';
import { MenuIcon as NavigationIcon } from '../../Icon';
import { getClass } from '../../_lib/helper';
function MobileHeader(_a) {
    var _b;
    var icon = _a.icon, label = _a.label, mobileLogo = _a.mobileLogo, onClick = _a.onClick, logoHref = _a.logoHref, drawMenu = _a.drawMenu;
    return (React.createElement("div", { className: getClass('elmo-nav-header-mobile', '', (_b = {},
            _b['with-draw-menu'] = !!drawMenu,
            _b)) },
        React.createElement("div", { className: "elmo-nav-header-mobile__icon" },
            React.createElement(Button, { icon: React.createElement(NavigationIcon, null), isText: true, onClick: onClick, isUppercase: false }, icon && label && (React.createElement("span", { className: "elmo-nav-header-mobile__home" },
                React.createElement(MenuIcon, { icon: icon }),
                " ",
                label)))),
        React.createElement("div", { className: "elmo-nav-header-mobile__logo", onClick: logoHref }, mobileLogo && (React.createElement(Image, { src: mobileLogo, className: "logo-small", alt: "Logo" }))),
        !!drawMenu && (React.createElement("div", { className: "elmo-nav-header-mobile__draw-menu-wrapper" }, drawMenu('mobile')))));
}
export default MobileHeader;
