import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getSurveyNotificationFailure,
  getSurveyNotificationSuccess,
} from './actions';
import { GetSurveyNotificationPayload } from './type';
import { getSurveyNotificationApi } from './api';
import { SagaAction } from 'type';

export const getSurveyNotificationSaga = function* (
  action: SagaAction<GetSurveyNotificationPayload>
): SagaIterator {
  try {
    const data = yield call(getSurveyNotificationApi(action.payload));

    yield put(getSurveyNotificationSuccess(data));
  } catch (error) {
    yield put(getSurveyNotificationFailure(error));
  }
};
