import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getDefaultInviteSenderFailure,
  getDefaultInviteSenderSuccess,
} from './actions';
import { getDefaultInviteSenderApi } from './api';

export const getDefaultInviteSenderSaga = function* (): SagaIterator {
  try {
    const data = yield call(getDefaultInviteSenderApi());

    yield put(getDefaultInviteSenderSuccess(data));
  } catch (error) {
    yield put(getDefaultInviteSenderFailure(error));
  }
};
