import { isSharedReport } from 'lib/helpers/sharedReports';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const surveyRoute = '/survey/:surveyId';
const sharedReportRoute = '/shared-reports/:surveyId';

export const getSurveyApi = (surveyId: number | string) => {
  const route = isSharedReport() ? sharedReportRoute : surveyRoute;

  return getRequest(
    getRoute(route, {
      params: { surveyId: surveyId },
    })
  );
};
