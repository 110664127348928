import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { SaveRespondentProgressPayload } from './type';

export const saveRespondentProgress: ActionCreator<SaveRespondentProgressPayload> =
  createAction('SAVE_RESPONDENT_PROGRESS');

export const saveRespondentProgressSuccess: EmptyActionCreator = createAction(
  'SAVE_RESPONDENT_PROGRESS_SUCCESS'
);

export const saveRespondentProgressFailure: EmptyActionCreator = createAction(
  'SAVE_RESPONDENT_PROGRESS_FAILURE'
);

export const setShouldUpdateSaveRespondentProgress: EmptyActionCreator =
  createAction('SET_SHOULD_UPDATE_SAVE_RESPONDENT_PROGRESS');
