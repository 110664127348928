import { createAction } from 'redux-act';

export const getFilter: any = createAction('SHARED_STATE_GET_FILTER');

export const getFilterSuccess: any = createAction(
  'SHARED_STATE_GET_FILTER_SUCCESS'
);

export const getFilterFailure: any = createAction(
  'SHARED_STATE_GET_FILTER_FAILURE'
);

export const clearFilterState: any = createAction(
  'SHARED_STATE_CLEAR_FILTER_STATE'
);
