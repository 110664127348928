import produce from 'immer';

import {
  clearGetResponse,
  getResponse,
  getResponseFailure,
  getResponseSuccess,
} from './actions';
import { transformResponse } from '../../../transformers';
import { getResponseInitialState } from './state';
import { GetResponseResponse } from './type';
import { ResponseState } from '../type';
import { ErrorResponse } from 'type';

export const getResponseReducer = (state: ResponseState): ResponseState => {
  return produce(state, (draft: ResponseState) => {
    draft.getResponse = { ...getResponseInitialState };
    draft.getResponse.isLoading = true;
  });
};

export const getResponseSuccessReducer = (
  state: ResponseState,
  data: GetResponseResponse
): ResponseState => {
  return produce(state, (draft: ResponseState) => {
    draft.getResponse.isLoading = false;
    draft.getResponse.isLoaded = true;
    draft.getResponse.isValid = true;
    draft.getResponse.data = transformResponse(data.data);
  });
};

export const getResponseFailureReducer = (
  state: ResponseState,
  error: ErrorResponse
): ResponseState => {
  return produce(state, (draft: ResponseState) => {
    draft.getResponse.isLoading = false;
    draft.getResponse.isLoaded = true;
    draft.getResponse.error = error;
  });
};

export const clearGetResponseReducer = (state: ResponseState) => {
  return produce(state, (draft: ResponseState) => {
    draft.getResponse = getResponseInitialState;
  });
};

export const getResponseReducers = {
  [`${getResponse}`]: getResponseReducer,
  [`${getResponseSuccess}`]: getResponseSuccessReducer,
  [`${getResponseFailure}`]: getResponseFailureReducer,
  [`${clearGetResponse}`]: clearGetResponseReducer,
};
