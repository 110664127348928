var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import Button from '../Button';
import BulkactionButtons from './component/BulkactionButtons';
import './BulkAction.scss';
var BulkAction = /** @class */ (function (_super) {
    __extends(BulkAction, _super);
    function BulkAction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClose = function () {
            var onClose = _this.props.onClose;
            if (onClose) {
                onClose();
            }
        };
        return _this;
    }
    BulkAction.prototype.render = function () {
        var _a = this.props, id = _a.id, isVisible = _a.isVisible, actions = _a.actions, isDisabled = _a.isDisabled;
        return (React.createElement("div", { id: id, "data-testid": "elmo-bulk-actions-" + (id || 'default'), className: getClass('elmo-bulk-actions', {}, { open: isVisible }) },
            React.createElement("div", { className: "elmo-bulk-actions__wrapper" },
                React.createElement(BulkactionButtons, { actions: actions, isDisabled: isDisabled }),
                React.createElement("div", { className: "elmo-bulk-actions__close" },
                    React.createElement(Button, { type: "primary", isText: true, onClick: this.handleClose }, "Cancel")))));
    };
    BulkAction.displayName = 'BulkAction';
    return BulkAction;
}(Component));
export default BulkAction;
