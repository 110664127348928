var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import TimePicker from 'rc-time-picker';
import { AccessTimeIcon as ScheduleIcon } from '../Icon';
import { getClass } from '../_lib/helper';
import 'rc-time-picker/assets/index.css';
import './Timepicker.scss';
var Timepicker = /** @class */ (function (_super) {
    __extends(Timepicker, _super);
    function Timepicker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onChangeHandler = function (e) {
            var onChange = _this.props.onChange;
            if (onChange) {
                onChange(e);
            }
        };
        _this.onOpenHandler = function (e) {
            var onOpen = _this.props.onOpen;
            if (onOpen) {
                onOpen();
            }
        };
        _this.onCloseHandler = function (e) {
            var onClose = _this.props.onClose;
            if (onClose) {
                onClose();
            }
        };
        return _this;
    }
    Timepicker.prototype.render = function () {
        var _a = this.props, placeholder = _a.placeholder, className = _a.className, showSecond = _a.showSecond, value = _a.value, hourStep = _a.hourStep, minuteStep = _a.minuteStep, disabled = _a.disabled, inputIcon = _a.inputIcon, format = _a.format, use12Hours = _a.use12Hours, isReadOnly = _a.isReadOnly, name = _a.name, id = _a.id, label = _a.label;
        return (React.createElement(React.Fragment, null,
            label && (React.createElement("label", { htmlFor: id, className: "sr-only" }, label)),
            React.createElement(TimePicker, { placeholder: placeholder, showSecond: showSecond, value: value, onChange: this.onChangeHandler, hourStep: hourStep, minuteStep: minuteStep, disabled: disabled, onOpen: this.onOpenHandler, onClose: this.onCloseHandler, inputIcon: inputIcon || React.createElement(ScheduleIcon, null), className: getClass('elmo-timepicker', className), placement: 'bottomLeft', popupClassName: 'elmo-timepicker__popup', format: format, use12Hours: use12Hours, inputReadOnly: isReadOnly, id: id, name: name, focusOnOpen: true })));
    };
    Timepicker.defaultProps = {
        hourStep: 1,
        minuteStep: 1,
        showSecond: false,
        disabled: false,
        use12Hours: false,
        format: 'HH:mm',
        placeholder: '00:00',
    };
    return Timepicker;
}(Component));
export default Timepicker;
