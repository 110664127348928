import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetSurveyCollectorUsageResponse } from './type';
import { ErrorResponse } from 'type';

export const getSurveyCollectorUsage: ActionCreator<number> = createAction(
  'GET_SURVEY_COLLECTOR_USAGE'
);

export const getSurveyCollectorUsageSuccess: ActionCreator<GetSurveyCollectorUsageResponse> =
  createAction('GET_SURVEY_COLLECTOR_USAGE_SUCCESS');

export const getSurveyCollectorUsageFailure: ActionCreator<ErrorResponse> =
  createAction('GET_SURVEY_COLLECTOR_USAGE_FAILURE');

export const clearGetSurveyCollectorUsage: EmptyActionCreator = createAction(
  'CLEAR_GET_SURVEY_COLLECTOR_USAGE'
);
