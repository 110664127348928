import produce from 'immer';

import {
  getLatestCreatedBulkLink,
  getLatestCreatedBulkLinkFailure,
  getLatestCreatedBulkLinkSuccess,
  clearGetLatestCreatedBulkLink,
} from './actions';
import { getLatestCreatedBulkLinkInitialState } from './state';
import { ShareReportState } from '../type';
import { ErrorResponse } from 'type';

export const getLatestCreatedBulkLinkReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getLatestCreatedBulkLink = {
      ...getLatestCreatedBulkLinkInitialState,
    };
    draft.getLatestCreatedBulkLink.isLoading = true;
  });
};

export const getLatestCreatedBulkLinkSuccessReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getLatestCreatedBulkLink.isLoading = false;
    draft.getLatestCreatedBulkLink.isLoaded = true;
    draft.getLatestCreatedBulkLink.isValid = true;
  });
};

export const getLatestCreatedBulkLinkFailureReducer = (
  state: ShareReportState,
  error: ErrorResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getLatestCreatedBulkLink.isLoading = false;
    draft.getLatestCreatedBulkLink.isLoaded = true;
    draft.getLatestCreatedBulkLink.error = error;
  });
};

export const clearGetLatestCreatedBulkLinkReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getLatestCreatedBulkLink = getLatestCreatedBulkLinkInitialState;
  });
};

export const getLatestCreatedBulkLinkReducers = {
  [`${getLatestCreatedBulkLink}`]: getLatestCreatedBulkLinkReducer,
  [`${getLatestCreatedBulkLinkSuccess}`]:
    getLatestCreatedBulkLinkSuccessReducer,
  [`${getLatestCreatedBulkLinkFailure}`]:
    getLatestCreatedBulkLinkFailureReducer,
  [`${clearGetLatestCreatedBulkLink}`]: clearGetLatestCreatedBulkLinkReducer,
};
