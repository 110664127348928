import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateInviteMessageScheduleFailure,
  updateInviteMessageScheduleSuccess,
} from './actions';
import { updateInviteMessageScheduleApi } from './api';

export const updateInviteMessageScheduleSaga = function* (
  action: any
): SagaIterator {
  try {
    const data = yield call(
      updateInviteMessageScheduleApi(
        action.payload.messageId,
        action.payload.scheduleDate
      )
    );

    yield put(updateInviteMessageScheduleSuccess(data));
  } catch (error) {
    yield put(updateInviteMessageScheduleFailure(error));
  }
};
