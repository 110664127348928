import { GetQuestionCategoryPayload } from './type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const globalUrl = '/admin/question-categories/:questionCategoryId';
const localUrl = '/question-categories/:questionCategoryId';

export const getQuestionCategoryApi = ({
  isGlobal,
  questionCategoryId,
}: GetQuestionCategoryPayload) => {
  const url = isGlobal ? globalUrl : localUrl;

  return getRequest(
    getRoute(url, {
      params: {
        questionCategoryId: questionCategoryId,
      },
    })
  );
};
