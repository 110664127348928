import {
  uploadBulkUrlsCsv,
  uploadBulkUrlsCsvSuccess,
  uploadBulkUrlsCsvFailure,
  generateBulkUrls,
  generateBulkUrlsSuccess,
  generateBulkUrlsFailure,
  clearBulkUrls,
  getLatestGenerateBulkUrl,
  getLatestGenerateBulkUrlSuccess,
  getLatestGenerateBulkUrlFailure,
} from '../actions';

export function uploadBulkUrlsCsvReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      fileUpload: {
        ...state.bulkUrls.fileUpload,
        isLoaded: false,
        isLoading: true,
        files: [],
        errorMessages: {
          uploadImportTemplate: '',
          generateBulkUrls: '',
        },
      },
    },
  };
}

export function uploadBulkUrlsCsvSuccessReducer(state: any, data: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      fileUpload: {
        ...state.bulkUrls.fileUpload,
        isLoaded: true,
        isLoading: false,
        files: data.data.files,
        errorMessages: {
          uploadImportTemplate: '',
          generateBulkUrls: '',
        },
      },
    },
  };
}

export function uploadBulkUrlsCsvFailureReducer(state: any, error: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      fileUpload: {
        ...state.bulkUrls.fileUpload,
        isLoaded: false,
        isLoading: false,
        files: [],
        errorMessages: {
          uploadImportTemplate: error.data.error,
          generateBulkUrls: '',
        },
      },
    },
  };
}

export function generateBulkUrlsReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      fileUpload: {
        ...state.bulkUrls.fileUpload,
        isGenerating: true,
        isGenerated: false,
        files: [],
        errorMessages: {
          uploadImportTemplate: '',
          generateBulkUrls: '',
        },
      },
    },
  };
}

export function generateBulkUrlsSuccessReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      fileUpload: {
        ...state.bulkUrls.fileUpload,
        isGenerating: false,
        isGenerated: true,
        files: [],
        errorMessages: {
          uploadImportTemplate: '',
          generateBulkUrls: '',
        },
      },
      generateCheck: {
        id: null,
        isLoading: false,
        isLoaded: false,
        data: {},
      },
    },
  };
}

export function generateBulkUrlsFailureReducer(state: any, error: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      fileUpload: {
        ...state.bulkUrls.fileUpload,
        isGenerating: false,
        isGenerated: false,
        files: [],
        errorMessages: {
          uploadImportTemplate: '',
          generateBulkUrls: error.data.error,
        },
      },
    },
  };
}

export function clearBulkUrlsReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      fileUpload: {
        isLoaded: false,
        isLoading: false,
        isGenerating: false,
        isGenerated: false,
        files: [],
        errorMessages: {
          uploadImportTemplate: '',
          generateBulkUrls: '',
        },
        fileUploadElementKey:
          state.bulkUrls.fileUpload.fileUploadElementKey + 1,
      },
      generateCheck: {
        id: null,
        isLoading: false,
        isLoaded: false,
        data: {},
      },
    },
  };
}

export function getLatestGenerateBulkUrlReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      generateCheck: {
        ...state.bulkUrls.generateCheck,
        id: null,
      },
    },
  };
}

export function getLatestGenerateBulkUrlSuccessReducer(state: any, data: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      generateCheck: {
        ...state.bulkUrls.generateCheck,
        id: data.data[0].id,
      },
    },
  };
}

export function getLatestGenerateBulkUrlFailureReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      generateCheck: {
        ...state.bulkUrls.generateCheck,
      },
    },
  };
}

export const mySurveyBulkUrlsGenerateUrlsReducers = {
  [`${uploadBulkUrlsCsv}`]: uploadBulkUrlsCsvReducer,
  [`${uploadBulkUrlsCsvSuccess}`]: uploadBulkUrlsCsvSuccessReducer,
  [`${uploadBulkUrlsCsvFailure}`]: uploadBulkUrlsCsvFailureReducer,
  [`${generateBulkUrls}`]: generateBulkUrlsReducer,
  [`${generateBulkUrlsSuccess}`]: generateBulkUrlsSuccessReducer,
  [`${generateBulkUrlsFailure}`]: generateBulkUrlsFailureReducer,
  [`${clearBulkUrls}`]: clearBulkUrlsReducer,
  [`${getLatestGenerateBulkUrl}`]: getLatestGenerateBulkUrlReducer,
  [`${getLatestGenerateBulkUrlSuccess}`]:
    getLatestGenerateBulkUrlSuccessReducer,
  [`${getLatestGenerateBulkUrlFailure}`]:
    getLatestGenerateBulkUrlFailureReducer,
};
