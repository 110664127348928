var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { NoteTypes } from './type';
import { getClass } from '../_lib/helper';
import Button from '../Button';
import { CloseIcon } from '../Icon';
import './Notification.scss';
var Notification = /** @class */ (function (_super) {
    __extends(Notification, _super);
    function Notification(props) {
        var _this = _super.call(this, props) || this;
        _this.onMouseOver = function () {
            var onmouserover = _this.props.onmouserover;
            if (onmouserover) {
                onmouserover();
            }
        };
        _this.onMouseOut = function () {
            var onmouseout = _this.props.onmouseout;
            if (onmouseout) {
                onmouseout();
            }
        };
        return _this;
    }
    Notification.prototype.render = function () {
        var _a;
        var _b = this.props, type = _b.type, icon = _b.icon, message = _b.message, isActive = _b.isActive, onClose = _b.onClose, onUndo = _b.onUndo, id = _b.id, undoBtnText = _b.undoBtnText;
        var classIsActive = isActive ? 'elmo-notification__item--active' : '';
        var customClass = getClass('elmo-notification__item', classIsActive, (_a = {},
            _a["" + type] = type,
            _a));
        var textClass = getClass('elmo-notification__text', {
            'elmo-notification__text--icon': icon,
        });
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { id: id, "data-testid": "elmo-notification-" + (id || 'default'), className: "elmo-notification", role: "alert" },
                React.createElement("div", { className: "elmo-notification__wrapper" },
                    React.createElement("div", { onMouseOver: this.onMouseOver, onMouseOut: this.onMouseOut, className: customClass },
                        React.createElement("div", { className: "elmo-notification__flex" },
                            icon && React.createElement("div", { className: "elmo-notification__icon" }, icon),
                            React.createElement("div", { className: textClass }, message),
                            onUndo && (React.createElement(Button, { "data-testid": "elmo-notification-undo-btn", isText: true, className: "elmo-notification__action undo_action", onClick: onUndo, ariaLabel: undoBtnText }, undoBtnText)),
                            React.createElement(Button, { icon: React.createElement(CloseIcon, null), isText: true, onClick: onClose, className: "elmo-notification__close", ariaLabel: "Close" })))))));
    };
    Notification.defaultProps = {
        type: NoteTypes.dark,
        undoBtnText: 'Undo',
    };
    return Notification;
}(Component));
export default Notification;
