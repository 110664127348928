import { isPendoEnabled, pendoApiKey } from '../environmentVariables';
import AuthAccess, { JwtObject } from '../access';
import { parseClientNameFromUrl } from '../env';

declare const window: any;

export const init = () => {
  const apiKey = pendoApiKey();

  if (!isPendoEnabled() || apiKey === '') {
    return;
  }

  const pendo = window.pendo;
  const clientName = parseClientNameFromUrl();
  const jwtObject: JwtObject | null = AuthAccess.currentUser;

  if (jwtObject && jwtObject.user_id) {
    pendo.initialize({
      visitor: {
        id: jwtObject.uuid,
        email: jwtObject.email,
        fullName: jwtObject.first_name + jwtObject.last_name,
      },
      account: {
        id: clientName,
      },
    });
  }
};
