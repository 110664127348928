import React from 'react';
import Loader from '../../Loader';
import './PlaceholderMenu.scss';
/**
 * Placeholder for main menu
 */
function PlaceholderMenu(_a) {
    var id = _a.id;
    return (React.createElement("div", { id: id, "data-testid": "elmo-nav-menu-placeholder-" + (id || 'default'), className: "elmo-nav-menu elmo-nav-menu--placeholder" },
        React.createElement(Loader, { type: "menu", className: "elmo-nav-menu_loader--placeholder", height: "350px" })));
}
export default PlaceholderMenu;
