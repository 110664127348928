import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  deleteSurveyTemplateFailure,
  deleteSurveyTemplateSuccess,
} from './actions';
import { deleteSurveyTemplateApi } from './api';
import { SagaAction } from 'type';

export const deleteSurveyTemplateSaga = function* (
  action: SagaAction<{ id: number; isGlobal: boolean }>
): SagaIterator {
  try {
    yield call(
      deleteSurveyTemplateApi(action.payload.id, action.payload.isGlobal)
    );
    yield put(deleteSurveyTemplateSuccess());
  } catch (error) {
    yield put(deleteSurveyTemplateFailure(error));
  }
};
