import { all, fork } from 'redux-saga/effects';

import surveyNotificationSagas from './modules/surveyNotification/sagas';
import questionCategorySagas from './modules/questionCategory/sagas';
import overviewReportSagas from './modules/overviewReport/sagas';
import surveyTemplateSagas from './modules/surveyTemplate/sagas';
import emailTemplateSagas from './modules/emailTemplates/sagas';
import createPdfSagas from '../element/createPdf/state/sagas';
import authenticateSagas from './modules/authenticate/sagas';
import mySurveysSagas from '../page/MySurveys/state/sagas';
import shareReportSagas from './modules/shareReport/sagas';
import reportsSagas from '../page/Reports/state/sagas';
import collectorSagas from './modules/collector/sagas';
import benchmarkSagas from './modules/benchmark/sagas';
import responseSagas from './modules/response/sagas';
import bulkUrlsSagas from './modules/bulkUrls/sagas';
import questionSagas from './modules/question/sagas';
import sharedStateSagas from 'lib/sharedState/sagas';
import invitesSagas from './modules/invites/sagas';
import surveySagas from './modules/survey/sagas';
import filterSagas from './modules/filter/sagas';
import themeSagas from './modules/theme/sagas';
import adminSagas from './modules/admin/sagas';
import userSagas from './modules/user/sagas';
import app from './modules/app/sagas';

/**
 * rootSaga
 */
export default function* sagas(): any {
  yield all([fork(app)]);
  yield all([fork(invitesSagas)]);
  yield all([fork(surveySagas)]);
  yield all([fork(collectorSagas)]);
  yield all([fork(responseSagas)]);
  yield all([fork(emailTemplateSagas)]);
  yield all([fork(authenticateSagas)]);
  yield all([fork(surveyTemplateSagas)]);
  yield all([fork(filterSagas)]);
  yield all([fork(shareReportSagas)]);
  yield all([fork(themeSagas)]);
  yield all([fork(overviewReportSagas)]);
  yield all([fork(mySurveysSagas)]);
  yield all([fork(reportsSagas)]);
  yield all([fork(createPdfSagas)]);
  yield all([fork(sharedStateSagas)]);
  yield all([fork(questionCategorySagas)]);
  yield all([fork(bulkUrlsSagas)]);
  yield all([fork(surveyNotificationSagas)]);
  yield all([fork(userSagas)]);
  yield all([fork(adminSagas)]);
  yield all([fork(benchmarkSagas)]);
  yield all([fork(questionSagas)]);
}
