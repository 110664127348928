var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { withDataTableContext } from '../DataTableContextConsumer';
import './DataTableSelect.scss';
function DataTableSelectAllMessage(_a) {
    var children = _a.children;
    return React.createElement("div", { className: "elmo-datatable__select-message" }, children);
}
/**
 * Provides Select All Items functionality.
 * Does not need to be created by the user, created in DataTableHeader.
 */
var DataTableSelect = /** @class */ (function (_super) {
    __extends(DataTableSelect, _super);
    function DataTableSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Called when Select All / Cancel clicked
         * @param isSelect
         */
        _this.handleSelectAll = function (isSelect) { return function () {
            if (isSelect) {
                _this.onSelectAvailable();
            }
            else {
                _this.onCancel();
            }
        }; };
        /**
         * Called when Select All Available clicked
         */
        _this.onSelectAvailable = function () {
            var onSelectAllAvailableToggle = _this.props.onSelectAllAvailableToggle;
            if (onSelectAllAvailableToggle) {
                onSelectAllAvailableToggle(true);
            }
        };
        /**
         * Called when "Cancel" clicked in Select All Available component
         */
        _this.onCancel = function () {
            var _a = _this.props, setContextState = _a.setContextState, onSelectAllAvailableToggle = _a.onSelectAllAvailableToggle;
            if (setContextState) {
                setContextState({
                    isSelectAllChecked: false,
                    showSelectAllComponent: false,
                });
            }
            if (onSelectAllAvailableToggle) {
                onSelectAllAvailableToggle(false);
            }
        };
        return _this;
    }
    DataTableSelect.prototype.render = function () {
        var _a = this.props, countItemsAvailable = _a.countItemsAvailable, isAllAvailableItemsSelected = _a.isAllAvailableItemsSelected, countSelectable = _a.countSelectable;
        if (isAllAvailableItemsSelected) {
            return (React.createElement(DataTableSelectAllMessage, null,
                "All ",
                countItemsAvailable,
                " items are selected.\u00A0",
                React.createElement("span", { onClick: this.handleSelectAll(false) }, "Clear Selection")));
        }
        return (React.createElement(DataTableSelectAllMessage, null,
            "All ",
            countSelectable,
            " items selected on this page.\u00A0",
            React.createElement("span", { onClick: this.handleSelectAll(true) },
                "Select all ",
                countItemsAvailable,
                " items.")));
    };
    return DataTableSelect;
}(React.Component));
export default withDataTableContext(DataTableSelect);
