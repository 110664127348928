import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { DeleteQuestionCategoryPayload } from './type';
import { ErrorResponse } from 'type';

export const deleteQuestionCategory: ActionCreator<DeleteQuestionCategoryPayload> =
  createAction('DELETE_QUESTION_CATEGORY');

export const deleteQuestionCategorySuccess: EmptyActionCreator = createAction(
  'DELETE_QUESTION_CATEGORY_SUCCESS'
);

export const deleteQuestionCategoryFailure: ActionCreator<ErrorResponse> =
  createAction('DELETE_QUESTION_CATEGORY_FAILURE');

export const clearDeleteQuestionCategory: EmptyActionCreator = createAction(
  'CLEAR_DELETE_QUESTION_CATEGORY'
);
