import { Layout, PlaceholderDashboard, PlaceholderHeader } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import { isBenchmarkEnabled } from 'lib/helpers/featureFlag';
import NotFound from '../../../errorPages/pages/NotFound';
import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const BenchmarkReportComponent = lazy(() =>
  withDelay(() => import('./BenchmarkReportComponent'))
);

const BenchmarkReport = (): ReactElement => {
  if (!isBenchmarkEnabled()) {
    return <NotFound />;
  }

  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={true}
              hasBackButton={true}
              hasButtons={false}
              numberOfTabs={5}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderDashboard cols={1} rows={3} />
          </Layout.Content>
        </>
      }
    >
      <BenchmarkReportComponent />
    </Suspense>
  );
};

export default BenchmarkReport;
