import React from 'react';
import PropTypes from 'prop-types';
import './HeaderTitle.scss';
export var HeaderTitlePropTypes = {
    children: PropTypes.node,
    subHeading: PropTypes.string,
};
function HeaderTitle(_a) {
    var children = _a.children, subHeading = _a.subHeading;
    return React.createElement("h1", null, children);
}
HeaderTitle.propTypes = HeaderTitlePropTypes;
export default HeaderTitle;
