import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getQuestionsFailure,
  getQuestionsSuccess,
  updateGetQuestionsQuery,
} from './actions';
import { GetQuestionsRequest, QuestionSearchField } from './type';
import { ErrorResponse, QuestionsQuery, SagaAction } from 'type';
import { getQuestionsInitialState } from './state';
import { getQuestionsApi } from './api';
import { getQuery } from 'lib/query';

export const getQuestionsSaga = function* (
  action: SagaAction<GetQuestionsRequest>
): SagaIterator {
  try {
    const query = action.payload.query
      ? action.payload.query
      : getQuestionsInitialState.query;

    yield put(updateGetQuestionsQuery(query));

    const data = yield call(getQuestionsApi(query));
    yield put(getQuestionsSuccess(data));
  } catch (error) {
    yield put(getQuestionsFailure(error as ErrorResponse));
  }
};

export const getQuestionsFromQuerySaga = function* (): SagaIterator {
  try {
    const query: QuestionsQuery = getQuery(getQuestionsInitialState.query);
    query.searchField = QuestionSearchField.QUESTION;
    yield put(updateGetQuestionsQuery(query));

    const data = yield call(getQuestionsApi(query));
    yield put(getQuestionsSuccess(data));
  } catch (error) {
    yield put(getQuestionsFailure(error as ErrorResponse));
  }
};
