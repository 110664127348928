import { createAction } from 'redux-act';

export const updateInviteMessageSchedule: any = createAction(
  'UPDATE_INVITE_MESSAGE_SCHEDULE'
);

export const updateInviteMessageScheduleSuccess: any = createAction(
  'UPDATE_INVITE_MESSAGE_SCHEDULE_SUCCESS'
);

export const updateInviteMessageScheduleFailure: any = createAction(
  'UPDATE_INVITE_MESSAGE_SCHEDULE_FAILURE'
);

export const clearUpdateInviteMessageSchedule: any = createAction(
  'CLEAR_UPDATE_INVITE_MESSAGE_SCHEDULE'
);
