import { Navigate, RouteObject } from 'react-router-dom';
import React from 'react';

import BulkUrlPage from 'page/MySurveys/components/BulkUrls';
import AssociatedFilters from './pages/AssociatedFilters';
import CreateSurvey from './pages/CreateSurvey';
import SurveyListPage from './pages/SurveyList';
import CollectorPage from './pages/Collectors';
import EditSurvey from './pages/EditSurvey';
import Overview from './pages/Overview';
import Details from './pages/Details';

const routes: RouteObject = {
  path: '/surveys',
  children: [
    {
      path: '/surveys/create',
      element: <CreateSurvey />,
    },
    {
      path: '/surveys/:surveyId/edit',
      element: <EditSurvey />,
    },
    {
      path: '/surveys/:surveyId/edit/:collectorId/bulk-urls',
      element: <BulkUrlPage />,
    },
    {
      path: '/surveys/:surveyId/details',
      element: <Details />,
    },
    {
      path: '/surveys/:surveyId/overview',
      element: <Overview />,
    },
    {
      path: '/surveys/:surveyId/filters',
      element: <AssociatedFilters />,
    },
    {
      path: '/surveys/:surveyId/collectors',
      element: <CollectorPage />,
    },
    {
      path: '/surveys/active',
      element: <SurveyListPage />,
    },
    {
      path: '/surveys/archived',
      element: <SurveyListPage />,
    },
    {
      path: '/surveys',
      element: <Navigate to={'/surveys/active'} />,
    },
  ],
};

export default routes;
