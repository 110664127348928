/**
 * App setup and initialisations
 *
 *
 * <Dev.Note>
 *  Any async/sync activities that need to be done first in order to load the app can be impelemnted here.
 *  As you can see we dont implement the logic related to each action in this file so we keep this setup as
 *  minimal and abstract as posible. In otherword the job of this `setup` function is to orchestrate some
 *  individual set up activities.
 * </Dev.Note>
 */
function setup(): Promise<any> {
  return Promise.resolve(true);
}

export default setup;
