import produce from 'immer';

import {
  getBulkLinks,
  getBulkLinksFailure,
  getBulkLinksSuccess,
  updateGetBulkLinksQuery,
} from './actions';
import { transformSharedReportBulks } from '../../../transformers';
import { getBulkLinksInitialState } from './state';
import { GetBulkLinksResponse } from './type';
import { ShareReportState } from '../type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getBulkLinksReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getBulkLinks = { ...getBulkLinksInitialState };
    draft.getBulkLinks.isLoading = true;
  });
};

export const getBulkLinksSuccessReducer = (
  state: ShareReportState,
  data: GetBulkLinksResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getBulkLinks.isLoading = false;
    draft.getBulkLinks.isLoaded = true;
    draft.getBulkLinks.isValid = true;
    draft.getBulkLinks.count = data.count;
    draft.getBulkLinks.list = transformSharedReportBulks(data.data);
  });
};

export const getBulkLinksFailureReducer = (
  state: ShareReportState,
  error: ErrorResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getBulkLinks.isLoading = false;
    draft.getBulkLinks.isLoaded = true;
    draft.getBulkLinks.error = error;
  });
};

export function updateGetBulkLinksQueryReducer(
  state: ShareReportState,
  query: Query
): ShareReportState {
  return produce(state, (draft: ShareReportState) => {
    draft.getBulkLinks.query = query;
  });
}

export const getBulkLinksReducers = {
  [`${getBulkLinks}`]: getBulkLinksReducer,
  [`${getBulkLinksSuccess}`]: getBulkLinksSuccessReducer,
  [`${getBulkLinksFailure}`]: getBulkLinksFailureReducer,
  [`${updateGetBulkLinksQuery}`]: updateGetBulkLinksQueryReducer,
};
