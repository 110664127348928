import { ThemeProvider as ElmoThemeProvider } from 'elmo-elements';
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useEffect } from 'react';

import { isRunSurveyPage, isSurveyFormPage } from 'lib/helpers/surveyForm';
import { isSharedReport } from 'lib/helpers/sharedReports';
import { isErrorPage } from 'lib/helpers/error';
import PageLoading from 'element/PageLoading';
import { getSiteTheme } from 'store/actions';
import { convertTheme } from './helpers';
import { RootState } from 'store/type';

export interface ThemeProviderProps {
  children: ReactElement;
}

const ThemeProvider = ({ children }: ThemeProviderProps): ReactElement => {
  const skipSiteTheme =
    isErrorPage() ||
    isSurveyFormPage() ||
    isRunSurveyPage() ||
    isSharedReport();

  const dispatch = useDispatch();

  const getSiteThemeState = useSelector(
    (state: RootState) => state.app.getSiteTheme
  );

  useEffect(() => {
    if (!getSiteThemeState.isLoaded && !skipSiteTheme) {
      dispatch(getSiteTheme());
    }
  }, [getSiteThemeState.isLoaded, skipSiteTheme]);

  if (getSiteThemeState.isLoaded || skipSiteTheme) {
    const theme =
      skipSiteTheme || !getSiteThemeState.isValid || !getSiteThemeState.data
        ? {}
        : convertTheme(getSiteThemeState.data);

    return <ElmoThemeProvider theme={theme}>{children}</ElmoThemeProvider>;
  }

  return <PageLoading text={'Loading...'} />;
};

export default ThemeProvider;
