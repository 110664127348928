import { GetBulkUrlsPayload } from './type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/bulkurl-collector/:collectorId/batches';

export const getBulkUrlsApi = (payload: GetBulkUrlsPayload): any => {
  return getRequest(
    getRoute(apiUrl, {
      params: {
        collectorId: payload.collectorId,
      },
      query: payload.query,
    })
  );
};
