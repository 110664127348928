export default {
  reports: {
    tabs: {
      questionSummary: {
        key: 'Helper.reports.tabs.questionSummary',
        default: 'Question Summaries',
      },
      individualResponses: {
        key: 'Helper.reports.tabs.individualResponses',
        default: 'Individual Responses',
      },
      details: {
        key: 'Helper.reports.tabs.details',
        default: 'Details',
      },
      collectors: {
        key: 'Helper.reports.tabs.collectors',
        default: 'Collectors',
      },
      filters: {
        key: 'Helper.reports.tabs.filters',
        default: 'Associated Filters',
      },
      notifications: {
        key: 'Helper.reports.tabs.notifications',
        default: 'Notifications',
      },
      benchmark: {
        key: 'Helper.reports.tabs.benchmark',
        default: 'Benchmark',
      },
      overview: {
        key: 'Helper.reports.tabs.overview',
        default: 'Overview',
      },
    },
    benchmarkScore: {
      notMet: {
        key: '',
        default: 'Minimum Threshold',
      },
      notMetToolTip: {
        key: '',
        default:
          'The benchmark score is not available currently as the data minimum thresholds to calculate the ' +
          'score has not been met. \n\n The score will show once the minimum numbers are reached.',
      },
    },
  },
  collectors: {
    table: {
      type: {
        key: 'Helper.collectors.table.type',
        default: 'Type',
      },
      usage: {
        key: 'Helper.collectors.table.usage',
        default: 'Usage',
      },
    },
    types: {
      url: {
        key: 'Helper.collectors.types.url',
        default: 'URL',
      },
      bulkUrl: {
        key: 'Helper.collectors.types.bulkUrl',
        default: 'Bulk URLs',
      },
      email: {
        key: 'Helper.collectors.types.email',
        default: 'Email',
      },
      learning: {
        key: 'Helper.collectors.types.learning',
        default: 'Learning activity',
      },
      onboarding: {
        key: 'Helper.collectors.types.onboarding',
        default: 'Onboarding workflow',
      },
      recruitment: {
        key: 'Helper.collectors.types.recruitment',
        default: 'Recruitment workflow',
      },
    },
  },
  emailInvites: {
    tabs: {
      messages: {
        key: 'Helper.emailInvites.tabs.messages',
        default: 'Messages',
      },
      recipients: {
        key: 'Helper.emailInvites.tabs.recipients',
        default: 'Recipients',
      },
      invites: {
        key: 'Helper.emailInvites.tabs.invites',
        default: 'Invites',
      },
    },
  },
  survey: {
    tabs: {
      active: {
        key: '',
        default: 'Active',
      },
      archived: {
        key: '',
        default: 'Archived',
      },
    },
  },
};
