import { put, select, take } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateRespondentProgress,
  createRespondentProgress,
  updateRespondentProgressSuccess,
  createRespondentProgressSuccess,
} from '../actions';
import {
  getShouldUpdateRespondentProgress,
  isSavingRespondentInProgress,
} from './selectors';
import {
  saveRespondentProgressFailure,
  saveRespondentProgressSuccess,
} from './actions';
import { SaveRespondentProgressPayload } from './type';
import { SagaAction } from 'type';

export const saveRespondentProgressSaga = function* (
  action: SagaAction<SaveRespondentProgressPayload>
): SagaIterator {
  const isAlreadyInProgress = yield select(isSavingRespondentInProgress);

  if (isAlreadyInProgress) {
    return;
  }

  const shouldUpdateRespondent = yield select(
    getShouldUpdateRespondentProgress
  );

  try {
    if (shouldUpdateRespondent) {
      yield put(updateRespondentProgress(action.payload));

      yield take(updateRespondentProgressSuccess);
    } else {
      yield put(createRespondentProgress(action.payload));

      yield take(createRespondentProgressSuccess);
    }

    yield put(saveRespondentProgressSuccess());
  } catch (error) {
    yield put(saveRespondentProgressFailure());
  }
};
