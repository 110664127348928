/**
 *  <Dev.Note>
 *    In this file only define the parameters that are not subjected to change based on the environment.
 *    For example list of pagination page sizes (10, 20, 30) does not need to be changed in dev or staging env
 *    so you can define it here. In contrast an API base url is subjected to change from the staging or dev
 *    enviroement so you should define that in the config servise
 *  </Dev.Note>
 */

export const ENV: any = {
  names: {
    development: 'development',
    staging: 'staging',
    production: 'production',
  },
};

export const MIXPANEL_ENVIRONMENT: any = {
  dev: 'dev',
  prod: 'prod',
};

export const PAGINATION: any = {
  SIZE_OPTIONS: [10, 20, 30],
};

export const DATE_FORMAT: string = 'DD/MM/YYYY';

export const SURVEY_MENU_KEY = 'app.menu.survey';

export const FROALA_KEY =
  'OXC1lA3J4B12A10D9D7kOPVi1b1b2Le2e1G1AWMSGSAFDTGHWsE7D7D6E5A1I4E3C3A6D7==';
