import { CreateSurveyNotificationState } from './type';
import { SurveyNotification } from 'type';

export const createSurveyNotificationInitialState: CreateSurveyNotificationState =
  {
    isLoaded: false,
    isLoading: false,
    isValid: false,
    data: {} as SurveyNotification,
  };
