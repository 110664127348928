import { GetBulkLinkBatchRequest } from './type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const route = '/reports/:surveyId/batches/:batchId';

export const getBulkLinkBatchApi = (request: GetBulkLinkBatchRequest) => {
  return getRequest(
    getRoute(route, {
      params: { surveyId: request.surveyId, batchId: request.batchId },
    })
  );
};
