import produce from 'immer';

import {
  getQuestionCategories,
  getQuestionCategoriesFailure,
  getQuestionCategoriesSuccess,
  updateGetQuestionCategoriesQuery,
} from './actions';
import { transformQuestionCategories } from '../../../transformers';
import { GetQuestionCategoriesResponse } from './type';
import { QuestionCategoryState } from '../type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export function getQuestionCategoriesReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.getQuestionCategories.isLoading = true;
    draft.getQuestionCategories.isLoaded = false;
    draft.getQuestionCategories.isValid = false;
    draft.getQuestionCategories.count = 0;
    draft.getQuestionCategories.data = [];
  });
}

export function getQuestionCategoriesSuccessReducer(
  state: QuestionCategoryState,
  { data }: GetQuestionCategoriesResponse
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.getQuestionCategories.isLoading = false;
    draft.getQuestionCategories.isLoaded = true;
    draft.getQuestionCategories.isValid = true;
    draft.getQuestionCategories.count = data.count;
    draft.getQuestionCategories.allQuestionCategories =
      transformQuestionCategories(data.all_categories);
    draft.getQuestionCategories.data = transformQuestionCategories(
      data.filtered_categories
    );
  });
}

export function getQuestionCategoriesFailureReducer(
  state: QuestionCategoryState,
  error: ErrorResponse
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.getQuestionCategories.isLoading = false;
    draft.getQuestionCategories.isLoaded = true;
    draft.getQuestionCategories.error = error;
  });
}

export function updateGetQuestionCategoriesQueryReducers(
  state: QuestionCategoryState,
  query: Query
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.getQuestionCategories.query = query;
  });
}

export const getQuestionCategoriesReducers = {
  [`${getQuestionCategories}`]: getQuestionCategoriesReducer,
  [`${getQuestionCategoriesSuccess}`]: getQuestionCategoriesSuccessReducer,
  [`${getQuestionCategoriesFailure}`]: getQuestionCategoriesFailureReducer,
  [`${updateGetQuestionCategoriesQuery}`]:
    updateGetQuestionCategoriesQueryReducers,
};
