var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
function CardContentStyle(props) {
    return (React.createElement(LoaderHolder, __assign({ viewBoxHeight: 153 }, props),
        React.createElement("rect", { x: "0", y: "0", rx: "3", ry: "3", width: "138", height: "6" }),
        React.createElement("rect", { x: "0", y: "17", rx: "3", ry: "3", width: "296", height: "16.5" }),
        React.createElement("rect", { x: "0", y: "76", rx: "3", ry: "3", width: "118", height: "21" }),
        React.createElement("rect", { x: "0", y: "120", rx: "3", ry: "3", width: "71", height: "6" }),
        React.createElement("rect", { x: "0", y: "132", rx: "3", ry: "3", width: "90", height: "21" })));
}
export default CardContentStyle;
