import produce from 'immer';

import {
  clearCreateInviteMessageTemplate,
  createInviteMessageTemplate,
  createInviteMessageTemplateFailure,
  createInviteMessageTemplateSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function createInviteMessageTemplateReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteMessageTemplate.isCreated = false;
    draft.createInviteMessageTemplate.isCreating = true;
    draft.createInviteMessageTemplate.data = undefined;
  });
}

export function createInviteMessageTemplateSuccessReducer(
  state: InvitesState,
  data: any
) {
  return produce(state, (draft) => {
    draft.createInviteMessageTemplate.isCreated = true;
    draft.createInviteMessageTemplate.isCreating = false;
    draft.createInviteMessageTemplate.data = data.data;
  });
}

export function createInviteMessageTemplateFailureReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteMessageTemplate.isCreated = false;
    draft.createInviteMessageTemplate.isCreating = false;
  });
}

export function clearCreateInviteMessageTemplateReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteMessageTemplate =
      invitesInitialState.createInviteMessageTemplate;
  });
}

export const createInviteMessageTemplateReducers = {
  [`${createInviteMessageTemplate}`]: createInviteMessageTemplateReducer,
  [`${createInviteMessageTemplateSuccess}`]:
    createInviteMessageTemplateSuccessReducer,
  [`${createInviteMessageTemplateFailure}`]:
    createInviteMessageTemplateFailureReducer,
  [`${clearCreateInviteMessageTemplate}`]:
    clearCreateInviteMessageTemplateReducer,
};
