import produce from 'immer';

import {
  saveRespondentProgress,
  saveRespondentProgressFailure,
  saveRespondentProgressSuccess,
  setShouldUpdateSaveRespondentProgress,
} from './actions';
import { SurveyState } from '../type';

export function saveRespondentProgressReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.saveRespondentProgress.isLoading = true;
    draft.saveRespondentProgress.isLoaded = false;
    draft.saveRespondentProgress.isValid = false;
  });
}

export function saveRespondentProgressSuccessReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.saveRespondentProgress.isLoaded = true;
    draft.saveRespondentProgress.isValid = true;
    draft.saveRespondentProgress.isLoading = false;
    draft.saveRespondentProgress.shouldUpdate = true;
  });
}

export function saveRespondentProgressFailureReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.saveRespondentProgress.isLoaded = true;
    draft.saveRespondentProgress.isLoading = false;
  });
}

export function setShouldUpdateSaveRespondentProgressReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.saveRespondentProgress.shouldUpdate = true;
  });
}

export const saveRespondentProgressReducers = {
  [`${saveRespondentProgress}`]: saveRespondentProgressReducer,
  [`${saveRespondentProgressSuccess}`]: saveRespondentProgressSuccessReducer,
  [`${saveRespondentProgressFailure}`]: saveRespondentProgressFailureReducer,
  [`${setShouldUpdateSaveRespondentProgress}`]:
    setShouldUpdateSaveRespondentProgressReducer,
};
