import {
  getBulkUrlsCollector,
  getBulkUrlsCollectorFailure,
  getBulkUrlsCollectorSuccess,
} from '../actions';

export function getBulkUrlsCollectorReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      bulkCollector: {
        isLoaded: false,
        isLoading: true,
        data: {},
      },
    },
  };
}

export function getBulkUrlsCollectorSuccessReducer(state: any, data: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      bulkCollector: {
        isLoaded: true,
        isLoading: false,
        data: data.data,
      },
    },
  };
}

export function getBulkUrlsCollectorFailureReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      bulkCollector: {
        isLoaded: false,
        isLoading: false,
        data: {},
      },
    },
  };
}

export const mySurveyBulkUrlsReducers = {
  [`${getBulkUrlsCollector}`]: getBulkUrlsCollectorReducer,
  [`${getBulkUrlsCollectorSuccess}`]: getBulkUrlsCollectorSuccessReducer,
  [`${getBulkUrlsCollectorFailure}`]: getBulkUrlsCollectorFailureReducer,
};
