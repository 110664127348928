export const BulkUrlsTranslations: any = {
  availableUrls: {
    title: {
      key: 'mysurvey.availableUrls.list.title',
      default: 'Available Urls',
    },
    noContent: {
      header: {
        key: 'mysurvey.availableUrls.list.noContent.header',
        default: 'There are currently no Available URLs',
      },
      body: {
        key: 'mysurvey.availableUrls.list.noContent.body',
        default: 'There are currently no Available URLs',
      },
    },
    noResults: {
      header: {
        key: 'mysurvey.availableUrls.list.noResults.header',
        default: 'here are currently no Available URLs',
      },
    },
    columns: {
      file: {
        key: 'mysurvey.availableUrls.list.columns.file',
        default: 'File',
      },
      createdDate: {
        key: 'mysurvey.availableUrls.list.columns.createdDate',
        default: 'Created Date',
      },
      createdBy: {
        key: 'mysurvey.availableUrls.list.columns.createdBy',
        default: 'Created By',
      },
      numberOfLinks: {
        key: 'mysurvey.availableUrls.list.columns.numberOfLinks',
        default: 'Number of links',
      },
    },
  },
  generateUrls: {
    successMessage: {
      key: 'mysurvey.generateUrls.successMessage',
      default: 'Bulk Urls generated successfully.',
    },
    status: {
      title: {
        key: 'mysurvey.generateUrls.status.title',
        default: 'Generate Bulk URLs',
      },
      body: {
        line1: {
          key: 'mysurvey.generateUrls.status.body.line1',
          default:
            'Your request is taking longer than expected. ' +
            'For large imports this may take up to 10 minutes.',
        },
        line2: {
          key: 'mysurvey.generateUrls.status.body.line2',
          default:
            'You may close this popup and come back later to download your file, ' +
            'or please wait while its being processed.',
        },
      },
    },
  },
  uploadImportTemplate: {
    errorMessage: {
      key: 'mysurvey.uploadImportTemplate.errorMessage',
      default:
        'The file you have selected is invalid. ' +
        'Please select a valid CSV file based on the ' +
        'downloadable import template.',
    },
  },
};
