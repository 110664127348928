import produce from 'immer';

import { getUsers, getUsersFailure, getUsersSuccess } from './actions';
import { transformUsers } from '../../../transformers';
import { getUsersInitialState } from './state';
import { ErrorResponse } from 'type';
import { UserState } from '../type';

export function getUsersReducer(state: UserState): UserState {
  return produce(state, (draft: UserState) => {
    draft.getUsers = { ...getUsersInitialState };
    draft.getUsers.isLoading = true;
  });
}

export function getUsersSuccessReducer(state: UserState, data: any): UserState {
  return produce(state, (draft: UserState) => {
    draft.getUsers.isLoading = false;
    draft.getUsers.isLoaded = true;
    draft.getUsers.isValid = true;
    draft.getUsers.count = data.count;
    draft.getUsers.data = transformUsers(data.data);
  });
}

export function getUsersFailureReducer(
  state: UserState,
  error: ErrorResponse
): UserState {
  return produce(state, (draft: UserState) => {
    draft.getUsers.isLoading = false;
    draft.getUsers.isLoaded = true;
    draft.getUsers.errors = error;
  });
}

export const getUsersReducers = {
  [`${getUsers}`]: getUsersReducer,
  [`${getUsersSuccess}`]: getUsersSuccessReducer,
  [`${getUsersFailure}`]: getUsersFailureReducer,
};
