import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getSurveyStatsFailure, getSurveyStatsSuccess } from './actions';
import { getSurveyStatsApi } from './api';
import { SagaAction } from 'type';

export const getSurveyStatsSaga = function* (
  action: SagaAction<string>
): SagaIterator {
  try {
    const data = yield call(getSurveyStatsApi(action.payload));

    yield put(getSurveyStatsSuccess(data));
  } catch (error) {
    yield put(getSurveyStatsFailure(error));
  }
};
