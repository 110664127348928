import produce from 'immer';

import {
  clearUploadBulkUrlCSV,
  uploadBulkUrlCSV,
  uploadBulkUrlCSVFailure,
  uploadBulkUrlCSVSuccess,
} from './actions';
import { uploadBulkUrlCSVInitialState } from './state';
import { UploadBulkUrlCSVResponse } from './type';
import { BulkUrlState } from '../type';
import { ErrorResponse } from 'type';

export function uploadBulkUrlCSVReducer(state: BulkUrlState): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.uploadBulkUrlCSV = { ...uploadBulkUrlCSVInitialState };
    draft.uploadBulkUrlCSV.isLoading = true;
  });
}

export function uploadBulkUrlCSVSuccessReducer(
  state: BulkUrlState,
  data: UploadBulkUrlCSVResponse
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.uploadBulkUrlCSV.isLoading = false;
    draft.uploadBulkUrlCSV.isLoaded = true;
    draft.uploadBulkUrlCSV.isValid = true;
    draft.uploadBulkUrlCSV.files = data.data.files;
  });
}

export function uploadBulkUrlCSVFailureReducer(
  state: BulkUrlState,
  error: ErrorResponse
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.uploadBulkUrlCSV.isLoading = false;
    draft.uploadBulkUrlCSV.isLoaded = true;
    draft.uploadBulkUrlCSV.error = error;
  });
}

export function clearUploadBulkUrlCSVReducer(
  state: BulkUrlState
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.uploadBulkUrlCSV = uploadBulkUrlCSVInitialState;
  });
}

export const uploadBulkUrlCSVReducers = {
  [`${uploadBulkUrlCSV}`]: uploadBulkUrlCSVReducer,
  [`${uploadBulkUrlCSVSuccess}`]: uploadBulkUrlCSVSuccessReducer,
  [`${uploadBulkUrlCSVFailure}`]: uploadBulkUrlCSVFailureReducer,
  [`${clearUploadBulkUrlCSV}`]: clearUploadBulkUrlCSVReducer,
};
