var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { getClass } from '../../_lib/helper';
import DataTableTh from './DataTableTh';
import DataTableBulkActionsButton from './DataTableBulkActionsButton';
import DataTableSort from './DataTableSort';
import { withDataTableContext } from '../DataTableContextConsumer';
import DataTableSelect from './DataTableSelect';
import DataTableCustomiseButton from './DataTableCustomiseButton';
import DataTableSelectAllCheckbox from './DataTableSelectAllCheckbox';
import './DataTableHeader.scss';
var DataTableHeader = /** @class */ (function (_super) {
    __extends(DataTableHeader, _super);
    function DataTableHeader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            displayColumns: [],
        };
        _this.updateDisplayColumns = function () {
            var displayColumns = _this.props.headerColumns.filter(function (c) {
                return !c.isHidden;
            });
            // get only the columns which should be displayed
            _this.setState({
                displayColumns: displayColumns,
            });
        };
        _this.updateContext = function () {
            var _a = _this.props, columns = _a.columns, setContextState = _a.setContextState, hasCustomise = _a.hasCustomise;
            setContextState({
                headerColumns: columns ? columns : [],
                showCustomiseModal: hasCustomise,
            });
        };
        /**
         * Called when Sort is clicked.
         * @param column
         */
        _this.handleSort = function (column) { return function () {
            var _a = _this.props, sortDirection = _a.sortDirection, sortColumn = _a.sortColumn, onSort = _a.onSort;
            var nextSortDirection = column !== sortColumn || !sortDirection || sortDirection === 'desc'
                ? 'asc'
                : 'desc';
            if (onSort) {
                onSort(column, nextSortDirection);
            }
        }; };
        _this.shouldShowSortIcon = function (columnIndex) {
            var sortColumn = _this.props.sortColumn;
            return sortColumn === columnIndex;
        };
        _this.shouldShowBulkActionsButton = function (columnIndex) {
            var _a = _this.props, isBulkActionOpen = _a.isBulkActionOpen, hasBulkActions = _a.hasBulkActions;
            return hasBulkActions && columnIndex === 0 && !isBulkActionOpen;
        };
        /**
         * Compute the colspan for each header column.
         * @param columnIndex
         */
        _this.computeColSpan = function (columnIndex) {
            var colSpanWithActions = 2;
            var colSpanNormal = 1;
            var hasRowActions = _this.props.hasRowActions;
            var displayColumns = _this.state.displayColumns;
            /**
             * If rows have an action column, then the colspan for the last column should be 2
             */
            var isLastColumn = columnIndex === displayColumns.length - 1;
            return hasRowActions && isLastColumn ? colSpanWithActions : colSpanNormal;
        };
        return _this;
    }
    DataTableHeader.prototype.componentDidMount = function () {
        this.updateContext();
        this.updateDisplayColumns();
    };
    DataTableHeader.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, columns = _a.columns, setContextState = _a.setContextState;
        // Update the columns in the DataTable context if columns have changed.
        if (prevProps.columns !== this.props.columns) {
            setContextState({
                headerColumns: columns,
            });
        }
        if (prevProps.headerColumns !== this.props.headerColumns) {
            this.updateDisplayColumns();
        }
    };
    /**
     * Renders the "Select All Items" Component
     */
    DataTableHeader.prototype.renderSelectHeader = function () {
        var headerColumns = this.props.headerColumns;
        return (React.createElement(DataTableTh, { colSpan: headerColumns.length },
            React.createElement(DataTableSelect, null)));
    };
    /**
     * Renders the Header Columns
     */
    DataTableHeader.prototype.renderColumns = function () {
        var _this = this;
        var _a = this.props, sortDirection = _a.sortDirection, onSort = _a.onSort, toggleBulkActionOpen = _a.toggleBulkActionOpen, showCustomiseModal = _a.showCustomiseModal;
        var displayColumns = this.state.displayColumns;
        if (!displayColumns) {
            return null;
        }
        return displayColumns.map(function (column, index) {
            var shouldShowBulkActions = _this.shouldShowBulkActionsButton(index);
            var shouldShowCustomiseButton = index === displayColumns.length - 1 && showCustomiseModal;
            var colSpan = _this.computeColSpan(index);
            return (React.createElement(DataTableTh, { key: index, width: column.width, colSpan: colSpan, textAlign: column.textAlign, className: getClass('', {
                    'bulk-actions': shouldShowBulkActions,
                    customise: shouldShowCustomiseButton,
                }) },
                shouldShowBulkActions && (React.createElement(DataTableBulkActionsButton, { toggleBulkActionOpen: toggleBulkActionOpen })),
                shouldShowCustomiseButton && React.createElement(DataTableCustomiseButton, null),
                React.createElement("div", __assign({ className: getClass('elmo-datatable__sort-wrapper', '', {
                        sortable: column.sortable,
                    }) }, (column.sortable && onSort
                    ? { onClick: _this.handleSort(index) }
                    : {})),
                    column.title,
                    React.createElement(DataTableSort, { direction: sortDirection, isHidden: !_this.shouldShowSortIcon(index) }))));
        });
    };
    DataTableHeader.prototype.render = function () {
        var _a = this.props, id = _a.id, className = _a.className, isBulkActionOpen = _a.isBulkActionOpen, hasBulkActions = _a.hasBulkActions, showSelectAllComponent = _a.showSelectAllComponent, onSelectAllVisibleToggle = _a.onSelectAllVisibleToggle;
        var displayColumns = this.state.displayColumns;
        if (!displayColumns) {
            return null;
        }
        return (React.createElement("thead", { id: id, className: getClass('elmo-datatable__head', className) },
            React.createElement("tr", null,
                hasBulkActions && isBulkActionOpen && (React.createElement(DataTableSelectAllCheckbox, { onSelectAllVisibleToggle: onSelectAllVisibleToggle })),
                showSelectAllComponent && this.renderSelectHeader(),
                !showSelectAllComponent && this.renderColumns())));
    };
    DataTableHeader.displayName = 'DataTableHeader';
    return DataTableHeader;
}(React.Component));
export default withDataTableContext(DataTableHeader);
