import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { createInviteFailure, createInviteSuccess } from './actions';
import { createInviteApi } from './api';

export const createInviteSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(
      createInviteApi(action.payload.collectorId, action.payload.data)
    );

    yield put(createInviteSuccess(data));
  } catch (error) {
    yield put(createInviteFailure(error));
  }
};
