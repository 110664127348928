import { Modal as ElmoModal, Loader } from 'elmo-elements';
import React from 'react';

import { loadingModalProps } from './type';

const LoadingModal = ({
  closeCallback,
  title,
  loaderType = 'list',
}: loadingModalProps) => {
  return (
    <>
      <ElmoModal
        id={'loading-dialog'}
        type={'large'}
        isOpened={true}
        closeModal={closeCallback}
        title={title}
      >
        <Loader type={loaderType} />
      </ElmoModal>
    </>
  );
};

export default LoadingModal;
