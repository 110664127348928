import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url =
  '/collectors/:collectorId/email-recipient-lists/:emailRecipientListId/reminder-messages';

export const createInviteReminderMessageApi = (
  collectorId: number,
  recipientListId: number,
  payload: any
) => {
  return postRequest(
    getRoute(url, {
      params: {
        collectorId: collectorId,
        emailRecipientListId: recipientListId,
      },
    }),
    payload
  );
};
