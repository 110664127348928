import { GetDefaultSenderState } from './type';

export const getDefaultSenderInitialState: GetDefaultSenderState = {
  data: {
    from_email: '',
    from_name: '',
  },
  isLoaded: false,
  isLoading: false,
  isValid: false,
};
