import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import {
  CreateSurveyTemplatePayload,
  CreateSurveyTemplateResponse,
} from './type';
import { ErrorResponse } from 'type';

export const createSurveyTemplate: ActionCreator<CreateSurveyTemplatePayload> =
  createAction('CREATE_SURVEY_TEMPLATE');

export const createSurveyTemplateSuccess: ActionCreator<CreateSurveyTemplateResponse> =
  createAction('CREATE_SURVEY_TEMPLATE_SUCCESS');

export const createSurveyTemplateFailure: ActionCreator<ErrorResponse> =
  createAction('CREATE_SURVEY_TEMPLATE_FAILURE');

export const clearCreateSurveyTemplate: EmptyActionCreator = createAction(
  'CLEAR_CREATE_SURVEY_TEMPLATE'
);
