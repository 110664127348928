import { GetIndividualResponsesState } from './type';
import { Query } from 'lib/stateTemplates';

export const getIndividualResponsesInitialState: GetIndividualResponsesState = {
  isLoaded: false,
  isLoading: false,
  isValid: false,
  count: 0,
  surveyId: 0,
  query: Query,
  data: [],
};
