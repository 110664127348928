import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetCollectorsResponse } from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const updateCollectorsQuery: ActionCreator<Query> = createAction(
  'UPDATE_GET_COLLECTORS_QUERY'
);

export const getCollectors: ActionCreator<number> =
  createAction('GET_COLLECTORS');

export const getCollectorsSuccess: ActionCreator<GetCollectorsResponse> =
  createAction('GET_COLLECTORS_SUCCESS');

export const getCollectorsFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_COLLECTORS_FAILURE'
);

export const clearGetCollectors: EmptyActionCreator = createAction(
  'CLEAR_GET_COLLECTORS'
);
