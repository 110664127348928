import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getAdminBenchmarkDataFailure,
  getAdminBenchmarkDataSuccess,
} from './actions';
import { getAdminBenchmarkDataApi } from './api';

export const getAdminBenchmarkDataSaga = function* (): SagaIterator {
  try {
    const data = yield call(getAdminBenchmarkDataApi());

    yield put(getAdminBenchmarkDataSuccess(data));
  } catch (error) {
    yield put(getAdminBenchmarkDataFailure(error));
  }
};
