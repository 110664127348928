const translations = {
  listSchedule: {
    key: '',
    default: 'Invite schedule',
  },
  listSent: {
    key: '',
    default: 'Invite sent',
  },
  sendNow: {
    key: '',
    default: 'Send Now',
  },
};

export default translations;
