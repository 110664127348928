import { createAction } from 'redux-act';

export const sendInviteMessage: any = createAction('SEND_INVITE_MESSAGE');

export const sendInviteMessageSuccess: any = createAction(
  'SEND_INVITE_MESSAGE_SUCCESS'
);

export const sendInviteMessageFailure: any = createAction(
  'SEND_INVITE_MESSAGE_FAILURE'
);

export const clearSendInviteMessage: any = createAction(
  'CLEAR_SEND_INVITE_MESSAGE'
);
