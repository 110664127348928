import produce from 'immer';

import {
  getDefaultSender,
  getDefaultSenderFailure,
  getDefaultSenderSuccess,
} from './actions';
import { getDefaultSenderInitialState } from './state';
import { GetDefaultSenderResponse } from './type';
import { ErrorResponse } from 'type';
import { AppState } from '../type';

export function getDefaultSenderReducer(state: AppState): AppState {
  return produce(state, (draft: AppState) => {
    draft.getDefaultSender = { ...getDefaultSenderInitialState };
    draft.getDefaultSender.isLoading = true;
  });
}

export function getDefaultSenderSuccessReducer(
  state: AppState,
  data: GetDefaultSenderResponse
): AppState {
  return produce(state, (draft: AppState) => {
    draft.getDefaultSender.isLoaded = true;
    draft.getDefaultSender.isLoading = false;
    draft.getDefaultSender.isValid = true;
    draft.getDefaultSender.data = data.data;
  });
}

export function getDefaultSenderFailureReducer(
  state: AppState,
  error: ErrorResponse
): AppState {
  return produce(state, (draft: AppState) => {
    draft.getDefaultSender.isLoaded = true;
    draft.getDefaultSender.isLoading = false;
    draft.getDefaultSender.error = error;
  });
}

export const getDefaultSenderReducers = {
  [`${getDefaultSender}`]: getDefaultSenderReducer,
  [`${getDefaultSenderSuccess}`]: getDefaultSenderSuccessReducer,
  [`${getDefaultSenderFailure}`]: getDefaultSenderFailureReducer,
};
