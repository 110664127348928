import { isSharedReport } from 'lib/helpers/sharedReports';
import { GetCollectorFilterOptionsPayload } from './type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId/collector';

export const getCollectorFilterOptionsApi = ({
  surveyId,
  searchText,
}: GetCollectorFilterOptionsPayload): any => {
  if (isSharedReport()) {
    return Promise.resolve([]);
  }

  return getRequest(
    getRoute(apiUrl, {
      params: { surveyId: surveyId },
      query: {
        excludeBulkURL: true,
        limit: 100,
        searchText: searchText,
      },
    })
  );
};
