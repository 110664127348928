import produce from 'immer';

import {
  createEmailTemplate,
  clearCreateEmailTemplate,
  createEmailTemplateFailure,
  createEmailTemplateSuccess,
} from './actions';
import { emailTemplateInitialState } from '../state';
import { EmailTemplateState } from '../type';
import { ErrorResponse } from 'type';

export function createEmailTemplateReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.createEmailTemplate.isUpdating = true;
    draft.createEmailTemplate.isUpdated = false;
    draft.createEmailTemplate.isValid = false;
  });
}

export function createEmailTemplateSuccessReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.createEmailTemplate.isUpdating = false;
    draft.createEmailTemplate.isUpdated = true;
    draft.createEmailTemplate.isValid = true;
  });
}

export function createEmailTemplateFailureReducer(
  state: EmailTemplateState,
  errors: ErrorResponse
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.createEmailTemplate.isUpdating = false;
    draft.createEmailTemplate.isUpdated = true;
    draft.createEmailTemplate.errors = errors;
  });
}

export function clearCreateEmailTemplateReducer(
  state: EmailTemplateState
): EmailTemplateState {
  return produce(state, (draft: EmailTemplateState) => {
    draft.createEmailTemplate = emailTemplateInitialState.createEmailTemplate;
  });
}

export const createEmailTemplateReducers = {
  [`${createEmailTemplate}`]: createEmailTemplateReducer,
  [`${createEmailTemplateSuccess}`]: createEmailTemplateSuccessReducer,
  [`${createEmailTemplateFailure}`]: createEmailTemplateFailureReducer,
  [`${clearCreateEmailTemplate}`]: clearCreateEmailTemplateReducer,
};
