import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/invite-messages/:inviteMessageId';

export const updateInviteMessageScheduleApi = (
  inviteMessageId: number,
  scheduleDate: string
) => {
  return putRequest(getRoute(url, { params: { inviteMessageId } }), {
    scheduleDate,
  });
};
