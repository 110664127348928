import produce from 'immer';

import { GetAnonSurveyThemeResponse, ThemeState } from '../type';
import { transformAnonTheme } from '../../../transformers';
import { getAnonSurveyThemeInitialState } from './state';
import * as actions from './actions';

export function getAnonSurveyThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getAnonSurveyTheme.isLoading = true;
    draft.getAnonSurveyTheme.isLoaded = false;
    draft.getAnonSurveyTheme.isValid = false;
    draft.getAnonSurveyTheme.error = undefined;
  });
}

export function getAnonSurveyThemeSuccessReducer(
  state: ThemeState,
  payload: GetAnonSurveyThemeResponse
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getAnonSurveyTheme.isLoading = false;
    draft.getAnonSurveyTheme.isLoaded = true;
    draft.getAnonSurveyTheme.isValid = true;
    draft.getAnonSurveyTheme.theme = transformAnonTheme(payload.data);
  });
}

export function getAnonSurveyThemeFailureReducer(
  state: ThemeState,
  error: any
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getAnonSurveyTheme.isLoading = false;
    draft.getAnonSurveyTheme.isLoaded = true;
    draft.getAnonSurveyTheme.error = error;
  });
}

export function clearGetAnonSurveyThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getAnonSurveyTheme = getAnonSurveyThemeInitialState;
  });
}

export const getAnonSurveyThemeReducers = {
  [`${actions.getAnonSurveyTheme}`]: getAnonSurveyThemeReducer,
  [`${actions.getAnonSurveyThemeSuccess}`]: getAnonSurveyThemeSuccessReducer,
  [`${actions.getAnonSurveyThemeFailure}`]: getAnonSurveyThemeFailureReducer,
  [`${actions.clearGetAnonSurveyTheme}`]: clearGetAnonSurveyThemeReducer,
};
