import { UploadBulkUrlCSVPayload } from './type';
import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/bulkurl-collector/:collectorId/upload-csv';

export const uploadBulkUrlCSVApi = (payload: UploadBulkUrlCSVPayload): any => {
  return postRequest(
    getRoute(apiUrl, {
      params: {
        collectorId: payload.collectorId,
      },
    }),
    payload.file
  );
};
