var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { mapPropsToClass } from './api';
import './Col.scss';
function Col(_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var className = mapPropsToClass('elmo-col', props);
    var role = props.role, key = props.key;
    return (React.createElement("div", { className: className, key: key, role: role }, children));
}
Col.defaultProps = {
    isAuto: false,
    isFill: false,
    role: 'cell',
};
export default Col;
