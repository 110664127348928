import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId';

export const updateSurveyApi = (surveyId: number, payload: any): any => {
  return putRequest(
    getRoute(apiUrl, { params: { surveyId: surveyId } }),
    payload
  );
};
