import React, { ReactElement } from 'react';
import { Loader } from 'elmo-elements';

import { LoaderOverlayProps } from './type';
import './style.scss';

const LoaderOverlay = ({ children }: LoaderOverlayProps): ReactElement => {
  return (
    <div className="loader-overlay">
      <div className="elmo-loader-overlay__bg" tabIndex={-1} />
      <div className="elmo-loader-overlay__content">
        <Loader type="spinner" />
        {children}
      </div>
    </div>
  );
};

export default LoaderOverlay;
