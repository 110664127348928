import React from 'react';
import Dropdown from '../../Dropdown';
import './PaginationDropdown.scss';
import { PAGINATION_OPTIONS } from '../../_lib/const';
function PaginationDropdown(_a) {
    var pageSize = _a.pageSize, pageSizeOptions = _a.pageSizeOptions, onPageSizeChange = _a.onPageSizeChange;
    return (React.createElement(Dropdown, { text: pageSize, isInline: true, ariaLabel: "Menu of the number of displayed records" }, pageSizeOptions &&
        pageSizeOptions.map(function (pageSizeOption, key) {
            return (React.createElement(Dropdown.Item, { key: key, onClick: onPageSizeChange, value: pageSizeOption }, pageSizeOption));
        })));
}
PaginationDropdown.defaultProps = {
    pageSizeOptions: PAGINATION_OPTIONS,
};
export default PaginationDropdown;
