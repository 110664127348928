import { takeEvery } from 'redux-saga/effects';

import {
  createQuestion,
  updateQuestion,
  getQuestions,
  getQuestionsFromQuery,
  deleteQuestion,
  getQuestion,
} from './actions';
import {
  getQuestionsSaga,
  getQuestionsFromQuerySaga,
} from './getQuestions/sagas';
import { deleteQuestionSaga } from './deleteQuestion/sagas';
import { createQuestionSaga } from './createQuestion/sagas';
import { updateQuestionSaga } from './updateQuestion/sagas';
import { getQuestionSaga } from './getQuestion/sagas';

export default function* sagas() {
  yield takeEvery(createQuestion, createQuestionSaga);
  yield takeEvery(updateQuestion, updateQuestionSaga);
  yield takeEvery(getQuestion, getQuestionSaga);
  yield takeEvery(getQuestions, getQuestionsSaga);
  yield takeEvery(getQuestionsFromQuery, getQuestionsFromQuerySaga);
  yield takeEvery(deleteQuestion, deleteQuestionSaga);
}
