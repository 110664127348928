const translations = {
  sectionTitle: {
    key: '',
    default: 'Participants',
  },
  addButton: {
    key: '',
    default: 'Add Participants',
  },
  recipientsType: {
    key: '',
    default: 'Contact by',
  },
  recipientCount: {
    key: '',
    default: 'No. of Participants',
  },
  manualInput: {
    key: '',
    default: 'Email address(es)',
  },
  tmsProfileSelect: {
    key: '',
    default: 'My organisation',
  },
  csvUpload: {
    key: '',
    default: 'CSV Upload',
  },
};

export default translations;
