export default {
  title: {
    update: {
      key: 'EmailInvites.messages.edit.title.update',
      default: 'Edit Invite Message',
    },
    add: {
      key: 'EmailInvites.messages.edit.title.add',
      default: 'Create Invite Message',
    },
  },
  cancel: {
    key: 'EmailInvites.messages.edit.cancel',
    default: 'Cancel',
  },
  save: {
    key: 'EmailInvites.messages.edit.save',
    default: 'Save Message',
  },
  formLabels: {
    subject: {
      key: 'EmailInvites.messages.edit.formLabels.subject',
      default: 'Subject',
    },
    htmlContent: {
      key: 'EmailInvites.messages.edit.formLabels.htmlContent',
      default: 'Content',
    },
    textContent: {
      key: 'EmailInvites.messages.edit.formLabels.textContent',
      default: 'Content (Plain Text)',
    },
    messageTitle: {
      key: 'EmailInvites.messages.edit.formLabels.messageTitle',
      default: 'Message title',
    },
    fromEmail: {
      key: 'EmailInvites.messages.edit.formLabels.fromEmail',
      default: `Sender's email address`,
    },
    fromName: {
      key: 'EmailInvites.messages.edit.formLabels.fromName',
      default: `Sender's name`,
    },
  },
  notifications: {
    success: {
      update: {
        key: 'EmailInvites.messages.edit.notifications.success.update',
        default: 'Message updated successfully.',
      },
      add: {
        key: 'EmailInvites.messages.edit.notifications.success.add',
        default: 'Message added successfully.',
      },
    },
  },
};
