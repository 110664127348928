import React from 'react';
import { MoreHorizIcon as ActionsIcon } from '../../Icon';
import Dropdown from '../../Dropdown';
import DataTableTd from './DataTableTd';
import './DataTableActions.scss';
/**
 * Wraps Dropdown to render the actions available for each row in the DataTable
 * @constructor
 */
function DataTableActions(_a) {
    var actions = _a.actions, isInline = _a.isInline;
    if (!actions) {
        return null;
    }
    return (React.createElement(DataTableTd, { className: "elmo-datatable__row-actions" },
        React.createElement(Dropdown, { icon: React.createElement(ActionsIcon, null), isInline: isInline, position: 'bottom-end', ariaLabel: "Actions" }, actions &&
            actions.map(function (action, index) {
                if (action.isDivider) {
                    return React.createElement(Dropdown.Divider, { key: index });
                }
                var dropdownAction = action;
                return (React.createElement(Dropdown.Item, { key: index, ariaLabel: dropdownAction.ariaLabel, onClick: dropdownAction.onClick, value: index, isDisabled: dropdownAction.isDisabled },
                    dropdownAction.icon,
                    " ",
                    dropdownAction.label));
            }))));
}
export default DataTableActions;
