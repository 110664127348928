import { Outlet } from 'react-router-dom';
import React from 'react';

import './SurveyLayout.scss';

const SurveyLayout = () => {
  return (
    <div id={'survey-layout-wrapper'}>
      <div id={'survey-layout'}>
        <Outlet />
      </div>
    </div>
  );
};

export default SurveyLayout;
