const sendEvent = (
  eventName: string,
  payload?: any,
  eventType?: string
): void => {
  return;
};

const increaseProfileProperty = (key: string, increment: number): void => {
  return;
};

const setProfileProperty = (key: string, value: any) => {
  return;
};

const timeEvent = (eventName: string) => {
  return;
};

const setAnonymous = () => {
  return { sendEvent, increaseProfileProperty, setProfileProperty, timeEvent };
};

export const analytics = {
  sendEvent,
  increaseProfileProperty,
  setProfileProperty,
  timeEvent,
  anonymous: setAnonymous,
};
