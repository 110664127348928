import { takeEvery } from 'redux-saga/effects';

import {
  createSurveyNotification,
  getSurveyNotifications,
  getSurveyNotification,
  updateSurveyNotification,
  getSurveyNotificationHistory,
} from './actions';
import { getSurveyNotificationHistorySaga } from './getSurveyNotificationHistory/sagas';
import { createSurveyNotificationSaga } from './createSurveyNotification/sagas';
import { updateSurveyNotificationSaga } from './updateSurveyNotification/sagas';
import { getSurveyNotificationsSaga } from './getSurveyNotifications/sagas';
import { getSurveyNotificationSaga } from './getSurveyNotification/sagas';

export default function* sagas() {
  yield takeEvery(createSurveyNotification, createSurveyNotificationSaga);
  yield takeEvery(getSurveyNotification, getSurveyNotificationSaga);
  yield takeEvery(
    getSurveyNotificationHistory,
    getSurveyNotificationHistorySaga
  );
  yield takeEvery(getSurveyNotifications, getSurveyNotificationsSaga);
  yield takeEvery(updateSurveyNotification, updateSurveyNotificationSaga);
}
