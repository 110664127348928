import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { SaveOverviewReportForPDFPayload } from './type';
import { ErrorResponse } from 'type';

export const saveOverviewReportForPdf: ActionCreator<SaveOverviewReportForPDFPayload> =
  createAction('SAVE_OVERVIEW_REPORT_FOR_PDF');

export const saveOverviewReportForPdfSuccess: ActionCreator<any> = createAction(
  'SAVE_OVERVIEW_REPORT_FOR_PDF_SUCCESS'
);

export const saveOverviewReportForPdfFailure: ActionCreator<ErrorResponse> =
  createAction('SAVE_OVERVIEW_REPORT_FOR_PDF_FAILURE');

export const clearSaveOverviewReportForPdf: EmptyActionCreator = createAction(
  'CLEAR_SAVE_OVERVIEW_REPORT_FOR_PDF'
);
