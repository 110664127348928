var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { Heading, Paragraph, Text } from '../Typography';
import Badge from '../Badge';
import { getClass } from '../_lib/helper';
import './Card.scss';
var Card = /** @class */ (function (_super) {
    __extends(Card, _super);
    function Card() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function () {
            var onClick = _this.props.onClick;
            if (onClick) {
                onClick();
            }
            return false;
        };
        _this.handleKeyPress = function (e) {
            if (e.key === 'Enter') {
                _this.handleClick();
            }
        };
        return _this;
    }
    Card.prototype.renderBadges = function () {
        var _a = this.props, id = _a.id, badges = _a.badges;
        var keyTemplate = 'card-badge-' + id + '-';
        return (badges &&
            badges.map(function (badge, index) {
                return (React.createElement(Badge, { icon: badge.icon, type: badge.type, role: badge.role, key: keyTemplate + index, "data-test-id": keyTemplate + index }, badge.children));
            }));
    };
    Card.prototype.render = function () {
        var _a = this.props, id = _a.id, heading = _a.heading, children = _a.children, description = _a.description, className = _a.className, isFullHeight = _a.isFullHeight, option = _a.option, prefix = _a.prefix, image = _a.image, imageAlt = _a.imageAlt, badges = _a.badges, onClick = _a.onClick, role = _a.role, fullWidthHeader = _a.fullWidthHeader;
        var backgroundImage = image
            ? { backgroundImage: 'url(' + image + ')' }
            : undefined;
        var shouldShowHeaderImage = image || badges || fullWidthHeader;
        return (React.createElement("div", { role: role, id: id, "data-testid": "elmo-card-" + (id || 'default'), className: getClass('elmo-card', className, {
                'is-full-height': isFullHeight,
                'is-clickable': onClick,
            }), onClick: onClick ? this.handleClick : undefined, onKeyPress: this.handleKeyPress, tabIndex: onClick ? 0 : undefined },
            shouldShowHeaderImage && (React.createElement("div", { className: "elmo-card__header-image", title: image && imageAlt, style: __assign({}, backgroundImage), role: "img", "aria-label": image && imageAlt },
                fullWidthHeader && React.createElement("div", null, fullWidthHeader),
                React.createElement("div", { className: "elmo-card__header-badges" }, this.renderBadges()))),
            (prefix || heading || description || option) && (React.createElement("div", { className: "elmo-card__header" },
                option && React.createElement("div", { className: "elmo-card__option" }, option),
                heading && (React.createElement(Heading, { className: "elmo-card__heading" },
                    prefix && (React.createElement(Text, { size: "sm", color: "gray", htmlTag: "small", className: "d-block mb-2 elmo-card__heading-prefix" }, prefix)),
                    heading)),
                description && React.createElement(Paragraph, null, description))),
            React.createElement("div", { className: "elmo-card__content" },
                React.createElement("div", { className: "elmo-card__content-inner" }, children))));
    };
    return Card;
}(Component));
export default Card;
