var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import StyledText from './style';
import { getClass } from '../../_lib/helper';
import '../Typography.scss';
export function mapPropsToClass(block, props) {
    var _a;
    var isBold = props.isBold, isItalic = props.isItalic, decoration = props.decoration, transform = props.transform, color = props.color, size = props.size, className = props.className;
    return getClass(block, (_a = {},
        _a["" + className] = className,
        _a["font-weight-bold"] = isBold,
        _a["font-italic"] = isItalic,
        _a["text-decoration-" + decoration] = decoration,
        _a["text-transform-" + transform] = transform,
        _a["text-size-" + size] = size,
        _a["text-color-" + color] = color,
        _a), []);
}
function Text(_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var id = props.id, htmlTag = props.htmlTag;
    var StyledTextComponent = StyledText(htmlTag);
    return (React.createElement(StyledTextComponent, { id: id, "data-testid": "elmo-typography-" + (id || 'default'), className: mapPropsToClass('elmo-typography', props) }, children));
}
Text.defaultProps = {
    htmlTag: 'span',
    isBold: false,
    isItalic: false,
    isTruncate: false,
};
export default Text;
