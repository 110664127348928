import React from 'react';
import './PlaceholderMobileMenu.scss';
import Loader from '../../Loader';
/**
 * Placeholder for mobile menu menu
 */
function PlaceholderMobileMenu(_a) {
    var id = _a.id;
    return (React.createElement("div", { className: "elmo-nav-header-mobile elmo-nav-header-mobile--placeholder", id: id, "data-testid": "elmo-nav-mobile-menu-placeholder-" + (id || 'default') },
        React.createElement("div", { className: "elmo-nav-header-mobile__icon" },
            React.createElement(Loader, { type: "avatar", width: 30 })),
        React.createElement("div", { className: "elmo-nav-header-mobile__logo" },
            React.createElement(Loader, { type: "icon" }))));
}
export default PlaceholderMobileMenu;
