import { createAction } from 'redux-act';

export const getInviteRecipients: any = createAction('GET_INVITE_RECIPIENTS');

export const getInviteRecipientsSuccess: any = createAction(
  'GET_INVITE_RECIPIENTS_SUCCESS'
);

export const getInviteRecipientsFailure: any = createAction(
  'GET_INVITE_RECIPIENTS_FAILURE'
);

export const updateGetInviteRecipientsQuery: any = createAction(
  'UPDATE_GET_INVITE_RECIPIENTS_QUERY'
);

export const clearGetInviteRecipients: any = createAction(
  'CLEAR_GET_INVITE_RECIPIENTS'
);
