var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import Pagination from '../../Pagination';
var DataTablePagination = /** @class */ (function (_super) {
    __extends(DataTablePagination, _super);
    function DataTablePagination() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onPageChange = function (pageNumber, startItem, endItem) {
            var _a = _this.props, onPageChange = _a.onPageChange, onPageUpdate = _a.onPageUpdate;
            if (onPageUpdate) {
                onPageUpdate(); // call DataTable to uncheck Select All checkbox
            }
            onPageChange(pageNumber, startItem, endItem);
        };
        _this.onPageSizeChange = function (pageSize) {
            var _a = _this.props, onPageSizeChange = _a.onPageSizeChange, onPageUpdate = _a.onPageUpdate;
            if (onPageUpdate) {
                onPageUpdate(); // call DataTable to uncheck Select All checkbox
            }
            onPageSizeChange(pageSize);
        };
        return _this;
    }
    DataTablePagination.prototype.render = function () {
        return (React.createElement(Pagination, __assign({}, this.props, { onPageChange: this.onPageChange, onPageSizeChange: this.onPageSizeChange })));
    };
    DataTablePagination.displayName = 'DataTablePagination';
    return DataTablePagination;
}(Component));
export default DataTablePagination;
