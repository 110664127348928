import { RouteObject } from 'react-router-dom';
import React from 'react';

import NotificationHistory from './pages/NotificationHistory';
import CreateNotification from './pages/CreateNotification';
import EditNotification from './pages/EditNotification';
import Notifications from './pages/NotificationsList';

const routes: RouteObject = {
  path: '/surveys/:surveyId/notifications',
  children: [
    {
      path: '/surveys/:surveyId/notifications/create',
      element: <CreateNotification />,
    },
    {
      path: '/surveys/:surveyId/notifications/:notificationId/edit',
      element: <EditNotification />,
    },
    {
      path: '/surveys/:surveyId/notifications/:notificationId/history',
      element: <NotificationHistory />,
    },
    {
      path: '/surveys/:surveyId/notifications',
      element: <Notifications />,
    },
  ],
};

export default routes;
