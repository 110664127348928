import {
  getAvailableUrls,
  getAvailableUrlsSuccess,
  getAvailableUrlsFailure,
  getAvailableUrlsQuery,
  getAvailableUrlsQuerySuccess,
  getAvailableUrlsQueryFailure,
  getAvailableUrl,
  getAvailableUrlSuccess,
  getAvailableUrlFailure,
} from '../actions';
import { Query } from '../../../../lib/stateTemplates';

export function getAvailableUrlsReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      availableUrls: {
        list: {
          ...state.bulkUrls.availableUrls.list,
          isLoaded: false,
          isLoading: true,
          count: 0,
          data: [],
        },
      },
    },
  };
}

export function getAvailableUrlsSuccessReducer(state: any, data: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      availableUrls: {
        list: {
          ...state.bulkUrls.availableUrls.list,
          isLoaded: true,
          isLoading: false,
          count: data.count,
          data: data.data,
        },
      },
    },
  };
}

export function getAvailableUrlsFailureReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      availableUrls: {
        list: {
          ...state.bulkUrls.availableUrls.list,
          isLoaded: false,
          isLoading: false,
          count: 0,
          data: [],
        },
      },
    },
  };
}

export function getAvailableUrlReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      generateCheck: {
        ...state.bulkUrls.generateCheck,
        isLoading: true,
        isLoaded: false,
      },
    },
  };
}

export function getAvailableUrlSuccessReducer(state: any, data: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      generateCheck: {
        ...state.bulkUrls.generateCheck,
        isLoading: false,
        isLoaded: true,
        data: data,
      },
    },
  };
}

export function getAvailableUrlFailureReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      generateCheck: {
        ...state.bulkUrls.generateCheck,
        isLoading: false,
        isLoaded: false,
      },
    },
  };
}

export function getAvailableUrlsQueryReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      availableUrls: {
        list: {
          ...state.bulkUrls.availableUrls.list,
          query: Query,
        },
      },
    },
  };
}

export function getAvailableUrlsQuerySuccessReducer(state: any, data: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      availableUrls: {
        list: {
          ...state.bulkUrls.availableUrls.list,
          query: data,
        },
      },
    },
  };
}

export function getAvailableUrlsQueryFailureReducer(state: any) {
  return {
    ...state,
    bulkUrls: {
      ...state.bulkUrls,
      availableUrls: {
        list: {
          ...state.bulkUrls.availableUrls.list,
          query: Query,
        },
      },
    },
  };
}

export const mySurveyBulkUrlsAvailableUrlsReducers = {
  [`${getAvailableUrls}`]: getAvailableUrlsReducer,
  [`${getAvailableUrlsSuccess}`]: getAvailableUrlsSuccessReducer,
  [`${getAvailableUrlsFailure}`]: getAvailableUrlsFailureReducer,
  [`${getAvailableUrlsQuery}`]: getAvailableUrlsQueryReducer,
  [`${getAvailableUrlsQuerySuccess}`]: getAvailableUrlsQuerySuccessReducer,
  [`${getAvailableUrlsQueryFailure}`]: getAvailableUrlsQueryFailureReducer,
  [`${getAvailableUrl}`]: getAvailableUrlReducer,
  [`${getAvailableUrlSuccess}`]: getAvailableUrlSuccessReducer,
  [`${getAvailableUrlFailure}`]: getAvailableUrlFailureReducer,
};
