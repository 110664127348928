import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { ReactElement, ReactNode } from 'react';
import { Card } from 'elmo-elements';

import './CardButton.scss';

export type CardButtonProps = {
  id?: string;
  icon: ReactNode;
  heading: string;
  description?: string;
  isActive?: boolean;
  doAction: () => void;
};

const CardButton = ({
  id,
  icon,
  heading,
  description = '',
  isActive = false,
  doAction,
}: CardButtonProps): ReactElement => {
  return (
    <>
      {isActive && (
        <CheckCircleIcon className={'card-active-icon'} fontSize={'large'} />
      )}
      <Card
        onClick={doAction}
        id={id}
        className={`card-button ${isActive === true ? 'card-active' : ''}`}
      >
        <div className="elmo-card__header-image" role="img">
          {icon}
        </div>
        <div className="elmo-card__header">
          <h3
            data-testid="elmo-typography-default"
            className="elmo-typography elmo-card__heading heading-size-rg sc-bxivhb bUZGRr"
          >
            {heading}
          </h3>
        </div>
        <div className="elmo-card__content">
          <div className="elmo-card__content-inner">{description}</div>
        </div>
      </Card>
    </>
  );
};

export default CardButton;
