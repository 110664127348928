/* eslint-disable max-len */
import React from 'react';
function Templates() {
    var inlineStyle = '.st0{opacity:   0.87;} .st1{fill - opacity:0.87;}';
    return (React.createElement("svg", { version: "1.1", id: "Layer_1", viewBox: "0 0 24 24", className: "elmo-icon__svg" },
        React.createElement("style", { type: "text/css" }, inlineStyle),
        React.createElement("polygon", { className: "st0", points: "11.8,13.7 9.3,16.2 8.2,15.1 7.2,16.2 8.2,17.3 8.2,17.3 9.3,18.3 10.4,17.3 10.4,17.3 12.8,14.8 " }),
        React.createElement("polygon", { className: "st0", points: "11.8,9.7 9.3,12.2 8.2,11.1 7.2,12.2 8.2,13.3 8.2,13.3 9.3,14.3 10.4,13.3 10.4,13.3 12.8,10.8 " }),
        React.createElement("path", { className: "st1", d: "M20.8,10.3c-0.5-0.5-1.1-0.5-1.5-0.1l-0.7,0.7l1.5,1.5l0.7-0.7C21.2,11.3,21.2,10.7,20.8,10.3z" }),
        React.createElement("polygon", { className: "st1", points: "13,16.5 13,18 14.5,18 19.4,13.1 17.9,11.6 " }),
        React.createElement("g", null,
            React.createElement("rect", { x: "4", y: "14", className: "st1", width: "2", height: "2" }),
            React.createElement("polygon", { className: "st1", points: "12,4 13,4 13,9 17.5,9 19.2,7.2 14,2 12,2 \t" }),
            React.createElement("rect", { x: "4", y: "6", className: "st1", width: "2", height: "2" }),
            React.createElement("rect", { x: "4", y: "10", className: "st1", width: "2", height: "2" }),
            React.createElement("rect", { x: "8", y: "2", className: "st1", width: "2", height: "2" }),
            React.createElement("path", { className: "st1", d: "M18,17.3V20H6v-2H4v2c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4.7L18,17.3z" }),
            React.createElement("path", { className: "st1", d: "M6,2C4.9,2,4,2.9,4,4h2V2z" }))));
}
export default Templates;
