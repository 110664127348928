import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getBulkUrlFailure, getBulkUrlSuccess } from './actions';
import { GetBulkUrlPayload } from './type';
import { getBulkUrlApi } from './api';
import { SagaAction } from 'type';

export const getBulkUrlSaga = function* (
  action: SagaAction<GetBulkUrlPayload>
): SagaIterator {
  try {
    const data = yield call(getBulkUrlApi(action.payload));

    yield put(getBulkUrlSuccess(data));
  } catch (error) {
    yield put(getBulkUrlFailure(error));
  }
};
