import { getDefaultSenderInitialState } from './getDefaultSender/state';
import { initializeAppInitialState } from './initializeApp/state';
import { notificationInitialState } from './notification/state';
import { getSiteThemeInitialState } from './getSiteTheme/state';
import { getMenuInitialState } from './getMenu/state';
import { AppState } from './type';

export const appInitialState: AppState = {
  getMenu: getMenuInitialState,
  initializeApp: initializeAppInitialState,
  notification: notificationInitialState,
  getDefaultSender: getDefaultSenderInitialState,
  getSiteTheme: getSiteThemeInitialState,
};
