export default {
  sendButton: {
    key: 'element.inviteMessageSummary.send',
    default: 'Send',
  },
  labels: {
    subject: {
      key: 'element.inviteMessageSummary.labels.subject',
      default: 'Subject',
    },
    header: {
      key: 'element.inviteMessageSummary.labels.header',
      default: 'Header',
    },
    content: {
      key: 'element.inviteMessageSummary.labels.content',
      default: 'Content',
    },
    from_name: {
      key: 'element.inviteMessageSummary.labels.from_name',
      default: `Sender's name`,
    },
    from_email: {
      key: 'element.inviteMessageSummary.labels.from_email',
      default: `Sender's email address`,
    },
  },
};
