import includes from 'lodash/includes';
import values from 'lodash/values';

import { decodeToken, getToken } from '../token';
import { Survey } from 'type';

export type JwtObject = {
  branding: object;
  capabilities: {
    survey?: any;
  };
  exp: number;
  feature_flags: object;
  host: string;
  iat: number;
  industry_settings: object;
  navigation_bar: string;
  roles: any;
  user_id: number;
  user_menu: string;
  username: string;
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
};

class AuthAccess {
  /**
   * Get current user JWT decoded
   */
  get currentUser(): JwtObject | null {
    const token = getToken();

    if (!token) {
      return null;
    }

    return decodeToken(token);
  }

  /**
   * Check if current user is Super Admin
   */
  public isSuperAdmin(): boolean {
    if (
      this.currentUser &&
      this.currentUser.roles.indexOf('ROLE_ELMO_SUPER') !== -1
    ) {
      return true;
    }

    return false;
  }

  /**
   * Check if current user is Company Admin
   */
  public isCompanyAdmin(): boolean {
    if (
      this.currentUser &&
      this.currentUser.roles.indexOf('ROLE_SUPER') !== -1
    ) {
      return true;
    }

    return false;
  }

  /**
   * Check if current user is Survey Admin
   */
  public isSurveyAdmin(): boolean {
    if (
      this.currentUser &&
      this.currentUser.roles.indexOf('ROLE_SURVEY_ADMIN') !== -1
    ) {
      return true;
    }

    return false;
  }

  /**
   * Check if current user is Survey Creator
   */
  public isSurveyCreator(): boolean {
    if (
      this.currentUser &&
      this.currentUser.roles.indexOf('ROLE_SURVEY_CREATOR') !== -1
    ) {
      return true;
    }

    return false;
  }

  /**
   * Check if current user is a member of the PM team
   */
  public isPMUser(): boolean {
    if (
      this.currentUser &&
      this.currentUser.roles.indexOf('ROLE_PEOPLEPULSE_PM_TEAM') !== -1
    ) {
      return true;
    }

    return false;
  }

  /**
   * Check if current user is "logged in as" someone else
   */
  public isImpersonator(): boolean {
    if (
      this.currentUser &&
      this.currentUser.roles.indexOf('ROLE_PREVIOUS_ADMIN') !== -1
    ) {
      return true;
    }

    return false;
  }

  public isELMOSurveyAdmin(): boolean {
    if (
      this.currentUser &&
      this.currentUser.roles.indexOf('ROLE_ELMO_SURVEY_ADMIN') !== -1
    ) {
      return true;
    }

    return false;
  }

  /**
   * Check if current user has Survey Module access
   */
  public hasSurveyModuleAccess(): boolean {
    if (
      this.isSurveyCreator() ||
      this.isSurveyAdmin() ||
      this.isSuperAdmin() ||
      this.isCompanyAdmin() ||
      this.hasCapability('report.survey2.view')
    ) {
      return true;
    }

    return false;
  }

  public hasCapability(capability: string): boolean {
    if (!this.currentUser) {
      return false;
    }

    return includes(values(this.currentUser.capabilities.survey), capability);
  }

  /**
   * Check current user is not a Survey Creator
   */
  isNotSurveyCreator(): boolean {
    return this.isSurveyAdmin() || this.isSuperAdmin() || this.isCompanyAdmin();
  }

  public canCreateAndEditTemplates(): boolean {
    if (
      this.isCompanyAdmin() ||
      this.isSuperAdmin() ||
      this.isSurveyAdmin() ||
      this.isSurveyCreator() ||
      this.hasCapability('admin.survey2.template.edit')
    ) {
      return true;
    }

    return false;
  }

  public canManageGlobalItems(): boolean {
    if (
      this.currentUser &&
      this.currentUser.capabilities.survey &&
      this.currentUser.capabilities.survey.hasOwnProperty('admin')
    ) {
      return this.currentUser.capabilities.survey.admin;
    }

    return false;
  }

  public isOnlyReportViewer(): boolean {
    if (
      this.isSurveyCreator() ||
      this.isSurveyAdmin() ||
      this.isSuperAdmin() ||
      this.isCompanyAdmin()
    ) {
      return false;
    }

    return this.hasCapability('report.survey2.view');
  }

  public canEditSurveyStructure(survey: Survey): boolean {
    return !(
      survey.isElmoManaged &&
      (!this.isPMUser() || this.isImpersonator())
    );
  }
}

export default new AuthAccess();
