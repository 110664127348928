import { CreateIndividualLinkRequest } from './type';
import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const route = '/reports/:surveyId/shared';

export const createIndividualLinkApi = (
  request: CreateIndividualLinkRequest
) => {
  return postRequest(
    getRoute(route, { params: { surveyId: request.surveyId } }),
    request.data
  );
};
