import { RouteObject } from 'react-router-dom';
import React from 'react';

import ReleaseNotes from './pages/ReleaseNotes';

const HelpCentreRoutes: RouteObject = {
  path: '/survey/help-centre',
  element: <ReleaseNotes />,
};

export default HelpCentreRoutes;
