import { createAction } from 'redux-act';

export const createInviteMessageTemplate: any = createAction(
  'CREATE_INVITE_MESSAGE_TEMPLATE'
);

export const createInviteMessageTemplateSuccess: any = createAction(
  'CREATE_INVITE_MESSAGE_TEMPLATE_SUCCESS'
);

export const createInviteMessageTemplateFailure: any = createAction(
  'CREATE_INVITE_MESSAGE_TEMPLATE_FAILURE'
);

export const clearCreateInviteMessageTemplate: any = createAction(
  'CLEAR_CREATE_INVITE_MESSAGE_TEMPLATE'
);
