import { createAction } from 'redux-act';

export const updateInviteMessageTemplate: any = createAction(
  'UPDATE_INVITE_MESSAGE_TEMPLATE'
);

export const updateInviteMessageTemplateSuccess: any = createAction(
  'UPDATE_INVITE_MESSAGE_TEMPLATE_SUCCESS'
);

export const updateInviteMessageTemplateFailure: any = createAction(
  'UPDATE_INVITE_MESSAGE_TEMPLATE_FAILURE'
);

export const clearUpdateInviteMessageTemplate: any = createAction(
  'UPDATE_INVITE_MESSAGE_TEMPLATE_CLEAR_STATE'
);
