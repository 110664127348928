import produce from 'immer';

import {
  clearCreateInviteReminderMessage,
  createInviteReminderMessage,
  createInviteReminderMessageFailure,
  createInviteReminderMessageSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function createInviteReminderMessageReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteReminderMessage.isCreated = false;
    draft.createInviteReminderMessage.isCreating = true;
    draft.createInviteReminderMessage.isValid = false;
    draft.createInviteReminderMessage.data = undefined;
  });
}

export function createInviteReminderMessageSuccessReducer(
  state: InvitesState,
  data: any
) {
  return produce(state, (draft) => {
    draft.createInviteReminderMessage.isCreated = true;
    draft.createInviteReminderMessage.isCreating = false;
    draft.createInviteReminderMessage.isValid = true;
    draft.createInviteReminderMessage.data = data.data;
  });
}

export function createInviteReminderMessageFailureReducer(
  state: InvitesState,
  error: any
) {
  return produce(state, (draft) => {
    draft.createInviteReminderMessage.isCreated = true;
    draft.createInviteReminderMessage.isCreating = false;
    draft.createInviteReminderMessage.error = error;
  });
}

export function clearCreateInviteReminderMessageReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteReminderMessage =
      invitesInitialState.createInviteReminderMessage;
  });
}

export const createInviteReminderMessageReducers = {
  [`${createInviteReminderMessage}`]: createInviteReminderMessageReducer,
  [`${createInviteReminderMessageSuccess}`]:
    createInviteReminderMessageSuccessReducer,
  [`${createInviteReminderMessageFailure}`]:
    createInviteReminderMessageFailureReducer,
  [`${clearCreateInviteReminderMessage}`]:
    clearCreateInviteReminderMessageReducer,
};
