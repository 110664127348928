import { GetFilterValuesState } from './type';
import { Query } from 'lib/stateTemplates';

export const getFilterValuesInitialState: GetFilterValuesState = {
  count: 0,
  data: [],
  isLoaded: false,
  isLoading: false,
  isValid: false,
  query: Query,
  error: undefined,
};
