export interface Logger {
  error: (e: Error, data?: any) => void;
  warn: (data?: any) => void;
}

const error = (e: Error, data?: any) => {
  console.error(data);
};

const warn = (data: string) => {
  console.warn(data);
};

const logger: Logger = {
  error,
  warn,
};

export default logger;
