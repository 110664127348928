import {
  UpdateSurveyNotificationPayload,
  UpdateSurveyNotificationState,
} from './type';

export const updateSurveyNotificationInitialState: UpdateSurveyNotificationState =
  {
    isLoaded: false,
    isLoading: false,
    isValid: false,
    payload: {} as UpdateSurveyNotificationPayload,
  };
