import { GetQuestionCategoriesState } from './type';
import { Query } from 'lib/stateTemplates';

export const getQuestionCategoriesInitialState: GetQuestionCategoriesState = {
  count: 0,
  data: [],
  allQuestionCategories: [],
  isLoaded: false,
  isLoading: false,
  isValid: false,
  query: Query,
};
