import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { DeleteResponsePayload } from './type';
import { ErrorResponse } from 'type';

export const deleteResponse: ActionCreator<DeleteResponsePayload> =
  createAction('DELETE_RESPONSE');

export const deleteResponseSuccess: EmptyActionCreator = createAction(
  'DELETE_RESPONSE_SUCCESS'
);

export const deleteResponseFailure: ActionCreator<ErrorResponse> = createAction(
  'DELETE_RESPONSE_FAILURE'
);

export const clearDeleteResponse: EmptyActionCreator = createAction(
  'CLEAR_DELETE_RESPONSE'
);
