import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getBenchmarkReportFailure,
  getBenchmarkReportSuccess,
} from './actions';
import { getBenchmarkReportApi } from './api';
import { SagaAction } from 'type';

export const getBenchmarkReportSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const data = yield call(getBenchmarkReportApi(action.payload));

    yield put(getBenchmarkReportSuccess(data));
  } catch (error) {
    yield put(getBenchmarkReportFailure(error));
  }
};
