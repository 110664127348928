import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import PageLoading from 'element/PageLoading';
import translations from './translations';
import { withDelay } from 'lib/util';
import { t } from 'lib/translation';

const RunSurveyComponent = lazy(() =>
  withDelay(() => import('./RunSurveyComponent'))
);

const RunSurvey = (): ReactElement => {
  return (
    <Suspense fallback={<PageLoading text={t(translations.loadingText)} />}>
      <RunSurveyComponent />
    </Suspense>
  );
};

export default RunSurvey;
