import { deleteQuestionCategoryInitialState } from './deleteQuestionCategory/state';
import { createQuestionCategoryInitialState } from './createQuestionCategory/state';
import { updateQuestionCategoryInitialState } from './updateQuestionCategory/state';
import { getQuestionCategoriesInitialState } from './getQuestionCategories/state';
import { getQuestionCategoryInitialState } from './getQuestionCategory/state';
import { QuestionCategoryState } from './type';

export const questionCategoryInitialState: QuestionCategoryState = {
  createQuestionCategory: createQuestionCategoryInitialState,
  deleteQuestionCategory: deleteQuestionCategoryInitialState,
  getQuestionCategories: getQuestionCategoriesInitialState,
  getQuestionCategory: getQuestionCategoryInitialState,
  updateQuestionCategory: updateQuestionCategoryInitialState,
};
