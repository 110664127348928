import produce from 'immer';

import {
  createBulkLinks,
  createBulkLinksFailure,
  createBulkLinksSuccess,
  clearCreateBulkLinks,
} from './actions';
import { createBulkLinksInitialState } from './state';
import { CreateBulkLinksResponse } from './type';
import { ShareReportState } from '../type';
import { ErrorResponse } from 'type';

export const createBulkLinksReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.createBulkLinks = { ...createBulkLinksInitialState };
    draft.createBulkLinks.isLoading = true;
  });
};

export const createBulkLinksSuccessReducer = (
  state: ShareReportState,
  data: CreateBulkLinksResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.createBulkLinks.isLoading = false;
    draft.createBulkLinks.isLoaded = true;
    draft.createBulkLinks.isValid = true;
    draft.createBulkLinks.linksCount = data.data.sharedReportBatch.linksCount;
  });
};

export const createBulkLinksFailureReducer = (
  state: ShareReportState,
  error: ErrorResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.createBulkLinks.isLoading = false;
    draft.createBulkLinks.isLoaded = true;
    draft.createBulkLinks.error = error;
  });
};

export const clearCreateBulkLinksReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.createBulkLinks = createBulkLinksInitialState;
  });
};

export const createBulkLinksReducers = {
  [`${createBulkLinks}`]: createBulkLinksReducer,
  [`${createBulkLinksSuccess}`]: createBulkLinksSuccessReducer,
  [`${createBulkLinksFailure}`]: createBulkLinksFailureReducer,
  [`${clearCreateBulkLinks}`]: clearCreateBulkLinksReducer,
};
