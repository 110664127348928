import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import map from 'lodash/map';
import max from 'lodash/max';

import {
  updateManageInviteParameter,
  updateManageInviteReminders,
} from 'store/modules/invites/manageInvite/actions';
import { message } from 'store/modules/invites/manageInvite/state';
import MessageEditor from 'element/messageEditor';
import translations from './translations';
import { ReminderProps } from './type';

const Reminder = ({ closeModal }: ReminderProps): ReactElement => {
  const dispatch = useDispatch();

  const parameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );
  const reminders = useSelector(
    (state: any) => state.emailInvites.manageInvite.data.reminders
  );

  const isNewReminder = parameters.currentReminder === -1;
  const newReminderId =
    isNewReminder && isEmpty(reminders) ? 1 : max(map(reminders, 'id')) + 1;

  const [reminderState, updateReminderState] = useState<any>(
    isNewReminder
      ? { ...message, id: newReminderId }
      : find(reminders, ['id', parameters.currentReminder])
  );

  const saveMessage = () => {
    dispatch(updateManageInviteReminders(reminderState));

    if (parameters.isEdit) {
      dispatch(updateManageInviteParameter({ currentStep: 'sendSchedule' }));
    } else {
      closeModal();
    }
  };

  return (
    <MessageEditor
      closeModal={closeModal}
      messageState={reminderState}
      updateMessageState={updateReminderState}
      hasSecondaryButton={false}
      primaryButtonOnClick={saveMessage}
      messageTranslations={translations}
    />
  );
};

export default Reminder;
