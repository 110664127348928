import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const getOverviewReportStats: ActionCreator<string | number> =
  createAction('GET_OVERVIEW_REPORT_STATS');

export const getOverviewReportStatsSuccess: ActionCreator<any> = createAction(
  'GET_OVERVIEW_REPORT_STATS_SUCCESS'
);

export const getOverviewReportStatsFailure: ActionCreator<ErrorResponse> =
  createAction('GET_OVERVIEW_REPORT_STATS_FAILURE');

export const clearOverviewReportStats: EmptyActionCreator = createAction(
  'CLEAR_GET_OVERVIEW_REPORT_STATS'
);
