import { ChartTypes, SavedChartDisplayTypes } from '../type';

export const getDisplayValue = (
  displayType: SavedChartDisplayTypes,
  type: string
) => {
  if (displayType === 'score') {
    return {
      label: 'Score',
      value: 'score',
    };
  }

  if (displayType === 'scorePercentage') {
    return {
      label: 'Score & Percentage',
      value: 'scoreAndPercentage',
    };
  }

  if (displayType === 'count') {
    return {
      label: 'Response Count',
      value: 'responseCount',
    };
  }

  if (displayType === 'percentage') {
    return {
      label: 'Response Percentage',
      value: 'responsePercentage',
    };
  }

  if (displayType === 'all' && type === 'matrix') {
    return {
      label: 'Score & Percentage',
      value: 'scoreAndPercentage',
    };
  }

  if (displayType === 'all') {
    return {
      label: 'Response Count & Percentage',
      value: 'responseCountAndPercentage',
    };
  }

  return {
    label: 'Response Count',
    value: 'responseCount',
  };
};

export const getChartType = (chartType: string): 'disable' | ChartTypes => {
  if (chartType === 'matrix-column') {
    return 'column';
  }

  const values = ['column', 'bar', 'pie', 'line', 'donut'];

  if (values.includes(chartType)) {
    return chartType as ChartTypes;
  }

  return 'disable';
};
