import produce from 'immer';

import { Item as InitialItem } from 'lib/stateTemplates';
import { AnonCollector, AnonSurvey } from 'type';
import { RunSurveyState } from './type';

export const runSurveyInitialState: RunSurveyState = produce(
  InitialItem,
  (draft: RunSurveyState) => {
    draft.data = {
      collector: {} as AnonCollector,
      survey: {} as AnonSurvey,
    };
  }
);
