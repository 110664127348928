import { GetAssociatedFiltersRequest } from './type';
import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/survey/:surveyId/filters';

export const getAssociatedFiltersApi = (
  request: GetAssociatedFiltersRequest,
  query: Query
) => {
  return getRequest(
    getRoute(url, {
      params: { surveyId: request.surveyId },
    }),
    { params: { ...query, withValues: request.withValues } }
  );
};
