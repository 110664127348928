import { getOverviewReportFilterOptionsInitialState } from './getOverviewReportFilterOptions/state';
import { updateOverviewReportPaginationInitialState } from './updateOverviewReportPagination/state';
import { getCollectorFilterOptionsInitialState } from './getCollectorFilterOptions/state';
import { saveOverviewReportForPDFInitialState } from './saveOverviewReportForPDF/state';
import { setOverviewReportFiltersInitialState } from './setOverviewReportFilters/state';
import { loadSavedOverviewReportInitialState } from './loadSavedOverviewReport/state';
import { getTextQuestionAnswersInitialState } from './getTextQuestionAnswers/state';
import { getOverviewReportStatsInitialState } from './getOverviewReportStats/state';
import { getSavedOverviewReportInitialState } from './getSavedOverviewReport/state';
import { updateQuestionSettingsInitialState } from './updateQuestionSettings/state';
import { OverviewReportState } from './type';

export const overviewReportInitialState: OverviewReportState = {
  getCollectorFilterOptions: getCollectorFilterOptionsInitialState,
  getOverviewReportFilterOptions: getOverviewReportFilterOptionsInitialState,
  getTextQuestionAnswers: getTextQuestionAnswersInitialState,
  getOverviewReportStats: getOverviewReportStatsInitialState,
  getSavedOverviewReport: getSavedOverviewReportInitialState,
  loadSavedOverviewReport: loadSavedOverviewReportInitialState,
  saveOverviewReportForPDF: saveOverviewReportForPDFInitialState,
  setOverviewReportFilters: setOverviewReportFiltersInitialState,
  updateQuestionSettings: updateQuestionSettingsInitialState,
  updateOverviewReportPagination: updateOverviewReportPaginationInitialState,
};
