import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

export const setDefaultTheme: ActionCreator<number> =
  createAction('SET_DEFAULT_THEME');

export const setDefaultThemeSuccess: ActionCreator<number> = createAction(
  'SET_DEFAULT_THEME_SUCCESS'
);

export const setDefaultThemeFailure: EmptyActionCreator = createAction(
  'SET_DEFAULT_THEME_FAILURE'
);

export const clearSetDefaultTheme: EmptyActionCreator = createAction(
  'CLEAR_DEFAULT_THEME'
);
