import { Alert, Button, Col, Container, Row } from 'elmo-elements';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import {
  updateManageInviteBulk,
  updateManageInviteParameter,
} from 'store/modules/invites/manageInvite/actions';
import { manageInviteInitialState } from 'store/modules/invites/manageInvite/state';
import { analytics, AnalyticsEventNames } from 'lib';
import translations from './translations';
import { t } from 'lib/translation';
import './styles.scss';

const DraftNotification = ({ collector }: any) => {
  const dispatch = useDispatch();

  const inviteParameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );

  const clearAction = () => {
    dispatch(updateManageInviteParameter({ isDraft: false, isFlow: true }));
    dispatch(updateManageInviteBulk(manageInviteInitialState.data));
    localStorage.removeItem('survey-invite-draft');

    if (collector.collector) {
      analytics.sendEvent(
        AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_CLEAR_DRAFT_BUTTON_CLICKED,
        {
          surveyId: collector.collector.survey.id,
          surveyTitle: collector.collector.survey.title,
          collectorId: collector.collector.id,
          surveyStatus: collector.collector.survey.status,
        }
      );
    }
  };

  if (!inviteParameters.isDraft) {
    return <></>;
  }

  return (
    <Container size="lg">
      <Row>
        <Col md={8} />
        <Col md={16}>
          <Alert type={'info'} className={'invite-draft-notification'}>
            {t(translations.draftUsed)}{' '}
            <Button
              id={'clear-invite-draft'}
              onClick={clearAction}
              className={'text-button'}
            >
              {t(translations.clearDraft)}
            </Button>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default DraftNotification;
