import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { deleteQuestionFailure, deleteQuestionSuccess } from './actions';
import { DeleteQuestionPayload } from './type';
import { deleteQuestionApi } from './api';
import { SagaAction } from 'type';

export const deleteQuestionSaga = function* (
  action: SagaAction<DeleteQuestionPayload>
): SagaIterator {
  try {
    yield call(deleteQuestionApi(action.payload));

    yield put(deleteQuestionSuccess());
  } catch (error) {
    yield put(deleteQuestionFailure(error as any));
  }
};
