import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createInviteReminderMessageFailure,
  createInviteReminderMessageSuccess,
} from './actions';
import { createInviteReminderMessageApi } from './api';

export const createInviteReminderMessageSaga = function* (
  action: any
): SagaIterator {
  try {
    const data = yield call(
      createInviteReminderMessageApi(
        action.payload.collectorId,
        action.payload.recipientListId,
        action.payload.data
      )
    );

    yield put(createInviteReminderMessageSuccess(data));
  } catch (error) {
    yield put(createInviteReminderMessageFailure(error));
  }
};
