import produce from 'immer';

import {
  getCollector,
  getCollectorFailure,
  getCollectorSuccess,
  clearGetCollector,
} from './actions';
import { transformFullCollector } from '../../../transformers';
import { collectorInitialState } from '../state';
import { CollectorState } from '../type';

export function getCollectorReducer(
  state: CollectorState,
  collectorId: number
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollector.isLoaded = false;
    draft.getCollector.isLoading = true;
    draft.getCollector.isValid = false;
    draft.getCollector.collectorId = collectorId;
    draft.getCollector.collector = collectorInitialState.getCollector.collector;
  });
}

export function getCollectorSuccessReducer(
  state: CollectorState,
  data: any
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollector.isLoaded = true;
    draft.getCollector.isLoading = false;
    draft.getCollector.isValid = true;
    draft.getCollector.collector = transformFullCollector(data.data);
  });
}

export function getCollectorFailureReducer(
  state: CollectorState
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollector.isLoaded = true;
    draft.getCollector.isLoading = false;
    draft.getCollector.isValid = false;
  });
}

export function clearGetCollectorReducer(
  state: CollectorState
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.getCollector = collectorInitialState.getCollector;
  });
}

export const getCollectorReducers = {
  [`${getCollector}`]: getCollectorReducer,
  [`${getCollectorSuccess}`]: getCollectorSuccessReducer,
  [`${getCollectorFailure}`]: getCollectorFailureReducer,
  [`${clearGetCollector}`]: clearGetCollectorReducer,
};
