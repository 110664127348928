import { createAction } from 'redux-act';

export const inviteParticipants: any = createAction('INVITE_PARTICIPANTS');

export const inviteParticipantsSuccess: any = createAction(
  'INVITE_PARTICIPANTS_SUCCESS'
);

export const inviteParticipantsFailure: any = createAction(
  'INVITE_PARTICIPANTS_FAILURE'
);

export const clearInviteParticipants: any = createAction(
  'CLEAR_INVITE_PARTICIPANTS'
);
