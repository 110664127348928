import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { DefaultQuery, ErrorResponse } from 'type';
import { GetSurveysResponse } from './type';

export const updateGetSurveysQuery: ActionCreator<DefaultQuery> = createAction(
  'GET_SURVEYS_UPDATE_SURVEY_LIST_QUERY'
);

export const getSurveys: any = createAction('GET_SURVEYS');

export const getSurveysSuccess: ActionCreator<GetSurveysResponse> =
  createAction('GET_SURVEYS_SUCCESS');

export const getSurveysFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_SURVEYS_FAILURE'
);

export const clearGetSurveys: EmptyActionCreator =
  createAction('CLEAR_GET_SURVEYS');
