import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getInviteMessageTemplatesFailure,
  getInviteMessageTemplatesSuccess,
} from './actions';
import { getInviteMessageTemplatesApi } from './api';

export const getInviteMessageTemplatesSaga = function* (
  action: any
): SagaIterator {
  try {
    const data = yield call(getInviteMessageTemplatesApi(action.payload));

    yield put(getInviteMessageTemplatesSuccess(data));
  } catch (error) {
    yield put(getInviteMessageTemplatesFailure(error));
  }
};
