import produce from 'immer';

import {
  clearUpdateInviteMessageSchedule,
  updateInviteMessageSchedule,
  updateInviteMessageScheduleFailure,
  updateInviteMessageScheduleSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function updateInviteMessageScheduleReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteMessageSchedule.isUpdated = false;
    draft.updateInviteMessageSchedule.isUpdating = true;
    draft.updateInviteMessageSchedule.isValid = false;
  });
}

export function updateInviteMessageScheduleSuccessReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteMessageSchedule.isUpdated = true;
    draft.updateInviteMessageSchedule.isUpdating = false;
    draft.updateInviteMessageSchedule.isValid = true;
  });
}

export function updateInviteMessageScheduleFailureReducer(
  state: InvitesState,
  error: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteMessageSchedule.isUpdated = true;
    draft.updateInviteMessageSchedule.isUpdating = false;
    draft.updateInviteMessageSchedule.errors = error;
  });
}

export function clearUpdateInviteMessageScheduleReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteMessageSchedule =
      invitesInitialState.updateInviteMessageSchedule;
  });
}

export const updateInviteMessageScheduleReducers = {
  [`${updateInviteMessageSchedule}`]: updateInviteMessageScheduleReducer,
  [`${updateInviteMessageScheduleSuccess}`]:
    updateInviteMessageScheduleSuccessReducer,
  [`${updateInviteMessageScheduleFailure}`]:
    updateInviteMessageScheduleFailureReducer,
  [`${clearUpdateInviteMessageSchedule}`]:
    clearUpdateInviteMessageScheduleReducer,
};
