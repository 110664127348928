import produce from 'immer';

import {
  clearDeleteQuestion,
  deleteQuestion,
  deleteQuestionFailure,
  deleteQuestionSuccess,
} from './actions';
import { deleteQuestionInitialState } from './state';
import { questionInitialState } from '../state';
import { QuestionState } from '../type';

export function deleteQuestionReducer(state: QuestionState): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.deleteQuestion = { ...deleteQuestionInitialState };
    draft.deleteQuestion.isLoading = true;
  });
}

export function deleteQuestionSuccessReducer(
  state: QuestionState
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.deleteQuestion.isLoaded = true;
    draft.deleteQuestion.isValid = true;
    draft.deleteQuestion.isLoading = false;
  });
}

export function deleteQuestionFailureReducer(
  state: QuestionState,
  error: any
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.deleteQuestion.isLoaded = true;
    draft.deleteQuestion.isLoading = false;
    draft.deleteQuestion.error = error;
  });
}

export function clearDeleteQuestionReducer(
  state: QuestionState
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.deleteQuestion = questionInitialState.deleteQuestion;
  });
}

export const deleteQuestionReducers = {
  [`${deleteQuestion}`]: deleteQuestionReducer,
  [`${deleteQuestionSuccess}`]: deleteQuestionSuccessReducer,
  [`${deleteQuestionFailure}`]: deleteQuestionFailureReducer,
  [`${clearDeleteQuestion}`]: clearDeleteQuestionReducer,
};
