import { takeEvery } from 'redux-saga/effects';

import { getAdminBenchmarkDataSaga } from './getAdminBenchmarkData/sagas';
import { getAdminBenchmarkData, getAdminDetails } from './actions';
import { getAdminDetailsSaga } from './getAdminDetails/sagas';

export default function* sagas() {
  yield takeEvery(getAdminDetails, getAdminDetailsSaga);
  yield takeEvery(getAdminBenchmarkData, getAdminBenchmarkDataSaga);
}
