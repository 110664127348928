import { isSharedReport } from 'lib/helpers/sharedReports';
import { GetTextQuestionAnswersPayload } from './type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyID/answers';
const sharedReportApiUrl = '/shared-reports/:surveyID/answers';

export const getTextQuestionAnswersApi = (
  {
    surveyId,
    questionName,
    currentPage,
    pageSize,
  }: GetTextQuestionAnswersPayload,
  filters: any
): any => {
  const route = isSharedReport() ? sharedReportApiUrl : apiUrl;

  return getRequest(
    getRoute(route, {
      params: { surveyID: surveyId },
      query: {
        question: questionName,
        page: currentPage,
        limit: pageSize,
        ...filters,
      },
    })
  );
};
