var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ToggleContext from './components/ToggleContext';
import ToggleOn from './components/ToggleOn';
import ToggleOff from './components/ToggleOff';
import ToggleButton from './components/ToggleButton';
import { useControlled } from '../_lib/hooks';
var Toggle = function (_a) {
    var externalIsOn = _a.isOn, defaultIsOn = _a.defaultIsOn, id = _a.id, _b = _a.disabled, disabled = _b === void 0 ? false : _b, onClick = _a.onClick, onChange = _a.onChange, children = _a.children, title = _a.title, rest = __rest(_a, ["isOn", "defaultIsOn", "id", "disabled", "onClick", "onChange", "children", "title"]);
    var _c = useControlled({
        controlled: externalIsOn,
        default: Boolean(defaultIsOn),
        name: 'Toggle',
        state: 'isOn',
    }), isOn = _c[0], setIsOn = _c[1];
    var toggleCallback = function (event) {
        event.persist();
        var newIsOn = event.target.checked;
        setIsOn(newIsOn);
        if (onChange) {
            onChange(event);
        }
        // for existing behavior
        if (onClick) {
            onClick(newIsOn);
        }
    };
    var contextValue = {
        isOn: isOn,
        toggleHandler: toggleCallback,
        id: id,
        disabled: disabled,
        title: title,
    };
    return (React.createElement(ToggleContext.Provider, { value: contextValue }, children ? children : React.createElement(ToggleButton, null)));
};
Toggle.On = ToggleOn;
Toggle.Off = ToggleOff;
Toggle.Button = ToggleButton;
export default Toggle;
