import { call, put } from 'redux-saga/effects';

import { createThemeFailure, createThemeSuccess } from './actions';
import { CreateThemePayload } from './type';
import { createThemeApi } from './api';
import { SagaAction } from 'type';

export const createThemeSaga = function* (
  action: SagaAction<CreateThemePayload>
) {
  try {
    yield call(createThemeApi(action.payload));
    yield put(createThemeSuccess());
  } catch (err) {
    yield put(createThemeFailure(err));
  }
};
