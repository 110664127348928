import React from 'react';
import { getClass } from '../../_lib/helper';
/**
 * Header cell component.
 * @param children
 * @param className
 * @param colSpan
 * @param width
 * @constructor
 */
function DataTableTh(_a) {
    var children = _a.children, className = _a.className, colSpan = _a.colSpan, width = _a.width, textAlign = _a.textAlign;
    var style = {};
    if (width) {
        style['width'] = width;
    }
    if (textAlign) {
        style['textAlign'] = textAlign;
    }
    return (React.createElement("th", { className: getClass('elmo-datatable__th', className), colSpan: colSpan, style: style }, children));
}
export default DataTableTh;
