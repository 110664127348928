import { RouteObject } from 'react-router-dom';
import React from 'react';

import SharedReport from './pages/SharedReport';
import ReportLogin from './pages/ReportLogin';

const routes: RouteObject[] = [
  {
    path: '/report/:reportHash/login',
    element: <ReportLogin />,
  },
  {
    path: '/report/:surveyId',
    element: <SharedReport />,
  },
];

export default routes;
