import { GetAdminDetailsState } from './type';

export const getAdminDetailsInitialState: GetAdminDetailsState = {
  data: {
    surveysCount: 0,
    clientsCount: 0,
    responsesCount: 0,
    responseIndustriesCount: 0,
  },
  isLoaded: false,
  isLoading: false,
  isValid: false,
};
