import { createReducer } from 'redux-act';

import { getFeaturedTemplatesReducers } from './getFeaturedTemplates/reducers';
import { updateSurveyTemplateReducers } from './updateSurveyTemplate/reducers';
import { deleteSurveyTemplateReducers } from './deleteSurveyTemplate/reducers';
import { createSurveyTemplateReducers } from './createSurveyTemplate/reducers';
import { getSurveyTemplatesReducers } from './getSurveyTemplates/reducers';
import { getSurveyTemplateReducers } from './getSurveyTemplate/reducers';
import { surveyTemplateInitialState } from './state';

export default createReducer(
  {
    ...deleteSurveyTemplateReducers,
    ...getFeaturedTemplatesReducers,
    ...getSurveyTemplatesReducers,
    ...updateSurveyTemplateReducers,
    ...createSurveyTemplateReducers,
    ...getSurveyTemplateReducers,
  },
  surveyTemplateInitialState
);
