import produce from 'immer';

import {
  clearCreateTheme,
  createTheme,
  createThemeFailure,
  createThemeSuccess,
} from './actions';
import { createThemeInitialState } from './state';
import { ThemeState } from '../type';

export function createThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.createTheme.isCreating = true;
  });
}

export function createThemeSuccessReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.createTheme.isCreated = true;
    draft.createTheme.isValid = true;
  });
}

export function createThemeFailureReducer(
  state: ThemeState,
  err: any
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.createTheme.error = err;
  });
}

export function clearCreateThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.createTheme = {
      ...createThemeInitialState,
    };
  });
}

export const createThemeReducers = {
  [`${createTheme}`]: createThemeReducer,
  [`${createThemeSuccess}`]: createThemeSuccessReducer,
  [`${createThemeFailure}`]: createThemeFailureReducer,
  [`${clearCreateTheme}`]: clearCreateThemeReducer,
};
