import produce from 'immer';

import {
  getFilter,
  clearGetFilter,
  getFilterFailure,
  getFilterSuccess,
} from './actions';
import { transformFilter } from '../../../transformers/filter';
import { getFilterInitialState } from './state';
import { GetFilterResponse } from './type';
import { FilterState } from '../type';
import { ErrorResponse } from 'type';

export function getFilterReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.getFilter.isLoading = true;
    draft.getFilter.isLoaded = false;
    draft.getFilter.isValid = false;
  });
}

export function getFilterSuccessReducer(
  state: FilterState,
  response: GetFilterResponse
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.getFilter.isLoading = false;
    draft.getFilter.isLoaded = true;
    draft.getFilter.isValid = true;
    draft.getFilter.data = transformFilter(response.data);
  });
}

export function getFilterFailureReducer(
  state: FilterState,
  errors: ErrorResponse
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.getFilter.isLoading = false;
    draft.getFilter.isLoaded = true;
    draft.getFilter.error = errors;
  });
}

export function clearGetFilterReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.getFilter = getFilterInitialState;
  });
}

export const getFilterReducers = {
  [`${getFilter}`]: getFilterReducer,
  [`${getFilterSuccess}`]: getFilterSuccessReducer,
  [`${getFilterFailure}`]: getFilterFailureReducer,
  [`${clearGetFilter}`]: clearGetFilterReducer,
};
