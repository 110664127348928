import { UpdateThemeData } from './type';
import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const updateThemeRoute = '/theme/:id';

export const updateThemeApi = ({ id, payload }: UpdateThemeData) =>
  putRequest(
    getRoute(updateThemeRoute, {
      params: { id },
    }),
    payload
  );
