import React, { useRef, useState } from 'react';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';

import { defaultRenderInputProps, inputTagsProps } from './type';

function uniq(arr: any) {
  let out = [];
  let duplicates = [];

  for (let i = 0; i < arr.length; i++) {
    if (out.indexOf(arr[i]) === -1) {
      out.push(arr[i]);
    } else {
      duplicates.push(arr[i]);
    }
  }

  return { result: out, duplicates: duplicates };
}

function getClipboardData(e: any) {
  if (e.clipboardData) {
    return e.clipboardData.getData('text/plain');
  }

  return '';
}

function defaultRenderInput(props: defaultRenderInputProps) {
  let { onChange, value, ...other } = props;
  return <input type="text" onChange={onChange} value={value} {...other} />;
}

function defaultRenderLayout(tagComponents: any, inputComponent: any) {
  return (
    <span>
      {tagComponents}
      {inputComponent}
    </span>
  );
}

function defaultPasteSplit(data: string) {
  return data.split(' ').map((d) => d.trim());
}

const TagsInput = ({
  value,
  onChange,
  className = 'react-tagsinput',
  focusedClassName = 'react-tagsinput--focused',
  addKeys = [9, 13],
  addOnBlur = false,
  addOnPaste = false,
  inputProps = {},
  removeKeys = [8],
  renderInput = defaultRenderInput,
  renderTag,
  renderLayout = defaultRenderLayout,
  pasteSplit = defaultPasteSplit,
  tagProps = {
    className: 'react-tagsinput-tag',
  },
  onlyUnique = false,
  onNonUniqueFound = () => true,
  maxTags = -1,
  onOverLimitTags = () => true,
  validationRegex = /.*/,
  onValidationReject = () => true,
  disabled = false,
  preventSubmit = true,
}: inputTagsProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [inputContent, updateInputContent] = useState<string>('');
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const removeTag = (index: number): void => {
    let values = value.concat([]);

    if (index > -1 && index < values.length) {
      let changed = values.splice(index, 1);
      onChange(values, changed, [index]);
    }
  };

  const addTags = (tags: string[], fromPaste: boolean = false) => {
    let clearInput: boolean = true;
    if (onlyUnique) {
      const result = uniq(tags);

      const existingDuplicates = result.result.filter(
        (tag) => indexOf(value, tag) >= 0
      );

      tags = result.result.filter((tag) => indexOf(value, tag) === -1);

      const allDuplicates = [...result.duplicates, ...existingDuplicates];

      if (allDuplicates.length > 0) {
        clearInput = false;
        onNonUniqueFound(tags, allDuplicates, fromPaste);
      }
    }

    const rejectedTags = tags.filter((tag) => !validationRegex.test(tag));
    tags = tags.filter((tag) => validationRegex.test(tag));

    if (maxTags >= 0) {
      const remainingLimit = Math.max(maxTags - value.length, 0);
      const overMaxBy = tags.length - remainingLimit;
      let overLimitTags: string[] = [];

      if (overMaxBy > 0) {
        overLimitTags = tags.slice(remainingLimit);
      }

      tags = tags.slice(0, remainingLimit);

      if (overMaxBy > 0) {
        if (!onOverLimitTags(tags, overLimitTags, overMaxBy, fromPaste)) {
          return false;
        }
      }
    }

    if (onValidationReject && rejectedTags.length > 0) {
      onValidationReject(tags, rejectedTags, fromPaste);
    }

    if (tags.length > 0) {
      let newValue = value.concat(tags);
      let indexes = [];
      for (let i = 0; i < tags.length; i++) {
        indexes.push(value.length + i);
      }
      onChange(newValue, tags, indexes);

      if (clearInput) {
        updateInputContent('');
      }
      return true;
    }

    if (rejectedTags.length > 0) {
      return false;
    }

    if (clearInput) {
      updateInputContent('');
    }
    return false;
  };

  const shouldPreventDefaultEventOnAdd = (
    added: boolean,
    empty: boolean,
    keyCode: number
  ) => {
    if (added) {
      return true;
    }

    if (keyCode === 13) {
      return preventSubmit || (!preventSubmit && !empty);
    }

    return false;
  };

  const onInputPaste = (e: any) => {
    if (!addOnPaste) {
      return;
    }

    e.preventDefault();

    const data = getClipboardData(e);
    const tags = pasteSplit(data);

    addTags(tags, true);
  };

  const onInputKeyDown = (e: any) => {
    if (e.defaultPrevented) {
      return;
    }

    let empty = inputContent === '';
    let keyCode = e.keyCode;
    let key = e.key;
    let add = addKeys.indexOf(keyCode) !== -1 || addKeys.indexOf(key) !== -1;
    let remove =
      removeKeys.indexOf(keyCode) !== -1 || removeKeys.indexOf(key) !== -1;

    if (add) {
      if (inputContent !== '') {
        addTags([inputContent]);
      }

      if (shouldPreventDefaultEventOnAdd(inputContent !== '', empty, keyCode)) {
        e.preventDefault();
      }
    }

    if (remove && value.length > 0 && empty) {
      e.preventDefault();
      removeTag(value.length - 1);
    }
  };

  const onInputBlur = (e: any) => {
    setIsFocused(false);

    if (e == null) {
      return;
    }

    if (addOnBlur && !isEmpty(e.target.value)) {
      addTags([e.target.value]);
    }
  };

  const focusInput = (e: any) => {
    if (e.target === divRef.current) {
      inputRef.current && inputRef.current.focus();
      setIsFocused(true);
    }
  };

  if (isFocused) {
    className += ' ' + focusedClassName;
  }

  let tagComponents = value.map((tag: string, index: number) => {
    return renderTag({
      key: index,
      tag,
      onRemove: removeTag,
      disabled,
      ...tagProps,
    });
  });

  let inputComponent = renderInput({
    ref: inputRef,
    value: inputContent,
    onPaste: onInputPaste,
    onKeyDown: onInputKeyDown,
    onChange: (e: any) => updateInputContent(e.target.value),
    onFocus: () => setIsFocused(true),
    onBlur: onInputBlur,
    ...inputProps,
  });

  return (
    <div ref={divRef} onClick={focusInput} className={className}>
      {renderLayout(tagComponents, inputComponent)}
    </div>
  );
};

export default TagsInput;
