import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetUserProfileFilterOptionsResponse } from './type';
import { ErrorResponse } from 'type';

export const getUserProfileFilterOptions: EmptyActionCreator = createAction(
  'GET_USER_PROFILE_FILTER_OPTIONS'
);

export const getUserProfileFilterOptionsSuccess: ActionCreator<GetUserProfileFilterOptionsResponse> =
  createAction('GET_USER_PROFILE_FILTER_OPTIONS_SUCCESS');

export const getUserProfileFilterOptionsFailure: ActionCreator<ErrorResponse> =
  createAction('GET_USER_PROFILE_FILTER_OPTIONS_FAILURE');

export const clearGetUserProfileFilterOptions: EmptyActionCreator =
  createAction('CLEAR_GET_USER_PROFILE_FILTER_OPTIONS');
