import { takeEvery } from 'redux-saga/effects';

import { getIndividualResponsesSaga } from './getIndividualResponses/sagas';
import { getIndividualResponses } from './getIndividualResponses/actions';
import { restoreResponseSaga } from './restoreResponse/sagas';
import { restoreResponse } from './restoreResponse/actions';
import { deleteResponseSaga } from './deleteResponse/sagas';
import { deleteResponse } from './deleteResponse/actions';
import { getResponseSaga } from './getResponse/sagas';
import { getResponse } from './getResponse/actions';

export default function* sagas() {
  yield takeEvery(deleteResponse, deleteResponseSaga);
  yield takeEvery(restoreResponse, restoreResponseSaga);
  yield takeEvery(getIndividualResponses, getIndividualResponsesSaga);
  yield takeEvery(getResponse, getResponseSaga);
}
