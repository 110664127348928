import { patchRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const collectorRoute = '/collector/:collectorId';

export const updateCollectorApi = (collectorId: number, data: any) => {
  return patchRequest(
    getRoute(collectorRoute, { params: { collectorId: collectorId } }),
    data
  );
};
