var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import ReactCalendar from 'rc-calendar';
import 'rc-calendar/assets/index.css';
import { getClass } from '../_lib/helper';
/**
 * Calendar Component
 * Wraps the rc-calendar library.
 */
var Calendar = /** @class */ (function (_super) {
    __extends(Calendar, _super);
    function Calendar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Called when date selected
         * @param date
         */
        _this.onDateSelect = function (date) {
            var _a = _this.props, onChange = _a.onChange, name = _a.name;
            // if the back/forward button is clicked, rc-calendar selects the date
            // without checking if it is disabled. Check if the date is disabled, if it is, don't update anything
            var isDateDisabled = _this.disabledDate(date);
            if (isDateDisabled) {
                return;
            }
            onChange(date, name);
        };
        _this.disabledDate = function (dateToBeChecked) {
            var disabledDate = _this.props.disabledDate;
            if (disabledDate && dateToBeChecked) {
                return disabledDate(dateToBeChecked);
            }
            return false;
        };
        return _this;
    }
    Calendar.prototype.render = function () {
        var _a = this.props, id = _a.id, className = _a.className, value = _a.value;
        return (React.createElement("div", { id: id, "data-testid": "elmo-calendar-" + (id || 'default'), className: getClass('elmo-calendar', className) },
            React.createElement(ReactCalendar, { showDateInput: false, defaultValue: value || undefined, disabledDate: this.disabledDate, onSelect: this.onDateSelect })));
    };
    return Calendar;
}(Component));
export default Calendar;
