import { Modal as ElmoModal, Button } from 'elmo-elements';
import React from 'react';

import { baseModalProps } from './type';
import './style.scss';

const BaseModal = ({
  id = 'popup-modal',
  modalType = 'large',
  buttonsType = 'together',
  children,
  closeModal,
  title,
  hasPrimaryButton = true,
  hasSecondaryButton = true,
  primaryButtonClick,
  primaryButtonIsLoading = false,
  primaryButtonIsDisabled = false,
  primaryButtonText = 'Ok',
  secondaryButtonClick,
  secondaryButtonIsLoading = false,
  secondaryButtonIsDisabled = false,
  secondaryButtonText = 'Close',
}: baseModalProps) => {
  return (
    <>
      <ElmoModal
        id={id}
        className={`popup-modal ${buttonsType}`}
        type={modalType}
        isOpened={true}
        closeModal={closeModal}
        title={title}
        {...(hasPrimaryButton || hasSecondaryButton
          ? {
              primaryButton: (
                <>
                  {hasSecondaryButton && (
                    <div className={'secondary-button'}>
                      <Button
                        id={`${id}-secondary-button`}
                        type={'default'}
                        isBlock={true}
                        onClick={secondaryButtonClick}
                        isLoading={secondaryButtonIsLoading}
                        isDisabled={secondaryButtonIsDisabled}
                        ariaLabel={secondaryButtonText}
                      >
                        {secondaryButtonText}
                      </Button>
                    </div>
                  )}
                  {hasPrimaryButton && (
                    <div className={'primary-button'}>
                      <Button
                        id={`${id}-primary-button`}
                        type={'primary'}
                        isBlock={true}
                        onClick={primaryButtonClick}
                        isLoading={primaryButtonIsLoading}
                        isDisabled={primaryButtonIsDisabled}
                        ariaLabel={primaryButtonText}
                      >
                        {primaryButtonText}
                      </Button>
                    </div>
                  )}
                </>
              ),
            }
          : {})}
        hasCloseFooterButton={false}
      >
        {children}
      </ElmoModal>
    </>
  );
};

export default BaseModal;
