import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  getOverviewReportFilterOptionsFailure,
  getOverviewReportFilterOptionsSuccess,
} from './actions';
import { getOverviewReportFilterOptionsApi } from './api';
import { SagaAction } from 'type';

export const getOverviewReportFilterOptionsSaga = function* (
  action: SagaAction<string | number>
): SagaIterator {
  try {
    const data = yield call(getOverviewReportFilterOptionsApi(action.payload));

    yield put(getOverviewReportFilterOptionsSuccess(data));
  } catch (error) {
    yield put(getOverviewReportFilterOptionsFailure(error));
  }
};
