var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import './Avatar.scss';
var Avatar = /** @class */ (function (_super) {
    __extends(Avatar, _super);
    function Avatar(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            iconLabel: _this.getIconLabel(),
        };
        return _this;
    }
    Avatar.prototype.render = function () {
        var _a;
        var _b = this.props, className = _b.className, id = _b.id, src = _b.src, label = _b.label, isLabelVisible = _b.isLabelVisible, size = _b.size, isBoldLabel = _b.isBoldLabel, alt = _b.alt;
        var iconLabel = this.state.iconLabel;
        var sizeClassName = size ? size : 's';
        var modifiers = {
            label: isLabelVisible,
            bold: isBoldLabel,
        };
        modifiers = __assign(__assign({}, modifiers), (_a = {}, _a[sizeClassName] = true, _a));
        var avatarClassNames = getClass('elmo-avatar', className, modifiers);
        var altText = alt ? alt : label;
        return (React.createElement("span", { className: avatarClassNames, id: id, "data-testid": "elmo-avatar-" + (id || 'default') },
            src && (React.createElement("span", { className: "elmo-avatar__image", "data-testid": "elmo-avatar-image-" + (id || 'default') },
                React.createElement("img", { src: src, alt: altText }))),
            !src && iconLabel && (React.createElement("span", { className: "elmo-avatar__label-icon", "data-testid": "elmo-avatar-label-icon-" + (id || 'default') }, iconLabel)),
            isLabelVisible && (React.createElement("span", { className: "elmo-avatar__label", "data-testid": "elmo-avatar-label-" + (id || 'default') }, label))));
    };
    Avatar.prototype.componentDidUpdate = function (prevProps) {
        var label = this.props.label;
        if (label && label !== prevProps.label) {
            this.setState({ iconLabel: this.getIconLabel() });
        }
    };
    Avatar.prototype.getIconLabel = function () {
        var _a = this.props, label = _a.label, iconLabel = _a.iconLabel;
        if (iconLabel) {
            return iconLabel;
        }
        if (!label) {
            return '?';
        }
        var nameParts = label.split(' ');
        var initialsParts = [];
        if (nameParts.length > 0) {
            initialsParts.push(nameParts[0].substring(0, 1));
        }
        if (nameParts.length > 1) {
            initialsParts.push(nameParts[nameParts.length - 1].substring(0, 1));
        }
        return initialsParts.join('');
    };
    return Avatar;
}(Component));
export default Avatar;
