import produce from 'immer';

import { getMenuInitialState } from './state';
import { ErrorResponse, Menu } from 'type';
import * as actions from './actions';
import { AppState } from '../type';

export const getMenuReducer = (state: AppState): AppState => {
  return produce(state, (draft: AppState) => {
    draft.getMenu = { ...getMenuInitialState };
    draft.getMenu.isLoading = true;
  });
};

export const getMenuSuccessReducer = (
  state: AppState,
  data: Menu
): AppState => {
  return produce(state, (draft: AppState) => {
    draft.getMenu.isLoading = false;
    draft.getMenu.isLoaded = true;
    draft.getMenu.isValid = true;
    draft.getMenu.data = data;
  });
};

export const getMenuFailureReducer = (
  state: AppState,
  error: ErrorResponse
): AppState => {
  return produce(state, (draft: AppState) => {
    draft.getMenu.isLoading = false;
    draft.getMenu.isLoaded = true;
    draft.getMenu.error = error;
  });
};

export const getMenuReducers = {
  [`${actions.getMenu}`]: getMenuReducer,
  [`${actions.getMenuSuccess}`]: getMenuSuccessReducer,
  [`${actions.getMenuFailure}`]: getMenuFailureReducer,
};
