import produce from 'immer';

import {
  getSurveyTemplates,
  getSurveyTemplatesFailure,
  getSurveyTemplatesSuccess,
  updateSurveyTemplatesQuery,
} from './actions';
import { transformSurveyTemplates } from '../../../transformers/surveyTemplate';
import { getSurveyTemplatesInitialState } from './state';
import { GetSurveyTemplatesResponse } from './type';
import { SurveyTemplateState } from '../type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getSurveyTemplatesReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getSurveyTemplates = { ...getSurveyTemplatesInitialState };
    draft.getSurveyTemplates.isLoading = true;
  });
};

export const getSurveyTemplatesSuccessReducer = (
  state: SurveyTemplateState,
  data: GetSurveyTemplatesResponse
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getSurveyTemplates.isLoading = false;
    draft.getSurveyTemplates.isLoaded = true;
    draft.getSurveyTemplates.isValid = true;
    draft.getSurveyTemplates.count = data.data.count;
    draft.getSurveyTemplates.data = transformSurveyTemplates(
      data.data.templates
    );
  });
};

export const getSurveyTemplatesFailureReducer = (
  state: SurveyTemplateState,
  error: ErrorResponse
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getSurveyTemplates.isLoading = false;
    draft.getSurveyTemplates.isLoaded = true;
    draft.getSurveyTemplates.error = error;
  });
};

export const updateSurveyTemplatesQueryReducers = (
  state: SurveyTemplateState,
  query: Query
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getSurveyTemplates.query = query;
  });
};

export const getSurveyTemplatesReducers = {
  [`${getSurveyTemplates}`]: getSurveyTemplatesReducer,
  [`${getSurveyTemplatesSuccess}`]: getSurveyTemplatesSuccessReducer,
  [`${getSurveyTemplatesFailure}`]: getSurveyTemplatesFailureReducer,
  [`${updateSurveyTemplatesQuery}`]: updateSurveyTemplatesQueryReducers,
};
