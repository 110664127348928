import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const route = '/reports/:surveyId/batches';

export const getLatestCreatedBulkLinkApi = (
  surveyId: number,
  params: Query
) => {
  return getRequest(getRoute(route, { params: { surveyId: surveyId } }), {
    params: params,
  });
};
