/* eslint-disable max-len */
import React from 'react';
function Themes() {
    var inlineStyle = '.st0{fill - opacity:0.87;}';
    return (React.createElement("svg", { version: "1.1", id: "Layer_1", viewBox: "0 0 24 24", className: "elmo-icon__svg" },
        React.createElement("style", { type: "text/css" }, inlineStyle),
        React.createElement("path", { className: "st0", d: "M18,20H6V4h7v5h4.5l1.8-1.8L14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4.7l-2,2V20z" }),
        React.createElement("path", { className: "st0", d: "M21.7,8c-0.1,0-0.3,0.1-0.4,0.2l-5,5l1.5,1.5l5-5c0.2-0.2,0.2-0.6,0-0.8l-0.7-0.7C22,8.1,21.8,8,21.7,8z\n         M15.2,14.1c-0.9,0-1.7,0.7-1.7,1.7c0,0.7-0.6,1.1-1.1,1.1c0.5,0.7,1.4,1.1,2.2,1.1c1.2,0,2.2-1,2.2-2.2\n        C16.9,14.9,16.2,14.1,15.2,14.1z" })));
}
export default Themes;
