import produce from 'immer';

import { AuthenticateState } from '../type';
import * as actions from './actions';
import { ErrorResponse } from 'type';

export function loginSharedReportReducer(
  state: AuthenticateState
): AuthenticateState {
  return produce(state, (draft: AuthenticateState) => {
    draft.loginSharedReport.isLoading = true;
    draft.loginSharedReport.isLoaded = false;
    draft.loginSharedReport.isValid = false;
    draft.loginSharedReport.error = undefined;
  });
}

export function loginSharedReportSuccessReducer(
  state: AuthenticateState
): AuthenticateState {
  return produce(state, (draft: AuthenticateState) => {
    draft.loginSharedReport.isLoading = false;
    draft.loginSharedReport.isLoaded = true;
    draft.loginSharedReport.isValid = true;
  });
}
export function loginSharedReportFailureReducer(
  state: AuthenticateState,
  error: ErrorResponse
): AuthenticateState {
  return produce(state, (draft: AuthenticateState) => {
    draft.loginSharedReport.isLoading = false;
    draft.loginSharedReport.isLoaded = true;
    draft.loginSharedReport.error = error;
  });
}

export const loginSharedReportReducers = {
  [`${actions.loginSharedReport}`]: loginSharedReportReducer,
  [`${actions.loginSharedReportSuccess}`]: loginSharedReportSuccessReducer,
  [`${actions.loginSharedReportFailure}`]: loginSharedReportFailureReducer,
};
