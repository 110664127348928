import { put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getMenuFailure, getMenuSuccess } from './actions';
import { getMenuApi } from './api';

export const getMenuSaga = function* (): SagaIterator {
  try {
    const response = yield call(getMenuApi());

    yield put(getMenuSuccess(response));
  } catch (error) {
    yield put(getMenuFailure(error));
  }
};
