import produce from 'immer';

import {
  getQuestion,
  getQuestionFailure,
  getQuestionSuccess,
  clearGetQuestion,
} from './actions';
import { GetQuestionResponse, GetQuestionPayload } from './type';
import { transformQuestion } from '../../../transformers';
import { getQuestionInitialState } from './state';
import { QuestionState } from '../type';
import { ErrorResponse } from 'type';

export function getQuestionReducer(
  state: QuestionState,
  payload: GetQuestionPayload
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.getQuestion = { ...getQuestionInitialState };
    draft.getQuestion.isLoading = true;
    draft.getQuestion.questionId = payload.questionId;
  });
}

export function getQuestionSuccessReducer(
  state: QuestionState,
  data: GetQuestionResponse
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.getQuestion.isLoading = false;
    draft.getQuestion.isLoaded = true;
    draft.getQuestion.isValid = true;
    draft.getQuestion.data = transformQuestion(data.data);
  });
}

export function getQuestionFailureReducer(
  state: QuestionState,
  error: ErrorResponse
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.getQuestion.isLoading = false;
    draft.getQuestion.isLoaded = true;
    draft.getQuestion.error = error;
  });
}

export function clearGetQuestionReducer(state: QuestionState): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.getQuestion = getQuestionInitialState;
  });
}

export const getQuestionReducers = {
  [`${getQuestion}`]: getQuestionReducer,
  [`${getQuestionSuccess}`]: getQuestionSuccessReducer,
  [`${getQuestionFailure}`]: getQuestionFailureReducer,
  [`${clearGetQuestion}`]: clearGetQuestionReducer,
};
