import React from 'react';
import Radio from '../../Radio';
import RadioButtons from '../../RadioButtons';
import FilterBadge from './FilterBadge';
import './FilterButtons.scss';
function FilterButtons(_a) {
    var statuses = _a.statuses, onChange = _a.onChange, selected = _a.selected;
    return (React.createElement(RadioButtons, { className: "elmo-filter__buttons", onChange: onChange, selected: selected, type: "solid" }, statuses.map(function (status, index) {
        return (React.createElement(Radio, { value: status.value, key: index },
            React.createElement("span", { className: "label" }, status.label),
            status.count !== undefined && (React.createElement(FilterBadge, { colour: status.colour }, status.count))));
    })));
}
export default FilterButtons;
