import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getCompanyUsersSuccess, getCompanyUsersFailure } from './actions';
import { getCompanyUsersApi } from './api';

export const getCompanyUsersSaga = function* (): SagaIterator {
  try {
    const data = yield call(getCompanyUsersApi());

    yield put(getCompanyUsersSuccess(data));
  } catch (error) {
    yield put(getCompanyUsersFailure(error));
  }
};
