import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import {
  UploadBulkPasswordCSVRequest,
  UploadBulkPasswordCSVResponse,
} from './type';
import { ErrorResponse } from 'type';

export const uploadBulkPasswordCSV: ActionCreator<UploadBulkPasswordCSVRequest> =
  createAction('UPLOAD_BULK_PASSWORD_CSV');

export const uploadBulkPasswordCSVSuccess: ActionCreator<UploadBulkPasswordCSVResponse> =
  createAction('UPLOAD_BULK_PASSWORD_CSV_SUCCESS');

export const uploadBulkPasswordCSVFailure: ActionCreator<ErrorResponse> =
  createAction('UPLOAD_BULK_PASSWORD_CSV_FAILURE');

export const clearUploadBulkPasswordCSV: EmptyActionCreator = createAction(
  'CLEAR_UPLOAD_BULK_PASSWORD_CSV'
);
