import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createInviteMessageTemplateFailure,
  createInviteMessageTemplateSuccess,
} from './actions';
import { createInviteMessageTemplateApi } from './api';

export const createInviteMessageTemplateSaga = function* (
  action: any
): SagaIterator {
  try {
    const data = yield call(
      createInviteMessageTemplateApi(
        action.payload.collectorId,
        action.payload.data
      )
    );

    yield put(createInviteMessageTemplateSuccess(data));
  } catch (error) {
    yield put(createInviteMessageTemplateFailure(error));
  }
};
