import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getDefaultThemeFailure, getDefaultThemeSuccess } from './actions';
import { getDefaultThemeApi } from './api';

export const getDefaultThemeSaga = function* (): SagaIterator {
  try {
    const data = yield call(getDefaultThemeApi());

    yield put(getDefaultThemeSuccess(data));
  } catch (error) {
    yield put(getDefaultThemeFailure(error));
  }
};
