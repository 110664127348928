import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateBulkPasswordRequest } from './type';
import { ErrorResponse } from 'type';

export const updateBulkPassword: ActionCreator<UpdateBulkPasswordRequest> =
  createAction('UPDATE_BULK_PASSWORD');

export const updateBulkPasswordSuccess: EmptyActionCreator = createAction(
  'UPDATE_BULK_PASSWORD_SUCCESS'
);

export const updateBulkPasswordFailure: ActionCreator<ErrorResponse> =
  createAction('UPDATE_BULK_PASSWORD_FAILURE');

export const clearUpdateBulkPassword: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_BULK_PASSWORD'
);
