import produce from 'immer';

import {
  getFeaturedTemplates,
  getFeaturedTemplatesFailure,
  getFeaturedTemplatesSuccess,
} from './actions';
import { transformSurveyTemplates } from '../../../transformers/surveyTemplate';
import { GetFeaturedTemplatesResponse } from './type';
import { SurveyTemplateState } from '../type';
import { ErrorResponse } from 'type';

export function getFeaturedTemplatesReducer(
  state: SurveyTemplateState
): SurveyTemplateState {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getFeaturedTemplates.isLoading = true;
    draft.getFeaturedTemplates.isLoaded = false;
    draft.getFeaturedTemplates.isValid = false;
    draft.getFeaturedTemplates.count = 0;
    draft.getFeaturedTemplates.data = [];
  });
}

export function getFeaturedTemplatesSuccessReducer(
  state: SurveyTemplateState,
  data: GetFeaturedTemplatesResponse
): SurveyTemplateState {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getFeaturedTemplates.isLoading = false;
    draft.getFeaturedTemplates.isLoaded = true;
    draft.getFeaturedTemplates.isValid = true;
    draft.getFeaturedTemplates.count = data.data.count;
    draft.getFeaturedTemplates.data = transformSurveyTemplates(
      data.data.templates
    );
  });
}

export function getFeaturedTemplatesFailureReducer(
  state: SurveyTemplateState,
  error: ErrorResponse
): SurveyTemplateState {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getFeaturedTemplates.isLoading = false;
    draft.getFeaturedTemplates.isLoaded = true;
    draft.getFeaturedTemplates.error = error;
  });
}

export const getFeaturedTemplatesReducers = {
  [`${getFeaturedTemplates}`]: getFeaturedTemplatesReducer,
  [`${getFeaturedTemplatesSuccess}`]: getFeaturedTemplatesSuccessReducer,
  [`${getFeaturedTemplatesFailure}`]: getFeaturedTemplatesFailureReducer,
};
