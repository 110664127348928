import { ActionCreator, createAction } from 'redux-act';

import {
  GetAssociatedFiltersRequest,
  GetAssociatedFiltersResponse,
} from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getAssociatedFilters: ActionCreator<GetAssociatedFiltersRequest> =
  createAction('GET_ASSOCIATED_FILTERS');

export const getAssociatedFiltersSuccess: ActionCreator<GetAssociatedFiltersResponse> =
  createAction('GET_ASSOCIATED_FILTERS_SUCCESS');

export const getAssociatedFiltersFailure: ActionCreator<ErrorResponse> =
  createAction('GET_ASSOCIATED_FILTERS_FAILURE');

export const updateGetAssociatedFiltersQuery: ActionCreator<Query> =
  createAction('UPDATE_GET_ASSOCIATED_FILTERS_QUERY');
