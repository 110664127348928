import produce from 'immer';

import { getBulkUrl, getBulkUrlFailure, getBulkUrlSuccess } from './actions';
import { transformBulkUrl } from '../../../transformers';
import { getBulkUrlInitialState } from './state';
import { GetBulkUrlResponse } from './type';
import { BulkUrlState } from '../type';
import { ErrorResponse } from 'type';

export function getBulkUrlReducer(state: BulkUrlState): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.getBulkUrl = { ...getBulkUrlInitialState };
    draft.getBulkUrl.isLoading = true;
  });
}

export function getBulkUrlSuccessReducer(
  state: BulkUrlState,
  data: GetBulkUrlResponse
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.getBulkUrl.isLoading = false;
    draft.getBulkUrl.isLoaded = true;
    draft.getBulkUrl.isValid = true;
    draft.getBulkUrl.data = transformBulkUrl(data.data);
  });
}

export function getBulkUrlFailureReducer(
  state: BulkUrlState,
  error: ErrorResponse
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.getBulkUrl.isLoading = false;
    draft.getBulkUrl.isLoaded = true;
    draft.getBulkUrl.error = error;
  });
}

export const getBulkUrlReducers = {
  [`${getBulkUrl}`]: getBulkUrlReducer,
  [`${getBulkUrlSuccess}`]: getBulkUrlSuccessReducer,
  [`${getBulkUrlFailure}`]: getBulkUrlFailureReducer,
};
