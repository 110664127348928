import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateQuestionCategoryPayload } from './type';
import { ErrorResponse } from 'type';

export const updateQuestionCategory: ActionCreator<UpdateQuestionCategoryPayload> =
  createAction('UPDATE_QUESTION_CATEGORY');

export const updateQuestionCategorySuccess: EmptyActionCreator = createAction(
  'UPDATE_QUESTION_CATEGORY_SUCCESS'
);

export const updateQuestionCategoryFailure: ActionCreator<ErrorResponse> =
  createAction('UPDATE_QUESTION_CATEGORY_FAILURE');

export const clearUpdateQuestionCategory: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_QUESTION_CATEGORY'
);
