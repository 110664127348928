import { useDispatch, useSelector } from 'react-redux';
import EmailIcon from '@mui/icons-material/Email';
import Delete from '@mui/icons-material/Delete';
import { NativeTable } from 'elmo-elements';
import map from 'lodash/map';
import React from 'react';

import SummaryCard from 'element/card/components/summaryCard';
import { removeManageInviteReminder } from 'store/actions';
import translations from './translations';
import { RemindersProps } from './type';
import { RootState } from 'store/type';
import { t } from 'lib/translation';
import { Message } from 'type';

const ReminderMessages = ({ editReminder }: RemindersProps) => {
  const dispatch = useDispatch();

  const parameters = useSelector(
    (state: RootState) => state.emailInvites.manageInvite.parameters
  );
  const reminderMessages = useSelector(
    (state: RootState) => state.emailInvites.manageInvite.data.reminders
  );

  const removeReminder = (reminderId: number | undefined): void => {
    if (reminderId) {
      dispatch(removeManageInviteReminder(reminderId));
    }
  };

  return (
    <>
      {map(reminderMessages, (message: Message, key: number) => (
        <SummaryCard
          key={key}
          id={`reminder-message-${message.id}`}
          icon={<EmailIcon />}
          sectionTitle={`${t(translations.sectionTitle)} ${key + 1}`}
          hasPrimaryAction={!parameters.isEdit}
          primaryActionOnClick={() => editReminder(message.id)}
          hasSecondaryAction={!parameters.isEdit}
          secondaryActionIcon={<Delete />}
          secondaryActionLabel={t(translations.deleteReminder)}
          secondaryActionOnClick={() => removeReminder(message.id)}
        >
          <NativeTable>
            <NativeTable.Body>
              <NativeTable.Tr>
                <NativeTable.Th>{t(translations.subject)}</NativeTable.Th>
                <NativeTable.Td>{message.subject}</NativeTable.Td>
              </NativeTable.Tr>
            </NativeTable.Body>
          </NativeTable>
        </SummaryCard>
      ))}
    </>
  );
};

export default ReminderMessages;
