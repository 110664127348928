import produce from 'immer';

import {
  getIndividualLinks,
  getIndividualLinksFailure,
  getIndividualLinksSuccess,
  updateGetIndividualLinksQuery,
} from './actions';
import { transformSharedReportIndividuals } from '../../../transformers';
import { getIndividualLinksInitialState } from './state';
import { GetIndividualLinksResponse } from './type';
import { ShareReportState } from '../type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getIndividualLinksReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getIndividualLinks = { ...getIndividualLinksInitialState };
    draft.getIndividualLinks.isLoading = true;
  });
};

export const getIndividualLinksSuccessReducer = (
  state: ShareReportState,
  data: GetIndividualLinksResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getIndividualLinks.isLoading = false;
    draft.getIndividualLinks.isLoaded = true;
    draft.getIndividualLinks.isValid = true;
    draft.getIndividualLinks.count = data.data.count;
    draft.getIndividualLinks.list = transformSharedReportIndividuals(
      data.data.sharedReports
    );
  });
};

export const getIndividualLinksFailureReducer = (
  state: ShareReportState,
  error: ErrorResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getIndividualLinks.isLoading = false;
    draft.getIndividualLinks.isLoaded = true;
    draft.getIndividualLinks.error = error;
  });
};

export function updateGetIndividualLinksQueryReducer(
  state: ShareReportState,
  query: Query
): ShareReportState {
  return produce(state, (draft: ShareReportState) => {
    draft.getIndividualLinks.query = query;
  });
}

export const getIndividualLinkReducers = {
  [`${getIndividualLinks}`]: getIndividualLinksReducer,
  [`${getIndividualLinksSuccess}`]: getIndividualLinksSuccessReducer,
  [`${getIndividualLinksFailure}`]: getIndividualLinksFailureReducer,
  [`${updateGetIndividualLinksQuery}`]: updateGetIndividualLinksQueryReducer,
};
