import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import 'es6-promise/auto';

import { LANGUAGES } from './messages';
export { messages, LANGUAGES } from './messages';

/**
 * Wrapper function around the i18next translate function
 * Translates using the default value if translations have not been loaded yet
 *
 * @param translationData
 * @param options
 * @param translationDataPlural
 */
export function t(
  translationData: any,
  options: any = {},
  translationDataPlural: any = { default: undefined }
) {
  return i18n.t(translationData.key, {
    ...options,
    defaultValue: translationData.default,
    defaultValue_plural: translationDataPlural.default,
  });
}

i18n.use(initReactI18next).init({
  resources: {},
  fallbackLng: LANGUAGES.en,
  debug: false,
  lng: LANGUAGES.en, // set default language to "en"
});

export default i18n;
