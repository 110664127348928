import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import {
  CreateSurveyNotificationPayload,
  CreateSurveyNotificationResponse,
} from './type';
import { ErrorResponse } from 'type';

export const createSurveyNotification: ActionCreator<CreateSurveyNotificationPayload> =
  createAction('CREATE_SURVEY_NOTIFICATION');

export const createSurveyNotificationSuccess: ActionCreator<CreateSurveyNotificationResponse> =
  createAction('CREATE_SURVEY_NOTIFICATION_SUCCESS');

export const createSurveyNotificationFailure: ActionCreator<ErrorResponse> =
  createAction('CREATE_SURVEY_NOTIFICATION_FAILURE');

export const clearCreateSurveyNotification: EmptyActionCreator = createAction(
  'CLEAR_CREATE_SURVEY_NOTIFICATION'
);
