import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

export const getCollector: ActionCreator<number> =
  createAction('GET_COLLECTOR');

export const getCollectorSuccess: ActionCreator<any> = createAction(
  'GET_COLLECTOR_SUCCESS'
);

export const getCollectorFailure: EmptyActionCreator = createAction(
  'GET_COLLECTOR_FAILURE'
);

export const clearGetCollector: EmptyActionCreator = createAction(
  'CLEAR_GET_COLLECTOR'
);
