import { takeEvery } from 'redux-saga/effects';

import {
  associateFilters,
  createFilter,
  getAssociatedFilters,
  getFilter,
  getFilters,
  getFilterValues,
  updateFilter,
} from './actions';
import { getAssociatedFiltersSaga } from './getAssociatedFilters/sagas';
import { associateFiltersSaga } from './associateFilters/sagas';
import { getFilterValuesSaga } from './getFilterValues/sagas';
import { createFilterSaga } from './createFilter/sagas';
import { updateFilterSaga } from './updateFilter/sagas';
import { getFiltersSaga } from './getFilters/sagas';
import { getFilterSaga } from './getFilter/sagas';

export default function* sagas() {
  yield takeEvery(createFilter, createFilterSaga);
  yield takeEvery(getFilter, getFilterSaga);
  yield takeEvery(getFilters, getFiltersSaga);
  yield takeEvery(getFilterValues, getFilterValuesSaga);
  yield takeEvery(updateFilter, updateFilterSaga);
  yield takeEvery(getAssociatedFilters, getAssociatedFiltersSaga);
  yield takeEvery(associateFilters, associateFiltersSaga);
}
