import { CreateSurveyNotificationPayload } from './type';
import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId/notification';

export const createSurveyNotificationApi = (
  payload: CreateSurveyNotificationPayload
) => {
  return postRequest(
    getRoute(apiUrl, { params: { surveyId: payload.surveyId } }),
    payload.data
  );
};
