import React, { ReactElement } from 'react';

import PageLoading from 'element/PageLoading';

interface AppLoadingProps {
  isLoaded: boolean;
  children: ReactElement;
  error?: Error;
}

const AppLoading = ({
  children,
  isLoaded,
  error,
}: AppLoadingProps): ReactElement => {
  if (error) {
    return <div>Oh no!!!</div>;
  }

  if (isLoaded) {
    return children;
  }

  return <PageLoading text={'Loading...'} />;
};

export default AppLoading;
