import produce from 'immer';

import {
  clearDeleteQuestionCategory,
  deleteQuestionCategory,
  deleteQuestionCategoryFailure,
  deleteQuestionCategorySuccess,
} from './actions';
import { deleteQuestionCategoryInitialState } from './state';
import { questionCategoryInitialState } from '../state';
import { QuestionCategoryState } from '../type';

export function deleteQuestionCategoryReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.deleteQuestionCategory = { ...deleteQuestionCategoryInitialState };
    draft.deleteQuestionCategory.isLoading = true;
  });
}

export function deleteQuestionCategorySuccessReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.deleteQuestionCategory.isLoaded = true;
    draft.deleteQuestionCategory.isValid = true;
    draft.deleteQuestionCategory.isLoading = false;
  });
}

export function deleteQuestionCategoryFailureReducer(
  state: QuestionCategoryState,
  error: any
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.deleteQuestionCategory.isLoaded = true;
    draft.deleteQuestionCategory.isLoading = false;
    draft.deleteQuestionCategory.error = error;
  });
}

export function clearDeleteQuestionCategoryReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.deleteQuestionCategory =
      questionCategoryInitialState.deleteQuestionCategory;
  });
}

export const deleteQuestionCategoryReducers = {
  [`${deleteQuestionCategory}`]: deleteQuestionCategoryReducer,
  [`${deleteQuestionCategorySuccess}`]: deleteQuestionCategorySuccessReducer,
  [`${deleteQuestionCategoryFailure}`]: deleteQuestionCategoryFailureReducer,
  [`${clearDeleteQuestionCategory}`]: clearDeleteQuestionCategoryReducer,
};
