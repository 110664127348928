var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
function CardImageStyle(props) {
    return (React.createElement(LoaderHolder, __assign({ viewBoxHeight: 140, viewBoxWidth: 400 }, props),
        React.createElement("rect", { x: "0", y: "0", rx: "0", ry: "0", width: "400", height: "140" })));
}
export default CardImageStyle;
