import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { CreateBulkLinksRequest, CreateBulkLinksResponse } from './type';
import { ErrorResponse } from 'type';

export const createBulkLinks: ActionCreator<CreateBulkLinksRequest> =
  createAction('CREATE_BULK_LINKS');

export const createBulkLinksSuccess: ActionCreator<CreateBulkLinksResponse> =
  createAction('CREATE_BULK_LINKS_SUCCESS');

export const createBulkLinksFailure: ActionCreator<ErrorResponse> =
  createAction('CREATE_BULK_LINKS_FAILURE');

export const clearCreateBulkLinks: EmptyActionCreator = createAction(
  'CLEAR_CREATE_BULK_LINKS'
);
