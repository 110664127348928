import { Card as ElmoCard, Col, Row, Heading } from 'elmo-elements';
import React from 'react';

import { withHeadingProps } from './type';
import './style.scss';

const withHeading = ({
  key = 1,
  icon,
  heading,
  actions,
  children,
}: withHeadingProps) => {
  return (
    <span className={'card-with-outer-heading'}>
      <ElmoCard isFullHeight={true} id={`with-heading-card-${key}`}>
        <Row className={'heading-row align-content-between'} isNoGutters={true}>
          <Col xs={24} sm={18}>
            <span className={'heading-label-wrapper'}>
              <span className={'icon'}>{icon}</span>
              <span className={'heading'}>
                <Heading>{heading}</Heading>
              </span>
            </span>
          </Col>
          <Col xs={24} sm={6} isAuto={true}>
            {actions}
          </Col>
        </Row>
        {children}
      </ElmoCard>
    </span>
  );
};

export default withHeading;
