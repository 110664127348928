import {
  Col,
  Container,
  Layout,
  PlaceholderDashboard,
  Row,
} from 'elmo-elements';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import defaultsDeep from 'lodash/defaultsDeep';
import React, { useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import {
  clearManageInvite,
  transformManageInviteSavedInvite,
  updateManageInviteBulk,
  updateManageInviteParameter,
  getInviteMessageTemplates,
  clearGetInvite,
  getInvite,
  clearInviteParticipants,
  getCollector,
} from 'store/actions';
import { getDefaultInviteSender } from 'lib/sharedState/getDefaultInviteSender/actions';
import { manageInviteData } from 'store/modules/invites/manageInvite/state';
import DraftNotification from './components/draftNotification';
import Summary from './components/summary';
import Header from './components/header';
import Steps from './components/steps';
import { RootState } from 'store/type';

const ManageInvite = () => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const { surveyId, collectorId, inviteId } = useParams() as unknown as {
    surveyId: number;
    collectorId: number;
    inviteId: number;
  };

  const invite = useSelector(
    (state: RootState) => state.emailInvites.getInvite
  );
  const collector = useSelector(
    (state: RootState) => state.collector.getCollector
  );

  useEffect(() => {
    dispatch(getDefaultInviteSender());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInviteMessageTemplates(collectorId));
  }, [collectorId, dispatch]);

  useEffect(() => {
    if (!collector.isLoaded && !collector.isLoading) {
      dispatch(getCollector(collectorId));
    }
  }, [collector, collectorId, dispatch]);

  useEffect(() => {
    if (!inviteId) {
      const draft = localStorage.getItem('survey-invite-draft');

      if (draft && !isEqual(JSON.parse(draft), manageInviteData)) {
        dispatch(
          updateManageInviteBulk(
            defaultsDeep(JSON.parse(draft), manageInviteData)
          )
        );
        dispatch(
          updateManageInviteParameter({
            currentStep: '',
            isDraft: true,
            isFlow: false,
          })
        );
      }
    }
  }, [inviteId, dispatch]);

  useEffect(() => {
    if (inviteId) {
      dispatch(
        getInvite({
          collectorId: collectorId,
          emailRecipientListId: inviteId,
        })
      );
    }
  }, [inviteId, collectorId, dispatch]);

  useEffect(() => {
    if (inviteId && invite.isLoaded && invite.isValid) {
      dispatch(
        transformManageInviteSavedInvite({ data: invite.data, action: hash })
      );
    }
  }, [inviteId, invite, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearManageInvite());
      dispatch(clearInviteParticipants());
      dispatch(clearGetInvite());
    };
  }, [dispatch]);

  if (inviteId && !invite.isLoaded) {
    return (
      <>
        <Layout.Header>
          <Header
            surveyId={surveyId}
            collectorId={collectorId}
            inviteId={inviteId}
          />
        </Layout.Header>
        <Layout.Content>
          <Container size="lg">
            <Row>
              <Col md={8}>
                <PlaceholderDashboard cols={1} rows={1} />
              </Col>
              <Col md={16}>
                <PlaceholderDashboard cols={1} rows={4} />
              </Col>
            </Row>
          </Container>
        </Layout.Content>
      </>
    );
  }

  return (
    <>
      <Layout.Header>
        <Header
          surveyId={surveyId}
          collectorId={collectorId}
          inviteId={inviteId}
        />
      </Layout.Header>
      <Layout.Content>
        <DraftNotification collector={collector} />
        <Summary />
      </Layout.Content>
      <Steps surveyId={surveyId} collectorId={collectorId} />
    </>
  );
};

export default ManageInvite;
