import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getSurveyNotificationHistoryFailure,
  getSurveyNotificationHistorySuccess,
  updateSurveyNotificationHistoryQuery,
} from './actions';
import { getSurveyNotificationHistoryInitialState } from './state';
import { GetSurveyNotificationHistoryPayload } from './type';
import { getSurveyNotificationHistoryApi } from './api';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

export const getSurveyNotificationHistorySaga = function* (
  action: SagaAction<GetSurveyNotificationHistoryPayload>
): SagaIterator {
  try {
    const currentQuery = getQuery(
      getSurveyNotificationHistoryInitialState.query
    );

    yield put(updateSurveyNotificationHistoryQuery(currentQuery));

    const data = yield call(
      getSurveyNotificationHistoryApi(action.payload, currentQuery)
    );
    yield put(getSurveyNotificationHistorySuccess(data));
  } catch (error) {
    yield put(getSurveyNotificationHistoryFailure(error));
  }
};
