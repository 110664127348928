var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, Children } from 'react';
import { getClass } from '../../_lib/helper';
import DataTableTd from './DataTableTd';
import Checkbox from '../../Checkbox';
import DataTableActions from './DataTableActions';
import { withDataTableContext } from '../DataTableContextConsumer';
/**
 * The Row component of the DataTable
 */
var DataTableTr = /** @class */ (function (_super) {
    __extends(DataTableTr, _super);
    function DataTableTr() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleItemToggle = function () {
            var _a = _this.props, onItemToggle = _a.onItemToggle, isSelected = _a.isSelected, updateSelectAllCheckboxState = _a.updateSelectAllCheckboxState;
            var nextIsSelected = !isSelected;
            updateSelectAllCheckboxState(nextIsSelected);
            if (onItemToggle) {
                onItemToggle();
            }
        };
        _this.shouldShowActions = function () {
            var headerColumns = _this.props.headerColumns;
            return (headerColumns.filter(function (c) {
                return !c.isHidden;
            }).length > 0);
        };
        return _this;
    }
    DataTableTr.prototype.componentDidMount = function () {
        /**
         * Check if the row has actions. If it does, then set hasRowActions in the DataTable context to true.
         * This makes the Header set the colspan of its last column to 2 if the Actions column is shown.
         */
        var _a = this.props, actions = _a.actions, hasRowActions = _a.hasRowActions, setContextState = _a.setContextState;
        if (actions && !hasRowActions && setContextState) {
            setContextState({
                hasRowActions: true,
            });
        }
    };
    DataTableTr.prototype.renderColumns = function () {
        var _a = this.props, children = _a.children, headerColumns = _a.headerColumns;
        if (!headerColumns || headerColumns.length === 0) {
            return null;
        }
        return Children.map(children, function (child, index) {
            // check if it is in the list of visible elements
            if (headerColumns[index] && !headerColumns[index].isHidden) {
                return child;
            }
            return null;
        });
    };
    DataTableTr.prototype.render = function () {
        var _a = this.props, className = _a.className, isBulkActionOpen = _a.isBulkActionOpen, rowIndex = _a.rowIndex, isSelected = _a.isSelected, isDisabled = _a.isDisabled, actions = _a.actions, onItemToggle = _a.onItemToggle, isAllAvailableSelected = _a.isAllAvailableSelected, isResponsive = _a.isResponsive;
        var showCheckbox = isBulkActionOpen && onItemToggle && rowIndex !== undefined;
        return (React.createElement("tr", { className: getClass('elmo-datatable__tr', className) },
            showCheckbox && (React.createElement(DataTableTd, { className: "elmo-datatable__row-checkbox" },
                React.createElement(Checkbox, { id: 'datatable-' + rowIndex, isChecked: isSelected || isAllAvailableSelected, isDisabled: isDisabled, onChange: this.handleItemToggle, ariaLabel: "Select all" }))),
            this.renderColumns(),
            !isBulkActionOpen && actions && this.shouldShowActions() && (React.createElement(DataTableActions, { actions: actions, isInline: !isResponsive }))));
    };
    DataTableTr.displayName = 'DataTableTr';
    return DataTableTr;
}(Component));
export default withDataTableContext(DataTableTr);
