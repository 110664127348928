import React from 'react';
import './FilterDropdown.scss';
import FilterBadge from './FilterBadge';
import Dropdown from '../../Dropdown';
function FilterDropdown(_a) {
    var statuses = _a.statuses, onChange = _a.onChange;
    return (React.createElement(Dropdown, { className: "elmo-filter__dropdown", position: "bottom-end", ariaLabel: "Dropdown" }, statuses.map(function (status, index) {
        return (React.createElement(Dropdown.Item, { onClick: onChange, value: status.value, key: index },
            React.createElement("span", { className: "label" }, status.label),
            status.count !== undefined && (React.createElement(FilterBadge, { colour: status.colour }, status.count))));
    })));
}
export default FilterDropdown;
