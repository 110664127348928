import { CreateQuestionPayload } from './type';
import { postRequest } from 'lib/api';

const globalUrl = '/admin/questions';
const localUrl = '/questions';

export const createQuestionApi = (payload: CreateQuestionPayload) => {
  const url = payload.global ? globalUrl : localUrl;

  return postRequest(url, payload);
};
