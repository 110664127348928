import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/anon/shared-reports/:reportHash/theme';

export const getAnonSurveyThemeApi = (reportHash: string) => {
  return getRequest(
    getRoute(url, {
      params: { reportHash: reportHash },
    })
  );
};
