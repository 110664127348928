export var PopoverModes = {
    click: 'click',
    hover: 'hover',
};
export var PopoverPlacements = {
    autoStart: 'auto-start',
    auto: 'auto',
    autoEnd: 'auto-end',
    topStart: 'top-start',
    top: 'top',
    topEnd: 'top-end',
    rightStart: 'right-start',
    right: 'right',
    rightEnd: 'right-end',
    bottomEnd: 'bottom-end',
    bottom: 'bottom',
    bottomStart: 'bottom-start',
    leftEnd: 'left-end',
    left: 'left',
    leftStart: 'left-start',
};
export var PopoverEvents = ['click', 'touchstart', 'keyup'];
export var POPOVER_CONTAINER_ELEMENT_ID = 'PopoverContainer';
export var POPPER_MODIFIERS = {
    preventOverflow: {
        /**
         * The amount of space between the boundary and the popup. Used so that it doesn't stick
         * right to the edge of the window and cause a horizontal scroll
         */
        padding: 30,
    },
};
