import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getSurveysFailure,
  getSurveysSuccess,
  updateGetSurveysQuery,
} from './actions';
import { getSurveysInitialState } from './state';
import { getSurveysApi } from './api';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

export const getSurveysSaga = function* (
  action: SagaAction<string>
): SagaIterator {
  try {
    const currentQuery = getQuery(getSurveysInitialState.query);
    yield put(updateGetSurveysQuery(currentQuery));

    // Disconnect from currentQuery so status does not show in address bar
    const queryParams = {
      ...currentQuery,
      status: action.payload,
    };

    const data = yield call(getSurveysApi(queryParams));

    yield put(getSurveysSuccess(data));
  } catch (error) {
    yield put(getSurveysFailure(error));
  }
};
