/**
 *
 * @param error
 */
function error(e, data) {
    // TODO: WE NEED SOME KIND OF LOG SERVICE FOR OUR FRONT-END!!!
    console.error(e, data);
}
/**
 *
 * @param error
 */
function warn(data) {
    // TODO: WE NEED SOME KIND OF LOG SERVICE FOR OUR FRONT-END!!!
    console.warn(data);
}
var logger = {
    error: error,
    warn: warn,
};
export default logger;
