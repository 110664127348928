import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  deleteInviteMessageFailure,
  deleteInviteMessageSuccess,
} from './actions';
import { deleteInviteMessageApi } from './api';

export interface DeleteInviteMessageSagaProps {
  error: boolean;
  payload: number;
  type: string;
}

export const deleteInviteMessageSaga = function* (
  action: DeleteInviteMessageSagaProps
): SagaIterator {
  try {
    yield call(deleteInviteMessageApi(action.payload));

    yield put(deleteInviteMessageSuccess());
  } catch (error) {
    yield put(deleteInviteMessageFailure());
  }
};
