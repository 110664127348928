var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import IconStyle from './component/IconStyle';
import MenuStyle from './component/MenuStyle';
import AvatarStyle from './component/AvatarStyle';
import BulletListStyle from './component/BulletListStyle';
import CodeStyle from './component/CodeStyle';
import ContentStyle from './component/ContentStyle';
import LineStyle from './component/LineStyle';
import WordStyle from './component/WordStyle';
import TextStyle from './component/TextStyle';
import ShortTextStyle from './component/ShortTextStyle';
import ListStyle from './component/ListStyle';
import ButtonsStyle from './component/ButtonsStyle';
import ButtonStyle from './component/ButtonStyle';
import SpinnerStyle from './component/SpinnerStyle';
import NarrowContentStyle from './component/NarrowContentStyle';
import NarrowBulletListStyle from './component/NarrowBulletListStyle';
import CardContentStyle from './component/CardContentStyle';
import CardImageStyle from './component/CardImageStyle';
function Loader(props) {
    var type = props.type, children = props.children, isLoading = props.isLoading;
    var loaderProps = __assign({}, props);
    delete loaderProps.type;
    delete loaderProps.children;
    delete loaderProps.isLoading;
    if (!isLoading) {
        return React.createElement(React.Fragment, null, children);
    }
    switch (type) {
        case 'menu':
            return (React.createElement(MenuStyle, __assign({}, loaderProps, { ariaLabel: "Loading menu..." })));
        case 'icon':
            return (React.createElement(IconStyle, __assign({}, loaderProps, { ariaLabel: "Loading icon..." })));
        case 'avatar':
            return (React.createElement(AvatarStyle, __assign({}, loaderProps, { ariaLabel: "Loading avatar..." })));
        case 'button':
            return (React.createElement(ButtonStyle, __assign({}, loaderProps, { ariaLabel: "Loading button..." })));
        case 'buttons':
            return (React.createElement(ButtonsStyle, __assign({}, loaderProps, { ariaLabel: "Loading buttons..." })));
        case 'word':
            return (React.createElement(WordStyle, __assign({}, loaderProps, { ariaLabel: "Loading word..." })));
        case 'text':
            return (React.createElement(TextStyle, __assign({}, loaderProps, { ariaLabel: "Loading text..." })));
        case 'short-text':
            return (React.createElement(ShortTextStyle, __assign({}, loaderProps, { ariaLabel: "Loading short text..." })));
        case 'line':
            return (React.createElement(LineStyle, __assign({}, loaderProps, { ariaLabel: "Loading line..." })));
        case 'bullet':
            return (React.createElement(BulletListStyle, __assign({}, loaderProps, { ariaLabel: "Loading bullet..." })));
        case 'narrow-bullet':
            return (React.createElement(NarrowBulletListStyle, __assign({}, loaderProps, { ariaLabel: "Loading narrow bullet..." })));
        case 'code':
            return (React.createElement(CodeStyle, __assign({}, loaderProps, { ariaLabel: "Loading code..." })));
        case 'list':
            return (React.createElement(ListStyle, __assign({}, loaderProps, { ariaLabel: "Loading list..." })));
        case 'spinner':
            return (React.createElement(SpinnerStyle, __assign({}, loaderProps, { ariaLabel: "Loading spinner..." })));
        case 'narrow-content':
            return (React.createElement(NarrowContentStyle, __assign({}, loaderProps, { ariaLabel: "Loading narrow content..." })));
        case 'card-content':
            return (React.createElement(CardContentStyle, __assign({}, loaderProps, { ariaLabel: "Loading card content..." })));
        case 'card-image':
            return (React.createElement(CardImageStyle, __assign({}, loaderProps, { ariaLabel: "Loading card image..." })));
        case 'content':
        default:
            return React.createElement(ContentStyle, __assign({}, loaderProps));
    }
}
Loader.defaultProps = {
    type: 'content',
    isLoading: true,
};
export default Loader;
