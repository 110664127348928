var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import FormItemLabel from './component/FormItemLabel';
import FormItemControl from './component/FormItemControl';
import FormItemMessage from './component/FormItemMessage';
import FormItemIcon from './component/FormItemIcon';
import './FormItem.scss';
var FormItemDefaultProps = {
    status: 'default',
    labelAddon: null,
    message: '',
    isFluid: true,
};
var FormItem = /** @class */ (function (_super) {
    __extends(FormItem, _super);
    function FormItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getClassname = function () {
            var _a;
            var status = _this.getStatus();
            var _b = _this.props, isFluid = _b.isFluid, icon = _b.icon, isDivider = _b.isDivider, isInline = _b.isInline, size = _b.size, className = _b.className, isBlock = _b.isBlock;
            return getClass('elmo-formitem', className, (_a = {},
                _a["on-" + status] = status,
                _a["size-" + size] = size,
                _a["is-fluid"] = isFluid && !size,
                _a["is-inline"] = isInline,
                _a["is-icon"] = icon,
                _a["is-divider"] = isDivider !== undefined,
                _a["is-block-max2"] = isBlock,
                _a));
        };
        return _this;
    }
    FormItem.prototype.getStatus = function () {
        // if error returns any, set the status as error
        var _a = this.props, error = _a.error, status = _a.status, isTouched = _a.isTouched;
        return error && isTouched ? 'error' : status;
    };
    FormItem.prototype.getMessage = function () {
        // if error returns any, overwrite the message with error
        var _a = this.props, error = _a.error, message = _a.message, isTouched = _a.isTouched;
        return error && isTouched ? error : message;
    };
    FormItem.prototype.render = function () {
        var _a = this.props, label = _a.label, icon = _a.icon, labelAddon = _a.labelAddon, children = _a.children, id = _a.id;
        return (React.createElement("div", { id: id, className: this.getClassname(), "data-testid": "elmo-form-item-" + (id || 'default') },
            icon && React.createElement(FormItemIcon, { icon: icon }),
            React.createElement("div", { className: "elmo-formitem__body" },
                (label || labelAddon) && (React.createElement(FormItemLabel, { label: label, labelAddon: labelAddon })),
                React.createElement("div", { className: "elmo-formitem__control" },
                    React.createElement(FormItemControl, null, children),
                    React.createElement(FormItemMessage, { message: this.getMessage() })))));
    };
    FormItem.Label = FormItemLabel;
    FormItem.defaultProps = FormItemDefaultProps;
    return FormItem;
}(Component));
export default FormItem;
