import React from 'react';
import MessageBlockHeader from './component/MessageBlockHeader';
import MessageBlockBody from './component/MessageBlockBody';
import './MessageBlock.scss';
function MessageBlock(_a) {
    var children = _a.children, id = _a.id;
    return (React.createElement("div", { className: "elmo-message-block", "data-testid": "elmo-message-block-" + (id || 'default') }, children));
}
MessageBlock.Header = MessageBlockHeader;
MessageBlock.Body = MessageBlockBody;
export default MessageBlock;
