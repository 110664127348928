const translations = {
  title: {
    key: '',
    default: 'Confirm Delete Reminder Message',
  },
  content: {
    key: '',
    default: 'Are you sure you want to delete this reminder?',
  },
  confirmationButton: {
    key: '',
    default: 'Delete Reminder',
  },
  updateSuccess: {
    key: '',
    default: 'Reminder deleted successfully.',
  },
};

export default translations;
