import { takeEvery } from 'redux-saga/effects';

import {
  createEmailTemplate,
  getDefaultEmailTemplate,
  getEmailTemplate,
  getEmailTemplates,
  updateEmailTemplate,
} from './actions';
import { getDefaultEmailTemplateSaga } from './getDefaultEmailTemplate/sagas';
import { createEmailTemplateSaga } from './createEmailTemplate/sagas';
import { updateEmailTemplateSaga } from './updateEmailTemplate/sagas';
import { getEmailTemplatesSaga } from './getEmailTemplates/sagas';
import { getEmailTemplateSaga } from './getEmailTemplate/sagas';

export default function* sagas() {
  yield takeEvery(createEmailTemplate, createEmailTemplateSaga);
  yield takeEvery(getDefaultEmailTemplate, getDefaultEmailTemplateSaga);
  yield takeEvery(getEmailTemplate, getEmailTemplateSaga);
  yield takeEvery(getEmailTemplates, getEmailTemplatesSaga);
  yield takeEvery(updateEmailTemplate, updateEmailTemplateSaga);
}
