import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetSurveyAccessListResponse } from './type';
import { ErrorResponse } from 'type';

export const getSurveyAccessList: ActionCreator<number> =
  createAction('GET_SURVEY_USERS');

export const getSurveyAccessListSuccess: ActionCreator<GetSurveyAccessListResponse> =
  createAction('GET_SURVEY_USERS_SUCCESS');

export const getSurveyAccessListFailure: ActionCreator<ErrorResponse> =
  createAction('GET_SURVEY_USERS_FAILURE');

export const clearGetSurveyAccessList: EmptyActionCreator = createAction(
  'CLEAR_GET_SURVEY_USERS'
);
