import { createReducer } from 'redux-act';

import { getSurveyNotificationHistoryReducers } from './getSurveyNotificationHistory/reducers';
import { createSurveyNotificationReducers } from './createSurveyNotification/reducers';
import { updateSurveyNotificationReducers } from './updateSurveyNotification/reducers';
import { getSurveyNotificationsReducers } from './getSurveyNotifications/reducers';
import { getSurveyNotificationReducers } from './getSurveyNotification/reducers';
import { surveyNotificationInitialState } from './state';

export default createReducer(
  {
    ...createSurveyNotificationReducers,
    ...getSurveyNotificationReducers,
    ...getSurveyNotificationsReducers,
    ...updateSurveyNotificationReducers,
    ...getSurveyNotificationHistoryReducers,
  },
  surveyNotificationInitialState
);
