import produce from 'immer';

import {
  associateFilters,
  associateFiltersFailure,
  associateFiltersSuccess,
  clearAssociateFilters,
} from './actions';
import { associateFiltersInitialState } from './state';
import { FilterState } from '../type';
import { ErrorResponse } from 'type';

export function associateFiltersReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.associateFilters = { ...associateFiltersInitialState };
    draft.associateFilters.isLoading = true;
  });
}

export function associateFiltersSuccessReducer(
  state: FilterState
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.associateFilters.isLoading = false;
    draft.associateFilters.isLoaded = true;
    draft.associateFilters.isValid = true;
  });
}

export function associateFiltersFailureReducer(
  state: FilterState,
  error: ErrorResponse
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.associateFilters.isLoading = false;
    draft.associateFilters.isLoaded = true;
    draft.associateFilters.error = error;
  });
}

export function clearAssociateFiltersReducer(state: FilterState): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.associateFilters = associateFiltersInitialState;
  });
}

export const associateFiltersReducers = {
  [`${associateFilters}`]: associateFiltersReducer,
  [`${associateFiltersSuccess}`]: associateFiltersSuccessReducer,
  [`${associateFiltersFailure}`]: associateFiltersFailureReducer,
  [`${clearAssociateFilters}`]: clearAssociateFiltersReducer,
};
