import produce from 'immer';

import { Item as InitialItem } from 'lib/stateTemplates';
import { CreateRespondentState } from './type';

export const createRespondentInitialState: CreateRespondentState = produce(
  InitialItem,
  (draft: CreateRespondentState) => {
    draft.data = {};
  }
);
