import { UploadedFile } from 'elmo-elements/FileUpload';

import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/collectors/:collectorId/email-recipient-lists/import-csv/upload';

export const uploadInviteRecipientsCSVApi = (payload: {
  collectorId: number;
  file: UploadedFile;
}) => {
  let formData = new FormData();

  // @ts-ignore
  formData.append('file', payload.file);

  return postRequest(
    getRoute(url, {
      params: { collectorId: payload.collectorId },
    }),
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
};
