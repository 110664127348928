import produce from 'immer';

import { Item as InitialItem } from 'lib/stateTemplates';
import { GetSurveyState } from './type';
import { Survey } from 'type';

export const getSurveyInitialState: GetSurveyState = produce(
  InitialItem,
  (draft: GetSurveyState) => {
    draft.surveyId = undefined;
    draft.data = {} as Survey;
  }
);
