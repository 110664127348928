import produce from 'immer';

import {
  getSurvey,
  getSurveyFailure,
  getSurveySuccess,
  clearGetSurvey,
} from './actions';
import { transformSurvey } from '../../../transformers';
import { getSurveyInitialState } from './state';
import { surveyInitialState } from '../state';
import { SurveyState } from '../type';

export function getSurveyReducer(
  state: SurveyState,
  surveyId: number | string
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurvey = { ...getSurveyInitialState };
    draft.getSurvey.isLoading = true;
    draft.getSurvey.surveyId = surveyId;
  });
}

export function getSurveySuccessReducer(
  state: SurveyState,
  data: any
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurvey.isLoaded = true;
    draft.getSurvey.isLoading = false;
    draft.getSurvey.isValid = true;
    draft.getSurvey.data = transformSurvey(data.data);
  });
}

export function getSurveyFailureReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurvey.isLoaded = true;
    draft.getSurvey.isLoading = false;
    draft.getSurvey.isValid = false;
  });
}

export function clearGetSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurvey = surveyInitialState.getSurvey;
  });
}

export const getSurveyReducers = {
  [`${getSurvey}`]: getSurveyReducer,
  [`${getSurveySuccess}`]: getSurveySuccessReducer,
  [`${getSurveyFailure}`]: getSurveyFailureReducer,
  [`${clearGetSurvey}`]: clearGetSurveyReducer,
};
