import produce from 'immer';

import {
  clearCreateInviteRecipients,
  createInviteRecipients,
  createInviteRecipientsFailure,
  createInviteRecipientsSuccess,
} from './actions';
import { createInviteRecipientsInitialState } from './state';
import { InvitesState } from '../type';

export function createInviteRecipientsReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.createInviteRecipients.isCreated = false;
    draft.createInviteRecipients.isCreating = true;
    draft.createInviteRecipients.isValid = false;
    draft.createInviteRecipients.data = undefined;
  });
}

export function createInviteRecipientsSuccessReducer(
  state: InvitesState,
  data: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.createInviteRecipients.isCreated = true;
    draft.createInviteRecipients.isCreating = false;
    draft.createInviteRecipients.isValid = true;
    draft.createInviteRecipients.data = data.data;
  });
}

export function createInviteRecipientsFailureReducer(
  state: InvitesState,
  error: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.createInviteRecipients.isCreated = true;
    draft.createInviteRecipients.isCreating = false;
    draft.createInviteRecipients.error = error;
  });
}

export function clearCreateInviteRecipientsReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.createInviteRecipients = createInviteRecipientsInitialState;
  });
}

export const createInviteRecipientsReducers = {
  [`${createInviteRecipients}`]: createInviteRecipientsReducer,
  [`${createInviteRecipientsSuccess}`]: createInviteRecipientsSuccessReducer,
  [`${createInviteRecipientsFailure}`]: createInviteRecipientsFailureReducer,
  [`${clearCreateInviteRecipients}`]: clearCreateInviteRecipientsReducer,
};
