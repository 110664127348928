import produce from 'immer';

import {
  clearUpdateInviteRecipient,
  updateInviteRecipient,
  updateInviteRecipientFailure,
  updateInviteRecipientSuccess,
} from './actions';
import { updateInviteRecipientInitialState } from './state';
import { InvitesState } from '../type';

export function updateInviteRecipientReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteRecipient.isUpdated = false;
    draft.updateInviteRecipient.isUpdating = true;
  });
}

export function updateInviteRecipientSuccessReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteRecipient.isUpdated = true;
    draft.updateInviteRecipient.isUpdating = false;
  });
}

export function updateInviteRecipientFailureReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteRecipient.isUpdated = false;
    draft.updateInviteRecipient.isUpdating = false;
  });
}

export function clearUpdateInviteRecipientReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.updateInviteRecipient = updateInviteRecipientInitialState;
  });
}

export const updateInviteRecipientReducers = {
  [`${updateInviteRecipient}`]: updateInviteRecipientReducer,
  [`${updateInviteRecipientSuccess}`]: updateInviteRecipientSuccessReducer,
  [`${updateInviteRecipientFailure}`]: updateInviteRecipientFailureReducer,
  [`${clearUpdateInviteRecipient}`]: clearUpdateInviteRecipientReducer,
};
