import { all, call, put, take, select } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  getSavedOverviewReportSuccess,
  getCollectorFilterOptions,
  getCollectorFilterOptionsSuccess,
  getOverviewReportFilterOptions,
  getOverviewReportFilterOptionsSuccess,
  setOverviewReportFilters,
  getOverviewReportStats,
  getSavedOverviewReport,
  loadSavedOverviewReportFailure,
  loadSavedOverviewReportSuccess,
  bulkUpdateQuestionSettings,
} from '../actions';
import { getSelectedFilters } from 'page/OverviewReport/state/sagas/overviewReportHelpers';
import { getSavedQuestionSettings } from '../getSavedOverviewReport/selectors';
import { countSelectedFilters } from 'lib/helpers/reports';
import { LoadSavedOverviewReportPayload } from './type';
import { SagaAction, SelectedFiltersState } from 'type';

export const loadSavedOverviewReportSaga = function* (
  action: SagaAction<LoadSavedOverviewReportPayload>
): SagaIterator {
  try {
    const { surveyId } = action.payload;

    yield put(getSavedOverviewReport(action.payload));
    yield put(getCollectorFilterOptions({ surveyId, searchText: '' }));
    yield put(getOverviewReportFilterOptions(surveyId));

    yield all([
      take(getSavedOverviewReportSuccess),
      take(getCollectorFilterOptionsSuccess),
      take(getOverviewReportFilterOptionsSuccess),
    ]);

    const selectedFilters: SelectedFiltersState = yield call(
      getSelectedFilters
    );

    yield put(
      setOverviewReportFilters({
        selectedFilters: selectedFilters,
        selectedFiltersCount: countSelectedFilters(selectedFilters),
      })
    );

    const questionSettings = yield select(getSavedQuestionSettings);

    yield put(bulkUpdateQuestionSettings(questionSettings));

    yield put(getOverviewReportStats(surveyId));

    yield put(loadSavedOverviewReportSuccess());
  } catch (error) {
    yield put(loadSavedOverviewReportFailure(error));
  }
};
