import * as HttpStatus from 'http-status-codes';

import {
  isSharedReport,
  isSharedReportLoginPage,
} from '../helpers/sharedReports';
import { removeJWTAndPromptForLogin } from '../authentication';
import { shouldIgnoreError } from '../errors';
import { ErrorResponse } from '../../type';
import history from '../history';

export const UNAUTHORIZED_ERROR = 'Unauthorized error';

class ErrorApiResponseHandler {
  public async handleError(error: any) {
    const responseError: ErrorResponse = {
      data: error.response.data,
      status: error.response.status,
      headers: error.response.headers,
    };

    switch (true) {
      case shouldIgnoreError(error.response.config.url):
        return Promise.reject(responseError);
      case isSharedReportLoginPage():
        return Promise.reject(responseError);
      case isSharedReport() && HttpStatus.NOT_FOUND === error.response.status:
      case HttpStatus.UNAUTHORIZED === error.response.status:
        removeJWTAndPromptForLogin();
        return Promise.reject(UNAUTHORIZED_ERROR);
      case HttpStatus.FORBIDDEN === error.response.status:
        history.push('/no-access');
        break;
      case HttpStatus.NOT_FOUND === error.response.status:
        history.push('not-found');
        break;
      default:
        return Promise.reject(responseError);
    }
  }
}

export default new ErrorApiResponseHandler();
