import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { default as HeaderElement } from 'sharedComponents/Header';
import translations from './translations';
import { headerProps } from './type';
import { t } from 'lib/translation';
import { getRoute } from 'lib/util';
import history from 'lib/history';

const Header = ({
  surveyId,
  collectorId,
  inviteId,
}: headerProps): ReactElement => {
  const invite = useSelector((state: any) => state.emailInvites.getInvite);

  const backButton = () => {
    return {
      onClick: () => {
        history.push(
          getRoute('/surveys/:surveyId/edit/:collectorId/email-invites', {
            params: { surveyId: surveyId, collectorId: collectorId },
          })
        );
      },
    };
  };

  return (
    <HeaderElement
      title={
        inviteId && invite.isLoaded ? invite.data.title : t(translations.title)
      }
      isLoading={inviteId ? invite.isLoading : false}
      getBackButton={backButton()}
      subHeading={t(translations.subTitle)}
    />
  );
};

export default Header;
