import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { RestoreResponsePayload } from './type';
import { ErrorResponse } from 'type';

export const restoreResponse: ActionCreator<RestoreResponsePayload> =
  createAction('RESTORE_RESPONSE');

export const restoreResponseSuccess: EmptyActionCreator = createAction(
  'RESTORE_RESPONSE_SUCCESS'
);

export const restoreResponseFailure: ActionCreator<ErrorResponse> =
  createAction('RESTORE_RESPONSE_FAILURE');

export const clearRestoreResponse: EmptyActionCreator = createAction(
  'CLEAR_RESTORE_RESPONSE'
);
