export default {
  columns: {
    surveyName: {
      key: 'reports.overviewList.list.columns.surveyName',
      default: 'Survey',
    },
    status: {
      key: 'reports.overviewList.list.columns.status',
      default: 'Status',
    },
    publishDate: {
      key: 'reports.overviewList.list.columns.publishDate',
      default: 'Published date',
    },
    responses: {
      key: 'reports.overviewList.list.columns.responses',
      default: 'Responses',
    },
  },
};
