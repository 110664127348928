import moment from 'moment-timezone';

import { Collector, FullCollector, Survey } from '../type';

export const generateSurveyUrl = (
  survey: Survey,
  collector: Collector
): string => {
  return `${window.location.origin}/run-survey/${survey.id}/${collector.hash}`;
};

export function getCollectorUrl(survey: Survey, collector: Collector): string {
  if (collector.shortUrl) {
    return collector.shortUrl;
  }

  return generateSurveyUrl(survey, collector);
}

export const isCollectorActive = (collector: FullCollector): boolean => {
  if (!collector.active) {
    return false;
  }

  return !collector.closedDate ? true : moment(collector.closedDate).isAfter();
};
