import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { CreateQuestionPayload } from './type';
import { ErrorResponse } from 'type';

export const createQuestion: ActionCreator<CreateQuestionPayload> =
  createAction('CREATE_QUESTION');

export const createQuestionSuccess: EmptyActionCreator = createAction(
  'CREATE_QUESTION_SUCCESS'
);

export const createQuestionFailure: ActionCreator<ErrorResponse> = createAction(
  'CREATE_QUESTION_FAILURE'
);

export const clearCreateQuestion: EmptyActionCreator = createAction(
  'CLEAR_CREATE_QUESTION'
);
