import map from 'lodash/map';

import { APISurveyUser, User } from 'type';
import { transformUser } from './user';

export const transformSurveyAccessList = (surveyUser: APISurveyUser): User => {
  return transformUser(surveyUser.user);
};

export const transformSurveyAccessLists = (
  surveyUsers: APISurveyUser[]
): User[] => {
  return map(surveyUsers, transformSurveyAccessList);
};
