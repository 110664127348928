import { takeEvery } from 'redux-saga/effects';

import {
  getAnonSurveyTheme,
  getDefaultTheme,
  getThemes,
  deleteTheme,
  setDefaultTheme,
  createTheme,
  updateTheme,
} from './actions';
import { getAnonSurveyThemeSaga } from './getAnonSurveyTheme/sagas';
import { getDefaultThemeSaga } from './getDefaultTheme/sagas';
import { setDefaultThemeSaga } from './setDefaultTheme/sagas';
import { createThemeSaga } from './createTheme/sagas';
import { updateThemeSaga } from './updateTheme/sagas';
import { deleteThemeSaga } from './deleteTheme/sagas';
import { getThemesSaga } from './getThemes/sagas';
import { getThemeSaga } from './getTheme/sagas';
import { getTheme } from './getTheme/actions';

export default function* sagas() {
  yield takeEvery(getAnonSurveyTheme, getAnonSurveyThemeSaga);
  yield takeEvery(getDefaultTheme, getDefaultThemeSaga);
  yield takeEvery(getThemes, getThemesSaga);
  yield takeEvery(deleteTheme, deleteThemeSaga);
  yield takeEvery(setDefaultTheme, setDefaultThemeSaga);
  yield takeEvery(createTheme, createThemeSaga);
  yield takeEvery(updateTheme, updateThemeSaga);
  yield takeEvery(getTheme, getThemeSaga);
}
