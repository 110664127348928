import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { CreateRespondentProgressPayload } from './type';

export const createRespondentProgress: ActionCreator<CreateRespondentProgressPayload> =
  createAction('CREATE_RESPONDENT_PROGRESS');

export const createRespondentProgressSuccess: EmptyActionCreator = createAction(
  'CREATE_RESPONDENT_PROGRESS_SUCCESS'
);

export const createRespondentProgressFailure: EmptyActionCreator = createAction(
  'CREATE_RESPONDENT_PROGRESS_FAILURE'
);
