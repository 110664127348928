import React, { ReactElement } from 'react';
import { Loader } from 'elmo-elements';

export interface PageLoadingProps {
  text: string;
}

const PageLoading = (props: PageLoadingProps): ReactElement => {
  return (
    <div className="app-loading-element">
      <Loader type="spinner" />
      {props.text}
    </div>
  );
};

export default PageLoading;
