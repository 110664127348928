import { takeEvery } from 'redux-saga/effects';

import {
  addCollector,
  getCollector,
  getCollectors,
  getCollectorUsage,
  updateCollector,
} from './actions';
import { getCollectorUsageSaga } from './getCollectorUsage/sagas';
import { updateCollectorSaga } from './updateCollector/sagas';
import { getCollectorsSaga } from './getCollectors/sagas';
import { getCollectorSaga } from './getCollector/sagas';
import { addCollectorSaga } from './addCollector/sagas';

export default function* sagas() {
  yield takeEvery(addCollector, addCollectorSaga);
  yield takeEvery(getCollector, getCollectorSaga);
  yield takeEvery(getCollectors, getCollectorsSaga);
  yield takeEvery(getCollectorUsage, getCollectorUsageSaga);
  yield takeEvery(updateCollector, updateCollectorSaga);
}
