import { Search as ElmoSearch } from 'elmo-elements';
import React, { useState } from 'react';
import unset from 'lodash/unset';

import { getCurrentQuery } from 'lib/query';
import { history } from 'lib/history';
import { getRoute } from 'lib/util';

export interface SearchProps {
  isOpen: boolean;
  searchPath?: string | null;
  placeholder?: string;
  closeSearch: () => void;
  updateCallback?: () => void;
  initSearchValue?: string;
}

const Search = ({
  isOpen,
  placeholder,
  closeSearch,
  searchPath,
  updateCallback,
  initSearchValue = '',
}: SearchProps) => {
  const [searchValue, updateSearchValue] = useState<string>(initSearchValue);

  if (!searchPath || !updateCallback) {
    return <></>;
  }

  const existingQuery = () => {
    const query = getCurrentQuery();

    unset(query, ['page']);
    unset(query, ['limit']);
    unset(query, ['keyword']);

    return query;
  };

  const handleSearchSubmit = () => {
    history.push(
      getRoute(searchPath, {
        query: {
          ...existingQuery(),
          keyword: searchValue,
        },
      })
    );

    updateCallback();
  };

  const handleCloseSearch = () => {
    updateSearchValue('');
    closeSearch();

    history.push(getRoute(searchPath, { query: existingQuery() }));

    updateCallback();
  };

  return (
    <ElmoSearch
      id={'header-search'}
      isVisible={isOpen}
      onClose={handleCloseSearch}
      onChange={updateSearchValue}
      onSubmit={handleSearchSubmit}
      value={searchValue}
      placeholder={placeholder}
    />
  );
};

export default Search;
