import { APIQuestion, ErrorResponse, Question, QuestionsQuery } from 'type';

export interface GetQuestionsState {
  isLoaded: boolean;
  isLoading: boolean;
  isValid: boolean;
  count: number;
  data: Question[];
  errors?: ErrorResponse;
  query: QuestionsQuery;
}

export interface GetQuestionsRequest {
  query?: QuestionsQuery;
}

export interface GetQuestionsResponse {
  data: {
    count: number;
    questions: APIQuestion[];
  };
}

export enum QuestionSearchField {
  CATEGORY_TITLE = 'category-title',
  QUESTION = 'question',
}
