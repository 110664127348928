import { createAction, EmptyActionCreator } from 'redux-act';

export const initializeApp: EmptyActionCreator = createAction('INITIALIZE_APP');

export const initializeAppSuccess: EmptyActionCreator = createAction(
  'INITIALIZE_APP_SUCCESS'
);

export const initializeAppFailure: EmptyActionCreator = createAction(
  'INITIALIZE_APP_FAILURE'
);
