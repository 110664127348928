import React from 'react';
import ListTable from '../../ListTable';
import Loader from '../../Loader';
import './PlaceholderListTable.scss';
/**
 * HeaderColumnLabel
 */
function HeaderColumnLabel() {
    return (React.createElement("div", { className: "elmo-listtable-header-label--placeholder" },
        React.createElement(Loader, { type: "word", width: "50px", height: "18px" })));
}
/**
 * ColumnLabel
 */
function ColumnLabel() {
    var type = Math.random() < 0.5 ? 'word' : 'short-text';
    return (React.createElement("div", { className: "elmo-listtable-label--placeholder" },
        React.createElement(Loader, { type: type, width: "50px", height: "18px" })));
}
/**
 * List table cols
 *
 * @param
 */
function getListTableCols(cols) {
    var elements = [];
    for (var i = 0; i < cols - 1; i++) {
        elements.push(React.createElement(ListTable.Td, { key: i },
            React.createElement(ColumnLabel, null)));
    }
    return elements;
}
/**
 * List table header
 *
 * @param
 */
function getListTableHeader(cols) {
    var elements = [];
    for (var i = 0; i < cols; i++) {
        elements.push(React.createElement(ListTable.Column, { key: i, label: React.createElement(HeaderColumnLabel, null), sortable: true, isPlaceholder: true }));
    }
    return elements;
}
/**
 * List table body
 *
 * @param
 */
function getListTableBody(rows, cols, hasAvatar) {
    var elements = [];
    for (var i = 0; i < rows; i++) {
        elements.push(React.createElement(ListTable.Tr, { key: i, icon: React.createElement(Loader, { type: "icon" }) },
            React.createElement(ListTable.Td, null, hasAvatar ? React.createElement(Loader, { type: "avatar" }) : React.createElement(ColumnLabel, null)),
            getListTableCols(cols)));
    }
    return elements;
}
/**
 * Placeholder list table
 */
function PlaceholderListTable(_a) {
    var id = _a.id, cols = _a.cols, rows = _a.rows, hasAvatar = _a.hasAvatar;
    return (React.createElement(ListTable, { hasBulkActionsButton: false, id: id },
        React.createElement(ListTable.Header, null, getListTableHeader(cols)),
        React.createElement(ListTable.Body, null, getListTableBody(rows, cols, hasAvatar))));
}
PlaceholderListTable.defaultProps = {
    cols: 5,
    rows: 5,
    hasAvatar: true,
};
export default PlaceholderListTable;
