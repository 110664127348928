export var DropdownPositions = {
    autoStart: 'auto-start',
    auto: 'auto',
    autoEnd: 'auto-end',
    topStart: 'top-start',
    top: 'top',
    topEnd: 'top-end',
    rightStart: 'right-start',
    right: 'right',
    rightEnd: 'right-end',
    bottomEnd: 'bottom-end',
    bottom: 'bottom',
    bottomStart: 'bottom-start',
    leftEnd: 'left-end',
    left: 'left',
    leftStart: 'left-start',
};
export var DropdownItemTypes = {
    default: 'default',
    danger: 'danger',
};
export var DropdownUserEvents = ['click', 'touchstart', 'keyup'];
export var DropdownKeyConstants = {
    arrowDownKey: 'ArrowDown',
    arrowUpKey: 'ArrowUp',
    enterKey: 'Enter',
    escapeKey: 'Escape',
};
