export enum AnalyticsProfileProperties {
  // report
  SURVEY_REPORTS_BULK_SHARED_COUNT = 'surveyReportsBulkSharedCount',
  SURVEY_REPORTS_BULK_DOWNLOADED_COUNT = 'surveyReportsBulkDownloadedCount',
  SURVEY_REPORTS_SHARED_COUNT = 'surveyReportsSharedCount',
  SURVEY_REPORT_HTML_QUESTION_TOGGLED_COUNT = 'surveyReportHtmlQuestionToggledCount',
  SURVEY_REPORT_FILTER_BUTTON_CLICKED_COUNT = 'surveyReportFilterButtonClickedCount',
  SURVEY_REPORT_BREAKDOWN_BUTTON_CLICKED_COUNT = 'surveyReportBreakdownButtonClickedCount',
  SURVEY_REPORT_FILTER_APPLY_BUTTON_CLICKED_COUNT = 'surveyReportFilterApplyButtonClickedCount',
  // help centre
  SURVEY_HELP_CENTRE_DOCUMENT_DOWNLOADED_COUNT = 'surveyHelpCentreDocumentDownloadedCount',
}
