import { GetThemeResponse } from './type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const getThemeRoute = '/theme/:id';

export const getThemeApi = (id: string): (() => Promise<GetThemeResponse>) =>
  getRequest(
    getRoute(getThemeRoute, {
      params: { id },
    })
  );
