import produce from 'immer';

import {
  clearUpdateQuestionCategory,
  updateQuestionCategory,
  updateQuestionCategoryFailure,
  updateQuestionCategorySuccess,
} from './actions';
import { updateQuestionCategoryInitialState } from './state';
import { questionCategoryInitialState } from '../state';
import { QuestionCategoryState } from '../type';

export function updateQuestionCategoryReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.updateQuestionCategory = { ...updateQuestionCategoryInitialState };
    draft.updateQuestionCategory.isLoading = true;
  });
}

export function updateQuestionCategorySuccessReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.updateQuestionCategory.isLoaded = true;
    draft.updateQuestionCategory.isValid = true;
    draft.updateQuestionCategory.isLoading = false;
  });
}

export function updateQuestionCategoryFailureReducer(
  state: QuestionCategoryState,
  error: any
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.updateQuestionCategory.isLoaded = true;
    draft.updateQuestionCategory.isLoading = false;
    draft.updateQuestionCategory.error = error;
  });
}

export function clearUpdateQuestionCategoryReducer(
  state: QuestionCategoryState
): QuestionCategoryState {
  return produce(state, (draft: QuestionCategoryState) => {
    draft.updateQuestionCategory =
      questionCategoryInitialState.updateQuestionCategory;
  });
}

export const updateQuestionCategoryReducers = {
  [`${updateQuestionCategory}`]: updateQuestionCategoryReducer,
  [`${updateQuestionCategorySuccess}`]: updateQuestionCategorySuccessReducer,
  [`${updateQuestionCategoryFailure}`]: updateQuestionCategoryFailureReducer,
  [`${clearUpdateQuestionCategory}`]: clearUpdateQuestionCategoryReducer,
};
