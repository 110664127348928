import produce from 'immer';

import {
  createSurveyTemplate,
  createSurveyTemplateFailure,
  createSurveyTemplateSuccess,
  clearCreateSurveyTemplate,
} from './actions';
import { transformSurveyTemplate } from '../../../transformers/surveyTemplate';
import { createSurveyTemplateInitialState } from './state';
import { CreateSurveyTemplateResponse } from './type';
import { SurveyTemplateState } from '../type';
import { ErrorResponse } from 'type';

export const createSurveyTemplateReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.createSurveyTemplate = { ...createSurveyTemplateInitialState };
    draft.createSurveyTemplate.isLoading = true;
  });
};

export const createSurveyTemplateSuccessReducer = (
  state: SurveyTemplateState,
  data: CreateSurveyTemplateResponse
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.createSurveyTemplate.isLoading = false;
    draft.createSurveyTemplate.isLoaded = true;
    draft.createSurveyTemplate.isValid = true;
    draft.createSurveyTemplate.data = transformSurveyTemplate(data.data);
  });
};

export const createSurveyTemplateFailureReducer = (
  state: SurveyTemplateState,
  error: ErrorResponse
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.createSurveyTemplate.isLoading = false;
    draft.createSurveyTemplate.isLoaded = true;
    draft.createSurveyTemplate.error = error;
  });
};

export const clearCreateSurveyTemplateReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.createSurveyTemplate = createSurveyTemplateInitialState;
  });
};

export const createSurveyTemplateReducers = {
  [`${createSurveyTemplate}`]: createSurveyTemplateReducer,
  [`${createSurveyTemplateSuccess}`]: createSurveyTemplateSuccessReducer,
  [`${createSurveyTemplateFailure}`]: createSurveyTemplateFailureReducer,
  [`${clearCreateSurveyTemplate}`]: clearCreateSurveyTemplateReducer,
};
