import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateFilterPayload } from './type';
import { ErrorResponse } from 'type';

export const updateFilter: ActionCreator<UpdateFilterPayload> =
  createAction('UPDATE_FILTER');

export const updateFilterSuccess: EmptyActionCreator = createAction(
  'UPDATE_FILTER_SUCCESS'
);

export const updateFilterFailure: ActionCreator<ErrorResponse> = createAction(
  'UPDATE_FILTER_FAILURE'
);

export const clearUpdateFilter: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_FILTER'
);
