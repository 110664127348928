import React, { ReactElement } from 'react';
import { Layout } from 'elmo-elements';

import EmptyState from 'sharedComponents/EmptyState';
import translations from '../../translations';

const NotFound = (): ReactElement => {
  return (
    <Layout.Content>
      <EmptyState content={translations.notFound} hasPrimaryButton={false} />
    </Layout.Content>
  );
};

export default NotFound;
