import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url =
  '/collectors/:collectorId/email-recipient-lists/:listId/email-recipients';

export const createInviteRecipientsApi = (
  collectorId: number,
  listId: number,
  payload: any
) => {
  return postRequest(
    getRoute(url, {
      params: { collectorId: collectorId, listId: listId },
    }),
    payload
  );
};
