import { isSharedReport } from 'lib/helpers/sharedReports';
import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/surveys/:surveyId/overview-reports/stats';
const sharedReportApiUrl = '/shared-reports/:surveyId/overview-reports/stats';

export const getOverviewReportStatsApi = (
  surveyId: number | string,
  filters: any
): any => {
  const route = isSharedReport() ? sharedReportApiUrl : apiUrl;

  return postRequest(
    getRoute(route, {
      params: { surveyId: surveyId },
    }),
    filters
  );
};
