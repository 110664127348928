import React, { useEffect, useRef } from 'react';
import { Card } from 'elmo-elements';
import get from 'lodash/get';

import { WithCardProps } from './type';
import Stepper from '../../index';
import './styles.scss';

const WithCard = ({ steps, activeStep, heading }: WithCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const setStepperWidth = () => {
    const parentWidth = get(cardRef.current, 'parentElement.clientWidth');

    if (parentWidth && cardRef.current) {
      cardRef.current.style.width = parentWidth - 30 + 'px';
    }
  };

  useEffect(() => {
    setStepperWidth();
    window.addEventListener('resize', setStepperWidth);
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', setStepperWidth);
    };
  }, []);

  return (
    <div id={'stepper-with-card'} className={'content-fixed'} ref={cardRef}>
      <Card>
        <Stepper steps={steps} activeStep={activeStep} heading={heading} />
      </Card>
    </div>
  );
};

export default WithCard;
