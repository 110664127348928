import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createQuestionCategoryFailure,
  createQuestionCategorySuccess,
} from './actions';
import { CreateQuestionCategoryPayload } from './type';
import { createQuestionCategoryApi } from './api';
import { SagaAction } from 'type';

export const createQuestionCategorySaga = function* (
  action: SagaAction<CreateQuestionCategoryPayload>
): SagaIterator {
  try {
    yield call(createQuestionCategoryApi(action.payload));

    yield put(createQuestionCategorySuccess());
  } catch (error) {
    yield put(createQuestionCategoryFailure(error));
  }
};
