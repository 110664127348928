const translations = {
  reminderLabel: {
    key: '',
    default: 'Reminder {{index}} Schedule',
  },
  reminderSentLabel: {
    key: '',
    default: 'Reminder {{index}} Sent',
  },
  deleteButtonExistingReminder: {
    key: '',
    default: 'Delete',
  },
  deleteButtonNewReminder: {
    key: '',
    default: 'Delete New Reminder',
  },
};

export default translations;
