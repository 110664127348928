import { isSharedReport } from 'lib/helpers/sharedReports';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId/overview-report/:reportId';
const sharedReportApiUrl =
  '/shared-reports/:surveyId/overview-report/:reportId';

export const getSavedOverviewReportApi = (
  surveyId: number | string,
  reportId: number
): any => {
  const route = isSharedReport() ? sharedReportApiUrl : apiUrl;

  return getRequest(
    getRoute(route, { params: { surveyId: surveyId, reportId: reportId } })
  );
};
