import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateQuestionCategoryFailure,
  updateQuestionCategorySuccess,
} from './actions';
import { UpdateQuestionCategoryPayload } from './type';
import { updateQuestionCategoryApi } from './api';
import { SagaAction } from 'type';

export const updateQuestionCategorySaga = function* (
  action: SagaAction<UpdateQuestionCategoryPayload>
): SagaIterator {
  try {
    yield call(updateQuestionCategoryApi(action.payload));

    yield put(updateQuestionCategorySuccess());
  } catch (error) {
    yield put(updateQuestionCategoryFailure(error));
  }
};
