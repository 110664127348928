import { Notification as ElmoNotification } from 'elmo-elements';
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useEffect } from 'react';

import { hideNotification } from 'store/actions';
import { RootState } from 'store/type';
import './Notification.scss';

const Notification = (): ReactElement => {
  const dispatch = useDispatch();

  const notificationState = useSelector(
    (state: RootState) => state.app.notification
  );

  const closeNotification = () => {
    dispatch(hideNotification());
  };

  useEffect(() => {
    if (notificationState.isVisible) {
      const timeout = setInterval(() => {
        dispatch(hideNotification());
        clearInterval(timeout);
      }, 7000);
    }
  }, [notificationState.isVisible, dispatch]);

  return (
    <>
      <ElmoNotification
        id={'survey-notification'}
        isActive={notificationState.isVisible}
        message={notificationState.message}
        onClose={closeNotification}
      />
    </>
  );
};

export default Notification;
