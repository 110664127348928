import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetSurveyTemplatePayload, GetSurveyTemplateResponse } from './type';
import { ErrorResponse } from 'type';

export const getSurveyTemplate: ActionCreator<GetSurveyTemplatePayload> =
  createAction('GET_SURVEY_TEMPLATE');

export const getSurveyTemplateSuccess: ActionCreator<GetSurveyTemplateResponse> =
  createAction('GET_SURVEY_TEMPLATE_SUCCESS');

export const getSurveyTemplateFailure: ActionCreator<ErrorResponse> =
  createAction('GET_SURVEY_TEMPLATE_FAILURE');

export const clearGetSurveyTemplate: EmptyActionCreator = createAction(
  'CLEAR_GET_SURVEY_TEMPLATE'
);
