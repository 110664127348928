import produce from 'immer';

import {
  updateSurveyNotification,
  updateSurveyNotificationFailure,
  updateSurveyNotificationSuccess,
  clearUpdateSurveyNotification,
} from './actions';
import { updateSurveyNotificationInitialState } from './state';
import { UpdateSurveyNotificationPayload } from './type';
import { SurveyNotificationState } from '../type';
import { ErrorResponse } from 'type';

export const updateSurveyNotificationReducer = (
  state: SurveyNotificationState,
  payload: UpdateSurveyNotificationPayload
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.updateSurveyNotification = {
      ...updateSurveyNotificationInitialState,
    };
    draft.updateSurveyNotification.isLoading = true;
    draft.updateSurveyNotification.payload = payload;
  });
};

export const updateSurveyNotificationSuccessReducer = (
  state: SurveyNotificationState
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.updateSurveyNotification.isLoading = false;
    draft.updateSurveyNotification.isLoaded = true;
    draft.updateSurveyNotification.isValid = true;
  });
};

export const updateSurveyNotificationFailureReducer = (
  state: SurveyNotificationState,
  error: ErrorResponse
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.updateSurveyNotification.isLoading = false;
    draft.updateSurveyNotification.isLoaded = true;
    draft.updateSurveyNotification.error = error;
  });
};

export const clearUpdateSurveyNotificationReducer = (
  state: SurveyNotificationState
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.updateSurveyNotification = updateSurveyNotificationInitialState;
  });
};

export const updateSurveyNotificationReducers = {
  [`${updateSurveyNotification}`]: updateSurveyNotificationReducer,
  [`${updateSurveyNotificationSuccess}`]:
    updateSurveyNotificationSuccessReducer,
  [`${updateSurveyNotificationFailure}`]:
    updateSurveyNotificationFailureReducer,
  [`${clearUpdateSurveyNotification}`]: clearUpdateSurveyNotificationReducer,
};
