import { CreateBulkLinksRequest } from './type';
import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const route = '/reports/:surveyId/sharedbulk';

export const createBulkLinksApi = (request: CreateBulkLinksRequest) => {
  return postRequest(
    getRoute(route, { params: { surveyId: request.surveyId } }),
    request.data
  );
};
