import produce from 'immer';

import {
  getDefaultInviteSender,
  getDefaultInviteSenderFailure,
  getDefaultInviteSenderSuccess,
} from './actions';
import { sharedStateInitialState } from '../type';

export function getDefaultInviteSenderReducer(state: sharedStateInitialState) {
  return produce(state, (draft) => {
    draft.getDefaultInviteSender.isLoaded = false;
    draft.getDefaultInviteSender.isLoading = true;
    draft.getDefaultInviteSender.isValid = false;
    draft.getDefaultInviteSender.data = undefined;
  });
}

export function getDefaultInviteSenderSuccessReducer(
  state: sharedStateInitialState,
  data: any
) {
  return produce(state, (draft) => {
    draft.getDefaultInviteSender.isLoaded = true;
    draft.getDefaultInviteSender.isLoading = false;
    draft.getDefaultInviteSender.isValid = true;
    draft.getDefaultInviteSender.data = data.data;
  });
}

export function getDefaultInviteSenderFailureReducer(
  state: sharedStateInitialState
) {
  return produce(state, (draft) => {
    draft.getDefaultInviteSender.isLoaded = true;
    draft.getDefaultInviteSender.isLoading = false;
    draft.getDefaultInviteSender.isValid = false;
  });
}

export const getDefaultInviteSenderReducers = {
  [`${getDefaultInviteSender}`]: getDefaultInviteSenderReducer,
  [`${getDefaultInviteSenderSuccess}`]: getDefaultInviteSenderSuccessReducer,
  [`${getDefaultInviteSenderFailure}`]: getDefaultInviteSenderFailureReducer,
};
