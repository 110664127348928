import { takeEvery } from 'redux-saga/effects';

import {
  getDefaultSender,
  getMenu,
  getSiteTheme,
  initializeApp,
} from './actions';
import { getDefaultSenderSaga } from './getDefaultSender/sagas';
import { initializeAppSaga } from './initializeApp/sagas';
import { getSiteThemeSaga } from './getSiteTheme/sagas';
import { getMenuSaga } from './getMenu/sagas';

export default function* sagas() {
  yield takeEvery(getMenu, getMenuSaga);
  yield takeEvery(initializeApp, initializeAppSaga);
  yield takeEvery(getDefaultSender, getDefaultSenderSaga);
  yield takeEvery(getSiteTheme, getSiteThemeSaga);
}
