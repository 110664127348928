import produce from 'immer';

import {
  transformMessage,
  transformMessages,
  transformRecipients,
} from '../../../transformers';
import {
  clearGetInvite,
  getInvite,
  getInviteFailure,
  getInviteSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function getInviteReducer(state: InvitesState, data: any): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInvite.isLoaded = false;
    draft.getInvite.isLoading = true;
    draft.getInvite.isValid = false;
    draft.getInvite.data = undefined;
    draft.getInvite.inviteId = data.emailRecipientListId;
  });
}

export function getInviteSuccessReducer(
  state: InvitesState,
  data: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInvite.isLoaded = true;
    draft.getInvite.isLoading = false;
    draft.getInvite.isValid = true;
    draft.getInvite.data = {
      createdAt: data.data.created_at,
      recipients: transformRecipients(data.data.email_recipients),
      id: data.data.id,
      inviteMessage: data.data.invite_messages[0]
        ? transformMessage(data.data.invite_messages[0])
        : null,
      isDeleted: data.data.is_deleted,
      lastInviteDate: data.data.last_invite_date,
      lastResponseDate: data.data.last_response_date,
      reminderMessages: transformMessages(data.data.reminder_messages),
      title: data.data.title,
      updatedAt: data.data.updated_at,
    };
  });
}

export function getInviteFailureReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInvite.isLoaded = true;
    draft.getInvite.isLoading = false;
    draft.getInvite.isValid = false;
  });
}

export function clearGetInviteReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInvite = invitesInitialState.getInvite;
  });
}

export const getInviteReducers = {
  [`${getInvite}`]: getInviteReducer,
  [`${getInviteSuccess}`]: getInviteSuccessReducer,
  [`${getInviteFailure}`]: getInviteFailureReducer,
  [`${clearGetInvite}`]: clearGetInviteReducer,
};
