import AlternateEmail from '@mui/icons-material/AlternateEmail';
import ImportExport from '@mui/icons-material/ImportExport';
import { Col, FormItem, Heading, Row } from 'elmo-elements';
import People from '@mui/icons-material/People';
import React from 'react';

import { isInviteCSVImportEnabled } from 'lib/helpers/featureFlag';
import BaseModal from 'element/Modal/components/baseModal';
import CardButton from 'sharedComponents/CardButton';
import { RecipientsTypeSelectorProps } from './type';
import translations from './translations';
import { t } from 'lib/translation';
import './style.scss';

const RecipientsTypeSelector = ({
  recipientsState,
  updateRecipientsStateByField,
  isFlow,
  closeModal,
  previousStep,
}: RecipientsTypeSelectorProps) => {
  const updateInputType = (value: string) => {
    updateRecipientsStateByField('inputType', value);
  };

  return (
    <BaseModal
      id={'invite-recipients-type-selector'}
      modalType={'x-large'}
      closeModal={closeModal}
      title={
        <>
          <People className={'header-icon'} />
          {t(translations.title)}
        </>
      }
      hasPrimaryButton={false}
      buttonsType={'separate'}
      hasSecondaryButton={isFlow}
      secondaryButtonClick={previousStep}
      secondaryButtonText={t(translations.previousButton)}
    >
      <>
        <Heading htmlTag="h5" type="title" size="xs">
          {t(translations.heading)}
        </Heading>
        <Row>
          <Col xs={24}>
            <FormItem label={''}>
              <Row>
                <Col md={8} xs={24}>
                  <CardButton
                    id={'tms-profiles'}
                    icon={<People />}
                    heading={t(translations.steps.tmsProfiles)}
                    doAction={() => updateInputType('selectTmsProfiles')}
                    isActive={recipientsState.inputType === 'selectTmsProfiles'}
                  />
                </Col>
                {isInviteCSVImportEnabled() && (
                  <Col md={8} xs={24}>
                    <CardButton
                      id={'csv-import'}
                      icon={<ImportExport />}
                      heading={t(translations.steps.csvImport)}
                      doAction={() => updateInputType('csvImport')}
                      isActive={recipientsState.inputType === 'csvImport'}
                    />
                  </Col>
                )}
                <Col md={8} xs={24}>
                  <CardButton
                    id={'manual-input'}
                    icon={<AlternateEmail />}
                    heading={t(translations.steps.manualInput)}
                    doAction={() => updateInputType('manualInput')}
                    isActive={recipientsState.inputType === 'manualInput'}
                  />
                </Col>
              </Row>
            </FormItem>
          </Col>
        </Row>
      </>
    </BaseModal>
  );
};

export default RecipientsTypeSelector;
