const translations = {
  stepperHeading: {
    key: '',
    default: 'Invite Steps',
  },
  steps: {
    details: {
      label: {
        key: '',
        default: 'List Details',
      },
      description: {
        key: '',
        default: 'Give your list a name for reports',
      },
    },
    recipients: {
      label: {
        key: '',
        default: 'Participants',
      },
      description: {
        key: '',
        default: 'Who are you inviting',
      },
    },
    message: {
      label: {
        key: '',
        default: 'Email Message',
      },
      description: {
        key: '',
        default: 'Message details',
      },
    },
    reminder: {
      label: {
        key: '',
        default: 'Reminders',
      },
      description: {
        key: '',
        default: 'Reminder details (Optional)',
      },
    },
    sendInvite: {
      label: {
        key: '',
        default: 'Schedule/Send Now',
      },
      description: {
        key: '',
        default: 'Date and time',
      },
    },
  },
};

export default translations;
