var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { CheckIcon as SelectedIcon } from '../../Icon';
import './SelectListOption.scss';
import { getClass } from '../../_lib/helper';
var SelectListOption = /** @class */ (function (_super) {
    __extends(SelectListOption, _super);
    function SelectListOption() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Called when the option is selected/deselected
         */
        _this.handleToggle = function () {
            var _a = _this.props, onToggle = _a.onToggle, isSelected = _a.isSelected, selectListHandleToggle = _a.selectListHandleToggle, isDisabled = _a.isDisabled;
            if (isDisabled || !onToggle) {
                return;
            }
            onToggle();
            if (selectListHandleToggle) {
                selectListHandleToggle(!isSelected);
            }
        };
        return _this;
    }
    SelectListOption.prototype.renderIcon = function () {
        var _a = this.props, icon = _a.icon, isSelected = _a.isSelected;
        if (isSelected) {
            return (React.createElement("span", { className: "elmo-select-list__selected" }, icon ? icon : React.createElement(SelectedIcon, null)));
        }
        else if (isSelected === undefined) {
            return icon ? icon : null;
        }
    };
    SelectListOption.prototype.render = function () {
        var _a = this.props, children = _a.children, isDisabled = _a.isDisabled;
        return (React.createElement("div", { className: getClass('elmo-select-list__option', {}, {
                disabled: isDisabled,
            }), onClick: this.handleToggle, role: "listitem", tabIndex: 0 },
            React.createElement("span", { className: "elmo-select-list__item" }, children),
            this.renderIcon()));
    };
    return SelectListOption;
}(Component));
export default SelectListOption;
