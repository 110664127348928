import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  deleteQuestionCategoryFailure,
  deleteQuestionCategorySuccess,
} from './actions';
import { DeleteQuestionCategoryPayload } from './type';
import { deleteQuestionCategoryApi } from './api';
import { SagaAction } from 'type';

export const deleteQuestionCategorySaga = function* (
  action: SagaAction<DeleteQuestionCategoryPayload>
): SagaIterator {
  try {
    yield call(deleteQuestionCategoryApi(action.payload));

    yield put(deleteQuestionCategorySuccess());
  } catch (error) {
    yield put(deleteQuestionCategoryFailure(error));
  }
};
