import { GenerateBulkUrlsPayload } from './type';
import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/bulkurl-collector/:collectorId/generate-urls';

export const generateBulkUrlsApi = (payload: GenerateBulkUrlsPayload): any => {
  return postRequest(
    getRoute(apiUrl, {
      params: {
        collectorId: payload.collectorId,
      },
    }),
    payload.data
  );
};
