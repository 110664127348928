var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import Button from '../Button';
import Avatar from '../Avatar';
export var Nav = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  ", "\n"], ["\n  ", "\n\n  ", "\n"])), function (_a) {
    var menuBackgroundColor = _a.menuBackgroundColor;
    return menuBackgroundColor &&
        "\n      background-color: " + menuBackgroundColor + " !important;\n  ";
}, function (_a) {
    var menuFont = _a.menuFont;
    return menuFont &&
        "\n    font-family: " + menuFont + ";\n  ";
});
export var CompanyLogoWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var logoHref = _a.logoHref;
    return "\n    cursor: pointer;\n  ";
});
export var AdminLink = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n\n  ", "\n"], ["\n  ", "\n\n  ", "\n"])), function (_a) {
    var adminBackgroundColor = _a.adminBackgroundColor;
    return adminBackgroundColor &&
        "\n    background-color: " + adminBackgroundColor + " !important;\n  ";
}, function (_a) {
    var adminColor = _a.adminColor;
    return adminColor &&
        "\n    a{\n      color: " + adminColor + " !important;\n    }\n  ";
});
export var Header = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var profileBackgroundColor = _a.profileBackgroundColor;
    return profileBackgroundColor &&
        "\n    background-color: " + profileBackgroundColor + " !important;\n  ";
});
export var MobileHeaderStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n\n  ", "\n"], ["\n  ", "\n\n  ", "\n"])), function (_a) {
    var backgroundColor = _a.backgroundColor;
    return backgroundColor &&
        "\n    .elmo-nav-header-mobile__icon .elmo-btn .elmo-icon:before{\n      background-color: " + backgroundColor + " !important;\n    }\n  ";
}, function (_a) {
    var color = _a.color;
    return color &&
        "\n    .elmo-nav-header-mobile__icon .elmo-btn .elmo-btn__label .elmo-icon{\n    color: " + color + " !important;\n    }\n  ";
});
export var StyledLink = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  ", "\n\n  ", "\n\n  ", "\n"])), function (_a) {
    var fontColor = _a.fontColor, isSubMenu = _a.isSubMenu;
    return !isSubMenu &&
        fontColor &&
        "\n    color: " + fontColor + " !important;\n    opacity: 1 !important;;\n  ";
}, function (_a) {
    var hoverFontColor = _a.hoverFontColor, isSubMenu = _a.isSubMenu;
    return !isSubMenu &&
        hoverFontColor &&
        "\n    &:hover{\n      color: " + hoverFontColor + " !important;\n    }\n  ";
}, function (_a) {
    var isSelected = _a.isSelected, hoverFontColor = _a.hoverFontColor;
    return isSelected === true &&
        hoverFontColor &&
        "\n      color: " + hoverFontColor + " !important;\n  ";
});
export var UserDetailsStyled = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n\n  ", "\n"], ["\n  ", "\n\n  ", "\n"])), function (_a) {
    var profileColor = _a.profileColor;
    return profileColor &&
        "\n    color: " + profileColor + " !important;\n  ";
}, function (_a) {
    var profileBackgroundColor = _a.profileBackgroundColor;
    return profileBackgroundColor &&
        "\n      background-color: " + profileBackgroundColor + " !important;\n  ";
});
export var UserMenuStyled = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", "\n\n  ", "\n"], ["\n  ", "\n\n  ", "\n"])), function (_a) {
    var profileColor = _a.profileColor;
    return profileColor &&
        "\n    a {\n      color: " + profileColor + " !important;\n    }\n  ";
}, function (_a) {
    var profileBackgroundColor = _a.profileBackgroundColor;
    return profileBackgroundColor &&
        "\n      background-color: " + profileBackgroundColor + " !important;\n  ";
});
export var UserMenuWrapperStyled = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", "\n\n  ", "\n"], ["\n  ", "\n\n  ", "\n"])), function (_a) {
    var menuFont = _a.menuFont;
    return menuFont &&
        "\n    * {\n      font-family: " + menuFont + " !important;\n    }\n  ";
}, function (_a) {
    var profileBackgroundColor = _a.profileBackgroundColor;
    return profileBackgroundColor &&
        "\n      background-color: " + profileBackgroundColor + " !important;\n  ";
});
export var EmptyIconPlaceholder = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 0 47px 0 16px;\n  display: inline-flex;\n  position: relative;\n"], ["\n  margin: 0 47px 0 16px;\n  display: inline-flex;\n  position: relative;\n"])));
export var UserAvatar = styled(Avatar)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  & .elmo-avatar__label {\n    .elmo-nav-layout--is-collapsed & {\n    }\n  }\n"], ["\n  & .elmo-avatar__label {\n    .elmo-nav-layout--is-collapsed & {\n    }\n  }\n"])));
export var UserDetailsButton = styled(Button)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", "\n\n  cursor: pointer;\n  & .elmo-btn__pressed,\n  & .elmo-btn__focused,\n  & .elmo-btn__hover {\n    background-color: transparent !important;\n  }\n  &:hover,\n  &:hover .elmo-btn__hover {\n    background-color: transparent !important;\n  }\n"], ["\n  ", "\n\n  cursor: pointer;\n  & .elmo-btn__pressed,\n  & .elmo-btn__focused,\n  & .elmo-btn__hover {\n    background-color: transparent !important;\n  }\n  &:hover,\n  &:hover .elmo-btn__hover {\n    background-color: transparent !important;\n  }\n"])), function (_a) {
    var profileColor = _a.profileColor;
    return profileColor &&
        "\n      color: " + profileColor + " !important;\n  ";
});
export var SubMenuStyled = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var menuFont = _a.menuFont;
    return menuFont &&
        "\n    * {\n      font-family: " + menuFont + " !important;\n    }\n  ";
});
export var MenuFootnoteStyled = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), function (_a) {
    var menuFontColor = _a.menuFontColor;
    return menuFontColor &&
        "\n    * {\n      color: " + menuFontColor + " !important;\n    }\n  ";
}, function (_a) {
    var menuFont = _a.menuFont;
    return menuFont &&
        "\n    font-family: " + menuFont + ";\n  ";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
