import produce from 'immer';

import { showNotification, hideNotification } from './actions';
import { AppState } from '../type';

export function showNotificationReducer(
  state: AppState,
  data: string
): AppState {
  return produce(state, (draft: AppState) => {
    draft.notification.isVisible = true;
    draft.notification.message = data;
  });
}

export function hideNotificationReducer(state: AppState): AppState {
  return produce(state, (draft: AppState) => {
    draft.notification.isVisible = false;
    draft.notification.message = '';
  });
}

export const notificationReducers = {
  [`${showNotification}`]: showNotificationReducer,
  [`${hideNotification}`]: hideNotificationReducer,
};
