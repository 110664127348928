import produce from 'immer';

import {
  clearGetSurveyTemplate,
  getSurveyTemplate,
  getSurveyTemplateFailure,
  getSurveyTemplateSuccess,
} from './actions';
import { transformSurveyTemplate } from '../../../transformers/surveyTemplate';
import { getSurveyTemplateInitialState } from './state';
import { GetSurveyTemplateResponse } from './type';
import { SurveyTemplateState } from '../type';
import { ErrorResponse } from 'type';

export const getSurveyTemplateReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getSurveyTemplate = { ...getSurveyTemplateInitialState };
    draft.getSurveyTemplate.isLoading = true;
  });
};

export const getSurveyTemplateSuccessReducer = (
  state: SurveyTemplateState,
  data: GetSurveyTemplateResponse
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getSurveyTemplate.isLoading = false;
    draft.getSurveyTemplate.isLoaded = true;
    draft.getSurveyTemplate.isValid = true;
    draft.getSurveyTemplate.data = transformSurveyTemplate(data.data);
  });
};

export const getSurveyTemplateFailureReducer = (
  state: SurveyTemplateState,
  error: ErrorResponse
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getSurveyTemplate.isLoading = false;
    draft.getSurveyTemplate.isLoaded = true;
    draft.getSurveyTemplate.error = error;
  });
};

export const clearGetSurveyTemplateReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.getSurveyTemplate = { ...getSurveyTemplateInitialState };
  });
};

export const getSurveyTemplateReducers = {
  [`${getSurveyTemplate}`]: getSurveyTemplateReducer,
  [`${getSurveyTemplateSuccess}`]: getSurveyTemplateSuccessReducer,
  [`${getSurveyTemplateFailure}`]: getSurveyTemplateFailureReducer,
  [`${clearGetSurveyTemplate}`]: clearGetSurveyTemplateReducer,
};
