import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const route = '/anon/survey/:surveyHash';

export const getPreviewSurveyApi = (surveyHash: string) => {
  return getRequest(
    getRoute(route, {
      params: { surveyHash: surveyHash },
    })
  );
};
