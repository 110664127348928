import produce from 'immer';

import {
  updateQuestionSettings,
  bulkUpdateQuestionSettings,
  clearUpdateQuestionSettings,
} from './actions';
import { updateQuestionSettingsInitialState } from './state';
import { UpdateQuestionSettingsPayload } from './type';
import { QuestionSettingsBulkGroup } from 'type';
import { OverviewReportState } from '../type';

export const updateQuestionSettingsReducer = (
  state: OverviewReportState,
  data: UpdateQuestionSettingsPayload
): OverviewReportState => {
  return produce(state, (draft: OverviewReportState) => {
    draft.updateQuestionSettings[data.name] = data.settings;
  });
};

export const bulkUpdateQuestionSettingsReducer = (
  state: OverviewReportState,
  data: QuestionSettingsBulkGroup
): OverviewReportState => {
  return produce(state, (draft: OverviewReportState) => {
    draft.updateQuestionSettings = data;
  });
};

export const clearUpdateQuestionSettingsReducer = (
  state: OverviewReportState
): OverviewReportState => {
  return produce(state, (draft: OverviewReportState) => {
    draft.updateQuestionSettings = updateQuestionSettingsInitialState;
  });
};

export const updateQuestionSettingsReducers = {
  [`${updateQuestionSettings}`]: updateQuestionSettingsReducer,
  [`${bulkUpdateQuestionSettings}`]: bulkUpdateQuestionSettingsReducer,
  [`${clearUpdateQuestionSettings}`]: clearUpdateQuestionSettingsReducer,
};
