import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { Schedules } from '../manageInvite/type';

export const updateInviteSchedules: ActionCreator<{
  scheduleData: Schedules;
  collectorId: number;
}> = createAction<{ scheduleData: Schedules; collectorId: number }>(
  'UPDATE_INVITE_SCHEDULES'
);

export const updateInviteSchedulesSuccess: EmptyActionCreator = createAction(
  'UPDATE_INVITE_SCHEDULES_SUCCESS'
);

export const updateInviteSchedulesFailure: EmptyActionCreator = createAction(
  'UPDATE_INVITE_SCHEDULES_FAILURE'
);

export const clearUpdateInviteSchedules: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_INVITE_SCHEDULES'
);
