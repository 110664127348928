import flatMap from 'lodash/flatMap';

export const hasPropertyValue = (
  element: any,
  property: string,
  value: string
): boolean => {
  return element.hasOwnProperty(property) && element[property] === value;
};

export const getSurveyQuestions = (structure: any) => {
  if (structure && structure.pages) {
    return flatMap(structure.pages, (page: any) => {
      return page.elements || [];
    });
  }

  return [];
};

export const getEmailInputQuestions = (structure: any): any[] => {
  const surveyQuestions = getSurveyQuestions(structure);

  return surveyQuestions.filter((question: any) => {
    return (
      hasPropertyValue(question, 'type', 'text') &&
      hasPropertyValue(question, 'inputType', 'email')
    );
  });
};

export const getChoiceQuestions = (structure: any): any[] => {
  const surveyQuestions = getSurveyQuestions(structure);

  return surveyQuestions.filter((question: any) => {
    return (
      question.type &&
      ['checkbox', 'radiogroup', 'dropdown', 'rating', 'boolean'].includes(
        question.type
      )
    );
  });
};

export const getQuestionById = (structure: any, questionId: string): any => {
  const surveyQuestions = getSurveyQuestions(structure);

  for (const question of surveyQuestions) {
    if (hasPropertyValue(question, 'name', questionId)) {
      return question;
    }
  }

  return null;
};

export const getChoicesForQuestion = (question: any) => {
  switch (question.type) {
    case 'rating':
      return question.rateValues;
    case 'boolean':
      return [
        { text: 'False', value: 0 },
        { text: 'True', value: 1 },
      ];
    case 'matrix':
    case 'matrixdropdown':
      return [];
    default:
      return question.choices;
  }
};

export const surveyHasQuestions = (structure: any): boolean => {
  const questions = getSurveyQuestions(structure);

  return questions.length > 0;
};
