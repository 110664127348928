import produce from 'immer';

import {
  clearUpdateQuestion,
  updateQuestion,
  updateQuestionFailure,
  updateQuestionSuccess,
} from './actions';
import { updateQuestionInitialState } from './state';
import { questionInitialState } from '../state';
import { QuestionState } from '../type';

export function updateQuestionReducer(state: QuestionState): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.updateQuestion = { ...updateQuestionInitialState };
    draft.updateQuestion.isUpdating = true;
  });
}

export function updateQuestionSuccessReducer(
  state: QuestionState
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.updateQuestion.isUpdated = true;
    draft.updateQuestion.isValid = true;
    draft.updateQuestion.isUpdating = false;
  });
}

export function updateQuestionFailureReducer(
  state: QuestionState,
  errors: any
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.updateQuestion.isUpdated = true;
    draft.updateQuestion.isUpdating = false;
    draft.updateQuestion.errors = errors;
  });
}

export function clearUpdateQuestionReducer(
  state: QuestionState
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.updateQuestion = questionInitialState.updateQuestion;
  });
}

export const updateQuestionReducers = {
  [`${updateQuestion}`]: updateQuestionReducer,
  [`${updateQuestionSuccess}`]: updateQuestionSuccessReducer,
  [`${updateQuestionFailure}`]: updateQuestionFailureReducer,
  [`${clearUpdateQuestion}`]: clearUpdateQuestionReducer,
};
