const translations = {
  title: {
    key: '',
    default: 'Schedule List',
  },
  primaryButton: {
    key: '',
    default: 'Save',
  },
  validation: {
    required: {
      key: '',
      default: 'This field is required',
    },
    afterInvite: {
      key: '',
      default: 'Date must be after invite date',
    },
    afterPrevReminder: {
      key: '',
      default: 'Date must be after previous reminder date',
    },
    afterNow: {
      key: '',
      default: 'Time must be at least 2 mins in the future',
    },
  },
  updateSuccess: {
    key: '',
    default: 'Invite schedule(s) updated successfully.',
  },
};

export default translations;
