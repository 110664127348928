var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { MoreHorizIcon } from '../../Icon';
import { Button } from '../../main';
import ActionCardHeaderOptionsDropdown from './ActionCardHeaderOptionsDropdown';
var isCustomOptions = function (props) {
    var onClick = props.onClick;
    return !!onClick;
};
function ActionCardHeaderOptions(_a) {
    var options = _a.options;
    var _b = options.icon, icon = _b === void 0 ? React.createElement(MoreHorizIcon, null) : _b;
    var content;
    if (isCustomOptions(options)) {
        content = React.createElement(Button, __assign({}, options, { icon: icon }));
    }
    else {
        if (options.dropdownOptions.length) {
            content = React.createElement(ActionCardHeaderOptionsDropdown, __assign({}, options, { icon: icon }));
        }
    }
    if (content) {
        return React.createElement("div", { className: "elmo-actioncard__header-options" }, content);
    }
    return null;
}
export default ActionCardHeaderOptions;
