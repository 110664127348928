import { getClass } from '../../../_lib/helper';
/**
 * Responsive properties
 */
export var responsiveProperties = [
    { property: 'span', prefix: 'elmo-col-' },
    { property: 'auto', prefix: 'elmo-col-' },
    { property: 'order', prefix: 'order-' },
    { property: 'offset', prefix: 'elmo-offset-' },
    { property: 'alignSelf', prefix: 'align-self-' },
    { property: 'shrink', prefix: 'flex-shrink-' },
    { property: 'grow', prefix: 'flex-grow-' },
    { property: 'fill', prefix: 'flex-' },
];
/**
 *
 * @param breakpoint
 * @param options
 */
export function mapResponsive(block, breakpoint, options) {
    if (options === null || options === undefined) {
        return '';
    }
    if (typeof options === 'number') {
        return block + "-" + breakpoint + "-" + options;
    }
    return responsiveProperties
        .map(function (item) {
        if (options[item.property] !== undefined) {
            return "" + item.prefix + breakpoint + "-" + options[item.property];
        }
        return undefined;
    })
        .join(' ');
}
/**
 *
 * @param block
 * @param props
 */
export function mapPropsToClass(block, props) {
    var _a;
    var className = props.className, isAuto = props.isAuto, span = props.span, order = props.order, offset = props.offset, align = props.align, shrink = props.shrink, grow = props.grow, isFill = props.isFill, xs = props.xs, sm = props.sm, md = props.md, lg = props.lg, xl = props.xl, xxl = props.xxl, xxxl = props.xxxl;
    return getClass('elmo-col', (_a = {},
        _a["" + className] = className,
        _a[block + "-auto"] = isAuto,
        _a["elmo-col-" + span] = span,
        _a["order-" + order] = order,
        _a["elmo-offset-" + offset] = offset,
        _a["align-self-" + align] = align,
        _a["flex-shrink-" + shrink] = shrink !== undefined,
        _a["flex-grow-" + grow] = grow !== undefined,
        _a["flex-fill"] = isFill,
        _a["" + mapResponsive(block, 'xs', xs)] = xs,
        _a["" + mapResponsive(block, 'sm', sm)] = sm,
        _a["" + mapResponsive(block, 'md', md)] = md,
        _a["" + mapResponsive(block, 'lg', lg)] = lg,
        _a["" + mapResponsive(block, 'xl', xl)] = xl,
        _a["" + mapResponsive(block, 'xxl', xxl)] = xxl,
        _a["" + mapResponsive(block, 'xxxl', xxxl)] = xxxl,
        _a));
}
