import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getFilterFailure, getFilterSuccess } from './actions';
import { getFilterApi } from './api';

export const getFilerSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(getFilterApi(action.payload));

    yield put(getFilterSuccess(data));
  } catch (error) {
    yield put(getFilterFailure(error));
  }
};
