import map from 'lodash/map';

import { APIFilterValue, FilterValue } from '../../type';

export const transformFilterValue = (
  filterValue: APIFilterValue
): FilterValue => {
  return {
    id: filterValue.id,
    value: filterValue.value,
  };
};

export const transformFilterValues = (
  filterValues: APIFilterValue[]
): FilterValue[] => {
  return map(filterValues, transformFilterValue);
};
