import { takeEvery } from '@redux-saga/core/effects';

import { getOverviewListSaga } from './sagas/overviewListSagas';
import { getSurvey, getOverviewList } from './actions';
import { getSurveySaga } from './sagas/surveySagas';

export default function* root() {
  yield takeEvery(getSurvey, getSurveySaga);
  yield takeEvery(getOverviewList, getOverviewListSaga);
}
