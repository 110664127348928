import { getAssociatedFiltersInitialState } from './getAssociatedFilters/state';
import { associateFiltersInitialState } from './associateFilters/state';
import { getFilterValuesInitialState } from './getFilterValues/state';
import { createFilterInitialState } from './createFilter/state';
import { updateFilterInitialState } from './updateFilter/state';
import { getFiltersInitialState } from './getFilters/state';
import { getFilterInitialState } from './getFilter/state';
import { FilterState } from './type';

export const filterInitialState: FilterState = {
  getAssociatedFilters: getAssociatedFiltersInitialState,
  createFilter: createFilterInitialState,
  getFilter: getFilterInitialState,
  getFilters: getFiltersInitialState,
  getFilterValues: getFilterValuesInitialState,
  updateFilter: updateFilterInitialState,
  associateFilters: associateFiltersInitialState,
};
