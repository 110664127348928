import { RootState } from '../../../type';

export const getShouldUpdateRespondentProgress = (state: RootState) => {
  return state.survey.saveRespondentProgress.shouldUpdate;
};

export const isSavingRespondentInProgress = (state: RootState) => {
  return (
    state.survey.createRespondentProgress.isLoading ||
    state.survey.updateRespondentProgress.isLoading
  );
};
