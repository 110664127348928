var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import Calendar from 'rc-calendar';
import moment from 'moment';
import { CancelIcon as ClearIcon } from '../Icon';
import { DEFAULT_DATE_FORMAT } from './type';
import PopoverBase from '../_lib/component/PopoverBase';
import { getClass, isMobileDevice } from '../_lib/helper';
import 'rc-calendar/assets/index.css';
import './Datepicker.scss';
import Input from '../Input';
/**
 * Datepicker Component
 * Wraps the rc-calendar library.
 */
var Datepicker = /** @class */ (function (_super) {
    __extends(Datepicker, _super);
    function Datepicker(props) {
        var _this = _super.call(this, props) || this;
        /**
         * Toggles the open state of datepicker
         */
        _this.toggleDatepicker = function () {
            var isDisabled = _this.props.isDisabled;
            if (isDisabled) {
                return;
            }
            _this.setFirstDayOfWeek();
            _this.setState(function (_a) {
                var isOpen = _a.isOpen;
                return ({ isOpen: !isOpen });
            });
        };
        /**
         * Called when date selected
         * @param date
         */
        _this.onDateSelect = function (date) {
            var _a = _this.props, onChange = _a.onChange, name = _a.name, format = _a.format;
            var nextDate = date;
            // if the back/forward button is clicked, rc-calendar selects the date
            // without checking if it is disabled. Check if the date is disabled, if it is, don't update anything
            var isDateDisabled = _this.disabledDate(date);
            if (isDateDisabled) {
                return;
            }
            _this.setState(__assign({ isOpen: false }, _this.updateSelectedDate(nextDate, format)));
            // convert to JS date object
            var nextDateNative = nextDate ? nextDate.toDate() : null;
            onChange(nextDateNative, name);
        };
        /**
         * Callback when the Clear button is clicked
         */
        _this.clear = function (e) {
            e.stopPropagation();
            _this.clearDate();
        };
        _this.clearDate = function () {
            var _a = _this.props, onChange = _a.onChange, name = _a.name;
            _this.setState(_this.updateSelectedDate(null, ''));
            onChange(null, name);
        };
        _this.setIsOpen = function (isOpen) {
            _this.setState({
                isOpen: isOpen,
            });
        };
        _this.onChange = function (e) {
            var _a = _this.props, onChange = _a.onChange, name = _a.name, format = _a.format, disabledDate = _a.disabledDate;
            var val = e.target.value;
            if (val.trim().length === 0) {
                _this.clearDate();
                return;
            }
            var date = moment(val, format, true);
            var nextState = {
                selectedDateString: val,
            };
            var dateIsDisabled = disabledDate && disabledDate(date.toDate());
            if (date.isValid() && !dateIsDisabled) {
                nextState.selectedDate = date;
                // convert to JS date object
                onChange(date.toDate(), name);
            }
            _this.setState(nextState);
        };
        _this.disabledDate = function (d) {
            var disabledDate = _this.props.disabledDate;
            var date = d ? d.toDate() : undefined;
            return disabledDate ? disabledDate(date) : false;
        };
        /**
         * When keyup events occur within the component
         * @param e
         */
        _this.onKeyUp = function (e) {
            var isOpen = _this.state.isOpen;
            if (!isOpen && e.key === 'Enter') {
                _this.setIsOpen(true);
                return;
            }
            else if (isOpen && e.key === 'Escape') {
                _this.setIsOpen(false);
            }
        };
        var _a = _this.init(props), selectedDate = _a.selectedDate, selectedDateString = _a.selectedDateString;
        _this.state = {
            isOpen: false,
            selectedDate: selectedDate,
            selectedDateString: selectedDateString,
            isMobile: isMobileDevice(),
        };
        return _this;
    }
    Datepicker.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, value = _a.value, format = _a.format;
        if (prevProps.value !== value) {
            var newDate = value ? this.dateToMoment(value) : null;
            this.setState(this.updateSelectedDate(newDate, format));
        }
    };
    Datepicker.prototype.dateToMoment = function (d) {
        if (!d) {
            return null;
        }
        this.setFirstDayOfWeek();
        var date = moment(d);
        return date.isValid() ? date : null;
    };
    Datepicker.prototype.updateSelectedDate = function (date, format) {
        if (date === null) {
            return {
                selectedDate: undefined,
                selectedDateString: '',
            };
        }
        return {
            selectedDate: date,
            selectedDateString: date.format(format),
        };
    };
    Datepicker.prototype.setFirstDayOfWeek = function () {
        var firstDayOfWeek = this.props.firstDayOfWeek;
        moment.updateLocale('en', {
            week: {
                dow: !!firstDayOfWeek ? firstDayOfWeek : 0,
                doy: 0,
            },
        });
    };
    /**
     * Gives Initialisation values for the component
     * @param props
     */
    Datepicker.prototype.init = function (_a) {
        var _b;
        var format = _a.format, value = _a.value;
        var selectedDate = undefined;
        var selectedDateString = '';
        // if there is a selected date passed in already, cast it using moment.
        if (value) {
            (_b = this.updateSelectedDate(this.dateToMoment(value), format), selectedDate = _b.selectedDate, selectedDateString = _b.selectedDateString);
        }
        return { selectedDate: selectedDate, selectedDateString: selectedDateString };
    };
    Datepicker.prototype.render = function () {
        var _a = this.props, placeholder = _a.placeholder, id = _a.id, className = _a.className, _b = _a.isClearable, isClearable = _b === void 0 ? true : _b, isDisabled = _a.isDisabled, name = _a.name, format = _a.format, isInline = _a.isInline, isReadOnly = _a.isReadOnly, ariaLabel = _a.ariaLabel;
        var _c = this.state, selectedDateString = _c.selectedDateString, selectedDate = _c.selectedDate, isOpen = _c.isOpen, isMobile = _c.isMobile;
        return (React.createElement("div", { onKeyUp: this.onKeyUp },
            React.createElement(PopoverBase, { id: id, testId: "elmo-datepicker-" + (id || 'default'), className: getClass('elmo-datepicker', className), isOpen: isOpen, setIsOpen: this.setIsOpen, position: "bottom-start", mode: "click", isInline: isInline, tabIndex: -1, boundariesElement: "scrollParent" },
                React.createElement(PopoverBase.Target, { className: "elmo-datepicker__input-wrapper" },
                    React.createElement(Input, { name: name, value: selectedDateString, onChange: this.onChange, isDisabled: isDisabled, placeholder: placeholder, onClick: this.toggleDatepicker, isReadOnly: isReadOnly || isMobile, ariaLabel: ariaLabel }),
                    isClearable && !isDisabled && selectedDate && (React.createElement("button", { type: "button", className: "btn-clear", onClick: this.clear, "aria-label": "Clear" },
                        React.createElement(ClearIcon, null)))),
                React.createElement(PopoverBase.Content, { className: "elmo-datepicker-calendar" },
                    React.createElement(Calendar, { showDateInput: false, defaultValue: selectedDate, disabledDate: this.disabledDate, format: format, onSelect: this.onDateSelect })))));
    };
    Datepicker.defaultProps = {
        format: DEFAULT_DATE_FORMAT,
        isInline: true,
    };
    return Datepicker;
}(Component));
export default Datepicker;
