import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/collectors/:collectorId/email-recipient-lists';

export const createInviteApi = (collectorId: number, payload: any) => {
  return postRequest(
    getRoute(url, {
      params: { collectorId: collectorId },
    }),
    payload
  );
};
