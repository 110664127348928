import { createReducer } from 'redux-act';

import { getAdminBenchmarkDataReducers } from './getAdminBenchmarkData/reducers';
import { getAdminDetailsReducers } from './getAdminDetails/reducers';
import { adminInitialState } from './state';

export default createReducer(
  {
    ...getAdminDetailsReducers,
    ...getAdminBenchmarkDataReducers,
  },
  adminInitialState
);
