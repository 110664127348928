import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { CreateQuestionCategoryPayload } from './type';
import { ErrorResponse } from 'type';

export const createQuestionCategory: ActionCreator<CreateQuestionCategoryPayload> =
  createAction('CREATE_QUESTION_CATEGORY');

export const createQuestionCategorySuccess: EmptyActionCreator = createAction(
  'CREATE_QUESTION_CATEGORY_SUCCESS'
);

export const createQuestionCategoryFailure: ActionCreator<ErrorResponse> =
  createAction('CREATE_QUESTION_CATEGORY_FAILURE');

export const clearCreateQuestionCategory: EmptyActionCreator = createAction(
  'CLEAR_CREATE_QUESTION_CATEGORY'
);
