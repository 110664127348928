import produce from 'immer';

import {
  transformAnonCollector,
  transformAnonRecipient,
  transformAnonSurvey,
} from '../../../transformers';
import { runSurvey, runSurveyFailure, runSurveySuccess } from './actions';
import { runSurveyInitialState } from './state';
import { RunSurveyResponse } from './type';
import { SurveyState } from '../type';

export function runSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.runSurvey = {
      ...runSurveyInitialState,
      isLoading: true,
    };
  });
}

export function runSurveySuccessReducer(
  state: SurveyState,
  { data }: RunSurveyResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.runSurvey.isLoaded = true;
    draft.runSurvey.isLoading = false;
    draft.runSurvey.isValid = true;

    const collector = transformAnonCollector(data.collector);
    const survey = transformAnonSurvey(data.survey);

    draft.runSurvey.data = {
      collector,
      survey,
    };

    if (collector.type === 'email' && data.recipient) {
      draft.runSurvey.data.recipient = transformAnonRecipient(data.recipient);
    }

    if ('respondentAnswer' in data) {
      draft.runSurvey.data.respondentAnswer = data.respondentAnswer;
    }
  });
}

export function runSurveyFailureReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.runSurvey.isLoaded = true;
    draft.runSurvey.isLoading = false;
    draft.runSurvey.isValid = false;
  });
}

export const runSurveyReducers = {
  [`${runSurvey}`]: runSurveyReducer,
  [`${runSurveySuccess}`]: runSurveySuccessReducer,
  [`${runSurveyFailure}`]: runSurveyFailureReducer,
};
