import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const emailTemplateRoute = '/email-templates/:emailTemplateId';

export const getEmailTemplateApi = (emailTemplateId: number) => {
  return getRequest(
    getRoute(emailTemplateRoute, {
      params: { emailTemplateId: emailTemplateId },
    })
  );
};
