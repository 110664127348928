import { createAction } from 'redux-act';

export const uploadBulkUrlsCsv: any = createAction(
  'MY_SURVEYS_UPLOAD_BULK_URL_TEMPLATE'
);

export const uploadBulkUrlsCsvSuccess: any = createAction(
  'MY_SURVEYS_UPLOAD_BULK_URL_TEMPLATE_SUCCESS'
);

export const uploadBulkUrlsCsvFailure: any = createAction(
  'MY_SURVEYS_UPLOAD_BULK_URL_TEMPLATE_FAILURE'
);

export const generateBulkUrls: any = createAction(
  'MY_SURVEYS_GENERATE_BULK_URLS'
);

export const generateBulkUrlsSuccess: any = createAction(
  'MY_SURVEYS_GENERATE_BULK_URLS_SUCCESS'
);

export const generateBulkUrlsFailure: any = createAction(
  'MY_SURVEYS_GENERATE_BULK_URLS_FAILURE'
);

export const clearBulkUrls: any = createAction(
  'MY_SURVEYS_GENERATE_BULK_URLS_CLEAR'
);

export const getLatestGenerateBulkUrl: any = createAction(
  'MY_SURVEYS_GENERATE_BULK_GET_LATEST'
);

export const getLatestGenerateBulkUrlSuccess: any = createAction(
  'MY_SURVEYS_GENERATE_BULK_GET_LATEST_SUCCESS'
);

export const getLatestGenerateBulkUrlFailure: any = createAction(
  'MY_SURVEYS_GENERATE_BULK_GET_LATEST_FAILURE'
);
