import { createInviteMessageScheduleInitialState } from './createInviteMessageSchedule/state';
import { createInviteMessageTemplateInitialState } from './createInviteMessageTemplate/state';
import { createInviteReminderMessageInitialState } from './createInviteReminderMessage/state';
import { updateInviteMessageScheduleInitialState } from './updateInviteMessageSchedule/state';
import { updateInviteMessageTemplateInitialState } from './updateInviteMessageTemplate/state';
import { getInviteMessageTemplatesInitialState } from './getInviteMessageTemplates/state';
import { uploadInviteRecipientsCSVInitialState } from './uploadInviteRecipientsCSV/state';
import { createInviteRecipientsInitialState } from './createInviteRecipients/state';
import { updateInviteSchedulesInitialState } from './updateInviteSchedules/state';
import { updateInviteRecipientInitialState } from './updateInviteRecipient/state';
import { createInviteMessageInitialState } from './createInviteMessage/state';
import { getInviteRecipientsInitialState } from './getInviteRecipients/state';
import { deleteInviteMessageInitialState } from './deleteInviteMessage/state';
import { inviteParticipantsInitialState } from './inviteParticipants/state';
import { sendInviteMessageInitialState } from './sendInviteMessage/state';
import { manageInviteInitialState } from './manageInvite/state';
import { deleteInviteInitialState } from './deleteInvite/state';
import { createInviteInitialState } from './createInvite/state';
import { getInvitesInitialState } from './getInvites/state';
import { getInviteInitialState } from './getInvite/state';
import { InvitesState } from './type';

export const invitesInitialState: InvitesState = {
  createInvite: createInviteInitialState,
  createInviteMessage: createInviteMessageInitialState,
  createInviteMessageSchedule: createInviteMessageScheduleInitialState,
  createInviteMessageTemplate: createInviteMessageTemplateInitialState,
  createInviteRecipients: createInviteRecipientsInitialState,
  createInviteReminderMessage: createInviteReminderMessageInitialState,
  deleteInvite: deleteInviteInitialState,
  deleteInviteMessage: deleteInviteMessageInitialState,
  getInvite: getInviteInitialState,
  getInviteMessageTemplates: getInviteMessageTemplatesInitialState,
  getInviteRecipients: getInviteRecipientsInitialState,
  getInvites: getInvitesInitialState,
  inviteParticipants: inviteParticipantsInitialState,
  manageInvite: manageInviteInitialState,
  sendInviteMessage: sendInviteMessageInitialState,
  updateInviteMessageSchedule: updateInviteMessageScheduleInitialState,
  updateInviteMessageTemplate: updateInviteMessageTemplateInitialState,
  updateInviteRecipient: updateInviteRecipientInitialState,
  updateInviteSchedules: updateInviteSchedulesInitialState,
  uploadInviteRecipientsCSV: uploadInviteRecipientsCSVInitialState,
};
