import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetEmailTemplateResponse } from './type';
import { ErrorResponse } from 'type';

export const getEmailTemplate: ActionCreator<number> =
  createAction('GET_EMAIL_TEMPLATE');

export const getEmailTemplateSuccess: ActionCreator<GetEmailTemplateResponse> =
  createAction('GET_EMAIL_TEMPLATE_SUCCESS');

export const getEmailTemplateFailure: ActionCreator<ErrorResponse> =
  createAction('GET_EMAIL_TEMPLATE_FAILURE');

export const clearGetEmailTemplate: EmptyActionCreator =
  createAction('CLEAR_GET_TEMPLATE');
