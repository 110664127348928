import { GetEmailTemplateState } from './type';
import { EmailTemplate } from 'type';

export const getEmailTemplateInitialState: GetEmailTemplateState = {
  isLoaded: false,
  isLoading: false,
  isValid: false,
  emailTemplateId: 0,
  data: {} as EmailTemplate,
};
