import React from 'react';
import { defaultTheme, withTheme, ThemeProvider } from '../_lib/style';
export function ApplicationTheme(props) {
    var theme = props.theme;
    if (theme) {
        var style = document.createElement('style');
        style.setAttribute('id', 'ApplicationTheme');
        document.head.appendChild(style);
        var stylesheet = style.sheet;
        if (theme.font) {
            stylesheet.insertRule('.elmo-elements { font-family:' + theme.font + '}', 0);
        }
    }
    return React.createElement(React.Fragment, null);
}
ApplicationTheme.defaultProps = defaultTheme;
export default withTheme(ApplicationTheme);
export { ThemeProvider, defaultTheme };
