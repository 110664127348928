import produce from 'immer';

import {
  getThemes,
  getThemesFailure,
  getThemesSuccess,
  clearGetThemes,
  updateGetThemesQuery,
} from './actions';
import { transformThemes } from '../../../transformers';
import { getThemesInitialState } from './state';
import { GetThemesResponse } from './type';
import { Query } from 'lib/query/type';
import { ThemeState } from '../type';

export function getThemesReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getThemes.isLoading = true;
    draft.getThemes.isLoaded = false;
    draft.getThemes.isValid = false;
    draft.getThemes.count = 0;
    draft.getThemes.data = [];
  });
}

export function getThemesSuccessReducer(
  state: ThemeState,
  data: GetThemesResponse
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getThemes.isLoading = false;
    draft.getThemes.isLoaded = true;
    draft.getThemes.isValid = true;
    draft.getThemes.count = data.data.count;
    draft.getThemes.data = transformThemes(data.data.themes);
  });
}

export function getThemesFailureReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getThemes.isLoading = false;
    draft.getThemes.isLoaded = true;
    draft.getThemes.isValid = false;
  });
}

export function clearGetThemesReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getThemes = getThemesInitialState;
  });
}

export function updateGetThemesQueryReducer(
  state: ThemeState,
  query: Query
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.getThemes.query = query;
  });
}

export const getThemesReducers = {
  [`${getThemes}`]: getThemesReducer,
  [`${getThemesSuccess}`]: getThemesSuccessReducer,
  [`${getThemesFailure}`]: getThemesFailureReducer,
  [`${clearGetThemes}`]: clearGetThemesReducer,
  [`${updateGetThemesQuery}`]: updateGetThemesQueryReducer,
};
