var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MoreHorizIcon as MoreIcon } from '../../Icon';
import Button from '../../Button';
import { getClass } from '../../_lib/helper';
import './HeaderButtons.scss';
import styled, { defaultTheme } from '../../_lib/style';
import classNames from 'classnames';
var StyledPrimaryButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    return (props.theme &&
        props.theme.button &&
        props.theme.button.background &&
        "\n      @media (max-width: 839.9px){\n        button.elmo-btn--primary {\n          background: transparent!important;\n        }\n        button.elmo-btn--primary.elmo-btn--icon .elmo-btn__icon{\n          background-color:" + props.theme.button.background + "!important;\n        }\n      }\n      ");
});
StyledPrimaryButtonWrapper.defaultProps = {
    theme: {
        button: defaultTheme.button,
        link: defaultTheme.link,
    },
};
var HeaderButtonsPropTypes = {
    children: PropTypes.node,
    buttons: PropTypes.array,
    primaryButton: PropTypes.shape({
        icon: PropTypes.node,
        label: PropTypes.string,
        onClick: PropTypes.func,
        children: PropTypes.array,
        ariaLabel: PropTypes.string,
    }),
};
var HeaderButtons = /** @class */ (function (_super) {
    __extends(HeaderButtons, _super);
    function HeaderButtons(props) {
        var _this = _super.call(this, props) || this;
        _this.eventType = (function () {
            var htmlTag = document.querySelector('html');
            if (htmlTag && htmlTag.className.search('isIOS') !== -1) {
                return 'touchstart';
            }
            return 'mousedown';
        })();
        /** individual button dropdown handler */
        _this.handleBtnDropdown = function (key, visible) {
            var tmpState = _this.state.toggleDropdownButtons;
            if (tmpState[key] === undefined || visible === false) {
                // set state by key of button
                tmpState[key] = false;
            }
            if (visible === undefined) {
                tmpState[key] = !tmpState[key];
            }
            _this.setState({
                toggleDropdownButtons: tmpState,
            }, function () {
                _this.handleBtnDropdownSetEvent(key);
            });
        };
        _this.handleBtnDropdownSetEvent = function (key) {
            var tmpState = _this.state.toggleDropdownButtons;
            if (tmpState[key]) {
                document.body.addEventListener(_this.eventType, function (e) {
                    _this.handleBtnDropdownRemoveEvent(e, key);
                });
            }
        };
        _this.handleBtnDropdownRemoveEvent = function (e, key) {
            var dropdown = document.querySelector('.elmo-header-btns__item--has-children');
            if (dropdown && dropdown.contains(e.target)) {
                return false;
            }
            document.body.removeEventListener(_this.eventType, function (event) {
                _this.handleBtnDropdownRemoveEvent(event, key);
            });
            _this.handleBtnDropdown(key, false);
        };
        _this.handleBtnDropdownClassname = function (children, key) {
            return getClass('elmo-header-btns__item', {}, {
                'has-children': children,
                'has-children--is-opened': _this.state.toggleDropdownButtons[key],
            });
        };
        /** main dropdown handler */
        _this.handleDropdown = function (visible) {
            if (visible !== undefined) {
                setTimeout(function () {
                    _this.setState({
                        toggleButtons: visible,
                    }, _this.handleDropdownSetEvent);
                }, 150); // Hack to trigger onClick before the dropdown gets closed by onBlur
            }
            else {
                _this.setState({
                    toggleButtons: !_this.state.toggleButtons,
                }, _this.handleDropdownSetEvent);
            }
        };
        _this.handleDropdownSetEvent = function () {
            if (_this.state.toggleButtons) {
                document.body.addEventListener(_this.eventType, _this.handleDropdownRemoveEvent);
            }
        };
        _this.handleDropdownRemoveEvent = function () {
            document.body.removeEventListener(_this.eventType, _this.handleDropdownRemoveEvent);
            _this.handleDropdown(false);
        };
        _this.handleOnClickPrimary = function (e) {
            var primaryButton = _this.props.primaryButton;
            if (primaryButton) {
                if (primaryButton.children) {
                    return _this.handlePrimaryDropdown();
                }
                if (primaryButton.onClick) {
                    return primaryButton.onClick(e);
                }
            }
        };
        _this.handlePrimaryDropdown = function (visible) {
            var primaryButton = _this.props.primaryButton;
            if (primaryButton && primaryButton.children) {
                if (visible !== undefined) {
                    _this.setState({
                        togglePrimary: visible,
                    }, _this.handlePrimaryDropdownSetEvent);
                }
                else {
                    _this.setState({
                        togglePrimary: !_this.state.togglePrimary,
                    }, _this.handlePrimaryDropdownSetEvent);
                }
            }
        };
        _this.handlePrimaryDropdownSetEvent = function () {
            if (_this.state.togglePrimary) {
                document.body.addEventListener(_this.eventType, _this.handlePrimaryDropdownRemoveEvent);
            }
        };
        _this.handlePrimaryDropdownRemoveEvent = function (e) {
            var dropdown = document.querySelector('.elmo-header-btns__primary');
            if (dropdown && dropdown.contains(e.target)) {
                return false;
            }
            document.body.removeEventListener(_this.eventType, _this.handlePrimaryDropdownRemoveEvent);
            _this.handlePrimaryDropdown(false);
        };
        _this.handlePrimaryDropdownClassname = function () {
            return getClass('elmo-header-btns__primary', {}, {
                'is-opened': _this.state.togglePrimary,
            });
        };
        // TODO: fix me, abstract me to a simple function componentn called HeaderButtonsPrimaryItem
        _this.primaryButton = function () {
            var primaryButton = _this.props.primaryButton;
            if (primaryButton) {
                var primaryButtonTooltip = undefined;
                if (primaryButton.label || primaryButton.tooltip) {
                    var tooltipTitle = (primaryButton.tooltip
                        ? primaryButton.tooltip
                        : primaryButton.label) + '';
                    primaryButtonTooltip = {
                        title: tooltipTitle,
                        placement: 'top',
                    };
                }
                var button = (React.createElement(Button, __assign({}, (primaryButton.id ? { id: primaryButton.id } : null), { type: "primary", onClick: _this.handleOnClickPrimary, icon: primaryButton.icon ? primaryButton.icon : '', isDisabled: primaryButton.isDisabled, badge: primaryButton.badge, className: classNames(primaryButton.className, {
                        'has-badge': primaryButton.badge,
                    }), tooltip: primaryButtonTooltip, ariaLabel: primaryButton.ariaLabel || primaryButton.label }), primaryButton.label));
                return (React.createElement(StyledPrimaryButtonWrapper, { className: _this.handlePrimaryDropdownClassname() },
                    button,
                    primaryButton.children && (React.createElement("div", { className: "elmo-header-btns__primary__dropdown" }, _this.renderButtonChildren(primaryButton.children, function () {
                        _this.handlePrimaryDropdown(false);
                    })))));
            }
            return null;
        };
        _this.buttonChildrenClick = function (e, onClick, closeDropdownCallback) {
            closeDropdownCallback();
            onClick(e);
        };
        _this.handleBtnClick = function (item, key) {
            if (item.children) {
                return _this.handleBtnDropdown(key);
            }
            else {
                return item.onClick(); // Need to fix it triggers all onClick passed in buttons
            }
        };
        // TODO: fix me, abstract me to a simple function componentn called HeaderButtonsItems
        // TODO: fix me, need optimisation
        _this.headerButtons = function () {
            var buttons = _this.props.buttons;
            if (buttons) {
                var countButtons = buttons.length;
                var wrapperDesktop = React.createElement(React.Fragment, null);
                var buttonsDesktop = [];
                var wrapperMobile = React.createElement(React.Fragment, null);
                var buttonsMobile = [];
                var buttonsDefault = [];
                var key = 0;
                if (countButtons >= 5) {
                    var idx = 3;
                    for (idx; idx < countButtons; idx++) {
                        buttonsDesktop.push(_this.renderButton(buttons[idx], key++));
                    }
                    wrapperDesktop = (React.createElement("div", { className: "elmo-header-btns__desktop" }, buttonsDesktop));
                    idx = 1;
                    for (idx; idx < 3; idx++) {
                        buttonsMobile.push(_this.renderButton(buttons[idx], key++));
                    }
                    wrapperMobile = (React.createElement("div", { className: "elmo-header-btns__mobile" },
                        buttonsMobile,
                        wrapperDesktop));
                    return (React.createElement(React.Fragment, null,
                        _this.renderButton(buttons[0], key++),
                        wrapperMobile));
                }
                else if (countButtons < 5 && countButtons > 2) {
                    var idx = 1;
                    for (idx; idx < countButtons; idx++) {
                        buttonsMobile.push(_this.renderButton(buttons[idx], key++));
                    }
                    wrapperMobile = (React.createElement("div", { className: "elmo-header-btns__mobile" }, buttonsMobile));
                    return (React.createElement(React.Fragment, null,
                        _this.renderButton(buttons[0], key++),
                        wrapperMobile));
                }
                else {
                    var idx = 0;
                    for (idx; idx < countButtons; idx++) {
                        buttonsDefault.push(_this.renderButton(buttons[idx], key++));
                    }
                    return buttonsDefault;
                }
            }
            return null;
        };
        _this.state = {
            toggleButtons: false,
            toggleDropdownButtons: [],
            togglePrimary: false,
        };
        return _this;
    }
    HeaderButtons.prototype.renderButtonChildren = function (children, closeDropdownCallback) {
        var _this = this;
        if (children) {
            return (React.createElement("div", { className: "elmo-header-btns__item-child" }, children.map(function (item, key) {
                return (React.createElement(React.Fragment, { key: key },
                    React.createElement(Button, __assign({}, (item.id ? { id: item.id } : null), { className: classNames(item.className), isText: true, isUppercase: false, onClick: function (e) {
                            return _this.buttonChildrenClick(e, item.onClick, closeDropdownCallback);
                        }, icon: item.icon, key: key, isDisabled: item.isDisabled, ariaLabel: item.label || item.ariaLabel }), item.label),
                    item.hasDivider && (React.createElement("div", { className: "elmo-header-btns__divider" }))));
            })));
        }
        return null;
    };
    HeaderButtons.prototype.renderButton = function (item, key) {
        var _this = this;
        if (item) {
            return (React.createElement(React.Fragment, { key: key },
                React.createElement("div", { className: this.handleBtnDropdownClassname(!!item.children, key) },
                    React.createElement(Button, __assign({}, (item.id ? { id: item.id } : null), { isText: true, isUppercase: false, onClick: function (e) { return _this.handleBtnClick(item, key); }, icon: item.icon, key: key, isDisabled: item.isDisabled, badge: item.badge, className: classNames(item.className, {
                            'has-badge': !!item.badge,
                            'show-btn-label': item.showBtnLabel,
                        }), ariaLabel: item.label || item.ariaLabel }, (item.tooltip
                        ? {
                            tooltip: {
                                title: item.tooltip,
                                placement: 'top',
                            },
                        }
                        : {})), item.label),
                    item.children &&
                        this.renderButtonChildren(item.children, function () {
                            _this.handleBtnDropdown(key, false);
                        })),
                item.hasDivider && React.createElement("div", { className: "elmo-header-btns__divider" })));
        }
        return null;
    };
    HeaderButtons.prototype.render = function () {
        var _this = this;
        var buttons = this.props.buttons;
        var toggleButtons = this.state.toggleButtons;
        var countButtons = buttons && buttons.length ? buttons.length : 0;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: getClass('elmo-header-btns', {}, { toggle: toggleButtons }) },
                this.headerButtons(),
                React.createElement("span", { className: "elmo-header-btns__toggle", "data-visible-mobile": countButtons > 2 ? 'true' : 'false', "data-visible-desktop": countButtons > 4 ? 'true' : 'false' },
                    React.createElement(Button, { isText: true, onClick: function () { return _this.handleDropdown(); }, icon: React.createElement(MoreIcon, null), ariaLabel: "Options", tooltip: { title: 'More Options', placement: 'top' } })),
                this.primaryButton())));
    };
    HeaderButtons.propTypes = HeaderButtonsPropTypes;
    return HeaderButtons;
}(Component));
export default HeaderButtons;
var templateObject_1;
