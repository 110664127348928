import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getOverviewListFailure,
  getOverviewListSuccess,
  updateOverviewListQuery,
} from '../actions';
import { getQuery } from '../../../../lib/query';
import { overviewList } from '../state';
import ReportsApi from '../api';

export const getOverviewListSaga = function* (action: any): SagaIterator {
  try {
    const currentQuery = getQuery(overviewList.query);
    yield put(updateOverviewListQuery(currentQuery));

    // Disconnect from currentQuery so status does not show in address bar
    const queryParams = {
      ...currentQuery,
      status: action.payload,
      excludeDrafts: true,
    };

    const data = yield call(ReportsApi.getOverviewReportList(queryParams));
    yield put(getOverviewListSuccess(data));
  } catch (error) {
    yield put(getOverviewListFailure(error));
  }
};
