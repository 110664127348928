import { Query } from '../../../lib/query/type';
import { getRoute } from '../../../lib/util';
import httpClient from '../../../lib/api';

export const resources: any = {
  survey: {
    url: '/survey/:id',
  },
  surveys: {
    url: '/survey',
  },
};

export class ReportsApiService {
  getSurvey(id: any): any {
    return () =>
      httpClient
        .get(getRoute(resources.survey.url, { params: { id: id } }))
        .then((result: any) => {
          return result.data;
        });
  }

  getOverviewReportList(params: Query): any {
    return () =>
      httpClient
        .get(resources.surveys.url, { params: params })
        .then((result: any) => result.data);
  }
}

export default new ReportsApiService();
