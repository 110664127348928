import { RunSurveyPayload } from './type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const anonSurveyRoute = '/anon/survey/:surveyId/:collectorHash';
const anonSurveyByInviteHashRoute = '/anon/survey-by-invite-hash/:inviteHash';
const surveyByHashRoute = '/survey-by-hash/:hash';

export const runSurveyApi = (payload: RunSurveyPayload) => {
  let route = anonSurveyRoute;

  if (payload.inviteHash) {
    route = anonSurveyByInviteHashRoute;
  } else if (payload.hash) {
    route = surveyByHashRoute;
  }

  return getRequest(
    getRoute(route, {
      params: payload,
    })
  );
};
