var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass, isElementOfType } from '../_lib/helper';
import PopoverBase from '../_lib/component/PopoverBase';
import { PopoverModes } from './type';
import './Popover.scss';
var PopoverContent = PopoverBase.Content, PopoverTarget = PopoverBase.Target;
var Popover = /** @class */ (function (_super) {
    __extends(Popover, _super);
    function Popover() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isPopperModeHover = function () {
            var mode = _this.props.mode;
            return !mode || mode === PopoverModes.hover;
        };
        return _this;
    }
    Popover.prototype.getSubComponents = function () {
        var children = this.props.children;
        var subComponents = {};
        React.Children.map(children, function (child) {
            if (!React.isValidElement(child)) {
                return;
            }
            if (isElementOfType(child, PopoverTarget)) {
                subComponents.PopoverTarget = child;
            }
            else if (isElementOfType(child, PopoverContent)) {
                subComponents.PopoverContent = child;
            }
        });
        return subComponents;
    };
    Popover.prototype.render = function () {
        var _a = this.props, id = _a.id, className = _a.className, position = _a.position, mode = _a.mode, isInline = _a.isInline, isAutoWidth = _a.isAutoWidth, isNoPadding = _a.isNoPadding, tabIndex = _a.tabIndex;
        var subComponents = this.getSubComponents();
        var popoverContentId = id + "-content";
        return (React.createElement(PopoverBase, { id: id, testId: "elmo-popover-" + (id || 'default'), className: getClass('elmo-popover', className, {
                hover: this.isPopperModeHover(),
            }), position: position, mode: mode, isInline: isInline, ariaDescribedby: popoverContentId, isSupportedFocusAndBlur: true, tabIndex: tabIndex },
            React.createElement(PopoverBase.Target, { className: "elmo-popover__target" }, subComponents.PopoverTarget),
            React.createElement(PopoverBase.Content, { className: getClass('elmo-popover__content', '', {
                    'auto-width': isAutoWidth,
                    'no-padding': isNoPadding,
                }), id: popoverContentId }, subComponents.PopoverContent)));
    };
    Popover.Content = PopoverContent;
    Popover.Target = PopoverTarget;
    Popover.defaultProps = {
        tabIndex: 0,
    };
    return Popover;
}(Component));
export default Popover;
