import produce from 'immer';

import { getBulkUrls, getBulkUrlsFailure, getBulkUrlsSuccess } from './actions';
import { transformBulkUrls } from '../../../transformers';
import { getBulkUrlsInitialState } from './state';
import { GetBulkUrlsResponse } from './type';
import { BulkUrlState } from '../type';
import { ErrorResponse } from 'type';

export function getBulkUrlsReducer(state: BulkUrlState): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.getBulkUrls = { ...getBulkUrlsInitialState };
    draft.getBulkUrls.isLoading = true;
  });
}

export function getBulkUrlsSuccessReducer(
  state: BulkUrlState,
  data: GetBulkUrlsResponse
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.getBulkUrls.isLoading = false;
    draft.getBulkUrls.isLoaded = true;
    draft.getBulkUrls.isValid = true;
    draft.getBulkUrls.data = transformBulkUrls(data.data);
  });
}

export function getBulkUrlsFailureReducer(
  state: BulkUrlState,
  error: ErrorResponse
): BulkUrlState {
  return produce(state, (draft: BulkUrlState) => {
    draft.getBulkUrls.isLoading = false;
    draft.getBulkUrls.isLoaded = true;
    draft.getBulkUrls.error = error;
  });
}

export const getBulkUrlsReducers = {
  [`${getBulkUrls}`]: getBulkUrlsReducer,
  [`${getBulkUrlsSuccess}`]: getBulkUrlsSuccessReducer,
  [`${getBulkUrlsFailure}`]: getBulkUrlsFailureReducer,
};
