import { RouteObject } from 'react-router-dom';
import React from 'react';

import CreateTemplate from './pages/CreateTemplate';
import TemplateList from './pages/TemplateList';
import EditTemplate from './pages/EditTemplate';

const routes: RouteObject = {
  path: '/email-templates',
  children: [
    {
      path: '/email-templates/:emailTemplateId/edit',
      element: <EditTemplate />,
    },
    {
      path: '/email-templates/create',
      element: <CreateTemplate />,
    },
    {
      path: '/email-templates',
      element: <TemplateList />,
    },
  ],
};

export default routes;
