import { ActionCreator, createAction } from 'redux-act';

import { GetSurveyNotificationsResponse } from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getSurveyNotifications: ActionCreator<number> = createAction(
  'GET_SURVEY_NOTIFICATIONS'
);

export const getSurveyNotificationsSuccess: ActionCreator<GetSurveyNotificationsResponse> =
  createAction('GET_SURVEY_NOTIFICATIONS_SUCCESS');

export const getSurveyNotificationsFailure: ActionCreator<ErrorResponse> =
  createAction('GET_SURVEY_NOTIFICATIONS_FAILURE');

export const updateSurveyNotificationsQuery: ActionCreator<Query> =
  createAction('UPDATE_SURVEY_NOTIFICATIONS_QUERY');
