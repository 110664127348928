import map from 'lodash/map';

import { APIQuestionCategory, QuestionCategory } from 'type';

export const transformQuestionCategory = (
  questionCategory: APIQuestionCategory
): QuestionCategory => {
  return {
    createdDate: questionCategory.created_date,
    description: questionCategory.description,
    global: questionCategory.global,
    globalCategoryId: questionCategory.global_category_id,
    id: questionCategory.id,
    title: questionCategory.title,
    updatedDate: questionCategory.updated_date,
  };
};

export const transformQuestionCategories = (
  questionCategories: APIQuestionCategory[]
): QuestionCategory[] => {
  return map(questionCategories, transformQuestionCategory);
};
