import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetDefaultSenderResponse } from './type';
import { ErrorResponse } from 'type';

export const getDefaultSender: EmptyActionCreator =
  createAction('GET_DEFAULT_SENDER');

export const getDefaultSenderSuccess: ActionCreator<GetDefaultSenderResponse> =
  createAction('GET_DEFAULT_SENDER_SUCCESS');

export const getDefaultSenderFailure: ActionCreator<ErrorResponse> =
  createAction('GET_DEFAULT_SENDER_FAILURE');
