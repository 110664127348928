import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useEffect } from 'react';

import { default as HeaderElement } from 'sharedComponents/Header';
import { getHeaderTabs } from 'lib/helpers/emailInvites';
import StatusBadge from 'element/StatusBadge';
import { getCollector } from 'store/actions';
import { isCollectorActive } from 'lib';
import { RootState } from 'store/type';
import { headerProps } from './type';
import { getRoute } from 'lib/util';
import history from 'lib/history';

const Header = ({
  surveyId,
  collectorId,
  subHeading,
  headerButtons,
}: headerProps): ReactElement => {
  const dispatch = useDispatch();
  const getCollectorState = useSelector(
    (state: RootState) => state.collector.getCollector
  );

  const backButton = () => {
    return {
      onClick: () => {
        history.push(
          getRoute('/surveys/:surveyId/collectors', {
            params: { surveyId: surveyId },
          })
        );
      },
    };
  };

  const renderStatus = (): ReactElement | null => {
    if (!getCollectorState.collector) {
      return null;
    }

    return (
      <StatusBadge
        status={
          isCollectorActive(getCollectorState.collector) ? 'active' : 'closed'
        }
      />
    );
  };

  useEffect(() => {
    if (
      (!getCollectorState.isLoading && !getCollectorState.isLoaded) ||
      getCollectorState.collectorId !== collectorId
    ) {
      dispatch(getCollector(collectorId));
    }
  }, [getCollectorState, collectorId, dispatch]);

  return (
    <HeaderElement
      title={getCollectorState.collector && getCollectorState.collector.title}
      titleAddon={renderStatus()}
      subHeading={subHeading}
      isLoading={getCollectorState.isLoading}
      getBackButton={backButton()}
      tabs={getHeaderTabs(surveyId, collectorId)}
      headerButtons={headerButtons}
    />
  );
};

export default Header;
