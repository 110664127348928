import produce from 'immer';

import {
  getAssociatedFilters,
  getAssociatedFiltersFailure,
  getAssociatedFiltersSuccess,
  updateGetAssociatedFiltersQuery,
} from './actions';
import {
  GetAssociatedFiltersRequest,
  GetAssociatedFiltersResponse,
} from './type';
import { getAssociatedFiltersInitialState } from './state';
import { transformFilters } from '../../../transformers';
import { Query } from 'lib/query/type';
import { FilterState } from '../type';
import { ErrorResponse } from 'type';

export function getAssociatedFiltersReducer(
  state: FilterState,
  request: GetAssociatedFiltersRequest
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.getAssociatedFilters = { ...getAssociatedFiltersInitialState };
    draft.getAssociatedFilters.isLoading = true;
    draft.getAssociatedFilters.surveyId = request.surveyId;
  });
}

export function getAssociatedFiltersSuccessReducer(
  state: FilterState,
  response: GetAssociatedFiltersResponse
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.getAssociatedFilters.isLoading = false;
    draft.getAssociatedFilters.isLoaded = true;
    draft.getAssociatedFilters.isValid = true;
    draft.getAssociatedFilters.count = response.data.count;
    draft.getAssociatedFilters.data = transformFilters(
      response.data.associatedFilters
    );
  });
}

export function getAssociatedFiltersFailureReducer(
  state: FilterState,
  error: ErrorResponse
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.getAssociatedFilters.isLoading = false;
    draft.getAssociatedFilters.isLoaded = true;
    draft.getAssociatedFilters.error = error;
  });
}

export function updateGetAssociatedFiltersQueryReducer(
  state: FilterState,
  query: Query
): FilterState {
  return produce(state, (draft: FilterState) => {
    draft.getAssociatedFilters.query = query;
  });
}

export const getAssociatedFiltersReducers = {
  [`${getAssociatedFilters}`]: getAssociatedFiltersReducer,
  [`${getAssociatedFiltersSuccess}`]: getAssociatedFiltersSuccessReducer,
  [`${getAssociatedFiltersFailure}`]: getAssociatedFiltersFailureReducer,
  [`${updateGetAssociatedFiltersQuery}`]:
    updateGetAssociatedFiltersQueryReducer,
};
