import { AssociateFiltersRequest } from './type';
import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/survey/:surveyId/filters';

export const associateFiltersApi = (request: AssociateFiltersRequest) => {
  return postRequest(
    getRoute(url, {
      params: { surveyId: request.surveyId },
    }),
    { params: request.payload }
  );
};
