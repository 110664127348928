import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetInvitesResponse } from './type';
import { Query } from 'lib/query/type';

export const getInvites: ActionCreator<any> = createAction('GET_INVITES');

export const getInvitesSuccess: ActionCreator<GetInvitesResponse> =
  createAction('GET_INVITES_SUCCESS');

export const getInvitesFailure: ActionCreator<any> = createAction(
  'GET_INVITES_FAILURE'
);

export const updateGetInvitesQuery: ActionCreator<Query> = createAction(
  'UPDATE_GET_INVITES_QUERY'
);

export const clearGetInvites: EmptyActionCreator =
  createAction('CLEAR_GET_INVITES');
