import React from 'react';
import { getClass } from '../_lib/helper';
import PopoverBase from '../_lib/component/PopoverBase';
import './Tooltip.scss';
var TooltipDefaultProps = {
    tabIndex: -1,
};
function Tooltip(_a) {
    var title = _a.title, placement = _a.placement, id = _a.id, className = _a.className, children = _a.children, tabIndex = _a.tabIndex;
    var popoverContentId = id + "-content";
    return (React.createElement(PopoverBase, { id: id, "data-testid": "elmo-tooltip-" + (id || 'default'), className: getClass('elmo-tooltip', className), position: placement, tabIndex: tabIndex, ariaDescribedby: popoverContentId },
        React.createElement(PopoverBase.Target, null,
            children,
            id && (React.createElement("div", { id: "hide-" + id, role: "tooltip", "aria-label": title, style: { display: 'none' } }))),
        React.createElement(PopoverBase.Content, { className: "elmo-tooltip__content", id: popoverContentId }, title)));
}
Tooltip.defaultProps = TooltipDefaultProps;
export default Tooltip;
