import React, { Suspense, ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'elmo-elements';

import Notification from 'sharedComponents/Notification';
import Menu from 'sharedComponents/Menu/Menu';
import PageLoader from 'element/PageLoading';

const PrivateLayoutWithMenu = (): ReactElement => {
  return (
    <Suspense fallback={<PageLoader text={'Loading...'} />}>
      <Layout menu={<Menu />}>
        <Outlet />
        <Notification />
      </Layout>
    </Suspense>
  );
};

export default PrivateLayoutWithMenu;
