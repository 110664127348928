import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const getCollectorFilterOptions: ActionCreator<any> = createAction(
  'GET_COLLECTOR_FILTER_OPTIONS'
);

export const getCollectorFilterOptionsSuccess: ActionCreator<any> =
  createAction('GET_COLLECTOR_FILTER_OPTIONS_SUCCESS');

export const getCollectorFilterOptionsFailure: ActionCreator<ErrorResponse> =
  createAction('GET_COLLECTOR_FILTER_OPTIONS_FAILURE');

export const clearGetCollectorFilterOptions: EmptyActionCreator = createAction(
  'CLEAR_GET_COLLECTOR_FILTER_OPTIONS'
);
