import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createInviteRecipientsFailure,
  createInviteRecipientsSuccess,
} from './actions';
import { createInviteRecipientsApi } from './api';

export const createInviteRecipientsSaga = function* (
  action: any
): SagaIterator {
  try {
    const data = yield call(
      createInviteRecipientsApi(
        action.payload.collectorId,
        action.payload.listId,
        action.payload.data
      )
    );

    yield put(createInviteRecipientsSuccess(data));
  } catch (error) {
    yield put(createInviteRecipientsFailure(error));
  }
};
