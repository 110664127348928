import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { createRespondentFailure, createRespondentSuccess } from './actions';
import { CreateRespondentPayload } from './type';
import { createRespondentApi } from './api';

export interface CreateRespondentSagaProps {
  error: boolean;
  payload: CreateRespondentPayload;
  type: string;
}

export const createRespondentSaga = function* (
  action: CreateRespondentSagaProps
): SagaIterator {
  try {
    const data = yield call(createRespondentApi(action.payload));

    yield put(createRespondentSuccess(data));
  } catch (error) {
    yield put(createRespondentFailure());
  }
};
