import React from 'react';
function ActionCardContent(_a) {
    var icon = _a.icon, heading = _a.heading, headingAddOn = _a.headingAddOn, children = _a.children, role = _a.role;
    return (React.createElement("div", { className: "elmo-actioncard__content", role: role },
        React.createElement("div", { className: "elmo-actioncard__content-header-wrapper" },
            React.createElement("div", { className: "elmo-actioncard__content-header" },
                React.createElement("div", { className: "elmo-actioncard__content-icon" }, icon),
                React.createElement("div", { className: "elmo-actioncard__content-heading" }, heading),
                headingAddOn)),
        React.createElement("div", { className: "elmo-actioncard__content-content" }, children)));
}
export default ActionCardContent;
