import JsPDF from 'jspdf';

type pdfExportSettings = {
  width: number;
  windowWidth: number;
  autoPaging: boolean | 'text' | 'slice' | undefined;
};

export const pdfExport = (
  elementId: string,
  fileName: string,
  settings: pdfExportSettings = {
    width: 750,
    windowWidth: 899,
    autoPaging: 'text',
  },
  callback: () => void = () => undefined
) => {
  const element = document.getElementById(elementId);
  const tooltips = element?.getElementsByClassName('elmo-tooltip');
  if (tooltips && tooltips.length !== 0) {
    for (let i = tooltips.length - 1; i >= 0; i--) {
      tooltips[i]?.remove();
    }
  }

  if (!element) {
    return;
  }

  let doc = new JsPDF({ unit: 'pt' });

  doc.html(element, {
    margin: 4,
    image: { type: 'png', quality: 1 },
    autoPaging: settings.autoPaging,
    html2canvas: {
      width: settings.width,
      windowWidth: settings.windowWidth,
      useCORS: true,
      svgRendering: true,
      imageTimeout: 0,
    },
    width: settings.width,
    windowWidth: settings.windowWidth,
    callback: function (doc) {
      doc.save(`${fileName}.pdf`);
      callback();
    },
  });
};
