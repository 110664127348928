import produce from 'immer';

import {
  clearGetInviteMessageTemplates,
  getInviteMessageTemplates,
  getInviteMessageTemplatesFailure,
  getInviteMessageTemplatesSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function getInviteMessageTemplatesReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInviteMessageTemplates.isLoaded = false;
    draft.getInviteMessageTemplates.isLoading = true;
    draft.getInviteMessageTemplates.isValid = false;
    draft.getInviteMessageTemplates.count = 0;
    draft.getInviteMessageTemplates.data = [];
  });
}

export function getInviteMessageTemplatesSuccessReducer(
  state: InvitesState,
  data: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInviteMessageTemplates.isLoaded = true;
    draft.getInviteMessageTemplates.isLoading = false;
    draft.getInviteMessageTemplates.isValid = true;
    draft.getInviteMessageTemplates.count = data.count;
    draft.getInviteMessageTemplates.data = data.data;
  });
}

export function getInviteMessageTemplatesFailureReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInviteMessageTemplates.isLoaded = true;
    draft.getInviteMessageTemplates.isLoading = false;
    draft.getInviteMessageTemplates.isValid = false;
  });
}

export function clearGetInviteMessageTemplatesReducer(
  state: InvitesState
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.getInviteMessageTemplates =
      invitesInitialState.getInviteMessageTemplates;
  });
}

export const getInviteMessageTemplatesReducers = {
  [`${getInviteMessageTemplates}`]: getInviteMessageTemplatesReducer,
  [`${getInviteMessageTemplatesSuccess}`]:
    getInviteMessageTemplatesSuccessReducer,
  [`${getInviteMessageTemplatesFailure}`]:
    getInviteMessageTemplatesFailureReducer,
  [`${clearGetInviteMessageTemplates}`]: clearGetInviteMessageTemplatesReducer,
};
