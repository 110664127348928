import { deleteRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/reminder-messages/:messageId';

export const deleteInviteMessageApi = (messageId: number) => {
  return deleteRequest(
    getRoute(url, {
      params: {
        messageId: messageId,
      },
    })
  );
};
