import produce from 'immer';

import {
  updateCollector,
  updateCollectorSuccess,
  updateCollectorFailure,
  clearUpdateCollector,
} from './actions';
import { updateCollectorInitialState } from './state';
import { CollectorState } from '../type';
import { ErrorResponse } from 'type';

export function updateCollectorReducer(state: CollectorState): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.updateCollector = { ...updateCollectorInitialState };
    draft.updateCollector.isLoading = true;
  });
}

export function updateCollectorSuccessReducer(
  state: CollectorState
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.updateCollector.isLoading = false;
    draft.updateCollector.isLoaded = true;
    draft.updateCollector.isValid = true;
  });
}

export function updateCollectorFailureReducer(
  state: CollectorState,
  error: ErrorResponse
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.updateCollector.isLoading = false;
    draft.updateCollector.isLoaded = true;
    draft.updateCollector.error = error;
  });
}

export function clearUpdateCollectorReducer(
  state: CollectorState
): CollectorState {
  return produce(state, (draft: CollectorState) => {
    draft.updateCollector = updateCollectorInitialState;
  });
}

export const updateCollectorReducers = {
  [`${updateCollector}`]: updateCollectorReducer,
  [`${updateCollectorSuccess}`]: updateCollectorSuccessReducer,
  [`${updateCollectorFailure}`]: updateCollectorFailureReducer,
  [`${clearUpdateCollector}`]: clearUpdateCollectorReducer,
};
