import { GetCollectorState } from './type';
import { FullCollector } from 'type';

export const getCollectorInitialState: GetCollectorState = {
  isLoaded: false,
  isLoading: false,
  isValid: false,
  data: undefined,
  collectorId: undefined,
  collector: {} as FullCollector,
};
