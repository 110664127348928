import { ActionCreator, createAction } from 'redux-act';

import {
  GetSurveyNotificationHistoryPayload,
  GetSurveyNotificationHistoryResponse,
} from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getSurveyNotificationHistory: ActionCreator<GetSurveyNotificationHistoryPayload> =
  createAction('GET_SURVEY_NOTIFICATION_HISTORY');

export const getSurveyNotificationHistorySuccess: ActionCreator<GetSurveyNotificationHistoryResponse> =
  createAction('GET_SURVEY_NOTIFICATION_HISTORY_SUCCESS');

export const getSurveyNotificationHistoryFailure: ActionCreator<ErrorResponse> =
  createAction('GET_SURVEY_NOTIFICATION_HISTORY_FAILURE');

export const updateSurveyNotificationHistoryQuery: ActionCreator<Query> =
  createAction('UPDATE_SURVEY_NOTIFICATION_HISTORY_QUERY');
