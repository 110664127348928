import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import { getLatestCreatedBulkLink } from '../getLatestCreatedBulkLink/actions';
import { createBulkLinksFailure, createBulkLinksSuccess } from './actions';
import { CreateBulkLinksRequest } from './type';
import { createBulkLinksApi } from './api';
import { SagaAction } from 'type';

export const createBulkLinksSaga = function* (
  action: SagaAction<CreateBulkLinksRequest>
): SagaIterator {
  try {
    const data = yield call(createBulkLinksApi(action.payload));

    if (data.error && data.error === 'timeout') {
      yield put(getLatestCreatedBulkLink(action.payload.surveyId));

      return;
    }

    yield put(createBulkLinksSuccess(data));
  } catch (error) {
    yield put(createBulkLinksFailure(error));
  }
};
