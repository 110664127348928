import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  createInviteMessageFailure,
  createInviteMessageSuccess,
} from './actions';
import { createInviteMessageApi } from './api';

export const createInviteMessageSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(
      createInviteMessageApi(
        action.payload.collectorId,
        action.payload.listId,
        action.payload.data
      )
    );

    yield put(createInviteMessageSuccess(data));
  } catch (error) {
    yield put(createInviteMessageFailure(error));
  }
};
