var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from '../../_lib/style';
import { mediaBreakpointUp } from '../../_lib/helper';
var StyledContainerBreakpoints = css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), mediaBreakpointUp.xs(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 570px;\n  "], ["\n    max-width: 570px;\n  "]))), mediaBreakpointUp.sm(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    max-width: 810px;\n  "], ["\n    max-width: 810px;\n  "]))), mediaBreakpointUp.md(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    max-width: 1000px;\n  "], ["\n    max-width: 1000px;\n  "]))), mediaBreakpointUp.lg(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    max-width: 1250px;\n  "], ["\n    max-width: 1250px;\n  "]))), mediaBreakpointUp.xl(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    max-width: 1410px;\n  "], ["\n    max-width: 1410px;\n  "]))), mediaBreakpointUp.xxl(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    max-width: 1850px;\n  "], ["\n    max-width: 1850px;\n  "]))));
var getStyledContainerSize = function (size) {
    switch (size) {
        case 'lg':
            return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n        max-width: 1200px;\n        margin-left: 0;\n        padding-left: 0;\n        padding-right: 0;\n      "], ["\n        max-width: 1200px;\n        margin-left: 0;\n        padding-left: 0;\n        padding-right: 0;\n      "])));
        default:
            return;
    }
};
var containerStyle = function (props) {
    if (!props.isFluid && !props.size) {
        return StyledContainerBreakpoints;
    }
    if (!!props.size) {
        return getStyledContainerSize(props.size);
    }
};
var StyledContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  padding-right: 12px;\n  padding-left: 12px;\n  margin-right: auto;\n  margin-left: auto;\n  box-sizing: border-box;\n  ", "\n"], ["\n  width: 100%;\n  padding-right: 12px;\n  padding-left: 12px;\n  margin-right: auto;\n  margin-left: auto;\n  box-sizing: border-box;\n  ", "\n"])), containerStyle);
StyledContainer.displayName = 'StyledContainer';
export default StyledContainer;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
