var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LayoutContext from '../Layout/component/LayoutContext';
import DataTableContext from './DataTableContext';
/**
 * Passes the DataTable and Layout Context to the component that this wraps.
 * @param WrappedComponent
 */
export var withDataTableContext = function (WrappedComponent) {
    var _a;
    return _a = /** @class */ (function (_super) {
            __extends(class_1, _super);
            function class_1() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            class_1.prototype.render = function () {
                var _this = this;
                return (React.createElement(LayoutContext.Consumer, null, function (layoutProps) { return (React.createElement(DataTableContext.Consumer, null, function (dataTableProps) { return (React.createElement(WrappedComponent, __assign({}, layoutProps, dataTableProps, _this.props))); })); }));
            };
            return class_1;
        }(React.Component)),
        _a.displayName = WrappedComponent.displayName
            ? WrappedComponent.displayName
            : '',
        _a;
};
