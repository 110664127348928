import { Header as ElmoHeader, PlaceholderHeader } from 'elmo-elements';
import React, { ReactNode, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { getPrimaryButton, getSecondaryButtons } from './helpers';
import usePrevious from 'hook/UsePrevious';
import { Search } from './components';
import './Header.scss';

export interface HeaderProps {
  title: ReactNode;
  isLoading?: boolean;
  getBackButton?: any;
  handlePrimaryButton?: any;
  tabs?: any;
  searchPath?: string | null;
  searchValue?: string;
  updateQuery?: () => void;
  searchPlaceHolder?: string;
  headerButtons?: any;
  primaryButtonIcon?: any;
  primaryButtonLabel?: string;
  primaryButtonChildren?: any;
  primaryButtonToolTip?: string;
  titleAddon?: ReactNode;
  subHeading?: string;
}

const Header = ({
  title,
  isLoading,
  getBackButton,
  handlePrimaryButton,
  tabs,
  searchPath,
  searchValue,
  updateQuery,
  searchPlaceHolder,
  headerButtons,
  primaryButtonIcon,
  primaryButtonLabel,
  primaryButtonChildren,
  titleAddon,
  subHeading,
}: HeaderProps) => {
  const [isSearchOpen, toggleSearchOpen] = useState<boolean>(false);
  const previousSearchValue = usePrevious(searchValue);

  const primaryButton = getPrimaryButton(
    primaryButtonLabel,
    primaryButtonIcon,
    handlePrimaryButton,
    primaryButtonChildren
  );

  const secondaryButtons = getSecondaryButtons(
    headerButtons,
    !!searchPath,
    () => toggleSearchOpen(true)
  );

  useEffect(() => {
    if (
      searchPath !== '' &&
      updateQuery &&
      previousSearchValue !== searchValue &&
      searchValue !== ''
    ) {
      toggleSearchOpen(true);
    }
  }, [previousSearchValue, searchValue, toggleSearchOpen]);

  if (isLoading) {
    return (
      <PlaceholderHeader
        hasBackButton={getBackButton !== undefined}
        hasSubHeading={!isEmpty(subHeading)}
        hasTabs={tabs !== undefined}
        hasButtons={!isEmpty(secondaryButtons)}
        numberOfTabs={tabs && tabs.length}
      />
    );
  }

  return (
    <ElmoHeader
      title={title}
      titleAddon={titleAddon}
      subHeading={subHeading}
      buttons={secondaryButtons}
      isSearchOpen={isSearchOpen}
      tabs={tabs}
      backButton={getBackButton}
      primaryButton={primaryButton}
    >
      <Search
        isOpen={isSearchOpen}
        searchPath={searchPath}
        closeSearch={() => toggleSearchOpen(false)}
        updateCallback={updateQuery}
        placeholder={searchPlaceHolder}
        initSearchValue={searchValue}
      />
    </ElmoHeader>
  );
};

export default Header;
