import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateSurveyAccessListRequest } from './type';
import { ErrorResponse } from 'type';

export const updateSurveyAccessList: ActionCreator<UpdateSurveyAccessListRequest> =
  createAction('UPDATE_SURVEY_USERS');

export const updateSurveyAccessListSuccess: EmptyActionCreator = createAction(
  'UPDATE_SURVEY_USERS_SUCCESS'
);

export const updateSurveyAccessListFailure: ActionCreator<ErrorResponse> =
  createAction('UPDATE_SURVEY_USERS_FAILURE');

export const clearUpdateSurveyAccessList: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_SURVEY_USERS'
);
