import Row from '../../Grid/Row';
import React from 'react';
import { getClass, isElementOfType } from '../../_lib/helper';
import ListTable from '../ListTable';
import ListTableTh from './ListTableTh';
import { withListTableContext } from '../ListTableContextConsumer';
function ListTableHeader(_a) {
    var isBulkActionsEnabled = _a.isBulkActionsEnabled, columns = _a.columns, hasActions = _a.hasActions;
    var colsClassNames = getClass('elmo-listtable__cols', '', {
        actions: hasActions && !isBulkActionsEnabled,
    });
    if (!columns) {
        return null;
    }
    return (React.createElement("div", { className: colsClassNames, role: "row" },
        React.createElement(Row, { className: "elmo-listtable__header" }, columns.map(function (child, index) {
            if (!isElementOfType(child, ListTable.Column)) {
                return null;
            }
            var column = child;
            return (React.createElement(ListTableTh, { index: index, label: column.props.label, sortable: !!column.props.sortable, key: index, isPlaceholder: column.props.isPlaceholder }));
        }))));
}
ListTableHeader.displayName = 'ListTableHeader';
export default withListTableContext(ListTableHeader);
