import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { deleteSurveyFailure, deleteSurveySuccess } from './actions';
import { deleteSurveyApi } from './api';
import { SagaAction } from 'type';

export const deleteSurveySaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    yield call(deleteSurveyApi(action.payload));

    yield put(deleteSurveySuccess());
  } catch (error) {
    yield put(deleteSurveyFailure(error));
  }
};
