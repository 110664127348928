import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetBenchmarkReportResponse } from './type';
import { ErrorResponse } from 'type';

export const getBenchmarkReport: ActionCreator<number> = createAction(
  'GET_BENCHMARK_REPORT'
);

export const getBenchmarkReportSuccess: ActionCreator<GetBenchmarkReportResponse> =
  createAction('GET_BENCHMARK_REPORT_SUCCESS');

export const getBenchmarkReportFailure: ActionCreator<ErrorResponse> =
  createAction('GET_BENCHMARK_REPORT_FAILURE');

export const clearGetBenchmarkReport: EmptyActionCreator = createAction(
  'CLEAR_GET_BENCHMARK_REPORT'
);
