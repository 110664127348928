import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/theme/set-default/:themeId';

export const setDefaultThemeApi = (themeId: number) => {
  return putRequest(
    getRoute(apiUrl, {
      params: { themeId },
    })
  );
};
