import {
  clearSurvey,
  getSurvey,
  getSurveyFailure,
  getSurveySuccess,
} from '../actions/surveyActions';

export function getSurveyReducer(state: any, surveyId: number) {
  return {
    ...state,
    survey: {
      ...state.survey,
      isLoaded: false,
      isLoading: true,
      isValid: false,
      surveyId: surveyId,
    },
  };
}

export function getSurveySuccessReducer(state: any, data: any) {
  return {
    ...state,
    survey: {
      ...state.survey,
      isLoaded: true,
      isLoading: false,
      isValid: true,
      data: data.data,
    },
  };
}

export function getSurveyFailureReducer(state: any) {
  return {
    ...state,
    survey: {
      ...state.survey,
      isLoaded: true,
      isLoading: false,
      isValid: false,
    },
  };
}

export function clearSurveyReducer(state: any) {
  return {
    ...state,
    survey: {
      ...state.survey,
      isLoaded: false,
      isLoading: false,
      isValid: false,
      survey: {},
    },
  };
}

export const surveyReducers = {
  [`${getSurvey}`]: getSurveyReducer,
  [`${getSurveySuccess}`]: getSurveySuccessReducer,
  [`${getSurveyFailure}`]: getSurveyFailureReducer,
  [`${clearSurvey}`]: clearSurveyReducer,
};
