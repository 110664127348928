import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getSurveyNotificationsFailure,
  getSurveyNotificationsSuccess,
  updateSurveyNotificationsQuery,
} from './actions';
import { getSurveyNotificationsInitialState } from './state';
import { getSurveyNotificationsApi } from './api';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

export const getSurveyNotificationsSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const currentQuery = getQuery(getSurveyNotificationsInitialState.query);

    yield put(updateSurveyNotificationsQuery(currentQuery));

    const data = yield call(
      getSurveyNotificationsApi(action.payload, currentQuery)
    );
    yield put(getSurveyNotificationsSuccess(data));
  } catch (error) {
    yield put(getSurveyNotificationsFailure(error));
  }
};
