import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { initializeAppFailure, initializeAppSuccess } from './actions';
import setup from 'lib/setup';

export const initializeAppSaga = function* (): SagaIterator {
  try {
    yield call(setup);

    yield put(initializeAppSuccess());
  } catch (error) {
    yield put(initializeAppFailure());
  }
};
