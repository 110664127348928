/* eslint-disable max-len */
import * as React from 'react';
import { withIcon } from '../Icon';
import AccessTime from './AccessTime';
import AccountBox from './AccountBox';
import AccountCircle from './AccountCircle';
import AccountCircleOutlined from './AccountCircleOutlined';
import Actions from './Actions';
import Add from './Add';
import AddCourseOutlined from './AddCourseOutlined';
import Administrator from './Administrator';
import Anonymity from './Anonymity';
import Approval from './Approval';
import AppSwitcher from './AppSwitcher';
import ArrowBack from './ArrowBack';
import ArrowBackIos from './ArrowBackIos';
import ArrowForward from './ArrowForward';
import ArrowForwardIos from './ArrowForwardIos';
import AssignmentInd from './AssignmentInd';
import AssignmentIndOutlined from './AssignmentIndOutlined';
import AssignmentLate from './AssignmentLate';
import AssignmentLateOutlined from './AssignmentLateOutlined';
import AssignmentTurnedIn from './AssignmentTurnedIn';
import AssignmentTurnedInOutlined from './AssignmentTurnedInOutlined';
import AttachMoney from './AttachMoney';
import Autorenew from './Autorenew';
import BeachAccess from './BeachAccess';
import BeachAccessOutlined from './BeachAccessOutlined';
import Block from './Block';
import BookmarkBorder from './BookmarkBorder';
import BulkAction from './BulkAction';
import Calendar from './Calendar';
import CalendarToday from './CalendarToday';
import CalendarTodayOutlined from './CalendarTodayOutlined';
import CallMade from './CallMade';
import CallReceived from './CallReceived';
import Cancel from './Cancel';
import CancelOutlined from './CancelOutlined';
import Careers from './Careers';
import Certificate from './Certificate';
import CertificateFilled from './CertificateFilled';
import ChangePassword from './ChangePassword';
import Chat from './Chat';
import ChatOutlined from './ChatOutlined';
import Check from './Check';
import CheckCircle from './CheckCircle';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ClearAllOutlined from './ClearAllOutlined';
import ClientOutlined from './ClientOutlined';
import ClockOnOutlined from './ClockOnOutlined';
import Close from './Close';
import CloudDownload from './CloudDownload';
import CloudUpload from './CloudUpload';
import CloudUploadOutlined from './CloudUploadOutlined';
import ComposeOutlined from './ComposeOutlined';
import Contracts from './Contracts';
import Copy from './Copy';
import CopyOutlined from './CopyOutlined';
import CourseCatalogue from './CourseCatalogue';
import CourseRecommended from './CourseRecommended';
import CourseRequest from './CourseRequest';
import Crt from './Crt';
import Cut from './Cut';
import Dashboard from './Dashboard';
import DashboardOutlined from './DashboardOutlined';
import DateDictionary from './DateDictionary';
import DateRange from './DateRange';
import DateRangeOutlined from './DateRangeOutlined';
import Delete from './Delete';
import DeleteOutlined from './DeleteOutlined';
import Description from './Description';
import DescriptionOutlined from './DescriptionOutlined';
import DiagonallyCollapseOutlined from './DiagonallyCollapseOutlined';
import DiagonallyExpandOutlined from './DiagonallyExpandOutlined';
import Documents from './Documents';
import DoneAll from './DoneAll';
import DragIndicator from './DragIndicator';
import Edit from './Edit';
import EditOutlined from './EditOutlined';
import ElmoAdmin from './ElmoAdmin';
import ElmoHome from './ElmoHome';
import ElmoSort from './ElmoSort';
import ElmoSurvey from './ElmoSurvey';
import EmailTemplates from './EmailTemplates';
import Error from './Error';
import ErrorOutline from './ErrorOutline';
import EventOutlined from './EventOutlined';
import ExpandLess from './ExpandLess';
import ExpandMore from './ExpandMore';
import Expenses from './Expenses';
import ExternalCourse from './ExternalCourse';
import ExternalTraining from './ExternalTraining';
import F2fSessionOutlined from './F2fSessionOutlined';
import File from './File';
import FilterList from './FilterList';
import FilterNewOutlined from './FilterNewOutlined';
import FirstPage from './FirstPage';
import FreeBreakfast from './FreeBreakfast';
import FreeBreakfastOutlined from './FreeBreakfastOutlined';
import GridOn from './GridOn';
import HelpOutline from './HelpOutline';
import High from './High';
import HighlightOff from './HighlightOff';
import HrCore from './HrCore';
import Home from './Home';
import HomeOutlined from './HomeOutlined';
import Info from './Info';
import InfoOutlined from './InfoOutlined';
import LastPage from './LastPage';
import Launch from './Launch';
import Learning from './Learning';
import Leave from './Leave';
import List from './List';
import LocalActivity from './LocalActivity';
import LocalActivityOutlined from './LocalActivityOutlined';
import LocationFilterOutlined from './LocationFilterOutlined';
import LocationOn from './LocationOn';
import LocationOnOutlined from './LocationOnOutlined';
import Lock from './Lock';
import LockOutlined from './LockOutlined';
import LogOut from './LogOut';
import Lookup from './Lookup';
import Mail from './Mail';
import MailOutlined from './MailOutlined';
import MakeRequisition from './MakeRequisition';
import ManagerDashboardOutlined from './ManagerDashboardOutlined';
import Map from './Map';
import MapOutlined from './MapOutlined';
import Marking from './Marking';
import Menu from './Menu';
import MessagingOutlined from './MessagingOutlined';
import MoreHoriz from './MoreHoriz';
import MyLearning from './MyLearning';
import MySurveys from './MySurveys';
import MyTeam from './MyTeam';
import MyTeamOutlined from './MyTeamOutlined';
import NoBreak from './NoBreak';
import Notifications from './Notifications';
import NotificationsNone from './NotificationsNone';
import NotNotified from './NotNotified';
import Null from './Null';
import OpenRequisition from './OpenRequisition';
import Paste from './Paste';
import PauseCircleFilled from './PauseCircleFilled';
import PauseCircleOutline from './PauseCircleOutline';
import Payroll from './Payroll';
import People from './People';
import PeopleOutline from './PeopleOutline';
import PeopleOutlined from './PeopleOutlined';
import PendingOutlined from './PendingOutlined';
import Performance from './Performance';
import PerformanceDirectReports from './PerformanceDirectReports';
import PerformanceIndirectReports from './PerformanceIndirectReports';
import PerformanceOutlined from './PerformanceOutlined';
import PersonAdd from './PersonAdd';
import PersonAddOutlined from './PersonAddOutlined';
import PersonalDetailsOutlined from './PersonalDetailsOutlined';
import PerformancePosition from './PerformancePosition';
import PictureAsCsv from './PictureAsCsv';
import PictureAsPdf from './PictureAsPdf';
import PictureAsPdfOutlined from './PictureAsPdfOutlined';
import PlayCircleFilled from './PlayCircleFilled';
import PlayCircleOutline from './PlayCircleOutline';
import Post from './Post';
import Preferences from './Preferences';
import PrintOutlined from './PrintOutlined';
import Profile from './Profile';
import ProfileFilled from './ProfileFilled';
import ProfessionalDevelopment from './ProfessionalDevelopment';
import QuestionCategories from './QuestionCategories';
import QuestionLibrary from './QuestionLibrary';
import Quiz from './Quiz';
import Recruitment from './Recruitment';
import RemoveRedEye from './RemoveRedEye';
import RemoveRedEyeOutlined from './RemoveRedEyeOutlined';
import Reports from './Reports';
import ReportOutlined from './ReportOutlined';
import ReportOffOutlined from './ReportOffOutlined';
import Required from './Required';
import Restaurant from './Restaurant';
import Review360Reuqest from './Review360Reuqest';
import RewardsRecognition from './RewardsRecognition';
import Roles from './Roles';
import Roster from './Roster';
import RotateLeft from './RotateLeft';
import SaveAlt from './SaveAlt';
import SaveAs from './SaveAs';
import School from './School';
import SchoolOutlined from './SchoolOutlined';
import Scorm from './Scorm';
import Search from './Search';
import SendMessageOutlined from './SendMessageOutlined';
import Settings from './Settings';
import SettingsOutlined from './SettingsOutlined';
import SidePanelLeftOutlined from './SidePanelLeftOutlined';
import SidePanelRightOutlined from './SidePanelRightOutlined';
import SignalCellular4Bar from './SignalCellular4Bar';
import SignOff from './SignOff';
import SignOut from './SignOut';
import SignupOutlined from './SignupOutlined';
import SignupSession from './SignupSession';
import SiteManagement from './SiteManagement';
import Slideshow from './Slideshow';
import SmsFailed from './SmsFailed';
import SmsFailedOutlined from './SmsFailedOutlined';
import Sort from './Sort';
import SortAsc from './SortAsc';
import SortByAscendingOutlined from './SortByAscendingOutlined';
import SortDesc from './SortDesc';
import Star from './Star';
import StarBorder from './StarBorder';
import StarHalf from './StarHalf';
import Status from './Status';
import Survey from './Survey';
import SurveyV2 from './SurveyV2';
import Succession from './Succession';
import SupervisedUserCircle from './SupervisedUserCircle';
import SupervisedUserCircleOutlined from './SupervisedUserCircleOutlined';
import SwapHoriz from './SwapHoriz';
import SwitchAccounts from './SwitchAccounts';
import TagsOutlined from './TagsOutlined';
import Templates from './Templates';
import TemplatesOutlined from './TemplatesOutlined';
import Themes from './Themes';
import ThumbUp from './ThumbUp';
import ThumbUpAlt from './ThumbUpAlt';
import Timer from './Timer';
import TimerOff from './TimerOff';
import Timesheet from './Timesheet';
import TimesheetApproval from './TimesheetApproval';
import Today from './Today';
import TodayOutlined from './TodayOutlined';
import UploadOutlined from './UploadOutlined';
import Urgent from './Urgent';
import UserFilterOutlined from './UserFilterOutlined';
import UserLog from './UserLog';
import UserManagement from './UserManagement';
import ViewAgenda from './ViewAgenda';
import ViewAgendaOutlined from './ViewAgendaOutlined';
import ViewArray from './ViewArray';
import ViewArrayOutlined from './ViewArrayOutlined';
import ViewColumnOutlined from './ViewColumnOutlined';
import ViewHeadline from './ViewHeadline';
import Warning from './Warning';
import WarningOutline from './WarningOutline';
import WbSunny from './WbSunny';
import WbSunnyOutlined from './WbSunnyOutlined';
import WithdrawnProfileOutlined from './WithdrawnProfileOutlined';
import WithdrawRequisition from './WithdrawRequisition';
import WithdrawSession from './WithdrawSession';
import Work from './Work';
import WorkOff from './WorkOff';
import WorkOffOutlined from './WorkOffOutlined';
import WorkOutline from './WorkOutline';
import WorkOutlined from './WorkOutlined';
import YearViewOutlined from './YearViewOutlined';
export var AppSwitcherIcon = withIcon(React.createElement(AppSwitcher, null));
export var AccessTimeIcon = withIcon(React.createElement(AccessTime, null));
export var AccountBoxIcon = withIcon(React.createElement(AccountBox, null));
export var AccountCircleIcon = withIcon(React.createElement(AccountCircle, null));
export var AccountCircleOutlinedIcon = withIcon(React.createElement(AccountCircleOutlined, null));
export var ActionsIcon = withIcon(React.createElement(Actions, null));
export var AddCourseOutlinedIcon = withIcon(React.createElement(AddCourseOutlined, null));
export var AddIcon = withIcon(React.createElement(Add, null));
export var AdministratorIcon = withIcon(React.createElement(Administrator, null));
export var AnonymityIcon = withIcon(React.createElement(Anonymity, null));
export var ApprovalIcon = withIcon(React.createElement(Approval, null));
export var ArrowBackIcon = withIcon(React.createElement(ArrowBack, null));
export var ArrowBackIosIcon = withIcon(React.createElement(ArrowBackIos, null));
export var ArrowForwardIcon = withIcon(React.createElement(ArrowForward, null));
export var ArrowForwardIosIcon = withIcon(React.createElement(ArrowForwardIos, null));
export var AssignmentIndIcon = withIcon(React.createElement(AssignmentInd, null));
export var AssignmentIndOutlinedIcon = withIcon(React.createElement(AssignmentIndOutlined, null));
export var AssignmentLateIcon = withIcon(React.createElement(AssignmentLate, null));
export var AssignmentLateOutlinedIcon = withIcon(React.createElement(AssignmentLateOutlined, null));
export var AssignmentTurnedInIcon = withIcon(React.createElement(AssignmentTurnedIn, null));
export var AssignmentTurnedInOutlinedIcon = withIcon(React.createElement(AssignmentTurnedInOutlined, null));
export var AttachMoneyIcon = withIcon(React.createElement(AttachMoney, null));
export var AutorenewIcon = withIcon(React.createElement(Autorenew, null));
export var BeachAccessIcon = withIcon(React.createElement(BeachAccess, null));
export var BeachAccessOutinedIcon = withIcon(React.createElement(BeachAccessOutlined, null));
export var BlockIcon = withIcon(React.createElement(Block, null));
export var BookmarkBorderIcon = withIcon(React.createElement(BookmarkBorder, null));
export var BulkActionIcon = withIcon(React.createElement(BulkAction, null));
export var CalendarIcon = withIcon(React.createElement(Calendar, null));
export var CalendarTodayIcon = withIcon(React.createElement(CalendarToday, null));
export var CalendarTodayOutlinedIcon = withIcon(React.createElement(CalendarTodayOutlined, null));
export var CallMadeIcon = withIcon(React.createElement(CallMade, null));
export var CallReceivedIcon = withIcon(React.createElement(CallReceived, null));
export var CancelIcon = withIcon(React.createElement(Cancel, null));
export var CancelOutlinedIcon = withIcon(React.createElement(CancelOutlined, null));
export var CareersIcon = withIcon(React.createElement(Careers, null));
export var CertificateIcon = withIcon(React.createElement(Certificate, null));
export var CertificateFilledIcon = withIcon(React.createElement(CertificateFilled, null));
export var ChangePasswordIcon = withIcon(React.createElement(ChangePassword, null));
export var ChatIcon = withIcon(React.createElement(Chat, null));
export var ChatOutlinedIcon = withIcon(React.createElement(ChatOutlined, null));
export var CheckIcon = withIcon(React.createElement(Check, null));
export var CheckCircleIcon = withIcon(React.createElement(CheckCircle, null));
export var ChevronLeftIcon = withIcon(React.createElement(ChevronLeft, null));
export var ChevronRightIcon = withIcon(React.createElement(ChevronRight, null));
export var ClearAllOutlinedIcon = withIcon(React.createElement(ClearAllOutlined, null));
export var ClientOutlinedIcon = withIcon(React.createElement(ClientOutlined, null));
export var ClockOnOutlinedIcon = withIcon(React.createElement(ClockOnOutlined, null));
export var CloseIcon = withIcon(React.createElement(Close, null));
export var CloudDownloadIcon = withIcon(React.createElement(CloudDownload, null));
export var CloudUploadIcon = withIcon(React.createElement(CloudUpload, null));
export var CloudUploadOutlinedIcon = withIcon(React.createElement(CloudUploadOutlined, null));
export var ComposeOutlinedIcon = withIcon(React.createElement(ComposeOutlined, null));
export var ContractsIcon = withIcon(React.createElement(Contracts, null));
export var CopyIcon = withIcon(React.createElement(Copy, null));
export var CopyOutlinedIcon = withIcon(React.createElement(CopyOutlined, null));
export var CourseCatalogueIcon = withIcon(React.createElement(CourseCatalogue, null));
export var CourseRecommendedIcon = withIcon(React.createElement(CourseRecommended, null));
export var CourseRequestIcon = withIcon(React.createElement(CourseRequest, null));
export var CrtIcon = withIcon(React.createElement(Crt, null));
export var CutIcon = withIcon(React.createElement(Cut, null));
export var DashboardIcon = withIcon(React.createElement(Dashboard, null));
export var DashboardOutlinedIcon = withIcon(React.createElement(DashboardOutlined, null));
export var DateDictionaryIcon = withIcon(React.createElement(DateDictionary, null));
export var DateRangeIcon = withIcon(React.createElement(DateRange, null));
export var DateRangeOutlinedIcon = withIcon(React.createElement(DateRangeOutlined, null));
export var DeleteIcon = withIcon(React.createElement(Delete, null));
export var DeleteOutlinedIcon = withIcon(React.createElement(DeleteOutlined, null));
export var DescriptionIcon = withIcon(React.createElement(Description, null));
export var DescriptionOutlinedIcon = withIcon(React.createElement(DescriptionOutlined, null));
export var DiagonallyCollapseOutlinedIcon = withIcon(React.createElement(DiagonallyCollapseOutlined, null));
export var DiagonallyExpandOutlinedIcon = withIcon(React.createElement(DiagonallyExpandOutlined, null));
export var DocumentsIcon = withIcon(React.createElement(Documents, null));
export var DoneAllIcon = withIcon(React.createElement(DoneAll, null));
export var DragIndicatorIcon = withIcon(React.createElement(DragIndicator, null));
export var EditIcon = withIcon(React.createElement(Edit, null));
export var EditOutlinedIcon = withIcon(React.createElement(EditOutlined, null));
export var ElmoAdminIcon = withIcon(React.createElement(ElmoAdmin, null));
export var ElmoHomeIcon = withIcon(React.createElement(ElmoHome, null));
export var ElmoSortIcon = withIcon(React.createElement(ElmoSort, null));
export var ElmoSurveyIcon = withIcon(React.createElement(ElmoSurvey, null));
export var EmailTemplatesIcon = withIcon(React.createElement(EmailTemplates, null));
export var ErrorIcon = withIcon(React.createElement(Error, null));
export var ErrorOutlineIcon = withIcon(React.createElement(ErrorOutline, null));
export var EventOutlinedIcon = withIcon(React.createElement(EventOutlined, null));
export var ExpandLessIcon = withIcon(React.createElement(ExpandLess, null));
export var ExpandMoreIcon = withIcon(React.createElement(ExpandMore, null));
export var ExpensesIcon = withIcon(React.createElement(Expenses, null));
export var ExternalCourseIcon = withIcon(React.createElement(ExternalCourse, null));
export var ExternalTrainingIcon = withIcon(React.createElement(ExternalTraining, null));
export var F2fSessionOutlinedIcon = withIcon(React.createElement(F2fSessionOutlined, null));
export var FileIcon = withIcon(React.createElement(File, null));
export var FilterListIcon = withIcon(React.createElement(FilterList, null));
export var FilterNewOutlinedIcon = withIcon(React.createElement(FilterNewOutlined, null));
export var FirstPageIcon = withIcon(React.createElement(FirstPage, null));
export var FreeBreakfastIcon = withIcon(React.createElement(FreeBreakfast, null));
export var FreeBreakfastOutlinedIcon = withIcon(React.createElement(FreeBreakfastOutlined, null));
export var GridOnIcon = withIcon(React.createElement(GridOn, null));
export var HelpOutlineIcon = withIcon(React.createElement(HelpOutline, null));
export var HighIcon = withIcon(React.createElement(High, null));
export var HighlightOffIcon = withIcon(React.createElement(HighlightOff, null));
export var HomeIcon = withIcon(React.createElement(Home, null));
export var HomeOutlinedIcon = withIcon(React.createElement(HomeOutlined, null));
export var HrCoreIcon = withIcon(React.createElement(HrCore, null));
export var InfoIcon = withIcon(React.createElement(Info, null));
export var InfoOutlinedIcon = withIcon(React.createElement(InfoOutlined, null));
export var LastPageIcon = withIcon(React.createElement(LastPage, null));
export var LaunchIcon = withIcon(React.createElement(Launch, null));
export var LearningIcon = withIcon(React.createElement(Learning, null));
export var LeaveIcon = withIcon(React.createElement(Leave, null));
export var ListIcon = withIcon(React.createElement(List, null));
export var LocalActivityIcon = withIcon(React.createElement(LocalActivity, null));
export var LocalActivityOutlinedIcon = withIcon(React.createElement(LocalActivityOutlined, null));
export var LocationFilterOutlinedIcon = withIcon(React.createElement(LocationFilterOutlined, null));
export var LocationOnIcon = withIcon(React.createElement(LocationOn, null));
export var LocationOnOutlinedIcon = withIcon(React.createElement(LocationOnOutlined, null));
export var LockIcon = withIcon(React.createElement(Lock, null));
export var LockOutlinedIcon = withIcon(React.createElement(LockOutlined, null));
export var LogOutIcon = withIcon(React.createElement(LogOut, null));
export var LookupIcon = withIcon(React.createElement(Lookup, null));
export var MailIcon = withIcon(React.createElement(Mail, null));
export var MailOutlinedIcon = withIcon(React.createElement(MailOutlined, null));
export var MakeRequisitionIcon = withIcon(React.createElement(MakeRequisition, null));
export var ManagerDashboardOutlinedIcon = withIcon(React.createElement(ManagerDashboardOutlined, null));
export var MapIcon = withIcon(React.createElement(Map, null));
export var MapOutlinedIcon = withIcon(React.createElement(MapOutlined, null));
export var MarkingIcon = withIcon(React.createElement(Marking, null));
export var MenuIcon = withIcon(React.createElement(Menu, null));
export var MessagingOutlinedIcon = withIcon(React.createElement(MessagingOutlined, null));
export var MoreHorizIcon = withIcon(React.createElement(MoreHoriz, null));
export var MyLearningIcon = withIcon(React.createElement(MyLearning, null));
export var MySurveysIcon = withIcon(React.createElement(MySurveys, null));
export var MyTeamIcon = withIcon(React.createElement(MyTeam, null));
export var MyTeamOutlinedIcon = withIcon(React.createElement(MyTeamOutlined, null));
export var NoBreakIcon = withIcon(React.createElement(NoBreak, null));
export var NotificationsIcon = withIcon(React.createElement(Notifications, null));
export var NotificationsNoneIcon = withIcon(React.createElement(NotificationsNone, null));
export var NotNotifiedIcon = withIcon(React.createElement(NotNotified, null));
export var NullIcon = withIcon(React.createElement(Null, null));
export var OpenRequisitionIcon = withIcon(React.createElement(OpenRequisition, null));
export var PauseCircleFilledIcon = withIcon(React.createElement(PauseCircleFilled, null));
export var PauseCircleOutlineIcon = withIcon(React.createElement(PauseCircleOutline, null));
export var PasteIcon = withIcon(React.createElement(Paste, null));
export var PayrollIcon = withIcon(React.createElement(Payroll, null));
export var PeopleIcon = withIcon(React.createElement(People, null));
export var PeopleOutlineIcon = withIcon(React.createElement(PeopleOutline, null));
export var PeopleOutlinedIcon = withIcon(React.createElement(PeopleOutlined, null));
export var PendingOutlinedIcon = withIcon(React.createElement(PendingOutlined, null));
export var PerformanceIcon = withIcon(React.createElement(Performance, null));
export var PerformanceOutlinedIcon = withIcon(React.createElement(PerformanceOutlined, null));
export var PerformancePositionIcon = withIcon(React.createElement(PerformancePosition, null));
export var PerformanceDirectReportsIcon = withIcon(React.createElement(PerformanceDirectReports, null));
export var PerformanceIndirectReportsIcon = withIcon(React.createElement(PerformanceIndirectReports, null));
export var PersonAddIcon = withIcon(React.createElement(PersonAdd, null));
export var PersonAddOutlinedIcon = withIcon(React.createElement(PersonAddOutlined, null));
export var PersonalDetailsOutlinedIcon = withIcon(React.createElement(PersonalDetailsOutlined, null));
export var PictureAsCsvIcon = withIcon(React.createElement(PictureAsCsv, null));
export var PictureAsPdfIcon = withIcon(React.createElement(PictureAsPdf, null));
export var PictureAsPdfOutlinedIcon = withIcon(React.createElement(PictureAsPdfOutlined, null));
export var PlayCircleFilledIcon = withIcon(React.createElement(PlayCircleFilled, null));
export var PlayCircleOutlineIcon = withIcon(React.createElement(PlayCircleOutline, null));
export var PostIcon = withIcon(React.createElement(Post, null));
export var PreferencesIcon = withIcon(React.createElement(Preferences, null));
export var PrintOutlinedIcon = withIcon(React.createElement(PrintOutlined, null));
export var ProfileIcon = withIcon(React.createElement(Profile, null));
export var ProfileFilledIcon = withIcon(React.createElement(ProfileFilled, null));
export var ProfessionalDevelopmentIcon = withIcon(React.createElement(ProfessionalDevelopment, null));
export var QuestionCategoriesIcon = withIcon(React.createElement(QuestionCategories, null));
export var QuestionLibraryIcon = withIcon(React.createElement(QuestionLibrary, null));
export var QuizIcon = withIcon(React.createElement(Quiz, null));
export var RecruitmentIcon = withIcon(React.createElement(Recruitment, null));
export var RemoveRedEyeIcon = withIcon(React.createElement(RemoveRedEye, null));
export var RemoveRedEyeOutlinedIcon = withIcon(React.createElement(RemoveRedEyeOutlined, null));
export var ReportsIcon = withIcon(React.createElement(Reports, null));
export var ReportOffOutlinedIcon = withIcon(React.createElement(ReportOffOutlined, null));
export var ReportOutlinedIcon = withIcon(React.createElement(ReportOutlined, null));
export var RequiredIcon = withIcon(React.createElement(Required, null));
export var RestaurantIcon = withIcon(React.createElement(Restaurant, null));
export var Review360RequestIcon = withIcon(React.createElement(Review360Reuqest, null));
export var RewardsRecognitionIcon = withIcon(React.createElement(RewardsRecognition, null));
export var RolesIcon = withIcon(React.createElement(Roles, null));
export var RosterIcon = withIcon(React.createElement(Roster, null));
export var RotateLeftIcon = withIcon(React.createElement(RotateLeft, null));
export var SaveAltIcon = withIcon(React.createElement(SaveAlt, null));
export var SaveAsIcon = withIcon(React.createElement(SaveAs, null));
export var SchoolIcon = withIcon(React.createElement(School, null));
export var SchoolOutlinedIcon = withIcon(React.createElement(SchoolOutlined, null));
export var ScormIcon = withIcon(React.createElement(Scorm, null));
export var SearchIcon = withIcon(React.createElement(Search, null));
export var SendMessageOutlinedIcon = withIcon(React.createElement(SendMessageOutlined, null));
export var SettingsIcon = withIcon(React.createElement(Settings, null));
export var SettingsOutlinedIcon = withIcon(React.createElement(SettingsOutlined, null));
export var SidePanelLeftOutlinedIcon = withIcon(React.createElement(SidePanelLeftOutlined, null));
export var SidePanelRightOutlinedIcon = withIcon(React.createElement(SidePanelRightOutlined, null));
export var SignalCellular4BarIcon = withIcon(React.createElement(SignalCellular4Bar, null));
export var SignOffIcon = withIcon(React.createElement(SignOff, null));
export var SignOutIcon = withIcon(React.createElement(SignOut, null));
export var SignupOutlinedIcon = withIcon(React.createElement(SignupOutlined, null));
export var SignupSessionIcon = withIcon(React.createElement(SignupSession, null));
export var SiteManagementIcon = withIcon(React.createElement(SiteManagement, null));
export var SlideshowIcon = withIcon(React.createElement(Slideshow, null));
export var SmsFailedIcon = withIcon(React.createElement(SmsFailed, null));
export var SmsFailedOutlinedIcon = withIcon(React.createElement(SmsFailedOutlined, null));
export var SortIcon = withIcon(React.createElement(Sort, null));
export var SortAscIcon = withIcon(React.createElement(SortAsc, null));
export var SortByAscendingOutlinedIcon = withIcon(React.createElement(SortByAscendingOutlined, null));
export var SortDescIcon = withIcon(React.createElement(SortDesc, null));
export var StatusIcon = withIcon(React.createElement(Status, null));
export var StarIcon = withIcon(React.createElement(Star, null));
export var StarBorderIcon = withIcon(React.createElement(StarBorder, null));
export var StarHalfIcon = withIcon(React.createElement(StarHalf, null));
export var SuccessionIcon = withIcon(React.createElement(Succession, null));
export var SurveyIcon = withIcon(React.createElement(Survey, null));
export var SurveyV2Icon = withIcon(React.createElement(SurveyV2, null));
export var SupervisedUserCircleIcon = withIcon(React.createElement(SupervisedUserCircle, null));
export var SupervisedUserCircleOutlinedIcon = withIcon(React.createElement(SupervisedUserCircleOutlined, null));
export var SwapHorizIcon = withIcon(React.createElement(SwapHoriz, null));
export var SwitchAccountsIcon = withIcon(React.createElement(SwitchAccounts, null));
export var TagsOutlinedIcon = withIcon(React.createElement(TagsOutlined, null));
export var TemplatesIcon = withIcon(React.createElement(Templates, null));
export var TemplatesOutlinedIcon = withIcon(React.createElement(TemplatesOutlined, null));
export var ThemesIcon = withIcon(React.createElement(Themes, null));
export var ThumbUpIcon = withIcon(React.createElement(ThumbUp, null));
export var ThumbUpAltIcon = withIcon(React.createElement(ThumbUpAlt, null));
export var TimerIcon = withIcon(React.createElement(Timer, null));
export var TimerOffIcon = withIcon(React.createElement(TimerOff, null));
export var TimesheetIcon = withIcon(React.createElement(Timesheet, null));
export var TimesheetApprovalIcon = withIcon(React.createElement(TimesheetApproval, null));
export var TodayIcon = withIcon(React.createElement(Today, null));
export var TodayOutlinedIcon = withIcon(React.createElement(TodayOutlined, null));
export var UploadOutlinedIcon = withIcon(React.createElement(UploadOutlined, null));
export var UrgentIcon = withIcon(React.createElement(Urgent, null));
export var UserFilterOutlinedIcon = withIcon(React.createElement(UserFilterOutlined, null));
export var UserLogIcon = withIcon(React.createElement(UserLog, null));
export var UserManagementIcon = withIcon(React.createElement(UserManagement, null));
export var ViewAgendaIcon = withIcon(React.createElement(ViewAgenda, null));
export var ViewAgendaOutlinedIcon = withIcon(React.createElement(ViewAgendaOutlined, null));
export var ViewArrayIcon = withIcon(React.createElement(ViewArray, null));
export var ViewArrayOutlinedIcon = withIcon(React.createElement(ViewArrayOutlined, null));
export var ViewColumnOutlinedIcon = withIcon(React.createElement(ViewColumnOutlined, null));
export var ViewHeadlineIcon = withIcon(React.createElement(ViewHeadline, null));
export var WarningIcon = withIcon(React.createElement(Warning, null));
export var WarningOutlineIcon = withIcon(React.createElement(WarningOutline, null));
export var WbSunnyIcon = withIcon(React.createElement(WbSunny, null));
export var WbSunnyOutlinedIcon = withIcon(React.createElement(WbSunnyOutlined, null));
export var WithdrawnProfileOutlinedIcon = withIcon(React.createElement(WithdrawnProfileOutlined, null));
export var WithdrawRequisitionIcon = withIcon(React.createElement(WithdrawRequisition, null));
export var WithdrawSessionIcon = withIcon(React.createElement(WithdrawSession, null));
export var WorkIcon = withIcon(React.createElement(Work, null));
export var WorkOffIcon = withIcon(React.createElement(WorkOff, null));
export var WorkOffOutlinedIcon = withIcon(React.createElement(WorkOffOutlined, null));
export var WorkOutlineIcon = withIcon(React.createElement(WorkOutline, null));
export var WorkOutlinedIcon = withIcon(React.createElement(WorkOutlined, null));
export var YearViewOutlinedIcon = withIcon(React.createElement(YearViewOutlined, null));
