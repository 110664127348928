import React from 'react';
import Button from '../../Button';
import { Text } from '../../Typography';
function SearchCloseButton(_a) {
    var onClick = _a.onClick;
    return (React.createElement("div", { className: "elmo-search__close" },
        React.createElement(Button, { isText: true, onClick: onClick },
            React.createElement(Text, { size: "button" }, "Cancel"))));
}
export default SearchCloseButton;
