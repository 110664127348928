import {
  List as ListType,
  ListNoQuery as ListNoQueryType,
  Create as CreateType,
  Update as UpdateType,
  Item as ItemType,
  Delete as DeleteType,
} from './type';

export const Query = {
  filter: 0,
  page: 1,
  limit: 10,
  status: '',
  sort: '',
  keyword: '',
};

export const List: ListType = {
  isLoaded: false,
  isLoading: false,
  isValid: false,
  count: 0,
  data: null,
  query: Query,
};

export const ListNoQuery: ListNoQueryType = {
  isLoaded: false,
  isLoading: false,
  count: 0,
  data: null,
};

export const Update: UpdateType = {
  isUpdated: false,
  isUpdating: false,
  isValid: false,
  errors: null,
};

export const Create: CreateType = {
  isCreating: false,
  isCreated: false,
  isValid: false,
  data: undefined,
  error: undefined,
};

export const Item: ItemType = {
  isLoaded: false,
  isLoading: false,
  isValid: false,
  data: undefined,
};

export const Delete: DeleteType = {
  isDeleted: false,
  isDeleting: false,
  isValid: false,
  error: undefined,
};

export const CollectorTypes = {
  url: 'url',
  email: 'email',
  bulkUrl: 'bulkurl',
  learning: 'learning',
  onBoarding: 'onboarding',
  recruitment: 'recruitment',
};
