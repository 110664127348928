import { deleteRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const themeRoute = '/theme/:themeId';

export const deleteThemeApi = (themeId: number) => {
  return deleteRequest(
    getRoute(themeRoute, {
      params: { themeId },
    })
  );
};
