import { createReducer } from 'redux-act';

import { overviewListReducers } from './reducers/overviewListReducers';
import { surveyReducers } from './reducers/surveyReducers';
import ReportsInitialState from './state';

export default createReducer(
  {
    ...overviewListReducers,
    ...surveyReducers,
  },
  ReportsInitialState
);
