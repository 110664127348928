var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import './CheckButton.scss';
var CheckButton = /** @class */ (function (_super) {
    __extends(CheckButton, _super);
    function CheckButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isChecked: false,
        };
        _this.handleToggle = function () {
            var isDisabled = _this.props.isDisabled;
            if (isDisabled) {
                return;
            }
            _this.updateChecked(!_this.state.isChecked);
        };
        _this.updateChecked = function (isChecked) {
            var _a = _this.props, onStatusChange = _a.onStatusChange, id = _a.id, className = _a.className;
            _this.setState({
                isChecked: isChecked,
            });
            if (onStatusChange) {
                var data = { isChecked: isChecked };
                if (id) {
                    Object.assign(data, { id: id });
                }
                if (className) {
                    Object.assign(data, { className: className });
                }
                onStatusChange(data);
            }
        };
        return _this;
    }
    CheckButton.prototype.componentDidMount = function () {
        var isChecked = this.props.isChecked;
        this.setState({
            isChecked: isChecked,
        });
    };
    CheckButton.prototype.componentDidUpdate = function (prevProps, prevState) {
        var isChecked = this.props.isChecked;
        if (isChecked !== prevProps.isChecked) {
            this.setState({
                isChecked: isChecked,
            });
        }
    };
    CheckButton.prototype.render = function () {
        var _a = this.props, id = _a.id, className = _a.className, isDisabled = _a.isDisabled, tabIndex = _a.tabIndex, children = _a.children;
        var isChecked = this.state.isChecked;
        return (React.createElement("div", { onClick: this.handleToggle, className: getClass('elmo-checkbutton', className, {
                disabled: isDisabled,
                checked: isChecked,
            }), "data-testid": "elmo-checkbutton-" + (id || 'default'), tabIndex: tabIndex, role: "checkbox", "aria-checked": isChecked, "aria-disabled": isDisabled },
            React.createElement("span", null, children)));
    };
    CheckButton.defaultProps = {
        isChecked: false,
        tabIndex: 0,
    };
    return CheckButton;
}(Component));
export default CheckButton;
