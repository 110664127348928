import { createQuestionInitialState } from './createQuestion/state';
import { deleteQuestionInitialState } from './deleteQuestion/state';
import { updateQuestionInitialState } from './updateQuestion/state';
import { getQuestionsInitialState } from './getQuestions/state';
import { getQuestionInitialState } from './getQuestion/state';
import { QuestionState } from './type';

export const questionInitialState: QuestionState = {
  createQuestion: createQuestionInitialState,
  updateQuestion: updateQuestionInitialState,
  getQuestion: getQuestionInitialState,
  getQuestions: getQuestionsInitialState,
  deleteQuestion: deleteQuestionInitialState,
};
