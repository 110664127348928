import { Button, NativeTable, Row } from 'elmo-elements';
import EmailIcon from '@mui/icons-material/Email';
import { useSelector } from 'react-redux';
import React from 'react';

import SummaryCard from 'element/card/components/summaryCard';
import { MessageSummaryProps } from './type';
import translations from './translations';
import { t } from 'lib/translation';
import './styles.scss';

const Message = ({
  editMessage,
  showSummary,
  showAddAction,
}: MessageSummaryProps) => {
  const parameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );
  const messageDetails = useSelector(
    (state: any) => state.emailInvites.manageInvite.data.message
  );

  if (showAddAction && !showSummary) {
    return (
      <Row justifyContent={'center'}>
        <Button
          id={'manage-invite-message'}
          type={'primary'}
          onClick={editMessage}
        >
          {t(translations.addButton)}
        </Button>
      </Row>
    );
  }

  if (!showSummary) {
    return <></>;
  }
  return (
    <>
      <SummaryCard
        id={'invite-message'}
        icon={<EmailIcon />}
        sectionTitle={t(translations.sectionTitle)}
        hasPrimaryAction={!parameters.isEdit}
        primaryActionOnClick={editMessage}
      >
        <NativeTable>
          <NativeTable.Body>
            <NativeTable.Tr>
              <NativeTable.Th>{t(translations.sender)}</NativeTable.Th>
              <NativeTable.Td>
                {messageDetails.senderEmail} ({messageDetails.senderName})
              </NativeTable.Td>
            </NativeTable.Tr>
            <NativeTable.Tr>
              <NativeTable.Th>{t(translations.subject)}</NativeTable.Th>
              <NativeTable.Td>{messageDetails.subject}</NativeTable.Td>
            </NativeTable.Tr>
            <NativeTable.Tr>
              <NativeTable.Th>{t(translations.message)}</NativeTable.Th>
              <NativeTable.Td>
                <div
                  className={'message-content'}
                  dangerouslySetInnerHTML={{ __html: messageDetails.content }}
                />
              </NativeTable.Td>
            </NativeTable.Tr>
          </NativeTable.Body>
        </NativeTable>
      </SummaryCard>
    </>
  );
};

export default Message;
