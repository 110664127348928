import produce from 'immer';

import {
  uploadBulkPasswordCSV,
  uploadBulkPasswordCSVFailure,
  uploadBulkPasswordCSVSuccess,
  clearUploadBulkPasswordCSV,
} from './actions';
import { uploadBulkPasswordCSVInitialState } from './state';
import { UploadBulkPasswordCSVResponse } from './type';
import { ShareReportState } from '../type';
import { ErrorResponse } from 'type';

export const uploadBulkPasswordCSVReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.uploadBulkPasswordCSV = { ...uploadBulkPasswordCSVInitialState };
    draft.uploadBulkPasswordCSV.isLoading = true;
  });
};

export const uploadBulkPasswordCSVSuccessReducer = (
  state: ShareReportState,
  data: UploadBulkPasswordCSVResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.uploadBulkPasswordCSV.isLoading = false;
    draft.uploadBulkPasswordCSV.isLoaded = true;
    draft.uploadBulkPasswordCSV.isValid = true;
    draft.uploadBulkPasswordCSV.files = data.data.files;
  });
};

export const uploadBulkPasswordCSVFailureReducer = (
  state: ShareReportState,
  error: ErrorResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.uploadBulkPasswordCSV.isLoading = false;
    draft.uploadBulkPasswordCSV.isLoaded = true;
    draft.uploadBulkPasswordCSV.error = error;
  });
};

export const clearUploadBulkPasswordCSVReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.uploadBulkPasswordCSV = uploadBulkPasswordCSVInitialState;
  });
};

export const uploadBulkPasswordCSVReducers = {
  [`${uploadBulkPasswordCSV}`]: uploadBulkPasswordCSVReducer,
  [`${uploadBulkPasswordCSVSuccess}`]: uploadBulkPasswordCSVSuccessReducer,
  [`${uploadBulkPasswordCSVFailure}`]: uploadBulkPasswordCSVFailureReducer,
  [`${clearUploadBulkPasswordCSV}`]: clearUploadBulkPasswordCSVReducer,
};
