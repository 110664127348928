const translations = {
  title: {
    key: '',
    default: 'List Details',
  },
  primaryButton: {
    saveClose: {
      key: '',
      default: 'Save & Close',
    },
    saveContinue: {
      key: '',
      default: 'Save & Continue',
    },
  },
  form: {
    name: {
      label: {
        key: '',
        default: 'List name',
      },
      message: {
        key: '',
        default:
          '(Give your list a name so you can find it easily in your reports)',
      },
    },
  },
};

export default translations;
