import { Query } from 'lib/stateTemplates';
import { GetThemesState } from './type';

export const getThemesInitialState: GetThemesState = {
  isValid: false,
  isLoaded: false,
  isLoading: false,
  count: 0,
  data: [],
  errors: undefined,
  query: Query,
};
