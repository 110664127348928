import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import { getSurveyFailure, getSurveySuccess } from '../actions/surveyActions';
import ReportsApi from '../api';

export const getSurveySaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(ReportsApi.getSurvey(action.payload));
    yield put(getSurveySuccess(data));
  } catch (error) {
    yield put(getSurveyFailure(error));

    window.location.href = '/reports/survey-overview';
  }
};
