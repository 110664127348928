import { put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import filter from 'lodash/filter';

import {
  updateManageInviteDetails,
  updateManageInviteMessage,
  updateManageInviteParameter,
  updateManageInviteRecipients,
  updateManageInviteReminders,
} from './actions';
import { getAssociatedFiltersCount } from '../../../../lib/helpers/emailRecipients';
import { getManageInviteState } from './selectors';
import { Message } from 'type';

export const saveInviteDraftSaga = function* (): SagaIterator {
  const inviteState = yield select(getManageInviteState);

  if (!inviteState.parameters.isEdit) {
    localStorage.setItem(
      'survey-invite-draft',
      JSON.stringify(inviteState.data)
    );
  }
};

export const transformSavedInviteSaga = function* (action: any): SagaIterator {
  const payload = action.payload.data;

  yield put(
    updateManageInviteParameter({
      isEdit: true,
      isFlow: false,
    })
  );

  const details = {
    id: payload.id,
    recipientGroup: payload.title,
    createdAt: payload.createdAt,
    updatedAt: payload.updatedAt,
  };

  yield put(updateManageInviteDetails(details));

  const importType = payload.recipients[0].inputType;
  const recipientList = filter(payload.recipients, ['inputType', 'Manual']);
  const tmsUserList = filter(payload.recipients, ['inputType', 'TMS Profiles']);
  const importList = filter(payload.recipients, ['inputType', 'Import']);

  let inputType = 'unknown';

  if (importType === 'TMS Profiles') {
    inputType = 'selectTmsProfiles';
  }

  if (importType === 'Manual') {
    inputType = 'manualInput';
  }

  if (importType === 'Import') {
    inputType = 'csvImport';
  }

  const recipients = {
    inputType: inputType,
    recipients: recipientList,
    uuids: tmsUserList,
    csvUpload: {
      recipientsCount: importList.length,
      associatedFiltersCount:
        importList.length > 0 ? getAssociatedFiltersCount(importList) : 0,
    },
  };

  yield put(updateManageInviteRecipients(recipients));

  if (payload.inviteMessage) {
    const message: Message = {
      selectedTemplate: null,
      senderEmail: payload.inviteMessage.senderEmail,
      senderName: payload.inviteMessage.senderName,
      content: payload.inviteMessage.content,
      subject: payload.inviteMessage.subject,
      id: payload.inviteMessage.id,
      scheduledDate: payload.inviteMessage.scheduledDate,
      sentDate: payload.inviteMessage.sentDate,
      status: payload.inviteMessage.status,
    };

    yield put(updateManageInviteMessage(message));
  }

  const reminderMessagesCount = payload.reminderMessages.length;

  if (reminderMessagesCount > 0) {
    for (let i = 0; i < reminderMessagesCount; i++) {
      const reminderPayload = payload.reminderMessages[i];

      const reminderMessage: Message = {
        selectedTemplate: null,
        senderEmail: reminderPayload.senderEmail,
        senderName: reminderPayload.senderName,
        content: reminderPayload.content,
        subject: reminderPayload.subject,
        id: reminderPayload.id,
        scheduledDate: reminderPayload.scheduledDate,
        sentDate: reminderPayload.sentDate,
        status: reminderPayload.status,
      };

      yield put(updateManageInviteReminders(reminderMessage));
    }
  }

  if (action.payload.action === '#editSchedule') {
    yield put(
      updateManageInviteParameter({
        currentStep: 'sendSchedule',
      })
    );
  }
};
