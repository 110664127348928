import { ReportsInitialState } from '../type';
import { List } from 'lib/stateTemplates';
// eslint-disable-next-line max-len

export const Query = {
  filter: 0,
  page: 1,
  limit: 10,
  status: '',
  sort: '',
  keyword: '',
};

export const CustomPassword = {
  isLoaded: false,
  isLoading: false,
  readyToUpload: false,
  sharedReportBatch: {},
  files: [],
  isUpdatingPassword: false,
  isUpdated: false,
  errorMessages: {
    uploadPasswordCsvFile: '',
    updatePassword: '',
  },
};

export const CreateCheck = {
  isLoading: false,
  isLoaded: false,
  id: null,
  data: {},
};

export const survey = {
  isLoaded: false,
  isLoading: false,
  isValid: false,
  surveyId: 0,
  data: {},
};

export const genderOptions = {
  filterGenderFemale: false,
  filterGenderMale: false,
  filterGenderOther: false,
};

export const ageOptions = {
  from18to24: false,
  from25to34: false,
  from35to44: false,
  from45to54: false,
  from55to64: false,
  from65to65plus: false,
};

export const residencyOptions = {
  australian: false,
  foreign: false,
  workHolidayMaker: false,
};

export const payOptions = {
  level1: false,
  level2: false,
  level3: false,
  level4: false,
  level5: false,
};

export const employmentStatusOptions = {
  fullTime: false,
  partTime: false,
  casual: false,
  labourHire: false,
};

export const reportPagination = {
  currentPage: 1,
  pageSize: 10,
};

export const shareReport = {
  associatedFilters: {
    isLoaded: false,
    isLoading: false,
    data: [],
  },
  sharedLinks: {
    isLoaded: false,
    isLoading: false,
    count: 0,
    list: [],
    query: Query,
  },
  create: {
    isAdded: false,
    isLoading: false,
    shortUrl: '',
    errorMessages: '',
  },
  createBulk: {
    isAdded: false,
    isLoading: false,
    linksCount: 0,
    errorMessages: '',
  },
  sharedBulkLinks: {
    isLoaded: false,
    isLoading: false,
    count: 0,
    list: [],
    query: Query,
    errorMessages: '',
  },
  closeLink: {
    isUpdated: false,
    isUpdating: false,
    error: null,
  },
  customPassword: CustomPassword,
  createCheck: CreateCheck,
  filterValues: {},
};

export const overviewList = {
  ...List,
  query: {
    ...Query,
    sort: '-published',
  },
};

export const answersModal = {
  isLoading: false,
  isLoaded: false,
  isValid: false,
  data: [],
  pagination: {
    pageSize: 10,
    currentPage: 1,
    totalResults: 0,
  },
};

export const collectorOptions = {
  isLoading: false,
  isLoaded: false,
  data: [],
};

export default {
  survey: survey,
  shareReport: shareReport,
  overviewList: overviewList,
} as ReportsInitialState;
