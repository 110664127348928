import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  createIndividualLinkFailure,
  createIndividualLinkSuccess,
} from './actions';
import { CreateIndividualLinkRequest } from './type';
import { createIndividualLinkApi } from './api';
import { SagaAction } from 'type';

export const createIndividualLinkSaga = function* (
  action: SagaAction<CreateIndividualLinkRequest>
): SagaIterator {
  try {
    const data = yield call(createIndividualLinkApi(action.payload));

    yield put(createIndividualLinkSuccess(data));
  } catch (error) {
    yield put(createIndividualLinkFailure(error));
  }
};
