import React, { Children, forwardRef, } from 'react';
import './DropdownBody.scss';
var DropdownBody = forwardRef(function (_a, ref) {
    var id = _a.id, toggleMenu = _a.toggleMenu, parentId = _a.parentId, children = _a.children, isWidthAuto = _a.isWidthAuto;
    var classname = 'elmo-dropdown__menu ' +
        (isWidthAuto ? 'elmo-dropdown__menu--is-width-auto' : '');
    return (React.createElement("ul", { id: id, className: classname, "data-testid": "elmo-dropdown-body-" + (parentId || 'default'), ref: ref, role: "menu" }, Children.map(children, function (child, rowIndex) {
        return React.cloneElement(child, {
            toggleMenu: toggleMenu,
        });
    })));
});
export default DropdownBody;
