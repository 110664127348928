import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const createScheduleUrl = '/invite-messages/:messageId/schedule';

export const createInviteMessageScheduleApi = (
  messageId: number,
  scheduleDate: string
) => {
  return postRequest(getRoute(createScheduleUrl, { params: { messageId } }), {
    scheduleDate,
  });
};
