import { call, put } from 'redux-saga/effects';

import { getThemeFailure, getThemeSuccess } from './actions';
import { GetThemeResponse } from './type';
import { getThemeApi } from './api';
import { SagaAction } from 'type';

export const getThemeSaga = function* (action: SagaAction<string>) {
  try {
    const resp: GetThemeResponse = yield call(getThemeApi(action.payload));
    yield put(getThemeSuccess(resp));
  } catch (err) {
    yield put(getThemeFailure(err));
  }
};
