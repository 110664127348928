import produce from 'immer';

import {
  closeIndividualLink,
  closeIndividualLinkFailure,
  closeIndividualLinkSuccess,
  clearCloseIndividualLink,
} from './actions';
import { closeIndividualLinkInitialState } from './state';
import { ShareReportState } from '../type';
import { ErrorResponse } from 'type';

export const closeIndividualLinkReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.closeIndividualLink = { ...closeIndividualLinkInitialState };
    draft.closeIndividualLink.isLoading = true;
  });
};

export const closeIndividualLinkSuccessReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.closeIndividualLink.isLoading = false;
    draft.closeIndividualLink.isLoaded = true;
    draft.closeIndividualLink.isValid = true;
  });
};

export const closeIndividualLinkFailureReducer = (
  state: ShareReportState,
  error: ErrorResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.closeIndividualLink.isLoading = false;
    draft.closeIndividualLink.isLoaded = true;
    draft.closeIndividualLink.error = error;
  });
};

export const clearCloseIndividualLinkReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.closeIndividualLink = closeIndividualLinkInitialState;
  });
};

export const closeIndividualLinkReducers = {
  [`${closeIndividualLink}`]: closeIndividualLinkReducer,
  [`${closeIndividualLinkSuccess}`]: closeIndividualLinkSuccessReducer,
  [`${closeIndividualLinkFailure}`]: closeIndividualLinkFailureReducer,
  [`${clearCloseIndividualLink}`]: clearCloseIndividualLinkReducer,
};
