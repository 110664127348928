import React from 'react';
import Modal from '../../Modal';
import Loader from '../../Loader';
import './PlaceholderModal.scss';
function Title() {
    return React.createElement(Loader, { type: "short-text", width: "250px" });
}
function PrimaryButton() {
    return (React.createElement("div", { className: "elmo-modal-btn--placeholder" },
        React.createElement(Loader, { type: "button", width: "280px" })));
}
function PlaceholderModal(_a) {
    var id = _a.id, type = _a.type, closeModal = _a.closeModal, hasPrimaryButton = _a.hasPrimaryButton;
    return (React.createElement(Modal, { id: id, type: type, title: React.createElement(Title, null), isOpened: true, closeModal: closeModal, hasCloseFooterButton: false, primaryButton: hasPrimaryButton && React.createElement(PrimaryButton, null) },
        React.createElement(Loader, { type: "narrow-content" })));
}
PlaceholderModal.defaultProps = {
    type: null,
    hasPrimaryButton: true,
    id: 'elmo-placeholder-modal',
    // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
    closeModal: function () { },
};
export default PlaceholderModal;
