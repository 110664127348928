import React from 'react';
import DOMpurify from 'dompurify';
import { MenuFootnoteStyled } from '../Styles';
DOMpurify.addHook('afterSanitizeAttributes', function (node) {
    if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
    }
});
function MenuFootnote(_a) {
    var footerMessage = _a.footerMessage, menuFontColor = _a.menuFontColor, menuFont = _a.menuFont;
    var content = footerMessage ? DOMpurify.sanitize(footerMessage) : '';
    return (React.createElement(MenuFootnoteStyled, { className: "elmo-nav-menu__footnote", 
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML: { __html: content }, menuFontColor: menuFontColor, menuFont: menuFont }));
}
export default MenuFootnote;
