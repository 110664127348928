import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetEmailTemplatesResponse } from './type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getEmailTemplates: EmptyActionCreator = createAction(
  'GET_EMAIL_TEMPLATES'
);

export const getEmailTemplatesSuccess: ActionCreator<GetEmailTemplatesResponse> =
  createAction('GET_EMAIL_TEMPLATES_SUCCESS');

export const getEmailTemplatesFailure: ActionCreator<ErrorResponse> =
  createAction('GET_EMAIL_TEMPLATES_FAILURE');

export const clearEmailTemplates: EmptyActionCreator = createAction(
  'CLEAR_EMAIL_TEMPLATES'
);

export const updateGetEmailTemplatesQuery: ActionCreator<Query> = createAction(
  'UPDATE_GET_EMAIL_TEMPLATES_QUERY'
);
