import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import { Query as defaultQuery } from '../stateTemplates/index';
import { isRestoreQueryEnabled } from '../helpers/featureFlag';
import { getRoute, queryString } from '../util';
import { DefaultQuery } from 'type';
import history from '../history';
import { Query } from './type';

/**
 * Get current query string and merge with default
 */
export function getQuery(userQuery?: any) {
  const currentQuery = queryString.parse(document.location.search);
  const query = {
    ...userQuery,
    ...currentQuery,
  };

  if (query.filter !== undefined) {
    query.filter = parseInt(query.filter, 10);
  }

  query.page = parseInt(query.page, 10);
  query.limit = parseInt(query.limit, 10);
  return query;
}

export function getCurrentQuery() {
  const currentQuery = queryString.parse(document.location.search);

  if (currentQuery === undefined) {
    return [];
  }

  return currentQuery;
}

export function getCurrentFilter(query: Query): number {
  const currentQuery = getQuery(query);

  return Number(currentQuery.filter);
}

export function getCurrentView(query: Query = getQuery()): string {
  const currentQuery = getQuery(query);

  return currentQuery.view;
}

export function getSurveyIdFromQueryString(): number {
  const currentQuery = getQuery();

  return Number(currentQuery.surveyId);
}

export function getReportIdFromQueryString(): number {
  const currentQuery = getQuery();

  return Number(currentQuery.reportId);
}

export function getSortFilter(query: Query): string {
  const currentQuery = getQuery(query);

  return currentQuery.sort;
}

/**
 * split sort query into the direction and columnIndex
 * @param sortableColumns
 * @param sort
 * @param sortKey
 */
export function getSortData(
  sortableColumns: any[],
  sort?: string,
  sortKey: string = 'id'
) {
  let sortColumn: number = -1;
  let sortDirection: string = 'asc';
  let sortColumnKey: string = '';
  if (sort) {
    const sortSplit = sort.split('-');
    sortColumnKey = sortSplit.length === 1 ? sortSplit[0] : sortSplit[1];
    sortDirection = sortSplit.length === 1 ? 'asc' : 'desc';
    sortableColumns.forEach((item, index) => {
      if (item[sortKey] === sortColumnKey) {
        sortColumn = index;
      }
    });
  }

  return {
    sortDirection,
    sortColumn,
    sortColumnKey,
  };
}

/**
 * Get exist filters/search query that are not default
 */
export const getUserSetQueryValues = (
  userQuery: Partial<Query>,
  initialQuery: any = defaultQuery
): Partial<Query> => {
  return omitBy(userQuery, function (value: any, key: any) {
    return initialQuery[key] === value;
  });
};

/**
 * Push change into history and trigger getting query
 * @param path
 * @param query
 * @param callback
 */
export const doRouteUpdateAndQuery = (
  path: string,
  query: any,
  callback: Function
): any => {
  history.push(getRoute(path, { query: query }));
  callback(query);
};

export const updateView = (
  view: string,
  currentQuery: Query,
  callback: Function = () => true
) => {
  const newQuery = getQuery(currentQuery);

  newQuery.view = view;

  const userQuery = getUserSetQueryValues(newQuery);

  doRouteUpdateAndQuery(window.location.pathname, userQuery, callback);
};

export const restoreQuery = (
  currentPath: string,
  savedQuery: Partial<DefaultQuery>,
  initialQuery: Partial<DefaultQuery>,
  pushOnEmptyQuery = false
): void => {
  if (!isRestoreQueryEnabled()) {
    return;
  }

  const userQuery = getUserSetQueryValues(savedQuery, initialQuery);
  const queryIsEmpty = isEmpty(userQuery);

  if (queryIsEmpty && pushOnEmptyQuery) {
    history.push(currentPath);
  } else if (!queryIsEmpty) {
    doRouteUpdateAndQuery(currentPath, userQuery, () => true);
  }
};
