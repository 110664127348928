import React, { ReactElement } from 'react';
import { Alert, Row } from 'elmo-elements';
import { useSelector } from 'react-redux';

import translations from '../translations';
import { RootState } from 'store/type';
import { t } from 'lib/translation';

const LoginError = (): ReactElement => {
  const authenticateState = useSelector(
    (state: RootState) => state.authenticate.loginSharedReport
  );

  if (
    authenticateState.error &&
    authenticateState.error.data.message === 'Invalid credentials.'
  ) {
    return (
      <Row className={'bottom-spacing'}>
        <Alert
          message={t(translations.errorMessages.invalidCredentials)}
          type={'danger'}
          isCloseable={false}
        />
      </Row>
    );
  }

  return <></>;
};

export default LoginError;
