import { transformQuestionCategories } from './questionCategory';
import { APIQuestion, Question } from 'type';

export const transformQuestion = ({
  id,
  categories,
  structure,
  global,
  is_deleted,
  created_date,
  updated_date,
}: APIQuestion): Question => {
  return {
    id,
    structure,
    global,
    categories: transformQuestionCategories(categories),
    isDeleted: is_deleted,
    createdDate: created_date,
    updatedDate: updated_date,
  };
};

export const transformQuestions = (questions: APIQuestion[]): Question[] => {
  return questions.map(transformQuestion);
};
