import { ActionCreator, createAction } from 'redux-act';

import { CreateThemePayload } from './type';

export const createTheme: ActionCreator<CreateThemePayload> =
  createAction('CREATE_THEME');

export const createThemeSuccess = createAction('CREATE_THEME_SUCCESS');

export const createThemeFailure: ActionCreator<any> = createAction(
  'CREATE_THEME_FAILURE'
);

export const clearCreateTheme = createAction('CLEAR_CREATE_THEME');
