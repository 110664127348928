import { Layout, PlaceholderDashboard, PlaceholderHeader } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const EditQuestionCategoryComponent = lazy(() =>
  withDelay(() => import('./EditQuestionCategoryComponent'))
);

type EditQuestionCategoryProps = {
  isGlobalCategory?: boolean;
};

const EditQuestionCategory = (
  props: EditQuestionCategoryProps
): ReactElement => {
  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={false}
              hasBackButton={true}
              hasButtons={false}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderDashboard cols={1} rows={1} />
          </Layout.Content>
        </>
      }
    >
      <EditQuestionCategoryComponent {...props} />
    </Suspense>
  );
};

export default EditQuestionCategory;
