import produce from 'immer';

import { Query } from 'lib/stateTemplates';
import { GetSurveysState } from './type';
import { DefaultQuery } from 'type';

export const getSurveysInitialState: GetSurveysState = {
  isLoading: false,
  isLoaded: false,
  isValid: false,
  count: 0,
  data: [],
  query: produce(Query, (draft: DefaultQuery) => {
    draft.sort = '-created-date';
  }),
};
