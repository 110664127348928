import produce from 'immer';

import {
  clearDeleteInvite,
  deleteInvite,
  deleteInviteFailure,
  deleteInviteSuccess,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function deleteInviteReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.deleteInvite.isDeleted = false;
    draft.deleteInvite.isDeleting = true;
  });
}

export function deleteInviteSuccessReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.deleteInvite.isDeleted = true;
    draft.deleteInvite.isDeleting = false;
  });
}

export function deleteInviteFailureReducer(
  state: InvitesState,
  error: any
): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.deleteInvite.isDeleted = true;
    draft.deleteInvite.isDeleting = false;
    draft.deleteInvite.error = error;
  });
}

export function clearDeleteInviteReducer(state: InvitesState): InvitesState {
  return produce(state, (draft: InvitesState) => {
    draft.deleteInvite = invitesInitialState.deleteInvite;
  });
}

export const deleteInviteReducers = {
  [`${deleteInvite}`]: deleteInviteReducer,
  [`${deleteInviteSuccess}`]: deleteInviteSuccessReducer,
  [`${deleteInviteFailure}`]: deleteInviteFailureReducer,
  [`${clearDeleteInvite}`]: clearDeleteInviteReducer,
};
