import map from 'lodash/map';

import {
  APISurveyNotification,
  SurveyNotification,
  SurveyNotificationHistory,
  APISurveyNotificationHistory,
} from 'type';
import { transformUser } from './user';

export const transformSurveyNotification = (
  surveyNotification: APISurveyNotification
): SurveyNotification => {
  return {
    id: surveyNotification.id,
    createdAt: surveyNotification.created_at,
    createdBy: transformUser(surveyNotification.created_by),
    fromEmail: surveyNotification.from_email,
    fromName: surveyNotification.from_name,
    isActive: surveyNotification.is_active,
    message: surveyNotification.message,
    subject: surveyNotification.subject,
    title: surveyNotification.title,
    toEmail: surveyNotification.to_email,
    emailQuestion: surveyNotification.email_question,
    type: surveyNotification.type,
    updatedAt: surveyNotification.updated_at,
    criteria: surveyNotification.criteria,
  };
};

export const transformSurveyNotifications = (
  surveyNotifications: APISurveyNotification[]
): SurveyNotification[] => {
  return map(surveyNotifications, transformSurveyNotification);
};

const transformSingleSurveyNotificationHistory = (
  surveyNotificationHistory: APISurveyNotificationHistory
): SurveyNotificationHistory => {
  return {
    id: surveyNotificationHistory.id,
    fromEmail: surveyNotificationHistory.from_email,
    fromName: surveyNotificationHistory.from_name,
    message: surveyNotificationHistory.message,
    sentDate: surveyNotificationHistory.sent_date,
    subject: surveyNotificationHistory.subject,
    title: surveyNotificationHistory.title,
    toEmail: surveyNotificationHistory.to_email,
  };
};

export const transformSurveyNotificationHistory = (
  surveyNotificationHistory: APISurveyNotificationHistory[]
): SurveyNotificationHistory[] => {
  return map(
    surveyNotificationHistory,
    transformSingleSurveyNotificationHistory
  );
};
