import { GetPreviewSurveyState } from './type';

export const getPreviewSurveyInitialState: GetPreviewSurveyState = {
  isLoaded: false,
  isLoading: false,
  isValid: false,
  survey: {
    anonymityLevel: 0,
    anonymous: false,
    description: '',
    id: 0,
    pagesCount: 0,
    questionsCount: 0,
    status: 'closed',
    structure: [],
    thankYou: '',
    theme: {
      buttonColour: '',
      footerBackgroundColor: '',
      footerDescription: '',
      footerFontColor: '',
      footerLogoUrl: '',
      footerLogoName: '',
      headingBannerBackgroundColour: '',
      headingBannerFontColour: '',
      logoUrl: '',
      logoName: '',
      textFontColour: '',
    },
    title: '',
  },
};
