import React from 'react';
import { getClass } from '../../_lib/helper';
import './NativeTableTh.scss';
function NativeTableTh(_a) {
    var children = _a.children, className = _a.className, colspan = _a.colspan, rowspan = _a.rowspan, width = _a.width, textAlign = _a.textAlign, scope = _a.scope;
    return (React.createElement("th", { className: getClass('elmo-nativetable-th', className), colSpan: colspan, rowSpan: rowspan, style: {
            width: width,
            textAlign: textAlign,
        }, scope: scope }, children));
}
export default NativeTableTh;
