import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';
import { DefaultQuery } from 'type';

const collectorRoute = '/survey/:surveyId/collector';

export const getCollectorsApi = (surveyId: number, data: DefaultQuery) => {
  return getRequest(
    getRoute(collectorRoute, { params: { surveyId: surveyId } }),
    { params: data }
  );
};
