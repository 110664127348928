import React from 'react';
import LayoutContext from './LayoutContext';
import { Heading } from '../../Typography';
import Button from '../../Button';
import { CloseIcon } from '../../Icon';
import './LayoutSidePanel.scss';
function LayoutSidePanel(_a) {
    var children = _a.children, title = _a.title;
    return (React.createElement(LayoutContext.Consumer, null, function (_a) {
        var isSidePanelOpen = _a.isSidePanelOpen, toggleSidePanelOpen = _a.toggleSidePanelOpen;
        return (isSidePanelOpen && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "elmo-layout__side-panel-bg", onClick: toggleSidePanelOpen }),
            React.createElement("div", { className: "elmo-layout__side-panel" },
                React.createElement("div", { className: "elmo-layout__side-panel__header" },
                    React.createElement(Heading, { htmlTag: "h2" }, title),
                    React.createElement(Button, { isText: true, icon: React.createElement(CloseIcon, null), onClick: toggleSidePanelOpen })),
                React.createElement("div", { className: "elmo-layout__side-panel__body" }, React.cloneElement(children, {
                    isSidePanelOpen: isSidePanelOpen,
                    toggleSidePanelOpen: toggleSidePanelOpen,
                }))))));
    }));
}
export default LayoutSidePanel;
