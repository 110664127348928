import produce from 'immer';

import {
  getFilterValues,
  getFilterValuesFailure,
  getFilterValuesSuccess,
  updateGetFilterValuesQuery,
} from './actions';
import { transformFilterValues } from '../../../transformers';
import { getFilterValuesInitialState } from './state';
import { GetFilterValuesResponse } from './type';
import { Query } from 'lib/query/type';
import { FilterState } from '../type';
import { ErrorResponse } from 'type';

export const getFilterValuesReducer = (state: FilterState): FilterState => {
  return produce(state, (draft: FilterState) => {
    draft.getFilterValues = { ...getFilterValuesInitialState };
    draft.getFilterValues.isLoading = true;
  });
};

export const getFilterValuesSuccessReducer = (
  state: FilterState,
  response: GetFilterValuesResponse
): FilterState => {
  return produce(state, (draft: FilterState) => {
    draft.getFilterValues.isLoading = false;
    draft.getFilterValues.isLoaded = true;
    draft.getFilterValues.isValid = true;
    draft.getFilterValues.count = response.data.count;
    draft.getFilterValues.data = transformFilterValues(
      response.data.filterValues
    );
  });
};

export const getFilterValuesFailureReducer = (
  state: FilterState,
  error: ErrorResponse
): FilterState => {
  return produce(state, (draft: FilterState) => {
    draft.getFilterValues.isLoading = false;
    draft.getFilterValues.isLoaded = true;
    draft.getFilterValues.error = error;
  });
};

export const updateGetFilterValuesQueryReducer = (
  state: FilterState,
  query: Query
): FilterState => {
  return produce(state, (draft: FilterState) => {
    draft.getFilterValues.query = query;
  });
};

export const getFilterValuesReducers = {
  [`${getFilterValues}`]: getFilterValuesReducer,
  [`${getFilterValuesSuccess}`]: getFilterValuesSuccessReducer,
  [`${getFilterValuesFailure}`]: getFilterValuesFailureReducer,
  [`${updateGetFilterValuesQuery}`]: updateGetFilterValuesQueryReducer,
};
