import map from 'lodash/map';

export const getAssociatedFiltersCount = (recipients: any): number => {
  let associatedFiltersCount = 0;

  map(recipients, (recipient: any) => {
    if (associatedFiltersCount < recipient.filterValues.length) {
      associatedFiltersCount = recipient.filterValues.length;
    }
  });

  return associatedFiltersCount;
};
