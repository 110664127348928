import produce from 'immer';

import {
  updateRespondentProgress,
  updateRespondentProgressFailure,
  updateRespondentProgressSuccess,
} from './actions';
import { updateRespondentProgressInitialState } from './state';
import { SurveyState } from '../type';

export function updateRespondentProgressReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateRespondentProgress = {
      ...updateRespondentProgressInitialState,
    };
    draft.updateRespondentProgress.isLoading = true;
  });
}

export function updateRespondentProgressSuccessReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateRespondentProgress.isLoaded = true;
    draft.updateRespondentProgress.isValid = true;
    draft.updateRespondentProgress.isLoading = false;
  });
}

export function updateRespondentProgressFailureReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateRespondentProgress.isLoaded = true;
    draft.updateRespondentProgress.isLoading = false;
  });
}

export const updateRespondentProgressReducers = {
  [`${updateRespondentProgress}`]: updateRespondentProgressReducer,
  [`${updateRespondentProgressSuccess}`]:
    updateRespondentProgressSuccessReducer,
  [`${updateRespondentProgressFailure}`]:
    updateRespondentProgressFailureReducer,
};
