import produce from 'immer';

import {
  clearGetCollectorFilterOptions,
  getCollectorFilterOptions,
  getCollectorFilterOptionsFailure,
  getCollectorFilterOptionsSuccess,
} from './actions';
import { getCollectorFilterOptionsInitialState } from './state';
import { OverviewReportState } from '../type';
import { ErrorResponse } from 'type';

export function getCollectorFilterOptionsReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getCollectorFilterOptions.isLoading = true;
    draft.getCollectorFilterOptions.isLoaded = false;
    draft.getCollectorFilterOptions.isValid = false;
    draft.getCollectorFilterOptions.data = [];
  });
}

export function getCollectorFilterOptionsSuccessReducer(
  state: OverviewReportState,
  data: any
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getCollectorFilterOptions.isLoading = false;
    draft.getCollectorFilterOptions.isLoaded = true;
    draft.getCollectorFilterOptions.isValid = true;
    draft.getCollectorFilterOptions.data = data.data;
  });
}

export function getCollectorFilterOptionsFailureReducer(
  state: OverviewReportState,
  error: ErrorResponse
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getCollectorFilterOptions.isLoading = false;
    draft.getCollectorFilterOptions.isLoaded = true;
    draft.getCollectorFilterOptions.error = error;
  });
}

export function clearGetCollectorFilterOptionsReducers(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getCollectorFilterOptions = getCollectorFilterOptionsInitialState;
  });
}

export const getCollectorFilterOptionsReducers = {
  [`${getCollectorFilterOptions}`]: getCollectorFilterOptionsReducer,
  [`${getCollectorFilterOptionsSuccess}`]:
    getCollectorFilterOptionsSuccessReducer,
  [`${getCollectorFilterOptionsFailure}`]:
    getCollectorFilterOptionsFailureReducer,
  [`${clearGetCollectorFilterOptions}`]: clearGetCollectorFilterOptionsReducers,
};
