import produce from 'immer';

import {
  getFilters,
  getFiltersFailure,
  getFiltersSuccess,
  updateGetFiltersQuery,
} from './actions';
import { transformFilters } from '../../../transformers/filter';
import { getFiltersInitialState } from './state';
import { GetFiltersResponse } from './type';
import { Query } from 'lib/query/type';
import { FilterState } from '../type';
import { ErrorResponse } from 'type';

export const getFiltersReducer = (state: FilterState): FilterState => {
  return produce(state, (draft: FilterState) => {
    draft.getFilters = { ...getFiltersInitialState };
    draft.getFilters.isLoading = true;
  });
};

export const getFiltersSuccessReducer = (
  state: FilterState,
  response: GetFiltersResponse
): FilterState => {
  return produce(state, (draft: FilterState) => {
    draft.getFilters.isLoading = false;
    draft.getFilters.isLoaded = true;
    draft.getFilters.isValid = true;
    draft.getFilters.count = response.data.count;
    draft.getFilters.data = transformFilters(response.data.filters);
  });
};

export const getFiltersFailureReducer = (
  state: FilterState,
  error: ErrorResponse
): FilterState => {
  return produce(state, (draft: FilterState) => {
    draft.getFilters.isLoading = false;
    draft.getFilters.isLoaded = true;
    draft.getFilters.error = error;
  });
};

export const updateGetFiltersQueryReducer = (
  state: FilterState,
  query: Query
): FilterState => {
  return produce(state, (draft: FilterState) => {
    draft.getFilters.query = query;
  });
};

export const getFiltersReducers = {
  [`${getFilters}`]: getFiltersReducer,
  [`${getFiltersSuccess}`]: getFiltersSuccessReducer,
  [`${getFiltersFailure}`]: getFiltersFailureReducer,
  [`${updateGetFiltersQuery}`]: updateGetFiltersQueryReducer,
};
