import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UploadBulkUrlCSVPayload, UploadBulkUrlCSVResponse } from './type';
import { ErrorResponse } from 'type';

export const uploadBulkUrlCSV: ActionCreator<UploadBulkUrlCSVPayload> =
  createAction('UPLOAD_BULK_URL_CSV');

export const uploadBulkUrlCSVSuccess: ActionCreator<UploadBulkUrlCSVResponse> =
  createAction('UPLOAD_BULK_URL_CSV_SUCCESS');

export const uploadBulkUrlCSVFailure: ActionCreator<ErrorResponse> =
  createAction('UPLOAD_BULK_URL_CSV_FAILURE');

export const clearUploadBulkUrlCSV: EmptyActionCreator = createAction(
  'CLEAR_UPLOAD_BULK_URL_CSV'
);
