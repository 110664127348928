var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import { getClass, noop } from '../../helper';
import './style.scss';
export var SwitchPropTypes = {
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    id: PropTypes.string,
    isOn: PropTypes.bool,
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
};
export var SwitchDefaultProps = {
    onClick: noop,
    onChange: noop,
    isOn: false,
};
function Switch(_a) {
    var id = _a.id, isOn = _a.isOn, onClick = _a.onClick, onChange = _a.onChange, ariaLabel = _a.ariaLabel, disabled = _a.disabled, title = _a.title, props = __rest(_a, ["id", "isOn", "onClick", "onChange", "ariaLabel", "disabled", "title"]);
    return (React.createElement("label", { "data-testid": "elmo-toggle-btn-label-" + (id || 'default'), "aria-label": ariaLabel || 'Toggle', title: title },
        React.createElement("input", { className: "elmo-toggle-btn__toggle-input", "data-testid": "elmo-toggle-btn-input-" + (id || 'default'), type: "checkbox", checked: isOn, onChange: onChange, onClick: onClick, disabled: disabled, id: id }),
        React.createElement("span", __assign({ className: getClass('elmo-toggle-btn', '', { on: isOn, disabled: disabled }) }, props))));
}
Switch.propTypes = SwitchPropTypes;
Switch.defaultProps = SwitchDefaultProps;
export default Switch;
