import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetFeaturedTemplatesResponse } from './type';
import { ErrorResponse } from 'type';

export const getFeaturedTemplates: EmptyActionCreator = createAction(
  'GET_FEATURED_TEMPLATES'
);

export const getFeaturedTemplatesSuccess: ActionCreator<GetFeaturedTemplatesResponse> =
  createAction('GET_FEATURED_TEMPLATES_SUCCESS');

export const getFeaturedTemplatesFailure: ActionCreator<ErrorResponse> =
  createAction('GET_FEATURED_TEMPLATES_FAILURE');
