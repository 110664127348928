import { PlaceholderHeader } from 'elmo-elements';
import React, { lazy, useEffect } from 'react';

import {
  getSurveyIdFromQueryString,
  getReportIdFromQueryString,
} from 'lib/query';
import { isExistingSavedReportsEnabled } from 'lib/helpers/featureFlag';
import Suspense from 'sharedComponents/Suspense';
import { getRoute, withDelay } from 'lib/util';
import history from 'lib/history';

const Container = lazy(() => withDelay(() => import('./container')));

/**
 * Saved reports in TMS redirect to the following URL:
 * xyz.elmosurvey.com/reports/survey-overview?surveyId=123&reportId=123
 *
 * This method redirects the user to the correct report route.
 */
function checkReportRedirect() {
  const surveyId = getSurveyIdFromQueryString();
  const reportId = getReportIdFromQueryString();

  if (!isNaN(surveyId) && !isNaN(reportId)) {
    if (isExistingSavedReportsEnabled()) {
      history.push(
        getRoute(
          '/surveys/:surveyId/reports/question-summaries/report/:reportId',
          {
            params: {
              surveyId: surveyId,
              reportId: reportId,
            },
          }
        )
      );
    } else {
      window.location.href = `/reports/survey-overview/${surveyId}/report/${reportId}`;
    }
  }
}

function OverviewListPage() {
  useEffect(() => {
    checkReportRedirect();
  }, []);

  return (
    <Suspense
      fallback={
        <PlaceholderHeader
          hasSubHeading={false}
          hasTabs={true}
          hasBackButton={false}
          hasButtons={false}
        />
      }
    >
      <Container />
    </Suspense>
  );
}

export default OverviewListPage;
