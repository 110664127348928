import { createAction } from 'redux-act';

export const getAvailableUrls: any = createAction(
  'MY_SURVEYS_GET_AVAILABLE_URLS'
);

export const getAvailableUrlsSuccess: any = createAction(
  'MY_SURVEYS_GET_AVAILABLE_URLS_SUCCESS'
);

export const getAvailableUrlsFailure: any = createAction(
  'MY_SURVEYS_GET_AVAILABLE_URLS_FAILURE'
);

export const getAvailableUrl: any = createAction(
  'MY_SURVEYS_GET_AVAILABLE_URL'
);

export const getAvailableUrlSuccess: any = createAction(
  'MY_SURVEYS_GET_AVAILABLE_URL_SUCCESS'
);

export const getAvailableUrlFailure: any = createAction(
  'MY_SURVEYS_GET_AVAILABLE_URL_FAILURE'
);

export const getAvailableUrlsQuery: any = createAction(
  'MY_SURVEYS_GET_AVAILABLE_URLS_QUERY'
);

export const getAvailableUrlsQuerySuccess: any = createAction(
  'MY_SURVEYS_GET_AVAILABLE_URLS_QUERY_SUCCESS'
);

export const getAvailableUrlsQueryFailure: any = createAction(
  'MY_SURVEYS_GET_AVAILABLE_URLS_QUERY_FAILURE'
);
