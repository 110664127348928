import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetQuestionsRequest, GetQuestionsResponse } from './type';
import { ErrorResponse, QuestionsQuery } from 'type';

export const getQuestions: ActionCreator<GetQuestionsRequest> =
  createAction('GET_QUESTIONS');

export const getQuestionsFromQuery: EmptyActionCreator = createAction(
  'GET_QUESTIONS_FROM_QUERY'
);

export const getQuestionsSuccess: ActionCreator<GetQuestionsResponse> =
  createAction('GET_QUESTIONS_SUCCESS');

export const getQuestionsFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_QUESTIONS_FAILURE'
);

export const updateGetQuestionsQuery: ActionCreator<QuestionsQuery> =
  createAction('UPDATE_GET_QUESTIONS_QUERY');

export const clearGetQuestions: EmptyActionCreator = createAction(
  'CLEAR_GET_QUESTIONS'
);
