export function getMixpanelEnvironment(): string {
  return window.config.mixPanelEnv;
}

export function isPendoEnabled(): boolean {
  return window.config.isPendoEnabled;
}

export function pendoApiKey(): string {
  return window.config.pendoApiKey;
}
