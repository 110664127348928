import { CreateRespondentProgressPayload } from './type';
import { getRoute } from '../../../../lib/util';
import { putRequest } from 'lib/api';

const route = '/anon/respondent-progress/:hash';

export const createRespondentProgressApi = (
  payload: CreateRespondentProgressPayload
) => {
  return putRequest(
    getRoute(route, { params: { hash: payload.hash } }),
    payload
  );
};
