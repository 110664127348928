import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { ErrorResponse } from 'type';

export const getLatestCreatedBulkLink: ActionCreator<number> = createAction(
  'GET_LATEST_CREATED_BULK_LINK'
);

export const getLatestCreatedBulkLinkSuccess: ActionCreator<any> = createAction(
  'GET_LATEST_CREATED_BULK_LINK_SUCCESS'
);

export const getLatestCreatedBulkLinkFailure: ActionCreator<ErrorResponse> =
  createAction('GET_LATEST_CREATED_BULK_LINK_FAILURE');

export const clearGetLatestCreatedBulkLink: EmptyActionCreator = createAction(
  'CLEAR_GET_LATEST_CREATED_BULK_LINK'
);
