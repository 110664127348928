import {
  Button,
  Card,
  FileUpload,
  Paragraph,
  CancelIcon,
  Loader,
  FormItem,
} from 'elmo-elements';
import { UploadedFile } from 'elmo-elements/FileUpload';
import React, { ReactElement, useState } from 'react';

import { t } from '../../../lib/translation';
import translations from './translations';

interface UploadCardProps {
  title: string;
  description: string;
  dropAreaText?: string;
  acceptedFiles?: string[];
  onDropAccepted: (files: UploadedFile) => void;
  onDropRejected: (files: UploadedFile) => void;
  handleFileDelete: () => void;
  isUploading: boolean;
  uploadedFileName: string | null;
  fileMaxSize: number;
}

const UploadCard = ({
  title,
  description,
  dropAreaText = 'Choose a csv file or drag it here',
  acceptedFiles = ['.csv'],
  onDropAccepted,
  onDropRejected,
  handleFileDelete,
  isUploading,
  uploadedFileName,
  fileMaxSize,
}: UploadCardProps): ReactElement => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileRejected = (uploadedFile: UploadedFile) => {
    const fileSizeInMb = uploadedFile.size / 1e6;
    if (fileMaxSize && fileSizeInMb > fileMaxSize) {
      setErrorMessage(t(translations.fileSizeExceedsLimit));
    }

    return onDropRejected(uploadedFile);
  };

  const handleFileAccepted = (uploadedFile: UploadedFile) => {
    !!errorMessage && setErrorMessage('');
    return onDropAccepted(uploadedFile);
  };

  return (
    <Card className={'import-csv-template-card'} heading={title}>
      <Paragraph>{description}</Paragraph>
      <br />
      {uploadedFileName && !isUploading && (
        <div className={`elmo-uploaded-file`}>
          <span>{uploadedFileName}</span>
          <Button
            icon={<CancelIcon />}
            onClick={handleFileDelete}
            id={'remove-file'}
          />
        </div>
      )}

      {isUploading && <Loader type={'spinner'} />}

      {!uploadedFileName && !isUploading && (
        <FormItem
          message={errorMessage}
          status={!!errorMessage ? 'error' : 'default'}
        >
          <FileUpload
            name={'import-csv-template-file-upload'}
            acceptedFiles={acceptedFiles}
            dropareaText={dropAreaText}
            displayFiles={false}
            isMulti={false}
            onDropAccepted={(files: UploadedFile[]) =>
              handleFileAccepted(files[0])
            }
            onDropRejected={(files: UploadedFile[]) =>
              handleFileRejected(files[0])
            }
            fileMaxSize={fileMaxSize}
            key={1}
          />
        </FormItem>
      )}
    </Card>
  );
};

export default UploadCard;
