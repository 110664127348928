var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import TimelineItem from './component/TimelineItem';
import { getClass } from '../_lib/helper';
import './Timeline.scss';
var Timeline = /** @class */ (function (_super) {
    __extends(Timeline, _super);
    function Timeline() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Timeline.prototype.render = function () {
        var _a;
        var _b = this.props, title = _b.title, children = _b.children, mode = _b.mode, id = _b.id;
        var modifiers = (_a = {}, _a["" + mode] = mode, _a);
        return (React.createElement("div", { id: id, "data-testid": "elmo-timeline-" + (id || 'default'), className: getClass('elmo-timeline', {}, modifiers) },
            React.createElement("div", null, title),
            React.createElement("ul", null, children)));
    };
    Timeline.Item = TimelineItem;
    return Timeline;
}(Component));
export default Timeline;
