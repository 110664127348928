import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { UpdateSurveyNotificationPayload } from './type';
import { ErrorResponse } from 'type';

export const updateSurveyNotification: ActionCreator<UpdateSurveyNotificationPayload> =
  createAction('UPDATE_SURVEY_NOTIFICATION');

export const updateSurveyNotificationSuccess: EmptyActionCreator = createAction(
  'UPDATE_SURVEY_NOTIFICATION_SUCCESS'
);

export const updateSurveyNotificationFailure: ActionCreator<ErrorResponse> =
  createAction('UPDATE_SURVEY_NOTIFICATION_FAILURE');

export const clearUpdateSurveyNotification: EmptyActionCreator = createAction(
  'CLEAR_UPDATE_SURVEY_NOTIFICATION'
);
