import { getIndividualResponsesInitialState } from './getIndividualResponses/state';
import { restoreResponseInitialState } from './restoreResponse/state';
import { deleteResponseInitialState } from './deleteResponse/state';
import { getResponseInitialState } from './getResponse/state';
import { ResponseState } from './type';

export const responseInitialState: ResponseState = {
  deleteResponse: deleteResponseInitialState,
  restoreResponse: restoreResponseInitialState,
  getIndividualResponses: getIndividualResponsesInitialState,
  getResponse: getResponseInitialState,
};
