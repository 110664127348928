import { Button, Card, Paragraph, SaveAltIcon } from 'elmo-elements';
import React, { ReactElement } from 'react';

interface DownloadCardProps {
  title: string;
  description: string;
  buttonText?: string;
  buttonAction: () => void;
}

const DownloadCard = ({
  title,
  description,
  buttonAction,
  buttonText = 'Download',
}: DownloadCardProps): ReactElement => {
  return (
    <Card
      id={'import-csv-template-download-template'}
      className={'import-csv-template-card'}
      heading={title}
    >
      <Paragraph>{description}</Paragraph>
      <br />
      <Button
        id="import-csv-template-download-template-button"
        onClick={buttonAction}
        icon={<SaveAltIcon />}
        type={'primary'}
      >
        {buttonText}
      </Button>
    </Card>
  );
};

export default DownloadCard;
