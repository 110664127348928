import { postRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/invite-messages/:inviteMessageId/send-invites';

export const sendInviteMessageApi = (inviteMessageId: number) => {
  return postRequest(
    getRoute(url, {
      params: { inviteMessageId: inviteMessageId },
    }),
    null
  );
};
