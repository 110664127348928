import { RouteObject } from 'react-router-dom';
import React from 'react';

import CreateQuestionCategory from './pages/CreateQuestionCategory';
import QuestionCategoryList from './pages/QuestionCategoryList';
import EditQuestionCategory from './pages/EditQuestionCategory';

export const QUESTION_CATEGORIES_PATH = '/question-categories';

const routes: RouteObject = {
  path: QUESTION_CATEGORIES_PATH,
  children: [
    {
      path: `${QUESTION_CATEGORIES_PATH}/global/:categoryId/edit`,
      element: <EditQuestionCategory isGlobalCategory />,
    },
    {
      path: `${QUESTION_CATEGORIES_PATH}/:categoryId/edit`,
      element: <EditQuestionCategory />,
    },
    {
      path: `${QUESTION_CATEGORIES_PATH}/create`,
      element: <CreateQuestionCategory />,
    },
    {
      path: QUESTION_CATEGORIES_PATH,
      element: <QuestionCategoryList />,
    },
  ],
};

export default routes;
