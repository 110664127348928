import { createReducer } from 'redux-act';

import { getDefaultInviteSenderReducers } from './getDefaultInviteSender/reducers';
import { filterReducers } from './filter/reducers';
import { sharedStateInitialState } from './state';

export default createReducer(
  {
    ...getDefaultInviteSenderReducers,
    ...filterReducers,
  },
  sharedStateInitialState
);
