import { DoneAllIcon as SelectAllIcon } from '../../Icon';
import React from 'react';
import './SelectAllButton.scss';
import { getClass } from '../../_lib/helper';
import Button from '../../Button';
function SelectAllButton(_a) {
    var handleSelectAllClick = _a.handleSelectAllClick, isActive = _a.isActive;
    return (React.createElement(Button, { className: getClass('elmo-select-list__select-all-btn', '', {
            active: isActive,
        }), isText: true, onClick: handleSelectAllClick, icon: React.createElement(SelectAllIcon, null), ariaLabel: "Select all" }));
}
export default SelectAllButton;
