import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getAnonSurveyThemeFailure,
  getAnonSurveyThemeSuccess,
} from './actions';
import { getAnonSurveyThemeApi } from './api';
import { SagaAction } from 'type';

export const getAnonSurveyThemeSaga = function* (
  action: SagaAction<string>
): SagaIterator {
  try {
    const responseData = yield call(getAnonSurveyThemeApi(action.payload));

    yield put(getAnonSurveyThemeSuccess(responseData));
  } catch (error) {
    yield put(getAnonSurveyThemeFailure(error));
  }
};
