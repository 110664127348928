import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  updateRespondentProgressFailure,
  updateRespondentProgressSuccess,
} from './actions';
import { UpdateRespondentProgressPayload } from './type';
import { updateRespondentProgressApi } from './api';
import { SagaAction } from 'type';

export const updateRespondentProgressSaga = function* (
  action: SagaAction<UpdateRespondentProgressPayload>
): SagaIterator {
  try {
    yield call(updateRespondentProgressApi(action.payload));

    yield put(updateRespondentProgressSuccess());
  } catch (error) {
    yield put(updateRespondentProgressFailure());
  }
};
