import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getBulkUrlsCollector,
  getBulkUrlsCollectorFailure,
  getBulkUrlsCollectorSuccess,
  uploadBulkUrlsCsv,
  uploadBulkUrlsCsvSuccess,
  uploadBulkUrlsCsvFailure,
  getAvailableUrls,
  getAvailableUrlsSuccess,
  getAvailableUrlsFailure,
  getAvailableUrlsQuery,
  getAvailableUrlsQuerySuccess,
  getAvailableUrlsQueryFailure,
  generateBulkUrls,
  generateBulkUrlsSuccess,
  generateBulkUrlsFailure,
  getLatestGenerateBulkUrlFailure,
  getLatestGenerateBulkUrl,
  getLatestGenerateBulkUrlSuccess,
  getAvailableUrlSuccess,
  getAvailableUrlFailure,
  getAvailableUrl,
} from './actions';
import { Query } from '../../../lib/stateTemplates';
import { getQuery } from '../../../lib/query';
import MySurveysApi from './api';

export const getBulkUrlsCollectorSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(
      MySurveysApi.getBulkUrlsCollector(action.payload.bulkCollectorId)
    );

    if (
      data.data === null ||
      data.data === undefined ||
      parseInt(data.data.survey.id, 10) !==
        parseInt(action.payload.surveyId, 10)
    ) {
      window.location.href =
        '/responses/' + action.payload.surveyId + '/survey-summary';
    }

    yield put(getBulkUrlsCollectorSuccess(data));
  } catch (error) {
    yield put(getBulkUrlsCollectorFailure(error));
  }
};

export const getAvailableUrlsSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(MySurveysApi.getAvailableUrls(action.payload));
    yield put(getAvailableUrlsSuccess(data));
  } catch (error) {
    yield put(getAvailableUrlsFailure(error));
  }
};

export const getAvailableUrlSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(MySurveysApi.getAvailableUrl(action.payload));
    yield put(getAvailableUrlSuccess(data));
  } catch (error) {
    yield put(getAvailableUrlFailure(error));
  }
};

export const getAvailableUrlsQuerySaga = function* (action: any): SagaIterator {
  try {
    const data = getQuery(Query);
    yield put(getAvailableUrlsQuerySuccess(data));

    const payload = { bulkCollectorId: action.payload, searchFilters: data };

    yield put(getAvailableUrls(payload));
  } catch (error) {
    yield put(getAvailableUrlsQueryFailure(error));
  }
};

export const uploadBulkUrlsCsvSaga = function* (action: any): SagaIterator {
  try {
    let formData = new FormData();
    formData.append('file', action.payload.file);

    const data = yield call(
      MySurveysApi.uploadDocumentRequest(action.payload.id, formData)
    );

    if (data === null || data === undefined) {
      yield put(uploadBulkUrlsCsvFailure(data));
    }
    yield put(uploadBulkUrlsCsvSuccess(data));
  } catch (error) {
    yield put(uploadBulkUrlsCsvFailure(error));
  }
};

export const generateBulkUrlsSaga = function* (action: any): SagaIterator {
  try {
    let formData = new FormData();
    formData.append('fileName', action.payload.fileName);
    formData.append('originalFileName', action.payload.originalFileName);

    const data = yield call(
      MySurveysApi.generateBulkUrlsRequest(action.payload.id, formData)
    );

    if (data.error && data.error === 'timeout') {
      yield put(
        getLatestGenerateBulkUrl({ bulkCollectorId: action.payload.id })
      );

      return;
    }

    if (data === null || data === undefined) {
      yield put(generateBulkUrlsFailure(data));
    }
    yield put(generateBulkUrlsSuccess(data));
  } catch (error) {
    yield put(generateBulkUrlsFailure(error));
  }
};

export const getLatestGenerateBulkUrlSaga = function* (
  action: any
): SagaIterator {
  try {
    const searchFilters = {
      page: 1,
      limit: 1,
    };

    const data = yield call(
      MySurveysApi.getAvailableUrls({ ...action.payload, searchFilters })
    );

    yield put(getLatestGenerateBulkUrlSuccess(data));
  } catch (error) {
    yield put(getLatestGenerateBulkUrlFailure(error));
  }
};

export default function* root() {
  yield takeEvery(getBulkUrlsCollector, getBulkUrlsCollectorSaga);
  yield takeEvery(getAvailableUrls, getAvailableUrlsSaga);
  yield takeEvery(getAvailableUrlsQuery, getAvailableUrlsQuerySaga);
  yield takeEvery(uploadBulkUrlsCsv, uploadBulkUrlsCsvSaga);
  yield takeEvery(generateBulkUrls, generateBulkUrlsSaga);
  yield takeEvery(getAvailableUrl, getAvailableUrlSaga);
  yield takeEvery(getLatestGenerateBulkUrl, getLatestGenerateBulkUrlSaga);
}
