import { put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { SITE_THEME_EXPIRY_TIME, SITE_THEME_KEY } from 'constants/index';
import { isSharedReport } from 'lib/helpers/sharedReports';
import { getSessionStorage, setSessionStorage } from 'lib';
import { getSiteThemeSuccess } from './actions';
import { getSiteThemeApi } from './api';

export const getSiteThemeSaga = function* (): SagaIterator {
  try {
    const currentTime = Date.now();
    const themeData = getSessionStorage(SITE_THEME_KEY, 'object');

    let response;
    if (
      themeData &&
      currentTime - themeData.timestamp < SITE_THEME_EXPIRY_TIME
    ) {
      response = themeData.data;
    } else if (isSharedReport()) {
      response = {};
    } else {
      response = yield call(getSiteThemeApi());

      setSessionStorage(
        SITE_THEME_KEY,
        {
          timestamp: currentTime,
          data: response,
        },
        'object'
      );
    }

    yield put(getSiteThemeSuccess(response));
  } catch (error) {
    const response = {};
    yield put(getSiteThemeSuccess(response));
  }
};
