var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
import './NarrowContentStyle.scss';
function NarrowContentStyle(props) {
    return (React.createElement(LoaderHolder, __assign({ viewBoxHeight: 400, className: "elmo-loader-narrow-content" }, props),
        React.createElement("rect", { x: "0", y: "10", rx: "4", ry: "4", width: "300", height: "25" }),
        React.createElement("rect", { x: "0", y: "60", rx: "3", ry: "3", width: "385", height: "25" }),
        React.createElement("rect", { x: "0", y: "140", rx: "3", ry: "3", width: "350", height: "25" }),
        React.createElement("rect", { x: "0", y: "190", rx: "3", ry: "3", width: "350", height: "25" }),
        React.createElement("rect", { x: "0", y: "270", rx: "4", ry: "4", width: "300", height: "25" }),
        React.createElement("rect", { x: "0", y: "320", rx: "3", ry: "3", width: "385", height: "25" })));
}
export default NarrowContentStyle;
