import { GetSurveyTemplateState } from './type';
import { Query } from 'lib/stateTemplates';
import { SurveyTemplate } from 'type';

export const getSurveyTemplateInitialState: GetSurveyTemplateState = {
  data: {} as SurveyTemplate,
  isLoaded: false,
  isLoading: false,
  isValid: false,
  query: Query,
};
