import { Layout, PlaceholderDashboard } from 'elmo-elements';
import React, { ReactElement } from 'react';

import { emailInvitesViewProps } from './type';
import Body from './components/body';
import Header from '../header';
import './style.scss';

/**
 * View
 */
const View = ({
  messagesState,
  surveyId,
  collectorId,
  showModal,
  currentModalRender,
}: emailInvitesViewProps): ReactElement => {
  return (
    <>
      <Layout.Header>
        <Header surveyId={surveyId} collectorId={collectorId} />
      </Layout.Header>

      <Layout.Content>
        <div id={'email-invites-messages-body-wrapper'}>
          {messagesState.isLoading ? (
            <PlaceholderDashboard cols={1} rows={1} />
          ) : (
            <>
              <Body showModal={showModal} />
              {currentModalRender}
            </>
          )}
        </div>
      </Layout.Content>
    </>
  );
};

export default View;
