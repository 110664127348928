import map from 'lodash/map';

import { APIResponse, Response } from 'type/responses';

export const transformResponse = (response: APIResponse): Response => {
  return {
    answers: response.answers,
    collector: response.collector,
    filterValues: response.filter_values,
    hash: response.hash,
    id: response.id,
    ipAddress: response.ip_address,
    respondentData: response.respondent_data,
    timestamp: response.timestamp,
    isDeleted: response.is_deleted,
  };
};

export const transformResponses = (responses: APIResponse[]): Response[] => {
  return map(responses, transformResponse);
};
