import { createReducer } from 'redux-act';

import { getDefaultSenderReducers } from './getDefaultSender/reducers';
import { initializeAppReducers } from './initializeApp/reducers';
import { notificationReducers } from './notification/reducers';
import { getSiteThemeReducers } from './getSiteTheme/reducers';
import { getMenuReducers } from './getMenu/reducers';
import { appInitialState } from './state';

export default createReducer(
  {
    ...getMenuReducers,
    ...initializeAppReducers,
    ...notificationReducers,
    ...getDefaultSenderReducers,
    ...getSiteThemeReducers,
  },
  appInitialState
);
