import produce from 'immer';

import {
  getSurveyNotification,
  getSurveyNotificationFailure,
  getSurveyNotificationSuccess,
  clearGetSurveyNotification,
} from './actions';
import { transformSurveyNotification } from '../../../transformers';
import { getSurveyNotificationInitialState } from './state';
import { GetSurveyNotificationResponse } from './type';
import { SurveyNotificationState } from '../type';
import { ErrorResponse } from 'type';

export const getSurveyNotificationReducer = (
  state: SurveyNotificationState
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotification = { ...getSurveyNotificationInitialState };
    draft.getSurveyNotification.isLoading = true;
  });
};

export const getSurveyNotificationSuccessReducer = (
  state: SurveyNotificationState,
  data: GetSurveyNotificationResponse
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotification.isLoading = false;
    draft.getSurveyNotification.isLoaded = true;
    draft.getSurveyNotification.isValid = true;
    draft.getSurveyNotification.data = transformSurveyNotification(data.data);
  });
};

export const getSurveyNotificationFailureReducer = (
  state: SurveyNotificationState,
  error: ErrorResponse
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotification.isLoading = false;
    draft.getSurveyNotification.isLoaded = true;
    draft.getSurveyNotification.error = error;
  });
};

export const clearGetSurveyNotificationReducers = (
  state: SurveyNotificationState
): SurveyNotificationState => {
  return produce(state, (draft: SurveyNotificationState) => {
    draft.getSurveyNotification = getSurveyNotificationInitialState;
  });
};

export const getSurveyNotificationReducers = {
  [`${getSurveyNotification}`]: getSurveyNotificationReducer,
  [`${getSurveyNotificationSuccess}`]: getSurveyNotificationSuccessReducer,
  [`${getSurveyNotificationFailure}`]: getSurveyNotificationFailureReducer,
  [`${clearGetSurveyNotification}`]: clearGetSurveyNotificationReducers,
};
