import React from 'react';
import ActionCardHeaderOptions from './ActionCardHeaderOptions';
import ActionCardHeaderBadge from './ActionCardHeaderBadge';
import ActionCardHeaderPopoverBadge from './ActionCardHeaderPopoverBadge';
function ActionCardHeader(_a) {
    var icon = _a.icon, title = _a.title, titleAddOn = _a.titleAddOn, badge = _a.badge, options = _a.options, popoverBadge = _a.popoverBadge;
    return (React.createElement("div", { className: "elmo-actioncard__header" },
        icon && React.createElement("div", { className: "elmo-actioncard__header-icon" }, icon),
        React.createElement("div", { className: "elmo-actioncard__header-content" },
            (title || titleAddOn) && (React.createElement("div", { className: "elmo-actioncard__header-content-inner" },
                title && (React.createElement("div", { className: "elmo-actioncard__header-title" }, title)),
                titleAddOn && (React.createElement("div", { className: "elmo-actioncard__header-title-add-on" }, titleAddOn)))),
            badge && React.createElement(ActionCardHeaderBadge, { badge: badge }),
            popoverBadge && (React.createElement(ActionCardHeaderPopoverBadge, { popoverBadge: popoverBadge }))),
        options && React.createElement(ActionCardHeaderOptions, { options: options })));
}
export default ActionCardHeader;
