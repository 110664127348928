import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getInviteRecipientsFailure,
  getInviteRecipientsSuccess,
  updateGetInviteRecipientsQuery,
} from './actions';
import { getInviteRecipientsApi } from './api';
import { Query } from 'lib/stateTemplates';
import { getQuery } from 'lib/query';

export const getInviteRecipientsSaga = function* (action: any): SagaIterator {
  try {
    const currentQuery = getQuery(Query);
    yield put(updateGetInviteRecipientsQuery(currentQuery));

    const data = yield call(
      getInviteRecipientsApi(action.payload, currentQuery)
    );

    yield put(getInviteRecipientsSuccess(data));
  } catch (error) {
    yield put(getInviteRecipientsFailure(error));
  }
};
