import { SiteTheme } from 'type';

export const convertTheme = (data: SiteTheme) => {
  return {
    font: data.siteFont,
    link: {
      color: data.linkColor,
    },
    button: {
      color: data.buttonFontColor,
      background: data.buttonColor,
      hover: data.buttonHoverColor,
      active: data.buttonHoverColor,
      font: data.siteFont,
    },
  };
};
