import produce from 'immer';

import {
  updateSurveyTemplate,
  updateSurveyTemplateFailure,
  updateSurveyTemplateSuccess,
  clearUpdateSurveyTemplate,
} from './actions';
import { updateSurveyTemplateInitialState } from './state';
import { SurveyTemplateState } from '../type';
import { ErrorResponse } from 'type';

export const updateSurveyTemplateReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.updateSurveyTemplate = { ...updateSurveyTemplateInitialState };
    draft.updateSurveyTemplate.isLoading = true;
  });
};

export const updateSurveyTemplateSuccessReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.updateSurveyTemplate.isLoading = false;
    draft.updateSurveyTemplate.isLoaded = true;
    draft.updateSurveyTemplate.isValid = true;
  });
};

export const updateSurveyTemplateFailureReducer = (
  state: SurveyTemplateState,
  error: ErrorResponse
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.updateSurveyTemplate.isLoading = false;
    draft.updateSurveyTemplate.isLoaded = true;
    draft.updateSurveyTemplate.error = error;
  });
};

export const clearUpdateSurveyTemplateReducer = (
  state: SurveyTemplateState
): SurveyTemplateState => {
  return produce(state, (draft: SurveyTemplateState) => {
    draft.updateSurveyTemplate = updateSurveyTemplateInitialState;
  });
};

export const updateSurveyTemplateReducers = {
  [`${updateSurveyTemplate}`]: updateSurveyTemplateReducer,
  [`${updateSurveyTemplateSuccess}`]: updateSurveyTemplateSuccessReducer,
  [`${updateSurveyTemplateFailure}`]: updateSurveyTemplateFailureReducer,
  [`${clearUpdateSurveyTemplate}`]: clearUpdateSurveyTemplateReducer,
};
