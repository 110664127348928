import produce from 'immer';

import { UpdateOverviewReportPaginationState } from './type';
import { updateOverviewReportPagination } from './actions';
import { OverviewReportState } from '../type';

export function updateOverviewReportPaginationReducer(
  state: OverviewReportState,
  data: UpdateOverviewReportPaginationState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.updateOverviewReportPagination.currentPage = data.currentPage;
    draft.updateOverviewReportPagination.pageSize = data.pageSize;
  });
}

export const updateOverviewReportPaginationReducers = {
  [`${updateOverviewReportPagination}`]: updateOverviewReportPaginationReducer,
};
