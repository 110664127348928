import React from 'react';
import Button from '../../Button';
import { CancelIcon as ClearIcon } from '../../Icon';
function SearchClearButton(_a) {
    var value = _a.value, onClear = _a.onClear;
    if (value) {
        return (React.createElement(Button, { className: "elmo-search__btn-clear", isText: true, icon: React.createElement(ClearIcon, { title: "Clear" }), onClick: onClear }));
    }
    return null;
}
export default SearchClearButton;
