import { createReducer } from 'redux-act';

import { createRespondentProgressReducers } from './createRespondentProgress/reducers';
import { updateRespondentProgressReducers } from './updateRespondentProgress/reducers';
import { getSurveyCollectorUsageReducers } from './getSurveyCollectorUsage/reducers';
import { updateSurveyAccessListReducers } from './updateSurveyAccessList/reducers';
import { saveRespondentProgressReducers } from './saveRespondentProgress/reducers';
import { getRespondentProgressReducers } from './getRespondentProgress/reducers';
import { getSurveyAccessListReducers } from './getSurveyAccessList/reducers';
import { getPreviewSurveyReducers } from './getPreviewSurvey/reducers';
import { createRespondentReducers } from './createRespondent/reducers';
import { getSurveyStatsReducers } from './getSurveyStats/reducers';
import { deleteSurveyReducers } from './deleteSurvey/reducers';
import { updateSurveyReducers } from './updateSurvey/reducers';
import { createSurveyReducers } from './createSurvey/reducers';
import { cloneSurveyReducers } from './cloneSurvey/reducers';
import { getSurveysReducers } from './getSurveys/reducers';
import { getSurveyReducers } from './getSurvey/reducers';
import { runSurveyReducers } from './runSurvey/reducers';
import { surveyInitialState } from './state';

export default createReducer(
  {
    ...getSurveyReducers,
    ...getSurveysReducers,
    ...cloneSurveyReducers,
    ...getSurveyCollectorUsageReducers,
    ...deleteSurveyReducers,
    ...updateSurveyReducers,
    ...getPreviewSurveyReducers,
    ...getSurveyAccessListReducers,
    ...updateSurveyAccessListReducers,
    ...runSurveyReducers,
    ...createRespondentReducers,
    ...createRespondentProgressReducers,
    ...updateRespondentProgressReducers,
    ...getRespondentProgressReducers,
    ...saveRespondentProgressReducers,
    ...getSurveyStatsReducers,
    ...createSurveyReducers,
  },
  surveyInitialState
);
