import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetRespondentProgressResponse } from './type';

export const getRespondentProgress: ActionCreator<string> = createAction(
  'GET_RESPONDENT_PROGRESS'
);

export const getRespondentProgressSuccess: ActionCreator<GetRespondentProgressResponse> =
  createAction('GET_RESPONDENT_PROGRESS_SUCCESS');

export const getRespondentProgressFailure: EmptyActionCreator = createAction(
  'GET_RESPONDENT_PROGRESS_FAILURE'
);

export const clearGetRespondentProgress: EmptyActionCreator = createAction(
  'CLEAR_GET_RESPONDENT_PROGRESS'
);
