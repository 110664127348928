var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
import './NarrowBulletListStyle.scss';
function NarrowBulletListStyle(props) {
    return (React.createElement(LoaderHolder, __assign({ viewBoxHeight: 300, className: "elmo-loader-narrow-bullet" }, props),
        React.createElement("circle", { cx: "14", cy: "25", r: "12" }),
        React.createElement("rect", { x: "40", y: "15", rx: "5", ry: "5", width: "180", height: "20" }),
        React.createElement("circle", { cx: "14", cy: "70", r: "12" }),
        React.createElement("rect", { x: "40", y: "60", rx: "5", ry: "5", width: "180", height: "20" }),
        React.createElement("circle", { cx: "14", cy: "115", r: "12" }),
        React.createElement("rect", { x: "40", y: "105", rx: "5", ry: "5", width: "180", height: "20" }),
        React.createElement("circle", { cx: "14", cy: "160", r: "12" }),
        React.createElement("rect", { x: "40", y: "150", rx: "5", ry: "5", width: "180", height: "20" })));
}
export default NarrowBulletListStyle;
