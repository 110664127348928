import { Layout, PlaceholderHeader, PlaceholderListTable } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const InvitesListContainer = lazy(() =>
  withDelay(() => import('./InvitesListContainer'))
);

const InvitesList = (): ReactElement => {
  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={true}
              hasTabs={false}
              hasBackButton={true}
              hasButtons={true}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderListTable rows={5} cols={5} hasAvatar={false} />
          </Layout.Content>
        </>
      }
    >
      <InvitesListContainer />
    </Suspense>
  );
};

export default InvitesList;
