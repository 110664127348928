import { createAction } from 'redux-act';

export const createInviteMessage: any = createAction('CREATE_INVITE_MESSAGE');

export const createInviteMessageSuccess: any = createAction(
  'CREATE_INVITE_MESSAGE_SUCCESS'
);

export const createInviteMessageFailure: any = createAction(
  'CREATE_INVITE_MESSAGE_FAILURE'
);

export const clearCreateInviteMessage: any = createAction(
  'CLEAR_CREATE_INVITE_MESSAGE'
);
