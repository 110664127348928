import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import {
  GetTextQuestionAnswersPayload,
  GetTextQuestionAnswersResponse,
} from './type';
import { ErrorResponse } from 'type';

export const getTextQuestionAnswers: ActionCreator<GetTextQuestionAnswersPayload> =
  createAction('REPORT_GET_TEXT_QUESTION_ANSWERS');

export const getTextQuestionAnswersSuccess: ActionCreator<GetTextQuestionAnswersResponse> =
  createAction('REPORT_GET_TEXT_QUESTION_ANSWERS_SUCCESS');

export const getTextQuestionAnswersFailure: ActionCreator<ErrorResponse> =
  createAction('REPORT_GET_TEXT_QUESTION_ANSWERS_FAILURE');

export const clearGetTextQuestionAnswers: EmptyActionCreator = createAction(
  'REPORT_GET_TEXT_QUESTION_ANSWERS_CLEAR'
);
