import produce from 'immer';

import {
  createRespondentProgress,
  createRespondentProgressFailure,
  createRespondentProgressSuccess,
} from './actions';
import { createRespondentProgressInitialState } from './state';
import { SurveyState } from '../type';

export function createRespondentProgressReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.createRespondentProgress = {
      ...createRespondentProgressInitialState,
    };
    draft.createRespondentProgress.isLoading = true;
  });
}

export function createRespondentProgressSuccessReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.createRespondentProgress.isLoaded = true;
    draft.createRespondentProgress.isValid = true;
    draft.createRespondentProgress.isLoading = false;
  });
}

export function createRespondentProgressFailureReducer(
  state: SurveyState
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.createRespondentProgress.isLoaded = true;
    draft.createRespondentProgress.isLoading = false;
  });
}

export const createRespondentProgressReducers = {
  [`${createRespondentProgress}`]: createRespondentProgressReducer,
  [`${createRespondentProgressSuccess}`]:
    createRespondentProgressSuccessReducer,
  [`${createRespondentProgressFailure}`]:
    createRespondentProgressFailureReducer,
};
