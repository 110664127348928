var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { debounce, getClass, isElementOfType, closestParentById, } from '../../helper';
import PopoverTarget from './component/PopoverTarget';
import PopoverContent from './component/PopoverContent';
import { Manager, Popper, Reference } from 'react-popper';
import { PopoverEvents, PopoverModes, POPPER_MODIFIERS, POPOVER_CONTAINER_ELEMENT_ID, } from './type';
import ReactDOM from 'react-dom';
import './PopoverBase.scss';
var PopoverDefaultProps = {
    isInline: false,
    tabIndex: 0,
    isSupportedFocusAndBlur: false,
};
var PopoverBase = /** @class */ (function (_super) {
    __extends(PopoverBase, _super);
    function PopoverBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isOpen: false,
        };
        _this.createPopoverContainer = function () {
            if (_this.hasContainerElement()) {
                return;
            }
            _this.createContainerElement();
        };
        _this.hasContainerElement = function () {
            return _this.getDocument().getElementById(POPOVER_CONTAINER_ELEMENT_ID);
        };
        /**
         * Create the PopoverContainer as the first element in the <body>
         */
        _this.createContainerElement = function () {
            var popoverContainer = _this.getDocument().createElement('div');
            popoverContainer.id = POPOVER_CONTAINER_ELEMENT_ID;
            popoverContainer.setAttribute('class', 'elmo-elements');
            _this.getDocument().body.insertBefore(popoverContainer, _this.getDocument().body.firstChild);
        };
        _this.addUserEvents = function () {
            var isInline = _this.props.isInline;
            // This is not required for "hover" mode as the popover will close itself once the
            // user moves the mouse out of the Popover
            PopoverEvents.forEach(function (eventName) {
                _this.getDocument().addEventListener(eventName, _this.handleActionOutsideComponent);
            });
            if (!isInline) {
                // This is not required for "hover" mode. The popover will close itself once
                // the page is scrolled, because when the page is scrolled, the mouse is moved outside of the Popover.
                _this.handleScrollListener = debounce(_this.handleScroll, 100);
                // if the popover is attached to the html body element, then close it if scrolled.
                _this.getWindow().addEventListener('scroll', _this.handleScrollListener, true);
            }
        };
        /**
         * Removes interaction events
         */
        _this.removeUserEvents = function () {
            var isInline = _this.props.isInline;
            PopoverEvents.forEach(function (eventName) {
                _this.getDocument().removeEventListener(eventName, _this.handleActionOutsideComponent);
            });
            if (!isInline) {
                _this.getWindow().removeEventListener('scroll', _this.handleScrollListener, true);
            }
        };
        _this.handleScroll = function (e) {
            if ((!_this.isOpen && _this.isControlled) ||
                closestParentById(e.target, 'PopoverContainer')) {
                return;
            }
            _this.setIsOpen(false);
        };
        /**
         * Close the popup when the user clicks/touches outside the component
         */
        _this.handleActionOutsideComponent = function (e) {
            if (!_this.isOpen || !_this.isActionOutsideComponent(e)) {
                return;
            }
            _this.setIsOpen(false);
        };
        /**
         * Returns true if an action occurred outside the component.
         * Used to close the popover if the user clicks outside the component.
         *
         * @param e
         */
        _this.isActionOutsideComponent = function (e) {
            var container = _this.getContainer();
            // if the event involves the component, don't close the popup
            var containerClicked = container.contains(e.target) && container !== e.target;
            var contentClicked = _this.contentNode.contains(e.target);
            // if the event involves an element that is not in the body then ignore it
            var clickedElementNotInPage = e.target.closest('body') === null;
            return !(containerClicked || clickedElementNotInPage || contentClicked);
        };
        _this.toggle = function () {
            if (_this.isControlled) {
                return;
            }
            _this.setState(function (_a) {
                var isOpen = _a.isOpen;
                return ({ isOpen: !isOpen });
            });
        };
        _this.open = function () {
            if (_this.isControlled) {
                return;
            }
            _this.setState({ isOpen: true });
        };
        _this.onMouseLeave = function () {
            if (_this.isControlled) {
                return;
            }
            _this.setState({ isOpen: false });
        };
        _this.onBlur = function (event) {
            if (_this.isControlled) {
                return;
            }
            var contentNode = _this.contentNode;
            var contains = contentNode &&
                contentNode.contains(event.relatedTarget);
            if (!contains) {
                _this.setState({ isOpen: false });
            }
        };
        /**
         * Passed to popperjs to access the popper content element.
         *
         * @param node
         */
        _this.setPopperContentNode = function (node) {
            _this.contentNode = node;
        };
        _this.getDocument = function () {
            return document;
        };
        _this.getWindow = function () {
            return window;
        };
        return _this;
    }
    Object.defineProperty(PopoverBase.prototype, "isControlled", {
        get: function () {
            return this.props.isOpen !== undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PopoverBase.prototype, "isOpen", {
        get: function () {
            if (this.isControlled) {
                return this.props.isOpen;
            }
            return this.state.isOpen;
        },
        enumerable: false,
        configurable: true
    });
    PopoverBase.prototype.setIsOpen = function (isOpen) {
        // if the open state is controlled by the parent component, call setIsOpen
        if (this.isControlled) {
            var setIsOpen = this.props.setIsOpen;
            if (setIsOpen) {
                setIsOpen(isOpen);
            }
        }
        else {
            // otherwise set the internal state
            this.setState({
                isOpen: isOpen,
            });
        }
    };
    PopoverBase.prototype.componentDidMount = function () {
        var mode = this.props.mode;
        // find the DOM node
        this.createPopoverContainer();
        if (mode === PopoverModes.click) {
            this.addUserEvents();
        }
    };
    PopoverBase.prototype.componentWillUnmount = function () {
        var mode = this.props.mode;
        if (mode === PopoverModes.click) {
            this.removeUserEvents();
        }
    };
    /**
     * Returns the component's container DOM element
     */
    PopoverBase.prototype.getContainer = function () {
        if (this.container) {
            return this.container;
        }
        // TODO: Fix deprecation for ReactDOM.findDOMNode
        // eslint-disable-next-line react/no-find-dom-node
        this.container = ReactDOM.findDOMNode(this);
        // eslint-disable-next-line react/no-find-dom-node
        return ReactDOM.findDOMNode(this);
    };
    PopoverBase.prototype.getSubComponents = function () {
        var children = this.props.children;
        var subComponents = {};
        React.Children.forEach(children, function (child) {
            if (!React.isValidElement(child)) {
                return;
            }
            if (isElementOfType(child, PopoverTarget)) {
                subComponents.PopperTarget = child;
            }
            else if (isElementOfType(child, PopoverContent)) {
                subComponents.PopperContent = child;
            }
        });
        return subComponents;
    };
    PopoverBase.prototype.renderContent = function (popperContent) {
        var _a = this.props, position = _a.position, isInline = _a.isInline, boundariesElement = _a.boundariesElement;
        var modifiers = POPPER_MODIFIERS;
        var boundary = !isInline && !boundariesElement ? 'viewport' : boundariesElement;
        if (boundary) {
            Object.assign(modifiers, {
                preventOverflow: {
                    boundariesElement: boundary,
                },
            });
        }
        var popperClassNames = getClass('elmo-popover-base__content', {}, {
            'is-on-body': !isInline,
            'delay-visibility': true,
        });
        var popper = (React.createElement(Popper, { placement: position ? position : 'bottom-start', eventsEnabled: false, modifiers: modifiers, innerRef: this.setPopperContentNode }, function (_a) {
            var ref = _a.ref, style = _a.style, placement = _a.placement;
            return (React.createElement("div", { ref: ref, style: style, "data-placement": placement, className: popperClassNames }, popperContent));
        }));
        if (!isInline) {
            return ReactDOM.createPortal(popper, this.getDocument().getElementById(POPOVER_CONTAINER_ELEMENT_ID));
        }
        return popper;
    };
    PopoverBase.prototype.render = function () {
        var _this = this;
        var _a = this, isOpen = _a.isOpen, _b = _a.props, id = _b.id, className = _b.className, mode = _b.mode, testId = _b.testId, ariaDescribedby = _b.ariaDescribedby, tabIndex = _b.tabIndex, isSupportedFocusAndBlur = _b.isSupportedFocusAndBlur;
        var subComponents = this.getSubComponents();
        return (React.createElement("div", __assign({ id: id, className: className }, (mode !== 'click' && {
            onMouseEnter: this.open,
            onMouseLeave: this.onMouseLeave,
            onFocus: isSupportedFocusAndBlur ? this.open : undefined,
            onBlur: isSupportedFocusAndBlur ? this.onBlur : undefined,
        }), { "data-testid": testId, "aria-describedby": isOpen ? ariaDescribedby : undefined, tabIndex: tabIndex }),
            React.createElement(Manager, null,
                React.createElement(Reference, null, function (_a) {
                    var ref = _a.ref;
                    return (React.createElement("div", __assign({ ref: ref, className: "elmo-popover-base__target" }, (mode === 'click' && { onClick: _this.toggle })), subComponents.PopperTarget));
                }),
                isOpen && this.renderContent(subComponents.PopperContent))));
    };
    PopoverBase.Content = PopoverContent;
    PopoverBase.Target = PopoverTarget;
    PopoverBase.defaultProps = PopoverDefaultProps;
    return PopoverBase;
}(React.Component));
export default PopoverBase;
