import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getRespondentProgressFailure,
  getRespondentProgressSuccess,
} from './actions';
import { setShouldUpdateSaveRespondentProgress } from '../actions';
import { getRespondentProgressApi } from './api';
import { SagaAction } from 'type';

export const getRespondentProgressSaga = function* (
  action: SagaAction<string>
): SagaIterator {
  try {
    const data = yield call(getRespondentProgressApi(action.payload));

    yield put(setShouldUpdateSaveRespondentProgress());

    yield put(getRespondentProgressSuccess(data));
  } catch (error) {
    yield put(getRespondentProgressFailure());
  }
};
