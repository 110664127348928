/* eslint-disable max-len */
import React from 'react';
function UserFilterOutlined() {
    return (React.createElement("g", null,
        React.createElement("path", { d: "M15 23H18V21H15V23Z" }),
        React.createElement("path", { d: "M10 13V15H24V13H10Z" }),
        React.createElement("path", { d: "M12 19H22V17H12V19Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.7647 7.52006C13.7647 10.0174 11.7115 12.0401 9.17652 12.0401C6.64157 12.0401 4.58838 10.0174 4.58838 7.52006C4.58838 5.02273 6.64157 3 9.17652 3C11.7115 3 13.7647 5.02273 13.7647 7.52006ZM11.4709 7.52005C11.4709 6.27703 10.4386 5.26002 9.17688 5.26002C7.91514 5.26002 6.88281 6.27703 6.88281 7.52005C6.88281 8.76306 7.91514 9.78008 9.17688 9.78008C10.4386 9.78008 11.4709 8.76306 11.4709 7.52005Z" }),
        React.createElement("path", { d: "M6.99941 15.499C2.49979 15.499 2.29407 18.4988 2.29407 20.2327L6.88221 20.2327V22.4927H0V20.2327C0 12.4993 6.99941 12.9992 6.99941 12.9992V15.499Z" })));
}
export default UserFilterOutlined;
