import produce from 'immer';

import { initializeAppInitialState } from './state';
import * as actions from './actions';
import { AppState } from '../type';

export const initializeAppReducer = (state: AppState): AppState => {
  return produce(state, (draft: AppState) => {
    draft.initializeApp = { ...initializeAppInitialState };
    draft.initializeApp.isLoading = true;
  });
};

export const initializeAppSuccessReducer = (state: AppState): AppState => {
  return produce(state, (draft: AppState) => {
    draft.initializeApp.isLoading = false;
    draft.initializeApp.isLoaded = true;
    draft.initializeApp.isValid = true;
  });
};

export const initializeAppFailureReducer = (state: AppState): AppState => {
  return produce(state, (draft: AppState) => {
    draft.initializeApp.isLoading = false;
    draft.initializeApp.isLoaded = true;
  });
};

export const initializeAppReducers = {
  [`${actions.initializeApp}`]: initializeAppReducer,
  [`${actions.initializeAppSuccess}`]: initializeAppSuccessReducer,
  [`${actions.initializeAppFailure}`]: initializeAppFailureReducer,
};
