import produce from 'immer';

import {
  clearSetOverviewReportFilters,
  setOverviewReportFilters,
} from './actions';
import { setOverviewReportFiltersInitialState } from './state';
import { OverviewReportState } from '../type';

export function setOverviewReportFiltersReducer(
  state: OverviewReportState,
  data: any
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.setOverviewReportFilters.filters = data.selectedFilters;
    draft.setOverviewReportFilters.selectedFilterCount =
      data.selectedFiltersCount;
  });
}

export function clearSetOverviewReportFiltersReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.setOverviewReportFilters = setOverviewReportFiltersInitialState;
  });
}

export const setOverviewReportFiltersReducers = {
  [`${setOverviewReportFilters}`]: setOverviewReportFiltersReducer,
  [`${clearSetOverviewReportFilters}`]: clearSetOverviewReportFiltersReducer,
};
