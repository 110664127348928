var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import ButtonLabel from './component/ButtonLabel';
import ButtonIcon from './component/ButtonIcon';
import ButtonBadge from './component/ButtonBadge';
import Spinner from '../Spinner';
import styled, { defaultTheme } from '../_lib/style';
import { getClass } from '../_lib/helper';
import './Button.scss';
import Tooltip from '../Tooltip';
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    return ((props.buttonType === 'primary' &&
        !props.isText &&
        !props.isDisabled &&
        props.theme &&
        props.theme.button &&
        props.theme.button.color &&
        props.theme.button.background &&
        "\n        color: " + props.theme.button.color + " !important;\n        background: " + props.theme.button.background + "!important;\n      ") ||
        (props.buttonType === 'primary' &&
            props.isText &&
            !props.isDisabled &&
            props.theme &&
            props.theme.link &&
            props.theme.link.color &&
            "\n        color: " + props.theme.link.color + " !important;\n      "));
});
StyledButton.defaultProps = {
    theme: {
        button: defaultTheme.button,
        link: defaultTheme.link,
    },
};
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * handle on click event
         */
        _this.handleOnClick = function (e) {
            var _a = _this.props, isDisabled = _a.isDisabled, isLoading = _a.isLoading, onClick = _a.onClick;
            if (e === undefined) {
                return;
            }
            if (isDisabled || isLoading) {
                e.stopPropagation();
                return;
            }
            if (onClick) {
                onClick(e);
            }
        };
        /**
         * Handle onblur event
         */
        _this.handleOnBlur = function (e) {
            var onBlur = _this.props.onBlur;
            if (onBlur) {
                onBlur(e);
            }
        };
        return _this;
    }
    Button.prototype.render = function () {
        var _a;
        var _b = this.props, id = _b.id, icon = _b.icon, type = _b.type, children = _b.children, isDisabled = _b.isDisabled, htmlType = _b.htmlType, className = _b.className, isBlock = _b.isBlock, isUppercase = _b.isUppercase, isText = _b.isText, isLoading = _b.isLoading, loadingTitle = _b.loadingTitle, isCircular = _b.isCircular, isLeftIndent = _b.isLeftIndent, badge = _b.badge, ariaLabel = _b.ariaLabel, role = _b.role, ariaChecked = _b.ariaChecked, ariaHaspopup = _b.ariaHaspopup, ariaExpanded = _b.ariaExpanded, tooltip = _b.tooltip, tabIndex = _b.tabIndex;
        var buttonClassNames = getClass('elmo-btn', className, (_a = {},
            _a[type] = type,
            _a.icon = icon,
            _a.block = isBlock,
            _a['is-uppercase'] = isUppercase,
            _a['is-text'] = isText,
            _a['is-disabled'] = isDisabled,
            _a['is-loading'] = isLoading,
            _a['is-circular'] = isCircular,
            _a['is-left-indent'] = isLeftIndent,
            _a));
        var roleName = role ? role : 'button';
        var renderButton = (React.createElement(StyledButton, { id: id, "data-testid": "elmo-btn-" + (id || 'default'), className: buttonClassNames, onClick: this.handleOnClick, onBlur: this.handleOnBlur, isDisabled: isDisabled, buttonType: type, isBlock: isBlock, isText: isText, type: htmlType, disabled: isDisabled, "aria-label": ariaLabel, role: roleName, "aria-checked": ariaChecked, "aria-haspopup": ariaHaspopup, "aria-expanded": ariaExpanded, tabIndex: tabIndex },
            isLoading && (React.createElement("span", { className: "elmo-btn__loading", title: loadingTitle },
                React.createElement(Spinner, null))),
            icon && React.createElement(ButtonIcon, { icon: icon, id: id }),
            children && React.createElement(ButtonLabel, { id: id }, children),
            React.createElement("span", { className: "elmo-btn__hover" }),
            React.createElement("span", { className: "elmo-btn__focused" }),
            React.createElement("span", { className: "elmo-btn__pressed" }),
            badge !== undefined && React.createElement(ButtonBadge, { badge: badge })));
        if (tooltip) {
            return (React.createElement(Tooltip, { id: id + '-tooltip', placement: tooltip.placement, title: tooltip.title, className: getClass('', isDisabled && 'elmo-tooltip__on-disabled') }, renderButton));
        }
        return renderButton;
    };
    Button.defaultProps = {
        htmlType: 'button',
        type: 'default',
        isUppercase: true,
        isText: false,
        tabIndex: 0,
    };
    return Button;
}(Component));
export default Button;
var templateObject_1;
