import { Layout, PlaceholderHeader, PlaceholderListTable } from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';

const ReleaseNotes = lazy(() => withDelay(() => import('./ReleaseNotes')));

const ReleaseNotesSuspense = (): ReactElement => {
  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={false}
              hasBackButton={false}
              hasButtons={false}
            />
          </Layout.Header>
          <Layout.Content>
            <PlaceholderListTable cols={4} hasAvatar={false} />
          </Layout.Content>
        </>
      }
    >
      <ReleaseNotes />
    </Suspense>
  );
};

export default ReleaseNotesSuspense;
