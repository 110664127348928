import { RouteObject } from 'react-router-dom';
import React from 'react';

import CreateSurveyTemplatePage from './pages/CreateSurveyTemplate';
import SurveyTemplateListPage from './pages/SurveyTemplatesList';
import EditSurveyTemplatePage from './pages/EditSurveyTemplate';

export const SURVEY_TEMPLATE_EDIT_GLOBAL =
  '/templates/:surveyTemplateId/edit-global';

export const SURVEY_TEMPLATE_COPY_GLOBAL =
  '/templates/:surveyTemplateId/copy-global';

export const SURVEY_TEMPLATE_COPY = '/templates/:surveyTemplateId/copy';

const Routes: RouteObject = {
  path: '/templates',
  children: [
    {
      path: '/templates/active',
      element: <SurveyTemplateListPage />,
    },
    {
      path: '/templates/archived',
      element: <SurveyTemplateListPage />,
    },
    {
      path: '/templates/create',
      element: <CreateSurveyTemplatePage />,
    },
    {
      path: SURVEY_TEMPLATE_EDIT_GLOBAL,
      element: <EditSurveyTemplatePage />,
    },
    {
      path: SURVEY_TEMPLATE_COPY_GLOBAL,
      element: <CreateSurveyTemplatePage />,
    },
    {
      path: '/templates/:surveyTemplateId/edit',
      element: <EditSurveyTemplatePage />,
    },
    {
      path: SURVEY_TEMPLATE_COPY,
      element: <CreateSurveyTemplatePage />,
    },
  ],
};

export default Routes;
