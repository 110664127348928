import { createAction } from 'redux-act';

export const getBulkUrlsCollector: any = createAction(
  'MY_SURVEYS_GET_SURVEY_BULK_URLS'
);

export const getBulkUrlsCollectorSuccess: any = createAction(
  'MY_SURVEYS_GET_SURVEY_BULK_URLS_SUCCESS'
);

export const getBulkUrlsCollectorFailure: any = createAction(
  'MY_SURVEYS_GET_SURVEY_BULK_URLS_FAILURE'
);
