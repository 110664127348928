import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getEmailTemplatesFailure,
  getEmailTemplatesSuccess,
  updateGetEmailTemplatesQuery,
} from './actions';
import { getEmailTemplatesApi } from './api';
import { Query } from 'lib/stateTemplates';
import { getQuery } from 'lib/query';

export const getEmailTemplatesSaga = function* (): SagaIterator {
  try {
    const queryData = getQuery(Query);

    yield put(updateGetEmailTemplatesQuery(queryData));

    const data = yield call(getEmailTemplatesApi(queryData));

    yield put(getEmailTemplatesSuccess(data));
  } catch (errors) {
    yield put(getEmailTemplatesFailure(errors));
  }
};
