var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import Button from '../../Button';
import ModalTitle from './ModalTitle';
import { CloseIcon } from '../../Icon';
var ModalHeaderDefaultProps = {
    actions: [],
};
var ModalHeader = /** @class */ (function (_super) {
    __extends(ModalHeader, _super);
    function ModalHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Render modal
     */
    ModalHeader.prototype.render = function () {
        var _a = this.props, icon = _a.icon, title = _a.title, titleSize = _a.titleSize, primaryButton = _a.primaryButton, closeModal = _a.closeModal, backButton = _a.backButton;
        return (React.createElement("div", { className: "elmo-modal__header" },
            backButton && (React.createElement("div", { className: "elmo-modal__header-back" }, backButton)),
            React.createElement(ModalTitle, { icon: icon, title: title, titleSize: titleSize }),
            !backButton && closeModal && (React.createElement("div", { className: "elmo-modal__header-close" },
                React.createElement(Button, { icon: React.createElement(CloseIcon, null), isText: true, onClick: function () {
                        closeModal();
                    }, className: "elmo-modal__header-close-btn", ariaLabel: "Close" }))),
            primaryButton && (React.createElement("div", { className: "elmo-modal__header-primary-btn" }, primaryButton))));
    };
    ModalHeader.defaultProps = ModalHeaderDefaultProps;
    return ModalHeader;
}(Component));
export default ModalHeader;
