const translations = {
  sectionTitle: {
    key: '',
    default: 'Email Message',
  },
  addButton: {
    key: '',
    default: 'Add Email Message',
  },
  sender: {
    key: '',
    default: 'From',
  },
  subject: {
    key: '',
    default: 'Subject',
  },
  message: {
    key: '',
    default: 'Message',
  },
};

export default translations;
