import { createReducer } from 'redux-act';

import { loginSharedReportReducers } from './loginSharedReport/reducers';
import { authenticateInitialState } from './state';

export default createReducer(
  {
    ...loginSharedReportReducers,
  },
  authenticateInitialState
);
