import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetAnonSurveyThemeResponse } from '../type';

export const getAnonSurveyTheme: ActionCreator<string> = createAction(
  'GET_ANON_SURVEY_THEME'
);

export const getAnonSurveyThemeSuccess: ActionCreator<GetAnonSurveyThemeResponse> =
  createAction('GET_ANON_SURVEY_THEME_SUCCESS');

export const getAnonSurveyThemeFailure: ActionCreator<any> = createAction(
  'GET_ANON_SURVEY_THEME_FAILURE'
);

export const clearGetAnonSurveyTheme: EmptyActionCreator = createAction(
  'CLEAR_GET_ANON_SURVEY_THEME'
);
