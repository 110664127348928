import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { createSurveyFailure, createSurveySuccess } from './actions';
import { CreateSurveyPayload } from './type';
import { createSurveyApi } from './api';
import { SagaAction } from 'type';

export const createSurveySaga = function* (
  action: SagaAction<CreateSurveyPayload>
): SagaIterator {
  try {
    const data = yield call(createSurveyApi(action.payload));
    yield put(createSurveySuccess(data));
  } catch (error) {
    yield put(createSurveyFailure(error));
  }
};
