import { takeEvery } from '@redux-saga/core/effects';
import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { createPdf, createPdfFailure, createPdfSuccess } from './actions';
import CreatePdfApiService from './api';

export const createPdfSaga = function* (action: any): SagaIterator {
  try {
    const params = { url: action.payload };

    const data = yield call(CreatePdfApiService.createPdf(params));
    yield put(createPdfSuccess(data));
  } catch (error) {
    yield put(createPdfFailure(error));
  }
};

export default function* root() {
  yield takeEvery(createPdf, createPdfSaga);
}
