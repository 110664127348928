import { createAction } from 'redux-act';

export const createInviteRecipients: any = createAction('CREATE_RECIPIENTS');

export const createInviteRecipientsSuccess: any = createAction(
  'CREATE_RECIPIENTS_SUCCESS'
);

export const createInviteRecipientsFailure: any = createAction(
  'CREATE_RECIPIENTS_FAILURE'
);

export const clearCreateInviteRecipients: any = createAction(
  'CLEAR_CREATE_INVITE_RECIPIENTS'
);
