import { getFeaturedTemplatesInitialState } from './getFeaturedTemplates/state';
import { deleteSurveyTemplateInitialState } from './deleteSurveyTemplate/state';
import { createSurveyTemplateInitialState } from './createSurveyTemplate/state';
import { updateSurveyTemplateInitialState } from './updateSurveyTemplate/state';
import { getSurveyTemplatesInitialState } from './getSurveyTemplates/state';
import { getSurveyTemplateInitialState } from './getSurveyTemplate/state';
import { SurveyTemplateState } from './type';

export const surveyTemplateInitialState: SurveyTemplateState = {
  deleteSurveyTemplate: deleteSurveyTemplateInitialState,
  getFeaturedTemplates: getFeaturedTemplatesInitialState,
  getSurveyTemplates: getSurveyTemplatesInitialState,
  updateSurveyTemplate: updateSurveyTemplateInitialState,
  createSurveyTemplate: createSurveyTemplateInitialState,
  getSurveyTemplate: getSurveyTemplateInitialState,
};
