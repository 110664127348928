import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { updateManageInviteParameter } from 'store/modules/invites/manageInvite/actions';
import InviteDetails from './components/inviteDetails';
import SendSchedule from './components/sendSchedule';
import Submission from './components/submission';
import Recipients from './components/recipients';
import Reminder from './components/reminder';
import Message from './components/messsage';
import { StepsProps } from './type';

const Steps = ({ collectorId, surveyId }: StepsProps) => {
  const dispatch = useDispatch();

  const inviteParameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );

  const changeStep = (step: string, brakeFlow: boolean = false) => {
    let flow = null;

    if (brakeFlow) {
      flow = { isFlow: false };
    }
    dispatch(updateManageInviteParameter({ currentStep: step, ...flow }));
  };

  if (inviteParameters.currentStep === 'inviteDetails') {
    return (
      <InviteDetails
        closeModal={() => changeStep('', true)}
        nextStep={() => changeStep('recipientsInput')}
      />
    );
  }

  if (inviteParameters.currentStep === 'recipientsInput') {
    return (
      <Recipients
        collectorId={collectorId}
        closeModal={() => changeStep('', true)}
        nextStep={() => changeStep('message')}
        previousStep={() => changeStep('inviteDetails')}
      />
    );
  }

  if (inviteParameters.currentStep === 'message') {
    return (
      <Message
        closeModal={() => changeStep('', true)}
        previousStep={() => changeStep('recipientsInput')}
      />
    );
  }

  if (inviteParameters.currentStep === 'submission') {
    return (
      <Submission
        surveyId={surveyId}
        collectorId={collectorId}
        closeModal={() => changeStep('', true)}
      />
    );
  }

  if (inviteParameters.currentStep === 'sendSchedule') {
    return (
      <SendSchedule
        surveyId={surveyId}
        collectorId={collectorId}
        closeModal={() => changeStep('', true)}
      />
    );
  }

  if (inviteParameters.currentStep === 'reminder') {
    return <Reminder closeModal={() => changeStep('', true)} />;
  }

  return <></>;
};

export default Steps;
