import React from 'react';
import PropTypes from 'prop-types';
import './HeaderTitleAddon.scss';
export var HeaderTitleAddonPropTypes = {
    children: PropTypes.node,
};
function HeaderTitleAddon(_a) {
    var children = _a.children;
    if (children) {
        return React.createElement("div", { className: "elmo-header__title-addon" }, children);
    }
    return null;
}
HeaderTitleAddon.propTypes = HeaderTitleAddonPropTypes;
export default HeaderTitleAddon;
