import React from 'react';
import './RadioLabel.scss';
function RadioLabel(_a) {
    var children = _a.children;
    if (children) {
        return React.createElement("span", { className: "elmo-radio__label" }, children);
    }
    return null;
}
export default RadioLabel;
