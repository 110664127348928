import { takeEvery } from 'redux-saga/effects';

import {
  getCollectorFilterOptions,
  getOverviewReportFilterOptions,
  getOverviewReportStats,
  getSavedOverviewReport,
  getTextQuestionAnswers,
  loadSavedOverviewReport,
  saveOverviewReportForPdf,
} from './actions';
import { getOverviewReportFilterOptionsSaga } from './getOverviewReportFilterOptions/sagas';
import { getCollectorFilterOptionsSaga } from './getCollectorFilterOptions/sagas';
import { saveOverviewReportForPdfSaga } from './saveOverviewReportForPDF/sagas';
import { loadSavedOverviewReportSaga } from './loadSavedOverviewReport/sagas';
import { getOverviewReportStatsSaga } from './getOverviewReportStats/sagas';
import { getSavedOverviewReportSaga } from './getSavedOverviewReport/sagas';
import { getTextQuestionAnswersSaga } from './getTextQuestionAnswers/sagas';

export default function* sagas() {
  yield takeEvery(getCollectorFilterOptions, getCollectorFilterOptionsSaga);
  yield takeEvery(
    getOverviewReportFilterOptions,
    getOverviewReportFilterOptionsSaga
  );
  yield takeEvery(getOverviewReportStats, getOverviewReportStatsSaga);
  yield takeEvery(getSavedOverviewReport, getSavedOverviewReportSaga);
  yield takeEvery(getTextQuestionAnswers, getTextQuestionAnswersSaga);
  yield takeEvery(saveOverviewReportForPdf, saveOverviewReportForPdfSaga);
  yield takeEvery(loadSavedOverviewReport, loadSavedOverviewReportSaga);
}
