import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { sendInviteMessageFailure, sendInviteMessageSuccess } from './actions';
import { sendInviteMessageApi } from './api';

export const sendInviteMessageSaga = function* (action: any): SagaIterator {
  try {
    yield call(sendInviteMessageApi(action.payload));

    yield put(sendInviteMessageSuccess());
  } catch (error) {
    yield put(sendInviteMessageFailure(error));
  }
};
