import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { createQuestionFailure, createQuestionSuccess } from './actions';
import { CreateQuestionPayload } from './type';
import { createQuestionApi } from './api';
import { SagaAction } from 'type';

export const createQuestionSaga = function* (
  action: SagaAction<CreateQuestionPayload>
): SagaIterator {
  try {
    yield call(createQuestionApi(action.payload));

    yield put(createQuestionSuccess());
  } catch (error) {
    yield put(createQuestionFailure(error as any));
  }
};
