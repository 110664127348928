/* eslint-disable max-len */
import React from 'react';
function EmailTemplates() {
    return (React.createElement("svg", { version: "1.1", id: "Layer_1", viewBox: "0 0 24 24", className: "elmo-icon__svg" },
        React.createElement("g", null,
            React.createElement("rect", { x: "6.1", y: "18", width: "2", height: "2" }),
            React.createElement("rect", { x: "10.1", y: "18", width: "2", height: "2" }),
            React.createElement("rect", { x: "14.1", y: "18", width: "2", height: "2" }),
            React.createElement("path", { d: "M20,4H4C2.9,4,2,4.9,2,6v6h2V8l8,5l8-5v10h-1.9v2H20c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M12,11L4,6h16L12,11z" }),
            React.createElement("rect", { x: "2", y: "14", width: "2", height: "2" }),
            React.createElement("path", { d: "M4.1,18L4.1,18H2c0,1.1,0.9,2,2,2h0.1V18z" }))));
}
export default EmailTemplates;
