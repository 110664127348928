import produce from 'immer';

import {
  clearOverviewReportStats,
  getOverviewReportStats,
  getOverviewReportStatsFailure,
  getOverviewReportStatsSuccess,
} from './actions';
import { getOverviewReportStatsInitialState } from './state';
import { OverviewReportState } from '../type';
import { ErrorResponse } from 'type';

export function getOverviewReportStatsReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getOverviewReportStats.isLoaded = false;
    draft.getOverviewReportStats.isLoading = true;
    draft.getOverviewReportStats.isValid = false;
    draft.getOverviewReportStats.data = {};
    draft.getOverviewReportStats.error = undefined;
  });
}

export function getOverviewReportStatsSuccessReducer(
  state: OverviewReportState,
  data: any
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getOverviewReportStats.isLoaded = true;
    draft.getOverviewReportStats.isLoading = false;
    draft.getOverviewReportStats.isValid = true;
    draft.getOverviewReportStats.data = data.data;
  });
}

export function getOverviewReportStatsFailureReducer(
  state: OverviewReportState,
  error: ErrorResponse
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getOverviewReportStats.isLoaded = true;
    draft.getOverviewReportStats.isLoading = false;
    draft.getOverviewReportStats.error = error;
  });
}

export function clearOverviewReportStatsReducer(
  state: OverviewReportState
): OverviewReportState {
  return produce(state, (draft: OverviewReportState) => {
    draft.getOverviewReportStats = getOverviewReportStatsInitialState;
  });
}

export const getOverviewReportReducers = {
  [`${getOverviewReportStats}`]: getOverviewReportStatsReducer,
  [`${getOverviewReportStatsSuccess}`]: getOverviewReportStatsSuccessReducer,
  [`${getOverviewReportStatsFailure}`]: getOverviewReportStatsFailureReducer,
  [`${clearOverviewReportStats}`]: clearOverviewReportStatsReducer,
};
