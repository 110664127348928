import { Button, Col, Row } from 'elmo-elements';
import React, { useEffect, useRef } from 'react';

import {
  ManageInviteParameters,
  ManageInvitesData,
} from 'store/modules/invites/manageInvite/type';
import {
  getPrimaryButtonText,
  getReminderEventName,
  getScheduleEventName,
} from '../helpers';
import {
  reminderIndexEvent,
  sendScheduleEvent,
} from '../../../../helpers/events';
import translations from '../translations';
import { isCollectorActive } from 'lib';
import { FullCollector } from 'type';
import { t } from 'lib/translation';

type ActionsProps = {
  invite: ManageInvitesData;
  inviteParameters: ManageInviteParameters;
  editSendSchedule: () => void;
  editReminder: () => void;
  collector: FullCollector;
  showPrimaryAction: boolean;
};

const Actions = ({
  invite,
  inviteParameters,
  editSendSchedule,
  editReminder,
  collector,
  showPrimaryAction,
}: ActionsProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const primaryButtonText = getPrimaryButtonText(invite);
  const reminderEventName = getReminderEventName(invite);
  const scheduleEventName = getScheduleEventName(invite);

  useEffect(() => {
    if (
      document.getElementById('submission-summary') &&
      ref.current &&
      !inviteParameters.isDraft
    ) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView();
        }
      }, 100);
    }
  }, [ref, inviteParameters.isDraft]);

  if (!isCollectorActive(collector)) {
    return <></>;
  }

  return (
    <div ref={ref} id={'submission-summary'}>
      <Row justifyContent={'between'} wrap={'nowrap'}>
        <Col>
          {invite.message.status !== 'cancelled' && (
            <Button
              id={'manage-invites-reminder'}
              className={'text-button'}
              onClick={() => {
                reminderIndexEvent(
                  reminderEventName,
                  collector,
                  invite,
                  invite.reminders.length + 1
                );

                editReminder();
              }}
            >
              {t(translations.reminderButton)}
            </Button>
          )}
        </Col>
        {showPrimaryAction && (
          <Col>
            <Button
              id={'manage-invites-sendSchedule'}
              type={'primary'}
              onClick={() => {
                sendScheduleEvent(scheduleEventName, collector, invite);
                editSendSchedule();
              }}
            >
              {primaryButtonText}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Actions;
