import { CreateQuestionCategoryPayload } from './type';
import { postRequest } from 'lib/api';

const globalUrl = '/admin/question-categories';
const localUrl = '/question-categories';

export const createQuestionCategoryApi = (
  payload: CreateQuestionCategoryPayload
) => {
  const url = payload.global ? globalUrl : localUrl;

  return postRequest(url, payload);
};
