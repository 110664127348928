import { getReportHash, isSharedReport } from '../helpers/sharedReports';
import { removeToken, setToken } from '../token';
import { getRoute } from '../util';
import history from 'lib/history';

export const getOAuthRedirectionPath = () => {
  return (
    '/oauth2-client/redirect-to-secure-frontend/null?frontend-url=' +
    window.location.href
  );
};

const redirectToTmsLogin = () => {
  window.location.href =
    'https://' + window.location.hostname + getOAuthRedirectionPath();
};

export const redirectToSharedReportLogin = () => {
  const reportHash = getReportHash();

  history.push(
    getRoute('/report/:reportHash/login', {
      params: { reportHash: reportHash },
    })
  );
};

export const removeJWTAndPromptForLogin = (): void => {
  removeToken();

  if (isSharedReport()) {
    return redirectToSharedReportLogin();
  }

  return redirectToTmsLogin();
};

const redirectToSharedReportPage = () => {
  const reportHash = getReportHash();

  history.push(
    getRoute('/report/:reportHash', {
      params: { reportHash: reportHash },
    })
  );
};

export const completeLogin = (token: string): void => {
  setToken(token);

  if (isSharedReport()) {
    redirectToSharedReportPage();
  }
};
