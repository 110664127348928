import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getCollectorUsageFailure, getCollectorUsageSuccess } from './actions';
import { getCollectorUsageApi } from './api';
import { SagaAction } from 'type';

export const getCollectorUsageSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const data = yield call(getCollectorUsageApi(action.payload));

    yield put(getCollectorUsageSuccess(data));
  } catch (error) {
    yield put(getCollectorUsageFailure(error));
  }
};
