import produce from 'immer';

import {
  clearGetIndividualResponses,
  getIndividualResponses,
  getIndividualResponsesFailure,
  getIndividualResponsesSuccess,
  updateIndividualResponsesQuery,
} from './actions';
import { getIndividualResponsesInitialState } from './state';
import { transformResponses } from '../../../transformers';
import { GetIndividualResponsesResponse } from './type';
import { ResponseState } from '../type';
import { Query } from 'lib/query/type';
import { ErrorResponse } from 'type';

export const getIndividualResponsesReducer = (
  state: ResponseState,
  surveyId: number
): ResponseState => {
  return produce(state, (draft: ResponseState) => {
    draft.getIndividualResponses = { ...getIndividualResponsesInitialState };
    draft.getIndividualResponses.isLoading = true;
    draft.getIndividualResponses.surveyId = surveyId;
  });
};

export const getIndividualResponsesSuccessReducer = (
  state: ResponseState,
  data: GetIndividualResponsesResponse
): ResponseState => {
  return produce(state, (draft: ResponseState) => {
    draft.getIndividualResponses.isLoading = false;
    draft.getIndividualResponses.isLoaded = true;
    draft.getIndividualResponses.isValid = true;
    draft.getIndividualResponses.data = transformResponses(data.data);
    draft.getIndividualResponses.count = data.count;
  });
};

export const getIndividualResponsesFailureReducer = (
  state: ResponseState,
  error: ErrorResponse
): ResponseState => {
  return produce(state, (draft: ResponseState) => {
    draft.getIndividualResponses.isLoading = false;
    draft.getIndividualResponses.isLoaded = true;
    draft.getIndividualResponses.error = error;
  });
};

export const updateIndividualResponsesQueryReducer = (
  state: ResponseState,
  query: Query
) => {
  return produce(state, (draft: ResponseState) => {
    draft.getIndividualResponses.query = query;
  });
};

export const clearGetIndividualResponsesReducer = (state: ResponseState) => {
  return produce(state, (draft: ResponseState) => {
    draft.getIndividualResponses = getIndividualResponsesInitialState;
  });
};

export const getIndividualResponsesReducers = {
  [`${getIndividualResponses}`]: getIndividualResponsesReducer,
  [`${getIndividualResponsesSuccess}`]: getIndividualResponsesSuccessReducer,
  [`${getIndividualResponsesFailure}`]: getIndividualResponsesFailureReducer,
  [`${updateIndividualResponsesQuery}`]: updateIndividualResponsesQueryReducer,
  [`${clearGetIndividualResponses}`]: clearGetIndividualResponsesReducer,
};
