import { getCollectorUsageInitialState } from './getCollectorUsage/state';
import { updateCollectorInitialState } from './updateCollector/state';
import { getCollectorsInitialState } from './getCollectors/state';
import { getCollectorInitialState } from './getCollector/state';
import { addCollectorInitialState } from './addCollector/state';
import { CollectorState } from './type';

export const collectorInitialState: CollectorState = {
  addCollector: addCollectorInitialState,
  getCollector: getCollectorInitialState,
  getCollectors: getCollectorsInitialState,
  getCollectorUsage: getCollectorUsageInitialState,
  updateCollector: updateCollectorInitialState,
};
