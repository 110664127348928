import { useLocation, useRoutes } from 'react-router-dom';
import React, { ReactElement, useEffect } from 'react';

import { analytics, AnalyticsEventNames, AnalyticsEventTypes } from 'lib';
import { appRoutes } from '../routes';
import './App.scss';

const AppLayout = (): ReactElement => {
  const element = useRoutes(appRoutes);
  const location = useLocation();

  useEffect(() => {
    analytics.sendEvent(
      AnalyticsEventNames.SURVEY_PAGE_VIEWED,
      undefined,
      AnalyticsEventTypes.EVENT_TYPE_VIEW_PAGE
    );
  }, [location.key]);

  return <>{element}</>;
};

export default AppLayout;
