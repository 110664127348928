import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { LoadSavedOverviewReportPayload } from './type';
import { ErrorResponse } from 'type';

export const loadSavedOverviewReport: ActionCreator<LoadSavedOverviewReportPayload> =
  createAction('LOAD_SAVED_OVERVIEW_REPORT');

export const loadSavedOverviewReportSuccess: EmptyActionCreator = createAction(
  'LOAD_SAVED_OVERVIEW_REPORT_SUCCESS'
);

export const loadSavedOverviewReportFailure: ActionCreator<ErrorResponse> =
  createAction('LOAD_SAVED_OVERVIEW_REPORT_FAILURE');

export const clearLoadSavedOverviewReport: EmptyActionCreator = createAction(
  'CLEAR_LOAD_SAVED_OVERVIEW_REPORT'
);
