import produce from 'immer';

import {
  getSurveyStats,
  getSurveyStatsSuccess,
  getSurveyStatsFailure,
  clearGetSurveyStats,
} from './actions';
import { getSurveyStatsInitialState } from './state';
import { GetSurveyStatsResponse } from './type';
import { SurveyState } from '../type';
import { ErrorResponse } from 'type';

export function getSurveyStatsReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyStats = { ...getSurveyStatsInitialState };
    draft.getSurveyStats.isLoading = true;
  });
}

export function getSurveyStatsSuccessReducer(
  state: SurveyState,
  data: GetSurveyStatsResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyStats.isLoading = false;
    draft.getSurveyStats.isLoaded = true;
    draft.getSurveyStats.isValid = true;
    draft.getSurveyStats.data = data.data;
  });
}

export function getSurveyStatsFailureReducer(
  state: SurveyState,
  error: ErrorResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyStats.isLoading = false;
    draft.getSurveyStats.isLoaded = true;
    draft.getSurveyStats.error = error;
  });
}

export function clearGetSurveyStatsReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.getSurveyStats = getSurveyStatsInitialState;
  });
}

export const getSurveyStatsReducers = {
  [`${getSurveyStats}`]: getSurveyStatsReducer,
  [`${getSurveyStatsSuccess}`]: getSurveyStatsSuccessReducer,
  [`${getSurveyStatsFailure}`]: getSurveyStatsFailureReducer,
  [`${clearGetSurveyStats}`]: clearGetSurveyStatsReducer,
};
