import {
  PlaceholderDashboard,
  PlaceholderHeader,
  Layout,
  Row,
  Col,
} from 'elmo-elements';
import React, { lazy, ReactElement } from 'react';

import Suspense from 'sharedComponents/Suspense';
import { withDelay } from 'lib/util';
import './Details.scss';

const DetailsContainer = lazy(() =>
  withDelay(() => import('./DetailsContainer'))
);

const Details = (): ReactElement => {
  return (
    <Suspense
      fallback={
        <>
          <Layout.Header>
            <PlaceholderHeader
              hasSubHeading={false}
              hasTabs={true}
              hasBackButton={true}
              hasButtons={false}
              numberOfTabs={5}
            />
          </Layout.Header>
          <Layout.Content>
            <Row>
              <Col className="mb-3 survey-details-width">
                <PlaceholderDashboard cols={1} rows={1} />
              </Col>
            </Row>
          </Layout.Content>
        </>
      }
    >
      <DetailsContainer />
    </Suspense>
  );
};

export default Details;
