import { Menu } from 'type';

export const getAdminInfo = (menu: Menu) => {
  return menu.menu.user
    .filter((n: { key: string }) => n.key === 'app.menu.admin')
    .shift();
};

export const getUserInfo = (menu: Menu) => {
  const { name, img } = menu.user;

  // handle admin information
  const adminInfo = getAdminInfo(menu);
  let isAdmin = false;
  let adminUrl = '';
  let isAdminSelected = false;

  if (adminInfo && 'url' in adminInfo) {
    isAdmin = true;
    adminUrl = adminInfo.url;
    isAdminSelected =
      (menu.status.location && menu.status.location.key) === adminInfo.key;
  }

  return {
    name,
    avatar: img,
    isAdmin,
    adminUrl,
    isAdminSelected,
  };
};
