import {
  Button,
  NativeTable,
  EditIcon,
  MailOutlinedIcon,
  Row,
} from 'elmo-elements';
import React, { ReactElement } from 'react';

import WithHeading from 'element/card/components/withHeading';
import { inviteMessageSummaryProps } from './type';
import translations from './translations';
import { t } from 'lib/translation';
import './style.scss';

const InviteMessageSummary = ({
  messageData,
  showModal,
  isActive,
}: inviteMessageSummaryProps): ReactElement => {
  if (!messageData) {
    return <></>;
  }

  const actions = isActive ? (
    <Row className={'message-actions'}>
      <Button
        id={`edit-invite-${messageData.id}`}
        icon={<EditIcon />}
        onClick={() => showModal('editInvite', messageData)}
      />
    </Row>
  ) : (
    <></>
  );

  return (
    <span className={'invite-message-summary'}>
      <WithHeading
        icon={<MailOutlinedIcon />}
        heading={messageData.title}
        actions={actions}
      >
        <NativeTable isFullWidth={true}>
          <NativeTable.Body>
            <NativeTable.Tr>
              <NativeTable.Th>
                {t(translations.labels.from_name)}
              </NativeTable.Th>
              <NativeTable.Td>{messageData.from_name}</NativeTable.Td>
            </NativeTable.Tr>
            <NativeTable.Tr>
              <NativeTable.Th>
                {t(translations.labels.from_email)}
              </NativeTable.Th>
              <NativeTable.Td>{messageData.from_email}</NativeTable.Td>
            </NativeTable.Tr>
            <NativeTable.Tr>
              <NativeTable.Th>{t(translations.labels.subject)}</NativeTable.Th>
              <NativeTable.Td>{messageData.subject}</NativeTable.Td>
            </NativeTable.Tr>
            <NativeTable.Tr>
              <NativeTable.Th>{t(translations.labels.content)}</NativeTable.Th>
              <NativeTable.Td>
                <p dangerouslySetInnerHTML={{ __html: messageData.message }} />
              </NativeTable.Td>
            </NativeTable.Tr>
          </NativeTable.Body>
        </NativeTable>
      </WithHeading>
    </span>
  );
};

export default InviteMessageSummary;
