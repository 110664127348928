import { RouteObject } from 'react-router-dom';
import React from 'react';

import PreviewSurvey from './pages/PreviewSurvey';

const routes: RouteObject = {
  path: '/preview/:previewHash',
  element: <PreviewSurvey />,
};

export default routes;
