import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getFiltersFailure,
  getFiltersSuccess,
  updateGetFiltersQuery,
} from './actions';
import { Query } from 'lib/stateTemplates';
import { getFiltersApi } from './api';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

export const getFiltersSaga = function* (
  action: SagaAction<boolean>
): SagaIterator {
  try {
    const queryData = getQuery(Query);

    yield put(updateGetFiltersQuery(queryData));

    const data = yield call(getFiltersApi(queryData, action.payload));

    yield put(getFiltersSuccess(data));
  } catch (errors) {
    yield put(getFiltersFailure(errors));
  }
};
