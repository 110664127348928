import { createReducer } from 'redux-act';

import { getOverviewReportFilterOptionsReducers } from './getOverviewReportFilterOptions/reducers';
import { updateOverviewReportPaginationReducers } from './updateOverviewReportPagination/reducers';
import { getCollectorFilterOptionsReducers } from './getCollectorFilterOptions/reducers';
import { saveOverviewReportForPDFReducers } from './saveOverviewReportForPDF/reducers';
import { setOverviewReportFiltersReducers } from './setOverviewReportFilters/reducers';
import { loadSavedOverviewReportReducers } from './loadSavedOverviewReport/reducers';
import { getSavedOverviewReportReducers } from './getSavedOverviewReport/reducers';
import { getTextQuestionAnswersReducers } from './getTextQuestionAnswers/reducers';
import { updateQuestionSettingsReducers } from './updateQuestionSettings/reducers';
import { getOverviewReportReducers } from './getOverviewReportStats/reducers';
import { overviewReportInitialState } from './state';

export default createReducer(
  {
    ...getOverviewReportReducers,
    ...getCollectorFilterOptionsReducers,
    ...getOverviewReportFilterOptionsReducers,
    ...getSavedOverviewReportReducers,
    ...getTextQuestionAnswersReducers,
    ...loadSavedOverviewReportReducers,
    ...saveOverviewReportForPDFReducers,
    ...setOverviewReportFiltersReducers,
    ...updateOverviewReportPaginationReducers,
    ...updateQuestionSettingsReducers,
  },
  overviewReportInitialState
);
