import { ActionCreator, createAction } from 'redux-act';

import { GetThemeResponse } from './type';

export const getTheme: ActionCreator<string> = createAction('GET_THEME');

export const getThemeSuccess: ActionCreator<GetThemeResponse> =
  createAction('GET_THEME_SUCCESS');

export const getThemeFailure: ActionCreator<any> =
  createAction('GET_THEME_FAILURE');

export const clearGetTheme = createAction('CLEAR_GET_THEME');
