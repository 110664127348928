import produce from 'immer';

import {
  updateBulkPassword,
  updateBulkPasswordFailure,
  updateBulkPasswordSuccess,
  clearUpdateBulkPassword,
} from './actions';
import { updateBulkPasswordInitialState } from './state';
import { ShareReportState } from '../type';
import { ErrorResponse } from 'type';

export const updateBulkPasswordReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.updateBulkPassword = { ...updateBulkPasswordInitialState };
    draft.updateBulkPassword.isLoading = true;
  });
};

export const updateBulkPasswordSuccessReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.updateBulkPassword.isLoading = false;
    draft.updateBulkPassword.isLoaded = true;
    draft.updateBulkPassword.isValid = true;
  });
};

export const updateBulkPasswordFailureReducer = (
  state: ShareReportState,
  error: ErrorResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.updateBulkPassword.isLoading = false;
    draft.updateBulkPassword.isLoaded = true;
    draft.updateBulkPassword.error = error;
  });
};

export const clearUpdateBulkPasswordReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.updateBulkPassword = updateBulkPasswordInitialState;
  });
};

export const updateBulkPasswordReducers = {
  [`${updateBulkPassword}`]: updateBulkPasswordReducer,
  [`${updateBulkPasswordSuccess}`]: updateBulkPasswordSuccessReducer,
  [`${updateBulkPasswordFailure}`]: updateBulkPasswordFailureReducer,
  [`${clearUpdateBulkPassword}`]: clearUpdateBulkPasswordReducer,
};
