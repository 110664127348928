import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url = '/collectors/:collectorId/email-recipient-lists';

export const getInvitesApi = (collectorId: number, filters: any) => {
  return getRequest(
    getRoute(url, {
      params: { collectorId: collectorId },
    }),
    { params: filters }
  );
};
