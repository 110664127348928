export default {
  title: {
    key: 'EmailInvites.manageInvite.header.title',
    default: 'Invite Participants',
  },
  subTitle: {
    key: '',
    default: 'Manage Invite',
  },
};
