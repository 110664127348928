import { Col, Row, FormItem, Input } from 'elmo-elements';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import React from 'react';

import {
  dateTimeWithTimeZone,
  formatDateTime,
  isTimeStampBefore,
} from 'lib/date';
import { Cancelled, Sent } from 'constants/messageStatus';
import Datetime from 'element/fields/Datetime';
import Checkbox from 'element/fields/Checkbox';
import translations from './translations';
import { t } from 'lib/translation';
import './style.scss';

const InviteSchedule = () => {
  const parameters = useSelector(
    (state: any) => state.emailInvites.manageInvite.parameters
  );
  const message = useSelector(
    (state: any) => state.emailInvites.manageInvite.data.message
  );

  const { values } = useFormikContext<any>();

  if (message.status === Sent) {
    return (
      <>
        <Row wrap={'nowrap'}>
          <Col xs={24} sm={12}>
            <FormItem
              label={t(translations.listSent)}
              message={dateTimeWithTimeZone(message.sentDate)}
            >
              <Input
                value={formatDateTime(message.sentDate, 'DD/MM/YYYY H:mm')}
                isDisabled={true}
              />
            </FormItem>
          </Col>
        </Row>
      </>
    );
  }

  const isDisabled =
    values.sendNow ||
    (parameters.isEdit &&
      isTimeStampBefore(message.scheduledDate, 120) &&
      message.status !== Cancelled);

  return (
    <>
      <Row wrap={'nowrap'}>
        <Col xs={24} sm={12}>
          <Datetime
            name={'inviteDate'}
            label={t(translations.listSchedule)}
            isDisabled={isDisabled}
            disablePast={!isDisabled}
          />
        </Col>
        {!parameters.isEdit && (
          <Col>
            <Checkbox
              id={'send-now-checkbox'}
              name={'sendNow'}
              label={t(translations.sendNow)}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default InviteSchedule;
