var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var HeaderTabsStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var primaryColor = _a.primaryColor;
    return primaryColor &&
        "\n    .elmo-tabs__nav > ul > .elmo-tabs__item--active a:after {\n      background-color: " + primaryColor + " !important;\n    }\n  ";
});
var templateObject_1;
