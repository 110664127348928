import { UpdateSurveyNotificationPayload } from './type';
import { putRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const apiUrl = '/survey/:surveyId/notification/:notificationId';

export const updateSurveyNotificationApi = (
  payload: UpdateSurveyNotificationPayload
) => {
  return putRequest(
    getRoute(apiUrl, {
      params: {
        surveyId: payload.surveyId,
        notificationId: payload.notificationId,
      },
    }),
    payload.data
  );
};
