import produce from 'immer';

import {
  clearUpdateTheme,
  updateTheme,
  updateThemeFailure,
  updateThemeSuccess,
} from './actions';
import { updateThemeInitialState } from './state';
import { ThemeState } from '../type';

export function updateThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.updateTheme.isUpdating = true;
  });
}

export function updateThemeSuccessReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.updateTheme.isUpdated = true;
    draft.updateTheme.isValid = true;
  });
}

export function updateThemeFailureReducer(
  state: ThemeState,
  err: any
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.updateTheme.errors = err;
  });
}

export function clearUpdateThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.updateTheme = {
      ...updateThemeInitialState,
    };
  });
}

export const updateThemeReducers = {
  [`${updateTheme}`]: updateThemeReducer,
  [`${updateThemeSuccess}`]: updateThemeSuccessReducer,
  [`${updateThemeFailure}`]: updateThemeFailureReducer,
  [`${clearUpdateTheme}`]: clearUpdateThemeReducer,
};
