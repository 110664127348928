import { cancel, fork, put, select, take } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import find from 'lodash/find';

import {
  createInviteMessageFailure,
  createInviteMessageSchedule,
  createInviteMessageScheduleSuccess,
  createInviteReminderMessage,
  createInviteReminderMessageFailure,
  createInviteReminderMessageSuccess,
  updateInviteMessageSchedule,
  updateInviteMessageScheduleFailure,
  updateInviteMessageScheduleSuccess,
} from '../actions';
import { createInviteReminderMessageSelector } from '../createInviteReminderMessage/selectors';
import {
  updateInviteSchedulesSuccess,
  updateInviteSchedulesFailure,
} from './actions';
import { shouldUpdateMessageSchedule } from 'lib/helpers/emailInvites';
import { getInvite } from '../manageInvite/selectors';
import { Schedules } from '../manageInvite/type';

export interface UpdateInviteScheduleSagaProps {
  error: boolean;
  payload: {
    scheduleData: Schedules;
    collectorId: number;
  };
  type: string;
}

export const updateInviteScheduleTask = function* (
  action: UpdateInviteScheduleSagaProps
): SagaIterator {
  try {
    const inviteData = yield select(getInvite);

    if (
      shouldUpdateMessageSchedule(
        inviteData.message,
        action.payload.scheduleData.scheduledDate
      )
    ) {
      const inviteSchedulePayload = {
        messageId: action.payload.scheduleData.messageId,
        scheduleDate: action.payload.scheduleData.scheduledDate,
      };

      yield put(updateInviteMessageSchedule(inviteSchedulePayload));

      yield take(updateInviteMessageScheduleSuccess);
    }

    for (let reminder of inviteData.reminders) {
      const isNewReminder = reminder.status === null;
      const reminderSchedule = find(action.payload.scheduleData.reminders, [
        'id',
        reminder.id,
      ]);

      if (!reminderSchedule) {
        throw new Error('Unable to find reminder schedule');
      }

      if (isNewReminder) {
        const newReminderPayload = {
          collectorId: action.payload.collectorId,
          recipientListId: inviteData.inviteDetails.id,
          data: {
            subject: reminder.subject,
            message: reminder.content,
            fromName: reminder.senderName,
            plainMessage: '',
          },
        };
        yield put(createInviteReminderMessage(newReminderPayload));

        yield take(createInviteReminderMessageSuccess);

        const reminderMessage = yield select(
          createInviteReminderMessageSelector
        );

        const createSchedulePayload = {
          messageId: reminderMessage.data.id,
          scheduleDate: reminderSchedule.scheduledDate,
        };

        yield put(createInviteMessageSchedule(createSchedulePayload));

        yield take(createInviteMessageScheduleSuccess);

        continue;
      }

      if (
        shouldUpdateMessageSchedule(reminder, reminderSchedule.scheduledDate)
      ) {
        const reminderSchedulePayload = {
          messageId: reminder.id,
          scheduleDate: reminderSchedule.scheduledDate,
        };

        yield put(updateInviteMessageSchedule(reminderSchedulePayload));

        yield take(updateInviteMessageScheduleSuccess);
      }
    }

    yield put(updateInviteSchedulesSuccess());
  } catch (error) {
    yield put(updateInviteSchedulesFailure());
  }
};

export const updateInviteScheduleSaga = function* (
  action: UpdateInviteScheduleSagaProps
): SagaIterator {
  const task = yield fork(updateInviteScheduleTask, action);

  yield take([
    updateInviteMessageScheduleFailure,
    createInviteReminderMessageFailure,
    createInviteMessageFailure,
  ]);
  yield cancel(task);
  yield put(updateInviteSchedulesFailure());
};
