import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';

import {
  getIndividualLinksFailure,
  getIndividualLinksSuccess,
  updateGetIndividualLinksQuery,
} from './actions';
import { getIndividualLinksApi } from './api';
import { Query } from 'lib/stateTemplates';
import { getQuery } from 'lib/query';
import { SagaAction } from 'type';

export const getIndividualLinksSaga = function* (
  action: SagaAction<number>
): SagaIterator {
  try {
    const queryData = getQuery(Query);

    yield put(updateGetIndividualLinksQuery(queryData));

    const data = yield call(getIndividualLinksApi(action.payload, queryData));

    yield put(getIndividualLinksSuccess(data));
  } catch (error) {
    yield put(getIndividualLinksFailure(error));
  }
};
