import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';
import { getRoute } from 'lib/util';

const url =
  '/collectors/:collectorId/email-recipient-lists/:listId/email-recipients';

export const getInviteRecipientsApi = (
  { collectorId, inviteId }: any,
  params: Query
) => {
  return getRequest(
    getRoute(url, {
      params: { collectorId: collectorId, listId: inviteId },
    }),
    { params: params }
  );
};
