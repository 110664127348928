import produce from 'immer';

import {
  getBulkLinkBatch,
  getBulkLinkBatchFailure,
  getBulkLinkBatchSuccess,
  clearGetBulkLinkBatch,
} from './actions';
import { transformSharedReportBulk } from '../../../transformers';
import { getBulkLinkBatchInitialState } from './state';
import { GetBulkLinkBatchResponse } from './type';
import { ShareReportState } from '../type';
import { ErrorResponse } from 'type';

export const getBulkLinkBatchReducer = (
  state: ShareReportState
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getBulkLinkBatch = { ...getBulkLinkBatchInitialState };
    draft.getBulkLinkBatch.isLoading = true;
  });
};

export const getBulkLinkBatchSuccessReducer = (
  state: ShareReportState,
  data: GetBulkLinkBatchResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getBulkLinkBatch.isLoading = false;
    draft.getBulkLinkBatch.isLoaded = true;
    draft.getBulkLinkBatch.isValid = true;
    draft.getBulkLinkBatch.data = transformSharedReportBulk(data.data);
  });
};

export const getBulkLinkBatchFailureReducer = (
  state: ShareReportState,
  error: ErrorResponse
): ShareReportState => {
  return produce(state, (draft: ShareReportState) => {
    draft.getBulkLinkBatch.isLoading = false;
    draft.getBulkLinkBatch.isLoaded = true;
    draft.getBulkLinkBatch.error = error;
  });
};

export function clearGetBulkLinkBatchReducer(
  state: ShareReportState
): ShareReportState {
  return produce(state, (draft: ShareReportState) => {
    draft.getBulkLinkBatch = getBulkLinkBatchInitialState;
  });
}

export const getBulkLinkBatchReducers = {
  [`${getBulkLinkBatch}`]: getBulkLinkBatchReducer,
  [`${getBulkLinkBatchSuccess}`]: getBulkLinkBatchSuccessReducer,
  [`${getBulkLinkBatchFailure}`]: getBulkLinkBatchFailureReducer,
  [`${clearGetBulkLinkBatch}`]: clearGetBulkLinkBatchReducer,
};
