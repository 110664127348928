import { PrimaryButtonType, HeaderButtonProp } from 'elmo-elements/Header';
import { SearchIcon } from 'elmo-elements';
import React, { ReactNode } from 'react';

export const getPrimaryButton = (
  label: string | undefined,
  icon: ReactNode,
  onClick?: () => void,
  children?: HeaderButtonProp[]
): PrimaryButtonType | undefined => {
  if (!onClick && !children) {
    return undefined;
  }

  let primaryButton: PrimaryButtonType = {
    icon: icon ? icon : null,
    label: label,
    onClick: onClick,
  };

  if (children) {
    primaryButton.children = children;
  }

  return primaryButton;
};

export const getSecondaryButtons = (
  secondaryButtons: HeaderButtonProp[],
  hasSearch: boolean,
  openSearch?: () => void
): HeaderButtonProp[] => {
  let buttons: HeaderButtonProp[] = secondaryButtons ? secondaryButtons : [];

  if (hasSearch && openSearch) {
    buttons = [
      ...buttons,
      {
        id: 'header-search',
        icon: <SearchIcon />,
        label: 'Search',
        onClick: openSearch,
      },
    ];
  }

  return buttons;
};
