import produce from 'immer';

import {
  setDefaultTheme,
  setDefaultThemeFailure,
  setDefaultThemeSuccess,
  clearSetDefaultTheme,
} from './actions';
import { setDefaultThemeInitialState } from './state';
import { ThemeState } from '../type';

export function setDefaultThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.setDefaultTheme.isLoading = true;
    draft.setDefaultTheme.isLoaded = false;
    draft.setDefaultTheme.isValid = false;
  });
}

export function setDefaultThemeSuccessReducer(
  state: ThemeState,
  themeId: number
): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.setDefaultTheme.isLoading = false;
    draft.setDefaultTheme.isLoaded = true;
    draft.setDefaultTheme.isValid = true;
    draft.setDefaultTheme.themeId = themeId;
  });
}

export function setDefaultThemeFailureReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.setDefaultTheme.isLoading = false;
    draft.setDefaultTheme.isLoaded = true;
    draft.setDefaultTheme.isValid = false;
  });
}

export function clearSetDefaultThemeReducer(state: ThemeState): ThemeState {
  return produce(state, (draft: ThemeState) => {
    draft.setDefaultTheme = setDefaultThemeInitialState;
  });
}

export const setDefaultThemeReducers = {
  [`${setDefaultTheme}`]: setDefaultThemeReducer,
  [`${setDefaultThemeSuccess}`]: setDefaultThemeSuccessReducer,
  [`${setDefaultThemeFailure}`]: setDefaultThemeFailureReducer,
  [`${clearSetDefaultTheme}`]: clearSetDefaultThemeReducer,
};
