var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import NativeTableWrapper from './component/NativeTableWrapper';
import NativeTableBody from './component/NativeTableBody';
import NativeTableHeader from './component/NativeTableHeader';
import NativeTableTd from './component/NativeTableTd';
import NativeTableTh from './component/NativeTableTh';
import NativeTableTr from './component/NativeTableTr';
function NativeTable(_a) {
    var children = _a.children, id = _a.id, className = _a.className, data = _a.data, isResponsive = _a.isResponsive, columns = _a.columns, theme = _a.theme, isFullWidth = _a.isFullWidth, isFirstColumnSticky = _a.isFirstColumnSticky, isHeaderSticky = _a.isHeaderSticky, isTableFixed = _a.isTableFixed;
    var props = {
        id: id,
        className: className,
        isResponsive: isResponsive,
        theme: theme,
        isFullWidth: isFullWidth,
        isFirstColumnSticky: isFirstColumnSticky,
        isHeaderSticky: isHeaderSticky,
        isTableFixed: isTableFixed,
    };
    if (typeof children === 'function') {
        return (React.createElement(NativeTableWrapper, __assign({}, props),
            columns && (React.createElement(NativeTableHeader, null,
                React.createElement(NativeTableTr, null, columns.map(function (column, index) { return (React.createElement(NativeTableTh, { key: index }, column)); })))),
            data && (React.createElement(NativeTableBody, null, data.map(function (item, index) {
                return children(item, index);
            })))));
    }
    return React.createElement(NativeTableWrapper, __assign({}, props), children);
}
// Assign the compound parts
NativeTable.Body = NativeTableBody;
NativeTable.Header = NativeTableHeader;
NativeTable.Td = NativeTableTd;
NativeTable.Th = NativeTableTh;
NativeTable.Tr = NativeTableTr;
export default NativeTable;
