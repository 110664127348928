import { Button, Col, FormItem, Input, Row } from 'elmo-elements';
import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import some from 'lodash/some';
import map from 'lodash/map';

import {
  dateTimeWithTimeZone,
  formatDateTime,
  isTimeStampBefore,
} from 'lib/date';
import { reminderIndexEvent } from '../../../../../helpers/events';
import { Cancelled, Sent } from 'constants/messageStatus';
import DeleteReminder from '../../../deleteReminder';
import Datetime from 'element/fields/Datetime';
import translations from './translations';
import { AnalyticsEventNames } from 'lib';
import { RootState } from 'store/type';
import { t } from 'lib/translation';
import { Message } from 'type';
import './style.scss';

interface ReminderSchedulesProps {
  collectorId: number;
}

const ReminderSchedules = ({
  collectorId,
}: ReminderSchedulesProps): ReactElement => {
  const [deleteReminder, updateDeleteReminder] = useState<null | Message>(null);
  const parameters = useSelector(
    (state: RootState) => state.emailInvites.manageInvite.parameters
  );
  const manageInvites = useSelector(
    (state: RootState) => state.emailInvites.manageInvite.data
  );

  const hasNewReminder: boolean = some(manageInvites.reminders, [
    'status',
    null,
  ]);

  const collector = useSelector(
    (state: RootState) => state.collector.getCollector
  );

  const deleteReminderEvent = (
    eventHasNewReminder: boolean,
    reminderIndex: number
  ) => {
    reminderIndexEvent(
      eventHasNewReminder
        ? AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_DELETE_NEW_REMINDER_BUTTON_CLICKED
        : AnalyticsEventNames.SURVEY_EMAIL_COLLECTOR_DELETE_REMINDER_BUTTON_CLICKED,
      collector.collector,
      manageInvites,
      reminderIndex
    );
  };

  return (
    <>
      {map(manageInvites.reminders, (reminder: Message, index: number) => {
        if (reminder.status === Sent) {
          return (
            <React.Fragment key={index}>
              <Row wrap={'nowrap'}>
                <Col xs={24} sm={12}>
                  <FormItem
                    label={t(translations.reminderSentLabel, {
                      index: index + 1,
                    })}
                    message={dateTimeWithTimeZone(reminder.sentDate)}
                  >
                    <Input
                      value={formatDateTime(
                        reminder.sentDate,
                        'DD/MM/YYYY H:mm'
                      )}
                      isDisabled={true}
                    />
                  </FormItem>
                </Col>
              </Row>
            </React.Fragment>
          );
        }

        const isDisabled =
          parameters.isEdit &&
          isTimeStampBefore(reminder.scheduledDate, 30) &&
          reminder.status !== Cancelled;

        const isNewReminder = reminder.status === null;

        return (
          <React.Fragment key={index}>
            <Row wrap={'nowrap'}>
              <Col xs={24} sm={12}>
                <Datetime
                  name={`reminder${reminder.id}`}
                  label={t(translations.reminderLabel, { index: index + 1 })}
                  isDisabled={isDisabled}
                  disablePast={!isDisabled}
                />
              </Col>
              {parameters.isEdit &&
                !isDisabled &&
                ((hasNewReminder && isNewReminder) || !hasNewReminder) && (
                  <Col>
                    <Button
                      id={`delete-reminder-${reminder.id}`}
                      className={'text-button deleteReminderButton'}
                      onClick={() => {
                        deleteReminderEvent(hasNewReminder, index + 1);
                        updateDeleteReminder(reminder);
                      }}
                    >
                      {hasNewReminder
                        ? t(translations.deleteButtonNewReminder)
                        : t(translations.deleteButtonExistingReminder)}
                    </Button>
                  </Col>
                )}
            </Row>
          </React.Fragment>
        );
      })}
      {deleteReminder && (
        <DeleteReminder
          collectorId={collectorId}
          reminder={deleteReminder}
          closeModal={() => updateDeleteReminder(null)}
        />
      )}
    </>
  );
};

export default ReminderSchedules;
