import assign from 'lodash/assign';
import map from 'lodash/map';
import * as Yup from 'yup';

import { isMessageEditable } from 'lib/helpers/emailInvites';
import { isTimeStampAfter } from 'lib/date';
import translations from '../translations';
import { t } from 'lib/translation';
import { Message } from 'type';

export const dateAfterPreviousFieldSchema = (
  previousFieldKey: string,
  isFirst: boolean
) => {
  return Yup.date()
    .required(t(translations.validation.required))
    .nullable()
    .min(
      Yup.ref(previousFieldKey),
      isFirst
        ? t(translations.validation.afterInvite)
        : t(translations.validation.afterPrevReminder)
    );
};

export const dateInFutureSchema = (fieldKey: string) => {
  return Yup.date()
    .required(t(translations.validation.required))
    .nullable()
    .test(fieldKey, '', function (value) {
      const { createError } = this as Yup.TestContext;

      if (isTimeStampAfter(value, 120)) {
        return true;
      } else {
        return createError({
          message: t(translations.validation.afterNow),
        });
      }
    });
};

export const getEditReminderValidation = (
  message: Message,
  reminders: Message[]
) => {
  let previousField = 'inviteDate';
  let isPreviousSent = !isMessageEditable(message);

  return map(reminders, (reminder: Message, index: number) => {
    const reminderKey = `reminder${reminder.id}`;
    const reminderEditable = isMessageEditable(reminder);

    if (!reminderEditable) {
      previousField = `${reminderKey}`;
      isPreviousSent = true;

      return { [reminderKey]: Yup.date().nullable() };
    }

    const reminderSchema = {
      [reminderKey]: Yup.date().when([], {
        is: () => isPreviousSent,
        then: dateInFutureSchema(reminderKey),
        otherwise: dateAfterPreviousFieldSchema(previousField, index === 0),
      }),
    };

    previousField = `${reminderKey}`;
    isPreviousSent = false;

    return reminderSchema;
  });
};

export const getReminderValidation = (
  message: Message,
  reminders: Message[]
): any => {
  let previousField = 'inviteDate';

  return map(reminders, (reminder: Message, index: number) => {
    const reminderKey = `reminder${reminder.id}`;

    const reminderSchema = {
      [reminderKey]: Yup.date().when('sendNow', {
        is: (sendNow: boolean) => index === 0 && sendNow,
        then: dateInFutureSchema(reminderKey),
        otherwise: dateAfterPreviousFieldSchema(previousField, index === 0),
      }),
    };

    previousField = `${reminderKey}`;

    return reminderSchema;
  });
};

export const sendScheduleValidationSchema = (
  isEdit: boolean,
  message: Message,
  reminders: Message[]
) => {
  const reminderValidation = isEdit
    ? getEditReminderValidation(message, reminders)
    : getReminderValidation(message, reminders);

  return Yup.object().shape({
    inviteDate: Yup.date()
      .nullable()
      .when('sendNow', {
        is: (sendNow: boolean) => {
          if (isEdit) {
            return isMessageEditable(message);
          }

          return !sendNow;
        },
        then: dateInFutureSchema('inviteDate'),
        otherwise: Yup.date().nullable(),
      }),
    ...assign.apply(assign, reminderValidation),
  });
};
