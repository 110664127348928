import React from 'react';
import './PaginationRange.scss';
import { Text } from '../../Typography';
function PaginationRange(_a) {
    var firstResult = _a.firstResult, lastResult = _a.lastResult, totalResults = _a.totalResults;
    return (React.createElement("div", { className: "elmo-pagination__range" },
        React.createElement(Text, { size: "sm" },
            firstResult,
            " - ",
            lastResult,
            " of ",
            totalResults,
            " records shown")));
}
export default PaginationRange;
