import map from 'lodash/map';

import {
  APISharedReportBulkLink,
  APISharedReportIndividualLink,
  SharedReportBulkLink,
  SharedReportIndividualLink,
} from '../../type';
import { transformUser } from './user';

export const transformSharedReportIndividual = (
  link: APISharedReportIndividualLink
): SharedReportIndividualLink => {
  return {
    closed: link.closed,
    closedDate: link.closed_date,
    createdBy: transformUser(link.created_by),
    createdDate: link.created_date,
    filterValues: link.filter_values,
    id: link.id,
    sharedReportBatch: link.shared_report_batch,
    shortUrl: link.short_url,
  };
};

export const transformSharedReportIndividuals = (
  links: APISharedReportIndividualLink[]
): SharedReportIndividualLink[] => {
  return map(links, transformSharedReportIndividual);
};

export const transformSharedReportBulk = (
  link: APISharedReportBulkLink
): SharedReportBulkLink => {
  return {
    createdBy: transformUser(link.created_by),
    createdDate: link.created_date,
    filterDisplay: link.filter_display,
    filters: link.filters,
    generateCompleted: link.generate_completed,
    id: link.id,
    linksCount: link.linksCount,
    title: link.title,
    updatedDate: link.updated_date,
  };
};

export const transformSharedReportBulks = (
  links: APISharedReportBulkLink[]
): SharedReportBulkLink[] => {
  return map(links, transformSharedReportBulk);
};
