import produce from 'immer';

import {
  createInviteMessageSchedule,
  createInviteMessageScheduleFailure,
  createInviteMessageScheduleSuccess,
  clearCreateInviteMessageSchedule,
} from './actions';
import { invitesInitialState } from '../state';
import { InvitesState } from '../type';

export function createInviteMessageScheduleReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteMessageSchedule.isCreated = false;
    draft.createInviteMessageSchedule.isCreating = true;
    draft.createInviteMessageSchedule.isValid = false;
  });
}

export function createInviteMessageScheduleSuccessReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteMessageSchedule.isCreated = true;
    draft.createInviteMessageSchedule.isCreating = false;
    draft.createInviteMessageSchedule.isValid = true;
  });
}

export function createInviteMessageScheduleFailureReducer(
  state: InvitesState,
  error: any
) {
  return produce(state, (draft) => {
    draft.createInviteMessageSchedule.isCreated = true;
    draft.createInviteMessageSchedule.isCreating = false;
    draft.createInviteMessageSchedule.error = error;
  });
}

export function clearCreateInviteMessageScheduleReducer(state: InvitesState) {
  return produce(state, (draft) => {
    draft.createInviteMessageSchedule =
      invitesInitialState.createInviteMessageSchedule;
  });
}

export const createInviteMessageScheduleReducers = {
  [`${createInviteMessageSchedule}`]: createInviteMessageScheduleReducer,
  [`${createInviteMessageScheduleSuccess}`]:
    createInviteMessageScheduleSuccessReducer,
  [`${createInviteMessageScheduleFailure}`]:
    createInviteMessageScheduleFailureReducer,
  [`${clearCreateInviteMessageSchedule}`]:
    clearCreateInviteMessageScheduleReducer,
};
