import { Divider, FormItem, Heading, Input } from 'elmo-elements';
import EmailIcon from '@mui/icons-material/Email';
import React, { useState } from 'react';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { MessageEditorProps, ValidationErrorType } from './type';
import BaseModal from '../Modal/components/baseModal';
import InviteSelect from './components/inviteSelect';
import translations from './translations';
import { t } from 'lib/translation';
import Editor from '../Editor';

const MessageEditor = ({
  closeModal,
  messageState,
  updateMessageState,
  hasSecondaryButton,
  secondaryButtonOnClick = () => true,
  primaryButtonOnClick,
  messageTranslations,
}: MessageEditorProps) => {
  const [validationError, setValidationError] =
    useState<ValidationErrorType>(null);

  const checkContentForPlaceHolder = (content: string): string => {
    const placeHolder = '{{SurveyLink}}';

    if (
      includes(messageState.content, placeHolder) &&
      !includes(content, placeHolder)
    ) {
      setValidationError('removedPlaceholder');
      return messageState.content;
    }

    return content;
  };

  const updateMessageStateByField = (field: string, data: any) => {
    if (field === 'content') {
      data = checkContentForPlaceHolder(data);
    }

    updateMessageState({ ...messageState, [field]: data });
  };

  const isSubmitDisabled = (): boolean => {
    return (
      isEmpty(messageState.senderName) ||
      isEmpty(messageState.content) ||
      isEmpty(messageState.subject) ||
      isEmpty(messageState.selectedTemplate)
    );
  };

  const validationModal = () => {
    if (validationError === null) {
      return <></>;
    }

    const errorTranslations = translations.errors[validationError];

    return (
      <BaseModal
        id={'validation-modal'}
        closeModal={() => setValidationError(null)}
        title={t(errorTranslations.title)}
        hasSecondaryButton={false}
        primaryButtonClick={() => setValidationError(null)}
        primaryButtonText={t(errorTranslations.primaryActionButton)}
      >
        <>
          {t(errorTranslations.body, {
            interpolation: { prefix: '{{{' },
          })}
        </>
      </BaseModal>
    );
  };

  return (
    <>
      <BaseModal
        id={'invite-message'}
        modalType={'x-large'}
        closeModal={closeModal}
        title={
          <>
            <EmailIcon className={'header-icon'} />
            {t(messageTranslations.title)}
          </>
        }
        buttonsType={'separate'}
        hasSecondaryButton={hasSecondaryButton}
        primaryButtonClick={primaryButtonOnClick}
        primaryButtonText={t(messageTranslations.confirmationButton)}
        primaryButtonIsDisabled={isSubmitDisabled()}
        secondaryButtonClick={secondaryButtonOnClick}
        secondaryButtonText={t(messageTranslations.secondaryButton)}
      >
        <>
          <FormItem
            label={t(messageTranslations.fields.selectMessage.title)}
            message={t(messageTranslations.fields.selectMessage.message)}
          >
            <InviteSelect
              currentForm={messageState}
              updateForm={updateMessageState}
            />
          </FormItem>

          {!isEmpty(messageState.selectedTemplate) && (
            <>
              <Divider />

              <Heading
                htmlTag="h4"
                type="title"
                size="sm"
                className={'invite-participants-heading'}
              >
                {t(messageTranslations.sectionTitles.sender)}
              </Heading>

              <FormItem label={t(messageTranslations.fields.senderEmail.title)}>
                <Input isDisabled={true} value={messageState.senderEmail} />
              </FormItem>
              <FormItem label={t(messageTranslations.fields.senderName.title)}>
                <Input
                  id={'sender-details-name'}
                  value={messageState.senderName}
                  onChange={(e: any) =>
                    updateMessageStateByField('senderName', e.target.value)
                  }
                  placeholder={t(
                    messageTranslations.fields.senderName.placeHolder
                  )}
                />
              </FormItem>

              <FormItem label={t(messageTranslations.fields.subject.title)}>
                <Input
                  id={'invitation-message-subject'}
                  value={messageState.subject}
                  onChange={(e: any) =>
                    updateMessageStateByField('subject', e.target.value)
                  }
                  placeholder={t(
                    messageTranslations.fields.subject.placeHolder
                  )}
                />
              </FormItem>

              <Divider />

              <Heading
                htmlTag="h4"
                type="title"
                size="sm"
                className={'invite-participants-heading'}
              >
                {t(messageTranslations.fields.content.title)}
              </Heading>

              <FormItem label={''}>
                <Editor
                  model={messageState.content}
                  onModelChange={(content: any) =>
                    updateMessageStateByField('content', content)
                  }
                  config={{
                    heightMin: 200,
                    placeholderText: t(
                      messageTranslations.fields.content.placeHolder
                    ),
                  }}
                />
              </FormItem>
            </>
          )}
        </>
      </BaseModal>
      {validationModal()}
    </>
  );
};

export default MessageEditor;
