export var SIZE_SMALL = 'small';
export var SIZE_MEDIUM = 'medium';
export var SIZE_LARGE = 'large';
export var STATUS_TYPE_WARNING = 'warning';
export var STATUS_TYPE_INFO = 'info';
export var STATUS_TYPE_SUCCESS = 'success';
export var STATUS_TYPE_DANGER = 'danger';
export var DIRECTION_VERTICAL = 'vertical';
export var DIRECTION_HORIZONTAL = 'horizontal';
export var PLACEMENT_TYPES = [
    'auto-start',
    'auto',
    'auto-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-end',
    'left',
    'left-start',
];
/**
 * Menu Constants
 */
export var MENU_STATE_TYPE_MIN = 'min';
export var MENU_STATE_TYPE_WIDE = 'wide';
export var SORT_ASC = 'asc';
export var SORT_DESC = 'desc';
export var TOTAL_GRID_COLS = 24;
/**
 * Pagination Constants
 */
export var PAGINATION_OPTIONS = [10, 20, 30, 40, 50, 100, 150, 200];
export var PAGINATION_FIRST_PAGE = 1;
export var TIMELINE_DEFAULT_COLOR = '#DBDBDB';
export var TIMELINE_ACTIVE_COLOR = '#058c45';
