var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LoaderHolder from './LoaderHolder';
import './MenuStyle.scss';
function MenuStyle(props) {
    return (React.createElement(LoaderHolder, __assign({ viewBoxHeight: 350, viewBoxWidth: 250, className: "elmo-loader-menu" }, props),
        React.createElement("rect", { x: "60", y: "15", rx: "4", ry: "4", width: "150", height: "20" }),
        React.createElement("rect", { x: "60", y: "45", rx: "3", ry: "3", width: "85", height: "20" }),
        React.createElement("circle", { cx: "30", cy: "40", r: "25" }),
        React.createElement("circle", { cx: "24", cy: "125", r: "12" }),
        React.createElement("rect", { x: "50", y: "115", rx: "5", ry: "5", width: "180", height: "20" }),
        React.createElement("circle", { cx: "24", cy: "170", r: "12" }),
        React.createElement("rect", { x: "50", y: "160", rx: "5", ry: "5", width: "180", height: "20" }),
        React.createElement("circle", { cx: "24", cy: "215", r: "12" }),
        React.createElement("rect", { x: "50", y: "205", rx: "5", ry: "5", width: "180", height: "20" }),
        React.createElement("circle", { cx: "24", cy: "260", r: "12" }),
        React.createElement("rect", { x: "50", y: "250", rx: "5", ry: "5", width: "180", height: "20" }),
        React.createElement("circle", { cx: "24", cy: "305", r: "12" }),
        React.createElement("rect", { x: "50", y: "295", rx: "5", ry: "5", width: "180", height: "20" })));
}
export default MenuStyle;
