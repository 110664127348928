const translations = {
  title: {
    key: 'EmailInvites.manageInvite.steps.recipients.recipientsTypeSelector.title',
    default: 'Participants',
  },
  heading: {
    key: 'EmailInvites.manageInvite.steps.recipients.recipientsTypeSelector.heading',
    default: 'Do you want to send your survey to your contacts by:',
  },
  steps: {
    manualInput: {
      key: 'EmailInvites.manageInvite.steps.recipients.recipientsTypeSelector.steps.manualInput',
      default: 'Enter emails manually',
    },
    tmsProfiles: {
      key: 'EmailInvites.manageInvite.steps.recipients.recipientsTypeSelector.steps.tmsProfiles',
      default: 'Search in my organisation',
    },
    csvImport: {
      key: 'EmailInvites.manageInvite.steps.recipients.recipientsTypeSelector.steps.csvImport',
      default: 'Import CSV',
    },
  },
  previousButton: {
    key: '',
    default: 'Previous',
  },
};

export default translations;
