import React from 'react';
import './CustomFilterSummary.scss';
import Button from '../../Button';
import { FilterListIcon, SaveAltIcon, CancelIcon } from '../../Icon';
/**
 * Component for rendering the selected custom filters
 * @param items
 * @param onSaveClick
 * @param onClearClick
 * @constructor
 */
function CustomFilterSummary(_a) {
    var items = _a.items, onSaveClick = _a.onSaveClick, onClearClick = _a.onClearClick;
    return (React.createElement("div", { className: "elmo-filter__summary" },
        React.createElement("div", { className: "elmo-filter__summary__title" },
            React.createElement(FilterListIcon, null)),
        items.map(function (item, index) {
            return (React.createElement("div", { className: "elmo-filter__summary__item", key: index },
                item.icon && item.icon,
                " ",
                item.title && React.createElement("b", null, item.title),
                item.label && item.label));
        }),
        React.createElement("div", { className: "elmo-filter__summary__item" },
            onSaveClick && (React.createElement(Button, { icon: React.createElement(SaveAltIcon, null), className: "elmo-filter__summary__button", onClick: onSaveClick, isUppercase: false, type: "primary", isText: true }, "Save")),
            onClearClick && (React.createElement(Button, { icon: React.createElement(CancelIcon, null), className: "elmo-filter__summary__button", onClick: onClearClick, isUppercase: false }, "Clear")))));
}
export default CustomFilterSummary;
