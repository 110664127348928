import produce from 'immer';

import {
  updateSurvey,
  updateSurveySuccess,
  updateSurveyFailure,
  clearUpdateSurvey,
} from './actions';
import { updateSurveyInitialState } from './state';
import { SurveyState } from '../type';
import { ErrorResponse } from 'type';

export function updateSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateSurvey.isLoading = true;
    draft.updateSurvey.isLoaded = false;
    draft.updateSurvey.isValid = false;
    draft.updateSurvey.error = undefined;
  });
}

export function updateSurveySuccessReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateSurvey.isLoading = false;
    draft.updateSurvey.isLoaded = true;
    draft.updateSurvey.isValid = true;
  });
}

export function updateSurveyFailureReducer(
  state: SurveyState,
  error: ErrorResponse
): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateSurvey.isLoading = false;
    draft.updateSurvey.isLoaded = true;
    draft.updateSurvey.error = error;
  });
}

export function clearUpdateSurveyReducer(state: SurveyState): SurveyState {
  return produce(state, (draft: SurveyState) => {
    draft.updateSurvey = updateSurveyInitialState;
  });
}

export const updateSurveyReducers = {
  [`${updateSurvey}`]: updateSurveyReducer,
  [`${updateSurveySuccess}`]: updateSurveySuccessReducer,
  [`${updateSurveyFailure}`]: updateSurveyFailureReducer,
  [`${clearUpdateSurvey}`]: clearUpdateSurveyReducer,
};
