import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import {
  CreateIndividualLinkRequest,
  CreateIndividualLinkResponse,
} from './type';
import { ErrorResponse } from 'type';

export const createIndividualLink: ActionCreator<CreateIndividualLinkRequest> =
  createAction('CREATE_INDIVIDUAL_LINK');

export const createIndividualLinkSuccess: ActionCreator<CreateIndividualLinkResponse> =
  createAction('CREATE_INDIVIDUAL_LINK_SUCCESS');

export const createIndividualLinkFailure: ActionCreator<ErrorResponse> =
  createAction('CREATE_INDIVIDUAL_LINK_FAILURE');

export const clearCreateIndividualLink: EmptyActionCreator = createAction(
  'CLEAR_CREATE_INDIVIDUAL_LINK'
);
