import { GetBenchmarkReportState } from './type';

export const getBenchmarkReportInitialState: GetBenchmarkReportState = {
  count: 0,
  data: [],
  total_respondent_count: 0,
  isLoaded: false,
  isLoading: false,
  isValid: false,
};
