import produce from 'immer';

import {
  getBenchmarkReport,
  getBenchmarkReportFailure,
  getBenchmarkReportSuccess,
  clearGetBenchmarkReport,
} from './actions';
import { getBenchmarkReportInitialState } from './state';
import { GetBenchmarkReportResponse } from './type';
import { BenchmarkState } from '../type';
import { ErrorResponse } from 'type';

export function getBenchmarkReportReducer(
  state: BenchmarkState
): BenchmarkState {
  return produce(state, (draft: BenchmarkState) => {
    draft.getBenchmarkReport = { ...getBenchmarkReportInitialState };
    draft.getBenchmarkReport.isLoading = true;
  });
}

export function getBenchmarkReportSuccessReducer(
  state: BenchmarkState,
  data: GetBenchmarkReportResponse
): BenchmarkState {
  return produce(state, (draft: BenchmarkState) => {
    draft.getBenchmarkReport.isLoaded = true;
    draft.getBenchmarkReport.isLoading = false;
    draft.getBenchmarkReport.isValid = true;
    draft.getBenchmarkReport.count = data.count;
    draft.getBenchmarkReport.total_respondent_count =
      data.total_respondent_count;
    draft.getBenchmarkReport.data = data.data;
  });
}

export function getBenchmarkReportFailureReducer(
  state: BenchmarkState,
  error: ErrorResponse
): BenchmarkState {
  return produce(state, (draft: BenchmarkState) => {
    draft.getBenchmarkReport.isLoaded = true;
    draft.getBenchmarkReport.isLoading = false;
    draft.getBenchmarkReport.error = error;
  });
}
export function clearGetBenchmarkReportReducer(
  state: BenchmarkState
): BenchmarkState {
  return produce(state, (draft: BenchmarkState) => {
    draft.getBenchmarkReport = getBenchmarkReportInitialState;
  });
}

export const getBenchmarkReportReducers = {
  [`${getBenchmarkReport}`]: getBenchmarkReportReducer,
  [`${getBenchmarkReportSuccess}`]: getBenchmarkReportSuccessReducer,
  [`${getBenchmarkReportFailure}`]: getBenchmarkReportFailureReducer,
  [`${clearGetBenchmarkReport}`]: clearGetBenchmarkReportReducer,
};
