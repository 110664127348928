var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { WarningIcon } from '../../Icon';
function WarningDialog(_a) {
    var id = _a.id, title = _a.title, content = _a.content, onCancel = _a.onCancel, onOk = _a.onOk, okLabel = _a.okLabel, cancelLabel = _a.cancelLabel, withoutIcon = _a.withoutIcon;
    return (React.createElement(Modal, __assign({ id: id }, (withoutIcon ? {} : { icon: React.createElement(WarningIcon, null) }), { type: "dialog", isOpened: true, title: title, hasCloseFooterButton: false, primaryButton: React.createElement(Button, { type: "primary", isBlock: true, onClick: onOk }, okLabel) }), content));
}
WarningDialog.defaultProps = {
    okLabel: 'Ok',
};
export default WarningDialog;
