import React, { ReactElement, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import PageLoader from 'element/PageLoading';

const PrivateLayout = (): ReactElement => {
  return (
    <Suspense fallback={<PageLoader text={'Loading...'} />}>
      <Outlet />
    </Suspense>
  );
};

export default PrivateLayout;
