import React, { ReactElement, useEffect, useState } from 'react';

import BaseModal from 'element/Modal/components/baseModal';

interface NotificationPopUpProps {
  errorMessage: string;
  title?: string;
  closeButtonText?: string;
  onCloseCallBack?: () => void;
}

const NotificationPopUp = ({
  errorMessage,
  title = 'We can see there was an issue',
  closeButtonText = 'Close',
  onCloseCallBack,
}: NotificationPopUpProps): ReactElement => {
  const [showNotification, toggleShowNotification] = useState<boolean>(false);

  const closeModal = () => {
    toggleShowNotification(false);

    if (onCloseCallBack) {
      onCloseCallBack();
    }
  };

  useEffect(() => {
    if (errorMessage) {
      toggleShowNotification(true);
    }
  }, [errorMessage]);

  if (!showNotification) {
    return <></>;
  }

  return (
    <BaseModal
      id={'notification-popup'}
      closeModal={closeModal}
      title={title}
      hasSecondaryButton={false}
      primaryButtonClick={closeModal}
      primaryButtonText={closeButtonText}
    >
      <>{errorMessage}</>
    </BaseModal>
  );
};

export default NotificationPopUp;
