var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import HeaderButtons from './component/HeaderButtons';
import HeaderBack from './component/HeaderBack';
import HeaderTitle from './component/HeaderTitle';
import HeaderTitleAddon from './component/HeaderTitleAddon';
import HeaderTabs from './component/HeaderTabs';
import { getClass } from '../_lib/helper';
import './Header.scss';
import { ThemeContextProvider } from './component/ThemeContext';
import { HeaderTabsStyled } from './Styles';
function Header(_a) {
    var children = _a.children, backButton = _a.backButton, buttons = _a.buttons, primaryButton = _a.primaryButton, tabs = _a.tabs, subHeading = _a.subHeading, titleAddon = _a.titleAddon, title = _a.title, isSearchOpen = _a.isSearchOpen, isBulkActionOpen = _a.isBulkActionOpen, theme = _a.theme;
    return (React.createElement(ThemeContextProvider, { value: theme },
        React.createElement("div", { className: getClass('elmo-header-wrapper', {}, {
                search: isSearchOpen,
                'bulk-actions': isBulkActionOpen,
                'has-buttons': buttons,
                'has-back': backButton,
                'has-tabs': tabs,
                'has-subheading': subHeading,
            }) },
            React.createElement("div", { className: getClass('elmo-header', {}, { subpage: backButton }) },
                children,
                React.createElement("div", { className: "elmo-header__base" },
                    React.createElement("div", { className: "elmo-header__top" },
                        (buttons || primaryButton) && (React.createElement(HeaderButtons, { buttons: buttons, primaryButton: primaryButton })),
                        React.createElement("div", { className: "elmo-header__title-wrapper" },
                            backButton && (React.createElement(HeaderBack, __assign({}, (backButton.id ? { id: backButton.id } : null), { onClick: backButton.onClick, title: backButton.title, ariaLabel: backButton.ariaLabel }))),
                            React.createElement("div", { className: "elmo-header__title-block-wrapper" },
                                React.createElement("div", { className: "elmo-header__title-block" },
                                    React.createElement(HeaderTitle, { subHeading: subHeading }, title),
                                    React.createElement(HeaderTitleAddon, null, titleAddon)),
                                !!subHeading && (React.createElement("h2", { className: "elmo-header__sub-heading" }, subHeading))))),
                    tabs && (React.createElement(HeaderTabsStyled, { primaryColor: theme && theme.primaryColor },
                        React.createElement(HeaderTabs, { tabs: tabs })))))),
        React.createElement("div", { className: "elmo-header-wrapper__spacing" })));
}
export default Header;
